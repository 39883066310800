const { defStruct } = require('../../lib');

/**
 * Chunk list is needed for loading, for example, chat messages.
 * In order not to load everything at once, but only a part.
 *
 * @typedef {object} ChunkList
 * @property {string} type
 * @property {[]} list
 * @property {number} amountLeft
 */

const chunkListMethods = defStruct('ChunkList', ['list', 'amountLeft']);

module.exports = {
  ...chunkListMethods
};
