export const scrollToBeVisible = (el, container) => {
  const top =
    el.getBoundingClientRect().top - container.getBoundingClientRect().top;
  const elHeight = el.clientHeight;
  const containerHeight = container.clientHeight;

  if (top < 0) {
    container.scrollTop += top;
    return;
  }

  if (containerHeight >= top + elHeight) {
    return;
  }

  container.scrollTop += top + elHeight - containerHeight;
};
