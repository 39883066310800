import React, { useCallback, useEffect, useMemo, useState } from 'react';
import block from 'bem-cn-lite';
import { IMaskInput } from 'react-imask';
import IMask from 'imask/esm/imask';
import { isNil } from 'ramda';
import { nanoid } from 'nanoid';
import { getInitTime, getTime, isFirstDateGTE } from '../../utils';
import { DEFAULT_TIME, TIME_MASK, TIME_SEPARATOR } from '../../config';

const b = block('datepicker-goodwix');

const iMaskBlockOption = {
  HH: {
    mask: IMask.MaskedRange,
    placeholderChar: '0',
    from: 0,
    to: 23,
    maxLength: 2
  },
  MM: {
    mask: IMask.MaskedRange,
    placeholderChar: '0',
    from: 0,
    to: 59,
    maxLength: 2
  }
};

const TimeInput = ({ t, date, setDate, minTime, timeTkey }) => {
  const [time, setTime] = useState(getInitTime(minTime));
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    if (isNil(date)) {
      setTime(DEFAULT_TIME);
    } else {
      setTime(getTime(date));
    }
  }, [date]);

  const onComplete = useCallback((value) => {
    setTime(value);
  }, []);

  const onFocus = useCallback(() => {
    setActive(true);
  }, []);

  const onBlur = useCallback(() => {
    setActive(false);

    if (isNil(date)) return;

    const [hh, mm] = time.split(TIME_SEPARATOR);
    let updatedDate = new Date(date);
    updatedDate.setHours(+hh, +mm, 0, 0);

    if (!isNil(minTime) && isFirstDateGTE(minTime, updatedDate)) {
      updatedDate = minTime;
      setTime(getTime(updatedDate));
    }

    setDate(updatedDate);
  }, [time, minTime]);

  const inputId = useMemo(() => `datepicker-time-${nanoid()}`, []);

  return (
    <div className={b('time', { active: isActive })}>
      <label htmlFor={inputId} className={b('input-label')}>
        {t(timeTkey)}
      </label>
      <IMaskInput
        id={inputId}
        mask={TIME_MASK}
        overwrite
        autofix
        lazy={false}
        blocks={iMaskBlockOption}
        className={b('input')}
        onComplete={onComplete}
        value={time}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={isNil(date)}
      />
    </div>
  );
};

export default TimeInput;
