import React from 'react';
import { Button, useWizard } from '@link/react-components';
import { useTranslate } from '@hooks';
import { getTranslate } from '../../../utils';
import { Footer } from '../../layout';
import { useGroupActions } from '../../../hooks';
import classes from './DeleteGroup.module.css';

export function DeleteGroup() {
  const t = useTranslate();
  const { getState, previous } = useWizard();

  const { currentGroup } = getState();

  const { loading, remove } = useGroupActions();

  function translate(tKey, params) {
    return getTranslate(t, `deleteGroup.${tKey}`, params);
  }

  async function onSubmit() {
    await remove(currentGroup.id);
    previous();
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>{translate('text')}</div>
      <Footer>
        <Button
          mode="primary"
          size="large"
          loading={loading}
          onClick={onSubmit}>
          {translate('actions.submit')}
        </Button>
      </Footer>
    </div>
  );
}
