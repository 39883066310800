import { isNil } from 'ramda';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import { getDate, getTime } from '../../../utils/date';

const template = 'd MMM';
const formatRu = (date) => {
  const formatedDate = format(date, template, { locale: ru });
  const [number, month] = formatedDate.split(' ');
  return `${number} ${month.slice(0, 3)}`;
};
const formatEn = (date) => format(date, template, { locale: en }).toLowerCase();

const formatDate = (language, date) => {
  if (language === 'ru') return formatRu(date);
  if (language === 'en') return formatEn(date);
  return format(date, 'dd.MM.yyyy');
};

export const getLastMessageDate = (language, messageDate) => {
  if (isNil(messageDate)) return '';

  const lastMessageDate = new Date(messageDate);
  const today = new Date();

  if (getDate(today) === getDate(lastMessageDate)) {
    return getTime(lastMessageDate);
  }

  return formatDate(language, lastMessageDate);
};
