import {
  setSearchQuery,
  setDateRange,
  toggleSortDir,
  toggleTag,
  disableTag,
  resetSearchQuery,
  updatedSearchQuery,
  loadMoreMsgs,
  repeatSearch
} from './messages';
import request from '../../../../lib/request';
import {
  SET_ADVANCED_SEARCH_GLOBAL_SEARCH,
  SET_ADVANCED_SEARCH_PARAM,
  SET_ADVANCED_SEARCH_RESULT,
  SET_ADVANCED_SEARCH_RESULT_PART,
  SET_ADVANCED_SEARCH,
  SET_HEADER_VIEW
} from '../../constants';
import { gaSend } from '../../../../action-creators/services';
import {
  toggleSortDir as toggleSortDirBlock,
  toggleTag as toggleTagBlock,
  xtoggleTag,
  makeBlockAdvancedSearch,
  isSearchQueryEmpty
} from '../../data-type/block-advanced-search';

import { getAdvancedSearch } from '../../getter';

const getServerQuery = (advancedSearch) => ({
  ...advancedSearch.params,
  search: advancedSearch.search
});

const searchReq = async (search) =>
  request.post('/api/chat/advanced-search', { search, limit: 20, offset: 0 });
const loadMoreMsgsReq = async (search, { limit = 20, offset = 0 } = {}) =>
  request.post('/api/chat/advanced-search/messages', { search, limit, offset });

const setAdvancedSearchGlobalSearch = (payload) => ({
  type: SET_ADVANCED_SEARCH_GLOBAL_SEARCH,
  payload
});

const setAdvancedSearchParam = (payload) => ({
  type: SET_ADVANCED_SEARCH_PARAM,
  payload
});

const setAdvancedSearchResults = (results) => ({
  type: SET_ADVANCED_SEARCH_RESULT,
  payload: results
});

const setASSearchResultsPart = (field, data) => ({
  type: SET_ADVANCED_SEARCH_RESULT_PART,
  payload: { field, data }
});

const setAdvancedSearchBlock = (blockSearch) => ({
  type: SET_ADVANCED_SEARCH,
  payload: blockSearch
});

const getAS = (getState) => getAdvancedSearch(getState());

let unsubSetSearchQuery;
let unsubSetDateRange;
let unsubToggleSortDir;
let unsubToggleTag;
let unsubDisableTag;
let unsubRepeatSearch;
let unsubLoadMoreMsgs;
let unsubUpdatedSearchQuery;

export const subscribeAdvancedSearch = () => (dispatch, getState) => {
  const searchAndSet = async (partQuery) => {
    if (
      (partQuery.search && partQuery.search !== '') ||
      !isSearchQueryEmpty(getAS(getState))
    ) {
      const advancedSearch = getAS(getState);
      const query = { ...getServerQuery(advancedSearch), ...partQuery };
      const result = await searchReq(query);
      dispatch(setAdvancedSearchResults(result));
    }
  };

  unsubSetSearchQuery = setSearchQuery.handle(async (text) => {
    await searchAndSet({ search: text });

    dispatch(setAdvancedSearchGlobalSearch(text));
    updatedSearchQuery.emit();
  });

  unsubSetDateRange = setDateRange.handle(async (dateRange) => {
    dispatch(setAdvancedSearchParam({ key: 'messageDate', value: dateRange }));
    updatedSearchQuery.emit();

    await searchAndSet({ messageDate: dateRange });
  });

  unsubToggleSortDir = toggleSortDir.handle(async () => {
    const nextSortDir = toggleSortDirBlock(getAS(getState));
    dispatch(setAdvancedSearchParam({ key: 'sortDir', value: nextSortDir }));
    updatedSearchQuery.emit();

    await searchAndSet({ sortDir: nextSortDir });
  });

  unsubToggleTag = toggleTag.handle(async (tag) => {
    const newTagList = toggleTagBlock(tag, getAS(getState));
    dispatch(
      setAdvancedSearchParam({ key: 'selectedTags', value: newTagList })
    );
    updatedSearchQuery.emit();

    await searchAndSet({ selectedTags: newTagList });
  });

  unsubDisableTag = disableTag.handle(async (tag) => {
    const newTagList = xtoggleTag(tag, getAS(getState));
    dispatch(
      setAdvancedSearchParam({ key: 'selectedTags', value: newTagList })
    );
    updatedSearchQuery.emit();

    await searchAndSet({ selectedTags: newTagList });
  });

  unsubRepeatSearch = repeatSearch.handle(async () => {
    await searchAndSet({});
  });

  resetSearchQuery.handle(() => {
    dispatch(setAdvancedSearchBlock(makeBlockAdvancedSearch()));
  });

  unsubLoadMoreMsgs = loadMoreMsgs.handle(async () => {
    const advancedSearch = getAS(getState);

    const { messages } = await loadMoreMsgsReq(getServerQuery(advancedSearch), {
      offset: getOffset()
    });

    dispatch(setASSearchResultsPart('messages', messages));

    function getOffset() {
      return advancedSearch.result.messages.list.length;
    }
  });

  unsubUpdatedSearchQuery = updatedSearchQuery.handle(() => {
    gaSend({
      category: 'Messenger',
      action: 'chat_adv_search_add',
      label: getState().getIn(['user', 'user', 'email'])
    })();
  });
};

export const unsubscribeAdvencedSearch = () => (dispatch) => {
  dispatch(setAdvancedSearchBlock(makeBlockAdvancedSearch()));
  dispatch({ type: SET_HEADER_VIEW, payload: 'dashboard' });
  unsubSetSearchQuery();
  unsubSetDateRange();
  unsubToggleSortDir();
  unsubToggleTag();
  unsubDisableTag();
  unsubRepeatSearch();
  unsubLoadMoreMsgs();
  unsubUpdatedSearchQuery();
};
