import React, { useCallback } from 'react';
import { useTranslate } from '@hooks';
import { isEmpty } from 'ramda';
import { SearchInput } from '@link/react-components';
import { Typography } from '../../../../shared/components';
import { getTranslate } from '../../utils';
import EmailStatusList from '../EmailStatusList/EmailStatusList';
import Loader from '../../../../shared/components/Loader/Loader';
import classes from './Modal.module.css';

const Content = ({
  loading,
  error,
  emailStatusList,
  onSearch,
  isEmptyResult = false
}) => {
  const onCleanUpCallback = useCallback(() => onSearch(''), [onSearch]);
  const t = useTranslate();

  if (error) {
    return (
      <div className={classes.errorWrapper}>
        <Typography variant="body1Reg">
          {getTranslate(t, 'modal.error')}
        </Typography>
      </div>
    );
  }

  if (loading) {
    return <Loader />;
  }

  if (isEmptyResult) {
    return (
      <div>
        <Typography variant="body1Reg">
          {getTranslate(t, 'modal.noResult')}
        </Typography>
      </div>
    );
  }

  return (
    <>
      <div className={classes.searchInput}>
        <SearchInput
          onSearch={onSearch}
          onCleanUp={onCleanUpCallback}
          placeholder={getTranslate(t, 'modal.searchPlaceholder')}
        />
      </div>
      {!isEmpty(emailStatusList) ? (
        <div className={classes.table}>
          <EmailStatusList emailStatusList={emailStatusList} />
        </div>
      ) : (
        <div>
          <Typography variant="body1Reg">
            {getTranslate(t, 'modal.noSearchResult')}
          </Typography>
        </div>
      )}
    </>
  );
};

export default Content;
