import React from 'react';

const Warning = () => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 6V10"
      stroke="#767B92"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 1L15 14H1L8 1Z"
      stroke="#767B92"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 11.75C8.75 12.1642 8.41421 12.5 8 12.5C7.58579 12.5 7.25 12.1642 7.25 11.75C7.25 11.3358 7.58579 11 8 11C8.41421 11 8.75 11.3358 8.75 11.75Z" // eslint-disable-line
      fill="#767B92"
    />
  </svg>
);

export default Warning;
