import React from 'react';
import Text, { FONTS } from 'react-components/src/components/Typography';
import { isNilOrEmpty } from '../../../../../../../../utils/utils';
import './style.css';

const NewsContent = ({ data }) => (
  <div className="news-message-news">
    <Text className="news-message-news__title" variant={FONTS.body1Med}>
      {data.title}
    </Text>
    {!isNilOrEmpty(data.images) && (
      <img
        className="news-message-news__picture"
        src={data.images[0].url}
        alt=""
      />
    )}
    {!isNilOrEmpty(data.description) && (
      <Text className="news-message-news__description" variant={FONTS.body1Reg}>
        {data.description}
      </Text>
    )}
  </div>
);

export default NewsContent;
