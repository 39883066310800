export const DEFAULT_SCALE = 1;
export const DEFAULT_STEP = 0.25;
export const DEFAULT_MIN_SCALE = 1;
export const DEFAULT_MAX_SCALE = 2;
export const DEFAULT_POSITION_X = 0;
export const DEFAULT_POSITION_Y = 0;
export const DEFAULT_PANNING = {
  disable: true
};
export const DEFAULT_BOUNDS = {
  minPositionX: 0,
  maxPositionX: 0,
  minPositionY: 0,
  maxPositionY: 0
};
