import { useCallback } from 'react';
import { actions } from '../redux';
import * as emailMessagesStorage from '../storage';
import { useActions } from '../../../../../hooks/useActions';

const useFetchConnectedEmails = () => {
  const { setConnectedEmails } = useActions(actions);

  const fetchConnectedEmails = useCallback(async () => {
    try {
      const connectedEmails =
        await emailMessagesStorage.fetchAllConnectedEmails();
      setConnectedEmails(connectedEmails.emails);

      return connectedEmails.emails;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, []);

  return { fetchConnectedEmails };
};

export default useFetchConnectedEmails;
