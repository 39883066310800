import req from '../lib/request';
import { getLocationOrigin } from '../lib/goodwix';

export default {
  logout: () =>
    req.get('/logout').then(() => {
      window.location = process.env.UNICAT_ACCOUNTS_SERVICE;
    }),
  messengerLogout: () =>
    req.get('/logout').then(() => {
      window.location = `${getLocationOrigin()}/login`;
    }),
  getTableExcludedAttributes: () => req.get('/api/user/excluded_attributes'),
  setTableExcludedAttributes: (table, excluded) =>
    req.post('/api/user/excluded_attributes', { excluded, table }),
  myAccessRoles: () => req.get('/api/myAccessRoles'),
  isAuthorized: () => req.get('/api/currentUser/')
};
