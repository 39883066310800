const isCyrillicCharacter = (character) => /[а-яА-ЯЁё]/.test(character);
const isLatinCharacter = (character) => /[a-zA-Z]/.test(character);
const isDigitCharacter = (character) => /^\d+$/.test(character);

const isBothCyrillic = (characterA, characterB) =>
  isCyrillicCharacter(characterA) && isCyrillicCharacter(characterB);
const isBothLatin = (characterA, characterB) =>
  isLatinCharacter(characterA) && isLatinCharacter(characterB);
const isBothDigit = (characterA, characterB) =>
  isDigitCharacter(characterA) && isDigitCharacter(characterB);

const compare = (stringA, stringB, direction = 'up') => {
  const pos = 1;
  const neg = -1;

  // Case: strings are equals
  // ---------------------------------
  if (stringA === stringB) {
    return 0;
  }

  const stringAChunks = [...stringA];
  const stringBChunks = [...stringB];

  let i = 0;

  while (stringAChunks[i] && stringBChunks[i]) {
    const characterA = direction === 'up' ? stringAChunks[i] : stringBChunks[i];
    const characterB = direction === 'up' ? stringBChunks[i] : stringAChunks[i];

    if (characterA !== characterB) {
      // Case: both are in same diapason (Cyrillic, Latin, Digit)
      // ---------------------------------
      if (
        isBothCyrillic(characterA, characterB) ||
        isBothLatin(characterA, characterB) ||
        isBothDigit(characterA, characterB)
      ) {
        // Call built in compare method
        return characterA.localeCompare(characterB);
      }

      // Case: A - Cyrillic and B - Latin
      // ---------------------------------
      if (isCyrillicCharacter(characterA) && isLatinCharacter(characterB))
        return neg;

      // Case: A - Latin and B - Cyrillic
      // ---------------------------------
      if (isLatinCharacter(characterA) && isCyrillicCharacter(characterB))
        return pos;

      // Case: A - Cyrillic and B - Digit
      // ---------------------------------
      if (isCyrillicCharacter(characterA) && isDigitCharacter(characterB))
        return neg;

      // Case: A - Digit and B - Cyrillic
      // ---------------------------------
      if (isDigitCharacter(characterA) && isCyrillicCharacter(characterB))
        return pos;

      // Case: A - Latin and B - Digit
      // ---------------------------------
      if (isLatinCharacter(characterA) && isDigitCharacter(characterB))
        return neg;

      // Case: A - Digit and B - Latin
      // ---------------------------------
      if (isDigitCharacter(characterA) && isLatinCharacter(characterB))
        return pos;

      // Case: A - (Cyrillic, Latin, Digit) and B - !(Cyrillic, Latin, Digit)
      // ---------------------------------
      if (
        (isCyrillicCharacter(characterA) ||
          isLatinCharacter(characterA) ||
          isDigitCharacter(characterA)) &&
        !isCyrillicCharacter(characterB) &&
        !isLatinCharacter(characterB) &&
        !isDigitCharacter(characterB)
      )
        return neg;

      // Case: A - !(Cyrillic, Latin, Digit) and B - (Cyrillic, Latin, Digit)
      // ---------------------------------
      if (
        !isCyrillicCharacter(characterA) &&
        !isLatinCharacter(characterA) &&
        !isDigitCharacter(characterA) &&
        (isCyrillicCharacter(characterB) ||
          isLatinCharacter(characterB) ||
          isDigitCharacter(characterB))
      )
        return pos;

      // Case: another symbols
      // ---------------------------------
      i++;
    } else {
      // Case: symbols are equals
      // ---------------------------------
      i++;
    }
  }

  // Case: all letters are equals or words length is not enough to decide
  // ---------------------------------
  if (stringA.length < stringB.length) return neg;
  if (stringA.length > stringB.length) return pos;
  return 0;
};

module.exports.compare = compare;
