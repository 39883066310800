import {
  getPlacement,
  PlacementSides
} from '../../ChatWidget/Messenger/entities/placement';

export const subHeaderConfig = {
  contacts: {
    showSearch: true,
    showAdvancedSearch: false,
    showAddContact: false,
    showFolder: false
  },
  chats: {
    showSearch: true,
    showAdvancedSearch: false,
    showAddChat: true,
    showFolder: true,
    extraMenu: false,
    addChatDropdown: {
      list: [
        { icon: 'without', value: 'dialog' },
        { icon: 'without', value: 'channel' },
        { icon: 'without', value: 'topic-widget' }
      ]
    }
  },
  chatWidgetMain: {
    modes: {
      none: false,
      noInternet: true,
      chat: true,
      createTopic: false,
      editTopic: false
    },
    frame: {
      dashboard: false,
      topicPanel: true,
      messages: true,
      inputWrapper: true
    },
    topic: {
      theme: 'widget',
      buttonBack: true,
      avatar: true,
      title: true,
      avatarsList: false,
      topic: ['search', 'addMembers', 'favorite', 'delayed'],
      topicEdit: 'edit-widget'
    },
    dashboard: {
      theme: 'widget',
      buttonBack: true,
      avatar: true,
      title: true,
      avatarsList: false,
      dialog: ['viewProfile', 'search', 'favorite', 'delayed'],
      channel: ['search', 'addMembers', 'favorite', 'delayed'],
      purchaserequestschannel: ['favorite', 'notification', 'extra'],
      newsfeedchannel: ['extra']
    },
    messages: {
      theme: 'widget',
      showAvatar: false,
      config: {
        ownMessage: {
          extraBtn: {
            name: 'extra',
            list: [
              {
                conditionName: 'allowEdit?',
                items: [
                  {
                    icon: 'edit',
                    value: 'edit',
                    helper: 'chat_message_edit_help',
                    helperPosition: 'bottom'
                  },
                  {
                    icon: 'edit',
                    value: 'edit-disable',
                    color: 'inactive',
                    helper: 'chat_message_edit_help',
                    helperPosition: 'bottom'
                  }
                ]
              },
              { icon: 'reply', value: 'reply' },
              {
                icon: 'forward',
                value: 'forward-message'
              },
              { icon: 'star', value: 'favorite' },
              {
                conditionName: 'allowDelete?',
                items: [
                  { icon: 'delete', value: 'delete-message' },
                  {
                    icon: 'delete',
                    value: 'delete-message-disabled',
                    color: 'inactive'
                  }
                ]
              }
            ]
          },
          buttonList: [
            { value: 'reply', tooltip: 'reply-on-message' },
            { value: 'favorite', tooltip: 'add-to-favorite' }
          ],
          showReadMsg: true,
          placement: 'right',
          positions: {
            default: getPlacement(PlacementSides.Right),
            left: getPlacement(PlacementSides.Left)
          }
        },
        otherMessage: {
          extraBtn: {
            name: 'extra',
            list: [
              { icon: 'reply', value: 'reply' },
              {
                icon: 'forward',
                value: 'forward-message'
              },
              { icon: 'star', value: 'favorite' }
            ]
          },
          buttonList: [
            { value: 'reply', tooltip: 'reply-on-message' },
            { value: 'favorite', tooltip: 'add-to-favorite' }
          ],
          showReadMsg: false,
          placement: 'left',
          positions: {
            default: getPlacement(PlacementSides.Right),
            left: getPlacement(PlacementSides.Left)
          }
        },
        unsentMessage: {
          extraBtn: {
            name: 'extra',
            list: [{ icon: 'delete', value: 'delete-message' }]
          },
          buttonList: [],
          showReadMsg: false,
          placement: 'right',
          positions: {
            default: getPlacement(PlacementSides.Right),
            left: getPlacement(PlacementSides.Left)
          }
        },
        purchaseRequestMessage: {
          extraBtn: {
            name: 'extra',
            list: [
              {
                icon: 'forward',
                value: 'forward-message'
              },
              { icon: 'highlight', value: 'highlight-message' },
              { icon: 'star', value: 'favorite' }
            ]
          },
          buttonList: [
            { icon: 'star', value: 'favorite', tooltip: 'favorite' }
          ],
          showReadMsg: false,
          placement: 'left',
          positions: {
            default: getPlacement(PlacementSides.Left),
            left: getPlacement(PlacementSides.Left)
          }
        },
        newsMessage: {
          placement: 'left'
        }
      }
    },
    favorites: {
      theme: 'widget',
      commonDashboardTheme: 'widget-favorite',
      showAvatar: false,
      config: {
        ownMessage: {
          extraBtn: { name: 'go-to-msg', tooltip: 'go-to-msg' },
          buttonList: [
            { icon: 'star', value: 'favorite', tooltip: 'favorite' }
          ],
          showReadMsg: false,
          placement: 'right',
          positions: {
            default: getPlacement(PlacementSides.Right),
            left: getPlacement(PlacementSides.Left)
          }
        },
        otherMessage: {
          extraBtn: { name: 'go-to-msg', tooltip: 'go-to-msg' },
          buttonList: [
            { icon: 'star', value: 'favorite', tooltip: 'favorite' }
          ],
          showReadMsg: false,
          placement: 'left',
          positions: {
            default: getPlacement(PlacementSides.Right),
            left: getPlacement(PlacementSides.Left)
          }
        },
        unsentMessage: {
          extraBtn: [],
          buttonList: [],
          showReadMsg: false,
          placement: 'right',
          positions: {
            default: getPlacement(PlacementSides.Right),
            left: getPlacement(PlacementSides.Left)
          }
        },
        purchaseRequestMessage: {
          extraBtn: { name: 'go-to-msg', tooltip: 'go-to-msg' },
          buttonList: [
            { icon: 'star', value: 'favorite', tooltip: 'favorite' }
          ],
          showReadMsg: false,
          placement: 'left',
          positions: {
            default: getPlacement(PlacementSides.Left),
            left: getPlacement(PlacementSides.Left)
          }
        }
      }
    },
    delayedMessages: {
      theme: 'main-chat',
      commonDashboardTheme: 'main-chat',
      showAvatar: true,
      config: {
        ownMessage: {
          extraBtn: {
            name: 'extra',
            list: [
              { icon: 'edit', value: 'edit' },
              { icon: 'publish', value: 'publish' },
              { icon: 'delete', value: 'delete-message' }
            ]
          },
          buttonList: [],
          showReadMsg: false,
          position: 'left-start',
          possiblePositions: [
            'left-center',
            'top-center',
            'right-center',
            'bottom-center'
          ],
          placement: 'right',
          positions: {
            default: getPlacement(PlacementSides.Right),
            left: getPlacement(PlacementSides.Left)
          }
        }
      }
    },
    searchMsgs: {
      theme: 'widget',
      commonDashboardTheme: 'widget-search-msgs',
      showAvatar: false,
      config: {
        ownMessage: {
          extraBtn: { name: 'go-to-msg', tooltip: 'go-to-msg' },
          buttonList: [],
          showReadMsg: false,
          placement: 'right',
          positions: {
            default: getPlacement(PlacementSides.Right),
            left: getPlacement(PlacementSides.Left)
          }
        },
        otherMessage: {
          extraBtn: { name: 'go-to-msg', tooltip: 'go-to-msg' },
          buttonList: [],
          showReadMsg: false,
          placement: 'left',
          positions: {
            default: getPlacement(PlacementSides.Right),
            left: getPlacement(PlacementSides.Left)
          }
        },
        unsentMessage: {
          extraBtn: [],
          buttonList: [],
          showReadMsg: false,
          placement: 'right',
          positions: {
            default: getPlacement(PlacementSides.Right),
            left: getPlacement(PlacementSides.Left)
          }
        },
        purchaseRequestMessage: {
          extraBtn: [],
          buttonList: [],
          showReadMsg: false,
          placement: 'left',
          positions: {
            default: getPlacement(PlacementSides.Left),
            left: getPlacement(PlacementSides.Left)
          }
        }
      }
    },
    inputMessage: {
      theme: 'widget'
    }
  }
};

export const bodyConfig = {
  chatList: {
    extraMenu: {
      icon: 'extra',
      position: 'left-start',
      possiblePositions: ['left-center'],
      fixedMenuPos: false,
      type: 'widget'
    },
    type: 'widget'
  }
};
