import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './style.css';

const SearchBlock = ({
  placeholder = '',
  value = '',
  onChange = () => {},
  width = 300,
  className
}) => (
  <div
    className={cn('cw-search-block__container', className)}
    style={{ width }}>
    <img
      className="cw-search-block__magnifier"
      src="/img/cw-magnifier.svg"
      alt="magnifier"
    />
    <input
      className="cw-search-block__input"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);

SearchBlock.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.number
};

SearchBlock.defaultProps = {
  placeholder: '',
  value: '',
  onChange: () => {},
  width: 300
};

export default SearchBlock;
