import React from 'react';
import { connect } from 'react-redux';
import { getInterlocutor } from 'core/data/light/dialog';
import { useNavigate } from '@hooks';
import { mapStateToProps } from './getters';
import ListOfItemsModal from '../../Templates/ListOfItemsModal';
import Rows from './Rows';
import * as chatTypes from '../../../helpers/chatType';
import { isFunction } from '../../../../../utils/utils';

const CWModalChatsList = ({
  title,
  placeholder,
  mode,
  chats: _chats,
  user,
  online,
  onClick: click,
  onCancel
}) => {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState('');

  const initialChats = React.useMemo(() => {
    const isNotShow = (c) =>
      chatTypes.isNewsFeedChannel(c) || chatTypes.isPurchaseRequestsChannel(c);
    if (mode === 'dialog') {
      return _chats.filter((c) => chatTypes.isDialog(c) && !isNotShow(c));
    }
    if (mode === 'topic') {
      return _chats.filter((c) => chatTypes.isTopic(c) && !isNotShow(c));
    }
    if (mode === 'channel') {
      return _chats.filter((c) => chatTypes.isChannel(c) && !isNotShow(c));
    }
    if (mode === 'topicout') {
      return _chats.filter((c) => !chatTypes.isTopic(c) && !isNotShow(c));
    }

    return _chats.filter((c) => !isNotShow(c));
  }, [mode, _chats]);

  const chats = React.useMemo(() => {
    if (!search) return initialChats;
    const regExpSearch = new RegExp(search, 'i');
    return initialChats.filter((chat) => {
      if (chatTypes.isDialog(chat)) {
        const interlocutor = getInterlocutor(user.get('employeeId'), chat);
        return regExpSearch.test(interlocutor.userName);
      }

      return regExpSearch.test(chat.name);
    });
  }, [search, initialChats]);

  const onClick = React.useCallback(
    (chat) => {
      navigate(`/chat/${chat.id}`);

      if (isFunction(click)) {
        click(chat);
      }
    },
    [click, navigate]
  );

  const onSearch = React.useCallback(
    (search0) => {
      setSearch(search0.search);
    },
    [chats, mode]
  );

  return (
    <ListOfItemsModal
      title={title}
      placeholder={placeholder}
      mode={mode}
      search={search}
      searchItems={onSearch}
      items={chats}
      onCancel={onCancel}>
      <Rows chats={chats} user={user} online={online} onClick={onClick} />
    </ListOfItemsModal>
  );
};

export default connect(mapStateToProps, null)(CWModalChatsList);
