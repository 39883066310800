import { bindActionCreators } from 'redux';
import * as storage from '../storage';
import * as actions from '../actions';
import { getQuery, updateQuery } from '../../query';
// eslint-disable-next-line import/no-cycle
import { subscribeToUpdateChat } from '../../../useCases';
import { uploadChatsService } from './uploadChats';
import { chunkUploadChatsService } from './chunkUploadChats';
import { uploadArchivesService } from './uploadArchives';
import { chunkUploadArchivesService } from './chunkUploadArchives';
import { sortChatsService } from './sortChats';
import { cancelSortChatsService } from './cancelSortChats';
import { transformToChatModels } from '../transformData';
import { getChatInput, getChatTemp, getOpenedChat } from '../../../getter';
import { setDraftMessage } from '../../draftMessages/useCases';
import { setChatMode } from '../../chatMode';
import { showChatService } from './showChat';
import { applyState } from '../../utils';
import { getCurrentEmployeeId } from '../../../../../storeGetters';
import * as tempInputActions from '../../tempMessageInput/actions';
import * as inputActions from '../../messageInput/actions';
import * as messagesActions from '../../messages/actions';
import * as messagesServices from '../../messages/services';
import * as contactInfoStorage from '../../contactInfo/storage';
import * as dialogInfoActions from '../../dialogInfo/actions';
import * as chatCategoriesServices from '../../chatCategories/services';
import { unsubscribeFromUpdateChatService } from './unsubscribeFromUpdateChat';

export const sortChats =
  ({ chatsList, type, direction }) =>
  (dispatch, getState) =>
    sortChatsService(
      {
        getCurrentEmployeeId: applyState(getState, getCurrentEmployeeId)
      },
      { type, direction, chatsList }
    );

export const uploadChats = (newParams) => async (dispatch, getState) =>
  uploadChatsService(
    {
      reqGetChats: async (params) => storage.reqGetChats(getState(), params),
      getChatsQuery: () => getQuery('chats', getState()),
      updateChatsQuery: (updatedQuery) =>
        dispatch(updateQuery('chats', updatedQuery)),
      sortChats: (chatsList) => dispatch(sortChats({ chatsList })),
      addChats: (chats) => dispatch(actions.addChats(chats)),
      addLastUnreadMsgs: (unreadMessages) =>
        dispatch(actions.addLastUnreadMsgs(unreadMessages)),
      getChatGroups: () => dispatch(chatCategoriesServices.getChatGroups()),
      transformToChatModels
    },
    { newParams }
  );

export const chunkUploadChats =
  (newParams, withQueryUpdate) => async (dispatch, getState) =>
    chunkUploadChatsService(
      {
        reqGetChats: async (params) => storage.reqGetChats(getState(), params),
        getChatsQuery: () => getQuery('chats', getState()),
        updateChatsQuery: (updatedQuery) =>
          dispatch(updateQuery('chats', updatedQuery)),
        addChats: (chats) => dispatch(actions.addChats(chats)),
        addLastUnreadMsgs: (unreadMessages) =>
          dispatch(actions.addLastUnreadMsgs(unreadMessages)),
        transformToChatModels
      },
      { newParams, withQueryUpdate }
    );

export const uploadArchives = (newParams) => async (dispatch, getState) =>
  uploadArchivesService(
    {
      reqGetArchives: async (params) =>
        storage.reqGetArchives(getState(), params),
      getArchivesQuery: () => getQuery('archives', getState()),
      updateArchivesQuery: (updatedQuery) =>
        dispatch(updateQuery('archives', updatedQuery)),
      setArchives: (chats) => dispatch(actions.setArchives(chats)),
      getChatGroups: () => dispatch(chatCategoriesServices.getChatGroups()),
      transformToChatModels
    },
    { newParams }
  );

export const chunkArchivesChats = (newParams) => async (dispatch, getState) =>
  chunkUploadArchivesService(
    {
      reqGetChats: async (params) => storage.reqGetArchives(getState(), params),
      getChatsQuery: () => getQuery('archives', getState()),
      updateChatsQuery: () => updateQuery('archives', getState()),
      addArchives: (chats) => dispatch(actions.addArchives(chats)),
      transformToChatModels
    },
    { newParams }
  );

export const sortArchives = (type, direction) => async (dispatch, getState) =>
  sortChatsService(
    {
      getChatsQuery: () => getQuery('archives', getState()),
      updateChatsQuery: (updatedQuery) =>
        dispatch(updateQuery('archives', updatedQuery)),
      uploadChats: (params) => dispatch(uploadArchives(params))
    },
    { type, direction }
  );

export const cancelSortChats = () => async (dispatch, getState) =>
  cancelSortChatsService({
    getChatsQuery: () => getQuery('chats', getState()),
    updateChatsQuery: (updatedQuery) =>
      dispatch(updateQuery('chats', updatedQuery)),
    uploadChats: (params) => dispatch(uploadChats(params))
  });

export const cancelSortArchives = () => async (dispatch, getState) =>
  cancelSortChatsService({
    getChatsQuery: () => getQuery('archives', getState()),
    updateChatsQuery: (updatedQuery) =>
      dispatch(updateQuery('archives', updatedQuery)),
    uploadChats: (params) => dispatch(uploadArchives(params))
  });

export const unsubscribeFromUpdateChat = (chatId) => (dispatch) =>
  unsubscribeFromUpdateChatService(
    {
      unsubscribeFromNewMsg: storage.unsubscribeFromNewMsg,
      unsubscribeFromUpdateMsg: storage.unsubscribeFromUpdateMsg,
      unsubscribeFromAddUnreadMsg: storage.unsubscribeFromAddUnreadMsg,
      unsubscribeFromClearUnreadMsg: storage.unsubscribeFromClearUnreadMsg,
      unsubscribeFromRemoveMsg: storage.unsubscribeFromRemoveMsg,
      unsubscribeFromUpdateChannel: storage.unsubscribeFromUpdateChannel,
      leaveFromChannel: storage.leaveFromChannel,
      removeLastUnreadMsg: bindActionCreators(
        messagesActions.removeLastUnreadMsg,
        dispatch
      )
    },
    chatId
  );

export const showChat = (chat) => async (dispatch, getState) =>
  showChatService(
    {
      getChatInput: applyState(getState, getChatInput),
      getChatTemp: applyState(getState, getChatTemp),
      getOpenedChat: applyState(getState, getOpenedChat),
      getCurrentEmployeeId: applyState(getState, getCurrentEmployeeId),
      setDraftMessage: bindActionCreators(setDraftMessage, dispatch),
      setChatMode: bindActionCreators(setChatMode, dispatch),
      addOrUpdateTempInput: bindActionCreators(
        tempInputActions.addOrUpdateTempInput,
        dispatch
      ),
      setInputText: bindActionCreators(inputActions.setInputText, dispatch),
      removeInputFromTemp: bindActionCreators(
        tempInputActions.removeInputFromTemp,
        dispatch
      ),
      clearSelectedMessages: bindActionCreators(
        messagesActions.clearSelectedMessages,
        dispatch
      ),
      setOpenChat: bindActionCreators(actions.setOpenChat, dispatch),
      uploadMessages: bindActionCreators(
        messagesServices.uploadMessages,
        dispatch
      ),
      subscribeToUpdateChat: bindActionCreators(
        subscribeToUpdateChat,
        dispatch
      ),
      unsubscribeToUpdateChat: bindActionCreators(
        unsubscribeFromUpdateChat,
        dispatch
      ),
      reqContactInfo: contactInfoStorage.getContactInfo,
      setDialogInfo: bindActionCreators(
        dialogInfoActions.setDialogInfo,
        dispatch
      )
    },
    chat
  );
