import React, { useMemo } from 'react';
import { Wizard } from '@link/react-components';
import { useTranslate } from '@hooks';
import { useChooseSupplierWizard } from './useChooseSupplierWizard';
import styles from './ChooseSupplierWizard.module.css';
import {
  ChooseGroups,
  DeleteGroup,
  EditGroup,
  CreateGroup
} from './components';
import { getTranslate } from './utils';
import { STEPS } from './constants';
import { TitleCounter } from './components/titleCounter';

export function ChooseSupplierWizard({ chosenCompanies = [], onChoose }) {
  const t = useTranslate();
  const { close } = useChooseSupplierWizard();

  const steps = useMemo(
    () => [
      {
        key: STEPS.chooseGroups,
        displayName: getTranslate(t, 'chooseGroups.title'),
        extendedHeader: <TitleCounter />,
        mainContent: <ChooseGroups />
      },
      {
        key: STEPS.createGroup,
        displayName: getTranslate(t, 'createGroup.title'),
        mainContent: <CreateGroup />,
        extendedHeader: <TitleCounter />,
        previous: STEPS.chooseGroups
      },
      {
        key: STEPS.editGroup,
        displayName: getTranslate(t, 'editGroup.title'),
        mainContent: <EditGroup />,
        extendedHeader: <TitleCounter />,
        previous: STEPS.chooseGroups
      },
      {
        key: STEPS.deleteGroup,
        displayName: getTranslate(t, 'deleteGroup.title'),
        mainContent: <DeleteGroup />,
        previous: STEPS.chooseGroups
      }
    ],
    [t]
  );

  return (
    <Wizard
      open
      onClose={close}
      steps={steps}
      disableBackdropClick
      className={styles.wizard}
      initialState={{
        initialCompanies: chosenCompanies,
        onChoose,
        selectedCompanies: []
      }}
    />
  );
}
