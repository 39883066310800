import React, { useContext, useEffect, useRef, useState } from 'react';
import { SpinnerContext } from './SpinnerContext';

export const ThumbnailItem = ({
  page,
  pageIndex,
  pageHeight,
  pageWidth,
  thumbnailHeight,
  thumbnailWidth,
  onRenderCompleted
}) => {
  const [src, setSrc] = useState('');
  const { renderSpinner } = useContext(SpinnerContext);
  const renderTask = useRef();

  useEffect(() => {
    const task = renderTask.current;

    if (task) {
      task.cancel();
    }

    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d', { alpha: false });

    const w = thumbnailWidth;
    const h = w / (pageWidth / pageHeight);
    const scale = w / pageWidth;

    canvas.height = h;
    canvas.width = w;
    canvas.style.height = `${h}px`;
    canvas.style.width = `${w}px`;

    const viewport = page.getViewport({ scale });
    renderTask.current = page.render({ canvasContext, viewport });
    renderTask.current.promise.then(
      () => {
        setSrc(canvas.toDataURL());
        onRenderCompleted(pageIndex);
      },
      () => {
        onRenderCompleted(pageIndex);
      }
    );

    return () => {
      if (renderTask.current) {
        renderTask.current.cancel();
      }
    };
  }, []);

  if (!src) {
    return renderSpinner();
  }

  return (
    <img
      src={src}
      height={`${thumbnailHeight}%`}
      width={`${thumbnailWidth}%`}
      alt=""
    />
  );
};
