import { validateEmail } from '../../../utils/validateField';

function validateEmailString(email) {
  if (!email.length) return 'requestKPByEmail.notBeEmpty';
  if (!validateEmail(email)) return 'requestKPByEmail.invalidEmail';
  return '';
}

export function generateEmailErrorMsg(currentEmail, emails) {
  const errorMsg = validateEmailString(currentEmail.email);
  if (errorMsg) return errorMsg;
  const indexExistEmail = emails.findIndex(
    (emailEntity) =>
      emailEntity.email === currentEmail.email &&
      emailEntity.id !== currentEmail.id
  );

  if (indexExistEmail !== -1) return 'requestKPByEmail.existEmail';

  return '';
}

export function validate(emails) {
  return emails.map((emailEntity) => {
    const errorMessage = generateEmailErrorMsg(emailEntity, emails);
    return {
      ...emailEntity,
      errorMessage
    };
  });
}
