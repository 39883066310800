import { useEffect } from 'react';

/**
 * Hook that load data by BlockList
 *
 * @param {object} params
 * @param {object} params.blockList
 * @param {Function} params.onLoad
 * @param params.ref
 */
export default ({ ref, blockList, onLoad }) => {
  useEffect(() => {
    const element = ref.current;

    const loadOnScroll = () => {
      const { list, size } = blockList;

      if (list.length < size && element) {
        const isAtBottom =
          element &&
          Math.abs(
            element.scrollHeight - element.scrollTop - element.clientHeight
          ) <= 3.0;
        if (isAtBottom) {
          onLoad();
        }
      }
    };

    element.addEventListener('scroll', loadOnScroll);
    return () => element.removeEventListener('scroll', loadOnScroll);
  }, [ref, blockList]);
};
