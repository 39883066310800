/**
 * @typedef {string} MODERATION
 * @typedef {string} ACTIVATED
 * @typedef {string} EXCLUDED
 * @typedef {string} LEAVE
 * @typedef {string} NOT_PARTNER
 * @typedef {string} DELETED
 * @typedef {(ACTIVATED|EXCLUDED|LEAVE|NOT_PARTNER|DELETED|MODERATION)} MemberStatus
 */

const MODERATION = 'MODERATION';
exports.MODERATION = MODERATION;

const ACTIVATED = 'ACTIVATED';
exports.ACTIVATED = ACTIVATED;

const EXCLUDED = 'EXCLUDED';
exports.EXCLUDED = EXCLUDED;

const LEAVE = 'LEAVE';
exports.LEAVE = LEAVE;

const NOT_PARTNER = 'NOT_PARTNER';
exports.NOT_PARTNER = NOT_PARTNER;

const DELETED = 'DELETED';
exports.DELETED = DELETED;
