import React from 'react';
import { Form, Modal, Button, FormGroup } from 'react-bootstrap';

const SingleImgPage = ({
  hideModalDialog,
  goPrevPage,
  goNextPage,
  pages,
  currentPage,
  slideLength,
  t
}) => (
  <div>
    <Modal.Header>
      <Modal.Title>
        <span className="help-modal-title">
          {pages[currentPage] && pages[currentPage].title}
        </span>
        <span
          className="modal-header-cross pull-right"
          onClick={() => hideModalDialog()}
        />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form
        horizontal
        style={{
          whiteSpace: 'nowrap'
        }}>
        <FormGroup controlId="value" style={{ marginBottom: 0, width: '100%' }}>
          <div className="help-modal-text">
            {pages[currentPage] && pages[currentPage].text}
          </div>
          <div>
            <img
              className="help-modal-img"
              src={pages[currentPage].img && pages[currentPage].img[0]}
              role="presentation"
              alt=""
            />
          </div>
        </FormGroup>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      {(slideLength === currentPage || currentPage !== 0) && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button id="cancel" onClick={goPrevPage}>
            {`← ${t('Back')}`}
          </Button>
          <div>{`${currentPage}/${slideLength}`}</div>
          {slideLength !== currentPage ? (
            <Button id="nextButton" onClick={goNextPage}>
              {`${t('Next')} →`}
            </Button>
          ) : (
            <div style={{ width: 40 }} />
          )}
        </div>
      )}
    </Modal.Footer>
  </div>
);

export default SingleImgPage;
