import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AttachmentMenu from './AttachmentMenu';

const MAX_HEIGHT = 460;
const MAX_WIDTH = 460;
const HORIZONTAL_PADDING = 32;
const MAX_CONTENT_WIDTH = MAX_WIDTH - HORIZONTAL_PADDING;

const SinglePictureAttach = ({
  id,
  src = '',
  name = '',
  showImageViewer,
  menuProps,
  onLoad,
  disableMenu = false,
  offset = 10
  // filesData NOTE: it's if imagesCache don't work
}) => (
  <div
    className="chat-message__single-picture-container"
    style={{ paddingTop: offset }}>
    <SinglePictureAttachment
      id={id}
      src={src}
      alt={name}
      onClick={(e) => {
        e.stopPropagation();
        showImageViewer(id);
      }}
      onLoad={(e) => {
        onLoad(e, id);
      }}
      width="auto"
      height="auto"
    />
    {!disableMenu && <AttachmentMenu {...menuProps} />}
  </div>
);

SinglePictureAttach.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showImageViewer: PropTypes.func.isRequired,
  menuProps: PropTypes.object.isRequired,
  onLoad: PropTypes.func
};

SinglePictureAttach.defaultProps = {
  onLoad: () => {}
};

const SinglePictureAttachment = styled.img`
  display: block;
  max-width: ${MAX_CONTENT_WIDTH}px;
  width: 100%;
  max-height: ${MAX_HEIGHT}px;
  cursor: zoom-in;
`;

export default SinglePictureAttach;
