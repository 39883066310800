import React from 'react';
import { noop } from '../utils/utils';

export function useIsScrolling(ref, delay = 150) {
  const timer = React.useRef(null);
  const [isScrolling, setIsScrolling] = React.useState(false);

  React.useEffect(() => {
    if (!ref.current) {
      return noop;
    }

    const handleScrollEnd = () => {
      setIsScrolling(false);
    };

    const handleScroll = () => {
      setIsScrolling(true);

      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(handleScrollEnd, delay);
    };

    ref.current.addEventListener('scroll', handleScroll);

    return () => {
      ref.current.removeEventListener('scroll', handleScroll);

      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return isScrolling;
}
