import React, { Fragment, useState } from 'react';
import { getOwnerMember } from 'core/data/light/mainChannel';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Title from './Title';
import ProductsTable from './ProductsTable';
import Caption from './Caption';
import Files from './Files';
import Links from './Links';
import Footer from './Footer';
import * as useCase from '../../useCases';
import { haveEditPermission } from '../../roles';
import { getChatMode } from '../../getter';
import { convertFileUrls } from '../../modules/files/utils';
import {
  rework,
  isStatus
} from '../../../../presenters/sidebars/PurchaseRequestResponsesSidebar/status';

const checkHideFooter = (currentMode) => {
  const excludedFooterMods = ['favorites'];
  return excludedFooterMods.includes(currentMode);
};

const getMode = ({ products, responseStatus }) => {
  if (isStatus(rework, responseStatus)) return 'message';
  if (products.length > 0) return 'table';
  return 'none';
};

const Description = ({
  topic,
  user,
  mode,
  handleTopicDescription,
  isActive,
  config
}) => {
  const [minimized, setMinimized] = useState(true);
  const {
    date,
    title,
    desc,
    files = [],
    links = [],
    products = [],
    responseStatus
  } = topic;
  const owner = getOwnerMember(topic, user.get('employeeId'));
  const onEditClick = () => handleTopicDescription(config.topicEdit, topic);
  const isHideFooter = checkHideFooter(mode.currentMode);

  return (
    <>
      <Title
        date={date}
        title={title}
        minimized={minimized}
        theme={config.theme}
      />
      {!minimized && (
        <ProductsTable
          mode={getMode({ products, responseStatus })}
          products={products}
        />
      )}
      {!minimized && <Caption caption={desc} theme={config.theme} />}
      {!minimized && (
        <Files files={convertFileUrls(files)} theme={config.theme} />
      )}
      {!minimized && <Links links={links} theme={config.theme} />}
      {!isHideFooter && (
        <Footer
          type={topic.hardcodeType}
          owner={owner}
          isActive={isActive}
          minimized={minimized}
          onMaximize={() => setMinimized(false)}
          onMinimize={() => setMinimized(true)}
          onEditClick={onEditClick}
          canEdit={haveEditPermission(topic, user)}
          theme={config.theme}
        />
      )}
    </>
  );
};

Description.propTypes = {
  topic: PropTypes.shape({
    date: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
    files: PropTypes.array,
    owner: PropTypes.shape({
      avatar: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      companyName: PropTypes.string
    })
  }).isRequired
};

export default connect(
  (state) => ({ mode: getChatMode(state) }),
  (dispatch) => bindActionCreators(useCase, dispatch)
)(Description);
