import React, { useCallback } from 'react';
import CWMItemMessage from './components/Message';
import './style.css';

const ItemMessageContainer = ({
  t,
  message,
  chat,
  currentEmployeeId,
  internetConnection,
  PRCategoriesList,
  handler,
  isSelected,
  isRead,
  isPinned,
  isSelectMode,
  isForwardedCortege,
  config,
  onLoadedSingleImage,
  filesData,
  messagesPlacement,
  onClickByVideoChatLink
}) => {
  const handlerMsg = useCallback(
    (item, employeeId) => handler(item, chat, message, null, employeeId),
    [message]
  );

  return (
    <CWMItemMessage
      t={t}
      chat={chat}
      message={message}
      currentEmployeeId={currentEmployeeId}
      internetConnection={internetConnection}
      PRCategoriesList={PRCategoriesList}
      handlerMsg={handlerMsg}
      isSelected={isSelected}
      isSelectMode={isSelectMode}
      isRead={isRead}
      isPinned={isPinned}
      isForwardedCortege={isForwardedCortege}
      onLoadedSingleImage={onLoadedSingleImage}
      config={config}
      filesData={filesData}
      messagesPlacement={messagesPlacement}
      onClickByVideoChatLink={onClickByVideoChatLink}
    />
  );
};

export default React.memo(ItemMessageContainer);
