import { bindActionCreators } from 'redux';
import * as storage from './storage';
import { getChatBlock } from '../chats/getters';
import { applyState } from '../utils';
import { showChat } from '../../useCases';
import { prevChatMode } from '../chatMode';
import * as chatActions from '../chats/actions';
import { createGroupChatCase } from './useCases/create';
import { editGroupChatCase } from './useCases/edit';
import * as temporaryAction from '../../../../action-creators/temporary';
import * as modalAction from '../../../../action-creators/modal';
import { gaSendMessengerAction } from '../GA';
import {
  getTempExcludedMembers,
  getTempInvitedMembers,
  getTempMemberRoles
} from '../../../../storeGetters';
import { updateChannelListByCreateGroupChat } from '../chats/storage';

export const createGroupChat =
  (name, membersIds, roles) => async (dispatch, getState) =>
    createGroupChatCase(
      {
        reqCreateGroupChat: storage.reqCreateGroupChat,
        getChatList: applyState(getState, getChatBlock),
        prevChatMode: () => dispatch(prevChatMode()),
        setChats: (chats) => dispatch(chatActions.setChats(chats)),
        showChat: (chat) => dispatch(showChat(chat)),
        scktUpdateChatList: (chat) => updateChannelListByCreateGroupChat(chat)
      },
      { name, membersIds, roles }
    );

export const editGroupChat =
  ({ chat, name, readOnly, isShowPrevious }) =>
  async (dispatch, getState) =>
    editGroupChatCase(
      {
        getInviteMembers: applyState(getState, getTempInvitedMembers),
        getExcludeMembers: applyState(getState, getTempExcludedMembers),
        getMembersRoles: applyState(getState, getTempMemberRoles),
        updateGroupChat: storage.updateChannel,
        clearTemporary: bindActionCreators(
          temporaryAction.clearTemporary,
          dispatch
        ),
        onCloseModal: bindActionCreators(modalAction.hideModalDialog, dispatch),
        gaSend: bindActionCreators(gaSendMessengerAction, dispatch)
      },
      { chat, name, readOnly, isShowPrevious }
    );
