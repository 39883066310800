import React from 'react';

const ArrowNext = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="15"
    viewBox="0 0 8 15">
    <path
      fill="#767b92"
      transform="rotate(135 2.197 5.304) scale(.99998)"
      // eslint-disable-next-line max-len
      d="M0 0v-.5a.5.5 0 0 0-.5.5H0zm9.9.5a.5.5 0 1 0 0-1v1zM-.5 9.9a.5.5 0 1 0 1 0h-1zM0 .5h9.9v-1H0v1zm.5 9.4V0h-1v9.9h1z"
    />
  </svg>
);

export default ArrowNext;
