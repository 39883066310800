const { defStruct } = require('../../lib');

/**
 * @typedef DataPart - A Partial data
 */
const { makeDataPart, getAllIds, getPart, isDataPart, getTotalCount } =
  defStruct('DataPart', ['allIds', 'part', 'totalCount']);

/**
 * Create a partial data data type
 *
 * @param {object} params
 * @param {[number]} params.allIds
 * @param {[*]} params.part
 * @param {number} params.totalCount
 * @returns {DataPart}
 */
exports.makeDataPart = ({ allIds = [], part = [], totalCount = 0 } = {}) =>
  makeDataPart(allIds, part, totalCount);

/**
 * Gives all ids, which match all the data
 *
 * @param {DataPart} dataPart
 * @returns {[number]}
 */
exports.getAllIds = (dataPart) => getAllIds(dataPart);

/**
 * Gives a part data from DataPart
 *
 * @param {DataPart} dataPart
 * @returns {[*]}
 */
exports.getPart = (dataPart) => getPart(dataPart);

/**
 * Gives value part from part data of dataPart
 *
 * @param {number} id
 * @param {DataPart} dataPart
 * @returns {object}
 */
exports.getById = (id, dataPart) => getPart(dataPart).find((p) => p.id === id);

/**
 * Checks if is DataPart
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isDataPart = (a) => isDataPart(a);

/**
 * Gives a total size of all data
 *
 * @param {DataPart} dataPart
 * @returns {number}
 */
const getTotalSize = (dataPart) => getAllIds(dataPart).length;
exports.getTotalSize = getTotalSize;

/**
 * Checks if is dataPart is empty
 *
 * @param {DataPart} dataPart
 * @returns {boolean}
 */
exports.isEmpty = (dataPart) => getTotalSize(dataPart) === 0;

/**
 * Gives total count value
 *
 * @param {DataPart} dataPart
 * @returns {number}
 */
exports.getTotalCount = (dataPart) => getTotalCount(dataPart);
