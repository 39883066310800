import React from 'react';
import './style.styl';

const CWDashboardCommon = ({ children, theme }) => (
  <div className={`cw-dashboard-common cw-dashboard-common_theme_${theme}`}>
    {children}
  </div>
);

export default CWDashboardCommon;
