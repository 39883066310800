import React from 'react';
import CWTabsList from './list';
import './style.css';

function CWSTabs({ config, ...props }) {
  return (
    <div className="cws-tabs">
      <CWTabsList config={config} {...props} />
    </div>
  );
}

export default CWSTabs;
