import React from 'react';
import { isNil } from 'ramda';
import Tooltip from '../../../../Tooltip';
import { useScrollToEndButton } from '../../../chat-items/scroll';

const ScrollBottomButton = ({ onClick, scrollContainerRef }) => {
  useScrollToEndButton(scrollContainerRef, [isNil(scrollContainerRef.current)]);

  return (
    <button type="button" className="cw-btn-bottom" onClick={onClick}>
      <Tooltip text="to-bottom-btn" placement="left-center">
        <span className="cw-btn-bottom__text">Bottom</span>
      </Tooltip>
    </button>
  );
};

export default React.memo(ScrollBottomButton);
