import { useState } from 'react';

export default function useToggle() {
  const [isOpen, setOpen] = useState(false);

  return {
    isOpen,
    show: () => setOpen(true),
    hide: () => setOpen(false),
    toggle: () => setOpen(!isOpen)
  };
}
