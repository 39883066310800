import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

import './style.css';

const Select = ({
  value,
  options,
  onChange,
  multi,
  searchable,
  clearable,
  disabled,
  filterOptions
}) => (
  <ReactSelect
    className="modal-role-select"
    value={value}
    options={options}
    onChange={onChange}
    multi={multi}
    searchable={searchable}
    clearable={clearable}
    disabled={disabled}
    filterOptions={filterOptions}
  />
);

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  multi: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  filterOptions: PropTypes.func
};

Select.defaultProps = {
  value: '',
  onChange: () => {},
  multi: false,
  searchable: false,
  clearable: false,
  options: [],
  disabled: false,
  filterOptions: () => {}
};

export default Select;
