import React from 'react';

function IconFitInS() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        className="cws-icon-main-stroke"
        x="0.5"
        y="0.5"
        width="17"
        height="17"
        rx="8.5"
        stroke="white"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.94116 7.94141L4.76469 7.94141L4.76469 7.41199L7.41175 7.41199L7.41175 4.76494L7.94116 4.76494L7.94116 7.94141Z" // eslint-disable-line
        fill="white"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.94116 10.0586L4.76469 10.0586L4.76469 10.588L7.41175 10.588L7.41175 13.2351L7.94116 13.2351L7.94116 10.0586Z" // eslint-disable-line
        fill="white"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0588 7.94141L13.2353 7.94141L13.2353 7.412L10.5882 7.41199L10.5883 4.76494L10.0588 4.76494L10.0588 7.94141Z" // eslint-disable-line
        fill="white"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0588 10.0586L13.2353 10.0586L13.2353 10.588L10.5882 10.588L10.5882 13.2351L10.0588 13.2351L10.0588 10.0586Z" // eslint-disable-line
        fill="white"
      />
    </svg>
  );
}

export default IconFitInS;
