import React, { useMemo } from 'react';
import { isMsgSystem } from '../../../../data-type/core/isMsgSystem';
import PopUpSystemNotification from '../../../chat-system-notification';
import CWMItemUserMessage from '../../msg';
import { getTextMessage } from '../../../messages';
import SystemMessage from './SystemMessage';
import * as messageTypes from '../../entity/message';
import PurchaseRequestMessage from './PurchaseRequestMessage';
import NewsMessage from './NewsMessage';
import { getMessageDOMId } from '../../../chat-items/helpers';

const CWMItemMessage = ({
  t,
  chat,
  message,
  currentEmployeeId,
  internetConnection,
  PRCategoriesList,
  isSelected,
  isRead,
  isPinned,
  handlerMsg,
  isSelectMode,
  isForwardedCortege,
  onLoadedSingleImage,
  config,
  filesData,
  messagesPlacement
}) => {
  const isForwardedMessage = messageTypes.isForwardedMessage(message);
  const isReplyMessage = messageTypes.isReplyMessage(message);
  const isUnsentMessage = messageTypes.isUnsentMessage(message);
  const isUserMessage = messageTypes.isUserMessage(message);
  const isNewsMessage = messageTypes.isNewsMessage(message);
  const isSupportMessage = messageTypes.isSupportMessage(message);
  const isPurchaseRequestMessage =
    messageTypes.isPurchaseRequestMessage(message);
  const isRoomCreatedMessage = messageTypes.isMsgRoomCreated(message);
  const isPopupSystemNotification =
    messageTypes.isPopupSystemNotification(message);
  const isSystemMessage = isMsgSystem(message);

  const preparedMessage = useMemo(
    () => getTextMessage({ t, message, chat, handler: handlerMsg }),
    [message]
  );

  if (
    isUserMessage ||
    isForwardedMessage ||
    isReplyMessage ||
    isSupportMessage ||
    isUnsentMessage ||
    isRoomCreatedMessage
  ) {
    return (
      <CWMItemUserMessage
        t={t}
        chat={chat}
        message={preparedMessage}
        currentEmployeeId={currentEmployeeId}
        internetConnection={internetConnection}
        handlerMsg={handlerMsg}
        isSelected={isSelected}
        isRead={isRead}
        isPinned={isPinned}
        isSelectMode={isSelectMode}
        isForwardedCortege={isForwardedCortege}
        onLoadedSingleImage={onLoadedSingleImage}
        config={config}
        filesData={filesData}
        messagesPlacement={messagesPlacement}
      />
    );
  }

  if (isPurchaseRequestMessage) {
    return (
      <PurchaseRequestMessage
        t={t}
        id={getMessageDOMId(message.id)}
        currentEmployeeId={currentEmployeeId}
        message={message}
        chat={chat}
        PRCategoriesList={PRCategoriesList}
        config={config}
        messagesPlacement={messagesPlacement}
        onClick={handlerMsg}
        isSelected={isSelected}
        isSelectMode={isSelectMode}
      />
    );
  }

  if (isNewsMessage) {
    return (
      <NewsMessage
        id={getMessageDOMId(message.id)}
        message={message.data}
        isSelected={isSelected}
        isSelectMode={isSelectMode}
        config={config}
      />
    );
  }

  if (isSystemMessage) {
    return (
      <SystemMessage
        t={t}
        message={message}
        chat={chat}
        currentEmployeeId={currentEmployeeId}
      />
    );
  }

  if (isPopupSystemNotification) {
    return <PopUpSystemNotification />;
  }

  return null;
};

export default React.memo(CWMItemMessage);
