import React from 'react';
import PropTypes from 'prop-types';

import FilePreview from '../../../Channel/file/filePreview';

import './style.css';

const Files = ({ files = [], theme }) => {
  if (!(files && files.length > 0)) return null;

  return (
    <div className={`cw-topic__files cw-topic__files-theme-${theme}`}>
      {files.map((file) => (
        <FilePreview key={file.id} file={file} files={files} />
      ))}
    </div>
  );
};

Files.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Files;
