const NEWS_FEED_CHANNEL = 'NewsFeedChannel';

exports.NEWS_FEED_CHANNEL = NEWS_FEED_CHANNEL;

exports.isNewsFeedChannel = (channel = {}) =>
  NEWS_FEED_CHANNEL === channel.type;

/**
 * @typedef {import('../channelMember')} ChannelMember
 */

/**
 * @typedef NewsFeedChannel
 * @property {string} type
 * @property {string} id
 * @property {string} name
 * @property {string} channelId
 * @property {number} ownerEmployeeId
 * @property {string} lastMessage
 * @property {Date} createdAt
 * @property {ChannelMember[]} members
 * @property {boolean} archived
 * @property {string} firstUnreadMessageId
 * @property {string[]} unreadMessagesId
 * @property {number[]} notificationOffMembers
 * @property {boolean} notificationOff
 * @property {object} checkedLists
 */

/**
 * Creates a channel data type
 *
 * @param {object} params
 * @param {string} params.id
 * @param {string} params.name
 * @param {Date} params.createdAt
 * @param {number} params.ownerEmployeeId
 * @param {string} params.lastMessage
 * @param {ChannelMember[]} params.members
 * @param {boolean} params.archived
 * @param {string} params.firstUnreadMessageId
 * @param {string[]} params.unreadMessagesId
 * @param {number[]} params.notificationOffMembers
 * @param {boolean} params.notificationOff
 * @param {object} params.checkedLists
 * @returns {NewsFeedChannel}
 */
exports.makeNewsFeedChannel = ({
  id,
  name,
  ownerEmployeeId,
  lastMessage,
  members,
  archived,
  firstUnreadMessageId,
  unreadMessagesId = [],
  notificationOffMembers = [],
  notificationOff,
  createdAt,
  checkedLists
} = {}) => ({
  type: NEWS_FEED_CHANNEL,
  id,
  name,
  ownerEmployeeId,
  lastMessage,
  members,
  archived,
  firstUnreadMessageId,
  unreadMessagesId,
  notificationOffMembers,
  notificationOff,
  createdAt,
  checkedLists
});
