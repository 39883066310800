import { isMessengerService } from '../../../../../lib/goodwix';
import customHistory from '../../../../../customHistory';

function openBrowserTab(url) {
  return Object.assign(document.createElement('a'), {
    target: '_blank',
    href: url
  }).click();
}

function onSubmit(type) {
  if (type === 'increase') {
    openBrowserTab('/billing/info');
    return;
  }
  if (type === 'clear') {
    openBrowserTab('/file-storage/chat');
  }
}

function openFileStorage() {
  customHistory.push('/file-storage/chat');
}

export const showSpaceLimitNotificationCase = ({
  getBillingEditPermission,
  setMessage,
  showModal
}) => {
  function storageOversizeHandler() {
    const billingEditPermission = getBillingEditPermission();
    if (billingEditPermission) {
      showModal('CW_STORAGE_OVERSIZE', { onSubmit });
    } else {
      openBrowserTab('/file-storage/chat');
    }
  }

  if (isMessengerService()) {
    setMessage({
      key: 'm_storage_space_limit',
      linkKey: 'go_to_storage',
      linkClickHandler: openFileStorage
    });

    return;
  }

  setMessage({
    key: 'Storage space limit',
    linkClickHandler: storageOversizeHandler,
    linkKey: 'increase_storage'
  });
};
