import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslate } from '../../TranslateProvider';
import './style.css';

const DropForChat = ({
  children,
  onDrop,
  disabled,
  tkeyText = 'chat-widget.section.dnd.main-text',
  tkeySubText = 'chat-widget.section.dnd.sub-text'
}) => {
  const t = useTranslate();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    disabled
  });

  return (
    <div {...getRootProps()} className="dropzone-chat">
      <input {...getInputProps()} />
      <div
        className="dropzone-chat__wrapper-inner"
        style={{ display: isDragActive ? 'block' : 'none' }}>
        <InnerDropzone
          t={t}
          onDrop={onDrop}
          tkeyText={tkeyText}
          tkeySubText={tkeySubText}
        />
      </div>
      {children}
    </div>
  );
};

const InnerDropzone = ({ t, onDrop, tkeyText, tkeySubText }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true
  });

  return (
    <div className="dropzone-chat-inner">
      <div {...getRootProps()} className="dropzone-chat-inner__dropzone">
        <input {...getInputProps()} />
        <img
          className="dropzone-chat-inner__dropzone-img"
          src="/img/chat-widget/drag-and-drop.svg"
          alt="dnd-icon"
        />
        <div className="dropzone-chat-inner__dropzone-text">{t(tkeyText)}</div>
        <div className="dropzone-chat-inner__dropzone-sub-text">
          {t(tkeySubText)}
        </div>
      </div>
    </div>
  );
};

DropForChat.propTypes = {
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool
};

DropForChat.defaultProps = {
  disabled: false
};

InnerDropzone.propTypes = {
  t: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired
};

export default React.memo(DropForChat);
