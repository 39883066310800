import { bindActionCreators } from 'redux';
import { applyState } from '../../../../../modules/utils';
import { getTempMemberRoles } from '../../../../../../../storeGetters';
import { gaSendMessengerAction } from '../../../../../modules/GA';
import * as temporaryAction from '../../../../../../../action-creators/temporary';
import { onChangeMemberRoleCase } from './changeMemberRole';
import { removeMemberCase } from './removeMember';
import { getMemberRoleCase } from './getMemberRole';

export const onChangeMemberRole =
  ({ mode, member, role }) =>
  (dispatch) =>
    onChangeMemberRoleCase(
      {
        setMemberRole: bindActionCreators(
          temporaryAction.setToMapTemp('membersRoles'),
          dispatch
        ),
        gaSend: bindActionCreators(gaSendMessengerAction, dispatch)
      },
      { mode, member, role }
    );

export const removeMember = (member) => (dispatch) =>
  removeMemberCase(
    {
      addToExcludeMembers: bindActionCreators(
        temporaryAction.appendToSetTemp('excludeMembers'),
        dispatch
      ),
      removeInviteMembers: bindActionCreators(
        temporaryAction.removeFromSetTemp('inviteMembers'),
        dispatch
      )
    },
    { member }
  );

export const getMemberRole = (member) => (dispatch, getState) =>
  getMemberRoleCase(
    {
      getMembersRoles: applyState(getState, getTempMemberRoles)
    },
    { member }
  );
