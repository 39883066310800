import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import UserMessageLayout from '../../../layouts/UserMessageLayout';
import PurchaseRequestMsg from '../../../msg/viewPurchaseRequestMsg/msg';
import Footer from '../../MessageFooter';

const BACKGROUND_COLOR = 'gray-1';

const PurchaseRequestMessage = ({
  t,
  id,
  chat,
  message,
  currentEmployeeId,
  config,
  messagesPlacement,
  onClick,
  isSelected,
  isSelectMode,
  PRCategoriesList
}) => {
  const { avatarSize } = config.config;
  const { placement, buttonList } = config.config.purchaseRequestMessage;

  const menuOptions = useMemo(() => {
    const { extraBtn, positions } = config.config.purchaseRequestMessage;
    const { position, possiblePositions } = positions[messagesPlacement];

    if (extraBtn.name === 'go-to-msg') {
      return {
        type: 'regular',
        icon: 'arrow-right',
        tooltip: extraBtn.tooltip,
        onClick: () => onClick('go-to-msg')
      };
    }
    if (extraBtn.name === 'extra') {
      return {
        type: 'dropdown',
        icon: 'extra',
        onSelect: onClick,
        list: extraBtn.list,
        position,
        possiblePositions
      };
    }
    throw new Error('Not such condition');
  }, [JSON.stringify(config), onClick]);

  const avatar = useMemo(
    () => ({
      show: config.showAvatar,
      size: avatarSize,
      info: {
        type: 'company',
        subType: 'avatar-in-chat',
        src: message.customerLogo,
        alt: message.customerName
      },
      isCursorPointer: true
    }),
    [config.showAvatar, message.customerLogo, avatarSize]
  );

  const onMessageLineClick = useCallback(() => {
    if (isSelectMode) {
      onClick('highlight-message', chat, message);
    }
  }, [isSelectMode, chat, message]);

  return (
    <UserMessageLayout
      id={id}
      className={cn(`cwm-msg_theme-${config.theme}`, {
        selected: isSelected,
        'select-mode': isSelectMode
      })}
      bgColor={BACKGROUND_COLOR}
      defaultPlacement={placement}
      onClick={onMessageLineClick}
      avatar={avatar}
      menuOptions={menuOptions}
      content={
        <>
          <PurchaseRequestMsg
            t={t}
            message={message}
            theme={config.theme}
            categoriesList={PRCategoriesList}
          />
          <Footer
            t={t}
            currentEmployeeId={currentEmployeeId}
            message={message}
            onClick={onClick}
            buttonList={buttonList}
            isUnsent={false}
          />
        </>
      }
      isSelected={isSelected}
    />
  );
};

export default React.memo(PurchaseRequestMessage);
