import React from 'react';
import styled from 'styled-components';
import IconBack from './Icon';

const CWMButtonBack = ({ onClick }) => (
  <Btn onClick={onClick}>
    <IconBack />
  </Btn>
);

export default CWMButtonBack;

const Btn = styled.div`
  display: flex;
  cursor: pointer;
  height: 48px;
  width: 16px;

  &:hover {
    background: rgba(118, 123, 146, 0.15);
  }
`;
