import React, { useCallback, useState } from 'react';
import { isEmpty, isNil } from 'ramda';
import { usePrevious, useUpdateEffect } from 'react-use';
import { useLayer } from 'react-laag';
import DatePicker, { registerLocale } from 'react-datepicker';

import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';

import ControlPanel from './components/ControlPanel';
import ToggleAllDay from './components/ToggleAllDay';
import { isFirstDateGT, dateOrNull, isEqualDate } from '../../utils';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

registerLocale('ru', ru);
registerLocale('en', en);

const parseDateMask = (str, separator = '.') => {
  const [day, month, year] = str.split(separator);
  return new Date(year, month - 1, day);
};

function useRangeDate(defaultValue) {
  const [rangeDate, setRangeDate] = useState(defaultValue);

  const handleChange = useCallback((dataOrCb) => {
    setRangeDate((prevState) => {
      let newState;

      if (typeof dataOrCb === 'function') {
        newState = dataOrCb(prevState);
      } else {
        const { start, end } = dataOrCb;

        newState = {
          ...prevState,
          start: dateOrNull(start),
          end: dateOrNull(end)
        };
      }

      const { start, end } = newState;
      if (!isNil(start) && !isNil(end) && isFirstDateGT(start, end)) {
        if (isEqualDate(start, prevState.start)) {
          newState = { ...newState, end: start };
        } else {
          newState = { ...newState, start: end };
        }
      }

      return newState;
    });
  }, []);

  return [rangeDate, handleChange];
}

// NOTE This component is too difficult need some refactoring
function RangeDateTimePicker({
  triggerComponent,
  startDate,
  endDate,
  maskSeparator = '.',
  language = 'ru',
  onChange,
  onApply: applyCb,
  onReset: resetCb,
  onFocus: focusCb,
  onClickOutside: clickOutsideCb,
  isOpen = false,
  overflowContainer = true,
  placement = 'bottom-center',
  preferX,
  preferY,
  zIndex = 1051,
  isCloseAfterSubmitted = true,
  isCloseAfterClear = false,
  isCloseAfterDisappear = true,
  isCloseAfterClickOutside = true,
  disabled = false
}) {
  const [isShow, setIsShow] = useState(isOpen);
  const [isActiveStart, setIsActiveStart] = useState(true);
  const [isAllDay, setIsAllDay] = useState(false);
  const [rangeDate, setRangeDate] = useRangeDate({
    start: startDate,
    end: endDate
  });
  const prevRangeDate = usePrevious(rangeDate);

  useUpdateEffect(() => {
    if (
      typeof onChange === 'function' &&
      (!isEqualDate(prevRangeDate.start, rangeDate.start) ||
        !isEqualDate(prevRangeDate.end, rangeDate.end))
    ) {
      onChange(rangeDate);
    }
  }, [rangeDate]);

  useUpdateEffect(() => {
    setRangeDate({ start: startDate, end: endDate });
  }, [startDate, endDate]);

  function getDate() {
    return isActiveStart ? rangeDate.start : rangeDate.end;
  }

  function onChangeDate(_rangeDate) {
    const [start, end] = _rangeDate;

    const newRange = { start, end };
    setRangeDate(newRange);
    if (start && !end) setIsActiveStart(false);
    if (typeof onChange === 'function') onChange(newRange);
  }

  function changeDate(prevDate, newDate) {
    if (!newDate) return null;

    const transformedNewDate = parseDateMask(newDate, maskSeparator);
    if (!prevDate) return transformedNewDate;

    const updatedDate = new Date(prevDate);
    updatedDate.setFullYear(
      transformedNewDate.getFullYear(),
      transformedNewDate.getMonth(),
      transformedNewDate.getDate()
    );

    return updatedDate;
  }

  function onInputDate(date, target) {
    if (target.id === 'start-date') {
      const newStart = changeDate(rangeDate.start, date);

      setRangeDate((prevState) => ({ ...prevState, start: newStart }));
    } else {
      const newEnd = changeDate(rangeDate.end, date);

      setRangeDate((prevState) => ({ ...prevState, end: newEnd }));
    }
  }

  function onInputTime(type = 'start-time') {
    return (date) => {
      if (type === 'start-time') {
        setRangeDate((prevState) => ({ ...prevState, start: date }));
      } else {
        setRangeDate((prevState) => ({ ...prevState, end: date }));
      }
    };
  }

  function onChangeActiveTab(event) {
    if (event.target.id === 'start-date') {
      setIsActiveStart(true);
    } else {
      setIsActiveStart(false);
    }

    if (typeof focusCb === 'function') focusCb(event);
  }

  function toggleAllDay() {
    if (!isAllDay) {
      const day = rangeDate.start || rangeDate.end;

      const start = new Date(day).setHours(0, 0, 0, 0);
      const end = new Date(day).setHours(23, 59, 0, 0);

      setRangeDate({ start, end });
    } else {
      setRangeDate((prevState) => ({ ...prevState, end: null }));
    }

    setIsAllDay(!isAllDay);
  }

  function isAllDayToggleDisabled() {
    return isRangeEmpty();
  }

  function isRangeEmpty() {
    return isNil(rangeDate.start) && isNil(rangeDate.end);
  }

  function onToggleShown() {
    if (disabled) return;

    setIsShow((prevState) => !prevState);
  }

  function onHide() {
    setIsShow(false);
  }

  function onKeyDown(event) {
    switch (event.key) {
      case 'Escape':
        setIsShow(false);
        break;
      case 'Enter':
        if (!isRangeEmpty()) {
          onApply();
        }
        break;
      default:
        break;
    }
  }

  function onApply() {
    if (typeof applyCb === 'function') {
      applyCb(rangeDate);
    }

    if (isCloseAfterSubmitted) onHide();
  }

  function onReset() {
    const clearedRange = { start: null, end: null };

    setRangeDate(clearedRange);
    if (typeof resetCb === 'function') {
      resetCb(clearedRange);
    }

    if (isCloseAfterClear) onHide();
  }

  function onOutsideClick() {
    if (typeof clickOutsideCb === 'function') {
      clickOutsideCb();
    }

    if (isCloseAfterClickOutside) {
      onHide();
    }
  }

  const dateMask = `dd${maskSeparator}mm${maskSeparator}yyyy`;

  const { layerProps, triggerProps, renderLayer } = useLayer({
    isOpen: isShow,
    placement,
    preferX,
    preferY,
    auto: true,
    fixed: true,
    overflowContainer,
    triggerOffset: 0,
    onOutsideClick,
    onDisappear: isCloseAfterDisappear ? onHide : undefined
  });

  const getHighlightDates = () => {
    const result = [];

    if (!isNil(rangeDate.start)) {
      result.push(new Date(rangeDate.start));
    }

    if (!isNil(rangeDate.end)) {
      result.push(new Date(rangeDate.end));
    }

    // Set custom class for dates in range
    return !isEmpty(result)
      ? [{ 'react-datepicker__day--in-range': result }]
      : result;
  };

  return (
    <div className="range-datetime-picker-container" onKeyDown={onKeyDown}>
      <div
        className="range-datetime-picker-trigger-component"
        onClick={onToggleShown}
        {...triggerProps}>
        {triggerComponent}
      </div>
      {isShow &&
        renderLayer(
          <div ref={layerProps.ref} style={{ ...layerProps.style, zIndex }}>
            <div className="range-datetime-picker">
              <div className="range-datetime-picker__left">
                <DatePicker
                  locale={language}
                  selected={getDate()}
                  startDate={rangeDate.start}
                  endDate={rangeDate.end}
                  onChange={onChangeDate}
                  highlightDates={getHighlightDates()}
                  disabledKeyboardNavigation
                  selectsRange
                  inline
                />
                <ToggleAllDay
                  value={isAllDay}
                  onClick={toggleAllDay}
                  disabled={isAllDayToggleDisabled()}
                />
              </div>
              <div className="range-datetime-picker__hr" />
              <div className="range-datetime-picker__right">
                <ControlPanel
                  dateMask={dateMask}
                  rangeDate={rangeDate}
                  isActiveStart={isActiveStart}
                  isAllDayChecked={isAllDay}
                  onInputDate={onInputDate}
                  onInputTime={onInputTime}
                  onChangeActiveTab={onChangeActiveTab}
                  onApply={onApply}
                  onReset={onReset}
                  onFocus={focusCb}
                />
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default RangeDateTimePicker;
