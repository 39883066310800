import { haveActivatedMS } from 'core/data/light/channelMember';
import {
  isAdmin as checkIsAdmin,
  isSupport as checkIsSupport
} from '../../../../../../../lib/goodwix';

export const isAdmin = (interlocutor) =>
  checkIsAdmin(+interlocutor.employeeId) ||
  checkIsSupport(+interlocutor.employeeId);

export const getColor = (chatType, interlocutor) =>
  isAdmin(chatType, interlocutor) ? 'blue' : '';

export const getCountActiveMembers = (members) =>
  members.filter(haveActivatedMS).length;
