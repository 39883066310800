import request from '../lib/request';
import { setSuccessMessage } from './message';

export const getToken = () => (dispatch) =>
  request
    .get('/api/partners/token')
    .then((payload) => {
      dispatch({ type: 'PARTNERS:SET_TOKEN', payload });
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: 'PARTNERS:CLEAR_TOKEN' });
    });

export const refreshToken = () => (dispatch) =>
  request
    .get('/api/partners/token/refresh')
    .then((payload) => {
      dispatch({ type: 'PARTNERS:SET_TOKEN', payload });
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: 'PARTNERS:CLEAR_TOKEN' });
    });

export const validateToken = (token) => (dispatch) =>
  request
    .post('/api/partners/token/validate', { token })
    .then((payload) => {
      dispatch({ type: 'PARTNERS:SET_TOKEN_VALIDATION', payload });
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: 'PARTNERS:CLEAR_TOKEN_VALIDATION' });
    });

export const sendLinkToEmail =
  ({ email, link }) =>
  (dispatch) => {
    request.post('/api/partners/send-link', { email, link }).then(() =>
      dispatch(
        setSuccessMessage({
          key: 'Invite was sent to email',
          params: { email }
        })
      )
    );
  };
