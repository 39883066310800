import React from 'react';
import './style.css';

const CWSDashboard = ({ left, right }) => (
  <div className="cws-dashboard">
    <div className="cws-dashboard__left">{left}</div>
    <div className="cws-dashboard__right">{right}</div>
  </div>
);

export default CWSDashboard;
