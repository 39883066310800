export const editGroupChatCase = (
  {
    getInviteMembers,
    getExcludeMembers,
    getMembersRoles,
    updateGroupChat,
    clearTemporary,
    onCloseModal,
    gaSend
  },
  { chat, name, readOnly = false, isShowPrevious = false }
) => {
  if (!readOnly) {
    const inviteMembers = getInviteMembers();
    const excludeMembers = getExcludeMembers();
    const membersRoles = getMembersRoles();

    updateGroupChat(
      chat.id,
      {
        name,
        excludeMembers,
        inviteMembers,
        membersRoles: Object.entries(membersRoles),
        isShowPrevious
      },
      chat.type
    );

    if (chat.type === 'Channel') gaSend('group_chat_edit');
    if (chat.type === 'Topic') gaSend('topic_edit');
  }
  clearTemporary();
  onCloseModal();
};
