import { makeUserAccessRoles } from 'core/data/userAccessRoles';
import {
  USER_ACCESS_ROLES_SET,
  USER_ACCESS_ROLES_CLEAR
} from '../actionTypes/userAccessRoles';

const defaultValue = () => makeUserAccessRoles();

const reducers = {
  [USER_ACCESS_ROLES_SET]: (state, userAccessRoles) => userAccessRoles,
  [USER_ACCESS_ROLES_CLEAR]: defaultValue
};

export default (state = defaultValue(), { type, payload }) => {
  if (reducers[type]) return reducers[type](state, payload);
  return state;
};
