export function removeUndefinedProps(object) {
  const _object = { ...object };

  Object.keys(_object).forEach((key) => {
    if (_object[key] && typeof _object[key] === 'object') {
      removeUndefinedProps(_object[key]);
    } else if (_object[key] === undefined) {
      delete _object[key];
    }
  });

  return _object;
}
