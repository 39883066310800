import { bindActionCreators } from 'redux';
import { pipe, flip } from 'ramda';
import { applyState } from '../utils';
import { getOpenedChat, getChatSearchMsgs } from '../../getter';
import {
  writeLoad,
  searchQuery,
  from,
  to,
  limit,
  offset,
  zeroOffset,
  nextOffset,
  setDateRange
} from '../../../../lib/query';
import { findMessages } from './storage';
import { SET_SEARCH_MESSAGES } from '../../constants';

const setSearchMessages = ({ list, amountLeft }) => ({
  type: SET_SEARCH_MESSAGES,
  payload: { list, amountLeft }
});

export const transformToServerQuery = (query) => ({
  queryText: searchQuery(query),
  fromDate: from(query),
  toDate: to(query),
  offset: offset(query),
  limit: limit(query)
});

const commonCase =
  ({ tranformQuery, tranformItems = (response) => response }) =>
  ({
    getCurrentChat,
    getQuery,
    saveQuery,
    reqFindMessages,
    getMessages,
    saveMessages
  }) =>
  async (data) => {
    const chat = getCurrentChat();
    const updatedQuery = tranformQuery(getQuery(), data);

    const response = await reqFindMessages(
      chat.id,
      transformToServerQuery(updatedQuery)
    );
    const messages = getMessages();
    saveMessages(tranformItems(response, messages));
    saveQuery(writeLoad(updatedQuery));
  };

export const searchMsgsCase = commonCase({ tranformQuery: zeroOffset });
export const loadMoreMsgsCase = commonCase({
  tranformQuery: nextOffset,
  tranformItems: (response, messages) => ({
    list: messages.list.concat(response.list),
    amountLeft: response.amountLeft
  })
});

export const searchMsgsWithDateRangeCase = commonCase({
  tranformQuery: flip(pipe(setDateRange, zeroOffset))
});

// eslint-disable-next-line
const makeUseCases = ({
  getCurrentId,
  getQuery,
  saveQuery,
  reqFindItems,
  getItems,
  saveItems,
  mergeItems
}) => ({
  getCurrentId,
  getQuery,
  saveQuery,
  reqFindItems,
  getItems,
  saveItems,
  mergeItems
});

// eslint-disable-next-line
const firstLoad = async ({
  getCurrentId,
  getQuery,
  saveQuery,
  reqFindItems,
  saveItems
}) => {
  const id = getCurrentId();
  const query = getQuery();

  const response = await reqFindItems(id, query);
  saveItems(response);
  saveQuery(writeLoad(query));
};

// eslint-disable-next-line
const loadMergeNextPage = async ({
  getCurrentId,
  getQuery,
  saveQuery,
  reqFindItems,
  getItems,
  saveItems,
  mergeItems
}) => {
  const id = getCurrentId();
  const updatedQuery = nextOffset(getQuery());

  const response = await reqFindItems(id, updatedQuery);
  const items = getItems();
  saveItems(mergeItems(response, items));
  saveQuery(updatedQuery);
};

// eslint-disable-next-line
const loadWithFilter =
  (transformQuery) =>
  async (
    filters,
    { getCurrentId, getQuery, saveQuery, reqFindItems, saveItems }
  ) => {
    const id = getCurrentId();
    const updatedQuery = transformQuery(filters, getQuery());

    const response = await reqFindItems(id, updatedQuery);
    saveItems(response);
    saveQuery(updatedQuery);
  };

const bindMethod =
  (fn) =>
  ({ getQuery, saveQuery }, data) =>
  async (dispatch, getState) =>
    fn({
      reqFindMessages: findMessages,
      getCurrentChat: applyState(getState, getOpenedChat),
      getQuery,
      saveQuery,
      getMessages: applyState(getState, getChatSearchMsgs),
      saveMessages: bindActionCreators(setSearchMessages, dispatch)
    })(data);

export const searchMsgs = bindMethod(searchMsgsCase);
export const loadMoreMsgs = bindMethod(loadMoreMsgsCase);
export const searchMsgsWithDateRange = bindMethod(searchMsgsWithDateRangeCase);
