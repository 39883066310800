import React from 'react';
import Text from 'react-components/src/components/Typography';
import { useTranslate } from '../../../../../../../TranslateProvider';

const PurchaseRequestChannelInfo = ({ chatName }) => {
  const t = useTranslate();

  return (
    <div className="cw-chat-card-info">
      <div className="cw-chat-card-info__chat-title">
        <Text
          level={1}
          weight={2}
          lineHeight={2}
          className="cw-chat-card-info__name">
          {t(chatName)}
        </Text>
      </div>
    </div>
  );
};

export default PurchaseRequestChannelInfo;
