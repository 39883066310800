import React from 'react';
import cn from 'classnames';
import { DEFAULT_MAX_MSG_LENGTH } from '../../../../../constants';
import styles from './Counter.module.css';

export const Counter = ({ amount, limit = DEFAULT_MAX_MSG_LENGTH }) => (
  <div
    className={cn(styles.counter, {
      [styles.counterLimit]: amount > limit
    })}>
    {`${amount}/${limit}`}
  </div>
);
