import React from 'react';
import PropTypes from 'prop-types';

const GridPageHeader = ({ children }, { b }) => (
  <div className={b('header')}>{children}</div>
);

GridPageHeader.contextTypes = {
  b: PropTypes.func
};

export default GridPageHeader;
