import React from 'react';

/* eslint-disable max-len */
function IconChat() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-stroke"
        d="M13.7845 23.6509L13.5843 23.5566L13.3798 23.6414L9.70438 25.1664L10.6302 21.1338L10.6756 20.9359L10.5702 20.7623C9.89075 19.6441 9.5 18.3346 9.5 16.9333C9.5 12.832 12.8539 9.5 17 9.5C21.1461 9.5 24.5 12.832 24.5 16.9333C24.5 21.0347 21.1461 24.3667 17 24.3667C15.8481 24.3667 14.7583 24.1096 13.7845 23.6509Z"
        stroke="#767B92"
      />
      <line
        className="cws-icon-main-stroke"
        x1="14"
        y1="15.5"
        x2="20"
        y2="15.5"
        stroke="#767B92"
      />
      <line
        className="cws-icon-main-stroke"
        x1="14"
        y1="18.5"
        x2="20"
        y2="18.5"
        stroke="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconChat;
