const cachedPages = new Map();

export const getPage = (doc, pageIndex) => {
  if (!doc) {
    return Promise.reject(new Error('The document is not loaded yet'));
  }

  const pageKey = `${doc.loadingTask.docId}__${pageIndex}`;

  if (cachedPages.has(pageKey)) {
    const page = cachedPages.get(pageKey);
    return Promise.resolve(page);
  }

  return new Promise((resolve) => {
    doc.getPage(pageIndex + 1).then((page) => {
      cachedPages.set(pageKey, page);
      // если что искать тут

      resolve(page);
    });
  });
};

export const clearPagesCache = () => {};
