import React from 'react';
import { connect } from 'react-redux';
import { isType } from 'core/lib';
import { isEmpty, isNil } from 'ramda';
import { useTranslate } from '../../../../../TranslateProvider';
import Msg from './msg';
import { getOpenedChat } from '../../../getter';
import { getTextMessage } from '../../messages';
import CreatedRoomMsg from '../components/Message/CreatedRoomMessage';

const getMessage = ({ t, chat, message }) => {
  if (isType('MsgVideoRoomWasCreated', message)) {
    return {
      ...message,
      msg: <CreatedRoomMsg chat={chat} message={message} />
    };
  }
  return getTextMessage({ t, message, chat });
};

const CWMReplyMessage = ({
  show = true,
  message,
  type = 'own',
  chat,
  theme = 'mainChat',
  onClick
}) => {
  const t = useTranslate();

  if (show === false) return null;

  if (!message) {
    return (
      <div className={`cwm-reply-message__block ${type}`}>
        <div className="cwm-reply-message__msg-deleted">
          {t('The message was deleted')}
        </div>
      </div>
    );
  }

  const msg = getMessage({ t, message, chat });
  const haveFiles = !isNil(msg.files) && !isEmpty(msg.files);

  return (
    <div className={`cwm-reply-message__block ${type}`}>
      <div className="cwm-reply-message__inner">
        <div className="cwm-reply-message__vertical-divider" />
        <div
          className="cwm-reply-message__text-container"
          onClick={() => onClick('move-to-reply')}>
          <div className="cwm-reply-message__author">{msg.userName}</div>
          <div className="cwm-reply-message__content-wrapper">
            {haveFiles && (
              <img
                src="/img/chat-widget/attach.svg"
                className="cwm-reply-message__attach-icon"
                role="presentation"
                alt=""
              />
            )}
            <Msg chat={chat} message={msg} theme={theme} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state) => ({
  chat: getOpenedChat(state),
  user: state.getIn(['user', 'user'])
}))(CWMReplyMessage);
