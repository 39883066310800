import { safetyServiceWorkerPostMessage } from '../../../../utils';

let notificationOffTimeout = null;

export const activeChatNotification = (chatId) => {
  const clearNotificationTimeout = () => {
    clearTimeout(notificationOffTimeout);
    notificationOffTimeout = null;
  };

  safetyServiceWorkerPostMessage({
    type: 'CLEAR_NOTIFICATIONS',
    chatId
  });
  clearNotificationTimeout();

  const sendSWActiveChatId = () => {
    safetyServiceWorkerPostMessage({
      type: 'SET_ACTIVE_CHAT_ID',
      chatId
    });
  };

  const clearSWActiveChat = () => {
    clearNotificationTimeout();
    safetyServiceWorkerPostMessage({
      type: 'SET_ACTIVE_CHAT_ID',
      chatId: null
    });
  };

  if (!notificationOffTimeout) {
    sendSWActiveChatId();
  }
  clearTimeout(notificationOffTimeout);
  notificationOffTimeout = setTimeout(clearSWActiveChat, 60 * 1000);
};
