const {
  isEmpty,
  append,
  findIndex: findIndexR,
  propEq,
  remove,
  curry,
  includes
} = require('ramda');
const { defStruct } = require('../../lib');
const {
  makeOr,
  makeEqualToOneOf,
  makeILike,
  getWhereParams,
  isOr,
  setField
} = require('../whereParamItem');

/**
 * @typedef WhereParams
 * @typedef {import('../whereParamItem')} WhereParamItem
 */
const {
  makeWhereParams: makeWhereParamList,
  getList,
  isWhereParams
} = defStruct('WhereParams', ['list']);

const findIndex = (field, list) => findIndexR(propEq('field', field), list);

const getField = (whereParamItem) => whereParamItem.field;

/**
 * Adds where param item to where params
 *
 * @param {WhereParamItem} whereParamItem
 * @param {WhereParams} whereParams
 * @returns {WhereParams}
 */
const addParam = curry((whereParamItem, whereParams) => {
  const list = getList(whereParams);
  const index = findIndex(getField(whereParamItem), list);
  if (index === -1) return makeWhereParamList(append(whereParamItem, list));

  return makeWhereParamList(append(whereParamItem, remove(index, 1, list)));
});
exports.addParam = addParam;

/**
 * Creates a where params data type
 *
 * @param {[WhereParamItem]} list
 * @returns {WhereParams}
 */
function makeWhereParams(list = []) {
  return list.reduce(
    (acc, whereParamsItem) => addParam(whereParamsItem, acc),
    makeWhereParamList([])
  );
}
exports.makeWhereParams = makeWhereParams;

/**
 * Gives list from where params
 *
 * @param {WhereParams} whereParams
 * @returns {[WhereParamItem]}
 */
exports.getList = (whereParams) => getList(whereParams);

/**
 * Checks if is where params
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isWhereParams = (a) => isWhereParams(a);

/**
 * Renames a filed name at where param item
 *
 * @param {string} oldName
 * @param {string} newName
 * @param {WhereParams} whereParams
 * @returns {WhereParams}
 */
const renameField = curry((oldName, newName, whereParams) => {
  let newList = getList(whereParams);

  const orList = newList.filter(isOr);
  if (!isEmpty(orList)) {
    newList = newList.map((whereParamItem) => {
      if (isOr(whereParamItem)) {
        return makeOr(
          renameField(oldName, newName, getWhereParams(whereParamItem))
        );
      }
      return whereParamItem;
    });
  }

  const index = findIndex(oldName, newList);

  if (index === -1) return makeWhereParams(newList);

  const whereParamItem = newList[index];

  return addParam(
    setField(newName, whereParamItem),
    makeWhereParams(remove(index, 1, newList))
  );
});
exports.renameField = renameField;

/**
 * Pikes where param item by field name
 *
 * @param {[string]} list
 * @param {WhereParams} whereParams
 * @returns {WhereParams}
 */
const pickByField = curry((list, whereParams) =>
  getList(whereParams).reduce((acc, whereParamItem) => {
    if (isOr(whereParamItem)) {
      return addParam(
        makeOr(pickByField(list, getWhereParams(whereParamItem))),
        acc
      );
    }
    if (includes(getField(whereParamItem), list)) {
      return addParam(whereParamItem, acc);
    }
    return acc;
  }, makeWhereParams([]))
);
exports.pickByField = pickByField;

/**
 * Checks if contains where param item with filed name
 *
 * @param {string} field
 * @param {WhereParams} whereParams
 * @returns {boolean}
 */
const contains = (field, whereParams) =>
  getList(whereParams).reduce((acc, whereParamItem) => {
    if (acc === true) return true;
    if (isOr(whereParamItem)) {
      return contains(field, getWhereParams(whereParamItem));
    }
    return getField(whereParamItem) === field;
  }, false);
exports.contains = contains;

/**
 * Removes where param item
 *
 * @param {string} field
 * @param {WhereParams} whereParams
 * @returns {WhereParams}
 */
const removeParam = (field, whereParams) =>
  getList(whereParams).reduce((acc, whereParamItem) => {
    if (isOr(whereParamItem)) {
      const newWhereParams = removeParam(field, getWhereParams(whereParamItem));

      if (isEmpty(getList(newWhereParams))) return acc;

      return addParam(makeOr(newWhereParams), acc);
    }

    if (getField(whereParamItem) !== field) {
      return addParam(whereParamItem, acc);
    }
    return acc;
  }, makeWhereParams([]));
exports.removeParam = removeParam;

/**
 * Generate function for search by list of filed names
 *
 * @param {[string]} fieldNames
 * @param {string} searchQuery
 * @param {WhereParams} whereParams
 * @returns {WhereParams}
 */
exports.searcher = curry((fieldNames, searchQuery, whereParams) => {
  if (searchQuery === '') {
    return fieldNames.reduce(
      (acc, fieldName) => removeParam(fieldName, acc),
      whereParams
    );
  }

  return addParam(
    makeOr(
      makeWhereParams(
        fieldNames.map((fieldName) => makeILike(fieldName, searchQuery))
      )
    ),
    whereParams
  );
});

exports.isEnabledFilterById = (whereParams) => contains('id', whereParams);

/**
 * Adds and removes ids from where params
 *
 * @param {[number]} ids
 * @param {WhereParams} whereParams
 * @returns {WhereParams}
 */
exports.toggleFilterById = (ids, whereParams) => {
  if (contains('id', whereParams)) {
    return removeParam('id', whereParams);
  }
  return addParam(makeEqualToOneOf('id', ids), whereParams);
};

/**
 * Omitting where parameter item by list of filed names
 *
 * @param {[string]} fieldNames
 * @param {WhereParams} whereParams
 * @returns {WhereParams}
 */
exports.omitByFields = (fieldNames, whereParams) =>
  fieldNames.reduce(
    (whereParam, name) => removeParam(name, whereParam),
    whereParams
  );

/**
 * Merges two where params
 *
 * @param {WhereParams} whereParamsA
 * @param {WhereParams} whereParamsB
 * @returns {WhereParams}
 */
const merge = (whereParamsA, whereParamsB) =>
  getList(whereParamsB).reduce((acc, whereParamItem) => {
    if (isOr(whereParamItem)) {
      const orA = whereParamItem;
      const indexOrB = getList(acc).findIndex((whereParamItemA) =>
        isOr(whereParamItemA)
      );
      if (indexOrB === -1) return addParam(orA, acc);
      const orB = getList(acc)[indexOrB];

      const whereParamsWithoutOr = makeWhereParams(
        remove(indexOrB, 1, getList(acc))
      );
      const newOr = makeOr(merge(getWhereParams(orB), getWhereParams(orA)));
      return addParam(newOr, whereParamsWithoutOr);
    }
    return addParam(whereParamItem, acc);
  }, whereParamsA);
exports.merge = merge;

/**
 * Remove field from WhereParams if condition === true
 *
 * @param {Function} condition
 * @param {string} field
 * @param {WhereParams} whereParams
 * @returns {WhereParams}
 */
exports.deleteIf = curry((condition, field, whereParams) => {
  if (condition(whereParams)) {
    return removeParam(field, whereParams);
  }
  return whereParams;
});
