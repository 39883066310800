import React from 'react';
import block from 'bem-cn-lite';

const b = block('modal-container');

const ModalContainer = ({ style, children, mods }) => (
  <div style={style} className={b(mods)}>
    {children}
  </div>
);

export default ModalContainer;
