import {
  SCROLL_TO_MSG,
  SCROLL_WHEN_LOAD_CHUNK,
  SET_MSG_TO_EDITOR
} from '../components/ChatWidget/constants';

export const timingMiddleware = () => (next) => (action) => {
  performance.mark(`${action.type}_start`);
  const result = next(action);

  performance.mark(`${action.type}_end`);
  performance.measure(
    `${action.type}`,
    `${action.type}_start`,
    `${action.type}_end`
  );

  return result;
};

export const customEventsMiddleware = () => (next) => (action) => {
  if (action.type === SCROLL_TO_MSG) {
    const { messageId, align = 'center' } = action.payload;
    const scrollToMsgEvent = new CustomEvent('scroll-to-message', {
      detail: { messageId, align }
    });
    document.dispatchEvent(scrollToMsgEvent);
  }
  if (action.type === SCROLL_WHEN_LOAD_CHUNK) {
    const scroll = new CustomEvent('scroll-when-load-chunk', {
      detail: { messageId: action.payload }
    });
    document.dispatchEvent(scroll);
  }
  if (action.type === SET_MSG_TO_EDITOR) {
    const event = new CustomEvent('set-msg-to-editor', {
      detail: { msg: action.payload }
    });
    document.dispatchEvent(event);
  }
  return next(action);
};
