import React from 'react';
import { connect } from 'react-redux';
import Dashboard from '../../modules/dashboard';
import Description from './Description';
import './style.css';

const CWTopic = ({ user, topic, isActive, config }) => (
  <div className="cw-topic">
    <Dashboard chat={topic} isActive={isActive} config={config} />
    <div id="topic-confirm-container" />
    <Description
      topic={topic}
      user={user}
      isActive={isActive}
      config={config}
    />
  </div>
);

export default connect((state) => ({ user: state.getIn(['user', 'user']) }))(
  CWTopic
);
