import React from 'react';
import { isType } from 'core/lib';
import OwnMessage from '../components/Message/OwnMessage';
import PersonMessage from '../components/Message/PersonMessage';
import './style.css';

const CWMItemUserMessage = ({
  t,
  chat,
  currentEmployeeId,
  message,
  isSelected,
  isRead,
  isPinned,
  handlerMsg,
  isSelectMode,
  isForwardedCortege,
  onLoadedSingleImage,
  config,
  internetConnection,
  messagesPlacement
}) => {
  const isUserMsg = currentEmployeeId !== message.employeeId;
  const isOwnMsg =
    currentEmployeeId === message.employeeId && !isType('MsgUnsent', message);
  const isUnsentMsg =
    currentEmployeeId === message.employeeId && isType('MsgUnsent', message);

  if (isOwnMsg || isUnsentMsg) {
    return (
      <OwnMessage
        t={t}
        id={`msgId-${message.id}`}
        currentEmployeeId={currentEmployeeId}
        chat={chat}
        message={message}
        isSelected={isSelected}
        isSelectMode={isSelectMode}
        isRead={isRead}
        isPinned={isPinned}
        isCortege={isForwardedCortege}
        onClick={handlerMsg}
        internetConnection={internetConnection}
        config={config}
        messagesPlacement={messagesPlacement}
        onLoadedSingleImage={onLoadedSingleImage}
      />
    );
  }

  if (isUserMsg) {
    return (
      <PersonMessage
        t={t}
        id={`msgId-${message.id}`}
        currentEmployeeId={currentEmployeeId}
        chat={chat}
        message={message}
        isSelected={isSelected}
        isSelectMode={isSelectMode}
        isRead={isRead}
        isPinned={isPinned}
        isCortege={isForwardedCortege}
        onClick={handlerMsg}
        internetConnection={internetConnection}
        config={config}
        messagesPlacement={messagesPlacement}
        onLoadedSingleImage={onLoadedSingleImage}
      />
    );
  }

  throw new Error('No handler');
};

export default React.memo(CWMItemUserMessage);
