import React from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  makeListVMFromStorage,
  isShowItems,
  initOnCancel,
  initOnSearch,
  getSearchBlockWidth,
  getTitle,
  getRows,
  getPlaceholder
} from './dataType';
import * as modalActions from '../../../../../../action-creators/modal';
import { useTranslate } from '../../../../../../TranslateProvider';
import TemplateModal from '../components/Modal';

const wrapper = (props) => {
  const t = useTranslate();

  return makeListVMFromStorage({
    t,
    title: props.title,
    placeholder: props.placeholder,
    mode: props.mode,
    search: props.search,
    items: props.items,
    onCancel: props.onCancel,
    showModal: props.showModal,
    hideModal: props.hideModal,
    searchItems: props.searchItems,
    searchBlockWidth: props.searchBlockWidth,
    children: props.children
  });
};

const ItemsFromServer = (props) => {
  const vm = wrapper(props);

  return (
    <TemplateModal
      isShowItems={isShowItems(vm)}
      width={getSearchBlockWidth(vm)}
      title={getTitle(vm)}
      placeholder={getPlaceholder(vm)}
      rows={getRows(vm)}
      onHide={initOnCancel(vm)}
      onSearch={initOnSearch(vm)}
    />
  );
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      showModal: modalActions.showModal,
      hideModal: modalActions.hideModalDialog
    },
    dispatch
  );

export default connect(null, mapDispatch)(ItemsFromServer);
