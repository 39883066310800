import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalTopic from './Topic/Base';

import * as topicUC from '../../modules/topic/useCases';
import { getEditFiles } from '../../modules/topic/getter';
import { getChannel } from '../../storeGetters';

const ModalEditTopic = ({
  initEditTopic,
  clearTopicEditInfo,
  cancel,
  updateTopic,
  attachFiles,
  removeFile,
  files,
  readOnly,
  storage
}) => (
  <ModalTopic
    title={readOnly ? 'view_topic_chat' : 'edit_topic_chat'}
    textBtnSubmit="Save changes"
    files={files}
    onSubmit={updateTopic}
    onCancel={cancel}
    attachFiles={attachFiles}
    removeFile={removeFile}
    initUpload={initEditTopic}
    clear={clearTopicEditInfo}
    readOnly={readOnly}
    storage={storage}
  />
);

ModalEditTopic.propTypes = {
  updateTopic: PropTypes.func.isRequired,
  attachFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  initEditTopic: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  clearTopicEditInfo: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  storage: PropTypes.object.isRequired
};

ModalEditTopic.defaultProps = {
  readOnly: false
};

export default connect(
  (state) => ({
    storage: {
      url: `/file-storage/chat/topic/attach/${getChannel(state).id}`,
      operation: 'edit'
    },
    files: getEditFiles(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateTopic: topicUC.updateTopic,
        initEditTopic: topicUC.initEditTopic,
        attachFiles: topicUC.filesLoadHandlerTopicInfo,
        removeFile: topicUC.removeFile,
        clearTopicEditInfo: topicUC.clearTopicEditInfo,
        cancel: topicUC.cancelEditTopic
      },
      dispatch
    )
)(ModalEditTopic);
