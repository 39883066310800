const { PurchaseRequestSettingsDto, ChatSettingsDto } = require('./common');

export class ResponseNotificationSettingsDto {
  constructor(props) {
    this.id = props.id;
    this.userId = props.userId;
    this.purchaseRequests = new PurchaseRequestSettingsDto(
      props.purchaseRequests
    );
    this.chat = new ChatSettingsDto(props.chat);
  }
}
