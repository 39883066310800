import { isType } from 'core/lib';
import { PURCHASE_REQUESTS_CHANNEL } from 'core/data/light/purchaseRequestChannel';
import { NEWS_FEED_CHANNEL } from 'core/data/light/newsFeedChannel';

export const CHAT_TYPES = {
  Dialog: 'Dialog',
  Channel: 'Channel',
  Topic: 'Topic',
  PurchaseRequestsChannel: PURCHASE_REQUESTS_CHANNEL,
  PurchaseResponseChat: 'ResponseChat',
  NewsFeedChannel: NEWS_FEED_CHANNEL
};

export const isDialog = (prop) => {
  if (typeof prop === 'string') {
    return prop === CHAT_TYPES.Dialog;
  }

  return isType(CHAT_TYPES.Dialog, prop);
};

export const isChannel = (prop) => {
  if (typeof prop === 'string') {
    return prop === CHAT_TYPES.Channel;
  }

  return isType(CHAT_TYPES.Channel, prop);
};

export const isTopic = (prop) => {
  if (typeof prop === 'string') {
    return prop === CHAT_TYPES.Topic;
  }

  return isType(CHAT_TYPES.Topic, prop);
};

export const isPurchaseRequestsChannel = (prop) => {
  if (typeof prop === 'string') {
    return prop === CHAT_TYPES.PurchaseRequestsChannel;
  }

  return isType(CHAT_TYPES.PurchaseRequestsChannel, prop);
};

export const isResponseChat = (prop) => {
  if (typeof prop === 'string') {
    return prop === CHAT_TYPES.PurchaseResponseChat;
  }
  return prop.hardcodeType === CHAT_TYPES.PurchaseResponseChat;
};

export const isNewsFeedChannel = (prop) => {
  if (typeof prop === 'string') {
    return prop === CHAT_TYPES.NewsFeedChannel;
  }

  return isType(CHAT_TYPES.NewsFeedChannel, prop);
};
