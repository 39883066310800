import React from 'react';
import PropTypes from 'prop-types';

const GridPage = ({ children, mods }, { b }) => (
  <div className={b(mods)}>{children}</div>
);

GridPage.contextTypes = {
  b: PropTypes.func
};

export default GridPage;
