const { has, join, filter, isEmpty } = require('ramda');

module.exports = (listOfMethods, module) => {
  const listOfNotRelizedMethods = filter(
    (methodName) => !has(methodName, module),
    listOfMethods
  );
  if (!isEmpty(listOfNotRelizedMethods)) {
    throw new Error(
      `Module not have method(s): ${join(', ', listOfNotRelizedMethods)}`
    );
  }
  return module;
};
