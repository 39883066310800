import { RichUtils } from 'draft-js';
import { send } from '../../useCases/send';
import { edit } from '../../useCases/edit';
import {
  getSelectedBlock,
  replaceContentByBlockType,
  insertSpace
} from '../plugins/utils';

const isPresetForOrderedList = (block) =>
  block.getType() === 'unstyled' &&
  block.getDepth() === 0 &&
  block.getText() === '1.';
const isPresetForUnorderedList = (block) =>
  block.getType() === 'unstyled' &&
  block.getDepth() === 0 &&
  block.getText() === '*';

export const initHandleKeyCommand =
  ({ onSend, onSubmit, onChange, onSetEditMode }) =>
  (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }

    // NOTE: code gets from https://medium.com/free-code-camp/how-to-create-ordered-and-unordered-lists-in-draft-js-with-a-shortcut-5de34a1a570f
    if (command === 'start-unordered-list') {
      const block = getSelectedBlock(editorState);

      if (isPresetForUnorderedList(block)) {
        const newEditorState = replaceContentByBlockType(
          editorState,
          block,
          'unordered-list-item'
        );

        onChange(newEditorState);
        return 'handled';
      }

      const newEditorState = insertSpace(editorState);

      onChange(newEditorState);
      return 'handled';
    }

    if (command === 'start-ordered-list') {
      const block = getSelectedBlock(editorState);

      if (isPresetForOrderedList(block)) {
        const newEditorState = replaceContentByBlockType(
          editorState,
          block,
          'ordered-list-item'
        );

        onChange(newEditorState);
        return 'handled';
      }

      const newEditorState = insertSpace(editorState);

      onChange(newEditorState);
      return 'handled';
    }

    if (command === 'send') {
      send({ onSend, onSubmit });
      return 'handled';
    }
    if (command === 'edit') {
      edit({ onSetEditMode });
      return 'handled';
    }

    // NOTE: need for experiments with functionality
    // if (command === 'test') {

    //   return 'handled';
    // }

    return 'not-handled';
  };
