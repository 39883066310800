import React from 'react';
import cn from 'classnames';
import { Modal as LibModal } from 'react-bootstrap';
import classes from './Modal.module.css';

const Modal = ({
  open,
  onClose,
  title,
  footer,
  children,
  disableEscape = false,
  disableEnforceFocus = false,
  ...restProps
}) => (
  <LibModal
    className={cn(classes.modal, 'modal-layout')}
    onHide={onClose}
    show={open}
    enforceFocus={!disableEnforceFocus}
    keyboard={!disableEscape}
    {...restProps}>
    {children}
    {footer}
  </LibModal>
);

export default Modal;
