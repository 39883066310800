import React from 'react';
import PropTypes from 'prop-types';
import FilePreview from './filePreview';

const Files = ({
  files,
  mode,
  modePreview,
  getMenu,
  allowDelete,
  onDelete,
  offset = 0,
  theme,
  onCopyToStorage
}) => (
  <div className="file-list-preview" style={{ paddingTop: offset }}>
    {files.map((file) => (
      <FilePreview
        key={`${file.id}`}
        file={file}
        files={files}
        mode={mode}
        allowDelete={allowDelete}
        modePreview={modePreview}
        getMenu={getMenu}
        onDelete={() => onDelete(file)}
        theme={theme}
        onCopyToStorage={onCopyToStorage}
      />
    ))}
  </div>
);

Files.propTypes = {
  files: PropTypes.array.isRequired,
  getMenu: PropTypes.func,
  mode: PropTypes.string,
  modePreview: PropTypes.string,
  onDelete: PropTypes.func
};

Files.defaultProps = {
  mode: 'only-delete',
  modePreview: 'preview',
  onDelete: () => {},
  getMenu: () => []
};

export default Files;
