import React from 'react';

const IconLinkNew = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11241_2736)">
      <path
        d="M9.66256 14.3884L8.48106 15.5699C6.84975 17.2012 4.20488 17.2012 2.57358 15.5699C0.942271 13.9386 0.942271 11.2937 2.57358 9.66243L3.75507 8.48094M14.3885 9.66243L15.57 8.48094C17.2013 6.84963 17.2013 4.20476 15.57 2.57346C13.9387 0.942149 11.2939 0.942149 9.66256 2.57346L8.48106 3.75495M6.14775 11.9957L11.9959 6.14761"
        stroke="#5B5F73"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11241_2736">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconLinkNew;
