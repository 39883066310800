import React from 'react';
import ItemCard from './ItemCard';

import '../styles/modal_theme_default.styl';

const CardsList = ({ list, selectedIds, onItemChoose, level }) =>
  list.map((item) => (
    <ItemCard
      key={item.value}
      item={item}
      selectedIds={selectedIds}
      onChoose={onItemChoose}
      level={level}
    />
  ));

export default CardsList;
