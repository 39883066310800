import React from 'react';
import SecurityBoxControls from './SecurityBoxControls';
import SecurityBoxSubmit from './SecurityBoxSubmit';
import SecurityBoxLink from './SecurityBoxLink';

export default ({ linkText, submitText, submitAction, theme = 'desktop' }) => (
  <div
    style={{
      marginLeft: theme === 'desktop' ? 50 : 35,
      width: 307,
      marginTop: 30
    }}>
    <SecurityBoxControls>
      <SecurityBoxLink name="loginLink" linkUrl="/login" text={linkText} />
      <div style={{ float: 'right' }}>
        <SecurityBoxSubmit
          name="resetPasswordButton"
          text={submitText}
          submitAction={submitAction}
        />
      </div>
    </SecurityBoxControls>
  </div>
);
