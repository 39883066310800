import React from 'react';
import block from 'bem-cn-lite';

const b = block('logo');

const MessengerLogo = (props) => {
  const { link, width = 148, height = 35 } = props;

  if (link) {
    return (
      <a className={b()} href={link}>
        <img
          style={{ width, height }}
          src="/img/chat-widget/logo.svg"
          alt="Istock.Link"
        />
      </a>
    );
  }

  return (
    <span className={b()}>
      <img
        style={{ width, height }}
        src="/img/chat-widget/logo.svg"
        alt="Istock.Link"
      />
    </span>
  );
};

export default MessengerLogo;
