import React from 'react';
import cn from 'classnames';
import { isEmpty, isNil } from 'ramda';
import Avatar from '../../../Avatar';
import ExtraBtn from '../msg/extraBtn';
import SelectIcon from '../components/SelectIcon';
import { stopPropagation } from '../../../../../utils/utils';
import MessageStatus from '../components/MessageStatus';
import './style.css';

const SELECT_ICON_SIZE = 18;

const UserMessageLayout = ({
  id,
  className,
  bgColor,
  onClick,
  avatar,
  header,
  content,
  footer,
  status,
  menuOptions,
  defaultPlacement = 'left',
  isSelected
}) => (
  <div
    id={id}
    className={cn('cw-message', `cw-message--${defaultPlacement}`, {
      [className]: !isNil(className),
      [`cw-message--bg-color-${bgColor}`]: !isNil(bgColor)
    })}
    onClick={onClick}>
    <div className="cw-message__outer">
      <div className="cw-message__select-icon">
        <SelectIcon size={SELECT_ICON_SIZE} isSelected={isSelected} />
      </div>
      <div className="cw-message__inner">
        {!isNil(avatar) && (
          <div className="cw-message__avatar">
            <Avatar {...avatar} size={30} />
          </div>
        )}
        <div className="cw-message__bubble">
          <div className="cw-message-bubble__header">{header}</div>
          <div className="cw-message-bubble__content">{content}</div>
          <div className="cw-message-bubble__footer">{footer}</div>
        </div>
        <div className="cw-message__actions">
          {!isNil(menuOptions) && !isEmpty(menuOptions) && (
            <div className="cw-message-actions__menu" onClick={stopPropagation}>
              <ExtraBtn data={menuOptions} />
            </div>
          )}
          {!isNil(status) && (
            <div className="cw-message-actions__status">
              <MessageStatus {...status} />
            </div>
          )}
        </div>
        <div className="cw-message__spacer" />
      </div>
    </div>
  </div>
);

export default React.memo(UserMessageLayout);
