import { anyPass, isNil } from 'ramda';
import { findMember } from 'core/data/light/channel';
import {
  haveActivatedMS,
  haveAdminMR,
  haveEditorMR,
  haveParticipantMR,
  haveReaderMR
} from 'core/data/light/channelMember';

const checkRoles = (chat, user, predicats) => {
  const employeeId = isNaN(user) ? user.get('employeeId') : user;
  const currentMember = findMember(chat, employeeId);
  if (!currentMember) return false;
  return anyPass(predicats)(currentMember);
};

export const haveFullPermission = (chat, user) =>
  checkRoles(chat, user, [haveAdminMR]);

export const haveEditPermission = (chat, user) =>
  checkRoles(chat, user, [haveEditorMR, haveAdminMR]);

export const haveParticipantPermission = (chat, user) =>
  checkRoles(chat, user, [haveParticipantMR]);

export const haveReaderPermission = (chat, user) =>
  checkRoles(chat, user, [haveReaderMR]);

export const haveActivedMS = (chat, user) => {
  const member = findMember(chat, user.get('employeeId'));
  if (isNil(member)) return false;
  return haveActivatedMS(member);
};
