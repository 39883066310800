import { bindActionCreators } from 'redux';
import { getCurrEmplId, getFavoritesMsgs, getOpenedChatId } from '../../getter';
import {
  toggleBookmarkCase,
  hideFavoritesCase,
  showFavoritesCase,
  loadMoreCase
} from './useCase';
import { applyState } from '../utils';
import { SET_FAVOTIRE_MSGS } from '../../constants';
import { setChatMode, prevChatMode } from '../chatMode';
import { makeBLWithSelect } from '../../data-type/block-list-with-select';
import { gaSendShowFavorites } from '../GA';
import request from '../../../../lib/request';

export const toggleBookmark =
  (message) => async (dispatch, getState, service) =>
    toggleBookmarkCase(
      {
        getCurrEmplId: applyState(getState, getCurrEmplId),
        getOpenedChatId: applyState(getState, getOpenedChatId),
        addBookmark: service.chatMessage.addBookmark,
        removeBookmark: service.chatMessage.removeBookmark
      },
      { message }
    );

const reqFavoriteMsgs = async (
  chatId,
  { limit = 40, pageNumber = 0, offset = pageNumber * limit }
) => {
  const url = `/api/channel/${chatId}/favorite-messages`;
  const msgsRaw = await request.post(url, { limit, offset });
  return makeBLWithSelect({
    list: msgsRaw.list,
    amountLeft: msgsRaw.amountLeft
  });
};

const reqFavoriteMsgsByOpenedChat = async (state, pageNumber) =>
  reqFavoriteMsgs(getOpenedChatId(state), { limit: 40, pageNumber });

const setFavotireMsgs = (fmsgs) => ({
  type: SET_FAVOTIRE_MSGS,
  payload: fmsgs
});

export const loadMore = (pageNumber) => async (dispatch, getState) =>
  loadMoreCase(
    {
      reqFavoriteMsgsByOpenedChat: async (pageNumberProp) =>
        reqFavoriteMsgsByOpenedChat(getState(), pageNumberProp),
      getFavoritesMsgs: applyState(getState, getFavoritesMsgs),
      setFavotireMsgs: bindActionCreators(setFavotireMsgs, dispatch)
    },
    pageNumber
  );

export const showFavorites = (chat) => async (dispatch, getState) =>
  showFavoritesCase(
    {
      reqFavoriteMsgsByOpenedChat: async () =>
        reqFavoriteMsgsByOpenedChat(getState()),
      ...bindActionCreators(
        { setFavotireMsgs, setChatMode, gaSendShowFavorites },
        dispatch
      )
    },
    chat
  );

export const hideFavorites = () => (dispatch) =>
  hideFavoritesCase(
    bindActionCreators(
      {
        prevChatMode,
        setFavotireMsgs
      },
      dispatch
    )
  );
