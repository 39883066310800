import React from 'react';
import Text, { FONTS } from '../Typography';
import './style.css';

const Types = {
  default: 'a',
  a: 'a',
  link: 'link'
};

function LinkComponent({ type, url, children }) {
  if (type === Types.a) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  throw new Error(`Passed unknown link type`);
}

function Link({
  url,
  children,
  type = Types.default,
  variant = FONTS.body1Reg
}) {
  return (
    <div className="Link">
      <LinkComponent type={type} url={url}>
        <Text variant={variant} className="Link__text">
          {children}
        </Text>
      </LinkComponent>
    </div>
  );
}

export default Link;
