import React from 'react';

/* eslint-disable max-len */
function IconOk() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.2334"
        y="0.5"
        width="15"
        height="15"
        rx="7.5"
        stroke="#767B92"
      />
      <path
        d="M14.113 5.3254L14.4384 4.94577L13.6792 4.29498L13.3538 4.6746L14.113 5.3254ZM7.35377 11.6746C7.17406 11.8843 7.19834 12.1999 7.408 12.3796C7.61767 12.5593 7.93332 12.5351 8.11303 12.3254L7.35377 11.6746ZM13.3538 4.6746L7.35377 11.6746L8.11303 12.3254L14.113 5.3254L13.3538 4.6746Z"
        fill="#767B92"
      />
      <path
        d="M7.3334 12.3C7.49908 12.5209 7.81248 12.5657 8.0334 12.4C8.25431 12.2343 8.29908 11.9209 8.1334 11.7L7.3334 12.3ZM5.1334 7.7L4.8334 7.3L4.0334 7.9L4.3334 8.3L5.1334 7.7ZM8.1334 11.7L5.1334 7.7L4.3334 8.3L7.3334 12.3L8.1334 11.7Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconOk;
