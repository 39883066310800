import { useCallback, useEffect } from 'react';

export function useEvent(name, handler, options) {
  const unsubscribe = useCallback(() => {
    document.removeEventListener(name, handler);
  }, [name, handler, JSON.stringify(options)]);

  useEffect(() => {
    document.addEventListener(name, handler, options);

    return unsubscribe;
  }, [unsubscribe]);

  return unsubscribe;
}
