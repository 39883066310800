import React from 'react';
import cn from 'classnames';
import { Typography } from '@link/react-components';
import classes from './Footer.module.css';

export function Footer({ className, hint, children }) {
  return (
    <div
      className={cn(
        className,
        classes.footer,
        Boolean(hint) && classes.withHint
      )}>
      {Boolean(hint) && (
        <Typography className={classes.hint} variant="body1Reg" noWrap>
          {hint}
        </Typography>
      )}
      <div className={classes.actions}>{children}</div>
    </div>
  );
}
