import React from 'react';

/* eslint-disable max-len */
const TagSVG = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0238 3.22752C14.5875 3.22752 15.0979 3.45619 15.4673 3.82548C15.8366 4.19492 16.0653 4.70542 16.0653 5.2691C16.0653 5.83278 15.8366 6.34314 15.4673 6.71258C15.0979 7.08202 14.5874 7.31054 14.0238 7.31054C13.46 7.31054 12.9497 7.08202 12.5804 6.71258C12.2109 6.34314 11.9824 5.83278 11.9824 5.2691C11.9824 4.70542 12.2109 4.19492 12.5804 3.82548C12.9497 3.45604 13.46 3.22752 14.0238 3.22752ZM18.7514 8.81246L9.18042 18.3834C9.01459 18.5494 8.7455 18.5494 8.57968 18.3834L0.998573 10.8022C0.832751 10.6365 0.832751 10.3676 0.998573 10.2016L10.5695 0.630617C10.6525 0.547706 10.7611 0.506104 10.8698 0.506104H18.2016C18.4363 0.506104 18.6264 0.696243 18.6264 0.930914C18.6264 0.937945 18.6261 0.945123 18.6258 0.952154L18.874 8.49869C18.8778 8.62027 18.8299 8.73145 18.7502 8.81129L18.7514 8.81246ZM8.87997 17.4822L18.0216 8.34048L17.7919 1.35572H11.0459L1.89976 10.502L8.87997 17.4822ZM14.9726 4.32017C14.7299 4.07744 14.3943 3.92714 14.0237 3.92714C13.6531 3.92714 13.3176 4.07729 13.0748 4.32017C12.8319 4.56289 12.6816 4.89849 12.6816 5.2691C12.6816 5.63971 12.8317 5.97517 13.0748 6.21804C13.3175 6.46092 13.6529 6.61106 14.0237 6.61106C14.3942 6.61106 14.7298 6.46092 14.9726 6.21804C15.2155 5.97517 15.3657 5.63971 15.3657 5.2691C15.3657 4.89849 15.2154 4.56289 14.9726 4.32017Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default TagSVG;
