import { createSelector } from 'reselect';
import { isEmpty, isNil } from 'ramda';
import { getChatWidget } from '../../getterCommon';
import { getList, hasList } from './block-list-forward';

export const getChatForward = (state) => getChatWidget(state).blockForward;

const getOpenedChat = (state) => getChatWidget(state).openedChat;
const getOpenedChatId = (state) => {
  const openedChat = getOpenedChat(state);
  return !isNil(openedChat) ? openedChat.id : null;
};

export const getOpenedChatForwardList = createSelector(
  [getOpenedChatId, getChatForward],
  (openedChatId, chatForward) =>
    !isNil(openedChatId) && hasList(chatForward, openedChatId)
      ? getList(chatForward, openedChatId).list
      : []
);

export const isForwardMode = (state) =>
  !isEmpty(getOpenedChatForwardList(state));
