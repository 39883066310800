import { useEffect, useState } from 'react';

function getParams() {
  const params = {};

  const iterator = new URLSearchParams(window.location.search).entries();

  // eslint-disable-next-line no-restricted-syntax,no-unused-vars
  for (const param of iterator) {
    const [key, value] = param;

    params[key] = value;
  }

  return params;
}

export function useParams() {
  const [params, setParams] = useState(getParams());

  useEffect(() => {
    const onChangeParams = () => {
      setParams(getParams());
    };

    window.addEventListener('popstate', onChangeParams);
    window.addEventListener('pushstate', onChangeParams);
    window.addEventListener('replacestate', onChangeParams);

    return () => {
      window.removeEventListener('popstate', onChangeParams);
      window.removeEventListener('pushstate', onChangeParams);
      window.removeEventListener('replacestate', onChangeParams);
    };
  }, []);

  return params;
}
