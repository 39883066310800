import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  SearchInput,
  Button,
  Icon,
  useWizard,
  Loader
} from '@link/react-components';
import { useTranslate, useDebounce } from '@hooks';
import { useChooseSupplierCompanyWizard } from '../../../useChooseSupplierCompanyWizard';
import { getTranslate } from '../../../utils';
import { GroupItem } from './GroupItem';
import { STEPS } from '../../../constants';
import { Container, Content, Footer, Header } from '../../layout';

import { useInitializeCompaniesGroup } from '../../../hooks';
import { config } from '../../../config';
import classes from './ChooseGroups.module.css';
import { NoGroupsCreated, NoSearchResult } from '../../noSearchResult';

const BUTTON_ICON_SIZE = 18;

export function ChooseGroups() {
  const t = useTranslate();
  const { setState, goTo, getState } = useWizard();
  const { close } = useChooseSupplierCompanyWizard();

  const { initialCompanies } = getState();

  const {
    loading,
    groups: sharedGroups,
    selectedCompanies
  } = useInitializeCompaniesGroup({ initialCompanies });

  const [groups, setGroups] = useState(sharedGroups || []);
  const [search, setSearch] = useState('');
  const selectedCompaniesRef = useRef(null);
  selectedCompaniesRef.current = selectedCompanies;

  useEffect(() => {
    setState({ maxChosenCount: config.maxSelectedCompanies });
  }, []);

  useEffect(() => {
    setGroups(sharedGroups);
  }, [sharedGroups]);

  const onSearch = useDebounce((searchValue) => {
    const searchRegExp = new RegExp(searchValue, 'i');
    const selectedGroups = sharedGroups.filter((group) =>
      searchRegExp.test(`${group.name}`)
    );
    setGroups(selectedGroups);
  }, config.searchDebounce);

  useEffect(() => {
    onSearch(search);
  }, [search, sharedGroups]);

  const onCreate = useCallback(() => {
    setState({ chosenCompanies: selectedCompaniesRef.current });
    goTo(STEPS.createGroup);
  }, [setState, goTo]);

  const onEdit = useCallback(
    (group) => {
      setState({
        currentGroup: group,
        chosenCompanies: selectedCompaniesRef.current
      });
      goTo(STEPS.editGroup);
    },
    [setState, goTo]
  );

  const onDelete = useCallback(
    (group) => {
      setState({
        currentGroup: group,
        chosenCompanies: selectedCompaniesRef.current
      });
      goTo(STEPS.deleteGroup);
    },
    [setState, goTo]
  );

  function translate(tKey, params) {
    return getTranslate(t, `chooseGroups.${tKey}`, params);
  }

  useEffect(() => {
    setState({ selectedCompanies });
  }, [selectedCompanies, setState]);

  if (loading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      {!!sharedGroups.length && (
        <Header>
          <SearchInput
            defaultValue={search}
            onSearch={setSearch}
            onChange={setSearch}
            onCleanUp={() => setSearch('')}
            placeholder={translate('inputs.search.placeholder')}
          />
        </Header>
      )}

      <Button
        className={classes.createGroup}
        mode="text"
        size="small"
        onClick={onCreate}
        before={
          <Icon
            iconName="plus"
            width={BUTTON_ICON_SIZE}
            height={BUTTON_ICON_SIZE}
          />
        }
        stretched={false}>
        {translate('groups.createGroupButton')}
      </Button>
      <Content>
        <div>
          {Boolean(!sharedGroups.length) && (
            <NoGroupsCreated tKey="chooseGroups" />
          )}

          {Boolean(!groups.length && search) && (
            <NoSearchResult tKey="chooseGroups" />
          )}
          {groups.map((group) => (
            <GroupItem
              key={group.id}
              group={group}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        </div>
      </Content>
      <Footer>
        <Button mode="text" size="large" onClick={close}>
          {translate('actions.cancel')}
        </Button>
        <Button mode="primary" size="large" onClick={close}>
          {translate('actions.submit')}
        </Button>
      </Footer>
    </Container>
  );
}
