import React from 'react';

/* eslint-disable max-len */
function IconEditCircle() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#F2F4F8" />
      <path
        d="M18.662 11.5685L11.1982 18.9445H8.5V16.2462L15.876 8.78247C16.4607 8.19076 17.4153 8.18794 18.0035 8.77619L18.6683 9.44098C19.2565 10.0292 19.2537 10.9838 18.662 11.5685Z"
        stroke="#767B92"
      />
      <line
        x1="15.1314"
        y1="9.75753"
        x2="18.1314"
        y2="12.7575"
        stroke="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconEditCircle;
