import React from 'react';

/* eslint-disable max-len */
function IconOkCircle() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4947 21.7876L9.74361 17.0365L9.0365 17.7436L14.4947 23.2018L25.6965 12L24.9894 11.2929L14.4947 21.7876Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconOkCircle;
