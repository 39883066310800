import React, { useCallback, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { isNil } from 'ramda';
import throttle from 'lodash.throttle';
import { useActions, useIsScrollBottom } from '@hooks';
import { canLoadNext, canLoadPrev, scrollToLastMessage } from './methods';
import Loader from './components/Loader';
import { useIsScrollTop } from '../chat-items/scroll';
import * as useCase from '../../useCases';
import { isFunction } from '../../../../utils/utils';
import { getBottomChatNodeId } from './helpers';
import ScrollBottomButton from './components/ScrollBottomButton';
import MessagesGroupContainer from './components/MessagesGroupContainer';
import './style.css';

const LOADING_DELAY = 500;
const DELTA_SCROLL_TOP = 120;
const DELTA_SCROLL_BOTTOM = 30;

const CWMessages = ({
  chat,
  list,
  amountLeftPrev = 0,
  amountLeftNext = 0,
  selectedList = [],
  isSelectMode,
  isShowUnreadMessagesDivider = false,
  config,
  isShowPopUpSystemNotification = false,
  handlerMsg,
  onLoadMoreNext,
  onLoadMorePrev,
  datePanelFormat,
  scrollContainerRef: _scrollContainerRef,
  messagesPlacement,
  isSearchMode
}) => {
  const { uploadMessages } = useActions(useCase);

  const ref = useRef(null);
  const scrollContainerRef = useRef(getScrollContainerRef());

  useEffect(() => {
    scrollContainerRef.current = getScrollContainerRef();
  }, [_scrollContainerRef, ref.current]);

  function getScrollContainerRef() {
    if (!isNil(_scrollContainerRef)) return _scrollContainerRef.current;

    if (!ref.current) return null;

    return ref.current.parentNode;
  }

  const isScrollTop = useIsScrollTop(
    scrollContainerRef,
    [list, scrollContainerRef.current],
    {
      delta: DELTA_SCROLL_TOP
    }
  );
  const isScrollBottom = useIsScrollBottom(
    scrollContainerRef,
    [list, scrollContainerRef.current],
    {
      delta: DELTA_SCROLL_BOTTOM
    }
  );

  const scrollToEnd = useCallback(async () => {
    if (canLoadNext(amountLeftNext)) {
      await loadFirstMessage();
    }
    setTimeout(scrollToLastMessage, 100, scrollContainerRef);

    async function loadFirstMessage() {
      if (isSearchMode) {
        return;
      }

      await uploadMessages(chat);
    }
  }, [amountLeftNext, isSearchMode]);

  const throttledOnLoadMorePrev = useCallback(
    throttle(() => {
      if (isFunction(onLoadMorePrev)) {
        onLoadMorePrev();
      }
    }, LOADING_DELAY),
    [onLoadMorePrev]
  );

  useEffect(() => {
    if (isScrollTop && canLoadPrev(amountLeftPrev)) {
      throttledOnLoadMorePrev();
    }
  }, [isScrollTop]);

  const throttledOnLoadMoreNext = useCallback(
    throttle(() => {
      if (isFunction(onLoadMoreNext)) {
        onLoadMoreNext();
      }
    }, LOADING_DELAY),
    [onLoadMoreNext]
  );

  useEffect(() => {
    if (isScrollBottom && canLoadNext(amountLeftNext)) {
      throttledOnLoadMoreNext();
    }
  }, [isScrollBottom]);

  return (
    <div
      ref={ref}
      className={`cwm-messages cwm-messages_theme-${config.theme}`}>
      <Loader
        key={`top-loader-${chat.id}`}
        isShow={canLoadPrev(amountLeftPrev)}
      />

      <MessagesGroupContainer
        chat={chat}
        messages={list}
        isCanLoadNext={canLoadNext(amountLeftNext)}
        selectedList={selectedList}
        handlerMsg={handlerMsg}
        isSelectMode={isSelectMode}
        isShowUnreadMessagesDivider={isShowUnreadMessagesDivider}
        isShowPopUpSystemNotification={isShowPopUpSystemNotification}
        config={config}
        datePanelFormat={datePanelFormat}
        messagesPlacement={messagesPlacement}
      />

      <Loader
        key={`bottom-loader-${chat.id}`}
        isShow={canLoadNext(amountLeftNext)}
      />

      {!canLoadNext(amountLeftNext) && (
        <div id={getBottomChatNodeId(chat.id)} style={{ height: 10 }} />
      )}
      {scrollContainerRef.current
        ? createPortal(
            // eslint-disable-next-line react/jsx-indent
            <ScrollBottomButton
              onClick={scrollToEnd}
              scrollContainerRef={scrollContainerRef}
            />,
            scrollContainerRef.current.parentNode
          )
        : null}
    </div>
  );
};

export default React.memo(CWMessages);
