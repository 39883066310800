import { getChatWidget } from '../getterCommon';
import { REDUX_KEY } from './constants';

export const getShowPurchaseRequestBlock = (state) =>
  getChatWidget(state)[REDUX_KEY];

export const isShowPurchaseRequestsFilter = (state) =>
  getShowPurchaseRequestBlock(state).isShow;

export const getPurchaseRequestFilterData = (state) =>
  getShowPurchaseRequestBlock(state).filterData;

export const isSubscribeToPRChannel = (state) =>
  getShowPurchaseRequestBlock(state).isSubscribed;
