import React from 'react';

/* eslint-disable max-len */
function IconCall() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1301 15.1021C15.2371 15.6022 15.5562 16.535 16.5112 17.49C17.4662 18.4449 18.399 18.764 18.8991 18.871L19.7922 17.9775C20.281 17.4892 21.0428 17.4026 21.6283 17.7693L21.6389 17.7759L24.3117 19.4968C24.9493 19.9015 25.1806 20.7206 24.8501 21.3987L23.5469 24.1658L23.543 24.1736C23.2696 24.7289 22.6796 25.0555 22.0639 24.9924L22.0611 24.9921C20.5442 24.8325 16.9109 24.1751 13.3681 20.632C9.82529 17.089 9.1676 13.4553 9.008 11.9392L9.00771 11.9363C8.94466 11.3206 9.27128 10.7307 9.82655 10.4572L9.83436 10.4534L12.6013 9.15028C13.2802 8.81905 14.1005 9.05129 14.5048 9.69021L16.2328 12.3735C16.5992 12.9593 16.5123 13.7206 16.0236 14.2091L15.1301 15.1021ZM15.3167 13.5017L14.2342 14.5837C14.1282 14.689 14.0772 14.8376 14.0962 14.9858C14.1644 15.5217 14.4664 16.8594 15.8041 18.1971C17.1418 19.5347 18.4794 19.8368 19.0154 19.9049C19.1636 19.9239 19.3122 19.8729 19.4174 19.7669L20.4995 18.6844C20.6587 18.5254 20.9067 18.4973 21.0975 18.6168L23.7738 20.3398C23.9843 20.4721 24.0601 20.7425 23.949 20.9649L22.6459 23.7318C22.5571 23.9121 22.3656 24.0181 22.1658 23.9976C20.7877 23.8526 17.4025 23.2524 14.0752 19.925C10.7479 16.5975 10.1475 13.2118 10.0025 11.8345C9.98205 11.6346 10.0881 11.4431 10.2683 11.3544L13.0352 10.0513C13.2581 9.93976 13.5293 10.016 13.6613 10.2274L15.3849 12.9037C15.5042 13.0946 15.476 13.3426 15.3167 13.5017Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconCall;
