const {
  isMember,
  findMember,
  isOwner,
  getOwnerMember,
  getMembersWithoutOwner,
  getMembersWithoutUser,
  addUnreadMsg,
  clearUnreadMsg,
  isArchived,
  isMemberNotificationOff,
  setName
} = require('../mainChannel');
const { isType } = require('../../../lib');

/**
 * @typedef {import('../channelMember')} ChannelMember
 * @typedef {import('../message')} Message
 * @typedef {string} HEX - example #000000
 */

/**
 * @typedef {object} Channel
 * @property {string} type
 * @property {string} id
 * @property {string} name
 * @property {Date} createdAt
 * @property {number} ownerEmployeeId
 * @property {HEX} color
 * @property {Message} lastMessage
 * @property {[ChannelMember]} members
 * @property {string} firstUnreadMessageId
 * @property {[string]} unreadMessagesId
 * @property {boolean} haveNewMessages
 * @property {boolean} archived
 * @property {[number]} notificationOffMembers
 * @property {boolean} notificationOff
 */

/**
 * Creates a channel data type
 *
 * @param {object} params
 * @param {string} params.id
 * @param {string} params.name
 * @param {Date} params.createdAt
 * @param {number} params.ownerEmployeeId
 * @param {HEX} params.color
 * @param {string} params.lastMessage
 * @param {[ChannelMember]} params.members
 * @param {string} params.firstUnreadMessageId
 * @param {[string]} params.unreadMessagesId
 * @param {boolean} params.haveNewMessages
 * @param {boolean} params.archived
 * @param {[string]} params.confirm
 * @param {[number]} params.notificationOffMembers
 * @param {boolean} params.notificationOff
 * @param {[Message]} params.pinnedMessages
 * @param {[Message]} params.scheduleMessages
 * @param {[Object]} params.tags
 * @param {string} params.hardcodeType
 * @param {object} params.additionalInfo
 * @returns {Channel}
 */
exports.makeChannel = ({
  id,
  name,
  color,
  ownerEmployeeId,
  lastMessage,
  members,
  firstUnreadMessageId,
  unreadMessagesId = [],
  haveNewMessages,
  archived,
  notificationOffMembers = [],
  notificationOff,
  scheduleMessages = [],
  confirm = [],
  pinnedMessages = [],
  createdAt,
  tags,
  hardcodeType,
  additionalInfo
} = {}) => ({
  type: 'Channel',
  id,
  name,
  color,
  ownerEmployeeId,
  lastMessage,
  members,
  firstUnreadMessageId,
  unreadMessagesId,
  haveNewMessages,
  archived,
  notificationOffMembers,
  notificationOff,
  scheduleMessages,
  confirm,
  pinnedMessages,
  createdAt,
  tags,
  hardcodeType,
  additionalInfo
});

/**
 * Checks if exist member with employeeId
 *
 * @param {Channel} channel
 * @param {number} employeeId
 * @returns {boolean}
 */
exports.isMember = isMember;

/**
 * Find member by employeeId if exist
 *
 * @param {Channel} channel
 * @param {number} employeeId
 * @returns {(null|ChannelMember)}
 */
exports.findMember = findMember;

/**
 * Check if member is owner
 *
 * @param {Channel} channel
 * @param {number} employeeId
 * @returns {boolean}
 */
exports.isOwner = isOwner;

/**
 * Gets owner member
 *
 * @param {Channel} channel
 * @returns {ChannelMember}
 */
exports.getOwnerMember = getOwnerMember;

/**
 * Gets members without owner
 *
 * @param {Channel} channel
 * @returns {[ChannelMember]}
 */
exports.getMembersWithoutOwner = getMembersWithoutOwner;

/**
 * Gets members without current employee
 *
 * @param {Channel} channel
 * @param {number} employeeId
 * @returns {[ChannelMember]}
 */
exports.getMembersWithoutUser = getMembersWithoutUser;

/**
 * Add unread message to channel
 *
 * @param {Channel} channel
 * @param {string} messageId
 * @returns {Channel}
 */
exports.addUnreadMsg = addUnreadMsg;

/**
 * Clear unread message in channel
 *
 * @param {Channel} channel
 * @returns {Channel}
 */
exports.clearUnreadMsg = clearUnreadMsg;

/**
 * Checks if channel archived
 *
 * @param {Channel} channel
 * @returns {boolean}
 */
exports.isArchived = isArchived;

/**
 * Checks channel notifications is off for member
 *
 * @param {Channel} channel
 * @param {number} employeeId
 * @returns {boolean}
 */
exports.isMemberNotificationOff = isMemberNotificationOff;

/**
 * Check if chat is channel
 *
 * @param {Channel} chat
 * @returns {boolean}
 */
exports.isChannel = (chat) => isType('Channel', chat);

/**
 * Set channel name
 *
 * @param {Channel} channel
 * @param {string} name
 * @returns {Channel}
 */
exports.setName = setName;
