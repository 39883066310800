import * as storage from '../storage';
import { addScheduleMessageCase } from './addScheduleMessage';
import { removeScheduleMessageCase } from './removeScheduleMessage';
import { jumpToMsg } from '../../../useCases';

export const addScheduleMessage = (channelId, messageId, date) => async () =>
  addScheduleMessageCase(
    {
      addScheduleOnMessage: storage.addScheduleOnMessage
    },
    { channelId, messageId, date }
  );

export const removeScheduleMessage = (channelId, messageId) => async () =>
  removeScheduleMessageCase(
    {
      removeScheduleMessage: storage.removeScheduleMessage
    },
    { channelId, messageId }
  );

export const jumpToScheduleMessage = (message) => (dispatch) =>
  dispatch(jumpToMsg(message));
