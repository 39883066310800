import { useEffect, useReducer, useRef, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

const DEFAULT_RECT = {
  height: 0,
  width: 0
};

const reducer = (state, action) => {
  const { rect } = action;

  return state.height !== rect.height || state.width !== rect.width
    ? rect
    : state;
};

export const useMeasureRect = (ref) => {
  const [element, setElement] = useState(ref.current);
  const initializedReactRef = useRef(false);
  const [rect, dispatch] = useReducer(reducer, DEFAULT_RECT);

  useIsomorphicLayoutEffect(() => {
    if (ref.current !== element) {
      setElement(ref.current);
    }
  });

  useIsomorphicLayoutEffect(() => {
    if (element && !initializedReactRef.current) {
      initializedReactRef.current = true;

      const { height, width } = element.getBoundingClientRect();
      dispatch({ rect: { height, width } });
    }
  }, [element]);

  useEffect(() => {
    if (!element) {
      return undefined;
    }

    const tracker = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === element) {
          const { height, width } = entry.contentRect;
          dispatch({ rect: { height, width } });
        }
      });
    });

    tracker.observe(element);

    return () => {
      tracker.unobserve(element);
    };
  }, [element]);

  return rect;
};
