import React, { Component, Fragment } from 'react';
import { is } from 'ramda';
import { FormControl } from 'react-bootstrap';
import './inputNumberStyle.css';

const invalidChars = [',', '.', '-', '+', 'e', 'E'];

// eslint-disable-next-line react/prefer-stateless-function
class InputText extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onChange(e) {
    const { onChange, maxLength = null } = this.props;
    if (
      this.isEnabledMaxLength() &&
      e.target.value.toString().length > maxLength
    )
      return;
    onChange(e);
  }

  onKeyDown(e) {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  }

  isEnabledMaxLength() {
    const { maxLength = null } = this.props;
    return is(Number, maxLength) && maxLength > 0;
  }

  render() {
    const {
      viewRead,
      value = '',
      clearButton = false,
      onClear = () => {},
      className = '',
      ...restProps
    } = this.props;

    if (viewRead) {
      return <div style={{ padding: 7 }}>{value || '-'}</div>;
    }

    const props = { ...this.props };
    delete props.onChange;
    delete props.viewRead;

    return (
      <>
        <FormControl
          className={`input-number-bootstrap ${className}`}
          type="number"
          {...restProps}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          value={value}
        />
        {clearButton && (
          <img
            style={{
              position: 'absolute',
              top: 39,
              bottom: 0,
              right: 20,
              cursor: 'pointer'
            }}
            onClick={onClear}
            role="presentation"
            src="/img/cross.svg"
            alt=""
          />
        )}
      </>
    );
  }
}

export default InputText;
