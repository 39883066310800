import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { enGB, ru } from 'date-fns/locale';
import { formatRelative } from 'date-fns';
import useCounter from '../../hooks/counter';
import * as commonGetters from '../../getter';
import * as getters from './getters';
import * as useCase from '../../useCases';
import CWMessages from '../../main/chat-messages';
import { usePlacement } from '../../main/chat-items/hooks';

const EVENT_LOAD_MORE = 'load-more';

const datePanelFormat = (t, date, language) => {
  const parts = date.split('.');
  const preparedDate = new Date(parts[2], parts[1] - 1, parts[0]);

  const locale = {
    ...getLocaleForDate(),
    formatRelative: getFormatRelativeLocale
  };

  return t(`delayedMessages.datePanel`, {
    date: formatRelative(preparedDate, new Date(), { locale })
  });

  function getLocaleForDate() {
    if (language === 'ru') return ru;
    return enGB;
  }

  function getFormatRelativeLocale(token) {
    const formatRelativeLocale = {
      lastWeek: 'd MMMM',
      yesterday: `'${t('yesterday')}', d MMMM`,
      today: `'${t('today')}', d MMMM`,
      tomorrow: 'd MMMM',
      nextWeek: 'd MMMM',
      other: 'd MMMM'
    };

    return formatRelativeLocale[token];
  }
};

const CWDelayedMessagesContent = ({ config }) => {
  const pageNumber = useCounter(1);
  const chat = useSelector(commonGetters.getOpenedChat);
  const messages = useSelector(getters.getDelayMessagesList);
  const user = useSelector(commonGetters.getCurrUser);
  const handler = bindActionCreators(
    useCase.handleDelayedMessages,
    useDispatch()
  );
  const messagesPlacement = usePlacement();

  const loadMore = useCallback(() => {
    handler(EVENT_LOAD_MORE, pageNumber.count());
    pageNumber.increase();
  }, [pageNumber.count()]);

  return (
    <div
      className={`cw-delayed-messages-content cw-delayed-messages-content_theme_${config.theme}`}>
      <CWMessages
        chat={chat}
        user={user}
        list={messages}
        handlerMsg={handler}
        onLoadMoreNext={loadMore}
        amountLeftNext={messages.amountLeft}
        config={config}
        datePanelFormat={datePanelFormat}
        messagesPlacement={messagesPlacement}
      />
    </div>
  );
};

export default CWDelayedMessagesContent;
