const { assoc, pipe, has, type, equals, curry } = require('ramda');
// Data is a Object
// Id is a Number
// Constructor is a methods of create Struct and get data from struct
// Struct is a typed data

// String -> String
const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

// Record -> String
const getType = (data) => (has('type', data) ? data.type : type(data));

const getErrorMsg = (methodName, methodType, sentType) =>
  `The method "${methodName}" belongs to the type "${methodType}" by your "${sentType}"`;
const genGetMethodName = (propName) => `get${capitalize(propName)}`;

const createGetMethods = (props, typeName) =>
  props.reduce(
    (acc, pr) =>
      assoc(
        genGetMethodName(pr),
        (struct) => {
          if (!equals(getType(struct), typeName)) {
            throw new Error(
              getErrorMsg(genGetMethodName(pr), typeName, getType(struct))
            );
          }
          return struct[pr];
        },
        assoc(
          pr,
          (struct) => {
            if (!equals(getType(struct), typeName)) {
              throw new Error(getErrorMsg(pr, typeName, getType(struct)));
            }
            return struct[pr];
          },
          acc
        )
      ),
    {}
  );

const withDefaultValues = (props, data = {}, defaultValues) =>
  props.reduce((result, key) => {
    if (data[key] === undefined && defaultValues[key] !== undefined) {
      return { ...result, [key]: defaultValues[key] };
    }
    return result;
  }, data);

// String Record -> Boolean
const isType = curry((typeName, data) => getType(data) === typeName);

// String [String] -> Constructor
const defRecord = (typeName, props = [], defaultValues = {}) =>
  pipe(
    createGetMethods,
    assoc('getModuleType', () => typeName),
    assoc(`make${typeName}`, (obj) =>
      assoc('type', typeName, withDefaultValues(props, obj, defaultValues))
    ),
    assoc(`is${typeName}`, (struct) => isType(typeName, struct))
  )(props, typeName, defaultValues);

module.exports = {
  isType,
  getType,
  defRecord
};
