import React from 'react';
import block from 'bem-cn-lite';

const b = block('grid-page');

const GridPageModal = ({ children }) => (
  <div className={b('modal')}>{children}</div>
);

export default GridPageModal;
