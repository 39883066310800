import React from 'react';
import { Typography } from '@link/react-components';
import cn from 'classnames';
import styles from './Switcher.module.css';

export const Switcher = ({ onClick, day, isActive }) => (
  <button
    className={cn(styles.switcher, {
      [styles.active]: isActive
    })}
    onClick={() => onClick(day)}>
    <Typography variant="body1Reg">{day.text}</Typography>
  </button>
);
