import React from 'react';

/* eslint-disable max-len */
function IconSaveFile() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 24.7432C16.7238 24.7432 16.5 24.5193 16.5 24.2432L16.5 14.2432C16.5 13.967 16.7238 13.7432 17 13.7432C17.2761 13.7432 17.5 13.967 17.5 14.2432V24.2432C17.5 24.5193 17.2761 24.7432 17 24.7432Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11.5C9 10.1193 10.1193 9 11.5 9H22.5C23.8807 9 25 10.1193 25 11.5V22.5C25 23.8807 23.8807 25 22.5 25H21V24H22.5C23.3284 24 24 23.3284 24 22.5V11.5C24 10.6716 23.3284 10 22.5 10H11.5C10.6716 10 10 10.6716 10 11.5V22.5C10 23.3284 10.6716 24 11.5 24H13V25H11.5C10.1193 25 9 23.8807 9 22.5V11.5Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5249 18.4752C12.3296 18.2799 12.3296 17.9633 12.5249 17.7681L16.6464 13.6465C16.8417 13.4513 17.1583 13.4513 17.3535 13.6465L21.4751 17.7681C21.6703 17.9633 21.6703 18.2799 21.4751 18.4752C21.2798 18.6704 20.9632 18.6704 20.768 18.4752L17 14.7072L13.232 18.4752C13.0367 18.6704 12.7201 18.6704 12.5249 18.4752Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconSaveFile;
