import React from 'react';
import { useTranslate } from '@hooks';
import { Typography } from '@link/react-components';
import { getTranslate } from '../../utils';
import classes from './NoSearchResult.module.css';

export function NoSearchResult({ tKey }) {
  const t = useTranslate();

  return (
    <Typography className={classes.noSearchResult} variant="body1Reg">
      {getTranslate(t, `${tKey}.empty.noSearchResults`)}
    </Typography>
  );
}
