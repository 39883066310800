const { typeOfClass } = require('../../../lib');

/**
 * @typedef {import('./msg-left-from-channel')} MsgLeftFromChannel
 * @typedef {import('./msg-excluded-from-channel')} MsgExcludedFromChannel
 * @typedef {import('./msg-added-to-channel')} MsgAddedToChannel
 * @typedef {import('./msg-created-channel')} MsgCreatedChannel
 * @typedef {import('./msg-renamed-channel')} MsgRenamedChannel
 * @typedef {import('./msg-regained-status')} MsgRegainedStatus
 * @typedef {import(./msg-subscribe-to-channel)} MsgSubscribeToChannel
 * @typedef {import(./msg-unsubscribe-from-channel)} MsgUnsubscribeFromChannel
 * @typedef {import(./msg-accept-response)} MsgAcceptResponse
 * @typedef {import(./msg-reject-response)} MsgRejectResponse
 * @typedef {(MsgCreatedChannel|MsgRenamedChannel|MsgAddedToChannel|MsgExcludedFromChannel|MsgLeftFromChannel|MsgRegainedStatus|MsgSubscribeToChannel|MsgUnsubscribeFromChannel|MsgAcceptResponse|MsgRejectResponse)} MsgSystem
 */

/**
 * Checks if is msg system
 *
 * @param {*} any
 * @returns {boolean}
 */
exports.isMsgSystem = typeOfClass([
  'MsgCreatedChannel',
  'MsgRenamedChannel',
  'MsgAddedToChannel',
  'MsgExcludedFromChannel',
  'MsgLeftFromChannel',
  'MsgRegainedStatus',
  'MsgSubscribeToChannel',
  'MsgUnsubscribeFromChannel',
  'MsgAcceptResponse',
  'MsgSendToReworkResponse',
  'MsgRejectResponse'
]);
