import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { allowEdit } from 'core/data/light/message/msg-user';
import { useSelector } from 'react-redux';
import * as messageTypes from '../../../entity/message';
import UserMessageLayout from '../../../layouts/UserMessageLayout';
import Msg from '../../../msg/msg';
import Footer from '../../MessageFooter';
import Reply from '../../../msg/reply';
import { checkPinBtn } from '../helpers/pin';
import CreatedRoomMsg from '../CreatedRoomMessage';
import ForwardedTitle from '../../ForwardedMessageTitle';
import PurchaseRequestMsg from '../../../msg/viewPurchaseRequestMsg/msg';
import * as dictionariesGetters from '../../../../../../../modules/dictionaries/getters';

const OwnMessage = ({
  t,
  id,
  currentEmployeeId,
  chat,
  message,
  isSelected,
  isSelectMode,
  isRead,
  isPinned,
  isCortege,
  onClick,
  internetConnection,
  config,
  messagesPlacement,
  onLoadedSingleImage
}) => {
  const [isOpen, setOpen] = useState(null);

  const isForwardedMessage = messageTypes.isForwardedMessage(message);
  const isReplyMessage = messageTypes.isReplyMessage(message);
  const isUnsentMessage = messageTypes.isUnsentMessage(message);
  const isVideoRoomCreatedMessage = messageTypes.isMsgRoomCreated(message);
  const isForwardVideoRoomCreatedMessage =
    messageTypes.isForwardedVideoRoomCreatedMsg(message);
  const isForwardPurchaseRequestMessage =
    messageTypes.isForwardedPurchaseRequestMessage(message);

  const PRCategoriesList = useSelector(
    dictionariesGetters.getPurchaseRequestsCategories
  );

  function getConfig() {
    if (isUnsentMessage) {
      return config.config.unsentMessage;
    }
    return config.config.ownMessage;
  }

  const { avatarSize } = config.config;
  const { showReadMsg, buttonList, placement } = getConfig();

  const isAllowEdit = () => {
    const timeout = +process.env.MSG_EDITING_ALLOW_TIME_MIN;
    return (
      allowEdit(new Date(message.time), timeout) &&
      !isForwardedMessage &&
      !isVideoRoomCreatedMessage
    );
  };

  const menuOptions = useMemo(() => {
    const { extraBtn, positions } = getConfig();
    const { position, possiblePositions } = positions[messagesPlacement];
    if (isUnsentMessage) {
      if (internetConnection) {
        return {
          type: 'none'
        };
      }
      if (extraBtn.name === 'danger-exclamation') {
        return {
          type: 'dropdown',
          icon: 'extra',
          onSelect: onClick,
          list: extraBtn.list,
          position,
          possiblePositions,
          onToggle: setOpen
        };
      }
    }
    if (extraBtn.name === 'go-to-msg') {
      return {
        type: 'regular',
        icon: 'arrow-left',
        tooltip: extraBtn.tooltip,
        onClick: () => onClick('go-to-msg')
      };
    }
    if (extraBtn.name === 'extra') {
      return {
        type: 'dropdown',
        icon: 'extra',
        onSelect: onClick,
        list: checkPinBtn(chat, currentEmployeeId, extraBtn.list).filter(
          (extBtn) => !buttonList.some((btnL) => extBtn.value === btnL.value)
        ),
        conditions: {
          'allowEdit?': isAllowEdit() ? 'edit' : 'edit-disable',
          'allowDelete?': isRead ? 'delete-message-disabled' : 'delete-message',
          'pinned?': isPinned ? 'unpin-message' : 'pin-message'
        },
        position,
        possiblePositions,
        onToggle: setOpen
      };
    }
    return {};
  }, [
    onClick,
    chat,
    isRead,
    isPinned,
    message.time,
    internetConnection,
    isUnsentMessage,
    messagesPlacement
  ]);

  const avatar = useMemo(
    () => ({
      show: config.showAvatar && !isCortege,
      size: avatarSize,
      info: {
        type: 'user',
        subType: 'avatar-in-chat',
        src: message.avatarSrc,
        alt: message.userName,
        employeeId: message.employeeId
      }
    }),
    [message.avatarSrc, isCortege]
  );

  const preparedMessage = useMemo(() => {
    if (isVideoRoomCreatedMessage) {
      return { msg: <CreatedRoomMsg message={message} chat={chat} /> };
    }
    if (isForwardVideoRoomCreatedMessage) {
      return {
        msg: <CreatedRoomMsg message={message.originMsgData} chat={chat} />
      };
    }

    return message;
  }, [message]);

  const messageStatus = useMemo(
    () => ({
      show: showReadMsg && !isUnsentMessage,
      status: isRead ? 'read' : 'unread'
    }),
    [isRead, isUnsentMessage, showReadMsg]
  );

  const showProfile = useCallback(
    (targetEmployeeId) => {
      onClick('view-specified-profile', targetEmployeeId);
    },
    [onClick]
  );

  const bgColor = isForwardedMessage ? 'gray-1' : 'gray-2';

  const onMessageLineClick = useCallback(() => {
    if (isSelectMode) {
      onClick('highlight-message', chat, message);
    }
  }, [isSelectMode, chat, message]);

  return (
    <UserMessageLayout
      id={id}
      className={cn(`cwm-msg_theme-${config.theme}`, {
        selected: isSelected,
        'select-mode': isSelectMode,
        active: isOpen,
        cortege: isCortege,
        'cwm-msg_forward-theme_2':
          isForwardedMessage ||
          isForwardVideoRoomCreatedMessage ||
          isForwardPurchaseRequestMessage
      })}
      bgColor={bgColor}
      defaultPlacement={placement}
      avatar={avatar}
      onClick={onMessageLineClick}
      content={
        <>
          {isForwardPurchaseRequestMessage ? (
            <PurchaseRequestMsg
              t={t}
              message={message.originMsgData}
              theme={config.theme}
              categoriesList={PRCategoriesList}
            />
          ) : (
            <Msg
              chat={chat}
              message={preparedMessage}
              isOwnMsg
              isReadMsg={isRead}
              showProfile={showProfile}
              theme={config.theme}
              onLoadedSingleImage={onLoadedSingleImage}
              filesData={message.files}
            />
          )}
          <Footer
            t={t}
            currentEmployeeId={currentEmployeeId}
            message={message}
            onClick={onClick}
            buttonList={buttonList}
            isUnsent={isUnsentMessage}
            scheduleMessages={chat.scheduleMessages}
            setActive={setOpen}
          />
        </>
      }
      header={
        <>
          <div className="cwm-msg__msg-header" />
          <Reply
            t={t}
            type="own"
            show={isReplyMessage}
            chat={chat}
            message={message.targetMsg}
            theme={config.theme}
            onClick={onClick}
          />
          <ForwardedTitle
            t={t}
            show={isForwardedMessage}
            name={message.authorUserName}
          />
        </>
      }
      menuOptions={menuOptions}
      status={messageStatus}
      isSelected={isSelected}
    />
  );
};

export default OwnMessage;
