import React, { useCallback } from 'react';
import { isNil } from 'ramda';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as modalActions from '../../../../action-creators/modal';
import './style.css';

const ModalLayout = ({ title, content, footer, enforceFocus = true }) => {
  const dispatch = useDispatch();
  const onHideModal = useCallback(() => {
    dispatch(modalActions.hideModalDialog());
  }, []);

  return (
    <Modal onHide={onHideModal} show enforceFocus={enforceFocus}>
      <div className="task-manager-modal-layout">
        <Modal.Header>
          <Modal.Title>
            {title}
            <span
              className="modal-header-cross pull-right"
              onClick={onHideModal}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        {!isNil(footer) && <Modal.Footer>{footer}</Modal.Footer>}
      </div>
    </Modal>
  );
};

export default ModalLayout;
