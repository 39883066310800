import request from '../../../../lib/request';
import { getScktChl } from '../socket';

const scktChl = getScktChl();

export const updateChannel = (channelId, config) =>
  scktChl.emit('update', { channelId, config });

export const reqCreateGroupChat = async (
  channelName,
  employeesId,
  membersRoles
) => request.post(`/api/channel`, { channelName, employeesId, membersRoles });
