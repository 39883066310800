import React, { useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';

const USER_AGENT = window.navigator?.userAgent ?? '';
const DELETE_CONTENT_BACKWARD = 'deleteContentBackward';

function searchMobil(ua) {
  const regex =
    /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|mini/i;
  return regex.test(ua);
}

export const mobile =
  Object.prototype.hasOwnProperty.call(window, 'ontouchstart') &&
  searchMobil(USER_AGENT);

function createObject(string) {
  const newObject = {};
  [...string].forEach((el, index) => {
    newObject[index + 1] = el;
  });
  return newObject;
}

const findDigitsOrLettersInValue = ({ value, looking }) => {
  const separator = value['3'];
  const regex = {
    digits: /[0-9]/g,
    letters: /[mMyYdD]/
  };
  const resultArray = Object.values(value)
    .filter((el) => el !== separator)
    .map((el) => el.search(regex[looking]))
    .filter((el) => el === 0);
  return resultArray.length;
};

const isCurrValueHaveDigits = (currValue) => {
  const quantityDigits = findDigitsOrLettersInValue({
    value: currValue,
    looking: 'digits'
  });
  const resultIndexLetters = Object.values(currValue).findIndex((el) => {
    const regex = /[mMyYdD]/;
    const result = el.search(regex);
    return result === 0;
  });
  const quantityLetters = findDigitsOrLettersInValue({
    value: currValue,
    looking: 'letters'
  });
  return {
    allDigits: Boolean(quantityDigits === 8),
    indexLetter: resultIndexLetters,
    allLetters: Boolean(quantityLetters === 8)
  };
};

function DateInput({
  id,
  mask = 'dd/mm/yyyy',
  showMaskAlways = false,
  showMaskOnFocus = false,
  value: inputValue = '',
  range,
  placeholder: inputPlaceholder = '',
  className = '',
  onChange: inputOnChange,
  onFocus: inputOnFocus,
  onBlur: inputOnBlur,
  onMouseEnter: inputOnMouseEnter,
  onMouseLeave: inputOnMouseLeave,
  onKeyDown: inputOnKeyDown,
  onCompile: inputOnCompile,
  onClick: inputOnClick,
  autocorrect = false,
  disabled = false
}) {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [letterObject, setLetterObject] = useState({});
  const [toggleCursor, setCursor] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({
    start: 0,
    end: 1
  });
  const [moveCursor, setMoveCursor] = useState({
    start: '',
    end: ''
  });

  const ref = useRef(null);

  useEffect(() => {
    setLetterObject(createObject(mask));
    ref.current.setSelectionRange(0, 1);
  }, [mask]);

  useEffect(() => {
    ref.current.setSelectionRange(cursorPosition.start, cursorPosition.end);
  }, [cursorPosition.start, cursorPosition.end, toggleCursor]);

  useEffect(() => {
    ref.current.setSelectionRange(moveCursor.start, moveCursor.end);
  }, [moveCursor.start, moveCursor.end]);

  useEffect(() => {
    function _getValue() {
      if (inputValue) return inputValue;
      if (showMaskAlways) return mask;
      return '';
    }

    setValue(createObject(_getValue()));
  }, [inputValue, showMaskOnFocus, range]);

  useUpdateEffect(() => {
    function _getValue() {
      if (inputValue) return inputValue;
      if (showMaskAlways) return mask;
      if (showMaskOnFocus && isFocused) return mask;
      return '';
    }

    if (isEmptyValue()) {
      ref.current.setSelectionRange(cursorPosition.start, cursorPosition.end);
      setValue(createObject(_getValue()));
    }
  }, [isFocused]);

  useUpdateEffect(() => {
    if (!isFocused && isNotCorrectValue() && autocorrect) {
      setValue(createObject(inputValue));
    }
  }, [isFocused]);

  function isEmptyValue() {
    const quantityDigits = findDigitsOrLettersInValue({
      value,
      looking: 'digits'
    });

    return quantityDigits === 0;
  }

  function isFilledValue() {
    const quantityDigits = findDigitsOrLettersInValue({
      value,
      looking: 'digits'
    });

    return quantityDigits === 8;
  }

  function isNotCorrectValue() {
    const quantityDigits = findDigitsOrLettersInValue({
      value,
      looking: 'digits'
    });

    return quantityDigits > 0 && quantityDigits < 8;
  }

  function getValue() {
    const preparedValue =
      Object.keys(value)?.length > 0 ? Object.values(value).join('') : '';

    return preparedValue;
  }

  function getPlaceholder() {
    if (inputValue) return '';

    if (isFocused && showMaskOnFocus) return mask;

    return inputPlaceholder;
  }

  const checkOneValue = (val, valueString, position) => {
    const regex = {
      d: /([0-3]d)|(0[1-9]|[12][0-9]|3[01])|(d[0-9])/,
      m: /([0-1]m)|(0[1-9]|1[012])|(m[0-2])/,
      y: /([1-2]yyy)|((19|20)yy)|((19|20)\dy)|((19|20)\d\d)|(y{2,3}\d{1,2})|(yy\dy)|([1-2]y\d{1,2})/,
      '/': /\//,
      '.': /\./
    };
    const letter = letterObject[position];
    let newVal;
    if (letter === 'd') {
      newVal =
        mask === 'dd.mm.yyyy' || mask === 'dd/mm/yyyy'
          ? valueString.slice(0, 2)
          : valueString.slice(3, 5);
    } else if (letter === 'm') {
      newVal =
        mask === 'dd.mm.yyyy' || mask === 'dd/mm/yyyy'
          ? valueString.slice(3, 5)
          : valueString.slice(0, 2);
    } else if (letter === 'y') {
      newVal = valueString.slice(6, 10);
    } else if (position === 3) {
      newVal = valueString.slice(2, 3);
    } else {
      newVal = valueString.slice(5, 6);
    }
    const isMatch = regex[letter].test(newVal);
    return isMatch;
  };

  function deletingElement({ pos }) {
    const newValue = letterObject[pos];
    setValue((prevState) => ({ ...prevState, [pos]: newValue }));

    const newStart = pos - 1;
    const newEnd = pos;

    setCursorPosition((prevState) => ({
      ...prevState,
      start: newStart,
      end: newEnd
    }));
  }

  function deletingAllElements() {
    setValue(letterObject);
    setCursorPosition({ start: 0, end: 1 });
  }

  function trackingCursorPosition(event) {
    const { selectionStart } = event.target;
    const { allDigits, indexLetter } = isCurrValueHaveDigits(value);

    if (allDigits) {
      if (cursorPosition.start !== selectionStart) {
        setCursorPosition((prevState) => ({
          ...prevState,
          start: selectionStart,
          end: selectionStart + 1
        }));
      }

      return;
    }

    if (indexLetter || indexLetter === 0) {
      setCursorPosition((prevState) => ({
        ...prevState,
        start: indexLetter,
        end: indexLetter + 1
      }));

      return;
    }

    setCursor(!toggleCursor);
  }

  function onPaste({ target: { selectionStart }, clipboardData }) {
    const pasteRaw = (clipboardData || window.clipboardData).getData('text');
    const paste = pasteRaw.slice(0, mask.length);
    const valueString = Object.values(value).join('');
    const prevValue = valueString.slice(0, selectionStart);
    const nextValue = valueString.slice(selectionStart + paste.length);

    let pos = selectionStart;
    const newValueObj = { ...value };
    const arrayValue = [];
    [...paste].forEach((el) => {
      pos++;
      newValueObj[pos] = el;

      const isMatch = checkOneValue(
        el,
        Object.values(newValueObj).join(''),
        pos
      );
      if (isMatch) {
        arrayValue.push(el);
      } else {
        newValueObj[pos] = letterObject[pos];
        arrayValue.push(letterObject[pos]);
      }
    });

    const newValueString = [prevValue, ...arrayValue, nextValue].join('');
    setValue((prevState) => ({
      ...prevState,
      ...createObject(newValueString)
    }));
  }

  function onClick(event) {
    trackingCursorPosition(event);

    if (typeof inputOnClick === 'function') {
      inputOnClick(event);
    }
  }

  function onTouchStart(event) {
    trackingCursorPosition(event);

    if (typeof inputOnClick === 'function') {
      inputOnClick(event);
    }
  }

  function onKeyDown(event) {
    const {
      key,
      target: { selectionStart, selectionEnd }
    } = event;

    if (key === 'Backspace' || key === 'Delete') {
      event.preventDefault();

      if (selectionStart !== 0) deletingElement({ pos: selectionStart });
      if (selectionStart === 0 && selectionEnd === mask.length)
        deletingAllElements();
    } else if (key === 'ArrowRight') {
      setMoveCursor((prevState) => ({
        ...prevState,
        start: selectionStart + 1,
        end: selectionStart + 2
      }));
    } else if (key === 'ArrowLeft') {
      setMoveCursor((prevState) => ({
        ...prevState,
        start: selectionStart - 1,
        end: selectionStart
      }));
    }

    if (typeof inputOnKeyDown === 'function') {
      inputOnKeyDown(event);
    }
  }

  function onInput(event) {
    const {
      target: { selectionStart, selectionEnd, value: currentValue },
      nativeEvent: { inputType }
    } = event;

    if (mobile && inputType === DELETE_CONTENT_BACKWARD) {
      deletingElement({ pos: selectionStart + 1 });
      return;
    }

    const valueArray = [...currentValue];
    const newPositionStart = selectionStart - 1;
    const newValue = valueArray[newPositionStart];
    const reg = /[\d]/g;
    const isValidValue = reg.test(newValue);

    let newState;
    if (isValidValue && selectionStart <= mask.length) {
      const valueString = Object.values({
        ...value,
        [selectionStart]: newValue
      }).join('');
      const isMatch = checkOneValue(newValue, valueString, selectionStart);

      if (isMatch) {
        newState = { ...value, [selectionStart]: newValue };
        setValue(newState);
        const newSelectionStart =
          selectionStart === 2 || selectionStart === 5
            ? selectionStart + 1
            : selectionStart;
        const newSelectionEnd =
          selectionStart === 2 || selectionStart === 5
            ? selectionEnd + 2
            : selectionEnd + 1;
        setCursorPosition((prevState) => ({
          ...prevState,
          start: newSelectionStart,
          end: newSelectionEnd
        }));
      } else if (selectionStart === 1 || selectionStart === 4) {
        const nextValue = selectionStart + 1;
        const newPosStart = selectionStart + 2;

        newState = {
          ...value,
          [selectionStart]: '0',
          [nextValue]: newValue
        };

        setValue(newState);
        setCursorPosition((prevState) => ({
          ...prevState,
          start: newPosStart,
          end: newPosStart + 1
        }));
      } else if (selectionStart === 7) {
        const nextValue = selectionStart + 2;
        const nextSelStart = selectionStart + 1;

        newState = {
          ...value,
          [selectionStart]: '2',
          [nextSelStart]: '0',
          [nextValue]: newValue
        };

        setValue(newState);
        setCursorPosition((prevState) => ({
          ...prevState,
          start: nextValue,
          end: nextSelStart + 1
        }));
      } else if (selectionStart === 3 || selectionStart === 6) {
        const nextSelStart = selectionStart;
        setCursorPosition((prevState) => ({
          ...prevState,
          start: nextSelStart,
          end: nextSelStart + 1
        }));
      } else {
        newState = { ...value };
        setCursor(!toggleCursor);
      }
    } else {
      newState = { ...value };
      setCursor(!toggleCursor);
    }

    if (typeof inputOnChange === 'function') {
      inputOnChange(Object.values(newState).join(''));
    }
  }

  function onFocus(event) {
    if (typeof inputOnFocus === 'function') {
      inputOnFocus(event);
    }

    setIsFocused(true);
  }

  function onBlur(event) {
    if (typeof inputOnBlur === 'function') {
      inputOnBlur(event);
    }

    if (typeof inputOnCompile === 'function') {
      if (isFilledValue()) {
        inputOnCompile(getValue(), { id });
      } else if (isEmptyValue()) {
        inputOnCompile('', { id });
      }
    }

    setIsFocused(false);
  }

  function onMouseEnter(event) {
    if (typeof inputOnMouseEnter === 'function') {
      inputOnMouseEnter(event);
    }
  }

  function onMouseLeave(event) {
    if (typeof inputOnMouseLeave === 'function') {
      inputOnMouseLeave(event);
    }
  }

  return (
    <input
      ref={ref}
      id={id}
      autoComplete="off"
      className={className}
      value={getValue()}
      placeholder={getPlaceholder()}
      onChange={onInput}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onPaste={onPaste}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchStart={onTouchStart}
      disabled={disabled}
    />
  );
}

export default DateInput;
