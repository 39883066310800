import { useWindowSize } from 'react-use';
import { useSelector } from 'react-redux';
import { getNavSidebarWidth } from '../../../../MessengerNavSidebar/getters';

const Placements = {
  Default: 'default',
  Left: 'left'
};

export default function usePlacement() {
  const { width } = useWindowSize();
  const navSidebarWidth = useSelector(getNavSidebarWidth);

  const isAlignLeft = width - navSidebarWidth - 68 > 1200;

  return isAlignLeft ? Placements.Left : Placements.Default;
}
