import { bindActionCreators } from 'redux';
import { applyState } from '../../../modules/utils';
import {
  getTempInvitedMembers,
  getTempMemberRoles
} from '../../../../../storeGetters';
import { gaSendMessengerAction } from '../../../modules/GA';
import * as temporaryAction from '../../../../../action-creators/temporary';
import * as modalAction from '../../../../../action-creators/modal';
import { showModalPickNewMembersCase } from './showModalPickNewMembers';
import { cancelCase } from './cancel';

export const showModalPickNewMembers =
  ({ chat, mode, name }) =>
  (dispatch, getState) =>
    showModalPickNewMembersCase(
      {
        getInviteMembers: applyState(getState, getTempInvitedMembers),
        getMembersRoles: applyState(getState, getTempMemberRoles),
        showModal: bindActionCreators(modalAction.showModal, dispatch),
        setInviteMembers: bindActionCreators(
          temporaryAction.setToSetTemp('inviteMembers'),
          dispatch
        ),
        setMemberRole: bindActionCreators(
          temporaryAction.setToMapTemp('membersRoles'),
          dispatch
        ),
        gaSend: bindActionCreators(gaSendMessengerAction, dispatch)
      },
      { chat, mode, name }
    );

export const onCancel = () => (dispatch) =>
  cancelCase({
    clearTemporary: bindActionCreators(
      temporaryAction.clearTemporary,
      dispatch
    ),
    onCloseModal: bindActionCreators(modalAction.hideModalDialog, dispatch)
  });
