const { defStruct } = require('../../lib');

const { makeFilterOptions, getOptions, isFilterOptions } = defStruct(
  'FilterOptions',
  ['options']
);

/**
 * @typedef FilterOptions
 * @typedef {import('./filterSelectValue')} FilterSelectValue
 */

/**
 * Create a FilterOptions
 *
 * @param {object.<string, Array>} options
 * @returns {FilterOptions}
 */
exports.makeFilterOptions = (options = {}) => makeFilterOptions(options);

/**
 * Returns FilterOptions options by name
 *
 * @param {string} name
 * @param {FilterOptions} filterOptions
 * @returns {[FilterSelectValue]}
 */
exports.findOptions = (name, filterOptions) => {
  const allOptions = getOptions(filterOptions);
  const options = allOptions[name];
  return options !== undefined ? options : [];
};

/**
 *  Checks is instance is a FilterOptions type
 *
 * @param {any} any
 * @returns {boolean}
 */
exports.isFilterOptions = (any) => isFilterOptions(any);
