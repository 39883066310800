import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import classNames from 'classnames';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import { renderQueueService } from '../../services/renderQueueService';
import { scrollToBeVisible } from './scrollToBeVisible';
import { ThumbnailContainer } from './ThumbnailContainer';

export const ThumbnailList = ({
  doc,
  currentPage,
  pageHeight,
  pageWidth,
  renderCurrentPageLabel,
  renderThumbnailItem,
  onJumpToPage
}) => {
  const { numPages } = doc;
  const { docId } = doc.loadingTask;
  const isRtl = false;

  const containerRef = useRef(null);
  const thumbnailsRef = useRef();

  const [currentFocused, setCurrentFocused] = useState(currentPage);
  const [renderPageIndex, setRenderPageIndex] = useState(-1);
  const [labels, setLabels] = useState([]);
  const numLabels = labels.length;

  const isMounted = useIsMounted();

  const pageIndexes = useMemo(
    () =>
      Array(numPages)
        .fill(1)
        .map((_, pageIndex) => pageIndex),
    [docId]
  );

  const renderQueueInstance = useMemo(
    () => renderQueueService({ doc, queueName: 'thumbnail-list' }),
    [docId]
  );

  useEffect(() => {
    doc.getPageLabels().then((data) => {
      if (isMounted.current) {
        setLabels(data || []);
      }
    });
  }, [docId]);

  const activateNextItem = () => {
    const container = containerRef.current;

    if (!container) {
      return;
    }

    const items = thumbnailsRef.current;
    const nextItem = currentFocused + 1;

    if (nextItem < items.length) {
      if (currentFocused >= 0) {
        items[currentFocused].setAttribute('tabindex', '-1');
      }
      setCurrentFocused(nextItem);
    }
  };

  const activatePreviousItem = () => {
    const container = containerRef.current;

    if (!container) {
      return;
    }

    const items = thumbnailsRef.current;
    const prevItem = currentFocused - 1;

    if (prevItem >= 0) {
      if (currentFocused >= 0) {
        items[currentFocused].setAttribute('tabindex', '-1');
      }
      setCurrentFocused(prevItem);
    }
  };

  const jumpToFocusedPage = () => {
    if (currentFocused >= 0 && currentFocused < numPages) {
      onJumpToPage(currentFocused);
    }
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowUp':
        activatePreviousItem();
        break;
      case 'ArrowDown':
        activateNextItem();
        break;
      case 'Enter':
        jumpToFocusedPage();
        break;

      default:
        break;
    }
  };

  useIsomorphicLayoutEffect(() => {
    const container = containerRef.current;

    if (!container) {
      return;
    }

    thumbnailsRef.current = Array.from(
      container.querySelectorAll('.pdf-viewer-thumbnail__item')
    );
  }, []);

  useIsomorphicLayoutEffect(() => {
    const container = containerRef.current;

    if (!container) {
      return;
    }

    const thumbnailNodes = container.children;
    if (thumbnailNodes.length > currentPage) {
      scrollToBeVisible(thumbnailNodes.item(currentPage), container);
    }
  }, [currentPage]);

  useEffect(() => {
    const thumbnails = thumbnailsRef.current;

    if (
      thumbnails.length === 0 ||
      currentFocused < 0 ||
      currentFocused > thumbnails.length
    ) {
      return;
    }

    const thumbnailElem = thumbnails[currentFocused];
    thumbnailElem.setAttribute('tabindex', '0');
    thumbnailElem.focus();
  }, [currentFocused]);

  const renderNextThumbnail = useCallback(() => {
    const nextPage = renderQueueInstance.getHighestPriorityPage();

    if (nextPage > -1) {
      renderQueueInstance.markRendered(nextPage);
      setRenderPageIndex(nextPage);
    }
  }, [docId]);

  const handleRenderCompleted = useCallback(
    (pageIndex) => {
      if (isMounted.current) {
        renderQueueInstance.markRendered(pageIndex);
        renderNextThumbnail();
      }
    },
    [docId]
  );

  const handleVisibilityChanged = useCallback(
    (pageIndex, visibility) => {
      renderQueueInstance.setVisibility(
        pageIndex,
        visibility.isVisible
          ? visibility.ratio
          : renderQueueInstance.OUT_OF_RANGE_VISIBILITY
      );
      renderNextThumbnail();
    },
    [docId]
  );

  useEffect(
    () => () => {
      renderQueueInstance.cleanup();
    },
    [docId]
  );

  return (
    <div
      ref={containerRef}
      className={classNames({
        'pdf-viewer-thumbnail__list': true,
        'pdf-viewer-thumbnail__list_rtl': isRtl
      })}
      onKeyDown={handleKeyDown}>
      {pageIndexes.map((pageIndex) => {
        const key = `${doc.loadingTask.docId}__${pageIndex}`;
        const pageLabel =
          numLabels === numPages ? labels[pageIndex] : `${pageIndex + 1}`;

        const label = renderCurrentPageLabel
          ? renderCurrentPageLabel({
              currentPage,
              pageIndex,
              numPages,
              pageLabel
            })
          : pageLabel;

        const thumbnail = (
          <ThumbnailContainer
            doc={doc}
            pageIndex={pageIndex}
            pageHeight={pageHeight}
            pageWidth={pageWidth}
            shouldRender={renderPageIndex === pageIndex}
            onRenderCompleted={handleRenderCompleted}
            onVisibilityChanged={handleVisibilityChanged}
          />
        );

        return renderThumbnailItem ? (
          renderThumbnailItem({
            key,
            currentPage,
            numPages,
            pageIndex,
            renderPageLabel: <>{label}</>,
            renderPageThumbnail: thumbnail,
            onJumpToPage: () => onJumpToPage(pageIndex)
          })
        ) : (
          <div key={key}>
            <div
              className={classNames({
                'pdf-viewer-thumbnail__item': true,
                'pdf-viewer-thumbnail__item_selected': currentPage === pageIndex
              })}
              role="button"
              tabIndex={currentPage === pageIndex ? 0 : -1}
              onClick={() => onJumpToPage(pageIndex)}>
              {thumbnail}
            </div>
            <div className="pdf-viewer-thumbnail__label">{label}</div>
          </div>
        );
      })}
    </div>
  );
};
