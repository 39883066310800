import React from 'react';

/* eslint-disable max-len */
function IconPlusRoundCircle() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 25C21.4183 25 25 21.4183 25 17C25 12.5817 21.4183 9 17 9C12.5817 9 9 12.5817 9 17C9 21.4183 12.5817 25 17 25ZM17 26C21.9706 26 26 21.9706 26 17C26 12.0294 21.9706 8 17 8C12.0294 8 8 12.0294 8 17C8 21.9706 12.0294 26 17 26Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17C12 16.7239 12.2239 16.5 12.5 16.5H21.5C21.7761 16.5 22 16.7239 22 17C22 17.2761 21.7761 17.5 21.5 17.5H12.5C12.2239 17.5 12 17.2761 12 17Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 12C17.2761 12 17.5 12.2239 17.5 12.5V21.5C17.5 21.7761 17.2761 22 17 22C16.7239 22 16.5 21.7761 16.5 21.5V12.5C16.5 12.2239 16.7239 12 17 12Z"
        fill="#767B92"
      />
      {/* <path */}
      {/*  className="cws-icon-main-fill" */}
      {/*  fillRule="evenodd" */}
      {/*  clipRule="evenodd" */}
      {/*  // d="M9.98828 17.9697C14.4066 17.9697 17.9883 14.388 17.9883 9.96973C17.9883 5.55145 14.4066 1.96973 9.98828 1.96973C5.57 1.96973 1.98828 5.55145 1.98828 9.96973C1.98828 14.388 5.57 17.9697 9.98828 17.9697ZM9.98828 18.9697C14.9588 18.9697 18.9883 14.9403 18.9883 9.96973C18.9883 4.99916 14.9588 0.969727 9.98828 0.969727C5.01772 0.969727 0.988281 4.99916 0.988281 9.96973C0.988281 14.9403 5.01772 18.9697 9.98828 18.9697Z" */}
      {/*  d="M17 25C21.4183 25 25 21.4183 25 17C25 12.5817 21.4183 9 17 9C12.5817 9 9 12.5817 9 17C9 21.4183 12.5817 25 17 25ZM17 26C21.9706 26 26 21.9706 26 17C26 12.0294 21.9706 8 17 8C12.0294 8 8 12.0294 8 17C8 21.9706 12.0294 26 17 26Z" */}
      {/*  fill="#767B92" */}
      {/* /> */}
      {/* <path */}
      {/*  className="cws-icon-main-fill" */}
      {/*  fillRule="evenodd" */}
      {/*  clipRule="evenodd" */}
      {/*  // d="M4.98828 9.96973C4.98828 9.69358 5.21214 9.46973 5.48828 9.46973H14.4883C14.7644 9.46973 14.9883 9.69358 14.9883 9.96973C14.9883 10.2459 14.7644 10.4697 14.4883 10.4697H5.48828C5.21214 10.4697 4.98828 10.2459 4.98828 9.96973Z" */}
      {/*  d="M12.5 17C12.5 16.7239 12.7239 16.5 13 16.5H21C21.2761 16.5 21.5 16.7239 21.5 17C21.5 17.2761 21.2761 17.5 21 17.5H13C12.7239 17.5 12.5 17.2761 12.5 17Z" */}
      {/*  fill="#767B92" */}
      {/* /> */}
      {/* <path */}
      {/*  className="cws-icon-main-fill" */}
      {/*  fillRule="evenodd" */}
      {/*  clipRule="evenodd" */}
      {/*  d="M9.98828 4.96973C10.2644 4.96973 10.4883 5.19358 10.4883 5.46973V14.4697C10.4883 14.7459 10.2644 14.9697 9.98828 14.9697C9.71214 14.9697 9.48828 14.7459 9.48828 14.4697V5.46973C9.48828 5.19358 9.71214 4.96973 9.98828 4.96973Z" */}
      {/*  fill="#767B92" */}
      {/* /> */}
    </svg>
  );
}
/* eslint-enable max-len */

export default IconPlusRoundCircle;
