import React from 'react';

/* eslint-disable max-len */
function IconForward() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        d="M19.6504 18.7776L23.5662 14.8482C23.81 14.6045 23.81 14.208 23.5672 13.9653L19.6521 10.0679C19.4075 9.8244 19.4066 9.42869 19.6501 9.18406C19.8936 8.9394 20.2894 8.93856 20.534 9.18206L24.4502 13.0804C25.1812 13.8115 25.1812 15.001 24.4509 15.7313L20.5358 19.6599C20.4137 19.7825 20.2534 19.8437 20.0931 19.8437C19.9335 19.8437 19.7739 19.783 19.6519 19.6614C19.4074 19.4178 19.4067 19.0221 19.6504 18.7776Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        d="M14.6097 13.7812H21.2815C21.6267 13.7812 21.9065 14.061 21.9065 14.4062C21.9065 14.7514 21.6267 15.0312 21.2815 15.0312H14.6097C12.2059 15.0312 10.2503 16.9868 10.2503 19.3905C10.2503 21.7943 12.2059 23.7499 14.6097 23.7499C14.9549 23.7499 15.2347 24.0297 15.2347 24.3749C15.2347 24.7201 14.9549 24.9999 14.6097 24.9999C11.5166 24.9999 9.0003 22.4836 9.0003 19.3905C9.0003 16.2975 11.5166 13.7812 14.6097 13.7812Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconForward;
