import React from 'react';

/* eslint-disable max-len */
function IconHide() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        d="M17.0269 11C15.9037 11 14.8275 11.2546 13.8285 11.6804L14.5901 12.4421C15.3619 12.1625 16.1801 12 17.0269 12C20.1196 12 22.8994 14.0024 23.9966 16.9999C23.5785 18.1422 22.9136 19.1369 22.0825 19.9344L22.7857 20.6376C23.7616 19.6967 24.5359 18.5196 25 17.1615C25.0357 17.0566 25.0357 16.9433 25 16.8383C23.8066 13.3462 20.6025 11 17.0269 11Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        d="M18.9784 16.8683L19.8802 17.7701C19.9458 17.5238 19.992 17.2698 19.992 17.003C19.992 15.3488 18.6464 14.0031 16.9921 14.0031C16.7253 14.0031 16.4713 14.0493 16.225 14.1149L17.1268 15.0167C18.1206 15.0843 18.9108 15.8745 18.9784 16.8683Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        d="M9.99988 10.707L12.0273 12.7345C10.6845 13.75 9.60657 15.1416 9.02674 16.8383C8.99109 16.9433 8.99109 17.0566 9.02674 17.1616C10.2201 20.6537 13.4241 22.9999 16.9998 22.9999C18.4862 22.9999 19.89 22.5631 21.1285 21.8356L23.2927 23.9999L23.9997 23.2929L10.7069 10L9.99988 10.707ZM16.9998 21.9999C13.9071 21.9999 11.1273 19.9975 10.0302 17C10.5713 15.5214 11.5437 14.3187 12.741 13.4482L14.5598 15.2669C14.2102 15.7575 13.9999 16.3531 13.9999 16.9999C13.9999 18.6542 15.3455 19.9999 16.9998 19.9999C17.6467 19.9999 18.2423 19.7895 18.7328 19.44L20.3967 21.1039C19.364 21.6635 18.2138 21.9999 16.9998 21.9999ZM15.2832 15.9904L18.0095 18.7167C17.712 18.8923 17.3696 19 16.9998 19C15.8968 19 14.9999 18.103 14.9999 17C14.9999 16.6302 15.1075 16.2878 15.2832 15.9904Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconHide;
