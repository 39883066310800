import React from 'react';
import RoundCheckbox from '../../../inputs/round-checkbox';
import '../style.css';

function ToggleAllDay({ value, onClick, disabled = false }) {
  return (
    <div className="rdp-all-day-checkbox">
      <div className="rdp-all-day-checkbox__label">Установить время</div>
      <div className="rdp-all-day-checkbox__value">
        <RoundCheckbox
          id="toggle-all-day"
          value={value}
          onClick={onClick}
          disabled={disabled}
        />
        <label htmlFor="toggle-all-day">Весь день</label>
      </div>
    </div>
  );
}

export default ToggleAllDay;
