export const mapList = (fn, list) => list.map((g) => fn(g));

export const makeDashboardVM = ({
  activeTab,
  chatUnreadMsgsCount,
  handler,
  config
}) =>
  config.map((tab) => ({
    currentTab: tab,
    isActive: activeTab === tab,
    chatUnreadMsgsCount,
    handler
  }));

export const getCurrentTab = (vm) => vm.currentTab;
export const getKey = (vm) => `${getCurrentTab(vm)}`;
export const getUnreadMsgCount = (vm) =>
  vm.chatUnreadMsgsCount[getCurrentTab(vm)];
export const isActiveTab = (vm) => vm.isActive;
export const getTooltipText = (vm) => `tab-${vm.currentTab}`;
export const isIconHasUnreadShown = (vm) =>
  getUnreadMsgCount(vm) > 0 && !isActiveTab(vm);
export const initOnTabClick = (vm) => () => vm.handler(getCurrentTab(vm));
