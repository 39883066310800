import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handle } from 'core/data/messageBus/frontend';

import { getInterlocutor } from 'core/data/light/dialog';
import './style.css';

import CWDashboard from 'react-components/src/components/ChatWidget/components/CWDashboard';
import { onlineQRY } from 'unicat-api/src/modules/online/domain/messages';
import { transformToMV, initOnClickAvatars } from '../../main/Avatars/data';
import * as handlers from './wrapper';
import { useTranslate } from '../../../../TranslateProvider';
import { emitToServer } from '../../../../storage/message';
import * as chatType from '../../helpers/chatType';

const FIVE_MINUTES = 1000 * 60 * 5;

const getHandler = ({
  handlerDialog,
  handlerChannel,
  handlerTopic,
  handlerPurchaseRequestsChannel,
  handlerNewsFeedChannel,
  chat
}) => {
  if (chatType.isChannel(chat)) return handlerChannel;
  if (chatType.isTopic(chat)) return handlerTopic;
  if (chatType.isDialog(chat)) return handlerDialog;
  if (chatType.isPurchaseRequestsChannel(chat))
    return handlerPurchaseRequestsChannel;
  if (chatType.isNewsFeedChannel(chat)) return handlerNewsFeedChannel;
  throw new Error(`Not handler for such type: ${chat.type}`);
};

const getLastSeenStatus = (lastSeen, loading = false) => {
  if (loading) {
    return 'unknown';
  }
  if (lastSeen === null) {
    return 'online';
  }
  const lastSeenDate = new Date(lastSeen);
  const nowDate = new Date();
  const minutesGap = (nowDate - lastSeenDate) / 1000 / 60;
  const oneDayInMinutes = 24 * 60;

  if (minutesGap < 5) {
    return 'justNow';
  }
  if (minutesGap < 30) {
    return 'recently';
  }
  if (minutesGap < 60) {
    return 'halfAnHour';
  }
  if (minutesGap < 180) {
    return 'moreThanHour';
  }
  if (minutesGap < oneDayInMinutes) {
    return 'today';
  }
  const yesterday = new Date().getDate() - 1;
  const twoDaysInMinutes = 2 * oneDayInMinutes;
  const weekInMinutes = 7 * oneDayInMinutes;

  if (lastSeenDate.getDate() === yesterday && twoDaysInMinutes > minutesGap) {
    return 'yesterday';
  }

  if (minutesGap < weekInMinutes) {
    return 'week';
  }

  return 'longAgo';
};

const CWMDashboard = ({
  chat,
  avatarLists,
  user,
  isActive,
  handlerDialog,
  handlerChannel,
  handlerTopic,
  handlerPurchaseRequestsChannel,
  handlerNewsFeedChannel,
  config
}) => {
  const [counter, setCounter] = useState(0);
  const [lastSeen, setLastSeen] = useState(null);
  const [isLoadingOnlineStatus, setLoadingOnlineStatus] = useState(true);
  const t = useTranslate();
  const onClickAvatar = useCallback(() => {
    if (chat.type === 'Dialog') {
      handlerDialog('view-profile', chat);
    }
  }, [chat]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCounter(counter + 1);
    }, FIVE_MINUTES);
    return () => clearTimeout(timeout);
  }, [chat]);

  const interlocutor = getInterlocutor(user.get('employeeId'), chat);

  useEffect(() => {
    if (chatType.isDialog(chat)) {
      emitToServer(onlineQRY.sync, { employeeId: interlocutor.employeeId });
    }
    return () =>
      chatType.isDialog(chat) &&
      emitToServer(onlineQRY.syncOff, { employeeId: interlocutor.employeeId });
  }, [chat]);

  useEffect(
    () =>
      handle(onlineQRY.update, (timestamp) => {
        setLastSeen(timestamp.lastSeen);
        setLoadingOnlineStatus(false);
      }),
    []
  );

  const onBackClick = () => {
    handlerDialog('exit-chat', chat);
  };

  const handleClick = getHandler({
    handlerDialog,
    handlerChannel,
    handlerTopic,
    handlerPurchaseRequestsChannel,
    handlerNewsFeedChannel,
    chat
  });
  const onClickAvatars = initOnClickAvatars({ handleClick, chat, user });
  const isCursorPointer =
    config.theme === 'main-chat' && chat.type === 'Dialog';

  const status = t(
    `lastSeenStatuses.${getLastSeenStatus(lastSeen, isLoadingOnlineStatus)}`
  );

  return (
    <CWDashboard
      chat={chat}
      user={user}
      config={config}
      avatarLists={avatarLists}
      isActive={isActive}
      isCursorPointer={isCursorPointer}
      interlocutor={interlocutor}
      onBackClick={onBackClick}
      onClickAvatar={onClickAvatar}
      onClickAvatars={onClickAvatars}
      status={status}
      onHandleClick={handleClick}
    />
  );
};

export default connect(
  (state, props) => ({
    user: state.getIn(['user', 'user']),
    avatarLists: transformToMV({
      chat: props.chat,
      user: state.getIn(['user', 'user'])
    })
  }),
  (dispatch) =>
    bindActionCreators(
      {
        handlerDialog: handlers.handleDashboardDialog,
        handlerChannel: handlers.handleDashboardChannel,
        handlerTopic: handlers.handleDashboardTopic,
        handlerPurchaseRequestsChannel: handlers.handlePurchaseRequestsChannel,
        handlerNewsFeedChannel: handlers.handleNewsFeedChannel
      },
      dispatch
    )
)(CWMDashboard);
