import React from 'react';

const IconWarning = () => (
  <svg
    width="30"
    height="25"
    viewBox="0 0 30 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 1L29 24H1L15 1Z"
      stroke="#C6CAE3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 9V16.5"
      stroke="#C6CAE3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 19.75C16.25 20.4404 15.6904 21 15 21C14.3096 21 13.75 20.4404 13.75 19.75C13.75 19.0596 14.3096 18.5 15 18.5C15.6904 18.5 16.25 19.0596 16.25 19.75Z" // eslint-disable-line
      fill="#C6CAE3"
    />
  </svg>
);

export default IconWarning;
