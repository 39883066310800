import { defRecord } from 'core/lib';
import * as chatTypes from '../../../../../helpers/chatType';

const { makeChatInfoVM, getInterlocutor } = defRecord('ChatInfoVM', [
  't',
  'chat',
  'online',
  'interlocutor'
]);

const getChatType = (vm) => vm.chat.hardcodeType || vm.chat.type;
const getChatName = (vm) => vm.chat.name;

const getExtraText = (vm) => {
  if (chatTypes.isDialog(getChatType(vm))) {
    return vm.interlocutor.companyName;
  }

  return `${vm.t('chat-widget.chat-item.members')} ${
    vm.chat.members.length
  }, online ${vm.online}`;
};

export {
  makeChatInfoVM,
  getChatType,
  getChatName,
  getExtraText,
  getInterlocutor
};
