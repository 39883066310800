import React from 'react';
import PropTypes from 'prop-types';
import './ModalDocxViewer.css';

const DocxViewerDocument = ({ children }) => (
  <div className="docx-viewer-document__container">
    <div className="docx-viewer-document__page">{children}</div>
  </div>
);

DocxViewerDocument.propTypes = {
  children: PropTypes.node.isRequired
};

export default DocxViewerDocument;
