import React, { memo, useCallback } from 'react';
import { ButtonIcon, Input, Typography } from '@link/react-components';

import styles from './RequestKPByEmail.module.css';
import { useTranslate } from '../../../TranslateProvider';

export const EmailRequestInput = memo(
  ({ errorMessage, id, deleteEmail, onChange, showAdornment }) => {
    const t = useTranslate();

    const onChangeEmail = useCallback((value) => {
      onChange(value, id);
    }, []);

    const onDelete = useCallback(() => {
      deleteEmail(id);
    }, []);

    return (
      <div className={styles.emailRequestInput}>
        <Input
          placeholder={t('requestKPByEmail.placeholder')}
          onChange={onChangeEmail}
          valid={!errorMessage}
          autocompleteOnlyByFocus
          endAdornment={
            showAdornment ? (
              <ButtonIcon
                size={14}
                iconName="trash"
                variant="medium"
                onClick={onDelete}
              />
            ) : null
          }
        />

        {errorMessage && (
          <Typography variant="caption" className={styles.error}>
            {t(errorMessage)}
          </Typography>
        )}
      </div>
    );
  }
);
