import { isNil, take } from 'ramda';
import { haveActivatedMS } from 'core/data/light/channelMember';
import { findMember } from 'core/data/light/channel';
import { haveParticipantPermission, haveReaderPermission } from '../../roles';

// Data definition
const makeAvatar = ({ avatarSrc, employeeId }) => ({ avatarSrc, employeeId });
const makeAvatarLists = ({ avatars, total }) => ({ avatars, total });

// Example of data
export const getFakeData = () =>
  makeAvatarLists({
    avatars: [
      makeAvatar({
        avatarSrc:
          // eslint-disable-next-line
          'https://img.favpng.com/25/7/23/computer-icons-user-profile-avatar-image-png-favpng-LFqDyLRhe3PBXM0sx2LufsGFU.jpg',
        employeeId: 9
      }),
      makeAvatar({
        avatarSrc:
          'https://prodenv01disks.blob.core.windows.net/public-media/83f1e465.jpg',
        employeeId: 5
      }),
      makeAvatar({ avatarSrc: '', employeeId: 4 })
    ],
    total: 3
  });

function isCurrentMemberActivated(chat, currentEmployeeId) {
  const currentMember = findMember(chat, currentEmployeeId);

  if (isNil(currentMember)) return false;

  return haveActivatedMS(currentMember);
}

const isNotShow = (chat, user) =>
  !isCurrentMemberActivated(chat, user.get('employeeId')) ||
  haveReaderPermission(chat, user) ||
  !['Channel', 'Topic'].includes(chat.type);

export const transformToMV = ({ chat, user, maxDisplay = 3 }) => {
  if (isNotShow(chat, user)) return null;
  const members = getOnlyActiveMembers(chat.members);

  const avatars = take(maxDisplay, members).map(transformMember);
  const total = members.length;

  return makeAvatarLists({ avatars, total });

  function transformMember(member) {
    return makeAvatar({
      avatarSrc: member.avatarSrc,
      employeeId: member.employeeId
    });
  }

  function getOnlyActiveMembers(allMembers) {
    return allMembers.filter(haveActivatedMS);
  }
};

export const initOnClickAvatars =
  ({ handleClick, chat, user }) =>
  () => {
    handleClick(getEvent(), chat);

    function getEvent() {
      if (haveParticipantPermission(chat, user)) return 'view';
      return 'edit';
    }
  };
