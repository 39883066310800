import {
  initState,
  setProductPositionsCategories,
  setProductPositions,
  selectCategory,
  setSelectCategories,
  clearSelectCategories,
  clearTable,
  selectProductPosition,
  clearSelectedProductPositions,
  setCurrency,
  updatePositionPrice,
  updateProductRetailPrice,
  deleteCategories,
  deletePositions,
  updateProductTotalPrice
} from '../work-with-data/product-positions';

const updateProductPrice = (data, { id, categoryId, cost }) =>
  data.updateIn(['items', categoryId], (products) => {
    const index = products.findIndex((i) => i.get('id') === id);
    return products.setIn([index, 'cost'], cost);
  });

const deleteProductPositions = (data, { productPositions, categoryId }) =>
  data.updateIn(['items', categoryId.toString()], (products) =>
    products.filter((i) => !productPositions.includes(i.get('id')))
  );

const productPositionsReducer = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'PRICE_CATEGORIES:GET_PRICE_CATEGORIES:SUCCESS':
      return setProductPositionsCategories(state, payload);
    case 'PRODUCT_POSITIONS:GET_PRODUCT_POSITIONS:SUCCESS':
      return setProductPositions(state, payload);
    case 'PRODUCT_POSITIONS:SELECT_CATEGORY':
      return selectCategory(state, payload);
    case 'PRODUCT_POSITIONS:SET_SELECT_CATEGORY':
      return setSelectCategories(state, payload);
    case 'PRODUCT_POSITIONS:CLEAR_SELECT_CATEGORY':
      return clearSelectCategories(state);
    case 'PRODUCT_POSITIONS:CLEAR_TABLE':
      return clearTable(state, payload);
    case 'PRODUCT_POSITIONS:SELECT':
      return selectProductPosition(state, payload);
    case 'PRODUCT_POSITIONS:DESELECT':
      return clearSelectedProductPositions(state);
    case 'PRODUCT_POSITIONS:SET_CURRENCY':
      return setCurrency(state, payload);
    case 'PRODUCT_POSITIONS:UPDATE_DISCOUNT:SUCCESS':
      return updatePositionPrice(state, payload);
    case 'PRODUCT_POSITIONS:UPDATE_TOTAL_PRICE:SUCCESS':
      return updateProductTotalPrice(state, payload);
    case 'PRODUCT_POSITIONS:UPDATE_RETAIL:SUCCESS':
      return updateProductRetailPrice(state, payload);
    case 'PRICE:DELETE_CATEGORY:SUCCESS':
      return deleteCategories(state, payload);
    case 'PRICE:PRODUCT_DELETE:SUCCESS':
      return deletePositions(state, payload);
    case 'PRODUCT_POSITIONS:UPDATE_RETAIL:SUCCES':
      return updateProductPrice(state, payload);
    case 'PRICE:PRODUCT_DELETE:SUCCESSS':
      return deleteProductPositions(state, payload);
    default:
      return state;
  }
};

export default productPositionsReducer;
