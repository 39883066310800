import { append, isNil } from 'ramda';
import { format, formatRelative, isThisYear } from 'date-fns';
import { enGB, ru } from 'date-fns/locale';
import { hasPopUpSN } from '../chat-system-notification/lib';

export const canLoadNext = (amountLeftNext) => amountLeftNext > 0;
export const canLoadPrev = (amountLeftPrev) => amountLeftPrev > 0;

const prepareDate = (chat) =>
  isNil(chat.lastMessage) ? chat.createdAt : chat.lastMessage.time;
const makePopUpSN = (chat) => ({
  type: 'pop-up-system-notification',
  time: prepareDate(chat)
});
export const addPopUpSN = ({
  chat,
  messages,
  isCanLoadNext,
  popUpSystemNotificationData,
  isShowPopUpSystemNotification
}) => {
  if (
    isShowPopUpSystemNotification &&
    !isCanLoadNext &&
    hasPopUpSN(popUpSystemNotificationData)
  ) {
    const popUpSN = makePopUpSN(chat);
    return append(popUpSN, messages);
  }
  return messages;
};

export function isUnsentMessage(message) {
  return message.type === 'MsgUnsent';
}

export function isDeliveredMessage(message) {
  return !isUnsentMessage(message);
}

const getLocaleForDate = (language) => {
  if (language === 'ru') return ru;
  return enGB;
};

export const getFormattedDate = (t, date, language) => {
  const parts = date.split('.');
  const preparedDate = new Date(parts[2], parts[1] - 1, parts[0]);

  const locale = {
    ...getLocaleForDate(language),
    formatRelative: getFormatRelativeLocale
  };

  if (!isThisYear(preparedDate))
    return format(preparedDate, 'EEEE, d MMMM yyyy', { locale });

  return formatRelative(preparedDate, new Date(), { locale });

  function getFormatRelativeLocale(token) {
    const formatRelativeLocale = {
      lastWeek: 'EEEE, d MMMM',
      yesterday: `'${t('yesterday')}', d MMMM`,
      today: `'${t('today')}', d MMMM`,
      tomorrow: 'EEEE, d MMMM',
      nextWeek: 'EEEE, d MMMM',
      other: 'EEEE, d MMMM'
    };

    return formatRelativeLocale[token];
  }
};

export const createConfig = (
  messageConfig,
  configName,
  btnName,
  ...modifiers
) => {
  if (isNil(messageConfig[configName])) return null;

  const messageButtons = getConfigField('buttonList');
  const showReadMsg = getConfigField('showReadMsg');
  const position = getConfigField('position');
  const possiblePositions = getConfigField('possiblePositions');

  const buttonList = createButtonList(messageButtons, ...modifiers);
  const extraBtnList = getConfigField('extraBtn').filter(
    (extBtn) => !buttonList.some((btnL) => extBtn.value === btnL.value)
  );

  const extraBtn = getExtraBtn(btnName, extraBtnList);
  return { extraBtn, buttonList, showReadMsg, position, possiblePositions };

  function getConfigField(field) {
    const searchedConfig = messageConfig[configName];
    return searchedConfig[field];
  }

  function getExtraBtn(name, extraList) {
    if (!Array.isArray(extraList)) return extraBtnList;

    return { name, list: createList(extraList, ...modifiers) };
  }

  function createButtonList(list, isSelectMode) {
    if (isSelectMode) {
      return [];
    }
    return list;
  }

  function createList(list, isSelectMode, archived) {
    if (isSelectMode)
      return list.filter((i) => i.value === 'highlight-message');
    if (archived) return list.filter((i) => i.conditionName !== 'allowEdit?');
    return list;
  }
};

export const scrollToLastMessage = (ref) => {
  const { current: el } = ref;
  if (el) {
    el.scrollTop = el.scrollHeight;
  }
};
