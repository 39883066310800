import React from 'react';
import { isNil } from 'ramda';
import SmallCrossSVG from '../../images/cross-small';
import { isShowRemoveIcon } from '../utils';
import { isFunction } from '../../utils';
import './style.css';

function Chip({ id, value, color, onClick, onRemove }) {
  return (
    <div
      className="simple-chip"
      style={!isNil(color) ? { backgroundColor: color } : undefined}
      onClick={(event) =>
        isFunction(onClick) ? onClick(event, id) : undefined
      }>
      <span className="simple-chip__value">{value}</span>
      {isShowRemoveIcon(onRemove) && (
        <div
          className="simple-chip__remove-icon"
          onClick={(event) => onRemove(event, id)}>
          <SmallCrossSVG />
        </div>
      )}
    </div>
  );
}

export default Chip;
