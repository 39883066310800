.collapsibleArea {
  display: flex;
  flex-direction: column;
}

.visibleHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.visibleContent {
  flex-grow: 1;
}

.button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  align-self: center;
  width: 14px;
  height: 14px;
}

.collapsibleContent {
  display: flex;
  justify-content: space-between;
}

.horizontalLine {
  color: var(--color-gray-300);
  opacity: 0.3;
  size: 1px;
  width: 100%;
}
