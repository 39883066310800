import { defRecord, isType } from 'core/lib';
import { isEmpty } from 'ramda';
import cn from 'classnames';
import { makeAttachesVM } from './attaches/dataType';
import { convertUrl } from '../../../../../modules/files/utils';

const { makeLastMessageVM } = defRecord(
  'LastMessageVM',
  ['t', 'chat', 'message'],
  { chat: {} }
);

function getLastMessage(vm) {
  return vm.chat.lastMessage;
}

const isShowAvatar = (vm) => vm.message.avatar.show;
const isOwnMessage = (vm) => vm.message.isOwn;
const isShowSenderName = (vm) => !isEmpty(vm.message.userName);
const isShowAttaches = (vm) => {
  const lastMessage = getLastMessage(vm);

  return isType('MsgUser', lastMessage) && !isEmpty(lastMessage.files);
};

const getCNMessage = (vm) =>
  `cws-chat-last-message ${vm.message.isUnread ? 'unread' : ''}`;
const getCNMessageText = (vm) =>
  `cws-chat-last-message__message cws-chat-last-message__message_${vm.message.type.toLowerCase()}`;
const getCNMessageUserName = (vm) =>
  cn('cws-chat-last-message__user-name', { own: isOwnMessage(vm) });

const generateAttachesVM = (vm) =>
  makeAttachesVM({ t: vm.t, message: getLastMessage(vm) });

const getAvatar = (vm) => convertUrl(vm.message.avatar.src);
const getAvatarAlt = (vm) => vm.message.avatar.alt;
const getSenderName = (vm) => vm.message.userName;
const getMessageText = (vm) => vm.message.text;

export {
  makeLastMessageVM,
  getCNMessage,
  getCNMessageText,
  isShowAvatar,
  isShowSenderName,
  isShowAttaches,
  generateAttachesVM,
  getAvatar,
  getAvatarAlt,
  getSenderName,
  getMessageText,
  getCNMessageUserName
};
