import { useState } from 'react';
import { curry } from 'ramda';

export const initUseStateDataType =
  (constructor) =>
  (...data) => {
    const [state, setState] = useState(constructor(...data));
    return { state, setState };
  };
export const persistState = (fnNextState, hookDataType) =>
  hookDataType.setState(fnNextState);
export const getState = (hookDataType) => hookDataType.state;

export const getData = curry((getter, hookDataType) =>
  getter(getState(hookDataType))
);
export const updateState = curry((updaterState, nextState, hookDataType) =>
  persistState((state) => updaterState(nextState, state), hookDataType)
);
