import customHistory from '../../customHistory';
import { getTempField, getContacts, getChannel } from '../../storeGetters';
import { getEditFiles } from './getter';
import { addParticipantsToTopic } from '../../action-creators/modalSets';
import { gaSend } from '../../action-creators/services';
import * as storage from './storage';
import * as tempActions from '../../action-creators/temporary';
import {
  setErrorMessage,
  setInfoMessage,
  clearMessages
} from '../../action-creators/message';
import * as channelApi from '../../storage/channel.storage';
import { filesLoadHandler, MODES } from '../../useCases/files';

export const createTopic =
  ({ name, desc }) =>
  (dispatch, getState) => {
    const state = getState();
    const contacts = getContacts(state);
    const filesToAdd = getTempField(state, 'filesToAdd', []);

    const submit = async (employeesId, membersRoles) => {
      const topic = await storage.createTopic({
        name,
        desc,
        files: filesToAdd,
        employeesId,
        membersRoles
      });
      customHistory.push(`/topics/${topic.id}`);

      const userEmail = state.getIn(['user', 'user', 'email']);
      gaSend({
        category: 'Common',
        action: 'topic_chat_create',
        label: userEmail
      })(dispatch);
    };

    const cancel = () => storage.clearUnsaveInfoFile('_');

    dispatch(
      addParticipantsToTopic({
        submit,
        dialogs: contacts,
        members: [],
        purpose: 'Create',
        btnSubmitText: 'Create',
        cancel
      })
    );
  };

export const uploadTopicUnsaveInfoFiles = () => async (dispatch, getState) => {
  const mainChannel = getChannel(getState());
  const files = await storage.getTopicUnsaveInfoFiles(mainChannel.id);
  dispatch(tempActions.setKeyValueTemp('filesToAdd', files));
};

export const initEditTopic = () => async (dispatch, getState) => {
  const state = getState();
  const mainChannel = getChannel(state);
  const files = await storage.getTopicUnsaveInfoFiles(mainChannel.id);
  if (mainChannel.name !== '') {
    dispatch(
      tempActions.setTemporary({
        name: mainChannel.name,
        desc: mainChannel.desc,
        filesToAdd: files
      })
    );
  }
};

export const cancelEditTopic = () => (dispatch, getState) => {
  const mainChannel = getChannel(getState());
  storage.clearUnsaveInfoFile(mainChannel.id);
};

export const clearTopicEditInfo = () => (dispatch) => {
  dispatch(tempActions.clearTemporary());
  dispatch(clearMessages());
};

export const removeFile = (file) => async (dispatch, getState) => {
  const state = getState();
  const mainChannel = getChannel(state);
  const filesToAdd = getTempField(state, 'filesToAdd', []);

  const hasInChannel = mainChannel.files.map((f) => f.id).includes(file.id);
  const hasInAdd = filesToAdd.map((f) => f.id).includes(file.id);

  if (hasInChannel) {
    dispatch(tempActions.appendToSetTemp('filesToRemove')(file.id));
  } else if (hasInAdd) {
    await storage.removeUnsaveInfoFile(mainChannel.id, file.id);
  }

  await uploadTopicUnsaveInfoFiles()(dispatch, getState);
};

export const filesLoadHandlerTopicInfo =
  (files, maxFiles = 15) =>
  async (dispatch, getState) => {
    const state = getState();
    const mainChannel = getChannel(state);
    const attachedFiles = getEditFiles(state);

    await filesLoadHandler({
      mode: MODES.Exclude,
      files,
      attachedFiles,
      maxFiles,
      beforeLoading: () =>
        dispatch(setInfoMessage({ key: 'File upload started' })),
      loadFile: async (file) =>
        storage.attachUnsaveInfoFile(mainChannel.id, file.name, file),
      onError: (key, params) => dispatch(setErrorMessage({ key, params })),
      onLoaded: async () => {
        await uploadTopicUnsaveInfoFiles()(dispatch, getState);
      },
      includeToAllowExt: ['pptx', 'ppt']
    });
  };

export const updateTopic =
  ({ name, desc }) =>
  (dispatch, getState) => {
    const state = getState();
    const topic = getChannel(state);
    const filesToAdd = getTempField(state, 'filesToAdd', []);
    const filesToRemove = getTempField(state, 'filesToRemove', []);
    channelApi.updateChannel(topic.id, {
      name,
      desc,
      filesToAdd,
      filesToRemove
    });
  };
