import React from 'react';
import Header from './header';
import Content from './content';
import './style.css';

const CWFavorites = ({ config }) => (
  <div className="cw-favorites">
    <Header config={config} />
    <Content config={config} />
  </div>
);

export default React.memo(CWFavorites);
