import React, { useState } from 'react';
import { FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate, useNavigate, useLocation } from '@hooks';
import { Modal, Button } from '@link/react-components';
import styled from 'styled-components';
import Input from '../../../Input/Input';

import * as modalActions from '../../../../action-creators/modal';
import * as serviceActions from '../../../../action-creators/services';
import * as groupChatsUC from '../../modules/groupChats';
import { getContacts, getCurrentUserEmail } from '../../../../storeGetters';
import {
  makeCreateGroupChatMV,
  getName,
  getCounter,
  getMaxLength,
  isSubmitDisable,
  isShowError,
  checkErrors,
  initHideModal,
  initOnChangeName,
  initOnCreateGroupChat
} from './dataType';
import { isWidget } from '../../../../lib/goodwix';

const MAX_CHAT_NAME_LENGTH = 60;

export const wrapper = () => {
  const dispatch = useDispatch();
  const contacts = useSelector(getContacts);
  const userEmail = useSelector(getCurrentUserEmail);
  const navigate = useNavigate();
  const location = useLocation();

  const hideModal = () => dispatch(modalActions.hideModal());
  const showAddMembersModal = (props) =>
    dispatch(modalActions.showModal('CW_GROUP_CHAT_ADD_MEMBERS', props));
  const createGroupChat = (name, membersIds, roles) =>
    dispatch(groupChatsUC.createGroupChat(name, membersIds, roles));
  const gaSendGroupChatCreate = () =>
    dispatch(
      serviceActions.gaSend({
        category: 'Messenger',
        action: 'group_chat_create',
        label: userEmail
      })
    );

  const [name, setName] = useState('');
  const [isPristine, setPristine] = useState(true);

  const onChangeName = (value) => {
    const reducedName = value.substr(0, MAX_CHAT_NAME_LENGTH);
    setName(reducedName);
    setPristine(false);
    return reducedName;
  };

  const onCreateGroupChat = () => {
    hideModal();
    showAddMembersModal({
      mode: 'Channel',
      submit: async (membersIds, membersRoles) => {
        const chat = await createGroupChat(name, membersIds, membersRoles);
        gaSendGroupChatCreate();

        if (!isWidget(location.pathname)) {
          navigate(`/chat/${chat.id}`);
        }
      },
      resetMembersIds: true,
      resetMembersRoles: true,
      dialogs: contacts || []
    });
  };

  return makeCreateGroupChatMV({
    name,
    isPristine,
    onChangeName,
    onCreateGroupChat,
    hideModal,
    maxLength: MAX_CHAT_NAME_LENGTH
  });
};

const CWModalCreateGroupChat = () => {
  const t = useTranslate();

  const viewModel = wrapper();

  const onClose = initHideModal(viewModel);

  const submitDisabled = isSubmitDisable(viewModel);

  const onSubmit = initOnCreateGroupChat(viewModel);

  return (
    <Modal onClose={onClose} open>
      <Modal.Header>{t('create_group_chat')}</Modal.Header>
      <Modal.Content>
        <FormWrapper controlId="txt" validationState={checkErrors(viewModel)}>
          <Label>{t('Name')}</Label>
          <Counter className="pull-right">
            <small>{getCounter(viewModel)}</small>
          </Counter>
          <Input
            value={getName(viewModel)}
            maxLength={getMaxLength(viewModel)}
            onChange={initOnChangeName(viewModel)}
            onSubmit={initOnCreateGroupChat(viewModel)}
            placeholder={t('purchaseRequest.filter.placeholders.default')}
            hideBtn
            hideCounter
          />
          {isShowError(viewModel) && (
            <HintError>{t('Required fields empty')}</HintError>
          )}
        </FormWrapper>
      </Modal.Content>
      <Modal.Footer>
        <Modal.Actions>
          <Button mode="text" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button mode="primary" onClick={onSubmit} disabled={submitDisabled}>
            {t('Next')}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

const FormWrapper = styled(FormGroup)`
  overflow: hidden;
`;

const Label = styled(ControlLabel)`
  font-size: 12px;
`;

const Counter = styled.h6`
  margin-bottom: 0;
`;

const HintError = styled(HelpBlock)`
  margin-bottom: 0;
`;

export default CWModalCreateGroupChat;
