export function send({ onSend, onSubmit }) {
  onSend(({ html, msg, mentions, files }) => {
    onSubmit({
      html,
      msg,
      mentions,
      files
    });
  });
}
