import React from 'react';

/* eslint-disable max-len */
const IconEdit = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-stroke"
      d="M15.2176 6.57141L5.17069 16.5H1.5V12.8293L11.4287 2.78247C12.0134 2.19076 12.968 2.18794 13.5563 2.77619L15.2239 4.44385C15.8121 5.03209 15.8093 5.98667 15.2176 6.57141Z"
      stroke="#767B92"
    />
    <line
      className="cws-icon-main-stroke"
      x1="10.3536"
      y1="3.64645"
      x2="14.3536"
      y2="7.64645"
      stroke="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconEdit;
