import React from 'react';
import Loader from '../components/Loader';
import { clearData, confirmClearData } from '../storage/lib/LS';

const ClearDataPage = ({ history }) => {
  confirmClearData(clearData);
  history.push('/');

  return <Loader theme="page" />;
};

export default ClearDataPage;
