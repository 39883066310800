export const PurchaseRequestStatuses = {
  draft: 'draft',
  receivingResponses: 'receiving_responses',
  receptionCompleted: 'reception_completed',
  archived: 'archived',
  onApproval: 'on_approval',
  onIntroduction: 'on_introduction',
  denialOfApproval: 'denial_of_approval',
  forPublication: 'for_publication',
  agreed: 'agreed',
  notified: 'notified'
};
