import React from 'react';
import { isNil } from 'ramda';
import { format } from 'date-fns';
import DateInput from '../../../inputs/date-input';
import TimeInput from '../../../inputs/time-input';
import '../style.css';

function getDate(range, type = 'start') {
  if (!range[type]) return '';

  return format(new Date(range[type]), 'dd.MM.yyyy');
}

function ControlPanel({
  dateMask,
  rangeDate,
  isActiveStart,
  onInputDate,
  onInputTime,
  onChangeActiveTab,
  onReset,
  onApply,
  onFocus,
  isAllDayChecked
}) {
  return (
    <div className="rdp-control-panel">
      <div className="rdp-control-panel__body">
        <div
          className={`rdp-control-panel__date-block${
            isActiveStart ? ' rdp-control-panel__date-block_active' : ''
          }`}>
          <div className="rdp-control-panel__date-block-label">Дата начала</div>
          <div className="rdp-control-panel__inputs-wrapper">
            <DateInput
              className="rdp-control-panel__input rdp-control-panel__input_date"
              id="start-date"
              mask={dateMask}
              range={rangeDate}
              value={getDate(rangeDate, 'start')}
              placeholder="Начало"
              showMaskOnFocus
              onCompile={onInputDate}
              onFocus={onChangeActiveTab}
              autocorrect
            />
            <TimeInput
              className="rdp-control-panel__input rdp-control-panel__input_time"
              id="start-time"
              maskSeparator=":"
              date={rangeDate.start}
              range={rangeDate}
              setDate={onInputTime('start-time')}
              disabled={isNil(rangeDate.start) || isAllDayChecked}
              onFocus={onFocus}
            />
          </div>
        </div>

        <div
          className={`rdp-control-panel__date-block${
            isActiveStart ? '' : ' rdp-control-panel__date-block_active'
          }`}>
          <div className="rdp-control-panel__date-block-label">
            Дата завершения
          </div>
          <div className="rdp-control-panel__inputs-wrapper">
            <DateInput
              className="rdp-control-panel__input rdp-control-panel__input_date"
              id="end-date"
              mask={dateMask}
              range={rangeDate}
              value={getDate(rangeDate, 'end')}
              placeholder="Конец"
              showMaskOnFocus
              onCompile={onInputDate}
              onFocus={onChangeActiveTab}
              autocorrect
            />
            <TimeInput
              className="rdp-control-panel__input rdp-control-panel__input_time"
              id="end-time"
              maskSeparator=":"
              date={rangeDate.end}
              range={rangeDate}
              setDate={onInputTime('end-time')}
              disabled={isNil(rangeDate.end) || isAllDayChecked}
              onFocus={onFocus}
            />
          </div>
        </div>
      </div>

      <div className="rdp-control-panel__footer">
        <div className="rdp-control-panel__btn-block">
          <button
            type="button"
            className="rdp-control-panel__btn-text rdp-control-panel__btn-text_secondary"
            onClick={onReset}>
            Очистить
          </button>
          <button
            type="button"
            className="rdp-control-panel__btn-text rdp-control-panel__btn-text_primary"
            onClick={onApply}>
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
}

export default ControlPanel;
