import React from 'react';
import './style.css';
import ProductsTable from '../../../PurchaseRequests/common/ProductsTable';
import { useTranslate } from '../../../../TranslateProvider';

const Table = ({ mode, products }) => {
  const t = useTranslate();

  if (mode === 'message') {
    return (
      <div className="cw-topic__products-table">
        {t('content_hidden_because_of_rework')}
      </div>
    );
  }

  if (mode === 'table') {
    return (
      <div className="cw-topic__products-table">
        <ProductsTable
          products={products}
          editPriceAndCount={false}
          editMode={false}
        />
      </div>
    );
  }

  return null; // mode none
};

export default Table;
