import { isNil, isEmpty } from 'ramda';
import { isType } from 'core/lib';
import { isMsgSystem } from 'core/data/light/message/msg-system';
import { containsUnreadMsg, findMember } from 'core/data/light/mainChannel';
import { PURCHASE_REQUESTS_MESSAGE_TYPE } from 'core/data/light/purchaseRequestChannel';
import { isNewsMessage } from 'core/data/light/message/msg-news';
import getMsgSystemText from '../../../../../SystemMsgTranslates';

// Fixme merge this file with frontend-web-publisher/src/components/ChatWidget/sidebar/list/chat-list-item/item/last-message/msg.js
const getAvatarSrcOrDefault = (avatarable) =>
  avatarable.avatarSrc || '/img/user.svg';

const isOwnMsg = (chat, user) =>
  isType('MsgUser', chat.lastMessage) &&
  chat.lastMessage.employeeId === user.get('employeeId');

function isShowAvatar(message) {
  return (
    !isType('MsgSubscribeToChannel', message) &&
    !isType('MsgUpdateSubscribeToChannel', message) &&
    !isType('MsgUnsubscribeFromChannel', message) &&
    !isType('MsgAcceptResponse', message) &&
    !isType('MsgRejectResponse', message) &&
    !isType('MsgVideoRoomWasCreated', message) &&
    !isType('MsgSendToReworkResponse', message) &&
    !isType(PURCHASE_REQUESTS_MESSAGE_TYPE, message)
  );
}

const getUserName = (t, chat, user) => {
  const { lastMessage } = chat;

  if (isType('MsgReply', lastMessage)) {
    const { userName } = lastMessage;
    const firstName = (userName || '').split(' ')[1];
    return isOwnMsg(chat, user)
      ? `${t('chat-widget.chat.you')}: `
      : `${firstName}: `;
  }
  if (!isType('MsgUser', lastMessage)) return '';

  const { userName } = lastMessage;
  const firstName = (userName || '').split(' ')[1];
  return isOwnMsg(chat, user)
    ? `${t('chat-widget.chat.you')}: `
    : `${firstName}: `;
};

const getAvatarSrcForSystemMsg = (chat) => {
  const message = chat.lastMessage;
  let employeeId;
  if (
    isType('MsgCreatedChannel', message) ||
    isType('MsgLeftFromChannel', message) ||
    isType('MsgRenamedChannel', message) ||
    isType('MsgRegainedStatus', message)
  ) {
    employeeId = message.employeeId;
  }
  if (
    isType('MsgAddedToChannel', message) ||
    isType('MsgExcludedFromChannel', message)
  ) {
    employeeId = message.whoEmployeeId;
  }

  if (!employeeId) return '';

  const channelMember = findMember(chat, employeeId);
  return getAvatarSrcOrDefault(channelMember);
};

const MESSAGE_TYPES_WITHOUT_AVATAR = [
  'MsgAcceptResponse',
  'MsgRejectResponse',
  'MsgSendToReworkResponse'
];
const isShowAvatarForSystemMsg = (message) =>
  !MESSAGE_TYPES_WITHOUT_AVATAR.includes(message.type);

const getMsg = (t, chat, user) => {
  const message = chat.lastMessage;
  if (isEmpty(message) || isNil(message)) return null;

  const isOwnMessage = isOwnMsg(chat, user);
  if (
    (isType('MsgUser', message) || isType('MsgReply', message)) &&
    isType('Dialog', chat)
  ) {
    return {
      type: 'User',
      isUnread: isOwnMsg(chat, user) && containsUnreadMsg(chat, message.id),
      avatar: {
        show: isOwnMessage,
        src: getAvatarSrcOrDefault(chat.lastMessage),
        alt: chat.lastMessage.userName
      },
      userName: getUserName(t, chat, user),
      text: message.msg,
      isOwn: isOwnMessage
    };
  }

  if (
    isType('MsgUser', message) &&
    (isType('Channel', chat) || isType('Topic', chat))
  ) {
    return {
      type: 'User',
      isUnread: isOwnMessage && containsUnreadMsg(chat, message.id),
      avatar: {
        show: true,
        src: getAvatarSrcOrDefault(chat.lastMessage),
        alt: chat.lastMessage.userName
      },
      userName: getUserName(t, chat, user),
      text: message.msg,
      isOwn: isOwnMessage
    };
  }

  if (isMsgSystem(message)) {
    return {
      type: 'System',
      isUnread: false,
      avatar: {
        show: isShowAvatarForSystemMsg(message),
        src: getAvatarSrcForSystemMsg(chat),
        alt: 'system msg'
      },
      userName: '',
      text: getMsgSystemText(t, chat, message, user)
    };
  }

  if (isType(PURCHASE_REQUESTS_MESSAGE_TYPE, message)) {
    return {
      type: 'Bot',
      isUnread: containsUnreadMsg,
      avatar: {
        show: isShowAvatar(message),
        src: message.customerLogo,
        alt: 'customer avatar'
      },
      userName: 'Bot: ',
      msg: message.title,
      hidden: message.hidden
    };
  }

  if (isNewsMessage(message)) {
    return {
      type: 'Bot',
      isUnread: containsUnreadMsg,
      avatar: {
        show: false
      },
      userName: '',
      msg: message.data.summary
    };
  }

  return null;
};

export default getMsg;
