import { bindActionCreators } from 'redux';
import { closeTopicDashboardService } from './closeTopicDashboard';
import { prevChatMode } from '../../chatMode';
import {
  setSuccessMessage,
  setErrorMessage,
  setInfoMessage
} from '../../../../../action-creators/message';
import * as tempActions from '../../../../../action-creators/temporary';
import * as storage from '../storage';
import * as inputActions from '../../messageInput/actions';
import * as chatsActions from '../../chats/actions';
import { getChatInput, getChatBlock, getOpenedChat } from '../../../getter';
import { getTempField } from '../../../../../storeGetters';
import { showChat } from '../../chats/services';
import { showModal } from '../../../../../action-creators/modal';
import { applyState } from '../../utils';
import { createTopicService } from './create';
import { updateTopicService } from './update';
import { gaSendMessengerAction } from '../../GA';
import { filesLoadHandler } from '../../../../../useCases/files';
import { createOrUpdateTopicService } from './createOrUpdate';
import { attachLocalFilesService } from './attachLocalFiles';
import { attachFromStorageService } from './attachFromStorage';
import { deleteFileService } from './deleteFile';
import { fileIncludes } from '../../../data-type/poly/files';
import { updateChannelListByCreateTopic } from '../storage';

export const closeTopicDashboard = (id) => async (dispatch) =>
  closeTopicDashboardService(
    {
      reqClearTopicUnsaveInfoFile: storage.clearTopicUnsaveInfoFile,
      prevChatMode: () => dispatch(prevChatMode()),
      clearInputFiles: () => dispatch(inputActions.setInputFiles([])),
      clearTempFiles: () =>
        dispatch(tempActions.clearSetTemp('filesToRemove')())
    },
    { id }
  );

const createTopic =
  ({ name, title, desc, files, employeesId, membersRoles }) =>
  async (dispatch, getState) =>
    createTopicService(
      {
        prevChatMode: () => dispatch(prevChatMode()),
        clearInputFiles: () => dispatch(inputActions.setInputFiles([])),
        clearTempFiles: () =>
          dispatch(tempActions.clearSetTemp('filesToRemove')()),
        gaTopicCreate: () => dispatch(gaSendMessengerAction('topic_create')),
        createTopic: storage.createTopic,
        getChatList: applyState(getState, getChatBlock),
        setChats: (chats) => dispatch(chatsActions.setChats(chats)),
        showChat: (chat) => dispatch(showChat(chat)),
        updateBySocket: (chat) => updateChannelListByCreateTopic(chat)
      },
      { name, title, desc, files, employeesId, membersRoles }
    );

export const updateTopic =
  ({ id, name, title, desc }) =>
  async (dispatch, getState) =>
    updateTopicService(
      {
        getChatInput: applyState(getState, getChatInput),
        getFilesForRemove: () => getTempField(getState(), 'filesToRemove', []),
        updateChannel: storage.updateChannel,
        prevChatMode: () => dispatch(prevChatMode()),
        clearInputFiles: () => dispatch(inputActions.setInputFiles([])),
        clearTempFiles: () =>
          dispatch(tempActions.clearSetTemp('filesToRemove')()),
        gaTopicUpdated: () =>
          dispatch(gaSendMessengerAction('topic_content_save'))
      },
      { id, name, title, desc }
    );

export const createOrUpdateTopic =
  ({ editMode, id = '_', name, title, desc, files }) =>
  async (dispatch) =>
    createOrUpdateTopicService(
      {
        createTopic: (data) => dispatch(createTopic(data)),
        updateTopic: (data) => dispatch(updateTopic(data)),
        onCancel: (data) => dispatch(closeTopicDashboard(data.id)),
        showAddMembersModal: (data) =>
          dispatch(showModal('CW_GROUP_CHAT_ADD_MEMBERS', data))
      },
      { editMode, id, name, title, desc, files }
    );

export const attachLocalFiles =
  ({ id, files }) =>
  async (dispatch, getState) =>
    attachLocalFilesService(
      {
        reqAttachTopicUnsaveInfoFile: storage.attachTopicUnsaveInfoFile,
        reqGetTopicUnsaveInfoFiles: storage.getTopicUnsaveInfoFiles,
        filesLoadHandler,
        getChatInput: applyState(getState, getChatInput),
        setInputFiles: bindActionCreators(inputActions.setInputFiles, dispatch),
        setInfoMessage: bindActionCreators(setInfoMessage, dispatch),
        setSuccessMessage: bindActionCreators(setSuccessMessage, dispatch),
        setErrorMessage: bindActionCreators(setErrorMessage, dispatch)
      },
      { id, files }
    );

export const attachFromStorage =
  ({ id }) =>
  async (dispatch) =>
    attachFromStorageService(
      {
        reqAttachTopicUnsaveInfoFileFromStorage:
          storage.attachTopicUnsaveInfoFileFromStorage,
        reqGetTopicUnsaveInfoFiles: storage.getTopicUnsaveInfoFiles,
        setInputFiles: bindActionCreators(inputActions.setInputFiles, dispatch),
        showFileAttachModal: (props) =>
          dispatch(showModal('STORAGE_FILE_ATTACHE', props))
      },
      { id }
    );

export const deleteFile =
  ({ editMode, id, file }) =>
  async (dispatch, getState) =>
    deleteFileService(
      {
        reqGetTopicUnsaveInfoFiles: storage.getTopicUnsaveInfoFiles,
        getChatInput: applyState(getState, getChatInput),
        getOpenedChat: applyState(getState, getOpenedChat),
        fileIncludes,
        addFileToTempRemove: bindActionCreators(
          tempActions.appendToSetTemp('filesToRemove'),
          dispatch
        ),
        removeUnsaveInfoFile: storage.removeUnsaveInfoFile,
        setInputFiles: bindActionCreators(inputActions.setInputFiles, dispatch)
      },
      { editMode, id, file }
    );
