const { last, is, has, keys } = require('ramda');
const assert = require('assert');
const { getType } = require('../../lib');

const makeTypeMethods = (type, methods) => {
  return { type: prepareType(), methods };

  function prepareType() {
    if (is(String, type)) return type;
    if (has('getModuleType', type)) return type.getModuleType();
    throw new Error('Not handler');
  }
};
const getMethods = (typeMethods) => typeMethods.methods;
const getMethodsName = (typeMethods) => keys(getMethods(typeMethods));

exports.makeTypeMethods = makeTypeMethods;

const makeHashTable = (arrayOfTypeMethods) =>
  arrayOfTypeMethods.reduce(
    (acc, typeMethods) => ({
      ...acc,
      [getType(typeMethods)]: getMethods(typeMethods)
    }),
    {}
  );

const hasType = (obj, hashTable) => has(getType(obj), hashTable);

const getMethod = (obj, methodName, hashTable) =>
  hashTable[getType(obj)][methodName];

const generateDispatchedMethods = (hashTable, requiredMethods, dispatcher) =>
  requiredMethods.reduce(
    (acc, methodName) => ({
      ...acc,
      [methodName]: dispatcher(hashTable, methodName)
    }),
    {}
  );

const dispatcher =
  (hashTable, methodName) =>
  (...allArgs) => {
    const obj = last(allArgs);
    assert(
      hasType(obj, hashTable),
      `Not type methods for type "${getType(obj)}"`
    );

    const method = getMethod(obj, methodName, hashTable);
    return method(...allArgs);
  };

const makePoly = (name, requiredMethods, arrayOfTypeMethods) => {
  const hashTable = makeHashTable(arrayOfTypeMethods);

  return generateDispatchedMethods(hashTable, requiredMethods, dispatcher);
};

const checkedPoly = (name, requiredMethods, arrayOfTypeMethods) => {
  arrayOfTypeMethods.forEach((typeMethods) =>
    requiredMethods.forEach((requiredMethod) =>
      assert(
        getMethodsName(typeMethods).includes(requiredMethod),
        `In type methods of "${getType(
          typeMethods
        )}" should be method "${requiredMethod}"`
      )
    )
  );

  return makePoly(name, requiredMethods, arrayOfTypeMethods);
};

exports.makePoly = checkedPoly;
