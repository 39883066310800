import { fromJS, Set } from 'immutable';
import {
  initState,
  setCustomers,
  setCategories,
  updateCategoryName,
  updateNewCategoryName,
  updateCustomerField,
  selectCustomer,
  updateEditField,
  setInvitations,
  clearInvitations,
  selectCategory,
  deselectAll,
  deselectCategory,
  deselectCustomer,
  clearNewCustomer,
  clearNewCategory,
  setSelectedItem,
  setSelectedCategory,
  deselectAllCategories,
  updateCategories,
  addNewCustomer,
  setRequests,
  setAdditionRequests,
  setSelectedItems,
  removeRequests,
  removeAdditionRequest,
  setSelectedRequests,
  setSelectedInvites,
  toggleLink,
  setToken
} from '../work-with-data/customers';

const setCount = (data, { customersCount }) =>
  data.set('customersCount', customersCount);

const removeCustomer = (data, { customerId, currentGroupId }) => {
  if (!currentGroupId) return data;
  return data.update('items', (items) =>
    items.filter((item) => item.get('id') !== customerId)
  );
};

const removeCustomers = (data, { customers, size, totalSize }) =>
  data
    .update('items', (items) =>
      items.filter((item) => !customers.includes(item.get('id')))
    )
    .set('size', size)
    .set('totalSize', totalSize);

const removeInvites = (data, { invites }) =>
  data
    .update('invitations', (items) =>
      items.filter((invite) => !invites.includes(invite.get('id')))
    )
    .remove('selected')
    .set('selected', new Set());

const selectAll = (data, { items }) => {
  const itemIds = items.map((item) => item.get('customer') || item.get('id'));
  return data.update('selected', (selected) => {
    if (selected.equals(new Set(itemIds))) {
      return new Set();
    }
    return selected.merge(new Set(itemIds));
  });
};

const removeGroupFromCustomer = (data, { customers, groupId }) =>
  data.update('items', (items) => {
    let newItems = items;
    for (const customerId of customers) {
      // eslint-disable-line
      const index = items.findIndex((item) => item.get('id') === customerId);
      newItems = newItems.updateIn([index, 'groups'], (groups) =>
        groups.filter((group) => group !== groupId)
      );
    }
    return newItems;
  });

const addGroupToCustomer = (data, { customers, groupId }) =>
  data.update('items', (items) => {
    let newItems = items;
    for (const customerId of customers) {
      // eslint-disable-line
      const index = items.findIndex((item) => item.get('id') === customerId);
      newItems = newItems.updateIn([index, 'groups'], (groups) => {
        if (!groups.includes(groupId)) {
          return groups.push(groupId);
        }
        return groups;
      });
    }
    return newItems;
  });

const updateGroup = (data, { group: { id, name } }) => {
  const groups = data.get('groups');
  const index = groups.findIndex((item) => item.get('id') === id);
  return data.updateIn(['groups', index], (group) => group.set('name', name));
};

const setResponsible = (data, { customerId, userId }) => {
  const index = data.get('items').findIndex((i) => i.get('id') === customerId);
  return data.setIn(['items', index, 'responsibleUser'], userId);
};

const setConsignees = (data, { consignees }) =>
  data.set('consignees', fromJS(consignees));

const clearConsignees = (data) => data.delete('consignees');

export default (state = initState(), { type, payload }) => {
  switch (type) {
    case 'CUSTOMERS:GET_CUSTOMERS:SUCCESS':
      return setCustomers(state, payload);
    case 'CUSTOMERS_CATEGORY:GET_CATEGORIES:SUCCESS':
      return setCategories(state, payload);
    case 'CUSTOMERS_CATEGORY:DELETE:SUCCESS':
      return updateCategories(state, payload);
    case 'CUSTOMERS_CATEGORY:CREATE:UPDATE_NAME':
      return updateNewCategoryName(state, payload);
    case 'CUSTOMERS_CATEGORY:UPDATE':
      return updateCategoryName(state, payload);
    case 'CUSTOMERS:CREATE:UPDATE_FIELD':
      return updateCustomerField(state, payload);
    case 'CUSTOMERS:GET_INVITATIONS:SUCCESS':
      return setInvitations(state, payload);
    case 'CUSTOMERS:CLEAR_INVITATIONS':
      return clearInvitations(state);
    case 'CUSTOMERS:SELECT':
      return selectCustomer(state, payload);
    case 'CUSTOMERS:EDIT:UPDATE':
      return updateEditField(state, payload);
    case 'CUSTOMERS:DELETE:SUCCESS':
      return removeCustomers(state, payload);
    case 'CUSTOMERS_CATEGORY:SELECT':
      return selectCategory(state, payload);
    case 'CUSTOMERS_CATEGORY:SET_SELECTED_CATEGORY':
      return setSelectedCategory(state, payload);
    case 'DESELECT_ALL':
      return deselectAll(state, payload);
    case 'DESELECT_ALL_CATEGORIES':
      return deselectAllCategories(state);
    case 'DESELECT_CUSTOMER':
      return deselectCustomer(state);
    case 'DESELECT_CATEGORY':
      return deselectCategory(state);
    case 'CUSTOMERS:CLEAR_FORM':
      return clearNewCustomer(state);
    case 'CUSTOMERS_CATEGORY:CREATE:SUCCESS':
      return clearNewCategory(state);
    case 'CUSTOMERS:SET_SELECTED_ITEM':
      return setSelectedItem(state, payload);
    case 'CUSTOMERS:SET_SELECTED_ITEMS':
      return setSelectedItems(state, payload);
    case 'CUSTOMERS:ADD:SUCCESS':
      return addNewCustomer(state, payload);
    case 'CUSTOMERS:GET_MY_REQUESTS:SUCCESS':
      return setRequests(state, payload);
    case 'CUSTOMERS:GET_ADDITION_REQUESTS:SUCCESS':
      return setAdditionRequests(state, payload);
    case 'CUSTOMERS:DELETE_REQUESTS:SUCCESS':
      return removeRequests(state, payload);
    case 'CUSTOMERS:CONFIRM_ADD:SUCCESS':
      return removeAdditionRequest(state, payload);
    case 'CUSTOMERS:REJECT_ADD:SUCCESS':
      return removeAdditionRequest(state, payload);
    case 'CUSTOMERS:SET_SELECTED_REQUESTS':
      return setSelectedRequests(state, payload);
    case 'CUSTOMERS:SET_SELECTED_INVITES':
      return setSelectedInvites(state, payload);
    case 'CUSTOMERS:GET_CUSTOMERS_COUNT:SUCCESS':
      return setCount(state, payload);
    case 'CUSTOMERS:UPDATE:SUCCESS':
      return removeCustomer(state, payload);
    case 'CUSTOMERS:DELETE_INVITES:SUCCESS':
      return removeInvites(state, payload);
    case 'CUSTOMERS:SELECT:ALL':
      return selectAll(state, payload);
    case 'CUSTOMERS:GROUP_REMOVE:SUCCESS':
      return removeGroupFromCustomer(state, payload);
    case 'CUSTOMERS:GROUP_ADD:SUCCESS':
      return addGroupToCustomer(state, payload);
    case 'CUSTOMERS_CATEGORY:UPDATE:SUCCESS':
      return updateGroup(state, payload);
    case 'CUSTOMERS:SET_RESPONSIBLE:SUCCESS':
      return setResponsible(state, payload);
    case 'CUSTOMERS:GET_CUSTOMER_CONSIGNEES:SUCCESS':
    case 'CUSTOMERS:SAVE_CUSTOMER_CONSIGNEES:SUCCESS':
      return setConsignees(state, payload);
    case 'CUSTOMERS:CONSIGNEES_CLEAR':
      return clearConsignees(state);
    case 'CUSTOMERS:TOGGLE_LINK:SUCCESS':
      return toggleLink(state, payload);
    case 'CUSTOMERS:SET_TOKEN':
      return setToken(state, payload);
    default:
      return state;
  }
};
