import React from 'react';

/* eslint-disable max-len */
function IconFilterCircle() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 9H25V11.22L19 16.72V24.3604L15 25.6937V16.72L9 11.22V9ZM10 10V10.78L16 16.28V24.3063L18 23.6396V16.28L24 10.78V10H10Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconFilterCircle;
