export function createStore(initialState = {}) {
  let state = initialState;

  const listeners = {};

  return {
    subscribe: (key, handler) => {
      listeners[key] = (listeners[key] || []).concat(handler);
    },
    unsubscribe: (key, handler) => {
      listeners[key] = (listeners[key] || []).filter((h) => h !== handler);
    },
    get: (key) => state[key],
    update: (key, data) => {
      state = { ...state, [key]: data };
      (listeners[key] || []).forEach((handler) => handler(state[key]));
    }
  };
}
