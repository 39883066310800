import React from 'react';

/* eslint-disable max-len */
function IconAttach() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9377 2.38182C13.2802 1.03939 15.4567 1.03939 16.7991 2.38182L17.8659 3.44862L16.8936 4.42089L15.8268 3.35409C15.0214 2.54864 13.7155 2.54864 12.91 3.35409L5.13182 11.1323C3.78939 12.4747 3.78939 14.6512 5.13182 15.9936L6.10409 16.9659C7.44652 18.3083 9.62302 18.3083 10.9655 16.9659L16.7991 11.1323L17.7714 12.1045L11.9377 17.9382C10.0583 19.8176 7.01122 19.8176 5.13182 17.9382L4.15955 16.9659C2.28015 15.0865 2.28015 12.0394 4.15955 10.16L11.9377 2.38182Z"
        fill="#767B92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7991 4.32641C17.6045 5.13187 17.6045 6.43777 16.7991 7.24323L9.50703 14.5353C8.97006 15.0722 8.09946 15.0722 7.56249 14.5353C7.02551 13.9983 7.02551 13.1277 7.56249 12.5907L9.50703 10.6462L8.53476 9.67391L6.59021 11.6185C5.51627 12.6924 5.51627 14.4336 6.59021 15.5075C7.66416 16.5815 9.40536 16.5815 10.4793 15.5075L17.7713 8.2155C19.1138 6.87307 19.1138 4.69657 17.7713 3.35414L16.7991 4.32641Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconAttach;
