import React from 'react';
import { useTranslate } from '../../../../TranslateProvider';
import IconSettings from './icons/settings';
import IconSort from './icons/sort';
import IconEdit from './icons/edit';
import IconView from './icons/view';
import IconDelete from './icons/delete';
import IconPerson from './icons/person';
import IconBlock from './icons/block';
import IconUnblock from './icons/unblock';
import IconFolder from './icons/folder';
import IconArchive from './icons/archive';
import IconNoticeOn from './icons/notice-on';
import IconNoticeOff from './icons/notice-off';
import IconLeave from './icons/leave';
import IconClose from './icons/close';
import IconReply from './icons/reply';
import IconForward from './icons/forward';
import IconHighlight from './icons/highlight';
import IconStar from './icons/star';
import IconSkype from './icons/skype';
import IconViber from './icons/viber';
import IconOtherContacts from './icons/other-contacts';
import IconAddFolder from './icons/add-folder';
import IconPin from './icons/pin';
import IconUnpin from './icons/unpin';
import Helper from '../../../Helper';
import IconDownload from './icons/download';
import IconHide from './icons/hide';
import IconHideOff from './icons/hide-off';
import IconUpload from './icons/upload';
import IconResend from './icons/resend';
import IconSelected from './icons/selected';
import IconMinusRound from './icons/minus-round';
import IconMessage from './icons/message';
import IconAt from './icons/at';
import IconLink from './icons/link';
import IconPublish from './icons/publish';
import IconTask from './icons/task';
import IconEmail from './icons/mail';
import IconChat from './icons/chat';
import IconLinkNew from './icons/linkNew';

const CWSDropdownItem = ({
  icon,
  color = 'gray',
  value,
  helper = '',
  helperPosition = 'right',
  selected = false,
  nested = false,
  onClick = () => {}
}) => {
  const t = useTranslate();

  return (
    <div
      className={`cws-dropdown-menu-item cws-dropdown-menu-item_color_${color}`}
      onClick={() => onClick()}>
      {icon !== 'without' && (
        <div className="cws-dropdown-menu-item__icon">
          {icon === 'person' && <IconPerson />}
          {icon === 'block' && <IconBlock />}
          {icon === 'unblock' && <IconUnblock />}
          {icon === 'folder' && <IconFolder />}
          {icon === 'settings' && <IconSettings />}
          {icon === 'sort' && <IconSort />}
          {icon === 'edit' && <IconEdit />}
          {icon === 'view' && <IconView />}
          {icon === 'delete' && <IconDelete />}
          {icon === 'archive' && <IconArchive />}
          {icon === 'notice-on' && <IconNoticeOn />}
          {icon === 'notice-off' && <IconNoticeOff />}
          {icon === 'leave' && <IconLeave />}
          {icon === 'close' && <IconClose />}
          {icon === 'reply' && <IconReply />}
          {icon === 'forward' && <IconForward />}
          {icon === 'highlight' && <IconHighlight />}
          {icon === 'star' && <IconStar />}
          {icon === 'skype' && <IconSkype />}
          {icon === 'viber' && <IconViber />}
          {icon === 'other-contacts' && <IconOtherContacts />}
          {icon === 'add-folder' && <IconAddFolder />}
          {icon === 'download' && <IconDownload />}
          {icon === 'pin' && <IconPin />}
          {icon === 'unpin' && <IconUnpin />}
          {icon === 'hide' && <IconHide />}
          {icon === 'hide-off' && <IconHideOff />}
          {icon === 'upload' && <IconUpload />}
          {icon === 'resend' && <IconResend />}
          {icon === 'minus-round' && <IconMinusRound />}
          {icon === 'message' && <IconMessage />}
          {icon === 'at' && <IconAt />}
          {icon === 'link' && <IconLink />}
          {icon === 'publish' && <IconPublish />}
          {icon === 'create-task' && <IconTask />}
          {icon === 'mail' && <IconEmail />}
          {icon === 'chat' && <IconChat />}
          {icon === 'linkNew' && <IconLinkNew />}
        </div>
      )}
      <div className="cws-dropdown-menu-item__text">
        {t(`chat-widget.menu-items.${value}`)}
      </div>
      {helper && (
        <span
          className="cws-dropdown-menu-item__helper"
          style={{ marginLeft: 5 }}>
          <Helper text={helper} placement={helperPosition} />
        </span>
      )}
      {selected && (
        <span className="cws-dropdown-menu-item__selected">
          <IconSelected />
        </span>
      )}
      {nested && (
        <span className="cws-dropdown-menu-item__arrow">
          <img
            className="cws-dropdown-menu-item__arrow-img"
            src="/img/chat-widget/arrow_right.svg"
            alt=""
          />
        </span>
      )}
    </div>
  );
};

export default CWSDropdownItem;
