import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import { Container, HeaderAvatar, Body } from '../Components';
import ContactLists from './ContactLists';
import * as modalActions from '../../../../action-creators/modal';
import * as useCase from '../../useCases';
import { getContact } from './getter';

const CWModalEditUserContactLists = ({
  contact,
  contactId,
  hideModal,
  handleEditUserInGroup
}) => {
  if (!contact) {
    console.error('Not found contact in blockContact with id', contactId);
    return null;
  }
  const { avatar = '', name = '', company = '' } = contact;

  return (
    <Modal show onHide={hideModal}>
      <Container>
        <HeaderAvatar
          onClose={hideModal}
          avatar={avatar}
          fullName={name}
          companyName={company}
        />
        <Body>
          <ContactLists contact={contact} handler={handleEditUserInGroup} />
        </Body>
      </Container>
    </Modal>
  );
};

CWModalEditUserContactLists.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    companyName: PropTypes.string
  }).isRequired,
  hideModal: PropTypes.func.isRequired
};

export default connect(
  (state, props) => ({ contact: getContact(state, props) }),
  (dispatch) =>
    bindActionCreators(
      { hideModal: modalActions.hideModalDialog, ...useCase },
      dispatch
    )
)(CWModalEditUserContactLists);
