import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo
} from 'react';
import { isNil } from 'ramda';
import { useLayer } from 'react-laag';
import DatePicker, { registerLocale } from 'react-datepicker';

import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';

import DTPHeader from './header';
import DTFooter from './footer';
import { isFirstDateGTE } from './utils';

import 'react-datepicker/dist/react-datepicker.css';
import '../datepicker.css';

registerLocale('ru', ru);
registerLocale('en', en);

function savePrevTime(newDate, prevDate) {
  if (isNil(newDate)) return null;
  if (isNil(prevDate)) return newDate;

  const hh = new Date(newDate).getHours();
  const mm = new Date(newDate).getMinutes();

  return new Date(new Date(newDate).setHours(hh, mm));
}

function getDate({ newDate, prevDate, minDate, minTime }) {
  const value = savePrevTime(newDate, prevDate);

  if (!isNil(minTime)) {
    return !isNil(value) && isFirstDateGTE(value, minTime) ? value : minTime;
  }
  if (!isNil(minDate)) {
    return !isNil(value) && isFirstDateGTE(value, minDate) ? value : minDate;
  }

  return !isNil(value) ? new Date(value) : getCurrentDay();
}

function getCurrentDay() {
  const date = new Date();
  date.setHours(0, 0, 0, 0);

  return date;
}

const DateTimePicker = ({
  t,
  title,
  value,
  onChange,
  onSubmit,
  onClear,
  onClick,
  language = 'ru',
  isShow = false,
  isCloseAfterSubmitted = true,
  isCloseAfterClear = false,
  disabled = false,
  minDate,
  maxDate,
  minTime,
  icon,
  root,
  getRootContainer,
  placement = 'bottom-center',
  overflowContainer = true,
  preferX,
  preferY,
  zIndex = 1051,
  dateTkey = 'defaultComponents.datepicker.date',
  timeTkey = 'defaultComponents.datepicker.time',
  btnClearTkey = 'defaultComponents.datepicker.btn_clear',
  btnApplyTkey = 'defaultComponents.datepicker.btn_apply',
  closeAfterDisappear = true,
  closeAfterClickOutside = true
}) => {
  const datePickerRef = useRef();
  const [_isShow, setIsShow] = useState(isShow);
  const [date, setDate] = useState(
    getDate({ newDate: value, minDate, minTime })
  );

  useEffect(() => {
    getDate({ newDate: value, minDate, minTime });
  }, [value]);

  useEffect(() => {
    setIsShow(isShow);
  }, [isShow]);

  useEffect(() => {
    if (_isShow && !date && minDate) {
      setDate(getDate({ newDate: value, minDate, minTime }));
    }
  }, [_isShow]);

  const onChangeDate = useCallback(
    (newDate) => {
      const preparedDate = getDate({
        newDate,
        prevDate: date,
        minDate,
        minTime
      });

      if (typeof onChange === 'function') {
        onChange(preparedDate);
      }

      setDate(preparedDate);
    },
    [onSubmit, minDate, minTime, date]
  );

  const onClearDate = useCallback(() => {
    if (typeof onClear === 'function') {
      onClear();
    }

    setDate(null);

    if (isCloseAfterClear) {
      setIsShow(false);
    }
  }, [onClear]);

  const onApply = useCallback(() => {
    if (typeof onSubmit === 'function') {
      onSubmit(date);
    }

    if (isCloseAfterSubmitted) {
      setIsShow(false);
    }
  }, [onSubmit, date]);

  const onToggle = () => !disabled && setIsShow(!_isShow);
  const onHide = useCallback(() => setIsShow(false), []);

  const onKeyDown = useCallback(
    (event) => {
      switch (event.key) {
        case 'Escape':
          setIsShow(false);
          break;
        case 'Enter':
          if (!isNil(date)) {
            onApply();
          }
          break;
        default:
          break;
      }
    },
    [date, onApply]
  );

  const $rootContainer = useMemo(
    () =>
      root ||
      (typeof getRootContainer === 'function' ? getRootContainer() : null),
    [root, getRootContainer]
  );

  const { layerProps, triggerProps, renderLayer } = useLayer({
    isOpen: _isShow,
    placement,
    preferX,
    preferY,
    auto: true,
    fixed: true,
    overflowContainer,
    triggerOffset: 0,
    container: $rootContainer,
    onOutsideClick: closeAfterClickOutside ? onHide : undefined,
    onDisappear: closeAfterDisappear ? onHide : undefined
  });

  return (
    <div onKeyDown={onKeyDown} onClick={onClick}>
      <div
        style={{ position: 'relative' }}
        className={`${_isShow ? 'iconActive' : null}`}
        onClick={onToggle}
        {...triggerProps}>
        {icon}
      </div>
      {_isShow &&
        renderLayer(
          <div ref={layerProps.ref} style={{ ...layerProps.style, zIndex }}>
            <div className="datepicker-goodwix" ref={datePickerRef}>
              <DTPHeader
                t={t}
                title={title}
                date={date}
                setDate={onChangeDate}
                minDate={minDate}
                maxDate={maxDate}
                minTime={minTime}
                dateTkey={dateTkey}
                timeTkey={timeTkey}
              />
              <DatePicker
                locale={language}
                selected={date}
                onChange={onChangeDate}
                minDate={minDate}
                maxDate={maxDate}
                minTime={minTime}
                inline
                disabled={disabled}
              />
              <DTFooter
                t={t}
                onApply={onApply}
                onClearDate={onClearDate}
                btnApplyTkey={btnApplyTkey}
                btnClearTkey={btnClearTkey}
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default DateTimePicker;
