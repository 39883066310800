const { anyPass, curry } = require('ramda');
const { isType } = require('./struct');

/**
 * Generates array of predicates for check if is type of class
 *
 * @param {[(string|Function)]} types - is name of type or a predicate to check if is type of class
 * @returns {[Function]}
 */
const generatePredicates = (types) =>
  types.map((type) => (isType('Function', type) ? type : isType(type)));

/**
 * Check if data type is belongs to class of type
 *
 * @param {[(string|Function)]} types - is name of type or a predicate to check if is type of class
 * @param {*} dataType
 * @returns {boolean}
 */
module.exports = curry(
  (types) => (dataType) => anyPass(generatePredicates(types))(dataType)
);
