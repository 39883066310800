import React from 'react';

/* eslint-disable max-len */
const ProfileSVG = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3742 4C13.3742 5.933 11.8072 7.5 9.8742 7.5C7.9412 7.5 6.3742 5.933 6.3742 4C6.3742 2.067 7.9412 0.5 9.8742 0.5C11.8072 0.5 13.3742 2.067 13.3742 4ZM2.89206 11.9901C3.111 11.1144 3.89785 10.5 4.80053 10.5H14.9479C15.8505 10.5 16.6374 11.1144 16.8563 11.9901C17.556 14.7889 15.4392 17.5 12.5543 17.5H7.19408C4.30918 17.5 2.19237 14.7889 2.89206 11.9901Z"
      stroke="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default ProfileSVG;
