import React from 'react';
import classNames from 'classnames';
import Text from 'react-components/src/components/Typography';
import Checkbox from '../CheckboxNew';
import './style.css';

const CheckboxWithLabel = ({
  label,
  value,
  onClick,
  className,
  labelClassName,
  labelLevel = 1,
  labelLineHeight = 3,
  labelWeight = 2,
  disabled,
  dataTestid
}) => {
  const ref = React.useRef();

  const onClickByLabel = () => {
    if (ref.current?.click) {
      ref.current.click();
    }
  };

  return (
    <div
      className={classNames('checkbox-with-label', {
        [className]: Boolean(className)
      })}>
      <Checkbox
        ref={ref}
        value={value}
        onClick={onClick}
        disabled={disabled}
        dataTestid={dataTestid}
      />
      <Text
        className={classNames('checkbox-with-label__label', {
          disabled,
          [labelClassName]: Boolean(labelClassName)
        })}
        onClick={onClickByLabel}
        level={labelLevel}
        lineHeight={labelLineHeight}
        weight={labelWeight}>
        {label}
      </Text>
    </div>
  );
};

export default CheckboxWithLabel;
