import { getMaxFilesCount } from '../../../../data-type/block-input';

const isMaxFiles = (files, input) => files.length >= getMaxFilesCount(input);

export const makeAttachFilesMenuVM = ({
  chatId,
  isOpen,
  input,
  files,
  toggle,
  hide,
  buttonRef,
  fileInputRef,
  onAttachLocalFiles,
  onAttachStorageFiles,
  gaSend,
  onClickOutside
}) => ({
  chatId,
  isOpen,
  input,
  files,
  toggle,
  hide,
  buttonRef,
  fileInputRef,
  onAttachLocalFiles,
  onAttachStorageFiles,
  gaSend,
  onClickOutside
});

export const getAttachListMode = (vm) => (vm.isOpen ? 'open' : 'close');
export const getButtonRef = (vm) => vm.buttonRef;
export const getFileInputRef = (vm) => vm.fileInputRef;

export const initOnToggle = (vm) => () => {
  if (!isMaxFiles(vm.files, vm.input)) {
    if (!vm.isOpen) {
      vm.gaSend('attach-open');
    }
    vm.toggle();
  }
};

export const initAttachLocal =
  (vm) =>
  ({ target }) => {
    vm.onAttachLocalFiles({ id: vm.chatId, files: convertFiles(target.files) });

    return undefined;

    function convertFiles(rawFiles) {
      const files = [];
      for (let i = 0; i < rawFiles.length; i++) {
        files.push(rawFiles[i]);
      }
      return files;
    }
  };

export const initAttachStorage = (vm) => () =>
  vm.onAttachStorageFiles({ id: vm.chatId });

export const initEmmitClickFile = (vm) => () => vm.fileInputRef.current.click();

export const hideWhenClickOutside = (vm) =>
  vm.onClickOutside(vm.buttonRef, vm.hide);
