import React from 'react';

/* eslint-disable max-len */
function IconLeftCircle({ style = {} }) {
  return (
    <svg
      style={style}
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="16.5" cy="16.5" r="16.5" stroke="#EBEEF4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4697 16.9697L19.8232 25.3232L20.5303 24.6161L12.8839 16.9697L20.5303 9.3232L19.8232 8.6161L11.4697 16.9697Z"
        fill="#767B92"
      />
      {/*
    <path
      d="M17 33C8.16344 33 1 25.8366 1 17H-1C-1 26.9411 7.05887 35 17 35V33ZM33 17C33 25.8366 25.8366 33 17 33V35C26.9411 35 35 26.9411 35 17H33ZM17 1C25.8366 1 33 8.16344 33 17H35C35 7.05887 26.9411 -1 17 -1V1ZM17 -1C7.05887 -1 -1 7.05887 -1 17H1C1 8.16344 8.16344 1 17 1V-1Z"
      fill="#EBEEF4"
    />
    */}
    </svg>
  );
}
/* eslint-enable max-len */

export default IconLeftCircle;
