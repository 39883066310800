import React from 'react';
import InputRange from 'react-input-range';
import styled from 'styled-components';
import ButtonIcon from '../../ChatWidget/ButtonIcon';
import 'react-input-range/lib/css/index.css';

const ZoomRange = ({
  value,
  zoomChange,
  zoomIn,
  zoomOut,
  onReset,
  step,
  minValue,
  maxValue
}) => (
  <Wrapper>
    <WrapperRange>
      <ButtonIcon
        disabled={value === minValue}
        onClick={zoomOut}
        icon="minus-s"
        tooltip="zoom-out"
        color="white"
      />
      <WrapperInput>
        <InputRange
          type="range"
          value={value}
          onChange={(val) => zoomChange(+val)}
          formatLabel={(val) => `${Math.floor(val * 100)}%`}
          step={step}
          minValue={minValue}
          maxValue={maxValue}
        />
      </WrapperInput>
      <ButtonIcon
        disabled={value === maxValue}
        onClick={zoomIn}
        icon="plus-s"
        tooltip="zoom-in"
        color="white"
      />
    </WrapperRange>
    <ButtonIcon
      disabled={value === minValue}
      onClick={onReset}
      icon="fit-in-s"
      tooltip="reset-settings"
      color="white"
    />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  left: calc(50% - 75px);
  bottom: 31px;
  z-index: 99;
`;

const WrapperRange = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  align-items: center;
  margin-right: 16px;
`;

const WrapperInput = styled.div`
  width: 150px !important;
  margin: 0 8px;

  &:hover {
    .input-range__label-container {
      display: block;
    }
  }

  .input-range__slider {
    border: 1px solid #fff;
    background: #a8aab6;
  }

  .input-range__track--active {
    background: #fff;
  }

  .input-range__track {
    height: 2px;
  }

  .input-range__slider {
    margin-top: -9px;
  }

  .input-range__label {
    color: #fff;
  }

  .input-range__label--min {
    display: none;
  }

  .input-range__label--max {
    display: none;
  }

  .input-range__slider:active {
    transform: none;
  }

  .input-range__track--background {
    margin-top: -1px;
  }

  .input-range__label-container {
    display: none;
  }
`;

export default ZoomRange;
