import React from 'react';
import Row from './row';
import './style.css';
import { mapGroup, getKey } from '../viewModel';

const List = ({ group }) => (
  <div className="cwsa-categories-lists__container">
    {mapGroup(
      (g) => (
        <Row key={getKey(g, 'row-parent')} group={g} />
      ),
      group
    )}
  </div>
);

export default List;
