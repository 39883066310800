import * as storage from '../storage';
import { changeMutedCase } from './changeMuted';
import { saveEndpointCase } from './saveEndpoint';
import { applyState } from '../../utils';
import { getCurrentUserId } from '../../../../../storeGetters';
import urlBase64ToUint8Array from '../../../../../utils/urlBase64ToUint8Array';
import { noop } from '../../../../../utils/utils';

export const setPushNotificationsMuted = () => async (dispatch, getState) =>
  changeMutedCase(
    {
      getCurrentUserId: applyState(getState, getCurrentUserId),
      changeMuted: storage.changeMuted
    },
    { isMuted: true }
  );

export const setPushNotificationsActivated = () => async (dispatch, getState) =>
  changeMutedCase(
    {
      getCurrentUserId: applyState(getState, getCurrentUserId),
      changeMuted: storage.changeMuted
    },
    { isMuted: false }
  );

export const saveEndpoint = () => (dispatch, getState) =>
  saveEndpointCase({
    getCurrentUserId: applyState(getState, getCurrentUserId)
  });

export const subscribe =
  ({ onSucceed = noop } = {}) =>
  async (dispatch) => {
    if ((await Notification.requestPermission()) === 'denied') return;

    const registration = await navigator.serviceWorker.ready;
    const vapidPublicKey = process.env.VAPID_PUBLIC_KEY;
    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidKey
    });

    dispatch(saveEndpoint());

    await storage.pushSubscribe(subscription);
    onSucceed();
  };

export const unsubscribe = () => async () => {
  const registration = await navigator.serviceWorker.ready;
  const subscription = await registration.pushManager.getSubscription();
  await subscription.unsubscribe();
  return storage.pushUnsubscribe(subscription);
};
