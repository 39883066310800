import { haveEditPermission, haveFullPermission } from '../../../../../roles';
import { isChannel, isTopic } from '../../../../../helpers/chatType';

const ALLOWED_CHAT_TYPE_FOR_PIN_MESSAGES = ['channel', 'topic'];

export function checkPinBtn(chat, currentEmployeeId, extraList = []) {
  if (!ALLOWED_CHAT_TYPE_FOR_PIN_MESSAGES.includes(chat.type.toLowerCase())) {
    return filterPinBtn(extraList);
  }

  if (isChannel(chat) && !haveFullPermission(chat, currentEmployeeId)) {
    return filterPinBtn(extraList);
  }

  if (isTopic(chat) && !haveEditPermission(chat, currentEmployeeId)) {
    return filterPinBtn(extraList);
  }

  return extraList;
}

function filterPinBtn(extraList = []) {
  return extraList.filter((btn) => btn.conditionName !== 'pinned?');
}
