import React from 'react';

const IconSimplePlus = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00001 11L6.00001 1.00001L7.00001 1.00001L7.00001 11H6.00001Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 6.5L11.5 6.5L11.5 5.5L1.5 5.5L1.5 6.5Z"
      fill="#767B92"
    />
  </svg>
);

export default IconSimplePlus;
