import React, { useRef } from 'react';
import cn from 'classnames';
import {
  AutoSizer,
  Table,
  Column,
  CellMeasurer,
  CellMeasurerCache
} from 'react-virtualized';
import classes from './VitrualizedTable.module.css';

const HeaderRenderer = ({ cellClassName, headerHeight, label }) => (
  <div
    className={cn(classes.cell, cellClassName)}
    style={{ height: headerHeight }}>
    <span>{label}</span>
  </div>
);

const CellRenderer = (props) => {
  const { cellData, cellClassName, rowHeight } = props;

  return (
    <div
      className={cn(classes.cell, cellClassName)}
      style={{ height: rowHeight }}>
      {cellData}
    </div>
  );
};

const calculateCellHeight = ({
  cellHeightsCache = [],
  columnCount,
  rowIndex
}) => {
  const cellHeightsByRow = new Array(columnCount)
    .fill(0)
    .map((_, index) => cellHeightsCache[`${rowIndex}-${index}`]);

  return Math.max(...cellHeightsByRow);
};

const VirtualTable = ({
  columns,
  rows,
  rowHeight = 34,
  headerHeight = 34,
  rowCount = rows.length,
  rowGetter,
  cellDataGetter,
  className,
  headerClassName,
  rowClassName,
  cellClassName,
  headerRenderer = HeaderRenderer,
  cellRenderer = CellRenderer,
  ...restTableProps
}) => {
  const _rowGetter = rowGetter || (({ index }) => rows[index]);

  const cellMeasurerCacheRef = useRef(
    new CellMeasurerCache({
      defaultWidth: rowHeight,
      minWidth: rowHeight,
      fixedWidth: true
    })
  );

  const _cellRenderer = (props) => {
    const { columnIndex, key, parent, rowIndex } = props;

    const cellHeight = calculateCellHeight({
      cellHeightsCache: cellMeasurerCacheRef.current._cellHeightCache,
      columnCount: cellMeasurerCacheRef.current._columnCount,
      rowIndex
    });

    return (
      <CellMeasurer
        cache={cellMeasurerCacheRef.current}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}>
        {(...measureProps) =>
          cellRenderer({
            ...props,
            rowHeight: cellHeight,
            measure: measureProps
          })
        }
      </CellMeasurer>
    );
  };

  return (
    <AutoSizer className={cn(classes.table, className)}>
      {({ height, width }) => (
        <Table
          headerHeight={headerHeight}
          height={height}
          deferredMeasurementCache={cellMeasurerCacheRef.current}
          rowHeight={cellMeasurerCacheRef.current.rowHeight}
          rowCount={rowCount}
          rowGetter={_rowGetter}
          rowClassName={cn(classes.row, rowClassName)}
          width={width}
          {...restTableProps}>
          {columns.map(({ dataKey, ...other }, index) => (
            <Column
              key={dataKey}
              dataKey={dataKey}
              cellDataGetter={cellDataGetter}
              headerRenderer={(headerProps) =>
                headerRenderer({ ...headerProps, columnIndex: index })
              }
              className={cn(classes.rowColumn, cellClassName)}
              cellRenderer={_cellRenderer}
              headerClassName={cn(classes.header, headerClassName)}
              {...other}
            />
          ))}
        </Table>
      )}
    </AutoSizer>
  );
};

export default VirtualTable;
