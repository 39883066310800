import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
import Attached from '../icons/attached';
import ButtonIcon from '../../ChatWidget/ButtonIcon';

const OtherViewer = ({ name, size, onDownload }) => (
  <div className="other-viewer__container">
    <div className="other-viewer__card">
      <div className="card__icon">
        <Attached />
      </div>
      <div className="card__info">
        <div className="card__title">{name}</div>
        <div className="card__size">{size}</div>
      </div>
      <div className="card__button">
        <ButtonIcon
          disabled={false}
          onClick={onDownload}
          icon="download"
          tooltip="download"
        />
      </div>
    </div>
  </div>
);

OtherViewer.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  onDownload: PropTypes.func.isRequired
};

export default OtherViewer;
