import React from 'react';
import cn from 'classnames';
import Typography from '../Typography/Typography';
import { Palette } from '../../lib/colors/palette';
import { getColor } from '../../lib/colors/util';
import { removeUndefinedProps } from '../../utils/removeUndefinedProps';
import classes from './Chip.module.css';

/**
 * @typedef {'filed'|'outlined'} Variants
 */

/**
 *
 * Simple Chip component
 *
 * @param {object} params
 * @param {string} params.className
 * @param {import('../../lib/colors/typedef').Palette} params.textColor
 * @param {'Lavender', 'Cardinal', 'Grass', 'Orange', 'White'} params.bgColor
 * @param {Variants} params.variant
 * @param {string} params.label
 * @returns {React.ReactNode}
 */
const Chip = ({ className, textColor, bgColor, variant = 'filed', label }) => {
  const _textColor = getColor(textColor, Palette);
  const _bgColor = getColor(bgColor, Palette);

  const style = removeUndefinedProps({
    '--chip-text-color': _textColor,
    '--chip-bg-color': _bgColor,
    '--chip-border-color': _bgColor
  });

  return (
    <div
      className={cn(classes.chip, className, {
        [classes.filed]: variant === 'filed',
        [classes.outlined]: variant === 'outlined'
      })}
      style={style}>
      <Typography variant="body2Med" className={classes.text}>
        {label}
      </Typography>
    </div>
  );
};

export default Chip;
