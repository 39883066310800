import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ButtonIcon from 'react-components/src/components/ButtonIcon';
import icons from 'react-components/src/icons/constants';
import Select from '../../../Dropdown/Select';
import Dashboard from './layout';
import { getChatWidget } from '../../../getter';
import * as chatWidgetUC from '../../../useCases';
import { useTranslate } from '../../../../../TranslateProvider';

const CWSDashboardArchive = ({ chatWidget, handleArchiveBtnClick }) => {
  const checkIfSelected = (type) => chatWidget.filter.archive.value === type;
  const sortActive = chatWidget.sidebarHeaderView === 'sort';
  const t = useTranslate();

  const filterList = [
    {
      icon: 'without',
      value: 'all-chats',
      selected: checkIfSelected('all-chats')
    },
    { icon: 'without', value: 'dialogs', selected: checkIfSelected('dialogs') },
    {
      icon: 'without',
      value: 'channels',
      selected: checkIfSelected('channels')
    },
    { icon: 'without', value: 'topics', selected: checkIfSelected('topics') }
  ];

  return (
    <Dashboard
      left={
        <Select
          title={chatWidget.filter.archive}
          onSelect={(item) => handleArchiveBtnClick('change-filter', item)}
          list={filterList}
        />
      }
      right={
        <>
          <ButtonIcon
            t={t}
            icon={icons.Search}
            tooltip="search-by-archive"
            size={18}
            onClick={() => handleArchiveBtnClick('search')}
          />
          <ButtonIcon
            icon={icons.StylePunkt}
            size={18}
            onClick={() => handleArchiveBtnClick('folder')}
            active={chatWidget.blockGroupChats.kind === 'chat-archive'}
          />
          <ButtonIcon
            t={t}
            tooltip={sortActive ? 'sort-off' : 'sort'}
            icon={icons.Sort}
            size={18}
            onClick={() => handleArchiveBtnClick('sorting', !sortActive)}
            active={sortActive}
          />
        </>
      }
    />
  );
};

CWSDashboardArchive.propTypes = {
  chatWidget: PropTypes.object.isRequired,
  handleArchiveBtnClick: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    chatWidget: getChatWidget(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...chatWidgetUC
      },
      dispatch
    )
)(CWSDashboardArchive);
