import React from 'react';

/* eslint-disable max-len */
function IconBell() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-stroke"
        d="M21.5 20.5V14C21.5 11.5147 19.4853 9.5 17 9.5V9.5C14.5147 9.5 12.5 11.5147 12.5 14V20.5"
        stroke="#767B92"
      />
      <line
        className="cws-icon-main-stroke"
        x1="10"
        y1="20.5"
        x2="24"
        y2="20.5"
        stroke="#767B92"
      />
      <path
        className="cws-icon-main-stroke"
        d="M19.45 22.5C19.2184 23.6411 18.2095 24.5 17 24.5C15.7905 24.5 14.7816 23.6411 14.55 22.5H19.45Z"
        stroke="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconBell;
