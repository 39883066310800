import React from 'react';
import cn from 'classnames';
import { useTranslate } from '../TranslateProvider';

export default ({
  text,
  status,
  className,
  iconClassName,
  textClassName,
  textStyle = {},
  iconStyle = {},
  statusColors = {}
}) => {
  const t = useTranslate();

  const statusColor = {
    draft: '#c6cae3',
    canceled: '#767b92',
    rejected: '#767b92',
    denied: '#767b92',
    'access denied': '#767b92',
    sent: '#163881',
    pending: '#163881',
    confirmed: '#1c50de',
    published: '#00cee0',
    received: '#00cee0',
    added: '#00cee0',
    'partial shipment': '#252b49',
    shipped: '#252b49',
    new: '#fd4f53',
    viewed: '#163881',
    has_changes: '#fd4f53', // eslint-disable-line camelcase
    available: '#fd4f53',
    waiting_for_approval: '#fd4f53', // eslint-disable-line camelcase
    approved: '#00cee0',
    editing: '#163881',
    'request unpublished': '#c6cae3',
    'request changes': '#fd4f53',
    'request published': '#00cee0',
    receiving_responses: '#163881', // eslint-disable-line camelcase
    reception_completed: '#B9BFCF', // eslint-disable-line camelcase
    removed_publication: '#FD4F53', // eslint-disable-line camelcase
    archived: '#C6CAE3',
    on_approval: '#FD7C6E', // eslint-disable-line camelcase
    on_introduction: '#F1D367', // eslint-disable-line camelcase
    denial_of_approval: '#DD1E2A', // eslint-disable-line camelcase
    for_publication: '#9182E2', // eslint-disable-line camelcase
    notified: '#0E7394',
    agreed: '#2BA13E',
    ...statusColors
  };

  function getText() {
    if (text) return text;

    return Object.keys(statusColor).includes(status)
      ? `Status ${status}`
      : status;
  }

  function isShowIcon() {
    const currentColor = Object.entries(statusColor).find(
      ([statusKey]) => statusKey === status
    )?.[1];

    return Boolean(currentColor);
  }

  return (
    <div className={cn('status-wrap', className)}>
      {isShowIcon() && (
        <div
          className={cn('status-icon', iconClassName)}
          style={{ backgroundColor: statusColor[status], ...iconStyle }}
        />
      )}
      {['publishing', 'import'].includes(status) && (
        <img
          src="/img/info-black.svg"
          alt="profile"
          style={{ marginLeft: -2, height: 15, ...iconStyle }}
        />
      )}
      <span
        className={cn('status-text', textClassName)}
        style={
          ['publishing', 'import'].includes(status)
            ? Object.assign(textStyle, { marginLeft: 8 })
            : textStyle
        }>
        {t(getText())}
      </span>
    </div>
  );
};
