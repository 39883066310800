import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { defaultTo } from 'ramda';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import Header from './Header';
import Title from './Title';
import Editor from './Editor';
import Files from './Files';

import * as useCases from '../../modules/topics/useCases';
import { getOpenedChat } from '../../getter';
import { haveFreeStorageSpace } from '../../../../storeGetters';
import {
  getColor,
  getDesc,
  getEditMode,
  getId,
  getName,
  getTitle,
  initOnCancel,
  initOnChangeDescription,
  initOnChangeName,
  initOnChangeTitle,
  initOnSave,
  isCannotSave,
  makeCreationTopicVM
} from './dataType';
import { getNotRemovedFiles } from './getters';

import './style.css';

const COLOR = '#DE95BA';
const MAX_NAME_LENGTH = 60;

const defaultToStr = defaultTo('');

const wrapper = ({ editMode = false }) => {
  const currentTopic = useSelector(getOpenedChat);
  const haveFreeSpace = useSelector(haveFreeStorageSpace);
  const files = useSelector(getNotRemovedFiles(editMode));

  const onSave = bindActionCreators(
    useCases.createOrUpdateTopic,
    useDispatch()
  );
  const onCancel = bindActionCreators(
    useCases.closeTopicDashboard,
    useDispatch()
  );

  const defaultValues = getDefaultValues();
  const { id } = defaultValues;
  const [name, _onChangeName] = useState(defaultValues.name);
  const [title, onChangeTitle] = useState(defaultValues.title);
  const [desc, onChangeDescription] = useState(defaultValues.desc);

  const onChangeName = (value) => {
    _onChangeName(value.substring(0, MAX_NAME_LENGTH));
  };

  return makeCreationTopicVM({
    editMode,
    id,
    color: COLOR,
    name,
    title,
    desc,
    files,
    haveFreeSpace,
    onChangeName,
    onChangeTitle,
    onChangeDescription,
    onSave,
    onCancel
  });

  function getDefaultValues() {
    return {
      id: editMode ? currentTopic.id : null,
      name: editMode ? defaultToStr(currentTopic.name) : '',
      title: editMode ? defaultToStr(currentTopic.title) : '',
      desc: editMode ? defaultToStr(currentTopic.desc) : ''
    };
  }
};

const CWCreateTopic = ({ editMode }) => {
  const vm = wrapper({ editMode });

  return (
    <div className="cw-create-topic">
      <Header
        name={getName(vm)}
        onChangeName={initOnChangeName(vm)}
        color={getColor(vm)}
        onCancel={initOnCancel(vm)}
        onSave={initOnSave(vm)}
        isSaveDisabled={isCannotSave(vm)}
      />
      <Title value={getTitle(vm)} onChangeTitle={initOnChangeTitle(vm)} />
      <Editor value={getDesc(vm)} onChange={initOnChangeDescription(vm)} />
      <Files editMode={getEditMode(vm)} chatId={getId(vm)} />
    </div>
  );
};

CWCreateTopic.propTypes = {
  editMode: PropTypes.bool
};

CWCreateTopic.defaultProps = {
  editMode: false
};

export default CWCreateTopic;
