import React from 'react';
import PropTypes from 'prop-types';

const GridPageMessage = ({ children }, { b }) => (
  <div className={b('message')}>{children}</div>
);

GridPageMessage.contextTypes = {
  b: PropTypes.func
};

export default GridPageMessage;
