import React from 'react';
import { LinksList } from '../../../PurchaseRequests/Request/LinksListBlock';
import { makeLinkVM } from '../../../PurchaseRequests/Request/linksVM';

const Links = ({ links, theme }) => {
  const linksVM = links.map((link) =>
    makeLinkVM({ key: link.id, url: link.url, name: link.name, buttons: [] })
  );

  return (
    <div className={`cw-topic__links cw-topic__links-theme-${theme}`}>
      <LinksList links={linksVM} />
    </div>
  );
};

export default Links;
