const { mergeRight } = require('ramda');
const typeOfClass = require('./typeOfClass');
const defMethod = require('./method');
const structMethods = require('./struct');
const recordMethods = require('./record');
const checkMethods = require('./checkMethods');

module.exports = mergeRight(
  { defMethod, typeOfClass, checkMethods },
  { ...structMethods, ...recordMethods }
);
