export const makeCreationTopicVM = ({
  editMode,
  id,
  color,
  name,
  title,
  desc,
  files,
  haveFreeSpace,
  onChangeName,
  onChangeTitle,
  onChangeDescription,
  onSave,
  onCancel
}) => ({
  editMode,
  id,
  color,
  name,
  title,
  desc,
  files,
  haveFreeSpace,
  onChangeName,
  onChangeTitle,
  onChangeDescription,
  onSave,
  onCancel
});

export const getEditMode = (vm) => vm.editMode;
export const getId = (vm) => vm.id;
export const getColor = (vm) => vm.color;
export const getName = (vm) => vm.name;
export const getTitle = (vm) => vm.title;
export const getDesc = (vm) => vm.desc;
const getFiles = (vm) => vm.files;
export const getHaveFreeSpace = (vm) => vm.haveFreeSpace;

const isAllFilled = (vm) =>
  getName(vm).trim() !== '' && getTitle(vm).trim() !== '';
export const isCannotSave = (vm) => !isAllFilled(vm);

export const isBlockedFunctions = (vm) => !getHaveFreeSpace(vm);

export const initOnChangeName = (vm) => vm.onChangeName;
export const initOnChangeTitle = (vm) => vm.onChangeTitle;
export const initOnChangeDescription = (vm) => vm.onChangeDescription;

export const initOnCancel = (vm) => () => vm.onCancel(getId(vm));

export const initOnSave = (vm) => () =>
  vm.onSave({
    editMode: getEditMode(vm),
    id: getId(vm),
    name: getName(vm),
    title: getTitle(vm),
    desc: getDesc(vm),
    files: getFiles(vm)
  });
