/* eslint-disable max-len */
import React from 'react';

function IconCrossCircle() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.64693 9.64645C9.8422 9.45118 10.1588 9.45118 10.354 9.64645L17.0005 16.2929L23.6469 9.64645C23.8422 9.45118 24.1588 9.45118 24.354 9.64645C24.5493 9.84171 24.5493 10.1583 24.354 10.3536L17.7076 17L24.354 23.6464C24.5493 23.8417 24.5493 24.1583 24.354 24.3536C24.1588 24.5488 23.8422 24.5488 23.6469 24.3536L17.0005 17.7071L10.354 24.3536C10.1588 24.5488 9.8422 24.5488 9.64693 24.3536C9.45167 24.1583 9.45167 23.8417 9.64693 23.6464L16.2934 17L9.64693 10.3536C9.45167 10.1583 9.45167 9.84171 9.64693 9.64645Z"
        fill="#767B92"
      />
    </svg>
  );
}

/* eslint-enable max-len */
export default IconCrossCircle;
