exports.makeQuery = (namespace, name, generateRoomName) => ({
  get: {
    type: 'QUERY',
    name: `QUERY:${namespace}:GET:${name}`,
    operation: 'get'
  },
  sync: {
    type: 'QUERY',
    name: `QUERY:${namespace}:SYNC:${name}`,
    operation: 'sync'
  },
  syncOff: {
    type: 'QUERY',
    name: `QUERY:${namespace}:SYNC-OFF:${name}`,
    operation: 'sync-off'
  },
  set: {
    type: 'QUERY',
    name: `QUERY:${namespace}:SET:${name}`,
    operation: 'set'
  },
  add: {
    type: 'QUERY',
    name: `QUERY:${namespace}:ADD:${name}`,
    operation: 'add'
  },
  update: {
    type: 'QUERY',
    name: `QUERY:${namespace}:UPDATE:${name}`,
    operation: 'update'
  },
  remove: {
    type: 'QUERY',
    name: `QUERY:${namespace}:REMOVE:${name}`,
    operation: 'remove'
  },
  roomName: generateRoomName
});
