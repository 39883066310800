import React from 'react';
import ChatTitle from '../../../../../ChatTitle';
import {
  getChatName,
  getChatType,
  getExtraText,
  getInterlocutor
} from './dataType';

const ChatInfo = ({ vm }) => (
  <div className="cws-chat-item__chat-info">
    <div className="cws-chat-item__chat-title">
      <ChatTitle
        chatType={getChatType(vm)}
        chatName={getChatName(vm)}
        interlocutor={getInterlocutor(vm)}
        isShowNotify
      />
    </div>
    <div className="cws-chat-item__chat-extra">{getExtraText(vm)}</div>
  </div>
);

export default ChatInfo;
