import React, { useMemo } from 'react';
import { createStore } from '../../store/createStore';
import { PrintStatus } from './PrintStatus';
import { PrintContainer } from './PrintContainer';
import { Print } from './Print';
import './style.css';

export const printPlugin = ({
  enableShortcuts = true,
  renderLoadingPrint
} = {}) => {
  const store = useMemo(
    () => createStore({ printStatus: PrintStatus.Inactive }),
    []
  );

  const print = () => {
    store.update('printStatus', PrintStatus.Preparing);
  };

  const PrintDecorator = (pr) => (
    <Print enableShortcuts={enableShortcuts} {...pr} store={store} />
  );

  const renderViewer = ({ slot, doc, pageHeight, pageWidth }) => {
    const updateSlot = {
      children: (
        <>
          <PrintContainer
            doc={doc}
            pageHeight={pageHeight}
            pageWidth={pageWidth}
            renderLoadingPrint={renderLoadingPrint}
            store={store}
          />
          {slot.children}
        </>
      )
    };

    return { ...slot, ...updateSlot };
  };

  return {
    renderViewer,
    print,
    Print: PrintDecorator
  };
};
