import React from 'react';
import styled from 'styled-components';
import { Flex } from '../../../../styledComponents/atoms';

const FilesList = ({ t }) => (
  <NotFoundCont>
    <img src="/img/ico_file.svg" alt="" />
    <div className="files-empty-text">{t('storage-empty')}</div>
  </NotFoundCont>
);

const NotFoundCont = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 130px 0;
`;

export default FilesList;
