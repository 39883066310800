import { convertFileUrls } from '../../files/utils';

export const attachFromStorageService = async (
  {
    reqAttachTopicUnsaveInfoFileFromStorage,
    reqGetTopicUnsaveInfoFiles,
    setInputFiles,
    showFileAttachModal
  },
  { id = '_' }
) => {
  const onAttach = async (fileIds) => {
    await reqAttachTopicUnsaveInfoFileFromStorage(id, fileIds);
    const uploadedFiles = await reqGetTopicUnsaveInfoFiles(id);
    setInputFiles(convertFileUrls(uploadedFiles));
  };
  showFileAttachModal({ onAttach });
};
