import { logUseCase } from '../../../../../utils/logger';

const logDF = logUseCase.extend('deleteFileService');
export const deleteFileService = async (
  {
    reqGetTopicUnsaveInfoFiles,
    getChatInput,
    getOpenedChat,
    fileIncludes,
    addFileToTempRemove,
    removeUnsaveInfoFile,
    setInputFiles
  },
  { editMode, id = '_', file }
) => {
  logDF(`editMode = ${editMode}`);
  if (editMode) {
    if (hasInChat()) {
      logDF(`remove file from chat`);
      addFileToTempRemove(file.id);
    }

    if (hasInAdd()) {
      logDF(`remove file from input`);
      await removeFile();
    }
    return;
  }

  await removeFile();

  async function removeFile() {
    await removeUnsaveInfoFile(id, file.id);
    const uploadedFiles = await reqGetTopicUnsaveInfoFiles(id);
    setInputFiles(uploadedFiles);
  }

  function hasInChat() {
    const chat = getOpenedChat();
    return fileIncludes(file, chat);
  }

  function hasInAdd() {
    const input = getChatInput();
    return fileIncludes(file, input);
  }
};
