import { ALLOWED_TYPE_CHATS } from 'core/data/light/scheduleMessages';
import { getOpenedChat } from '../../getter';

export const getFiredScheduleMessage = (state) => {
  const chat = getOpenedChat(state);

  if (!chat || !ALLOWED_TYPE_CHATS.includes(chat.type.toLowerCase()))
    return null;

  const { scheduleMessages = [] } = chat;
  const message = scheduleMessages.find((msg) => msg.isSent)?.message;

  return message || null;
};
