import { ReactNode, useState } from 'react';
import { WithClassName } from '@/components/common.types';
import { Icon } from '@/components/Icon';
import styles from './CollapsibleArea.module.scss';

export interface BtnCollapseProps {
  isCollapsed: boolean;
  onClick: () => void;
}

export function BtnCollapse({ isCollapsed, onClick }: BtnCollapseProps) {
  return (
    // TODO заменить на Button Icon
    <button type="button" className={styles.button} onClick={onClick}>
      <Icon
        iconName={isCollapsed ? 'arrow-up' : 'arrow-down'}
        width={14}
        height={14}
      />
    </button>
  );
}

export interface CollapsibleAreaProps extends WithClassName {
  isCollapsed: boolean;
  visibleContent: ReactNode;
  collapsibleContent: ReactNode;
}

export function CollapsibleArea({
  isCollapsed,
  className,
  visibleContent,
  collapsibleContent
}: CollapsibleAreaProps) {
  const [collapsed, setCollapsed] = useState(isCollapsed);
  const handleCollapse = () => {
    setCollapsed((prevState) => !prevState);
  };

  return (
    <div className={className}>
      <div className={styles.collapsibleArea}>
        <div className={styles.visibleHeader}>
          <div className={styles.visibleContent}>{visibleContent}</div>
          <BtnCollapse onClick={handleCollapse} isCollapsed={!collapsed} />
        </div>
        {!collapsed && (
          <div className={styles.collapsibleContent}>{collapsibleContent}</div>
        )}
      </div>
    </div>
  );
}
