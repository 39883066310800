export const getToLink = ({
  tokenLink = null,
  partnerToken = null,
  loginLink = null
}) => {
  if (tokenLink) {
    return `/welcome/login/${tokenLink}`;
  }

  if (partnerToken) {
    return `/welcome/partner/login/${partnerToken}`;
  }

  if (loginLink) {
    return loginLink;
  }

  return '/login';
};
