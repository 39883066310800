import React from 'react';
import './style.css';
import Dashboard from './dashboard';
import Search from './search';
import Sort from './sort';

const sortList = [
  { icon: 'without', value: 'sort-by-chat-name' },
  { icon: 'without', value: 'sort-by-creation-date' },
  { icon: 'without', value: 'sort-by-author' },
  { icon: 'without', value: 'sort-by-last-update' }
];

/**
 * @typedef {import('../../../lib/data-type.js')} ReactDom
 */

/**
 * Render the header
 *
 * @param {object} params
 * @param {string} params.view - one of (dashboard, search)
 * @param {string} params.activeTab
 * @param {object} params.config
 * @returns {ReactDom}
 */
const CWSHeader = ({ view = 'dashboard', activeTab, config }) => (
  <div className="cws-header">
    {['dashboard', 'sort'].includes(view) && (
      <Dashboard activeTab={activeTab} config={config} />
    )}
    {view === 'sort' && <Sort list={sortList} />}
    {view === 'search' && <Search config={config} />}
  </div>
);

export default CWSHeader;
