import { defRecord } from 'core/lib';
import { makeChatVM } from './item/dataType';

const { makeRowsVM, getChats, getOnline, getUser } = defRecord(
  'RowsVM',
  ['t', 'chats', 'user', 'online', 'onClick'],
  {
    chats: [],
    onClick: () => {}
  }
);

const generateChatVM = (vm, chat) =>
  makeChatVM({
    t: vm.t,
    chat,
    user: getUser(vm),
    online: getOnline(vm),
    onClick: () => vm.onClick(chat)
  });

export { makeRowsVM, getChats, generateChatVM };
