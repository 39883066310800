import { useCallback, useRef, useState } from 'react';
import { useIsomorphicLayoutEffect, useRafState } from 'react-use';
import { ScrollMods } from '../constants';

const ZERO_OFFSET = {
  left: 0,
  top: 0
};

const SCROLL_EVENT_OPTIONS = {
  capture: false,
  passive: true
};

export const useScroll = ({ ref, isRtl, scrollMode }) => {
  const [scrollOffset, setScrollOffset] = useRafState(ZERO_OFFSET);
  const [element, setElement] = useState(ref.current);
  const factor = isRtl ? -1 : 1;
  const latestRef = useRef(scrollMode);
  latestRef.current = scrollMode;

  useIsomorphicLayoutEffect(() => {
    setElement(ref.current);
  });

  useIsomorphicLayoutEffect(() => {
    if (!element) {
      return undefined;
    }

    const handleScroll = () => {
      switch (latestRef.current) {
        case ScrollMods.Horizontal:
          setScrollOffset({
            left: factor * element.scrollLeft,
            top: 0
          });
          break;
        case ScrollMods.Vertical:
        default:
          setScrollOffset({
            left: 0,
            top: element.scrollTop
          });
          break;
      }
    };

    element.addEventListener('scroll', handleScroll, SCROLL_EVENT_OPTIONS);

    return () => {
      element.removeEventListener('scroll', handleScroll, SCROLL_EVENT_OPTIONS);
    };
  }, [element]);

  const scrollTo = useCallback(
    (offset) => {
      const elem = ref.current;
      if (elem) {
        switch (latestRef.current) {
          case ScrollMods.Horizontal:
            elem.scrollLeft = factor * offset.left;
            break;
          case ScrollMods.Vertical:
          default:
            elem.scrollTop = offset.top;
            break;
        }
      }
    },
    [ref]
  );

  return {
    scrollOffset,
    scrollTo
  };
};
