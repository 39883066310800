import { curry } from 'ramda';
import { nanoid } from 'nanoid';
import { defRecord } from 'core/lib';
import {
  initUseStateDataType,
  getState,
  getData,
  updateState
} from '../../../lib/data-hook';
import {
  makeList,
  add,
  removeById,
  total,
  isEmpty,
  getItems,
  clear,
  isEqualItems
} from './list';

const genId = () => nanoid(7);
const { makeHLink } = defRecord('HLink', ['id', 'name', 'url']);

/*
 * Hook used for add and remove link from list
 */

export const tranformHLink = ({ id = genId(), text, url }) =>
  makeHLink({ id, name: text, url });
const addLinkToList = curry(({ text, url }, hookList) =>
  add(tranformHLink({ text, url }), hookList)
);

export const useListLinks = initUseStateDataType(makeList);
export const addLink = updateState(addLinkToList);
export const removeLink = updateState(removeById);
export const clearLinks = updateState((none, list) => clear(list), null);
export const totalLinks = getData(total);
export const isNoLinks = getData(isEmpty);
export const getLinks = getData(getItems);
export const isEqualLinks = (links, hook) =>
  isEqualItems(links, getState(hook));
