import React, { useState } from 'react';
import '../style.css';

function DynamicBorderInput({
  value,
  onChange,
  placeholder,
  maxLength,
  disabled = false,
  required = false
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [isHover, setIsHover] = useState(false);

  function onFocus() {
    setIsFocused(true);
  }

  function onBlur() {
    setIsFocused(false);
  }

  function onMouseEnter() {
    setIsHover(true);
  }

  function onMouseLeave() {
    setIsHover(false);
  }

  function showRequiredMark() {
    return required && !isHover && !isFocused;
  }

  return (
    <div className="dynamic-border-require-input-wrapper">
      <input
        className="dynamic-border-require-input"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        maxLength={maxLength}
        size={value.length || placeholder.length}
      />
      {showRequiredMark() && (
        <div className="dynamic-border-require-input__required">*</div>
      )}
    </div>
  );
}

export default DynamicBorderInput;
