export class SaveNoteDto {
  constructor(props) {
    this.id = props.id;
    this.fromUserId = props.fromUserId;
    this.targetCompanyId = props.targetCompanyId;
    this.description = props.description;
    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }
}
