import React, { useState } from 'react';
import { isEmpty } from 'ramda';
import { Modal, Button } from 'react-bootstrap';
import { ModalContainer } from '../elements';
import * as modalActions from '../../../action-creators/modal';
import { useTranslate } from '../../../TranslateProvider';
import SectionOffset from '../../PurchaseRequests/Request/SectionOffset';
import { filterOptionsByValue, toggleItem } from './helper';
import CardsList from './CardsList';
import SearchBlockDefault from '../../SearchBlock/SearchBlockDefault';
import { useActions } from '../../../hooks/useActions';
import { initTranslateComponent } from '../../../utils/translateComponent';
import '../styles/modal_theme_default.styl';

const MAX_SELECTED_COUNT = 10;

const ModalItemsSelect = ({
  modalId = 'selectItems',
  header,
  title,
  noResultsText,
  submitText,
  searchPlaceholder,
  noticeText,
  list = [],
  selectedList = [],
  onChoose = () => {},
  isQuantityLimited = true,
  maxSelectedCount = MAX_SELECTED_COUNT
}) => {
  const t = useTranslate();
  const translate = initTranslateComponent(t, 'modalSelectItems');

  const { hideModalDialog } = useActions(modalActions);

  const [filteredList, setFilteredList] = useState(list);
  const [isSearch, setIsSearch] = useState(false);
  const [selectedIds, setSelectedIds] = useState(selectedList);

  const onSearch = (value = '') => {
    if (!isEmpty(value.trim())) {
      setIsSearch(true);
      setFilteredList(filterOptionsByValue({ list, value }));
    } else {
      setFilteredList(list);
      setIsSearch(false);
    }
  };

  const onItemChoose = (itemValue) => () => {
    const newItems = toggleItem({ selectedList: selectedIds, itemValue });
    if (newItems.length <= maxSelectedCount) {
      setSelectedIds(newItems);
    }
  };

  const onSubmit = () => {
    onChoose(selectedIds);
    hideModalDialog();
  };

  return (
    <Modal id={modalId} onHide={hideModalDialog} show>
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal.Header>
          <Modal.Title>
            {header || translate('header')}
            <span
              className="modal-header-cross-top"
              onClick={hideModalDialog}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchBlockDefault
            width="100%"
            style={{ height: 34 }}
            placeholder={searchPlaceholder || translate('searchPlaceholder')}
            onSubmit={onSearch}
            onEmpty={onSearch}
            onChange={onSearch}
          />
          <SectionOffset offset={32} />

          <Modal.Title style={{ fontSize: 14 }}>
            {title || translate('title')}
          </Modal.Title>

          <SectionOffset offset={16} />
          <div style={{ maxHeight: 400, overflow: 'auto' }}>
            <CardsList
              list={filteredList}
              selectedIds={selectedIds}
              onItemChoose={onItemChoose}
              level={0}
            />
            {isSearch && isEmpty(filteredList) && (
              <span style={{ color: '#767B92' }}>
                {noResultsText || translate('noResultsText')}
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: 'flex',
            alignItems: 'center',
            background: '#F2F4F8',
            borderRadius: '0px 8px 8px 10px'
          }}>
          {isQuantityLimited && (
            <span style={{ color: '#767B92' }}>
              {noticeText ||
                translate('notice', { maxCount: maxSelectedCount })}
            </span>
          )}
          <Button
            bsStyle="primary"
            style={{ background: 'transparent', marginLeft: 'auto' }}
            onClick={onSubmit}>
            {submitText || translate('submit')}
          </Button>
        </Modal.Footer>
      </ModalContainer>
    </Modal>
  );
};

export default ModalItemsSelect;
