import { increateLoadCounter, resetPage } from '../../../data-type/query';

export const uploadArchivesService = async (
  {
    reqGetArchives,
    setArchives,
    getArchivesQuery,
    updateArchivesQuery,
    getChatGroups,
    transformToChatModels
  },
  { newParams = {} }
) => {
  const archives = await reqGetArchives(newParams);
  setArchives(transformToChatModels(archives));
  getChatGroups();

  const query = newParams.query || getArchivesQuery();
  updateArchivesQuery(increateLoadCounter(resetPage(query)));
};
