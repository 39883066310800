import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import CWMessages from '../../main/chat-messages';
import Header from './header';
import { getChatSearchMsgs, getOpenedChat, getCurrUser } from '../../getter';
import * as useCase from '../../useCases';
import './style.css';
import SearchNotFound from './not-found';
import { defMulti, addHandler, call } from '../../../../lib/multi';
import { makeQuery } from '../../../../lib/query';
import {
  makeViewModel,
  isFirstLoadMsgs,
  isNoMessages,
  searchQueryValue,
  initSetSearchQuery,
  initOnChangeDates,
  initLoadMore,
  isActiveCalendar,
  initOnSearch,
  initOnClose,
  chat,
  user,
  amountLeftNext,
  messages,
  initHandlerMsg,
  ownConfig,
  otherConfig,
  config,
  messagesPlacement
} from './dataTypes';
import * as useCasesSM from './useCases';
import { usePlacement } from '../../main/chat-items/hooks';

// Example of config message
// const ownConfig = {
//   extraBtn: { name: 'go-to-msg', tooltip: 'go-to-msg' },
//   buttonList: [],
//   showReadMsg: false
// };
// const otherConfig = {
//   extraBtn: { name: 'go-to-msg', tooltip: 'go-to-msg' },
//   buttonList: []
// };

const getCNContent = (conf) =>
  `cwm-search-content cwm-search-content_theme_${conf.theme}`;

const wrapper = ({ config: conf }) => {
  const [query, saveQuery] = useState(makeQuery());
  const userParam = useSelector(getCurrUser);
  const blockSearchMsgs = useSelector(getChatSearchMsgs);
  const openedChat = useSelector(getOpenedChat);
  const dispatch = useDispatch();
  const handleSearchMode = bindActionCreators(
    useCase.handleSearchMode,
    dispatch
  );
  const { searchMsgs, loadMoreMsgs, searchMsgsWithDateRange } =
    bindActionCreators(useCasesSM, dispatch);
  const placement = usePlacement();

  return makeViewModel({
    openedChat,
    user: userParam,
    config: conf,
    blockSearchMsgs,
    handleSearchMode,
    getQuery: () => query,
    saveQuery,
    searchMsgs,
    loadMoreMsgs,
    searchMsgsWithDateRange,
    messagesPlacement: placement
  });
};

// render

const MODE_BLANK = 'mode_blank';
const MODE_NOT_FOUND = 'mode_not_found';
const MODE_SHOW_MSGS = 'mode_show_msgs';

const content = defMulti('content', (vm) => {
  if (isFirstLoadMsgs(vm)) return MODE_BLANK;
  if (isNoMessages(vm)) return MODE_NOT_FOUND;
  if (!isNoMessages(vm)) return MODE_SHOW_MSGS;
  return undefined;
});

addHandler(content, MODE_BLANK, () => null);
addHandler(content, MODE_NOT_FOUND, () => <SearchNotFound />);
addHandler(content, MODE_SHOW_MSGS, (viewModel) => (
  <CWMessages
    chat={chat(viewModel)}
    user={user(viewModel)}
    list={messages(viewModel)}
    handlerMsg={initHandlerMsg(viewModel)}
    ownConfig={ownConfig(viewModel)}
    otherConfig={otherConfig(viewModel)}
    config={config(viewModel)}
    onLoadMoreNext={initLoadMore(viewModel)}
    amountLeftNext={amountLeftNext(viewModel)}
    messagesPlacement={messagesPlacement(viewModel)}
    isSearchMode
  />
));

const Search = (props) => {
  const viewModel = wrapper(props);

  return (
    <div className="cwm-search">
      <Header
        searchQuery={searchQueryValue(viewModel)}
        setSearchQuery={initSetSearchQuery(viewModel)}
        setDateRange={initOnChangeDates(viewModel)}
        isActiveCalendar={isActiveCalendar(viewModel)}
        onSearch={initOnSearch(viewModel)}
        onClose={initOnClose(viewModel)}
        config={config(viewModel)}
      />
      <div className={getCNContent(config(viewModel))}>
        {call(viewModel, content)}
      </div>
    </div>
  );
};

export default Search;
