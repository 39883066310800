import React, { Fragment, useEffect, useState } from 'react';
import { isNil } from 'ramda';
import { createPortal } from 'react-dom';
import Confirm from './confirm';

const PortalConfirms = ({ confirms }) => {
  const [parentNode, setParentNode] = useState(null);

  useEffect(() => {
    setParentNode(document.getElementById('topic-confirm-container'));
  }, []);

  if (isNil(parentNode)) return null;

  return createPortal(
    <>
      {confirms.map((confirm, key) => (
        <Confirm key={key} {...confirm} />
      ))}
    </>,
    parentNode
  );
};

const View = ({
  topConfirms = [],
  bottomConfirms = [],
  isShowPortal = false,
  children
}) => (
  <>
    {isShowPortal && <PortalConfirms confirms={topConfirms} />}
    {!isShowPortal &&
      topConfirms.map((confirm, key) => <Confirm key={key} {...confirm} />)}
    {children}
    {bottomConfirms.map((confirm, key) => (
      <Confirm key={key} {...confirm} />
    ))}
  </>
);

export default View;
