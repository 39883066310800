import React from 'react';
import Member from './Member';
import { useBindState } from '../../../../../lib/bindler';
import * as getters from './getters';
import {
  getCurrentMember,
  getMembers,
  getMode,
  isReadOnly,
  makeMembersVM
} from './dataType';
import { getCurrEmplId } from '../../../getter';

const wrapper = ({ mode, readOnly, chat, setHasChanges }) =>
  makeMembersVM({
    mode,
    readOnly,
    hardcodeType: chat.hardcodeType,
    setHasChanges,
    ...useBindState({
      currentEmployeeId: getCurrEmplId,
      members: getters.getMembers(chat)
    })
  });

function mapMembers(vm) {
  return getMembers(vm).map((member) => (
    <Member
      key={member.employeeId}
      readOnly={isReadOnly(vm)}
      mode={getMode(vm)}
      hardcodeType={vm.hardcodeType}
      currentMember={getCurrentMember(vm)}
      member={member}
      setHasChanges={vm.setHasChanges}
    />
  ));
}

const Members = (props) => {
  const vm = wrapper(props);

  return <div className="members">{mapMembers(vm)}</div>;
};

export default Members;
