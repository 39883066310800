import React, { useState, useEffect } from 'react';
import {
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal
} from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslate } from '../../../../TranslateProvider';
import { Body, Container, Footer, Header } from '../Components';
import Members from './Members';
import * as groupChatCases from '../../modules/groupChats';
import { useBindMethods } from '../../../../lib/bindler';
import {
  checkValidStatus,
  getBtnTextCancel,
  getBtnTextConfirm,
  getChat,
  getMaxNameLength,
  getMembersBlockLabel,
  getModalTitle,
  getMode,
  getName,
  getTitle,
  initOnCancel,
  initOnChange,
  initOnKeyDown,
  initOnSubmit,
  isReadOnly,
  isSubmitDisable,
  makeEditGroupChatVM
} from './dataType';
import * as useCases from './useCases';

import './styles.css';

const RequiredSymbol = () => <RequiredText>*</RequiredText>;

const CounterOfSymbols = ({ string, max }) => (
  <h6 className="pull-right" style={{ marginTop: 5 }}>
    <small>{`${string.length}/${max}`}</small>
  </h6>
);

const wrapper = (props) => {
  const { chat = {}, readOnly = false } = props;

  const t = useTranslate();

  const methods = useBindMethods({
    ...useCases,
    onSubmit: groupChatCases.editGroupChat
  });

  const [name, setName] = useState(chat.name || '');
  const [modalTitle, setModalTitle] = useState(getInitialModalTitle());
  const [hasMemberChanges, setHasMemberChanges] = useState(false);

  useEffect(() => {
    if (chat.name) setName(chat.name);
    setModalTitle(getInitialModalTitle());
  }, []);

  return makeEditGroupChatVM({
    t,
    mode: props.mode,
    chat: props.chat,
    readOnly: props.readOnly,
    btnTextCancel: props.btnTextCancel,
    btnTextConfirm: props.btnTextConfirm,
    isShowPrevious: props.isShowPrevious,
    name,
    setName,
    modalTitle,
    setModalTitle,
    hasMemberChanges,
    setHasMemberChanges,
    ...methods
  });

  function getInitialModalTitle() {
    const typeView = readOnly ? 'view' : 'edit';
    return `${chat.type.toLowerCase()}.${typeView}-channel`;
  }
};

const EditGroupChat = (props) => {
  const vm = wrapper(props);

  return (
    <Modal id="simpleSubmit" onHide={initOnCancel(vm)} show>
      <Container>
        <Header title={getModalTitle(vm)} onClose={initOnCancel(vm)} />
        <Body>
          <Form>
            <FormGroup validationState={checkValidStatus(vm)} controlId="name">
              <ControlLabel className="control-label-row">
                {getTitle(vm)}
                <RequiredSymbol />
              </ControlLabel>
              <CounterOfSymbols
                string={getName(vm)}
                max={getMaxNameLength(vm)}
              />
              <FormControl
                type="text"
                maxLength={getMaxNameLength(vm)}
                value={getName(vm)}
                onChange={initOnChange(vm)}
                disabled={isReadOnly(vm)}
                onKeyDown={initOnKeyDown(vm)}
              />
            </FormGroup>
            <div className="members-table-header">
              <div className="members-label">{getMembersBlockLabel(vm)}</div>
            </div>
            <Members
              chat={getChat(vm)}
              mode={getMode(vm)}
              readOnly={isReadOnly(vm)}
              setHasChanges={vm.setHasMemberChanges}
            />
          </Form>
        </Body>
        <Footer
          lbTitle={getBtnTextCancel(vm)}
          lbOnClick={initOnCancel(vm)}
          rbTitle={getBtnTextConfirm(vm)}
          rbOnClick={initOnSubmit(vm)}
          rbDisabled={isSubmitDisable(vm)}
        />
      </Container>
    </Modal>
  );
};

const RequiredText = styled.span`
  color: red;
`;

export default EditGroupChat;
