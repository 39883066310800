import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import None from './none';
import Chat from './chat';
import CreateTopic from './CreateTopic';
import { getChatMode, getAdvancedSearch } from '../getter';
import { isMessengerService } from '../../../lib/goodwix';
import * as ChatWidgetUC from '../useCases';
import * as ChatModeUC from '../modules/chatMode';
import * as ProfileUC from '../modules/profile';
import NoInternet from './noConnections/noInternet';
import { useActions } from '../../../hooks/useActions';
import { useMessengerConfig } from '../Messenger/hooks/useMessengerConfig';
import { useParams } from '../../../hooks/history';
import './style.css';

const POSSIBLE_CHAT_MODES = [
  'chat',
  'favorites',
  'show-delayed-messages',
  'select',
  'search',
  'show-hidden-messages'
];

const useActionByParam = () => {
  const { openDialogByEmployeeId, openChannelById, showProfile, setChatMode } =
    useActions({
      ...ChatWidgetUC,
      ...ProfileUC,
      ...ChatModeUC
    });

  const params = useParams();

  useEffect(() => {
    async function messengerActionByParam() {
      const {
        chat: chatEmployeeId,
        channels: channelId,
        profile: profileId,
        chatMode,
        chatId
      } = params;

      if (chatEmployeeId) {
        openDialogByEmployeeId(chatEmployeeId);
      }

      if (channelId) {
        openChannelById(channelId);
      }

      if (profileId) {
        showProfile(profileId);
        openDialogByEmployeeId(profileId);
      }

      if (chatMode && chatMode === 'topic-create') {
        setChatMode('topic-create');
      }
      if (chatMode && chatMode === 'topic-edit') {
        await openChannelById(chatId);
        setChatMode('topic-edit');
        // Delete query from URL
        window.history.replaceState(null, null, window.location.pathname);
      }
    }

    if (isMessengerService()) {
      messengerActionByParam();
    }
  }, [params]);
};

const ChatWidgetMain = React.memo(() => {
  const config = useMessengerConfig();
  const mode = useSelector(getChatMode);
  const search = useSelector(getAdvancedSearch);

  useActionByParam();

  const { currentMode } = mode;
  const { hidden: searchHidden } = search;
  const { modes } = config;

  return (
    <div
      className={`chat-widget-main ${
        !searchHidden ? 'chat-widget-main_search_open' : ''
      }`}>
      {modes.none && currentMode === 'none' && <None />}
      {modes.noInternet && currentMode === 'no-connection-internet' && (
        <NoInternet />
      )}
      {modes.chat && POSSIBLE_CHAT_MODES.includes(currentMode) && (
        <Chat mode={mode} config={config} />
      )}
      {modes.createTopic && currentMode === 'topic-create' && <CreateTopic />}
      {modes.editTopic && currentMode === 'topic-edit' && (
        <CreateTopic editMode />
      )}
    </div>
  );
});

export default ChatWidgetMain;
