import React, { useCallback, useEffect, useState } from 'react';
import { isNil } from 'ramda';
import { IMaskInput } from 'react-imask';
// eslint-disable-next-line import/no-extraneous-dependencies
import IMask from 'imask/esm/imask';
import classNames from 'classnames';
import { getTime, getTimeMask } from './utils';
import './style.css';

const PLACEHOLDER_CHAR = '0';
const iMaskBlockOption = {
  HH: {
    mask: IMask.MaskedRange,
    placeholderChar: PLACEHOLDER_CHAR,
    from: 0,
    to: 23,
    maxLength: 2
  },
  MM: {
    mask: IMask.MaskedRange,
    placeholderChar: PLACEHOLDER_CHAR,
    from: 0,
    to: 59,
    maxLength: 2
  }
};

function TimeInput({
  id,
  className,
  date,
  setDate,
  maskSeparator = ':',
  range,
  disabled = false
}) {
  const defaultTime = `${PLACEHOLDER_CHAR}${PLACEHOLDER_CHAR}${maskSeparator}${PLACEHOLDER_CHAR}${PLACEHOLDER_CHAR}`;

  const [dateTime, setDateTime] = useState(defaultTime);

  useEffect(() => {
    setDateTime(isNil(date) ? defaultTime : getTime(date));
  }, [range, id]);

  const onBlur = useCallback(() => {
    if (isNil(date)) return;

    const [hh, mm] = dateTime.split(maskSeparator);
    const updatedDate = new Date(date);
    updatedDate.setHours(+hh, +mm, 0, 0);

    setDate(updatedDate);
  }, [dateTime, date]);

  return (
    <IMaskInput
      id={id}
      mask={getTimeMask(maskSeparator)}
      overwrite
      autofix
      lazy={false}
      blocks={iMaskBlockOption}
      className={classNames({
        'custom-time-input': true,
        [className]: !isNil(className)
      })}
      onComplete={setDateTime}
      value={dateTime}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
}

export default TimeInput;
