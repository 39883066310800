import request from '../../lib/request';
import { escapeText } from '../../utils/utils';

export const createTopic = ({
  name,
  title,
  desc,
  files,
  employeesId,
  membersRoles
}) =>
  request.post(`/api/topic`, {
    name,
    title,
    desc,
    files,
    employeesId,
    membersRoles
  });

export const findTopic = (id) => request.get(`/api/topic/${id}`);
export const findTopicMessages = (id) =>
  request.get(`/api/topic/${id}/messages`);

export const getTopicUnsaveInfoFiles = (topicId) => {
  const id = topicId === '' ? '_' : topicId;
  return request.get(`/api/topic/${id}/unsave-info-files`);
};

export const attachUnsaveInfoFile = async (topicId, fileName, file) => {
  const id = topicId === '' ? '_' : topicId;
  return request.sendFile(
    `/api/topic/${id}/unsave-info-files/${escapeText(fileName)}`,
    file
  );
};

export const attachUnsaveInfoFileFromStorage = (topicId, filesId) =>
  request.post(`/api/topic/${topicId}/unsave-info-files`, { filesId });

export const removeUnsaveInfoFile = async (topicId, fileId) => {
  const id = topicId === '' ? '_' : topicId;
  return request.delete(`/api/topic/${id}/unsave-info-files/${fileId}`);
};

export const clearUnsaveInfoFile = async (topicId) => {
  const id = topicId === '' ? '_' : topicId;
  return request.delete(`/api/topic/${id}/unsave-info-files/all`);
};
