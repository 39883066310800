import React from 'react';
import { defRecord } from 'core/lib';
import { PURCHASE_REQUESTS_MESSAGE_TYPE } from 'core/data/light/purchaseRequestChannel';
import { isNil, isEmpty } from 'ramda';
import { makeMessageVM } from './message/dataType';
import CreatedRoomMsg from '../chat-messages/components/Message/CreatedRoomMessage';

const { makeForwardPanelVM, getChat } = defRecord('ForwardPanelVM', [
  't',
  'list',
  'chat',
  'currentEmployeeId',
  'showPreviewModal',
  'cancelForward',
  'showProfile'
]);

const getMessage = (vm) => vm.list[0];
const getMsgsCount = (vm) => vm.list.length;
const getAvatarInfo = (vm) => {
  const { avatarSrc, userName, employeeId, customerLogo, customerName } =
    getMessage(vm);

  if (isOriginalPRMessage(vm))
    return { type: 'company', src: customerLogo, alt: customerName };

  return { type: 'user', src: avatarSrc, alt: userName, employeeId };
};
const getPanelTitle = (vm) => {
  if (getMsgsCount(vm) > 1) return vm.t('forwarded_messages');

  return getMessage(vm).userName;
};
const getReactComponent = (vm) => {
  const message = getMessage(vm);
  if (message.type === 'MsgVideoRoomWasCreated') {
    return <CreatedRoomMsg message={message} chat={getChat(vm)} />;
  }
  if (
    message.type === 'MsgForwarded' &&
    message.originMsgType === 'MsgVideoRoomWasCreated'
  ) {
    return (
      <CreatedRoomMsg message={message.originMsgData} chat={getChat(vm)} />
    );
  }
  return null;
};

const generateMessageVM = (vm) =>
  makeMessageVM({
    t: vm.t,
    message: getMessage(vm),
    chat: vm.chat,
    currentEmployeeId: vm.currentEmployeeId,
    showProfile: vm.showProfile,
    component: getMsgsCount(vm) === 1 ? getReactComponent(vm) : null
  });

const isShowNothing = (vm) => isEmpty(vm.list);
const isShowAttachIcon = (vm) =>
  vm.list.some((m) => !isNil(m.files) && !isEmpty(m.files));
const isShowMsgAvatar = (vm) => getMsgsCount(vm) === 1;

const isShowLinkToMsgPreview = (vm) => getMsgsCount(vm) > 1;
const getMsgPreviewLinkText = (vm) => `${getMsgsCount(vm)} ${vm.t('messages')}`;

const isShowMessage = (vm) => getMsgsCount(vm) === 1;

const initOnShowPreview = (vm) => () => vm.showPreviewModal();
const initOnCancel = (vm) => () => vm.cancelForward();

function isOriginalPRMessage(vm) {
  return getMessage(vm).type === PURCHASE_REQUESTS_MESSAGE_TYPE;
}

export {
  makeForwardPanelVM,
  generateMessageVM,
  isShowNothing,
  isShowMsgAvatar,
  getAvatarInfo,
  getPanelTitle,
  isShowAttachIcon,
  initOnShowPreview,
  isShowLinkToMsgPreview,
  getMsgPreviewLinkText,
  isShowMessage,
  initOnCancel
};
