import React from 'react';
import Text from 'react-components/src/components/Typography';
import './style.css';

const NewsFeedInfo = ({ chatName }) => (
  <div className="cw-chat-card-info">
    <div className="cw-chat-card-info__chat-title">
      <Text
        level={1}
        weight={2}
        lineHeight={2}
        className="cw-chat-card-info__name">
        {chatName}
      </Text>
    </div>
    <Text
      className="cw-chat-card-info__subtitle"
      level={2}
      lineHeight={3}
      weight={3}>
      Chat-bot
    </Text>
  </div>
);

export default NewsFeedInfo;
