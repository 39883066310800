import { useModal } from '@hooks';
import { useCallback } from 'react';
import { CHOOSE_SUPPLIER_COMPANY_WIZARD_KEY } from './constants';

export function useChooseSupplierCompanyWizard() {
  const modalActions = useModal();

  const open = useCallback(() => {
    modalActions.onOpen(CHOOSE_SUPPLIER_COMPANY_WIZARD_KEY, {});
  }, [modalActions.onOpen]);

  const close = useCallback(() => {
    modalActions.onClose(CHOOSE_SUPPLIER_COMPANY_WIZARD_KEY);
  }, [modalActions.onClose]);

  return { open, close };
}
