import React from 'react';
import customHistory from '../../customHistory';

const linkClickHandle = (event, url) => {
  event.preventDefault();
  customHistory.push(url);
};

export default ({ text, linkUrl, name }) => (
  <a
    name={name}
    className="page-link"
    onClick={(event) => linkClickHandle(event, linkUrl)}
    href="">
    {text}
  </a>
);
