import req from '../lib/request';
import { doNotUseGetOrdersUnsentRequestsComments } from '../storeGetters/index';
import { saveToLSByState } from './lib/LS';

export const requestCategories = () => req.get('/api/requests/categories');

export const writeUnsentRequestsCommentsToLS = saveToLSByState(
  'unsentRequestComments',
  doNotUseGetOrdersUnsentRequestsComments
);
