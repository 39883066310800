import React, { memo } from 'react';
import { Icons } from 'react-components/src/icons';
import {
  isChannel,
  isDialog,
  isPurchaseRequestsChannel,
  isTopic,
  isNewsFeedChannel
} from '../../../../../helpers/chatType';
import DialogInfo from './DialogInfo';
import ChatInfo from './ChatInfo';
import PurchaseRequestChannelInfo from './PurchaseRequestChannelInfo';
import NewsFeedInfo from './NewsFeedInfo';

const CWChatInfo = ({
  chatType,
  chatName,
  members,
  interlocutor,
  online,
  isResponseChat
}) => {
  if (isDialog(chatType)) {
    return <DialogInfo interlocutor={interlocutor} />;
  }

  if (isChannel(chatType)) {
    return (
      <ChatInfo
        icon={isResponseChat ? Icons.Purchase : Icons.Group}
        chatName={chatName}
        members={members}
        online={online}
      />
    );
  }

  if (isTopic(chatType)) {
    return (
      <ChatInfo
        icon={isResponseChat ? Icons.Purchase : Icons.Topic}
        chatName={chatName}
        members={members}
        online={online}
      />
    );
  }

  if (isPurchaseRequestsChannel(chatType)) {
    return <PurchaseRequestChannelInfo chatName={chatName} />;
  }

  if (isNewsFeedChannel(chatType)) {
    return <NewsFeedInfo chatName={chatName} />;
  }

  throw new Error(`No such handler for chat type = ${chatType}`);
};

export default memo(CWChatInfo);
