import { adjust, curry, isEmpty, last } from 'ramda';
import { isToday } from 'date-fns';
import { format } from 'core/lib/date';
import { isNilOrEmpty } from '../../../../../../utils/utils';

function makeGroup(date, messagesList, options = {}) {
  return [format('dd.MM.yyyy', date), messagesList, options];
}

function makeTodayGroup(messagesList) {
  const options = { date: format('MM/dd/yyyy', new Date()) };

  return makeGroup(new Date(), messagesList, options);
}

function hasMessagesToday(messagesList) {
  if (!messagesList.length) return false;
  const lastGroup = last(messagesList);

  if (isNilOrEmpty(lastGroup)) return false;

  return isToday(new Date(lastGroup[2].date));
}

export const addUnsentMessages = curry((unsentMessagesList, messagesList) => {
  if (isEmpty(unsentMessagesList)) {
    return messagesList;
  }

  if (hasMessagesToday(messagesList)) {
    return adjust(messagesList.length - 1, addUnsent, messagesList);
  }

  return [...messagesList, makeTodayGroup(unsentMessagesList)];

  function addUnsent(arr) {
    return adjust(1, (msgsList) => msgsList.concat(unsentMessagesList), arr);
  }
});
