import { isType } from 'core/lib';
import { formatRequestNumber } from '../../../utils/utils';

const isBuyer = (chat, employeeId) => chat.ownerEmployeeId === employeeId;
const getRole = (chat, employeeId) =>
  isBuyer(chat, employeeId) ? 'buyer' : 'supplier';

const getTranslateKey = (chat, message, role) => {
  const path = `chat-widget.system-msg.${chat.type}.`;
  if (isType('MsgCreatedChannel', message)) return `${path}created`;
  if (isType('MsgAddedToChannel', message)) return `${path}added`;
  if (isType('MsgExcludedFromChannel', message)) return `${path}excluded`;
  if (isType('MsgLeftFromChannel', message)) return `${path}left`;
  if (isType('MsgRenamedChannel', message)) return `${path}renamed`;
  if (isType('MsgRegainedStatus', message)) return `${path}regained-status`;
  if (isType('MsgSubscribeToChannel', message)) return `${path}subscribe`;
  if (isType('MsgUpdateSubscribeToChannel', message))
    return `${path}update-subscribe`;
  if (isType('MsgUnsubscribeFromChannel', message)) return `${path}unsubscribe`;
  if (isType('MsgVideoRoomWasCreated', message))
    return `${path}video-room-was-created`;
  if (isType('MsgAcceptResponse', message) && role === 'buyer')
    return `${path}accept-response-buyer`;
  if (isType('MsgAcceptResponse', message) && role === 'supplier')
    return `${path}accept-response-supplier`;
  if (isType('MsgRejectResponse', message)) return `${path}reject-response`;
  if (isType('MsgSendToReworkResponse', message))
    return `${path}send-to-rework`;
  return '';
};

export default (t, chat, message, employeeId) => {
  if (isType('MsgAcceptResponse', message)) {
    return t(getTranslateKey(chat, message, getRole(chat, employeeId)), {
      requestNumber: formatRequestNumber(chat.requestId),
      interpolation: { escapeValue: false }
    });
  }
  return t(getTranslateKey(chat, message), {
    ...message,
    interpolation: { escapeValue: false }
  });
};
