import React from 'react';
import { isEmpty } from 'ramda';
import SimpleChip from '../../../chips/simple';
import '../style.css';

const Chips = ({ items, mode, onClick, onRemove }) => {
  if (mode === 'simple')
    return items.map((chip, index) => (
      <SimpleChip
        key={index}
        id={chip.id}
        value={chip.value}
        onClick={onClick}
        onRemove={onRemove}
      />
    ));

  throw new Error(`No such handler for mode = ${mode}`);
};

function Placeholder({ value }) {
  return (
    <div className="dynamic-border-chips-container__placeholder">{value}</div>
  );
}

function ChipContainer({
  items = [],
  placeholder,
  mode = 'simple',
  tabIndex = 0,
  onClick: clickCb,
  onClickByOption,
  onRemove,
  disabled
}) {
  const onClick = (event) => {
    if (disabled) return;

    if (typeof clickCb === 'function') {
      clickCb(event);
    }
  };

  return (
    <div
      className={`dynamic-border-chips-container${
        disabled ? ' dynamic-border-chips-container_disabled' : ''
      }`}
      tabIndex={tabIndex}
      onClick={onClick}>
      {isEmpty(items) ? (
        <Placeholder value={placeholder} />
      ) : (
        <Chips
          items={items}
          mode={mode}
          onClick={onClickByOption}
          onRemove={onRemove}
        />
      )}
    </div>
  );
}

export default ChipContainer;
