import React from 'react';

/* eslint-disable max-len */
function IconMessage() {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.7334 13.5H7.61536L7.50979 13.5528L4.2334 15.191V14V13.5H3.7334H1.7334C1.45726 13.5 1.2334 13.2761 1.2334 13V1C1.2334 0.723858 1.45726 0.5 1.7334 0.5H17.7334C18.0095 0.5 18.2334 0.723858 18.2334 1V13C18.2334 13.2761 18.0095 13.5 17.7334 13.5H7.7334Z"
        stroke="#767B92"
      />
      <line
        x1="6.2334"
        y1="5.5"
        x2="13.2334"
        y2="5.5"
        stroke="#767B92"
        strokeLinecap="round"
      />
      <line
        x1="6.2334"
        y1="8.5"
        x2="13.2334"
        y2="8.5"
        stroke="#767B92"
        strokeLinecap="round"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconMessage;
