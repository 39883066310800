import React from 'react';
import { Checkbox, Typography } from '@link/react-components';
import classes from './Row.module.css';

export function Row({
  label,
  afterLabel,
  value,
  actions,
  checked,
  indeterminate,
  onToggle,
  checkDisabled
}) {
  return (
    <div className={classes.row}>
      <div className={classes.content}>
        <Checkbox
          label={label}
          checked={checked}
          indeterminate={indeterminate}
          onChange={() => onToggle(value)}
          noWrap
          disabled={checkDisabled}
          size={16}
        />
        {Boolean(afterLabel) && (
          <Typography variant="body1Reg">{afterLabel}</Typography>
        )}
      </div>
      {Boolean(actions) && <div className={classes.actions}>{actions}</div>}
    </div>
  );
}
