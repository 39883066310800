import React from 'react';

const IconHelper = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="13" height="13" rx="6.5" stroke="#767B92" />
    <path
      d="M6.361 8.68405C6.361 8.22832 6.41634 7.86536 6.52702 7.59518C6.63769 7.325 6.83952 7.0597 7.13248 6.79928C7.42871 6.53561 7.62565 6.32239 7.7233 6.15963C7.82096 5.99362 7.86979 5.81946 7.86979 5.63717C7.86979 5.08704 7.61588 4.81198 7.10807 4.81198C6.86718 4.81198 6.6735 4.88685 6.52702 5.03659C6.38379 5.18307 6.30892 5.38652 6.30241 5.64694H4.88639C4.8929 5.02519 5.0931 4.53854 5.48698 4.18698C5.88411 3.83541 6.42448 3.65963 7.10807 3.65963C7.79817 3.65963 8.33366 3.82728 8.71452 4.16256C9.09538 4.49459 9.2858 4.96497 9.2858 5.5737C9.2858 5.85039 9.22396 6.11243 9.10026 6.35983C8.97656 6.60397 8.76009 6.87578 8.45084 7.17526L8.05534 7.55124C7.80794 7.78887 7.66634 8.06719 7.63053 8.3862L7.611 8.68405H6.361ZM6.2194 10.1831C6.2194 9.96497 6.29264 9.78594 6.43913 9.64596C6.58886 9.50273 6.77929 9.43112 7.01041 9.43112C7.24153 9.43112 7.43034 9.50273 7.57682 9.64596C7.72656 9.78594 7.80143 9.96497 7.80143 10.1831C7.80143 10.3979 7.72819 10.5753 7.5817 10.7153C7.43847 10.8553 7.24804 10.9253 7.01041 10.9253C6.77278 10.9253 6.58073 10.8553 6.43424 10.7153C6.29101 10.5753 6.2194 10.3979 6.2194 10.1831Z" // eslint-disable-line
      fill="#767B92"
    />
  </svg>
);

export default IconHelper;
