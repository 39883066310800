import { increateLoadCounter } from '../../../data-type/query';
import { logUseCase } from '../../../../../utils/logger';
import { extractFirstUnreadMsgFromChannel } from '../transformData';

const log = logUseCase.extend('chunkUploadChatsService');

export const chunkUploadChatsService = async (
  {
    reqGetChats,
    getChatsQuery,
    updateChatsQuery,
    addChats,
    addLastUnreadMsgs,
    transformToChatModels
  },
  { newParams = {}, withQueryUpdate = true }
) => {
  const chats = await reqGetChats(newParams);
  addChats(transformToChatModels(chats));

  const lastUnreadMsgs = chats
    .filter((chat) => chat.firstUnreadMessageId)
    .map(extractFirstUnreadMsgFromChannel);

  addLastUnreadMsgs(lastUnreadMsgs);

  log(`withQueryUpdate = ${withQueryUpdate}`);
  if (withQueryUpdate) {
    const query = newParams.query || getChatsQuery();
    updateChatsQuery(increateLoadCounter(query));
  }
};
