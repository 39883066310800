import React, { useEffect, useState } from 'react';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { getPage } from '../utils/managePages';
import { AnnotationLayer } from '../annotations/AnnotationLayer';
import { CanvasLayer } from './CanvasLayer';
import { TextLayer } from './TextLayer';
import { SvgLayer } from './SvgLayer';
import { useSpinner } from '../hooks/useSpinner';

export const PageLayer = ({
  doc,
  height,
  measureRef,
  pageIndex,
  renderPage,
  scale,
  shouldRender,
  width,
  onRenderCompleted
}) => {
  const isMounted = useIsMounted();
  const { renderSpinner } = useSpinner();
  const [pageSize, setPageSize] = useState({
    page: null,
    pageHeight: height,
    pageWidth: width
  });
  const [canvasLayerRendered, setCanvasLayerRendered] = useState(false);
  const [textLayerRendered, setTextLayerRendered] = useState(false);

  const { page, pageHeight, pageWidth } = pageSize;

  const scaledWidth = pageWidth * scale;
  const scaledHeight = pageHeight * scale;

  const isVertical = true;
  const w = isVertical ? scaledWidth : scaledHeight;
  const h = isVertical ? scaledHeight : scaledWidth;

  const determinePageSize = () => {
    getPage(doc, pageIndex).then((pdfPage) => {
      const viewport = pdfPage.getViewport({ scale: 1 });

      if (isMounted.current) {
        setPageSize({
          page: pdfPage,
          pageHeight: viewport.height,
          pageWidth: viewport.width
        });
      }
    });
  };

  const defaultPageRenderer = (props) => (
    <>
      {props.canvasLayer.children}
      {props.textLayer.children}
      {props.annotationLayer.children}
    </>
  );

  const renderPageLayer = renderPage || defaultPageRenderer;

  const handleRenderCanvasCompleted = () => {
    if (isMounted.current) {
      setCanvasLayerRendered(true);
    }
  };

  const handleRenderTextCompleted = () => {
    if (isMounted.current) {
      setTextLayerRendered(true);
    }
  };

  useEffect(() => {
    setPageSize({
      page: null,
      pageHeight: height,
      pageWidth: width
    });
    setCanvasLayerRendered(false);
    setTextLayerRendered(false);
  }, [scale]);

  useEffect(() => {
    if (shouldRender && isMounted.current && !page) {
      determinePageSize();
    }
  }, [shouldRender, page]);

  useEffect(() => {
    if (canvasLayerRendered && textLayerRendered) {
      onRenderCompleted(pageIndex);
    }
  }, [canvasLayerRendered, textLayerRendered]);

  return (
    <div
      className="pdf-viewer__page-layer"
      ref={measureRef}
      style={{ height: `${h}px`, width: `${w}px` }}>
      {!page ? (
        renderSpinner()
      ) : (
        <>
          {renderPageLayer({
            annotationLayer: {
              attrs: {},
              children: <AnnotationLayer />
            },
            canvasLayer: {
              attrs: {},
              children: (
                <CanvasLayer
                  page={page}
                  scale={scale}
                  height={h}
                  width={w}
                  onRenderCanvasCompleted={handleRenderCanvasCompleted}
                />
              )
            },
            textLayer: {
              attrs: {},
              children: (
                <TextLayer
                  page={page}
                  scale={scale}
                  onRenderTextCompleted={handleRenderTextCompleted}
                />
              )
            },
            svgLayer: {
              attrs: {},
              children: (
                <SvgLayer page={page} scale={scale} height={h} width={w} />
              )
            },
            canvasLayerRendered,
            textLayerRendered,
            doc,
            height: h,
            width: w,
            pageIndex,
            scale,
            markRendered: onRenderCompleted
          })}
        </>
      )}
    </div>
  );
};
