import React from 'react';

/* eslint-disable max-len */
const IconFolder = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.208 5L8.72068 2.4902C8.54062 2.18633 8.2136 2 7.86039 2H3C2.44772 2 2 2.44772 2 3V15C2 15.5523 2.44772 16 3 16H15C15.5523 16 16 15.5523 16 15V6C16 5.44771 15.5523 5 15 5H10.208ZM3 1C1.89543 1 1 1.89543 1 3V15C1 16.1046 1.89543 17 3 17H15C16.1046 17 17 16.1046 17 15V6C17 4.89543 16.1046 4 15 4H10.7778L9.58097 1.9804C9.22084 1.37267 8.56681 1 7.86039 1H3Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconFolder;
