import React from 'react';
import Row from './item';
import { generateChatVM, getChats, makeRowsVM } from './dataType';
import { useTranslate } from '../../../../../TranslateProvider';

const Rows = ({ chats, user, online, onClick }) => {
  const t = useTranslate();
  const vm = makeRowsVM({ t, chats, user, online, onClick });

  return getChats(vm).map((chat) => (
    <Row key={chat.id} vm={generateChatVM(vm, chat)} />
  ));
};

export default Rows;
