import React from 'react';

/* eslint-disable max-len */
function IconShare() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19C13.1046 19 14 18.1046 14 17C14 15.8954 13.1046 15 12 15C10.8954 15 10 15.8954 10 17C10 18.1046 10.8954 19 12 19ZM12 20C13.6569 20 15 18.6569 15 17C15 15.3431 13.6569 14 12 14C10.3431 14 9 15.3431 9 17C9 18.6569 10.3431 20 12 20Z"
        fill="#252B49"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 13C23.1046 13 24 12.1046 24 11C24 9.89543 23.1046 9 22 9C20.8954 9 20 9.89543 20 11C20 12.1046 20.8954 13 22 13ZM22 14C23.6569 14 25 12.6569 25 11C25 9.34315 23.6569 8 22 8C20.3431 8 19 9.34315 19 11C19 12.6569 20.3431 14 22 14Z"
        fill="#252B49"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 25C23.1046 25 24 24.1046 24 23C24 21.8954 23.1046 21 22 21C20.8954 21 20 21.8954 20 23C20 24.1046 20.8954 25 22 25ZM22 26C23.6569 26 25 24.6569 25 23C25 21.3431 23.6569 20 22 20C20.3431 20 19 21.3431 19 23C19 24.6569 20.3431 26 22 26Z"
        fill="#252B49"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7428 19.0629L20.2428 21.5629L19.7572 22.4371L15.2572 19.9371L15.7428 19.0629Z"
        fill="#252B49"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7773 13.416L14.2774 16.416L13.7227 15.584L18.2226 12.584L18.7773 13.416Z"
        fill="#252B49"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconShare;
