import React from 'react';

const ForwardedTitle = ({ t, show, name }) => {
  if (!show || !name) return null;

  return (
    <div className="cwm-forward-message__msg-from-name">
      <img src="/img/forward.svg" height={12} width={12} alt="icon-forward" />
      <div className="cwm-forward-message__inner-msg-from-name">{`${t(
        'forwarded_from'
      )} ${name}`}</div>
    </div>
  );
};

export default React.memo(ForwardedTitle);
