import React, { useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { PdfJsApi } from '../vendors/PdfJsApi';

export const SvgLayer = ({ page, scale, width, height }) => {
  const containerRef = useRef();

  const empty = () => {
    const containerElem = containerRef.current;

    if (!containerElem) {
      return;
    }
    containerElem.innerHTML = '';
  };

  useIsomorphicLayoutEffect(() => {
    const containerElem = containerRef.current;
    const viewport = page.getViewport({ scale });

    page.getOperatorList().then((operatorList) => {
      empty();
      const graphic = new PdfJsApi.SVGGraphics(page.commonObjs, page.objs);
      graphic.getSVG(operatorList, viewport).then((svg) => {
        svg.style.height = `${height}px`;
        svg.style.width = `${width}px`;

        containerElem.appendChild(svg);
      });
    });
  }, []);

  return <div className="pdf-viewer__svg-layer" ref={containerRef} />;
};
