const { defStruct } = require('../../lib');

/**
 * @typedef SortBy
 */
const { makeSortBy, getField, getDirection, isSortBy } = defStruct('SortBy', [
  'field',
  'direction'
]);

/**
 * Creates a sortBy data type
 *
 * @param {object} params
 * @param {string} params.field
 * @param {string} params.direction
 * @returns {SortBy}
 */
exports.makeSortBy = ({ field, direction } = {}) =>
  makeSortBy(field, direction);

/**
 * Get sortBy field value
 *
 * @param {SortBy} sortBy
 * @returns {string}
 */
exports.getField = (sortBy) => getField(sortBy);

/**
 * Set sortBy field value
 *
 * @param {string} field
 * @param {SortBy} sortBy
 * @returns {SortBy}
 */
exports.setField = (field, sortBy) => makeSortBy(field, getDirection(sortBy));

/**
 * Get sortBy direction value
 *
 * @param {SortBy} sortBy
 * @returns {string}
 */
exports.getDirection = (sortBy) => getDirection(sortBy);

/**
 * Set sortBy direction value
 *
 * @param {string} direction
 * @param {SortBy} sortBy
 * @returns {SortBy}
 */
exports.setDirection = (direction, sortBy) =>
  makeSortBy(getField(sortBy), direction);

/**
 * Check is sortBy
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isSortBy = (a) => isSortBy(a);

/**
 * Check is direction DESC
 *
 * @param {SortBy} sortBy
 * @returns {boolean}
 */
exports.isDescDirection = (sortBy) => getDirection(sortBy) === 'DESC';

/**
 * Check is column sort
 *
 * @param {string} columnName
 * @param {SortBy} sortBy
 * @returns {boolean}
 */
exports.isSortColumn = (columnName, sortBy) => getField(sortBy) === columnName;

/**
 * Toggle direction
 *
 * @param {SortBy} sortBy
 * @returns {string}
 */
exports.toggleDirection = (sortBy) =>
  getDirection(sortBy) === 'DESC' ? 'ASC' : 'DESC';
