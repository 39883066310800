import request from '../../lib/request';

export const open = async (
  requestId,
  responseId,
  respondedCompanyId,
  respondedUserId
) =>
  request.post(`/api/response-chat/open`, {
    requestId,
    responseId,
    respondedCompanyId,
    respondedUserId
  });
export const addContact = async (responseId) =>
  request.get(`/api/response-chat/${responseId}/contact-add`);

export const declineConfirm = async ({ responseId, confirmType }) =>
  request.get(
    `/api/response-chat/${responseId}/decline-confirm/${confirmType}`
  );
