import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';
import ButtonIcon from 'react-components/src/components/ButtonIcon';
import Icons from 'react-components/src/icons/constants';
import * as modalActions from '../../../action-creators/modal';
import OldButtonIcon from '../../ChatWidget/ButtonIcon';
import { getUserLang } from '../../ChatWidget/getter';
import './ModalPDFViewer.css';

const formatDate = (language, date) => {
  if (!date) return '';
  const formattedDate = format(new Date(date), 'dd.M.yyyy');
  const formattedTime = format(new Date(date), 'HH:mm');
  const unionWord = language === 'ru' ? 'в' : 'at';
  return `${formattedDate} ${unionWord} ${formattedTime}`;
};

const PDFViewerFooter = ({
  t,
  isShowAddToStorage,
  isDeleteEnabled,
  isDownloadEnabled,
  name,
  senderName,
  date,
  language,
  onCopyToStorage,
  onDownload,
  onDelete,
  print,
  zoomIn,
  zoomOut,
  zoomPageFit,
  handleResend
}) => (
  <div className="pdf-viewer-footer__wrapper">
    <div className="pdf-viewer-footer__container">
      <div className="pdf-viewer-footer__container-left">
        <div className="pdf-viewer-footer__name">{name}</div>
        <div className="pdf-viewer-footer__description">{`${senderName} ${formatDate(
          language,
          date
        )}`}</div>
      </div>
      <div className="pdf-viewer-footer__container-middle">
        <div className="pdf-viewer-footer__zoom-control">
          <OldButtonIcon
            onClick={zoomOut}
            icon="minus-s"
            tooltip="zoom-out"
            color="white"
          />
          <OldButtonIcon
            onClick={zoomIn}
            icon="plus-s"
            tooltip="zoom-in"
            color="white"
          />
          <OldButtonIcon
            onClick={zoomPageFit}
            icon="fit-in-s"
            tooltip="fit-in"
            color="white"
          />
        </div>
      </div>
      <div className="pdf-viewer-footer__container-right">
        <OldButtonIcon
          onClick={print}
          icon="print-18"
          tooltip="btn-print"
          color="white"
        />
        {isShowAddToStorage && onCopyToStorage && (
          <OldButtonIcon
            disabled={!isDownloadEnabled}
            onClick={onCopyToStorage}
            icon="save-file"
            tooltip="add_to_storage"
            color="white"
          />
        )}
        <ButtonIcon
          t={t}
          onClick={handleResend}
          icon={Icons.Forward}
          size={34}
          tooltip="reply"
          color="white"
        />
        {isDownloadEnabled && (
          <OldButtonIcon
            disabled={!isDownloadEnabled}
            onClick={onDownload}
            icon="download"
            tooltip="download"
            color="white"
          />
        )}
        {isDeleteEnabled && (
          <OldButtonIcon
            disabled={!isDeleteEnabled}
            onClick={onDelete}
            icon="remove-1"
            tooltip="delete"
            color="white"
          />
        )}
      </div>
    </div>
  </div>
);

PDFViewerFooter.propTypes = {
  language: PropTypes.string,
  isShowAddToStorage: PropTypes.bool,
  isDeleteEnabled: PropTypes.bool.isRequired,
  isDownloadEnabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  senderName: PropTypes.string,
  date: PropTypes.string,
  onDownload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCopyToStorage: PropTypes.func.isRequired
};

PDFViewerFooter.defaultProps = {
  isShowAddToStorage: false,
  isDownloadEnabled: true,
  senderName: '',
  date: '',
  language: 'en'
};

const mapState = (state) => ({
  language: getUserLang(state)
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalActions.hideModalDialog
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(PDFViewerFooter);
