import React from 'react';
import classNames from 'classnames';
import AcceptRoundIcon from '../../../icons/accept-round';
import './style.css';

const isSelected = (id, list) => list.includes(id);

const ItemCard = ({ item, selectedIds, onChoose, level }) => (
  <div
    className={classNames('select-item-modal-card', {
      selected: isSelected(item.value, selectedIds)
    })}
    onClick={onChoose(item.value)}>
    <div style={{ minWidth: level * 28 }} />
    <div className="select-item-modal-name">{item.label}</div>
    <div className="select-item-modal-accept">
      <AcceptRoundIcon />
    </div>
  </div>
);

export default ItemCard;
