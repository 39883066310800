const { defStruct } = require('../../lib');
const { makePaginationQuery } = require('../paginationQuery');
const { makeWhereParams } = require('../whereParams');
const { makeSortBy } = require('../sortBy');

/**
 * @typedef RequestMany
 * @typedef {import('../paginationQuery')} PaginationQuery
 * @typedef {import('../whereParams')} WhereParams
 * @typedef {import('../sortBy')} SortBy
 */
const {
  makeRequestMany,
  getPaginationQuery,
  getWhereParams,
  getSortBy,
  isRequestMany
} = defStruct('RequestMany', ['paginationQuery', 'whereParams', 'sortBy']);

/**
 * Creates a request many data type
 *
 * @param {object} params
 * @param {PaginationQuery} params.paginationQuery
 * @param {WhereParams} params.whereParams
 * @param {SortBy} params.sortBy
 * @returns {RequestMany}
 */
exports.makeRequestMany = ({
  paginationQuery = makePaginationQuery(),
  whereParams = makeWhereParams(),
  sortBy = makeSortBy()
} = {}) => makeRequestMany(paginationQuery, whereParams, sortBy);

/**
 * Gives pagination query data type from request many
 *
 * @param {RequestMany} requestMany
 * @returns {PaginationQuery}
 */
exports.getPaginationQuery = (requestMany) => getPaginationQuery(requestMany);

/**
 * Gives where params data type from request many
 *
 * @param {RequestMany} requestMany
 * @returns {WhereParams}
 */
exports.getWhereParams = (requestMany) => getWhereParams(requestMany);

/**
 * Get sortBy data type from request many
 *
 * @param {RequestMany} requestMany
 * @returns {SortBy}
 */
exports.getSortBy = (requestMany) => getSortBy(requestMany);

/**
 * Checks if is request many
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isRequestMany = (a) => isRequestMany(a);

/**
 * Sets the where params into request many
 *
 * @param {WhereParams} whereParams
 * @param {RequestMany} requestMany
 * @returns {RequestMany}
 */
exports.setWhereParams = (whereParams, requestMany) =>
  makeRequestMany(
    getPaginationQuery(requestMany),
    whereParams,
    getSortBy(requestMany)
  );

/**
 * Sets the pagination query into request many
 *
 * @param {PaginationQuery} paginationQuery
 * @param {RequestMany} requestMany
 * @returns {RequestMany}
 */
exports.setPaginationQuery = (paginationQuery, requestMany) =>
  makeRequestMany(
    paginationQuery,
    getWhereParams(requestMany),
    getSortBy(requestMany)
  );
