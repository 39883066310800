import React from 'react';
import Loader from '../../../components/Loader';

// TODO Реализовать Loader

export default () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%'
    }}>
    <Loader />
  </div>
);
