import React from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '@hooks';
import CWSTabs from 'react-components/src/components/ChatWidget/components/Tabs';
import { getActiveTab, getChatUnreadMsgsCount } from '../../getter';
import { changeTab } from './wrapper';

const Tabs = ({ config }) => {
  const activeTab = useSelector(getActiveTab);
  const chatUnreadMsgsCount = useSelector(getChatUnreadMsgsCount);
  const handler = useActions(changeTab);

  return (
    <CWSTabs config={config} {...{ activeTab, chatUnreadMsgsCount, handler }} />
  );
};

export default Tabs;
