import request from '../lib/request';
import { CreateGroupDto, UpdateGroupDto } from '../DTOs';

// TODO: статусы ошибок можно найти тут legacy/backend/src/lib/validation/errorCodes.js

// Examples errors
// name includes incorrect symbol (checked by regex)
// {
//   "message": {
//     "name": "VALIDATION_ERROR",
//     "details": [
//     {
//       "type": "INCORRECT_VALUE",
//       "field": "name"
//     }
//   ]
// }

// name required, but it is empty
// {
//   "message": {
//     "name": "VALIDATION_ERROR",
//     "details": [
//     {
//       "type": "STRING_EMPTY",
//       "field": "name"
//     }
//   ]
// }

// name must be shorter than 100 symbols, but it is larger
// {
//   "message": {
//     "name": "Validation Error",
//     "details": [
//     {
//       "type": "STRING_MAX",
//       "field": "name",
//       "constraint": 100
//     }
//   ]
// }

class CompaniesGroupService {
  #baseUrl = '/api/group-companies';

  async getAll() {
    return request.post('/api/select-options/search/all-companies', {
      includesGroups: true
    });
  }

  async create(data) {
    const createGroupDto = new CreateGroupDto(data);
    return request.post(`${this.#baseUrl}/create`, createGroupDto);
  }

  async update(data) {
    const updateGroupDto = new UpdateGroupDto(data);
    return request.patch(
      `${this.#baseUrl}/${updateGroupDto.id}/update`,
      updateGroupDto
    );
  }

  async delete(groupId) {
    return request.delete(`${this.#baseUrl}/${groupId}/delete`);
  }
}

export const companiesGroupService = new CompaniesGroupService();
