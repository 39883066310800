import React from 'react';

const Danger = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18">
    <use fill="#FFF" xlinkHref="#a" />
    <use fill="#FD4F53" transform="translate(6 6)" xlinkHref="#b" />
    <defs>
      <path id="a" d="M18 9A9 9 0 1 1 0 9a9 9 0 0 1 18 0z" />
      <path
        id="b"
        d="M.354-.354a.5.5 0 1 0-.708.708l.708-.708zm5.292 6.708a.5.5 0 1 0 .708-.708l-.708.708zm.708-6a.5.5 0 1 0-.708-.708l.708.708zM-.354 5.646a.5.5 0 1 0 .708.708l-.708-.708zm0-5.292l6 6 .708-.708-6-6-.708.708zm6-.708l-6 6 .708.708 6-6-.708-.708z" // eslint-disable-line
      />
    </defs>
  </svg>
);

export default Danger;
