import React, { useLayoutEffect, useRef, useState } from 'react';
import { getPage } from '../utils/managePages';
import { ScrollMods } from '../constants';
import { decrease } from '../plugins/zoom/zoomingLevels';
import { calculateScale } from './CalculateScale';
import { useSpinner } from '../hooks/useSpinner';

const RESERVE_HEIGHT = 45;
const RESERVE_WIDTH = 45;

export const PageSizeCalculator = ({
  defaultScale,
  doc,
  scrollMode,
  render
}) => {
  const pagesRef = useRef();
  const [pageSize, setPageSize] = useState({
    pageHeight: 0,
    pageWidth: 0,
    scale: 1
  });
  const { renderSpinner } = useSpinner();

  useLayoutEffect(() => {
    getPage(doc, 0).then((pdfPage) => {
      const viewport = pdfPage.getViewport({ scale: 1 });
      const { width, height } = viewport;

      const pagesEle = pagesRef.current;
      if (!pagesEle) {
        return;
      }

      const parentEle = pagesEle.parentElement;

      const scaleWidth = (parentEle.clientWidth - RESERVE_WIDTH) / width;
      const scaleHeight = (parentEle.clientWidth - RESERVE_HEIGHT) / height;

      let scaled = scaleWidth;
      switch (scrollMode) {
        case ScrollMods.Horizontal:
          scaled = Math.min(scaleWidth, scaleHeight);
          break;
        case ScrollMods.Vertical:
        default:
          scaled = scaleWidth;
      }

      let scale;
      if (defaultScale) {
        scale =
          typeof defaultScale === 'string'
            ? calculateScale(parentEle, height, width, defaultScale)
            : defaultScale;
      } else {
        scale = decrease(scaled);
      }

      setPageSize({
        pageHeight: height,
        pageWidth: width,
        scale
      });
    });
  }, [doc]);

  const { pageWidth } = pageSize;

  return pageWidth === 0 ? (
    <div className="pdf-viewer__page-size-calculator" ref={pagesRef}>
      {renderSpinner()}
    </div>
  ) : (
    render(pageSize)
  );
};
