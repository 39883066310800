import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from '../../../../hoc/withRouter';
import {
  makeCategoriesVM,
  isCategoriesHidden,
  getList,
  isHeaderDisabled,
  getHeaderTitle,
  isCheckedAll,
  initSetCheckedAll,
  initOnAddList,
  getCloseSidebarHandler
} from './viewModel';
import Header from './header';
import List from './list';
import * as chatWidgetUC from '../../useCases';
import useSelect from '../../hooks/useSelect';
import { getGroupChats } from './chats/getters';
import { getGroupContacts } from './contacts/getters';
import { isWidget } from '../../../../lib/goodwix';
import { useTranslate } from '../../../../TranslateProvider';
import { useActions } from '../../../../hooks/useActions';
import './style.css';

export const wrapper = (t) => {
  const { selected, onSelect } = useSelect([]);
  const groupChats = useSelector(getGroupChats);
  const groupContacts = useSelector(getGroupContacts);
  const actions = useActions(chatWidgetUC);
  return makeCategoriesVM({
    t,
    groupContacts,
    groupChats,
    selected,
    onSelect,
    actions
  });
};

const ChatWidgetSidebarAuxiliary = ({
  history: {
    location: { pathname }
  }
}) => {
  const t = useTranslate();
  const viewModel = wrapper(t);
  if (isCategoriesHidden(viewModel)) return null;

  const handleClose = () => getCloseSidebarHandler(viewModel)('folder');

  return (
    <div
      className={`chat-widget-sidebar-auxiliary chat-widget-sidebar-auxiliary_${
        isCategoriesHidden(viewModel) ? 'hidden' : 'shown'
      } ${
        isWidget(pathname) ? ' chat-widget-sidebar-auxiliary_inside-widget' : ''
      }`}>
      <Header
        title={getHeaderTitle(viewModel)}
        isCheckedAll={isCheckedAll(viewModel)}
        disabled={isHeaderDisabled(viewModel)}
        setCheckedAll={initSetCheckedAll(viewModel)}
        onAddList={initOnAddList(viewModel)}
        onClose={handleClose}
      />
      <List group={getList(viewModel)} />
    </div>
  );
};

export default withRouter(ChatWidgetSidebarAuxiliary);
