import { toggleMessengerCategoryCase } from './useCases/toggleCategoryGroup';
import { getGroupChats } from './chats/getters';
import { getGroupContacts } from './contacts/getters';
import { applyState } from '../utils';
import { hideGroupChat, showGroupChat } from './chats/actions';
import { hideGroupContact, showGroupContact } from './contacts/actions';
import * as storage from '../contacts/storage';
// eslint-disable-next-line import/no-cycle
import { setCountsForGroupContacts } from '../contacts';
import { isShown } from '../../data-type/block-group';

export const getGroup = (getState, name) => {
  const groupChats = applyState(getState, getGroupChats);
  const groupContacts = applyState(getState, getGroupContacts);
  if (name === 'chat' || name === 'chat-archive') return groupChats;
  if (name === 'contact') return groupContacts;
  throw new Error('no handler');
};

const showGroup = (kind, name) => (dispatch) => {
  if (name === 'chat' || name === 'chat-archive')
    return dispatch(showGroupChat(kind));
  if (name === 'contact') return dispatch(showGroupContact(kind));
  throw new Error('no actions');
};

export const hideGroup = (kind) => (dispatch, getState) => {
  const groupChats = applyState(getState, getGroupChats);
  const groupContacts = applyState(getState, getGroupContacts);
  if (isShown(groupChats())) return dispatch(hideGroupChat(kind));
  if (isShown(groupContacts())) return dispatch(hideGroupContact(kind));
  return null;
};

const updateGroup = (name) => async (dispatch, getState) => {
  if (name === 'chat' || name === 'chat-archive') {
    return null;
  }
  if (name === 'contact') {
    const contacts = await storage.reqGetContacts(getState(), {});
    return setCountsForGroupContacts(contacts.membersCount);
  }
  throw new Error('no handler');
};

export const toggleMessengerCategories = (name) => async (dispatch, getState) =>
  toggleMessengerCategoryCase(
    {
      getGroup: getGroup(getState, name),
      showGroup: (kind) => dispatch(showGroup(kind, name)),
      hideGroup: () => dispatch(hideGroup()),
      updateGroup: async () => dispatch(updateGroup(name))
    },
    { name }
  );
