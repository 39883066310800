import React, { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import ChipContainer from '../chip-container';
import FileSelectWrapper from '../../../wrappers/file-select';
import '../style.css';

const transformToView = (inputValue) =>
  inputValue.map(({ id, name }) => ({ id, value: name }));

function convertFiles(rawFiles) {
  const files = [];
  for (let i = 0; i < rawFiles.length; i++) {
    files.push({ file: rawFiles[i] });
  }
  return files;
}

function DynamicBorderFileInput({
  value: inputValue = [],
  placeholder,
  onAttach: attachCb,
  onRemove: removeCb,
  onClickByOption: clickByOptionCb,
  accept = '',
  multiply,
  disabled
}) {
  const [files, setFiles] = useState(transformToView(inputValue));

  useUpdateEffect(() => {
    setFiles(transformToView(inputValue));
  }, [inputValue]);

  const onRemove = (event, id) => {
    const updatedFiles = files.filter((file) => file.id !== id);

    setFiles(updatedFiles);

    if (typeof removeCb === 'function') {
      removeCb(event, id);
    }
  };

  const onAttach = (data) => {
    if (typeof attachCb === 'function') {
      attachCb(convertFiles(data));
    }
  };
  const onClickByOption = (event, id) => {
    event.stopPropagation();

    if (typeof clickByOptionCb === 'function') {
      clickByOptionCb(event, id);
    }
  };

  return (
    <FileSelectWrapper
      component={(props) => (
        <ChipContainer
          {...props}
          items={files}
          mode="simple"
          placeholder={placeholder}
          onClickByOption={onClickByOption}
          onRemove={onRemove}
          disabled={disabled}
        />
      )}
      multiple={multiply}
      accept={accept}
      disabled={disabled}
      onChange={onAttach}
      className="dynamic-border-file-input"
    />
  );
}

export default DynamicBorderFileInput;
