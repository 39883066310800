import { Tabs as MUITabs, Tab as MUITab } from '@mui/material';
import { useState, SyntheticEvent } from 'react';
import { Typography } from '@/components/Typography';
import cn from 'classnames';
import styles from './Tabs.module.scss';
import { TabsProps, TabProps } from './Tabs.types';

export function Tabs({ tabContent, className = '' }: TabsProps) {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <div className={cn(className, styles.container)} data-testid="tabs">
      <MUITabs
        value={currentTab}
        onChange={handleChange}
        className={styles.tabs}>
        {tabContent.map((tab: TabProps, index: number) => (
          <MUITab
            key={tab.title}
            label={
              <Typography variant="body1Med" className={styles.title}>
                {tab.title}
              </Typography>
            }
            value={index}
            disabled={tab?.disabled}
            disableRipple
          />
        ))}
        <span className={styles.delimiter} />
      </MUITabs>
      {tabContent[currentTab].content}
    </div>
  );
}
