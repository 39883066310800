import { show, hide } from './redux-storage';
import { regGetRequest } from '../../../../action-creators/purchaseRequests';

export const openCompany =
  ({ requestId, companyId }) =>
  async (dispatch) => {
    let id;

    if (companyId) {
      id = companyId;
    } else {
      const request = await regGetRequest(requestId);
      id = request.companyId;
    }

    dispatch(show({ companyId: id }));
  };

export const closeCompany = () => (dispatch) => {
  dispatch(hide());
};
