import React from 'react';
import { useSelector } from 'react-redux';
import { getTextMessage } from '../messages';

import './style.css';

import * as useCase from '../../useCases';
import Avatar from '../../Avatar';
import { getOpenedChat, getReplyMessageByChat } from '../../getter';
import CreatedRoomMsg from '../chat-messages/components/Message/CreatedRoomMessage';
import { useTranslate } from '../../../../TranslateProvider';
import { useActions } from '../../../../hooks/useActions';

const CWChatReplyPanel = () => {
  const t = useTranslate();

  const chat = useSelector(getOpenedChat);
  const message = useSelector(getReplyMessageByChat);

  const { handleReplyMsgPanel } = useActions(useCase);

  if (!message) return null;
  const { userName, avatarSrc } = message;
  const haveFiles = message.files && message.files.length > 0;

  const renderMsg = () => {
    if (message.type === 'MsgVideoRoomWasCreated') {
      return <CreatedRoomMsg message={message} chat={chat} />;
    }
    if (
      message.type === 'MsgForwarded' &&
      message.originMsgType === 'MsgVideoRoomWasCreated'
    ) {
      return <CreatedRoomMsg message={message.originMsgData} chat={chat} />;
    }

    const msg = getTextMessage({
      t,
      message,
      chat,
      handler: handleReplyMsgPanel
    });
    return msg.msg;
  };

  return (
    <div className="reply-msgs">
      <div className="reply-msgs_img">
        <img src="/img/forward.svg" height={22} width={22} alt="icon-attach" />
      </div>
      <div className="reply-msgs_wrap">
        <div className="reply-msgs_info text-wrap">
          <div className="reply-msgs_avatar">
            <Avatar
              size={34}
              info={{
                type: 'user',
                src: avatarSrc,
                alt: userName,
                employeeId: message.employeeId,
                hideOnlineStatus: true
              }}
            />
          </div>
          <div className="text-wrap">
            <div className="reply-msgs_username">{userName}</div>
            <div className="text-wrap">
              {haveFiles && (
                <img
                  src="/img/chat-widget/attach.svg"
                  className="reply-msgs_attach"
                  role="presentation"
                  alt=""
                  onClick={() => handleReplyMsgPanel('preview')}
                />
              )}
              {renderMsg()}
            </div>
          </div>
          <div
            className="reply-msgs_close"
            onClick={() => handleReplyMsgPanel('cancel', message.id)}>
            <img src="/img/cross.svg" width="10" role="presentation" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CWChatReplyPanel;
