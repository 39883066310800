const { makeCommand, makeEvent } = require('core/data/messageBus/message');
const { makeQuery } = require('core/messages/utils');

const NS = 'VIDEO_ROOM_PEER';

const addedMemberEVT = makeEvent(NS, 'MEMBER_WAS_ADDED');
const removedMemberEVT = makeEvent(NS, 'MEMBER_WAS_REMOVED');

const viewCMD = makeCommand(NS, 'view');
const videoRoomPeerQRY = makeQuery(NS, 'video-room-peer');

module.exports = {
  viewCMD,
  videoRoomPeerQRY,
  addedMemberEVT,
  removedMemberEVT
};
