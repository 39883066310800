import taskMSG from 'core/messages/task';
import request from '../lib/request';
import { actions as taskActions } from './reducers/task';
import { emitToServer } from '../storage/message';

export const createNewTag = (title) => async (dispatch) => {
  const tag = await request.post('/api/tags/create', { title });
  dispatch(taskActions.addTagToList({ tag }));
  return tag;
};

export const updateTag = (data) => async (dispatch) => {
  const tag = await request.patch(`/api/tags/${data.value}/update`, {
    tag: data
  });
  dispatch(taskActions.changeTag({ tag }));
  return tag;
};

export const getAllUserTags = () => async (dispatch) => {
  const list = await request.get('/api/tags/current-user/all');
  dispatch(taskActions.setTagList({ list }));
  return list;
};

export const getPossibleExecutors = () => async (dispatch) => {
  const list = await request.get('/api/tasks/executors-list');
  dispatch(taskActions.setExecutorsList({ list }));
  return list;
};

export const createNewTask = (data) => async () => {
  const task = await request.post('/api/tasks/create', data);
  return task;
};

export const getAllCompanyTasks = () => async (dispatch) => {
  const list = await request.get('/api/tasks/current-company/all');
  dispatch(taskActions.setTaskList({ list }));
  return list;
};

export const loadCompanyTasksChunk =
  ({ limit, offset, sections }) =>
  async (dispatch) => {
    const chunk = await request.post('/api/tasks/current-company/load-chunk', {
      limit,
      offset,
      sections
    });
    dispatch(taskActions.uploadChunk(chunk));
  };

export const collapseCompanyTasksSections = (sections) => async (dispatch) => {
  const chunk = await request.post('/api/tasks/current-company/load-chunk', {
    limit: 10,
    offset: 0,
    sections
  });
  dispatch(taskActions.reloadTaskSection(chunk));
};

export const moveTask =
  ({ id, position, fromStatus, atStatus }) =>
  async (dispatch) => {
    await request.patch(`/api/tasks/${id}/move`, {
      id,
      position,
      status: atStatus
    });
    dispatch(taskActions.moveTask({ id, position, fromStatus, atStatus }));
  };

export const editTask =
  ({ id, data }) =>
  async (dispatch) => {
    const task = await request.patch(`/api/tasks/${id}/edit`, data);
    dispatch(taskActions.editTask({ task }));
  };

export const removeTask =
  ({ id, status }) =>
  async (dispatch) => {
    await request.delete(`/api/tasks/${id}/delete`);
    dispatch(taskActions.removeTask({ id, status }));
  };

export const setTaskAsViewed =
  ({ taskId }) =>
  async () => {
    emitToServer(taskMSG.setTaskAsViewedCMD, { taskId });
  };
