import React from 'react';
import './style.css';

const RoundSmallButton = ({
  onClick = () => {},
  isActive,
  svg,
  disabled = false,
  ...otherProps
}) => (
  <button
    type="button"
    onClick={!disabled ? onClick : undefined}
    className={`round-small-button ${
      isActive ? 'round-small-button-active' : ''
    } ${disabled ? 'round-small-button-disabled' : ''}`}
    {...otherProps}>
    {svg}
  </button>
);

export default RoundSmallButton;
