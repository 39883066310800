import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalTopic from './Topic/Base';

import { getTempField } from '../../storeGetters';
import * as topicUC from '../../modules/topic/useCases';

const ModalCreateTopicChat = ({
  createTopic,
  attachFiles,
  removeFile,
  uploadtopicUnsaveInfoFiles,
  clearTopicEditInfo,
  files,
  cancel,
  storage
}) => (
  <ModalTopic
    title="create_topic_chat"
    textBtnSubmit="Next"
    files={files}
    onSubmit={createTopic}
    onCancel={cancel}
    attachFiles={attachFiles}
    removeFile={removeFile}
    initUpload={uploadtopicUnsaveInfoFiles}
    clear={clearTopicEditInfo}
    storage={storage}
  />
);

ModalCreateTopicChat.propTypes = {
  createTopic: PropTypes.func.isRequired,
  attachFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  uploadtopicUnsaveInfoFiles: PropTypes.func.isRequired,
  clearTopicEditInfo: PropTypes.func.isRequired,
  storage: PropTypes.object.isRequired
};

export default connect(
  (state) => ({
    storage: {
      url: '/file-storage/chat/topic/attach/_',
      operation: 'create'
    },
    files: getTempField(state, 'filesToAdd', [])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        createTopic: topicUC.createTopic,
        uploadtopicUnsaveInfoFiles: topicUC.uploadTopicUnsaveInfoFiles,
        clearTopicEditInfo: topicUC.clearTopicEditInfo,
        attachFiles: topicUC.filesLoadHandlerTopicInfo,
        removeFile: topicUC.removeFile,
        cancel: topicUC.cancelEditTopic
      },
      dispatch
    )
)(ModalCreateTopicChat);
