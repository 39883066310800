import React from 'react';
import { connect } from 'react-redux';
import Text from 'react-components/src/components/Typography';
import { getDraftMessage } from '../../../../../modules/draftMessages/getters';
import AttachFilesInfo from '../attach-files-info';
import { useTranslate } from '../../../../../../../TranslateProvider';

const DraftInfo = ({ draft }) => {
  const t = useTranslate();

  return (
    <div className="cws-chat-last-message cws-chat-last-message-draft">
      <Text
        className="cws-chat-last-message-draft__title"
        level={2}
        lineHeight={2}
        weight={2}>
        {`${t('chat-widget.chat.draft')}: `}
      </Text>
      <AttachFilesInfo files={draft.files} />
      <Text
        className="cws-chat-last-message__message cws-chat-last-message-draft__text cws-chat-last-message__message_user"
        level={2}
        lineHeight={2}
        weight={3}>
        {draft.text}
      </Text>
    </div>
  );
};

export default connect((state, props) => ({
  draft: getDraftMessage(state, props.chatId)
}))(DraftInfo);
