import React from 'react';
import { isEmpty, isNil } from 'ramda';
import classNames from 'classnames';
import { useNavigate } from '@hooks';
import { openChatAtMsg } from '../../../useCases';
import Avatar from './components/Avatar';
import ChatInfo from './components/Info';
import LastMessageDate from './components/LastMessageDate';
import TogglePinBtn from './components/Buttons/TogglePin';
import Badges from './components/Badges';
import MessageInfo from '../../../sidebar/list/chat-list-item/item/message-info';
import getContextMenu from '../../../sidebar/list/chat-list-item/item/context-menu';
import * as chatTypes from '../../../helpers/chatType';
import './style.css';

const useChatConfig = ({ chat, currentUser, commonConfig }) => {
  const { extraMenu } = commonConfig;
  const { type } = extraMenu;

  const createExtraList = () => {
    if (type === 'widget')
      return [
        {
          conditionName: 'pinned?',
          items: [
            { icon: 'pin', value: 'pin' },
            { icon: 'unpin', value: 'unpin' }
          ]
        }
      ];
    if (type === 'chats' || type === 'archiveChats') {
      return getContextMenu(chat, currentUser);
    }
    return [];
  };

  const createExtraConditions = () => {
    const { pinned, notificationOff } = chat;

    if (type === 'widget')
      return {
        'pinned?': pinned ? 'unpin' : 'pin'
      };
    if (type === 'chats' || type === 'archive') {
      return {
        'notice-on?': notificationOff ? 'notice-on' : 'notice-off',
        'pinned?': pinned ? 'unpin' : 'pin'
      };
    }
    return {};
  };

  return React.useMemo(
    () => ({
      extra: {
        ...extraMenu,
        list: createExtraList(),
        conditions: createExtraConditions()
      }
    }),
    [chat?.pinned, chat?.notificationOff, chat?.archived, commonConfig]
  );
};

const Chat = ({
  active,
  currentUser,
  interlocutor,
  chatColor,
  chatName,
  chatType,
  chat,
  amountOnline,
  chatNotificationOff,
  members,
  isNotAvailable,
  config: commonConfig,
  handler,
  replaceOpenChat = false,
  employeeId,
  typingList,
  lastMessage,
  containsUnreadMsg,
  channelMember,
  chatId,
  amountUnreadMsg,
  hasMention,
  pinned,
  language,
  showPinButton
}) => {
  const history = useNavigate();

  const config = useChatConfig({ chat, currentUser, commonConfig });

  const { extra } = config;
  const { type } = extra;

  const handlerOpenChat = (e) => {
    if (
      !e.target.className.includes('cws-dropdown-menu-item') &&
      e.target.className !== 'cws-tooltip__children'
    ) {
      if (replaceOpenChat) {
        openChatAtMsg.emit(chat.lastMessage);
      } else {
        handler('open-chat', chat, interlocutor);
        if (type !== 'widget') {
          history(`/chat/${chat.id}`);
        }
      }
    }
  };

  const onMsgProfileClick = React.useCallback((id) => {
    handler('view-specified-profile', null, null, id);
  }, []);

  const onTogglePin = React.useCallback(() => {
    const event = pinned ? 'unpin' : 'pin';

    handler(event, chat);
  }, [chat?.id, pinned]);

  const { scheduleMessages = [] } = chat;
  const isShowScheduleBadge =
    !isNil(scheduleMessages) && !isEmpty(scheduleMessages);
  const isActiveScheduleBadge = scheduleMessages.some(
    (message) => message.isSent
  );

  return (
    <div
      className={classNames('cw-chat-card', {
        active: Boolean(active),
        pinned: Boolean(pinned),
        'not-available': isNotAvailable
      })}
      onClick={handlerOpenChat}>
      <div className="cw-chat-card__header">
        <div className="cw-chat-card__avatar">
          <Avatar
            chat={chat}
            size={40}
            color={chatColor}
            name={chatName}
            interlocutor={interlocutor}
          />
        </div>
        <ChatInfo
          chatName={chatName}
          chatType={chatType}
          isResponseChat={chatTypes.isResponseChat(chat)}
          members={members}
          online={amountOnline}
          interlocutor={interlocutor}
        />
        <div className="cw-chat-card__last-message-date">
          {showPinButton && (
            <div className="cw-chat-card__pin-btn">
              <TogglePinBtn size={22} isPinned={pinned} onClick={onTogglePin} />
            </div>
          )}
          <LastMessageDate time={lastMessage?.time} language={language} />
        </div>
      </div>
      <div className="cw-chat-card__body">
        <MessageInfo
          employeeId={employeeId}
          showProfile={onMsgProfileClick}
          typingList={typingList}
          lastMessage={lastMessage}
          chatType={chatType}
          containsUnreadMsg={containsUnreadMsg}
          channelMember={channelMember}
          interlocutor={interlocutor}
          chatId={chatId}
          chat={chat}
        />
        <div className="cw-chat-card__badges">
          <Badges
            isChatNotificationOff={chatNotificationOff || isNotAvailable}
            amountUnreadMsg={amountUnreadMsg}
            isShowScheduleBadge={isShowScheduleBadge}
            isActiveScheduleBadge={isActiveScheduleBadge}
            hasMention={hasMention}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Chat);
