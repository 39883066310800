import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside the passed ref
 *
 * @param {object} ref
 * @param {Function} callback
 */
export default function useOutsideClick(
  ref,
  callback,
  { eventName = 'mousedown' } = {}
) {
  useEffect(() => {
    /**
     * Handler of click outside of element
     *
     * @param {object} event
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener(eventName, handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener(eventName, handleClickOutside);
    };
  }, [ref]);
}
