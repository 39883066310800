import React from 'react';
import classNames from 'classnames';
import Text from 'react-components/src/components/Typography';
import Icon, { Icons } from 'react-components/src/icons';
import { haveMemberStatus } from 'core/data/light/channelMember';
import { DELETED, NOT_PARTNER } from 'core/data/light/memberStatus';
import { useTranslate } from '../../../../../../../TranslateProvider';
import { getColor } from './helpers';
import './style.css';

const DialogInfo = ({ interlocutor }) => {
  const t = useTranslate();

  const isNotPartner = haveMemberStatus(NOT_PARTNER, interlocutor);
  const isDeleted = haveMemberStatus(DELETED, interlocutor);
  const isNotAvailable = isNotPartner || isDeleted;

  const color = getColor(interlocutor);

  const renderCompanyName = () => {
    const { companyName } = interlocutor;
    if (interlocutor.isDeletedCompany) {
      return `${companyName} (${t('chat-widget.chat-item.company-deleted')})`;
    }
    return companyName;
  };

  return (
    <div className="cw-chat-card-info">
      <div className="cw-chat-card-info__chat-title">
        {isNotAvailable && (
          <div className="cw-chat-card-info__title-icon">
            <Icon icon={Icons.Block} size={16} />
          </div>
        )}
        <Text
          level={1}
          weight={2}
          lineHeight={2}
          className={classNames('cw-chat-card-info__name', {
            [`cw-chat-card-info__name_color_${color}`]: color,
            'cw-chat-card-info__user-name_not-available': isNotAvailable
          })}>
          {interlocutor.userName}
        </Text>
      </div>
      <Text
        className="cw-chat-card-info__subtitle"
        level={2}
        lineHeight={3}
        weight={3}>
        {renderCompanyName()}
      </Text>
    </div>
  );
};

export default DialogInfo;
