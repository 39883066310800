export default (values) => {
  const errors = {};

  const password = values.get('password');
  const repeatPassword = values.get('repeatPassword');

  if (!password) {
    errors.password = 'Required';
  }

  if (password && password.length < 8) {
    errors.password = 'Password min length 8 symbols';
  }

  if (!repeatPassword) {
    errors.repeatPassword = 'Required';
  }

  if (repeatPassword && password && password !== repeatPassword) {
    errors.repeatPassword = 'Password fields must be the same';
  }

  return errors;
};
