import React from 'react';
import { useTranslate } from '@hooks';
import { Typography } from '@components';

import { InfoBlock } from '@link/react-components';
import styles from './StatusIndoBlock.module.css';

import { EmailStatuses } from '../../../../api/requests';
import { getTranslate } from '../../utils';

const statuses = [
  EmailStatuses.Processed,
  EmailStatuses.Delivered,
  EmailStatuses.Opened,
  EmailStatuses.Failed
];

export default function StatusInfoBlock() {
  const t = useTranslate();

  return (
    <InfoBlock>
      {statuses.map((status) => (
        <div key={status} className={styles.line}>
          <Typography variant="body1Sb" className={styles[status]}>
            {getTranslate(t, `statuses.${status}.displayName`)}
          </Typography>

          <Typography variant="body1Reg" className={styles.description}>
            {getTranslate(t, `statuses.${status}.description`)}
          </Typography>
        </div>
      ))}
    </InfoBlock>
  );
}
