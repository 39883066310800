import React from 'react';

/* eslint-disable max-len */
function IconEyeOff() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        d="M19.1201 14.6282C18.9739 14.482 18.7362 14.482 18.5899 14.6282C18.4437 14.7745 18.4437 15.013 18.5899 15.1584C19.0144 15.5829 19.2491 16.1476 19.2491 16.7491C19.2491 17.9894 18.2397 18.9989 16.9993 18.9989C16.3979 18.9989 15.8332 18.7649 15.4087 18.3397C15.2625 18.1934 15.0247 18.1934 14.8785 18.3397C14.7322 18.4852 14.7322 18.7237 14.8785 18.8699C15.4439 19.4369 16.1976 19.7488 16.9993 19.7488C18.6537 19.7488 19.999 18.4035 19.999 16.7491C19.999 15.9474 19.6871 15.1937 19.1201 14.6282Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        d="M17.522 13.8008C17.3518 13.7708 17.1778 13.749 16.9993 13.749C15.345 13.749 13.9996 15.0944 13.9996 16.7488C13.9996 16.9272 14.0213 17.1012 14.0521 17.2715C14.0843 17.453 14.2426 17.5812 14.4203 17.5812C14.442 17.5812 14.4638 17.5797 14.4863 17.5752C14.6895 17.5392 14.826 17.3442 14.79 17.141C14.7668 17.0135 14.7495 16.8837 14.7495 16.7488C14.7495 15.5084 15.7589 14.4989 16.9993 14.4989C17.1343 14.4989 17.264 14.5162 17.3915 14.5387C17.591 14.5792 17.7897 14.4382 17.8257 14.235C17.8617 14.0318 17.7253 13.8368 17.522 13.8008Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        d="M25.9093 16.5066C25.8246 16.4061 23.796 14.0385 20.998 12.5889C20.8165 12.4937 20.5878 12.5657 20.4925 12.7502C20.3973 12.9339 20.4693 13.1604 20.6537 13.2556C22.8091 14.3715 24.5309 16.1129 25.1196 16.7488C24.2257 17.717 20.7077 21.2485 16.9993 21.2485C15.7409 21.2485 14.4728 20.9447 13.2286 20.3448C13.0442 20.254 12.8184 20.3328 12.7284 20.5195C12.6377 20.7055 12.7165 20.9297 12.9032 21.0197C14.2493 21.6699 15.6277 21.9984 16.9994 21.9984C21.6197 21.9984 25.7369 17.1958 25.9101 16.9911C26.0285 16.8515 26.0278 16.6468 25.9093 16.5066Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        d="M19.4598 11.932C18.6064 11.6447 17.7785 11.5 16.9993 11.5C12.3789 11.5 8.26177 16.3026 8.08853 16.5073C7.97903 16.6363 7.97003 16.8238 8.06755 16.9641C8.11929 17.0383 9.35894 18.7984 11.5075 20.2255C11.5712 20.2683 11.6425 20.2885 11.7145 20.2885C11.8352 20.2885 11.9545 20.23 12.0265 20.1198C12.1412 19.9481 12.094 19.7148 11.9215 19.6008C10.3436 18.5517 9.26371 17.285 8.85948 16.7698C9.72267 15.8324 13.2639 12.25 16.9993 12.25C17.6975 12.25 18.4452 12.382 19.2206 12.6422C19.4171 12.7126 19.6301 12.6039 19.6953 12.4067C19.7613 12.2102 19.6563 11.998 19.4598 11.932Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        d="M23.6385 10.1097C23.4923 9.96344 23.2545 9.96344 23.1083 10.1097L10.3594 22.8586C10.2131 23.0049 10.2131 23.2426 10.3594 23.3888C10.4329 23.4616 10.5288 23.4983 10.6249 23.4983C10.7209 23.4983 10.8168 23.4616 10.8896 23.3888L23.6385 10.6399C23.7847 10.4937 23.7847 10.2559 23.6385 10.1097Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconEyeOff;
