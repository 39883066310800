import React from 'react';
import Success from './success';
import Danger from './danger';
import Warning from './warning';
import Info from './info';

const Icon = ({ name }) => (
  <>
    {name === 'success' && <Success />}
    {name === 'danger' && <Danger />}
    {name === 'warning' && <Warning />}
    {name === 'info' && <Info />}
  </>
);

export default Icon;
