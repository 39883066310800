import { isShown } from '../../../data-type/block-group';

export const toggleMessengerCategoryCase = async (
  { getGroup, showGroup, hideGroup, updateGroup },
  { name }
) => {
  const group = await getGroup();
  if (isShown(group)) {
    hideGroup();
  } else {
    showGroup(name);
    await updateGroup();
  }
};
