import { defRecord } from 'core/lib';

const { makeAttachesVM, getMessage } = defRecord('AttachesVM', [
  't',
  'message'
]);

const getAttachText = (vm) => {
  const { files } = getMessage(vm);

  if (files.length === 1) return files[0].name;

  return `${files.length} ${vm.t('chat-widget.files')}`;
};

export { makeAttachesVM, getAttachText };
