import React from 'react';
import { useSelector } from 'react-redux';
import Dashboard from '../../../../../../modules/dashboard';
import { useMessengerConfig } from '../../../../../../Messenger/hooks/useMessengerConfig';
import * as getters from '../../../../../../getter';

const ChatDashboard = () => {
  const { dashboard } = useMessengerConfig();

  const user = useSelector(getters.getCurrUser);
  const openedChat = useSelector(getters.getOpenedChat);
  const isActive = useSelector(getters.checkCurrentMemberHaveActivedMS);

  return (
    <Dashboard
      chat={openedChat}
      user={user}
      isActive={isActive}
      config={dashboard}
    />
  );
};

export default React.memo(ChatDashboard);
