import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const TitlePage = ({ t, hideModalDialog, goNextPage, pages, currentPage }) => (
  <div className="help-modal-first-page">
    <Modal.Header>
      <Modal.Title>
        <span
          className="modal-header-cross pull-right"
          onClick={() => hideModalDialog()}
        />
      </Modal.Title>
    </Modal.Header>
    <div className="modal-header-first-page-text-block">
      <div className="modal-header-first-page-title">
        {pages[currentPage].title}
      </div>
      <div className="modal-header-first-page-text">
        {pages[currentPage].text}
      </div>
      <Button
        bsStyle="primary"
        onClick={() => goNextPage()}
        className="modal-header-first-page-next-button">
        {t('Continue')}
      </Button>
    </div>
  </div>
);

export default TitlePage;
