import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const Caption = ({ caption, theme }) => (
  <div
    className={`cw-topic__caption cw-topic__caption-theme-${theme}`}
    dangerouslySetInnerHTML={{ __html: caption }}
  />
);

Caption.propTypes = {
  caption: PropTypes.string.isRequired
};

export default Caption;
