import { updateAndUnshift } from '../../../data-type/block-list';
import customHistory from '../../../../../customHistory';
import { isWidget } from '../../../../../lib/goodwix';

export const createTopicService = async (
  {
    prevChatMode,
    clearInputFiles,
    gaTopicCreate,
    createTopic,
    getChatList,
    setChats,
    showChat,
    updateBySocket
  },
  { name, title, desc, files, employeesId, membersRoles }
) => {
  const topic = await createTopic({
    name,
    title,
    desc,
    files,
    employeesId,
    membersRoles
  });
  updateBySocket(topic);
  const chatList = getChatList();
  const updateChatList = updateAndUnshift(chatList, topic);
  prevChatMode();
  setChats(updateChatList);
  clearInputFiles();
  gaTopicCreate();
  showChat(topic);

  if (!isWidget(customHistory.location.pathname)) {
    customHistory.push(`/chat/${topic.id}`);
  }

  return topic;
};
