import React, { useEffect, useState } from 'react';
import { isNil } from 'ramda';
import { format } from 'date-fns';
import RangeDateTimePicker from '../range-datetime-picker';
import ChipContainer from '../../inputs/dynamic-border/chip-container';

const DEFAULT_RANGE = { start: null, end: null };

function dateOrNull(value) {
  return value ? new Date(value) : null;
}

function RangeDatetimeInputPicker({
  startDate,
  endDate,
  placeholder,
  language,
  onChange,
  onApply,
  onReset,
  onFocus,
  disabled = false
}) {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [rangeDateList, setRangeDateList] = useState(
    getDefaultRange(startDate, endDate)
  );
  const [viewedValues, setViewedValues] = useState([]);

  useEffect(() => {
    if (startDate !== rangeDateList.start || endDate !== rangeDateList.end) {
      setRangeDateList(getDefaultRange(startDate, endDate));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (isEmptyRangeDate(rangeDateList)) {
      setViewedValues([]);
    } else {
      setViewedValues([transformRangeToView(rangeDateList)]);
    }
  }, [rangeDateList]);

  const onOpen = () => {
    setIsPickerOpen(true);
  };

  const onClosePicker = () => {
    if (isEmptyRangeDate(rangeDateList[0])) {
      setViewedValues([]);
    }
  };

  const onChangeRange = (newRange) => {
    setRangeDateList(isEmptyRangeDate(newRange) ? DEFAULT_RANGE : newRange);

    if (typeof onChange === 'function') {
      onChange(newRange);
    }
  };

  const onClearRangeDate = () => {
    setRangeDateList(DEFAULT_RANGE);
  };

  return (
    <RangeDateTimePicker
      language={language}
      isOpen={isPickerOpen}
      triggerComponent={
        <ChipContainer
          items={viewedValues}
          onClick={onOpen}
          onRemove={onClearRangeDate}
          mode="simple"
          placeholder={placeholder}
          disabled={disabled}
        />
      }
      startDate={dateOrNull(rangeDateList.start)}
      endDate={dateOrNull(rangeDateList.end)}
      onClickOutside={onClosePicker}
      onChange={onChangeRange}
      onApply={onApply}
      onReset={onReset}
      onFocus={onFocus}
      disabled={disabled}
    />
  );
}

export default RangeDatetimeInputPicker;

function isEmptyRangeDate(rangeDate) {
  return isNil(rangeDate) || (isNil(rangeDate.start) && isNil(rangeDate.end));
}

function transformDate(date) {
  return format(new Date(date), 'dd.MM.yyyy HH:mm');
}

function transformToChip(value) {
  return { value };
}

function transformRangeToView(range) {
  if (!isNil(range.start) && !isNil(range.end)) {
    return transformToChip(
      `${transformDate(range.start)} - ${transformDate(range.end)}`
    );
  }

  if (!isNil(range.start)) {
    return transformToChip(transformDate(range.start));
  }

  if (!isNil(range.end)) {
    return transformToChip(transformDate(range.end));
  }

  return transformToChip('');
}

function getDefaultRange(start, end) {
  if (!isNil(start) || !isNil(end)) {
    return {
      start: start || null,
      end: end || null
    };
  }

  return DEFAULT_RANGE;
}
