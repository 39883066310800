import React from 'react';

/* eslint-disable max-len */
function DangerExclamation() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"
        fill="#FD4F53"
      />
      <path
        d="M9 5V11"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2499 13.9766C10.2499 14.6669 9.69023 15.2266 8.99988 15.2266C8.30952 15.2266 7.74988 14.6669 7.74988 13.9766C7.74988 13.2862 8.30952 12.7266 8.99988 12.7266C9.69023 12.7266 10.2499 13.2862 10.2499 13.9766Z"
        fill="white"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default DangerExclamation;
