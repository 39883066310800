import React from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import Text, { FONTS } from 'react-components/src/components/Typography';
import Link from 'react-components/src/components/Link';
import UserMessageLayout from '../../../layouts/UserMessageLayout';
import { isNilOrEmpty } from '../../../../../../../utils/utils';
import NewsContent from './NewsContent';
import './style.css';

const NewsMessage = ({ id, message, isSelected, isSelectMode, config }) => {
  const { newsMessage } = config.config;
  const { placement } = newsMessage;

  return (
    <UserMessageLayout
      id={id}
      className={cn(`cwm-msg_theme-${config.theme}`, {
        selected: isSelected,
        'select-mode': isSelectMode
      })}
      defaultPlacement={placement}
      content={
        <div className="news-message">
          <div className="news-message__header">
            <Text className="news-message__title" variant={FONTS.body1Sb}>
              {message.title}
            </Text>
            <Text
              className="news-message__number"
              variant={FONTS.body2Reg}>{`#${message.itemId}`}</Text>
          </div>
          {!isNilOrEmpty(message.metadata) && (
            <div className="news-message__content">
              <div className="news-message__vertical-divider" />
              <NewsContent data={message.metadata} />
            </div>
          )}
          <div className="news-message__footer">
            <div className="news-message-source">
              <div className="news-message-source__link">
                <Link url={message.url} type="a">
                  {message.source}
                </Link>
              </div>
              <Text
                className="news-message-source__author"
                variant={FONTS.body1Reg}>
                {message.author}
              </Text>
            </div>
            <Text
              className="news-message__published-time"
              variant={FONTS.body2Reg}>
              {format(new Date(message.publishedTime), 'dd.MM.yyyy hh:mm')}
            </Text>
          </div>
        </div>
      }
    />
  );
};

export default React.memo(NewsMessage);
