import React from 'react';

const ArrowLast = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="15"
    viewBox="0 0 13 15">
    <path
      fill="#767b92"
      transform="rotate(180 6.25 7.25) scale(.99998)"
      // eslint-disable-next-line max-len
      d="M5 7l-.354-.353a.5.5 0 0 0 0 .707L5 7zM12.354.354a.5.5 0 1 0-.707-.708l.707.708zm-.707 14a.5.5 0 0 0 .707-.707l-.707.707zM0 7l-.354-.353a.5.5 0 0 0 0 .707L0 7zM7.354.354a.5.5 0 0 0-.707-.708l.707.708zm-.707 14a.5.5 0 0 0 .707-.707l-.707.707zm-1.293-7l7-7-.707-.708-7 7 .707.708zm7 6.293l-7-7-.708.707 7 7 .708-.707zm-12-6.293l7-7-.707-.708-7 7 .707.708zm7 6.293l-7-7-.708.707 7 7 .708-.707z"
    />
  </svg>
);

export default ArrowLast;
