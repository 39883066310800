import React from 'react';
import ButtonIcon from 'react-components/buttons/ButtonIcon';

const defaultBtn = {
  icon: 'without',
  value: 'null'
};

const selectBtn = (list, chosen) => {
  const btn = list.find(({ value }) => value === chosen);
  return btn || defaultBtn;
};

const IfButton = ({ t, chosen = '', list = [], size = 18, onClick }) => {
  const {
    icon,
    disabled,
    active,
    color,
    activeColor,
    tooltip,
    value,
    tooltipId,
    wait
  } = selectBtn(list, chosen);

  return (
    <ButtonIcon
      t={t}
      icon={icon}
      size={size}
      disabled={disabled}
      active={active}
      color={color}
      activeColor={activeColor}
      tooltip={tooltip}
      tooltipId={tooltipId}
      onClick={() => {
        if (
          chosen === 'inactive-video-call' ||
          chosen === 'active-video-call'
        ) {
          console.log(`click by ${chosen}`);
        }

        onClick(value);
      }}
      wait={wait}
    />
  );
};

export default IfButton;
