export const MODE = Object.freeze({
  INPUT: 'INPUT',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  VIEW: 'VIEW'
});

export const ERROR_TYPES = Object.freeze({
  FIELDS_PARSE: 'FieldsParseError',
  FILE_PARSE: 'FileParseError'
});

export const INPUT_TYPES = Object.freeze({
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  LOAD_FILE_SUCCESS: 'LOAD_FILE_SUCCESS',
  LOAD_FILE_ERROR: 'LOAD_FILE_ERROR'
});

export const allowedFileTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
];

export const MAX_FILE_SIZE = 1024 * 1024 * 10; // 10 MB
