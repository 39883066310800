import { createSelector } from 'reselect';
import { getChatWidget } from '../../getterCommon';
import { sortChatsMethod } from './services/sortChats';
import { filterChats } from './services/filter';

export const getChatBlock = (state) => getChatWidget(state).blockChat;
export const getChatBlockList = (state) => getChatWidget(state).blockChat.list;
export const getChatArchive = (state) => getChatWidget(state).blockArchive;
export const getChatArchiveList = (state) =>
  getChatWidget(state).blockArchive.list;
export const getGroupChats = (state) => getChatWidget(state).blockGroupChats;

const getCurrEmplId = (state) => state.getIn(['user', 'user', 'employeeId']);
const getWhereChats = (state) => getChatWidget(state).whereChats;
const getWhereArchivies = (state) => getChatWidget(state).whereArchivies;

const generateChats = (chatBlock, currentEmployeeId, whereChats) => ({
  ...chatBlock,
  list: sortChatsMethod({
    currentEmployeeId,
    chatsList: filterChats({
      filterBy: whereChats.filterBy,
      chatsList: chatBlock.list
    }),
    ...whereChats
  })
});

export const getChatBlockWithWhere = createSelector(
  [getChatBlock, getCurrEmplId, getWhereChats],
  generateChats
);

export const getChatArchiveWithWhere = createSelector(
  [getChatArchive, getCurrEmplId, getWhereArchivies],
  generateChats
);
