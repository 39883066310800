import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Checkbox from '../../../../../Checkbox';

import { formatDate } from '../../../../../../../utils/date';

import * as modalActions from '../../../../../../../action-creators/modal';
import '../style.css';
import { convertUrlToFile } from '../../../../../modules/files/utils';

const previewSrc = (url, type) => {
  const ext = type.toLowerCase();
  if (ext === 'jpeg' || ext === 'jpg' || ext === 'png' || ext === 'webp') {
    return convertUrlToFile(url);
  }
  return '/img/ico_doc.svg';
};

const renderCheckbox = (checked, isMaxFiles, onClickCheckbox) => (
  <div className="cwsa-files-list__list-checkbox">
    <Checkbox
      checked={checked}
      onClick={onClickCheckbox}
      disabled={checked ? false : isMaxFiles}
    />
  </div>
);

const renderImg = (src, type) => (
  <div className="cwsa-files-list__list-img-ct">
    <img
      className="cwsa-files-list__list-img"
      src={previewSrc(src, type)}
      alt=""
    />
  </div>
);

const renderName = (name) => (
  <div className="cwsa-files-list__list-name">
    <span className="textlimit">{name}</span>
  </div>
);

const renderDate = (date) => (
  <div className="cwsa-files-list__list-date">
    <span>{formatDate(date)}</span>
  </div>
);

const renderType = (type) => (
  <div className="cwsa-files-list__list-type">
    <span>{type}</span>
  </div>
);

const renderSize = (size) => {
  let s = '';
  if (+size >= 1024 * 1024) {
    s = `${(+size / (1024 * 1024)).toFixed(1)}mb`;
  } else {
    s = `${(+size / 1024).toFixed(1)}kb`;
  }
  return (
    <div className="cwsa-files-list__list-size">
      <span>{s}</span>
    </div>
  );
};

const Row = ({
  id,
  src,
  name,
  date,
  type,
  size,
  checked,
  onClickCheckbox,
  isMaxFiles
}) => (
  <div className="cwsa-contact-lists__list-container">
    {renderCheckbox(checked, isMaxFiles, () => onClickCheckbox(id))}
    {renderImg(src, type)}
    {renderName(name)}
    {renderDate(date)}
    {renderType(type)}
    {renderSize(size)}
  </div>
);

Row.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
  onClickCheckbox: PropTypes.func.isRequired,
  isMaxFiles: PropTypes.bool.isRequired
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      showModal: modalActions.showModal
    },
    dispatch
  );

export default connect(null, mapDispatch)(Row);
