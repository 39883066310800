import React from 'react';
import classNames from 'classnames';
import { useIsScrolling } from '../../hooks/useIsScrolling';
import './style.css';

const Scrollbox = React.forwardRef(
  ({ id, className, style, children, delay = 500 }, ref) => {
    const isScrolling = useIsScrolling(ref, delay);

    return (
      <div
        id={id}
        className={classNames('scrollbox', {
          scrolling: isScrolling,
          [className]: Boolean(className)
        })}
        style={style}
        ref={ref}>
        <div style={{ height: '1px', minHeight: '100%' }}>{children}</div>
      </div>
    );
  }
);

export default Scrollbox;
