import request from '../lib/request';

class WithConfirmationRequestService {
  #baseURl = '/api/requests';

  async confirm({ action, requestId }) {
    const response = await request.patch(
      `${this.#baseURl}/${requestId}/user-action/${action}`
    );

    return response;
  }
}

export const withConfirmationRequestService =
  new WithConfirmationRequestService();
