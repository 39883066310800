import React from 'react';
import { isEmpty } from 'ramda';
import DOMPurify from 'dompurify';
import Text from 'react-components/src/components/Typography';

import classNames from 'classnames';
import getMsg from './msg';
import Attachs from './attachs';

import {
  linkToProfileHandler,
  replaceEmojiToMarkup
} from '../../../../../utils';
import IconDangerExclamation from '../../../../../ButtonIcon/icons/danger-exclamation';
import { getTextMessage } from '../../../../../main/messages';
import { useTranslate } from '../../../../../../../TranslateProvider';

function isContainsEmoji(text) {
  return /data-emoji=/.test(text);
}

function formatter(text) {
  if (!text) return null;

  if (isContainsEmoji(text)) return text;

  return text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');
}

const EMOJI_SIZE = 16;

const RenderLastMsg = ({ t, message, showProfile, interlocutor }) => {
  const msg = getTextMessage({
    t,
    message,
    chat: {},
    handler: null,
    interlocutor
  });

  if (msg.msg?.$$typeof) {
    return (
      <Text
        className={`cws-chat-last-message__message cws-chat-last-message__message_${msg.type.toLowerCase()}`}
        onClick={(event) => linkToProfileHandler(event, showProfile)}
        level={2}
        lineHeight={2}
        weight={3}>
        {msg.msg}
      </Text>
    );
  }

  const msgText = formatter(replaceEmojiToMarkup(msg.msg, EMOJI_SIZE));

  return (
    <Text
      className={`cws-chat-last-message__message cws-chat-last-message__message_${msg.type.toLowerCase()}`}
      level={2}
      lineHeight={2}
      weight={3}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(msgText) }} // eslint-disable-line
      onClick={(event) => linkToProfileHandler(event, showProfile)}
    />
  );
};

const LastMessage = ({
  lastMessage,
  chatType,
  chat,
  containsUnreadMsg,
  employeeId,
  channelMember,
  showProfile,
  interlocutor
}) => {
  const t = useTranslate();
  const msg = getMsg(
    t,
    lastMessage,
    chatType,
    containsUnreadMsg,
    employeeId,
    channelMember,
    chat
  );
  if (msg === null) return null;

  return (
    <div className={`cws-chat-last-message ${msg.isUnread ? 'unread' : ''}`}>
      {!isEmpty(msg.userName) && (
        <Text
          className={classNames(`cws-chat-last-message__user-name`, {
            own: msg.isOwnMsg
          })}
          level={2}
          weight={2}
          lineHeight={2}>
          {msg.userName}
        </Text>
      )}
      <Attachs lastMessage={lastMessage} />
      <RenderLastMsg
        t={t}
        message={msg}
        showProfile={showProfile}
        interlocutor={interlocutor}
      />
      {msg.unsentIcon && <IconDangerExclamation />}
    </div>
  );
};

export default LastMessage;
