import {
  CHOOSE_SUPPLIER_WIZARD_KEY,
  ChooseSupplierWizard
} from './ChooseSupplierWizard';
import {
  CHOOSE_SUPPLIER_COMPANY_WIZARD_KEY,
  ChooseSupplierCompanyWizard
} from './ChooseSupplierCompanyWizard';

export const wizards = {
  [CHOOSE_SUPPLIER_WIZARD_KEY]: ChooseSupplierWizard,
  [CHOOSE_SUPPLIER_COMPANY_WIZARD_KEY]: ChooseSupplierCompanyWizard
};
