import React from 'react';
import block from 'bem-cn-lite';
import { isNil } from 'ramda';
import DateInput from './components/DateInput';
import TimeInput from './components/TimeInput';
import '../datepicker.css';

const b = block('datepicker-goodwix');

const DTPHeader = ({
  t,
  title,
  date,
  setDate,
  minTime,
  minDate,
  maxDate,
  dateTkey,
  timeTkey
}) => (
  <div className={b('header-wrapper')}>
    {!isNil(title) && <div className={b('title')}>{t(title)}</div>}
    <div className={b('header')}>
      <DateInput
        t={t}
        date={date}
        setDate={setDate}
        minDate={minDate}
        maxDate={maxDate}
        dateTkey={dateTkey}
      />
      <TimeInput
        t={t}
        date={date}
        setDate={setDate}
        minTime={minTime}
        timeTkey={timeTkey}
      />
    </div>
  </div>
);

export default DTPHeader;
