import { getVideoResolution } from './utils';

const { width, height } = getVideoResolution(480);

export default {
  defaultAvatar: '/img/user.svg',
  peerConfig: {
    host: '/',
    path: '/peerjs',
    secure: process.env.NODE_ENV !== 'development',
    port: process.env.NODE_ENV === 'development' ? '80' : '443',
    debug: 2
  },
  video: {
    width,
    height,
    frameRate: 16
  }
};
