import { useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { useZoom } from './useZoom';
import * as zoomingLevel from './zoomingLevels';

export const Zoom = ({ store, levels, children }) => {
  const { scale } = useZoom(store);
  const scaleRef = useRef(scale);

  useIsomorphicLayoutEffect(() => {
    scaleRef.current = scale;
  }, [scale]);

  const zoomTo = (newLvl) => {
    const zoom = store.get('zoom');
    if (zoom) {
      zoom(newLvl);
    }
  };

  const zoomIn = () => {
    const zoom = store.get('zoom');
    if (zoom) {
      const newLvl = zoomingLevel.increase(scaleRef.current);
      zoom(newLvl);
    }
  };

  const zoomOut = () => {
    const zoom = store.get('zoom');
    if (zoom) {
      const newLvl = zoomingLevel.decrease(scale);
      zoom(newLvl);
    }
  };

  return children({
    scale,
    onZoom: zoomTo,
    zoomIn,
    zoomOut
  });
};
