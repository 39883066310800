import React from 'react';

const Cross = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L9 9M9 1L1 9" stroke="#767B92" strokeLinecap="round" />
  </svg>
);

export default Cross;
