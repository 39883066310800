import React from 'react';

/* eslint-disable max-len */
function IconStar() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-stroke"
        d="M16.5516 9.03827C16.735 8.66665 17.265 8.66665 17.4484 9.03827L19.2579 12.7048C19.4764 13.1475 19.8987 13.4543 20.3873 13.5253L24.4335 14.1133C24.8436 14.1729 25.0074 14.6768 24.7106 14.9661L21.7827 17.8201C21.4292 18.1647 21.2679 18.6612 21.3513 19.1478L22.0425 23.1776C22.1126 23.5861 21.6839 23.8976 21.3171 23.7047L17.698 21.8021C17.261 21.5723 16.739 21.5723 16.302 21.8021L12.6829 23.7047C12.3161 23.8976 11.8874 23.5861 11.9575 23.1776L12.6486 19.1478C12.7321 18.6612 12.5708 18.1647 12.2173 17.8201L9.28939 14.9661C8.99263 14.6768 9.15639 14.1729 9.5665 14.1133L13.6127 13.5253C14.1013 13.4543 14.5236 13.1475 14.7421 12.7048L16.5516 9.03827Z"
        stroke="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconStar;
