import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import { useActions, useScrollTo, useEvent, useIsScrollBottom } from '@hooks';
import * as useCase from '../../useCases';
import CWMessages from '../chat-messages';
import { getLastUnreadMessageByChatId } from '../../getter';
import useIsFocusedTab from '../../hooks/isFocusedTab';
import * as messagesUC from '../../modules/messages/useCases';
import * as clientsChatDataUC from '../../modules/clientData';
import * as messagesDividerUC from '../../modules/messagesDivider';
import { Scrollbox } from './components';
import { usePlacement, useScrollMode, useUnreadMessagesDivider } from './hooks';
import {
  getDividerNode,
  getDividerNodeAnchor,
  getMessageDOMId,
  getMessageNode
} from './helpers';
import { getBottomChatNode } from '../chat-messages/helpers';
import { useDeepMemo } from '../../hooks';
import './style.css';

const SCROLL_MODS = {
  AutoScroll: 'autoScroll',
  UserScroll: 'userScroll'
};

const useMessagesUploaded = ({ chat, messages }) => {
  const [isMessagesUploaded, setMessagesUploaded] = useState(false);

  useEffect(() => {
    setMessagesUploaded(
      !isEmpty(messages) &&
        messages.some((message) => message.channelId === chat.id)
    );
  }, [chat.id, isEmpty(messages)]);

  return isMessagesUploaded;
};

function canLoadNext(amountLeftNext) {
  return amountLeftNext > 0;
}

const CWMMessages = ({
  chat,
  list,
  amountLeftPrev = 0,
  amountLeftNext = 0,
  selectedList,
  isSelectMode = false,
  isPossibleShowDivider = false,
  config,
  datePanelFormat
}) => {
  const lastUnreadMessageId = useSelector((state) =>
    getLastUnreadMessageByChatId(state, chat)
  );

  const {
    handleMessageClick,
    setReadMessagesInChat,
    saveClientData,
    clearMessageDivider
  } = useActions({
    ...useCase,
    ...messagesUC,
    ...clientsChatDataUC,
    ...messagesDividerUC
  });

  const scrollContainerRef = useRef(null);
  const [currentScrollMode, setCurrentScrollMode] = useScrollMode(
    SCROLL_MODS.AutoScroll
  );
  const isScrollBottom = useIsScrollBottom(scrollContainerRef, [list], {
    delta: 30
  });
  const isActiveTab = useIsFocusedTab();

  const isMessagesUploaded = useMessagesUploaded({ chat, messages: list });

  const isShowUnreadMessagesDivider = useUnreadMessagesDivider({
    isPossibleShowDivider,
    isActiveTab,
    isScrollBottom,
    lastUnreadMessageId,
    isUploadedMessages: !isEmpty(list)
  });

  useEffect(
    () => () => {
      saveClientData(null, { isScrollBottom: false, isActiveTab: false });
    },
    []
  );

  useEffect(() => {
    if (chat.id) {
      saveClientData(chat.id, { isScrollBottom, isActiveTab });
    }
  }, [isScrollBottom, isActiveTab]);

  const { scrollTo } = useScrollTo([list]);

  useEffect(() => {
    if (isScrollBottom && isActiveTab) {
      setReadMessagesInChat(chat.id);
    }
  }, [chat.id, lastUnreadMessageId, isActiveTab, isScrollBottom]);

  useEffect(() => {
    if (
      !isMessagesUploaded ||
      currentScrollMode.current !== SCROLL_MODS.AutoScroll
    ) {
      return;
    }

    if (isNil(lastUnreadMessageId)) {
      scrollTo(getBottomChatNode(chat.id));
      return;
    }

    const $divider = getDividerNode(chat.id);

    if ($divider) {
      $divider.scrollIntoView();
      return;
    }

    const $firstUnreadMessage = getMessageNode(lastUnreadMessageId);

    if ($firstUnreadMessage) {
      $firstUnreadMessage.scrollIntoView();
    }
  }, [isMessagesUploaded, list]);

  useEffect(() => {
    if (!isMessagesUploaded) return;

    if (isScrollBottom) {
      setCurrentScrollMode(SCROLL_MODS.AutoScroll);
    } else {
      setCurrentScrollMode(SCROLL_MODS.UserScroll);
    }
  }, [isScrollBottom]);

  useEffect(() => {
    setCurrentScrollMode(SCROLL_MODS.AutoScroll);
  }, [chat.id]);

  const handleScrollToMessageByLoadChunkEvent = useCallback((event) => {
    const { messageId } = event.detail;

    let $message = getMessageNode(messageId);

    if ($message) {
      $message.scrollIntoView();
    } else {
      setTimeout(() => {
        $message = getMessageNode(messageId);
        if ($message) {
          $message.scrollIntoView();
        } else {
          console.error('Not msg with html id: ', messageId);
        }
      }, 0);
    }
  }, []);

  const handleScrollToMessageByEvent = useCallback(
    (event) => {
      const { messageId, align = 'center' } = event.detail;
      scrollTo(getMessageDOMId(messageId), { block: align });
    },
    [scrollTo]
  );

  useEvent('scroll-when-load-chunk', handleScrollToMessageByLoadChunkEvent);
  useEvent('scroll-to-message', handleScrollToMessageByEvent);

  useEvent('load-divider', () => {
    const dividerAnchor = getDividerNodeAnchor(chat.id);
    dividerAnchor.scrollIntoView({ block: 'start', inline: 'nearest' });
  });

  useEffect(() => {
    if (canLoadNext(amountLeftNext) || isEmpty(list)) return;

    if (isScrollBottom && isActiveTab) {
      clearMessageDivider();
    }
  }, [isScrollBottom, isActiveTab, amountLeftNext, isEmpty(list)]);

  useEffect(() => {
    if (canLoadNext(amountLeftNext) || isEmpty(list)) return;

    saveClientData(chat.id, { isScrollBottom, isActiveTab });
  }, [isScrollBottom, isActiveTab, amountLeftNext, isEmpty(list)]);

  const onLoadMorePrev = useCallback(
    () => handleMessageClick('load-more', chat),
    [chat]
  );
  const onLoadMoreNext = useCallback(
    () => handleMessageClick('load-more-next', chat),
    [chat]
  );
  const memoizedChat = useDeepMemo(
    () => chat,
    [
      chat.id,
      chat.members,
      chat.scheduleMessages,
      chat.pinnedMessages,
      chat.unreadMessagesId
    ]
  );

  const messagesPlacement = usePlacement();

  return (
    <Scrollbox
      id="chat-items-scrollbox"
      className="cwm-scrollbox"
      chatId={chat?.id}
      ref={scrollContainerRef}>
      <CWMessages
        chat={memoizedChat}
        list={list}
        amountLeftPrev={amountLeftPrev}
        amountLeftNext={amountLeftNext}
        selectedList={selectedList}
        handlerMsg={handleMessageClick}
        isSelectMode={isSelectMode}
        isShowUnreadMessagesDivider={isShowUnreadMessagesDivider}
        config={config}
        lastUnreadMessageId={lastUnreadMessageId}
        isShowPopUpSystemNotification
        onLoadMorePrev={onLoadMorePrev}
        onLoadMoreNext={onLoadMoreNext}
        scrollContainerRef={scrollContainerRef}
        datePanelFormat={datePanelFormat}
        messagesPlacement={messagesPlacement}
      />
    </Scrollbox>
  );
};

export default CWMMessages;
