import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as modalActions from '../../../action-creators/modal';
import * as messageActions from '../../../action-creators/message';
import PDFViewerHeader from './PDFViewerHeader';
import PDFViewerFooter from './PDFViewerFooter';
import { Viewer } from '../../../lib/PDFViewer/Viewer';
import { thumbnailPlugin } from '../../../lib/PDFViewer/plugins/thumbnail';
import { zoomPlugin } from '../../../lib/PDFViewer/plugins/zoom';
import { printPlugin } from '../../../lib/PDFViewer/plugins/print';
import { useTranslate } from '../../../TranslateProvider';
import Loader from '../../Loader';
import { useActions } from '../../../hooks/useActions';
import { resendFile } from '../../ChatWidget/useCases';
import './ModalPDFViewer.css';

const renderThumbnailItem = ({
  pageIndex,
  currentPage,
  onJumpToPage,
  renderPageThumbnail
}) => (
  <div
    key={pageIndex}
    style={{
      backgroundColor:
        pageIndex === currentPage ? 'rgba(0, 0, 0, 0.3)' : '#fafbfc',
      padding: '1.5rem',
      width: '100%'
    }}>
    <div
      style={{
        marginBottom: '.5rem',
        boxShadow: pageIndex === currentPage ? '0 .4em 0 #e01e5a' : 'none'
      }}
      onClick={onJumpToPage}>
      {renderPageThumbnail}
    </div>
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto',
        width: '100px'
      }}>
      <div style={{ margin: 'auto', textAlign: 'center' }}>{pageIndex + 1}</div>
    </div>
  </div>
);

const renderSpinner = () => (
  <div className="modal-pdf-viewer__loader">
    <Loader />
  </div>
);

const ModalPDFViewer = ({
  src,
  name,
  senderName,
  addedAt,
  file,
  hideModalDialog,
  isCopyToStorageEnable,
  isDeleteEnabled,
  isDownloadEnabled,
  onCopyToStorageDocument,
  onDeleteDocument
}) => {
  const t = useTranslate();
  const zoomPluginInstance = zoomPlugin();
  const { zoomIn, zoomOut, zoomPageFit } = zoomPluginInstance;
  const printPluginInstance = printPlugin({
    renderLoadingPrint: renderSpinner
  });
  const { print } = printPluginInstance;
  const thumbnailPluginInstance = thumbnailPlugin({ renderSpinner });
  const { Thumbnails } = thumbnailPluginInstance;
  const actions = useActions({ resendFile });

  const onDownload = () => {
    window.open(`${src}?name=${name}`, '_blank');
  };

  const onDelete = async () => {
    if (window.confirm(t('confirm_document_delete'))) {
      await onDeleteDocument();
      hideModalDialog();
    }
  };

  const handleResend = useCallback(() => {
    actions.resendFile(file);
  }, []);

  return (
    <div className="modal-pdf-viewer__container" style={{ display: 'flex' }}>
      <PDFViewerHeader
        name={name}
        date={addedAt}
        senderName={senderName}
        hideModalDialog={hideModalDialog}
      />
      <div style={{ width: '20%' }}>
        <Thumbnails renderThumbnailItem={renderThumbnailItem} />
      </div>
      <div style={{ flex: 1, overflow: 'hidden' }}>
        <Viewer
          renderLoader={renderSpinner}
          fileUrl={src}
          plugins={[
            thumbnailPluginInstance,
            zoomPluginInstance,
            printPluginInstance
          ]}
          withCredentials
        />
      </div>
      <PDFViewerFooter
        t={t}
        name={name}
        date={addedAt}
        senderName={senderName}
        isShowAddToStorage={isCopyToStorageEnable}
        isDeleteEnabled={isDeleteEnabled}
        isDownloadEnabled={isDownloadEnabled}
        print={print}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        zoomPageFit={zoomPageFit}
        onDownload={onDownload}
        onDelete={onDelete}
        onCopyToStorage={onCopyToStorageDocument}
        handleResend={handleResend}
      />
    </div>
  );
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalActions.hideModalDialog,
      clearMessages: messageActions.clearMessages
    },
    dispatch
  );

export default connect(null, mapDispatch)(ModalPDFViewer);
