import { assoc, assocPath, remove, append, isEmpty } from 'ramda';

const { makeChunkList } = require('core/data/chunkList');

/**
 * @typedef BlockAdvancedSearch
 * @property {string} type
 * @property {boolean} hidden
 * @property {string} search
 * @property {object} params
 * @property {string|null} params.chatName
 * @property {string|null} params.companyName
 * @property {string|null} params.contactName
 * @property {string|null} params.messageText
 * @property {string|null} params.messageDate
 * @property {object} result
 * @property {[object]} result.dialogs
 * @property {[object]} result.contacts
 * @property {[object]} result.archives
 */

/**
 * Creates a block search data type
 *
 * @param {object} params
 * @param {boolean} params.hidden
 * @param {string} params.search
 * @param {string} params.sortDir
 * @param {[string]} params.selectedTags
 * @param {string|null} params.messageDate
 * @param {[object]} params.contacts
 * @param {[object]} params.messages
 * @param {[object]} params.chats
 * @param {[object]} params.companies
 * @returns {BlockAdvancedSearch}
 */
export const makeBlockAdvancedSearch = ({
  hidden = true,
  search = '',
  messageDate = { from: null, to: null },
  sortDir = 'up',
  selectedTags = [],
  contacts = [],
  messages = makeChunkList([], 0),
  chats = [],
  companies = []
} = {}) => ({
  type: 'BlockAdvancedSearch',
  hidden,
  search,
  viewTags: [
    'contacts',
    'messages',
    'chats',
    'companies'
    /* 'files'  */
  ],
  params: {
    selectedTags,
    messageDate,
    sortDir
  },
  result: {
    contacts,
    messages,
    chats,
    companies
  }
});

/**
 * Set search
 *
 * @param {BlockAdvancedSearch} blockSearch
 * @param {string} search
 * @returns {BlockAdvancedSearch}
 */
export const setASBlockGlobalSearch = (blockSearch, search = '') =>
  assoc('search', search, blockSearch);

/**
 * Set search params to block
 *
 * @param {BlockAdvancedSearch} blockSearch
 * @param {string} key
 * @param {string} value
 * @returns {BlockAdvancedSearch}
 */
export const setASBlockParams = (blockSearch, key, value) => {
  const newParams = assoc(key, value, blockSearch.params);
  return assoc('params', newParams, blockSearch);
};

/**
 * Set search result
 *
 * @param {BlockAdvancedSearch} blockSearch
 * @param {object} result
 * @returns {BlockAdvancedSearch}
 */
export const setASBlockResults = (blockSearch, result = {}) =>
  assoc('result', result, blockSearch);

export const setASBlockResultsPart = (blockSearch, { field, data }) =>
  assocPath(
    ['result', field, 'amountLeft'],
    data.amountLeft,
    assocPath(
      ['result', field, 'list'],
      blockSearch.result.messages.list.concat(data.list),
      blockSearch
    )
  );

/**
 * Hide block search
 *
 * @param {BlockAdvancedSearch} blockSearch
 * @returns {BlockAdvancedSearch}
 */
export const hideAdvancedSearch = (blockSearch) =>
  assoc('hidden', true, blockSearch);

/**
 * Show block search
 *
 * @param {BlockAdvancedSearch} blockSearch
 * @returns {BlockAdvancedSearch}
 */
export const showAdvancedSearch = (blockSearch) =>
  assoc('hidden', false, blockSearch);

export const isSearchQueryEmpty = (blockSearch) => blockSearch.search === '';

export const isActiveFilter = (blockSearch) =>
  blockSearch.params.messageDate.from !== null ||
  blockSearch.params.messageDate.to !== null ||
  !isEmpty(blockSearch.params.selectedTags);

export const toggleSortDir = (blockSearch) =>
  blockSearch.params.sortDir === 'up' ? 'down' : 'up';

export const removeTag = (tag, blockSearch) => {
  const index = blockSearch.params.selectedTags.findIndex(
    (stag) => stag === tag
  );
  if (index > -1) {
    return remove(index, 1, blockSearch.params.selectedTags);
  }
  return blockSearch.params.selectedTags;
};

export const toggleTag = (tag, blockSearch) => {
  if (blockSearch.params.selectedTags.includes(tag)) {
    return removeTag(tag, blockSearch);
  }
  return append(tag, blockSearch.params.selectedTags);
};

export const xtoggleTag = (tag, blockSearch) => {
  if (isEmpty(blockSearch.params.selectedTags)) {
    const index = blockSearch.viewTags.findIndex((stag) => stag === tag);
    return remove(index, 1, blockSearch.viewTags);
  }
  return removeTag(tag, blockSearch);
};

export const isSearchByNickname = (blockSearch) =>
  blockSearch.search.length > 0 && blockSearch.search[0] === '@';
