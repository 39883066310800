import { defRecord } from 'core/lib';
import { isEmpty } from 'ramda';
import { useBindData } from '../../../../hooks/useBindData';
import { useOnReachBottom } from '../../../../hooks/reachBottom';

const { makeListVM, getItems } = defRecord(
  'ListVM',
  [
    't',
    'mode',
    'title',
    'placeholder',
    'search',
    'items',
    'page',
    'limit',
    'size',
    'onMountEffect',
    'onCancel',
    'showModal',
    'hideModal',
    'searchItems',
    'loadItems',
    'loadItemsChunk',
    'isLoadingChunk',
    'searchBlockWidth',
    'children'
  ],
  {
    items: [],
    page: 0,
    limit: 9999,
    size: 0,
    search: '',
    placeholder: 'find_contacts',
    onCancel: () => {},
    searchBlockWidth: 510,
    isLoadingChunk: false
  }
);

const loadItemsOnScroll = (getData) => () => {
  const vm = getData();

  if (!vm.isLoadingChunk && hasItemsForLoad()) {
    vm.loadItemsChunk({
      mode: vm.mode,
      search: vm.search,
      page: vm.page + 1,
      limit: vm.limit
    });
  }

  function hasItemsForLoad() {
    const items = getItems(vm);
    return items.length < vm.size;
  }
};

function isShowItems(vm) {
  return !isEmpty(getItems(vm));
}

function getTitle(vm) {
  return vm.t(vm.title);
}

function getPlaceholder(vm) {
  return vm.t(vm.placeholder);
}

function getRows(vm) {
  return vm.children;
}

function getSearchBlockWidth(vm) {
  return vm.searchBlockWidth;
}

const initOnSearch = (vm) => (search) => {
  vm.searchItems({ mode: vm.mode, search });
};

const initOnCancel = (vm) => () => {
  vm.hideModal();
  vm.onCancel();
};

const onMount = (vm) => async () => {
  await vm.loadItems({ mode: vm.mode, limit: vm.limit });
};

const init = (vm, ref) => {
  const getData = useBindData(vm);

  const cb = loadItemsOnScroll(getData);
  useOnReachBottom(ref, cb);

  vm.onMountEffect(onMount(vm, cb));
};

export {
  init,
  makeListVM,
  isShowItems,
  getTitle,
  getPlaceholder,
  getRows,
  getSearchBlockWidth,
  initOnCancel,
  initOnSearch
};
