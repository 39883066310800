import React from 'react';
import Linkify from 'react-linkify';
import PropTypes from 'prop-types';

import './style.css';

const Title = ({ date, title, minimized, theme }) => (
  <div
    className={`cw-topic__title ${
      minimized ? 'cw-topic__title-minimized' : ''
    } cw-topic__title-theme-${theme}`}>
    {!minimized && <div className="cw-topic__title_date">{date}</div>}
    <Linkify className="cw-topic__title_title">{title}</Linkify>
  </div>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
  minimized: PropTypes.bool.isRequired
};

export default Title;
