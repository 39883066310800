import { curry, append, assoc, equals } from 'ramda';

/*
 * Data type for working with list of items
 */

export const makeList = (items = []) => ({ type: 'List', items });
export const getItems = (list) => list.items;
export const setItems = (items, list) => assoc('items', items, list);
export const isEqualItems = (items, list) => equals(items, getItems(list));
export const total = (list) => getItems(list).length;
export const isEmpty = (list) => total(list) === 0;
export const add = curry((item, list) =>
  setItems(append(item, getItems(list)), list)
);
export const clear = (list) => setItems([], list);
export const notFn =
  (predicat) =>
  (...args) =>
    !predicat(...args);
export const remove = curry((predicat, list) =>
  setItems(getItems(list).filter(notFn(predicat)), list)
);
export const byId = (id) => (item) => item.id === id;
export const removeById = (id, list) => remove(byId(id), list);
