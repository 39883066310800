import { compareDesc, isEqual } from 'date-fns';
import { isNil } from 'ramda';

export function isFirstDateGTE(firstDate, nextDate) {
  return compareDesc(new Date(firstDate), new Date(nextDate)) < 1;
}

export function isFirstDateGT(firstDate, nextDate) {
  return compareDesc(new Date(firstDate), new Date(nextDate)) === -1;
}

export function isFunction(fn) {
  return typeof fn === 'function';
}

export const noop = () => {};

export function dateOrNull(value) {
  return value ? new Date(value) : null;
}

export function isEqualDate(aDate, bDate) {
  if (isNil(aDate) || isNil(bDate)) {
    return aDate === bDate;
  }

  return isEqual(aDate, bDate);
}
