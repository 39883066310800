import React, { useCallback, useState } from 'react';
import './styles/modal_theme_default.styl';

import { isEmpty } from 'ramda';
import { Button, Modal } from '@link/react-components';
import { Form } from 'react-bootstrap';
import { useActions, useTranslate } from '@hooks';
import { formatterText } from '../../utils/utils';
import * as modalAction from '../../action-creators/modal';
import { TextArea } from '../FormControls';

function translateText(t, text) {
  if (!text) return '';
  if (typeof text === 'string') return t(text);
  if (text.tkey) return formatterText(t(text.key, text.options));
  return text;
}

const ModalSubmitWithComment = ({
  title,
  text,
  defaultInputText = '',
  maxInputSymbols,
  placeholder,
  onSubmit,
  onCancel = () => {},
  textBtnCancel,
  textBtnSubmit,
  submitArguments = {},
  textKey = '',
  isCloseAfterClickBackground = true
}) => {
  const t = useTranslate();

  const { hideModalDialog } = useActions(modalAction);

  const [inputText, setInputText] = useState(
    translateText(t, defaultInputText)
  );

  const closeModal = useCallback(() => {
    onCancel();
    hideModalDialog();
  }, []);

  const submit = useCallback(() => {
    if (!isEmpty(submitArguments) || !isEmpty(textKey)) {
      onSubmit({ ...submitArguments, [textKey]: inputText });
      hideModalDialog();
      return;
    }

    onSubmit(inputText);
    hideModalDialog();
  }, [inputText, submitArguments, textKey]);

  const changeText = ({ target }) => {
    setInputText(target.value);
  };

  const preventForm = useCallback((e) => {
    if (e.keyCode === 13) e.preventDefault();
  }, []);

  return (
    <Modal
      onClose={isCloseAfterClickBackground ? hideModalDialog : undefined}
      open>
      <Modal.Header>{translateText(t, title)}</Modal.Header>
      <Modal.Content>
        <Form>
          <p className="modal-container__row">{translateText(t, text)}</p>
          <TextArea
            value={inputText}
            onChange={changeText}
            placeholder={translateText(t, placeholder)}
            maxLength={maxInputSymbols}
            onKeyDown={preventForm}
          />
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Modal.Actions>
          <Button mode="text" onClick={closeModal}>
            {translateText(t, textBtnCancel) || t('Cancel')}
          </Button>
          <Button mode="primary" onClick={submit}>
            {translateText(t, textBtnSubmit) || t('Confirm')}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSubmitWithComment;
