import { defaultTo } from 'ramda';
import { ADMIN, EDITOR, PARTICIPANT, READER } from 'core/data/light/memberRole';
import {
  haveAdminMR,
  haveEditorMR,
  haveMemberStatus
} from 'core/data/light/channelMember';
import { MODERATION } from 'core/data/light/memberStatus';

const DEFAULT_MEMBER_AVATAR = '/img/user.svg';

const defaultToStr = defaultTo('');

export const makeMemberVM = ({
  t,
  mode = 'Channel',
  readOnly = false,
  currentMember,
  member,
  removeMember,
  getMemberRole,
  hardcodeType,
  setHasChanges,
  onChangeMemberRole
}) => ({
  t,
  mode,
  readOnly,
  currentMember,
  member,
  removeMember,
  getMemberRole,
  hardcodeType,
  setHasChanges,
  onChangeMemberRole
});

export const getMode = (vm) => vm.mode;
export const getMemberName = (vm) => defaultToStr(vm.member.userName);
export const getCompanyName = (vm) => defaultToStr(vm.member.companyName);
export const showMode = (vm) => (vm.isReadOnly ? 'view' : 'edit');

export const getAvatarInfo = (vm) => ({
  type: 'user',
  src: vm.member.avatarSrc || DEFAULT_MEMBER_AVATAR,
  employeeId: vm.member.employeeId
});

const generateOption = (vm, value) => ({
  value,
  label: vm.t(`channel-roles-${value}`)
});

export const getRole = (vm) => generateOption(vm, vm.getMemberRole(vm.member));

const getAllRolesByChatType = (vm) => {
  if (vm.mode === 'Channel') {
    return [ADMIN, PARTICIPANT];
  }

  if (vm.mode === 'Topic') {
    return [ADMIN, PARTICIPANT, EDITOR, READER];
  }

  return [];
};

const getPossibleOptions = (vm) => {
  if (vm.mode === 'Channel') {
    return [ADMIN, PARTICIPANT];
  }

  if (vm.mode === 'Topic') {
    if (haveAdminMR(vm.currentMember)) {
      return [ADMIN, PARTICIPANT, EDITOR, READER];
    }
    if (haveEditorMR(vm.currentMember)) {
      return [PARTICIPANT, EDITOR, READER];
    }
  }

  return [];
};

export const getPossibleRoles = (vm) =>
  getPossibleOptions(vm).map((value) => generateOption(vm, value));

export const getRolesHint = (vm) =>
  getAllRolesByChatType(vm).map((value) => generateOption(vm, value));

export const isEditRoleDisabled = (vm) => {
  if (vm.hardcodeType === 'ResponseChat') return true;
  if (vm.currentMember.employeeId === vm.member.employeeId) return true;
  if (vm.currentMember.memberRole !== 'ADMIN') return true;
  return !haveAdminMR(vm.currentMember) && haveAdminMR(vm.member);
};

export const initOnChangeMemberRole =
  (vm) =>
  ({ value }) => {
    vm.onChangeMemberRole({ mode: vm.mode, member: vm.member, role: value });
    vm.setHasChanges(true);
  };

export const isAllowDelete = (vm) => {
  if (vm.currentMember.employeeId === vm.member.employeeId) return false;
  if (haveAdminMR(vm.currentMember)) return true;
  if (haveEditorMR(vm.currentMember)) return !haveAdminMR(vm.member);

  return haveMemberStatus(MODERATION, vm.member);
};

export const generateCNMemberDelete = (vm) =>
  `member__delete ${isAllowDelete(vm) ? '' : 'disabled'}`;

export const initRemoveMember = (vm) => () => {
  if (!isAllowDelete(vm)) {
    return;
  }

  vm.removeMember(vm.member);
  vm.setHasChanges(true);
};
