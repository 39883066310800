import { PURCHASE_REQUESTS_CHANNEL } from 'core/data/light/purchaseRequestChannel';
import {
  ADMIN,
  EDITOR,
  filterMenu,
  havePermission,
  NOTACTIVE,
  PARTICIPANT,
  READER,
  USER
} from '../../../Dropdown/lib';
import {
  haveEditPermission,
  haveFullPermission,
  haveParticipantPermission
} from '../../../roles';
import * as chatType from '../../../helpers/chatType';

export const makeDashboardVM = ({
  chat,
  videoChat,
  user,
  isActive,
  handler,
  userTo,
  isDemo,
  config,
  type,
  size,
  t
}) => ({
  chat,
  videoChat,
  user,
  isActive,
  isSubscribed: chat.active,
  handler,
  userTo,
  isDemo,
  config,
  type,
  size,
  t
});

export const notificationBtnList = [
  { icon: 'notification', value: 'bell-on', tooltip: 'notice-off', wait: 0 },
  {
    icon: 'notification-mute',
    color: 'red',
    value: 'bell-off',
    tooltip: 'notice-on',
    wait: 0
  }
];

export const innerVideoCallList = [
  {
    icon: 'video',
    value: 'inactive-video-call',
    color: 'inactive',
    tooltip: 'video-call',
    wait: 1000
  },
  {
    icon: 'video',
    value: 'active-video-call',
    color: 'active',
    tooltip: 'return-to-video-call',
    active: true,
    tooltipId: 'return-to-video-call',
    wait: 1000
  }
];

export const innerVideoCallListDisabled = [
  {
    disabled: true,
    icon: 'video',
    value: 'inactive-video-call',
    color: 'inactive',
    tooltip: 'video-call',
    wait: 1000
  }
];

export const callList = [
  {
    conditionName: 'skype?',
    items: [
      { icon: 'skype', value: 'call-skype', color: 'active' },
      { icon: 'skype', value: 'skype-inactive', color: 'inactive' }
    ]
  },
  {
    conditionName: 'viber?',
    items: [
      { icon: 'viber', value: 'call-viber', color: 'active' },
      { icon: 'viber', value: 'viber-inactive', color: 'inactive' }
    ]
  },
  { separator: true },
  { icon: 'other-contacts', value: 'other-contacts-audio' }
];

export const videoCallList = [
  {
    conditionName: 'skype?',
    items: [
      { icon: 'skype', value: 'video-call-skype', color: 'active' },
      { icon: 'skype', value: 'skype-inactive', color: 'inactive' }
    ]
  },
  {
    conditionName: 'viber?',
    items: [
      { icon: 'viber', value: 'video-call-viber', color: 'active' },
      { icon: 'viber', value: 'viber-inactive', color: 'inactive' }
    ]
  },
  { separator: true },
  { icon: 'other-contacts', value: 'other-contacts-video' }
];

const channelPermissions = {
  edit: [ADMIN],
  view: [PARTICIPANT],
  archive: [ADMIN, READER, NOTACTIVE],
  unarchive: [ADMIN, READER, NOTACTIVE],
  'add-members': [ADMIN],
  'add-to-list': [ADMIN, READER, NOTACTIVE],
  'notice-off': [ADMIN, READER],
  'notice-on': [ADMIN, READER],
  'leave-channel': [ADMIN, READER],
  'video-call': [ADMIN, READER, PARTICIPANT],
  'download-chat-history': [ADMIN, EDITOR, READER, PARTICIPANT, NOTACTIVE],
  'download-chat-history-disable': [
    ADMIN,
    EDITOR,
    READER,
    PARTICIPANT,
    NOTACTIVE
  ]
};

const topicPermissions = {
  edit: [ADMIN, EDITOR],
  view: [PARTICIPANT],
  archive: [ADMIN, EDITOR, READER, PARTICIPANT, NOTACTIVE],
  unarchive: [ADMIN, EDITOR, READER, PARTICIPANT, NOTACTIVE],
  'add-members': [ADMIN, EDITOR],
  'add-to-list': [ADMIN, EDITOR, READER, PARTICIPANT, NOTACTIVE],
  'leave-topic': [ADMIN, EDITOR, READER, PARTICIPANT],
  'close-topic': [ADMIN],
  'video-call': [ADMIN, READER, PARTICIPANT],
  'download-chat-history': [ADMIN, EDITOR, READER, PARTICIPANT, NOTACTIVE],
  'download-chat-history-disable': [
    ADMIN,
    EDITOR,
    READER,
    PARTICIPANT,
    NOTACTIVE
  ]
};

const dialogPermissions = {
  edit: [USER]
};

const purchaseRequestsChannelPermissions = {
  edit: [ADMIN]
};

const newsFeedChannelPermissions = {
  edit: [ADMIN]
};

const getRoleOfTopic = (chat, user, isActive) => {
  if (!isActive || chat.isClosed) return NOTACTIVE;
  if (haveFullPermission(chat, user)) return ADMIN;
  if (haveEditPermission(chat, user)) return EDITOR;
  if (haveParticipantPermission(chat, user)) return PARTICIPANT;
  return READER;
};

const getRoleOfChannel = (chat, user, isActive) => {
  if (!isActive) return NOTACTIVE;
  if (haveEditPermission(chat, user)) return ADMIN;
  return READER;
};

const getRole = ({ chat, user, isActive }) => {
  if (chatType.isChannel(chat)) return getRoleOfChannel(chat, user, isActive);
  if (chatType.isTopic(chat)) return getRoleOfTopic(chat, user, isActive);
  if (chatType.isDialog(chat)) return USER;
  if (chatType.isPurchaseRequestsChannel(chat)) return READER;
  if (chatType.isNewsFeedChannel(chat)) return READER;
  throw new Error('no such type');
};

const getPermissions = (chat) => {
  if (chatType.isChannel(chat)) return channelPermissions;
  if (chatType.isTopic(chat)) return topicPermissions;
  if (chatType.isDialog(chat)) return dialogPermissions;
  if (chatType.isPurchaseRequestsChannel(chat))
    return purchaseRequestsChannelPermissions;
  if (chatType.isNewsFeedChannel(chat)) return newsFeedChannelPermissions;
  throw new Error('no such type');
};

const makeExtraList = (chat, { edit = false, closeTopic = false } = {}) => {
  if (chatType.isPurchaseRequestsChannel(chat)) {
    return [{ icon: 'minus-round', value: 'unsubscribe-purchase-requests' }];
  }

  if (chatType.isNewsFeedChannel(chat)) {
    return [
      {
        conditionName: 'archived?',
        items: [
          { icon: 'archive', value: 'archive' },
          { icon: 'archive', value: 'unarchive' }
        ]
      },
      { icon: 'folder', value: 'add-to-list' }
      // { icon: 'leave', value: 'leave-news-feed-channel' }
    ];
  }

  const list = [
    {
      conditionName: 'archived?',
      items: [
        { icon: 'archive', value: 'archive' },
        { icon: 'archive', value: 'unarchive' }
      ]
    },
    { icon: 'folder', value: 'add-to-list' },
    {
      conditionName: 'downloadEnabled?',
      items: [
        { icon: 'download', value: 'download-chat-history' },
        {
          icon: 'download',
          color: 'inactive',
          value: 'download-chat-history-disable'
        }
      ]
    }
  ];
  if (chatType.isDialog(chat)) {
    list.unshift({ icon: 'person', value: 'view-profile' });
    return list;
  }
  if (chatType.isChannel(chat) || chatType.isTopic(chat)) {
    const button = edit
      ? { icon: 'edit', value: 'edit' }
      : { icon: 'view', value: 'view' };
    list.unshift(button);
  }
  if (chatType.isChannel(chat)) {
    list.push({ icon: 'leave', value: 'leave-channel' });
    return list;
  }
  if (chatType.isTopic(chat)) {
    list.push({ icon: 'leave', value: 'leave-topic' });

    if (closeTopic) list.push({ icon: 'close', value: 'close-topic' });

    return list;
  }
  throw new Error('no such type');
};

export const getChat = (vm) => vm.chat;
const getVideoChat = (vm) => vm.videoChat;
export const getHandler = (vm) => vm.handler;
export const getType = (vm) => vm.type;
export const getUser = (vm) => vm.user;
export const getIsActive = (vm) => vm.isActive;
export const getUserTo = (vm) => vm.userTo;
export const getIsDemo = (vm) => vm.isDemo;
const getUserRole = (vm) =>
  getRole({ user: getUser(vm), chat: getChat(vm), isActive: getIsActive(vm) });

const isTopic = (vm) => getType(vm) === 'Topic';
const isPurchaseRequestChannel = (vm) =>
  getChat(vm).type === PURCHASE_REQUESTS_CHANNEL;
export const isShowFilter = (vm) =>
  isPurchaseRequestChannel(vm) && vm.isSubscribed;
export const isShowUnsubscribe = (vm) =>
  isPurchaseRequestChannel(vm) && vm.isSubscribed;
export const isUnsubscribedPRChat = (vm) =>
  isPurchaseRequestChannel(vm) && !vm.isSubscribed;

export const hasPermissionToAddPeople = (vm) =>
  havePermission('add-members', getUserRole(vm), getPermissions(getChat(vm)));
const hasPermissionToEdit = (vm) =>
  havePermission('edit', getUserRole(vm), getPermissions(getChat(vm)));
const hasPermissionToCloseTopic = (vm) =>
  isTopic(vm) &&
  havePermission('close-topic', getUserRole(vm), getPermissions(getChat(vm)));
const makeExtraListConditions = (chat, isDemo) => ({
  'archived?': chat.archived ? 'unarchive' : 'archive',
  'downloadEnabled?': isDemo
    ? 'download-chat-history-disable'
    : 'download-chat-history'
});
export const isNotificationBtnChoosen = (vm) =>
  getChat(vm).notificationOff ? 'bell-off' : 'bell-on';
export const getVideoChatValue = (vm) => {
  const videoChat = getVideoChat(vm);

  return videoChat && videoChat.chatId === getChat(vm).id
    ? 'active-video-call'
    : 'inactive-video-call';
};
export const isCallDropdownDisabled = (vm) => {
  const { isBlockedContact, isBlockedMe } = getUserTo(vm);
  return isBlockedContact || isBlockedMe;
};
export const getCallListConditions = (vm, callType) => {
  const { skypeLogin, viberLogin } = getUserTo(vm);
  const positiveAnswer = getPositiveAnswer(callType);

  return {
    'skype?': skypeLogin ? positiveAnswer.skype : 'skype-inactive',
    'viber?': viberLogin ? positiveAnswer.viber : 'viber-inactive'
  };

  function getPositiveAnswer(type) {
    const base = { skype: 'call-skype', viber: 'call-viber' };
    if (type === 'call-toggle') return base;
    if (type === 'video-toggle') {
      return { skype: `video-${base.skype}`, viber: `video-${base.viber}` };
    }
    throw new Error('no such call');
  }
};

function hideButtons(buttonsList, hideList = []) {
  return buttonsList.filter((item) => !hideList.includes(item.value));
}

const hiddenForInactive = ['view', 'edit'];
function hideButtonsForInactiveMember(buttonsList) {
  return hideButtons(buttonsList, hiddenForInactive);
}

const hiddenForUnsubscribedPRChat = ['unsubscribe-purchase-requests'];
function hideButtonsForUnsubscribedPRChat(buttonsList) {
  return hideButtons(buttonsList, hiddenForUnsubscribedPRChat);
}

const modifyExtraMenuList = (vm, extraList) => {
  let result = extraList;

  if (!getIsActive(vm)) {
    result = hideButtonsForInactiveMember(result);
  }

  if (isUnsubscribedPRChat(vm)) {
    result = hideButtonsForUnsubscribedPRChat(result);
  }

  if (getType(vm) === 'Channel') {
    const role = getUserRole(vm);
    return filterMenu(channelPermissions, role, result);
  }

  if (getType(vm) === 'Topic') {
    const role = getUserRole(vm);
    return filterMenu(topicPermissions, role, result);
  }

  return result;
};

export const getExtraMenuList = (vm) => {
  const extraList = makeExtraList(getChat(vm), {
    edit: hasPermissionToEdit(vm),
    closeTopic: hasPermissionToCloseTopic(vm)
  });

  return modifyExtraMenuList(vm, extraList);
};

export const getExtraMenuListConditions = (vm) =>
  makeExtraListConditions(getChat(vm), getIsDemo(vm));

export const initHandler = (vm, handlerName) => (value, params) =>
  getHandler(vm)(handlerName || value, getChat(vm), params);
