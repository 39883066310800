exports.sendDelayedMessageCase = async (
  {
    getOpenedChannelId,
    getPlannedTime,
    sendCreateDelayedMessageCMD,
    showDelayedMessages
  },
  { data }
) => {
  const channelId = getOpenedChannelId();
  const time = getPlannedTime();

  sendCreateDelayedMessageCMD({ channelId, time, data });

  showDelayedMessages();
};
