import React from 'react';
import { Button } from 'react-bootstrap';
import ButtonIcon from '../../ButtonIcon';
import { useTranslate } from '../../../../TranslateProvider';
import './style.css';

const ChatConfirm = ({
  subtype,
  text,
  acceptTKey,
  rejectTKey,
  onReject,
  onAccept,
  onClose,
  onSendToRework,
  reworkTKey
}) => {
  const t = useTranslate();

  return (
    <div className={`chat-confirm chat-confirm__theme-${subtype}`}>
      {text ? <div className="chat-confirm__text">{text}</div> : null}
      <Button
        type="button"
        bsStyle="default"
        onClick={onReject}
        className="btn-cancel">
        {t(rejectTKey)}
      </Button>
      {!!onSendToRework && (
        <Button type="button" bsStyle="default" onClick={onSendToRework}>
          {t(reworkTKey)}
        </Button>
      )}
      <Button type="button" bsStyle="primary" onClick={onAccept}>
        {t(acceptTKey)}
      </Button>
      {onClose ? (
        <div className="chat-confirm__cross">
          <ButtonIcon
            icon="cross"
            tooltip="close"
            width={14}
            height={14}
            onClick={onClose}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ChatConfirm;
