import React from 'react';
import IconSimpleCross from '../../../icons/cross';
import { useTranslate } from '../../../TranslateProvider';
import { getNameString } from '../helper';

const Tag = ({ category, onDelete, disabled }) => {
  if (!category) {
    return null;
  }

  const t = useTranslate();
  return (
    <div className="category-select-tag-wrapper">
      <div className="category-select-tag">
        {getNameString(category.name, t)}
      </div>
      {!disabled && (
        <div onClick={onDelete(category.id)} style={{ display: 'flex' }}>
          <IconSimpleCross />
        </div>
      )}
    </div>
  );
};

export default Tag;
