import React from 'react';

/* eslint-disable max-len */
const IconBack = () => (
  <svg
    width="16"
    height="48"
    viewBox="0 0 16 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99997 24L11.3033 18.6967L10.5962 17.9896L4.58576 24L10.5962 30.0104L11.3033 29.3033L5.99997 24Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconBack;
