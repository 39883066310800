import React, { useEffect, useRef } from 'react';

import './style.css';

const PopupSmall = ({
  content,
  top = 20,
  isOpened,
  setIsOpened = () => {},
  style = {},
  testIdWrapper,
  testIdContent
}) => {
  const ref = useRef();

  function handlerClickOutside(event) {
    if (ref && !ref.current?.contains(event.target) && isOpened) {
      setIsOpened(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handlerClickOutside);

    return () => document.removeEventListener('click', handlerClickOutside);
  }, [isOpened]);

  return (
    <div
      style={{ position: 'relative', padding: 0 }}
      ref={ref}
      data-testid={testIdWrapper}>
      {isOpened && (
        <div
          style={{ ...style, top }}
          className="popup-small-content"
          data-testid={testIdContent}>
          {content}
        </div>
      )}
    </div>
  );
};

export default PopupSmall;
