import React from 'react';
import { getAttachText } from './dataType';

const Attaches = ({ vm }) => (
  <div className="cws-chat-last-message__attach">
    <img
      className="cws-chat-last-message__attach-icon"
      src="/img/chat-widget/attach.svg"
      alt="file"
    />
    <div className="cws-chat-last-message__attach-text">
      {getAttachText(vm)}
    </div>
  </div>
);

export default Attaches;
