import React, { useEffect, useMemo, useState } from 'react';
import {
  InputCounter,
  SearchInput,
  Button,
  useWizard
} from '@link/react-components';
import { useList, useTranslate, useDebounce } from '@hooks';
import { Container, Content, Footer, Header } from '../../layout';
import { getTranslate } from '../../../utils';
import { Row } from '../../row';
import { NoSearchResult } from '../../noSearchResult';
import { useGroupActions } from '../../../hooks';
import { config } from '../../../config';

export function EditGroup() {
  const t = useTranslate();
  const { getState, previous, setState } = useWizard();

  const { currentGroup, companies: allCompanies } = getState();

  const [search, setSearch] = useState('');
  const [title, setTitle] = useState(currentGroup.name || '');
  const [titleError, setTitleError] = useState(null);
  const [selectedCompanies, selectedCompaniesActions] = useList(
    currentGroup.companiesList || []
  );
  const [companies, setCompanies] = useState(allCompanies || []);

  const { loading, edit } = useGroupActions();

  const onSearchCompanies = useDebounce((searchValue) => {
    const searchRegExp = new RegExp(searchValue, 'i');
    const selectedCompanies = allCompanies.filter((company) =>
      searchRegExp.test(`${company.label}`)
    );
    setCompanies(selectedCompanies);
  }, config.searchDebounce);

  useEffect(() => {
    onSearchCompanies(search);
  }, [search]);

  useEffect(() => {
    setTitleError(!title.trim() && selectedCompanies.length > 0);
  }, [selectedCompanies, title]);

  function translate(tKey, params) {
    return getTranslate(t, `editGroup.${tKey}`, params);
  }

  async function onSubmit() {
    const updatedGroup = {
      ...currentGroup,
      name: title,
      companiesList: selectedCompanies
    };
    await edit(updatedGroup);
    previous();
  }

  function getHint() {
    if (selectedCompanies.length < config.minCompaniesInGroup) {
      return translate('hints.notEnoughCompanies');
    }
  }

  function isCompanyDisabled(selectedCompaniesList, companyId) {
    const selectedCount = selectedCompaniesList.length;

    return selectedCompaniesList.includes(companyId)
      ? false
      : selectedCount >= config.maxCompaniesInGroup;
  }

  function disableSubmit() {
    return (
      title.trim().length < config.minGroupNameLength ||
      selectedCompanies.length < config.minCompaniesInGroup
    );
  }

  useEffect(() => {
    setState({ selectedCompanies });
  }, [selectedCompanies, setState]);

  return (
    <Container>
      <Header>
        <InputCounter
          value={title}
          onChange={setTitle}
          label={translate('inputs.title.label')}
          valid={!titleError}
          total={config.maxGroupNameLength}
        />
        <SearchInput
          defaultValue={search}
          onSearch={setSearch}
          onChange={setSearch}
          onCleanUp={() => setSearch('')}
          placeholder={translate('inputs.search.placeholder')}
        />
      </Header>
      <Content>
        {Boolean(!companies.length && search) && (
          <NoSearchResult tKey="editGroup" />
        )}
        {useMemo(
          () =>
            companies.map((company) => (
              <Row
                key={company.value}
                value={company.value}
                checked={selectedCompanies.includes(company.value)}
                onToggle={selectedCompaniesActions.toggle}
                label={company.label}
                checkDisabled={isCompanyDisabled(
                  selectedCompanies,
                  company.value
                )}
              />
            )),
          [companies, selectedCompanies]
        )}
      </Content>
      <Footer hint={getHint()}>
        <Button
          mode="primary"
          loading={loading}
          disabled={disableSubmit()}
          onClick={onSubmit}>
          {translate('actions.submit')}
        </Button>
      </Footer>
    </Container>
  );
}
