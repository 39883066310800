import React from 'react';
import PropTypes from 'prop-types';
import Close from '../icons/close';

const DocxViewerHeader = ({ hideModalDialog }) => (
  <div className="docx-viewer-header__container">
    <div className="docx-viewer-header__button-close" onClick={hideModalDialog}>
      <Close />
    </div>
  </div>
);

DocxViewerHeader.propTypes = {
  hideModalDialog: PropTypes.func.isRequired
};

export default DocxViewerHeader;
