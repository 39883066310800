import React, { forwardRef } from 'react';
import { Modal } from 'react-bootstrap';

import { Container, Header, Body } from '../../../Components';
import SearchBlockDefault from '../../../../../SearchBlock/SearchBlockDefault';
import NotFound from './NotFound';

const TemplateModal = forwardRef(
  ({ onHide, title, placeholder, onSearch, width, isShowItems, rows }, ref) => (
    <Modal onHide={onHide} show>
      <Container>
        <Header title={title} onClose={onHide} />
        <Body>
          <SearchBlockDefault
            placeholder={placeholder}
            onSubmit={onSearch}
            width={width}
          />
          <div id="modal-list" ref={ref} className="cw-modal-list__container">
            {isShowItems ? rows : <NotFound />}
          </div>
        </Body>
      </Container>
    </Modal>
  )
);

export default TemplateModal;
