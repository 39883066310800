import { useCallback, useMemo, useState } from 'react';

export function useList(initialList = []) {
  const [list, setList] = useState(initialList);

  const toggle = useCallback((value) => {
    setList((prevState) => {
      const newState = new Set(prevState);

      if (newState.has(value)) {
        newState.delete(value);
      } else {
        newState.add(value);
      }

      return Array.from(newState);
    });
  }, []);

  const push = useCallback((value) => {
    setList((prevState) => [...prevState, value]);
  }, []);

  const actions = useMemo(
    () => ({
      set: setList,
      push,
      toggle
    }),
    [setList, toggle]
  );

  return [list, actions];
}
