import request from '../lib/request';

export const EmailStatuses = {
  Failed: 'failed',
  Processed: 'processed',
  Delivered: 'delivered',
  Opened: 'opened',
  Clicked: 'clicked'
};

class RequestService {
  url = '/api/request/emails/statuses/';

  async getEmailsByRequestId(requestId) {
    return request.get(this.url + requestId);
  }
}

export const requestEmailsApi = new RequestService();
