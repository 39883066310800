import React, { useCallback, useMemo, useState } from 'react';
import block from 'bem-cn-lite';
import { IMaskInput } from 'react-imask';
import { format } from 'date-fns';
import IMask from 'imask/esm/imask';
import { nanoid } from 'nanoid';
import { isNil } from 'ramda';

const b = block('datepicker-goodwix');

const iMaskBlockOption = {
  d: {
    mask: IMask.MaskedRange,
    placeholderChar: 'D',
    from: 1,
    to: 31,
    maxLength: 2
  },
  m: {
    mask: IMask.MaskedRange,
    placeholderChar: 'M',
    from: 1,
    to: 12,
    maxLength: 2
  },
  Y: {
    mask: IMask.MaskedRange,
    placeholderChar: 'Y',
    from: 1900,
    to: 2999,
    maxLength: 4
  }
};

const parseDateMask = (str, date) => {
  const [day, month, year] = str.split('.');

  let hh;
  let mm;

  if (!isNil(date)) {
    hh = new Date(date).getHours();
    mm = new Date(date).getMinutes();
  }

  return new Date(year, month - 1, day, hh, mm);
};

const DateInput = ({ t, date, setDate, minDate, maxDate, dateTkey }) => {
  const [isActive, setActive] = useState(false);

  const formattedDate = useMemo(
    () => (date ? format(date, 'dd.MM.yyyy') : ''),
    [date]
  );

  const clearDate = useCallback((value) => {
    if (Number.isNaN(+value[0])) {
      setDate(null);
    }
  }, []);

  const onComplete = useCallback(
    (value) => {
      setDate(parseDateMask(value, date));
    },
    [date]
  );

  const onFocus = useCallback(() => {
    setActive(true);
  }, []);

  const onBlur = useCallback(() => {
    setActive(false);
  }, []);

  const inputId = useMemo(() => `datepicker-time-${nanoid()}`, []);

  return (
    <div className={b('date', { active: isActive })}>
      <label htmlFor={inputId} className={b('input-label')}>
        {t(dateTkey)}
      </label>
      <IMaskInput
        id={inputId}
        mask={Date}
        overwrite
        autofix
        lazy={false}
        blocks={iMaskBlockOption}
        className={`datepicker-goodwix__input ${!formattedDate ? 'empty' : ''}`}
        onAccept={clearDate}
        onComplete={onComplete}
        min={minDate}
        max={maxDate}
        value={formattedDate}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};

export default DateInput;
