const { path, assocPath, curry } = require('ramda');
const { defStruct } = require('../../lib');

/**
 * @typedef UserAccessRoles
 * @typedef {{ read: boolean, write: boolean, delete: boolean }} UserAccessDB
 */
const { makeUserAccessRoles, getList, isUserAccessRoles } = defStruct(
  'UserAccessRoles',
  ['list']
);

const setRole = (role, section, userAccessRoles) =>
  makeUserAccessRoles(assocPath([section], role, getList(userAccessRoles)));

/**
 * Creates a user access
 *
 * @returns {UserAccessRoles}
 */
exports.makeUserAccessRoles = () => makeUserAccessRoles({});

/**
 * Checks if is a user access
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isUserAccessRoles = (a) => isUserAccessRoles(a);

/**
 * Gives a role name
 *
 * @param {string} section
 * @param {UserAccessRoles} userAccessRoles
 * @returns {string}
 */
exports.getRole = (section, userAccessRoles) =>
  path([section], getList(userAccessRoles));

/**
 * Sets a no role of user access by section and use case names
 *
 * @param {string} section
 * @param {UserAccessRoles} userAccessRoles
 * @returns {UserAccessRoles}
 */
const setNoRole = (section, userAccessRoles) =>
  setRole('no', section, userAccessRoles);
exports.setNoRole = setNoRole;

/**
 * Sets a reader role of user access by section and use case names
 *
 * @param {string} section
 * @param {UserAccessRoles} userAccessRoles
 * @returns {UserAccessRoles}
 */
const setReaderRole = (section, userAccessRoles) =>
  setRole('reader', section, userAccessRoles);
exports.setReaderRole = setReaderRole;

/**
 * Sets a writer role of user access by section and use case names
 *
 * @param {string} section
 * @param {UserAccessRoles} userAccessRoles
 * @returns {UserAccessRoles}
 */
const setWriterRole = (section, userAccessRoles) =>
  setRole('writer', section, userAccessRoles);
exports.setWriterRole = setWriterRole;

/**
 * Sets a advanced role of user access by section and use case names
 *
 * @param {string} section
 * @param {UserAccessRoles} userAccessRoles
 * @returns {UserAccessRoles}
 */
const setAdvancedRole = (section, userAccessRoles) =>
  setRole('advanced', section, userAccessRoles);
exports.setAdvancedRole = setAdvancedRole;

/**
 * Sets a admin role of user access by section and use case names
 *
 * @param {string} section
 * @param {UserAccessRoles} userAccessRoles
 * @returns {UserAccessRoles}
 */
exports.setAdminRole = (section, userAccessRoles) =>
  setRole('admin', section, userAccessRoles);

/**
 * Sets role from user access db
 *
 * @param {string} section
 * @param {UserAccessDB} userAccessDB
 * @param {UserAccessRoles} userAccessRoles
 * @returns {UserAccessRoles}
 */
exports.setRoleFromDB = curry((section, userAccessDB, userAccessRoles) => {
  if (userAccessDB.delete === true)
    return setAdvancedRole(section, userAccessRoles);
  if (userAccessDB.write === true)
    return setWriterRole(section, userAccessRoles);
  if (userAccessDB.read === true)
    return setReaderRole(section, userAccessRoles);
  return setNoRole(section, userAccessRoles);
});
