import { isFunction } from './utils';

export const initTranslateComponent =
  (t, translatePath = '') =>
  (tkey, params = {}) => {
    if (!isFunction(t)) {
      throw new Error(
        `Received invalid params t, waiting function but got: ${t}`
      );
    }

    return !translatePath
      ? t(tkey, params)
      : t(`${translatePath}.${tkey}`, params);
  };
