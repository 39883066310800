import React from 'react';
import { isType } from 'core/lib';
import AttachFilesInfo from '../attach-files-info';

const Attachs = ({ lastMessage }) => {
  const message = lastMessage;

  if (
    lastMessage.hidden ||
    !(
      isType('MsgUser', message) ||
      isType('MsgForwarded', message) ||
      isType('MsgReply', message)
    )
  )
    return null;

  return <AttachFilesInfo files={message.files} />;
};

export default Attachs;
