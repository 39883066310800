import { useCallback, useMemo, useRef, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { useScroll } from './useScroll';
import { useMeasureRect } from './useMeasureRect';
import { ScrollMods } from '../constants';
import { findNearest } from '../utils/findNearest';
import { clamp } from '../utils/clamp';

const ZERO_OFFSET = {
  left: 0,
  top: 0
};

const ZERO_RECT = {
  height: 0,
  width: 0
};

function calculateRange(scrollMode, measurements, outerSize, scrollOffset) {
  let currentOffset = 0;

  switch (scrollMode) {
    case ScrollMods.Horizontal:
      currentOffset = scrollOffset.left;
      break;
    case ScrollMods.Vertical:
    default:
      currentOffset = scrollOffset.top;
      break;
  }

  const size = measurements.length - 1;

  const getOffset = (index) => {
    switch (scrollMode) {
      case ScrollMods.Horizontal:
        return measurements[index].start.left;
      case ScrollMods.Wrapped:
      case ScrollMods.Vertical:
      default:
        return measurements[index].start.top;
    }
  };

  let start = findNearest(0, size, currentOffset, getOffset);
  if (scrollMode === ScrollMods.Wrapped) {
    const startTop = measurements[start].start.top;
    while (
      start - 1 >= 0 &&
      measurements[start - 1].start.top === startTop &&
      measurements[start - 1].start.left >= scrollOffset.left
    ) {
      start--;
    }
  }

  let end = start;
  const visibilities = {};
  let maxVisibilityIndex = start;
  let maxVisibility = -1;

  while (end <= size) {
    const itemRect = measurements[end].size;
    const visibility = {
      width: 0,
      height: 0
    };
    const topLeftCorner = {
      top: measurements[end].start.top - scrollOffset.top,
      left: measurements[end].start.left - scrollOffset.left
    };
    const visibleSize = {
      height: outerSize.height - topLeftCorner.top,
      width: outerSize.width - topLeftCorner.left
    };

    if (scrollMode === ScrollMods.Horizontal && visibleSize.width < 0) {
      end--;
      break;
    }

    if (scrollMode === ScrollMods.Vertical && visibleSize.height < 0) {
      end--;
      break;
    }

    if (
      scrollMode === ScrollMods.Wrapped &&
      (visibleSize.width < 0 || visibleSize.height < 0)
    ) {
      end--;
      break;
    }

    if (scrollMode === ScrollMods.Vertical) {
      visibility.width = 1;
    } else if (topLeftCorner.left < 0) {
      const visibleWidth = itemRect.width - -topLeftCorner.left;
      visibility.width =
        visibleWidth <= outerSize.width ? visibleWidth / outerSize.width : 1;
    } else {
      visibility.width =
        itemRect.width <= visibleSize.width
          ? 1
          : visibleSize.width / itemRect.width;
    }

    if (scrollMode === ScrollMods.Horizontal) {
      visibility.height = 1;
    } else if (topLeftCorner.top < 0) {
      const visibleHeight = itemRect.height - -topLeftCorner.top;
      visibility.height =
        visibility.height <= outerSize.height
          ? visibleHeight / outerSize.height
          : 1;
    } else {
      visibility.height =
        itemRect.height <= visibleSize.height
          ? 1
          : visibleSize.height / itemRect.height;
    }

    visibilities[end] = visibility.width * visibility.height;
    if (maxVisibility < visibilities[end]) {
      maxVisibility = visibilities[end];
      maxVisibilityIndex = end;
    }
    end++;
  }

  return {
    start,
    end,
    maxVisibilityIndex,
    visibilities
  };
}

export const useVirtual = ({
  estimateSize,
  isRtl,
  numberOfItems,
  setStartRange,
  setEndRange,
  scrollMode,
  parentRef,
  transformSize
}) => {
  const { scrollTo, scrollOffset } = useScroll({
    ref: parentRef,
    isRtl,
    scrollMode
  });
  const parentRect = useMeasureRect(parentRef);

  const latestRef = useRef({
    scrollOffset: ZERO_OFFSET,
    measurements: [],
    parentRect: ZERO_RECT,
    totalSize: ZERO_RECT
  });
  latestRef.current.scrollOffset = scrollOffset;
  latestRef.current.parentRect = parentRect;

  const resizeObserversRef = useRef(new Map());
  const [cacheMeasure, setCacheMeasure] = useState({});

  const measurements = useMemo(() => {
    const listMeasurements = [];

    let totalWidth = 0;
    let firstOfRow = {
      left: 0,
      top: 0
    };
    let maxHeight = 0;
    for (let i = 0; i < numberOfItems; i++) {
      const size = cacheMeasure[i] || transformSize(estimateSize(i));
      let start = ZERO_OFFSET;

      if (i === 0) {
        totalWidth = size.width;
        firstOfRow = {
          left: 0,
          top: 0
        };
        maxHeight = size.height;
      } else {
        switch (scrollMode) {
          case ScrollMods.Wrapped:
            totalWidth += size.width;
            if (totalWidth < parentRect.width) {
              start = {
                left: listMeasurements[i - 1].end.left,
                top: firstOfRow.top
              };
              maxHeight = Math.max(maxHeight, size.height);
            } else {
              totalWidth = size.width;
              start = {
                left: firstOfRow.left,
                top: firstOfRow.top + maxHeight
              };
              firstOfRow = {
                left: start.left,
                top: start.top
              };
              maxHeight = size.height;
            }
            break;
          case ScrollMods.Horizontal:
          case ScrollMods.Vertical:
          default:
            start = listMeasurements[i - 1].end;
            break;
        }
      }

      const end = {
        left: start.left + size.width,
        top: start.top + size.height
      };

      listMeasurements[i] = {
        index: i,
        start,
        end,
        size,
        visibility: -1
      };
    }

    return listMeasurements;
  }, [estimateSize, scrollMode, parentRect, cacheMeasure, transformSize]);

  const totalSize = measurements[numberOfItems - 1]
    ? {
        height: measurements[numberOfItems - 1].end.top,
        width: measurements[numberOfItems - 1].end.left
      }
    : ZERO_RECT;
  latestRef.current.measurements = measurements;
  latestRef.current.totalSize = totalSize;

  const { maxVisibilityIndex, visibilities, start, end } = calculateRange(
    scrollMode,
    latestRef.current.measurements,
    latestRef.current.parentRect,
    latestRef.current.scrollOffset
  );

  const startRange = setStartRange(start);
  const endRange = setEndRange(end);

  const virtualItems = useMemo(() => {
    const virtualItemsList = [];

    for (let i = startRange; i <= endRange; i++) {
      const current = measurements[i];
      const virtualItem = {
        ...current,
        visibility: visibilities[i] !== undefined ? visibilities[i] : -1,
        measureRef: (elem) => {
          if (!elem) {
            return;
          }
          new ResizeObserver(([{ target }], tracker) => {
            const rect = target.getBoundingClientRect();
            if (!rect) {
              tracker.disconnect();
              resizeObserversRef.current.delete(target);
              return;
            }

            const measuredSize = transformSize({
              height: rect.height,
              width: rect.width
            });

            if (resizeObserversRef.current.get(target)) {
              resizeObserversRef.current.get(target).disconnect();
            }
            resizeObserversRef.current.set(target, tracker);

            if (
              measuredSize.height !== current.size.height ||
              measuredSize.width !== current.size.width
            ) {
              setCacheMeasure((prev) => ({ ...prev, [i]: measuredSize }));
            }
          }).observe(elem);
        }
      };
      virtualItemsList.push(virtualItem);
    }

    return virtualItemsList;
  }, [visibilities, measurements, transformSize]);

  const scrollToItem = useCallback(
    (index, offset) => {
      const measurement =
        latestRef.current.measurements[clamp(0, numberOfItems - 1, index)];
      if (measurement) {
        scrollTo({
          left: offset.left + measurement.start.left,
          top: offset.top + measurement.start.top
        });
      }
    },
    [scrollTo]
  );

  const getContainerStyles = useCallback(() => {
    switch (scrollMode) {
      case ScrollMods.Horizontal:
        return {
          position: 'relative',
          height: '100%',
          width: `${totalSize.width}px`
        };
      case ScrollMods.Vertical:
      default:
        return {
          position: 'relative',
          height: `${totalSize.height}px`,
          width: '100%'
        };
    }
  }, [scrollMode, totalSize]);

  const getItemStyles = useCallback(
    (item) => {
      const sideProperty = isRtl ? 'right' : 'left';
      const factor = isRtl ? -1 : 1;

      switch (scrollMode) {
        case ScrollMods.Horizontal:
          return {
            height: '100%',
            width: `${item.size.width}px`,
            position: 'absolute',
            top: 0,
            [sideProperty]: 0,
            transform: `translateX(${item.start.left * factor}px)`
          };
        case ScrollMods.Wrapped:
          return {
            height: `${item.size.height}px`,
            width: `${item.size.width}px`,
            position: 'absolute',
            top: 0,
            [sideProperty]: 0,
            transform: `translate(${item.start.left * factor}px, ${
              item.start.top
            }px)`
          };
        case ScrollMods.Vertical:
        default:
          return {
            height: `${item.size.height}px`,
            width: `100%`,
            position: 'absolute',
            top: 0,
            [sideProperty]: 0,
            transform: `translateY(${item.start.top}px)`
          };
      }
    },
    [isRtl, scrollMode]
  );

  useIsomorphicLayoutEffect(() => () => {
    resizeObserversRef.current.forEach((tracker) => tracker.disconnect());
    resizeObserversRef.current.clear();
  });

  return {
    startIndex: start,
    endIndex: end,
    startRange,
    endRange,
    virtualItems,
    maxVisibilityIndex,
    getContainerStyles,
    getItemStyles,
    scrollToItem
  };
};
