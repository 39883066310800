import { EditorState } from 'draft-js';
import { convertFromHTML } from 'draft-convert';

export function getPlainText(es) {
  return es.getCurrentContent().getPlainText();
}

export function setMessage(es, msg) {
  return moveFocusToEnd(fromHTML(es, msg));
}

export function moveFocusToEnd(es) {
  return EditorState.moveFocusToEnd(es);
}

export function fromHTML(es, html) {
  const options = {
    htmlToEntity: (nodeName, node, createEntity) => {
      if (nodeName === 'span') {
        if (node.dataset.type === 'mention') {
          const { employeeId, avatar } = node.dataset;
          const mention = {
            name: node.innerText,
            employeeId,
            avatar
          };
          return createEntity('mention', 'IMMUTABLE', { mention });
        }
      }
      return null;
    }
  };
  return createWithContent(es, convertFromHTML(options)(html));
}

function createWithContent(es, cs) {
  return EditorState.createWithContent(cs, es.getDecorator());
}
