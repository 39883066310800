import React from 'react';
import PropTypes from 'prop-types';

const CounterText = ({ text, maxLength }) => (
  <h6 className="pull-right">
    <small>{`${text.length}/${maxLength}`}</small>
  </h6>
);

CounterText.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired
};

export default CounterText;
