import React from 'react';
import { useTranslate } from '../../TranslateProvider';
import MainContent from './elements/MainContent';
import { GridPageThemeDefault } from '../GridPage';
import { GridContentThemeDefault } from '../GridContent';
import ContainersDefault from '../Containers/ContainersDefault';

const MainSingleProduct = () => {
  const t = useTranslate();
  const url =
    'https://docs.google.com/forms/d/e/1FAIpQLSe8JLbp7oeSbgaQxeHKrjzHqd0nR16amjC6ZHNXkV9-SMaBWA/viewform?embedded=true'; // eslint-disable-line

  return (
    <GridPageThemeDefault
      pageDetails={{ title: t('Send us a message') }}
      content={
        <GridContentThemeDefault
          content={
            <MainContent>
              <ContainersDefault>
                <div style={{ overflowY: 'auto', height: '100%' }}>
                  <iframe
                    title="loading"
                    style={{ border: 0 }}
                    src={url}
                    width="100%"
                    height="1100px"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0">
                    Loading...
                  </iframe>
                </div>
              </ContainersDefault>
            </MainContent>
          }
        />
      }
    />
  );
};

export default MainSingleProduct;
