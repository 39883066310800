import {
  initState,
  setSuppliers,
  selectSupplier,
  setSeletedGroup,
  clearSelectedGroup,
  deselectSuppliers,
  setRequests,
  setSelectedItem,
  setGroups,
  setInvites,
  addInviteOrRequest,
  deleteInvites,
  deleteRequests,
  deleteGroup,
  updateGroup,
  addGroup,
  setAdditionRequests,
  removeAdditionRequest,
  setSelectedRequests,
  toggleLink,
  setToken
} from '../work-with-data/suppliers';

const setCount = (data, { suppliersCount }) =>
  data.set('suppliersCount', suppliersCount);

const removeSuppliers = (data, { suppliers, size, totalSize }) =>
  data
    .update('suppliers', (items) =>
      items.filter((item) => !suppliers.includes(item.get('id')))
    )
    .set('size', size)
    .set('totalSize', totalSize);

const removeSupplier = (data, { supplierId, currentGroupId }) => {
  if (!currentGroupId) return data;
  return data.update('suppliers', (items) =>
    items.filter((item) => item.get('id') !== supplierId)
  );
};

const selectAll = (data, { items }) => {
  const itemIds = items.map((item) => item.get('id'));
  return data.update('selected', (selected) => {
    if (selected.equals(new Set(itemIds))) {
      return new Set();
    }
    return selected.merge(new Set(itemIds));
  });
};

const removeGroupFromSupplier = (data, { suppliers, groupId }) =>
  data.update('suppliers', (items) => {
    let newItems = items;
    for (const supplierId of suppliers) {
      // eslint-disable-line
      const index = items.findIndex((item) => item.get('id') === supplierId);
      newItems = newItems.updateIn([index, 'groups'], (groups) =>
        groups.filter((group) => group !== groupId)
      );
    }
    return newItems;
  });

const addGroupToSupplier = (data, { suppliers, groupId }) =>
  data.update('suppliers', (items) => {
    let newItems = items;
    for (const supplierId of suppliers) {
      // eslint-disable-line
      const index = items.findIndex((item) => item.get('id') === supplierId);
      newItems = newItems.updateIn([index, 'groups'], (groups) => {
        if (!groups.includes(groupId)) {
          return groups.push(groupId);
        }
        return groups;
      });
    }
    return newItems;
  });

const setResponsible = (data, { supplierId, userId }) => {
  const index = data
    .get('suppliers')
    .findIndex((i) => i.get('id') === supplierId);
  return data.setIn(['suppliers', index, 'responsibleUser'], userId);
};

export default (state = initState(), { type, payload }) => {
  switch (type) {
    case 'SUPPLIERS:GET_SUPPLIERS:SUCCESS':
      return setSuppliers(state, payload);
    case 'SUPPLIERS:SELECT_SUPPLIERS':
      return selectSupplier(state, payload);
    case 'SUPPLIERS:SELECT_GROUP':
      return setSeletedGroup(state, payload);
    case 'SUPPLIERS:CLEAR_SELECT_GROUP':
      return clearSelectedGroup(state);
    case 'DESELECT_ALL_SUPPLIERS':
      return deselectSuppliers(state);
    case 'SUPPLIERS:GET_REQUESTS:SUCCESS':
      return setRequests(state, payload);
    case 'SUPPLIERS:SELECT_ITEM':
      return setSelectedItem(state, payload);
    case 'SUPPLIERS:GET_GROUPS:SUCCESS':
      return setGroups(state, payload);
    case 'SUPPLIERS:GET_INVITES:SUCCESS':
      return setInvites(state, payload);
    case 'SUPPLIERS:ADD:SUCCESS':
      return addInviteOrRequest(state, payload);
    case 'SUPPLIERS:DELETE_REQUESTS:SUCCESS':
      return deleteRequests(state, payload);
    case 'SUPPLIERS:DELETE_INVITES:SUCCESS':
      return deleteInvites(state, payload);
    case 'SUPPLIERS:GROUP_DELETE:SUCCESS':
      return deleteGroup(state, payload);
    case 'SUPPLIERS:GROUP_EDIT:SUCCESS':
      return updateGroup(state, payload);
    case 'SUPPLIERS:MOVE:SUCCESS':
      return removeSupplier(state, payload);
    case 'SUPPLIERS:CREATE_GROUP:SUCCESS':
      return addGroup(state, payload);
    case 'SUPPLIERS:GET_ADDITION_REQUESTS:SUCCESS':
      return setAdditionRequests(state, payload);
    case 'SUPPLIERS:CONFIRM_ADD:SUCCESS':
      return removeAdditionRequest(state, payload);
    case 'SUPPLIERS:REJECT_ADD:SUCCESS':
      return removeAdditionRequest(state, payload);
    case 'SUPPLIERS:SELECT_REQUEST':
      return setSelectedRequests(state, payload);
    case 'SUPPLIERS:GET_SUPPLIERS_COUNT:SUCCESS':
      return setCount(state, payload);
    case 'SUPPLIERS:DELETE:SUCCESS':
      return removeSuppliers(state, payload);
    case 'SUPPLIERS:SELECT:ALL':
      return selectAll(state, payload);
    case 'SUPPLIERS:GROUP_REMOVE:SUCCESS':
      return removeGroupFromSupplier(state, payload);
    case 'SUPPLIERS:GROUP_ADD:SUCCESS':
      return addGroupToSupplier(state, payload);
    case 'SUPPLIERS:SET_RESPONSIBLE:SUCCESS':
      return setResponsible(state, payload);
    case 'SUPPLIERS:TOGGLE_LINK:SUCCESS':
      return toggleLink(state, payload);
    case 'SUPPLIERS:SET_TOKEN':
      return setToken(state, payload);
    default:
      return state;
  }
};
