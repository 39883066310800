import request from '../../../../lib/request';

export const findMessages = (
  chatId,
  { queryText = '', fromDate = null, toDate = null, limit = 20, offset = 0 }
) =>
  request.post(`/api/channel/${chatId}/search-messages`, {
    queryText,
    fromDate,
    toDate,
    limit,
    offset
  });
