import assert from 'assert';
import { is, isNil, type } from 'ramda';

export const ERROR = 'error';

export const isPossibleType = (value, possibleTypes = []) =>
  possibleTypes.some((currType) => is(currType, value));

export const isEmptyField = (fieldValue = '') => {
  assert(
    isPossibleType(fieldValue, [String]),
    `Unexpected type: ${type(fieldValue)}`
  );

  return fieldValue.trim().length === 0;
};

export const isMoreThenMaxLength = (fieldValue, maxLength) => {
  assert(!isNil(maxLength), 'Max length should be passed');
  assert(!isNil(fieldValue), 'Value should be passed');
  assert(
    isPossibleType(maxLength, [Number]),
    `Unexpected type for maxLength: ${type(maxLength)}`
  );
  assert(
    isPossibleType(fieldValue, [String, Array]),
    `Unexpected type: ${type(fieldValue)}`
  );

  return fieldValue.length > maxLength;
};

export const checkFieldValid = (
  fieldValue,
  { maxLength, isPossibleEmpty = false } = {}
) => {
  if (maxLength && isMoreThenMaxLength(fieldValue, maxLength)) {
    return ERROR;
  }

  if (!isPossibleEmpty && isEmptyField(fieldValue)) {
    return ERROR;
  }

  return null;
};
