import { gaSend } from '../../../../action-creators/services';
import { getCurrentUserEmail } from '../../../../storeGetters';

const MESSENGER = 'Messenger';

export const gaSendShowFavorites = (chat) => async (dispatch, getState) => {
  dispatch(
    gaSend({
      category: MESSENGER,
      action: actionName(),
      label: userEmail()
    })
  );

  function userEmail() {
    return getState().getIn(['user', 'user', 'email']);
  }

  function actionName() {
    return `${name()}_favorite_mes`;

    function name() {
      switch (chat.type) {
        case 'Dialog':
          return 'personal_chat';
        case 'Channel':
          return 'group_chat';
        case 'Topic':
          return 'topic';
        case 'PurchaseRequestsChannel':
          return 'purchase_requests_channel';
        default:
          console.error(
            `Not handler for action name with chat type ${chat.type}`
          );
          return chat.type.toLowerCase();
      }
    }
  }
};

export const gaSendMessengerAction = (action) => async (dispatch, getState) => {
  dispatch(
    gaSend({
      category: MESSENGER,
      action,
      label: getCurrentUserEmail(getState())
    })
  );
};
