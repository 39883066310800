import React from 'react';
import { useTranslate } from '../../../../TranslateProvider';
import './style.css';

const CWMainNone = () => {
  const t = useTranslate();

  return (
    <div className="cwmain-none">
      <div className="cwmain-none__common">
        <img
          className="cwmain-none__icon"
          src="/img/chat-widget/message.svg"
          alt="not chosen chat"
        />
        <div className="cwmain-none__msg">
          {t('chat-widget.section.main.choose-chat')}
        </div>
      </div>
      <div className="cwmain-none__input" />
    </div>
  );
};

export default CWMainNone;
