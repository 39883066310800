import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { PrintStatus } from './PrintStatus';
import { PageThumbnailContainer } from './PageThumbnailContainer';

export const PrintZone = ({
  doc,
  numLoadedPages,
  pageHeight,
  pageWidth,
  printStatus,
  onCancel,
  onLoad
}) => {
  const canvas = useMemo(() => document.createElement('canvas'), []);

  const container = useMemo(() => {
    const zoneElem = document.querySelector('.pdf-viewer-print__zone');
    if (zoneElem) {
      return zoneElem;
    }

    const div = document.createElement('div');
    div.classList.add('pdf-viewer-print__zone');
    document.body.appendChild(div);

    return div;
  }, []);

  useEffect(() => {
    if (printStatus === PrintStatus.Ready) {
      document.documentElement.classList.add('pdf-viewer-print__html-printing');
      document.body.classList.add('pdf-viewer-print__body-printing');
      window.print();
    }

    const handler = () => {
      if (printStatus === PrintStatus.Ready) {
        document.documentElement.classList.remove(
          'pdf-viewer-print__html-printing'
        );
        document.body.classList.remove('pdf-viewer-print__body-printing');

        const zones = document.querySelectorAll('.pdf-viewer-print__zone');
        if (zones) {
          zones.forEach((zoneElem) => {
            zoneElem.parentElement.removeChild(zoneElem);
          });
        }

        canvas.height = 0;
        canvas.width = 0;

        document.removeEventListener('mousemove', handler);

        onCancel();
      }
    };
    document.addEventListener('mousemove', handler);

    return () => {
      document.removeEventListener('mousemove', handler);
    };
  }, [printStatus]);

  return createPortal(
    <>
      {Array(Math.min(numLoadedPages + 1, doc.numPages))
        .fill(1)
        .map((_, index) => (
          <PageThumbnailContainer
            key={index}
            canvas={canvas}
            doc={doc}
            pageHeight={pageHeight}
            pageWidth={pageWidth}
            pageIndex={index}
            onLoad={onLoad}
          />
        ))}
      <style
        dangerouslySetInnerHTML={{
          __html: `@page { size: ${pageWidth}pt ${pageHeight}pt`
        }}
      />
    </>,
    container
  );
};
