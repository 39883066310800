import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import './styles.styl';

const b = block('search-block');

class SearchBlockDefault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: ''
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.excecuterEmpty = this.excecuterEmpty.bind(this);
    this.clearQuery = this.clearQuery.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { query = '' } = this.props;
    this.setState({ query });
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.onSubmit(this.query.trim());
  }

  onChange(e) {
    const { onChange, queryLimit } = this.props;
    if (e.target.value.length > queryLimit) {
      e.target.value = this.query; // eslint-disable-line
      return;
    }
    this.query = e.target.value;
    if (onChange) onChange(e.target.value);
  }

  get isDisabled() {
    return !this.props.allowNull && !this.query.length;
  }

  get isQueryEmpty() {
    return !this.query.length;
  }

  set query(query) {
    this.setState({ query });
    if (query === '') {
      this.excecuterEmpty();
    }
  }

  get query() {
    return this.state.query;
  }

  excecuterEmpty() {
    const { onEmpty } = this.props;

    onEmpty();
  }

  clearQuery() {
    this.input.focus();
    this.query = '';
    this.excecuterEmpty();
  }

  render() {
    const { onClick, theme, placeholder, width, iconSize = 18 } = this.props;
    return (
      <div className={b({ type: 'default', theme })} onClick={onClick}>
        <form
          className={b('search')}
          onSubmit={this.onSubmit}
          style={{ width }}>
          <input
            type="text"
            className={b('input', { active: !this.isQueryEmpty })}
            onChange={this.onChange}
            value={this.query}
            placeholder={placeholder}
            ref={(input) => {
              this.input = input;
            }}
          />
          <button
            type="button"
            className={b('btn', {
              submit: true,
              active: !this.isQueryEmpty,
              show: true
            })}
            onClick={this.onSubmit}>
            <img src="/img/magnifier.svg" width={iconSize} alt="search" />
          </button>
          <button
            type="button"
            className={b('btn', {
              reset: true,
              show: !this.isQueryEmpty,
              left: true
            })}
            onClick={this.clearQuery}>
            <img src="/img/cross.svg" width="10" alt="cross" />
          </button>
        </form>
      </div>
    );
  }
}

SearchBlockDefault.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  theme: PropTypes.string,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onEmpty: PropTypes.func,
  query: PropTypes.string,
  queryLimit: PropTypes.number,
  allowNull: PropTypes.bool
};

SearchBlockDefault.defaultProps = {
  theme: 'default',
  width: 300,
  queryLimit: 40,
  placeholder: '',
  query: '',
  allowNull: false,
  onSubmit: () => {},
  onChange: () => {},
  onEmpty: () => {}
};

export default SearchBlockDefault;
