import { Map, List, Set, fromJS } from 'immutable'; // eslint-disable-line

const newItems = () => new Map();
const newCategories = () => new List();
const newSelect = () => new Set();
const newErrorsInfo = () => new Map();
const newSelectedCategories = () => new Set();

export const initState = () =>
  new Map({
    items: newItems(),
    firstUploaded: false,
    categories: newCategories(),
    selectedCategories: newSelectedCategories(),
    category: newSelect(),
    selected: newSelect(),
    errors: newErrorsInfo(),
    currentCategory: Map(),
    size: 0,
    offset: 0,
    limit: 20
  });

export const setProductPositionsCategories = (data, categories) =>
  data
    .remove('categories')
    .set('categories', fromJS(categories))
    .set('firstUploaded', true);

export const setProductPositions = (
  data,
  { productPositions, categoryId, size, offset, limit }
) => {
  let updatedData = data
    .remove('items')
    .setIn(['items', categoryId.toString()], fromJS(productPositions));
  if (size !== undefined && offset !== undefined && limit !== undefined) {
    updatedData = updatedData
      .set('size', size)
      .set('offset', offset)
      .set('limit', limit);
  }
  return updatedData;
};

export const clearTable = (data, categoryId) =>
  data.deleteIn(['items', categoryId]);

export const selectCategory = (data, { id }) =>
  data
    .update(
      'selectedCategories',
      newSelectedCategories(),
      (selectedCategories) =>
        selectedCategories.includes(id)
          ? selectedCategories.remove(id)
          : selectedCategories.add(id)
    )
    .remove('selected')
    .set('selected', newSelect());

export const setSelectCategories = (data, { ids }) =>
  data.set('selectedCategories', Set(ids));

export const clearSelectCategories = (data) =>
  data.update('selectedCategories', () => newSelectedCategories());

export const deletePositions = (data, { productPositions, category }) =>
  data
    .updateIn(['items', category], (positions) =>
      positions.filterNot((position) =>
        new List(productPositions).includes(position.get('id'))
      )
    )
    .set('selected', newSelect());

export const selectProductPosition = (data, { category, item }) => {
  const currentCategory = data.get('currentCategory');
  if (
    (currentCategory.size > 0 &&
      currentCategory.get('id') !== category.get('id')) ||
    currentCategory.size === 0 ||
    data.get('selected').size === 0
  ) {
    return data
      .remove('currentCategory')
      .set('currentCategory', category)
      .remove('selected')
      .set('selected', new Set([item]))
      .remove('selectedCategories')
      .set('selectedCategories', newSelectedCategories());
  }
  return data
    .update('selected', newSelect(), (selected) =>
      selected.includes(item) ? selected.delete(item) : selected.add(item)
    )
    .remove('selectedCategories')
    .set('selectedCategories', newSelectedCategories());
};

export const clearSelectedProductPositions = (data) =>
  data
    .remove('selected')
    .set('selected', newSelect())
    .remove('selectedCategories')
    .set('selectedCategories', newSelectedCategories());

export const setCurrency = (data, { currency }) =>
  data.set('currency', currency);

export const updatePositionPrice = (
  data,
  { sku, categoryId, priceCategoryId, discount }
) => {
  const items = data.getIn(['items', categoryId.toString()]) || newItems();
  const index = items.findIndex((position) => position.get('sku') === sku);
  const offer = data.getIn([
    'items',
    categoryId,
    index,
    'offer',
    priceCategoryId
  ]);
  if (!offer) {
    return data.setIn(
      ['items', categoryId, index, 'offer', priceCategoryId],
      fromJS({ discount })
    );
  }
  return data.updateIn(
    ['items', categoryId, index, 'offer', priceCategoryId],
    (value) => value.set('discount', discount)
  );
};

export const updateProductUnits = (data, { units }) => {
  data.set('units', units);
};

export const deleteCategories = (data, { categories }) =>
  data
    .update('categories', newCategories(), (productCategories) =>
      productCategories.filterNot((category) =>
        new List(categories).includes(category.get('id'))
      )
    )
    .remove('selectedCategories')
    .set('selectedCategories', newSelectedCategories());

export const updateProductRetailPrice = (
  data,
  { sku, categoryId, newPrice }
) => {
  const items = data.getIn(['items', categoryId]);
  const index = items.findIndex((position) => position.get('sku') === sku);
  return data.setIn(['items', categoryId, index, 'offer_retail'], newPrice);
};

export const updateProductTotalPrice = (
  data,
  { sku, categoryId, total, priceCategoryId }
) => {
  const items = data.getIn(['items', categoryId.toString()]) || newItems();

  const index = items.findIndex((position) => position.get('sku') === sku);
  const offer = data.getIn([
    'items',
    categoryId,
    index,
    'offer',
    priceCategoryId
  ]);
  if (!offer) {
    return data.setIn(
      ['items', categoryId, index, 'offer'],
      fromJS({ [priceCategoryId]: { total } })
    );
  }
  return data.updateIn(
    ['items', categoryId, index, 'offer', priceCategoryId],
    (value) => value.set('total', total)
  );
};
