import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Text } from '../../../../styledComponents/atoms';
import { useTranslate } from '../../../../TranslateProvider';
import { getLastUnreadMessageByChatId } from '../../getter';

function CWDividerNewMessage({ id, chat, messageId }) {
  const t = useTranslate();
  const lastUnreadMessageId = useSelector((state) =>
    getLastUnreadMessageByChatId(state, chat)
  );

  useEffect(() => {
    if (lastUnreadMessageId === messageId) {
      const event = new CustomEvent('load-divider');
      document.dispatchEvent(event);
    }
  }, [lastUnreadMessageId, messageId]);

  if (lastUnreadMessageId !== messageId) {
    return null;
  }

  return (
    <Divider id={id}>
      <DividerAnchor id={`${id}-anchor`} />
      <NewMessageText>{t('have unread messages')}</NewMessageText>
    </Divider>
  );
}

const Divider = styled.div`
  width: calc(100% - 116px);
  height: 1px;
  padding: 14px 0 6px;
  margin: 0 auto;
  position: relative;

  &:after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: ${(p) => p.theme.colors.gray.additional};
    position: absolute;
  }
`;

const NewMessageText = styled(Text)`
  margin: 0;
  padding: 0 0 0 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${(p) => p.theme.colors.gray.additional};
  background: ${(p) => p.theme.colors.white};
  z-index: 2;
  position: absolute;
  transform: translateY(-50%);
  right: 0;
`;

const DividerAnchor = styled(Text)`
  height: 10px;
  width: 10px;
  background: red;
  opacity: 0;
  position: absolute;
  top: -10px;
  right: 0;
`;

export default CWDividerNewMessage;
