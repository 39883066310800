import React from 'react';

const IconOk = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      id="ok"
      d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"
      fill="#00CEE0"
    />
    <path
      id="ok_2"
      d="M13 6L8.15793 11L6 9.0804"
      stroke="white"
      strokeLinecap="round"
    />
  </svg>
);

export default IconOk;
