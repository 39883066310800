import React from 'react';
import styled from 'styled-components';
import ButtonIcon from '../../ChatWidget/ButtonIcon';

const ZoomToolsButtons = ({ zoomIn, zoomOut, zoomReset, options }) => {
  const { scale, minScale = 1, maxScale = 2 } = options;

  return (
    <Wrapper>
      <MarginBottom value={16}>
        <ButtonIcon
          disabled={scale === minScale}
          onClick={zoomReset}
          icon="fit-in"
          tooltip="fit-in"
          color="white"
        />
      </MarginBottom>
      <MarginBottom value={8}>
        <ButtonIcon
          disabled={scale === maxScale}
          onClick={zoomIn}
          icon="plus"
          tooltip="zoom-in"
          color="white"
        />
      </MarginBottom>
      <ButtonIcon
        disabled={scale === minScale}
        onClick={zoomOut}
        icon="minus"
        tooltip="zoom-out"
        color="white"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 98px;
  right: 68px;
  display: flex;
  flex-direction: column;
`;

const MarginBottom = styled.div(({ value }) => ({
  'margin-bottom': `${value}px`
}));

export default ZoomToolsButtons;
