/* eslint-disable react/no-danger */
import React, { useRef } from 'react';
import Scrollbar from '../common/Scrollbar';
import useZoomEffect from '../../../utils/zoomer';
import ZoomToolsButtons from '../common/ZoomToolsButtons';
import useWindowSize from '../../../utils/windowSize';

const VERTICAL_PADDING = 115;
const HORIZONTAL_PADDING = 272;

const ZoomDocx = ({ docxHTML }) => {
  const ref = useRef(null);
  const { width, height } = useWindowSize();

  const maxWidth = width - HORIZONTAL_PADDING;
  const maxHeight = height - VERTICAL_PADDING;

  const { scale, zoomIn, zoomOut, zoomReset } = useZoomEffect(
    { domTarget: ref },
    {
      overflowX: 'initial',
      overflowY: 'initial',
      maxHeight,
      maxWidth,
      flex: false
    }
  );

  const zoomOptions = {
    scale,
    minScale: 1,
    maxScale: 2
  };

  return (
    <>
      <Scrollbar style={{ width: maxWidth, height: maxHeight }}>
        <div
          ref={ref}
          style={{ padding: '0 15px', transformOrigin: '0 0' }}
          dangerouslySetInnerHTML={{ __html: docxHTML }}
        />
      </Scrollbar>
      <ZoomToolsButtons
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        zoomReset={zoomReset}
        options={zoomOptions}
      />
    </>
  );
};

export default ZoomDocx;
