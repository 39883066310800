import React from 'react';
import { VirtualizedTable } from '../../../../shared/components';
import Header from './Header';
import Cell from './Cell';
import { useTranslate } from '../../../../TranslateProvider';
import { getTranslate } from '../../utils';
import classes from './EmailStatusList.module.css';

const headerStyle = {
  height: '100%',
  textTransform: 'none',
  margin: 0,
  paddingBottom: 4
};

const EmailStatusList = ({
  emailStatusList,
  rowCount = emailStatusList.length
}) => {
  const t = useTranslate();

  return (
    <VirtualizedTable
      rowCount={rowCount}
      columns={[
        {
          width: 482,
          label: getTranslate(t, 'table.companyName'),
          dataKey: 'company',
          headerStyle
        },
        {
          width: 110,
          label: getTranslate(t, 'table.updatedStatus'),
          dataKey: 'updated',
          numeric: true,
          headerStyle
        }
      ]}
      rows={emailStatusList}
      headerRenderer={(props) => <Header {...props} t={t} />}
      cellRenderer={(props) => <Cell {...props} t={t} />}
      rowClassName={classes.row}
      cellClassName={classes.rowColumn}
    />
  );
};

export default EmailStatusList;
