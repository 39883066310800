import { defRecord } from 'core/lib';
import { getInterlocutor as getInterlocutorFn } from 'core/data/light/dialog';
import { assocPath, isNil } from 'ramda';
import { countOnlineByIds } from '../../../../../../modules/online/getter';
import getMsg from './last-message/msg';
import { makeLastMessageVM } from './last-message/dataType';
import { makeChatInfoVM } from './info/dataType';

const { makeChatVM } = defRecord(
  'ChatVM',
  ['t', 'active', 'chat', 'user', 'online', 'onClick'],
  {
    active: false,
    online: false
  }
);

function isDialog(vm) {
  return vm.chat.type === 'Dialog';
}

function isChannel(vm) {
  return (
    vm.chat.type === 'Channel' || vm.chat.type === 'PurchaseRequestsChannel'
  );
}

function isTopic(vm) {
  return vm.chat.type === 'Topic';
}

function getInterlocutor(vm) {
  if (!isDialog(vm)) return null;

  return getInterlocutorFn(vm.user.get('employeeId'), vm.chat);
}

const getAmountOnline = (vm) =>
  countOnlineByIds(
    vm.online,
    vm.chat.members.map((m) => m.employeeId)
  );

const generateCNChat = (vm) => `cws-chat-item ${vm.active ? 'active' : ''}`;

const isShowAvatar = (vm) => isDialog(vm) || isChannel(vm) || isTopic(vm);
const getAvatarInfo = (vm) => ({
  chat: vm.chat,
  type: vm.chat.type,
  size: 40,
  color: vm.chat.color,
  name: vm.chat.name,
  interlocutor: getInterlocutor(vm)
});

function getLastMessage(vm) {
  const message = getMsg(vm.t, vm.chat, vm.user);
  return !isNil(message) ? assocPath(['avatar', 'show'], false, message) : null;
}

const isShowLastMessage = (vm) => !isNil(getLastMessage(vm));
const generateLastMessageVM = (vm) =>
  makeLastMessageVM({
    t: vm.t,
    chat: vm.chat,
    message: getLastMessage(vm)
  });

const generateChatInfoVM = (vm) =>
  makeChatInfoVM({
    t: vm.t,
    chat: vm.chat,
    online: getAmountOnline(vm),
    interlocutor: getInterlocutor(vm)
  });

const initOnClick = (vm) => vm.onClick;

export {
  makeChatVM,
  generateCNChat,
  isShowAvatar,
  getAvatarInfo,
  isShowLastMessage,
  generateLastMessageVM,
  generateChatInfoVM,
  initOnClick
};
