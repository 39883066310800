import i18n from 'i18next';

import getBrowserLanguage from './utils/languageDetector';
import ru from './locales/ru.json';
import en from './locales/en.json';

const languages = ['en', 'ru'];
const defaultLanguage = 'en';
const browserLang = getBrowserLanguage();

i18n.init(
  {
    resources: {
      en: { ui: en },
      ru: { ui: ru }
    },
    lng: languages.includes(browserLang) ? browserLang : defaultLanguage,
    nsSeparator: false,
    debug: false,
    defaultNS: 'ui'
  },
  (err) => {
    if (err) console.error('something went wrong loading translate', err);
  }
);

export default i18n;
