const { assoc, isNil } = require('ramda');
const { defStruct } = require('../../lib');

/**
 * @typedef WhereParamItem
 * @typedef {import('../whereParams')} WhereParams
 * @typedef EqualToOneOf
 * @typedef EqualOne
 * @typedef ILike
 * @typedef Or
 * @typedef Between
 */

const makeWhereParam = (type) => defStruct(type, ['field', 'value']);

const { makeEqualToOneOf, isEqualToOneOf } = makeWhereParam('EqualToOneOf');
const { makeEqualOne, isEqualOne } = makeWhereParam('EqualOne');
const { makeILike, isILike } = makeWhereParam('ILike');
const { makeOr, getWhereParams, isOr } = defStruct('Or', ['whereParams']);
const { makeBetween, getFrom, getTo, isBetween } = defStruct('Between', [
  'field',
  'from',
  'to'
]);

/**
 * Creates a equal to one of data type, is sub type of where param item
 *
 * @param {string} field
 * @param {*} value
 * @returns {EqualToOneOf}
 */
exports.makeEqualToOneOf = (field, value) => makeEqualToOneOf(field, value);

/**
 * Creates a equal one data type, is sub type of where param item
 *
 * @param {string} field
 * @param {*} value
 * @returns {EqualOne}
 */
exports.makeEqualOne = (field, value) => makeEqualOne(field, value);

/**
 * Creates a i like data type, is sub type of where param item
 *
 * @param {string} field
 * @param {*} value
 * @returns {ILike}
 */
exports.makeILike = (field, value) => makeILike(field, value);

/**
 * Creates a "or" data type, is sub type of where param item
 *
 * @param {WhereParams} whereParams
 * @returns {Or}
 */
exports.makeOr = (whereParams) => makeOr(whereParams);

/**
 * Creates a between data type
 *
 * @param {object} params
 * @param {*} params.from
 * @param {*} params.to
 * @returns {Between}
 */
exports.makeBetween = ({ field, from, to } = {}) =>
  makeBetween(field, from, to);

/**
 * Gives a from
 *
 * @param {Between} between
 * @returns {*}
 */
exports.getFrom = (between) => getFrom(between);

/**
 * Gives a to
 *
 * @param {Between} between
 * @returns {*}
 */
exports.getTo = (between) => getTo(between);

/**
 * Checks if has from
 *
 * @param {Between} between
 * @returns {boolean}
 */
exports.hasFrom = (between) => !isNil(getFrom(between));

/**
 * Checks if has to
 *
 * @param {Between} between
 * @returns {boolean}
 */
exports.hasTo = (between) => !isNil(getTo(between));

/**
 * Checks if is between
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isBetween = (a) => isBetween(a);

/**
 * Gives value from where parameter item
 *
 * @param {WhereParamItem} whereParamItem
 * @returns {string}
 */
exports.getField = (whereParamItem) => whereParamItem.field;

/**
 * Gives value from where parameter item
 *
 * @param {WhereParamItem} whereParamItem
 * @returns {*}
 */
exports.getValue = (whereParamItem) => whereParamItem.value;

/**
 * Gives the list of where parameters from or data type
 *
 * @param {Or} or
 * @returns {WhereParams}
 */
exports.getWhereParams = (or) => getWhereParams(or);

/**
 * Return a new where parameter item with consumes filed name
 *
 * @param {string} fieldName
 * @param {WhereParamItem} whereParamItem
 * @returns {WhereParamItem}
 */
exports.setField = (fieldName, whereParamItem) =>
  assoc('field', fieldName, whereParamItem);

/**
 * Checks if is equal to one of
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isEqualToOneOf = (a) => isEqualToOneOf(a);

/**
 * Checks if is equal one
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isEqualOne = (a) => isEqualOne(a);

/**
 * Checks if is i like
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isILike = (a) => isILike(a);

/**
 * Checks if is or
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isOr = (a) => isOr(a);

/**
 * Checks if is where parameter item
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isWhereParamItems = (a) =>
  isEqualToOneOf(a) || isEqualOne(a) || isILike(a) || isOr(a);
