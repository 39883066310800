import React, { useEffect, useState } from 'react';
import { PrintStatus } from './PrintStatus';
import { PrintZone } from './PrintZone';
import { PrintProgress } from './PrintProgress';
import { useSpinner } from '../../hooks/useSpinner';

export const PrintContainer = ({
  doc,
  pageHeight,
  pageWidth,
  store,
  renderLoadingPrint
}) => {
  const { renderSpinner } = useSpinner();
  const [printStatus, setPrintStatus] = useState(PrintStatus.Inactive);
  const [numLoadedPagesForPrint, setNumLoadedPagesForPrint] = useState(0);

  const cancelPrinting = () => {
    setNumLoadedPagesForPrint(0);
    setPrintStatus(PrintStatus.Inactive);
  };

  const handlePrintStatus = (status) => setPrintStatus(status);

  const onLoadPage = () => {
    const total = numLoadedPagesForPrint + 1;
    if (total <= doc.numPages) {
      setNumLoadedPagesForPrint(total);
    }

    if (total === doc.numPages) {
      setPrintStatus(PrintStatus.Ready);
    }
  };

  useEffect(() => {
    store.subscribe('printStatus', handlePrintStatus);

    return () => {
      store.unsubscribe('printStatus', handlePrintStatus);
    };
  }, []);

  return (
    <>
      {printStatus === PrintStatus.Preparing &&
        (renderLoadingPrint ? (
          renderLoadingPrint({
            numPages: doc.numPages,
            numLoadedPages: numLoadedPagesForPrint,
            onCancel: cancelPrinting,
            Spinner: renderSpinner()
          })
        ) : (
          <PrintProgress
            numPages={doc.numPages}
            numLoadedPages={numLoadedPagesForPrint}
            onCancel={cancelPrinting}
          />
        ))}
      {(printStatus === PrintStatus.Preparing ||
        printStatus === PrintStatus.Ready) &&
        numLoadedPagesForPrint <= doc.numPages && (
          <PrintZone
            doc={doc}
            numLoadedPages={numLoadedPagesForPrint}
            pageWidth={pageWidth}
            pageHeight={pageHeight}
            printStatus={printStatus}
            onLoad={onLoadPage}
            onCancel={cancelPrinting}
          />
        )}
    </>
  );
};
