import { isType } from 'core/lib';
import { PURCHASE_REQUESTS_MESSAGE_TYPE } from 'core/data/light/purchaseRequestChannel';
import msgNewsMethods from 'core/data/light/message/msg-news';

export const isOwnMessage = (message, currentEmployeeId) =>
  message.employeeId === currentEmployeeId;
export const isMessageFromInterlocutor = (message, currentEmployeeId) =>
  !isOwnMessage(message, currentEmployeeId);
export const isUnsentMessage = (message) => isType('MsgUnsent', message);
export const isPurchaseRequestMessage = (message) =>
  isType(PURCHASE_REQUESTS_MESSAGE_TYPE, message);
export const isForwardedPurchaseRequestMessage = (message) =>
  message.originMsgType === PURCHASE_REQUESTS_MESSAGE_TYPE;
export const isMsgRoomCreated = (message) =>
  isType('MsgVideoRoomWasCreated', message);
export const isForwardedVideoRoomCreatedMsg = (message) =>
  message.originMsgType === 'MsgVideoRoomWasCreated';
export const isForwardedMessage = (message) => isType('MsgForwarded', message);
export const isReplyMessage = (message) => isType('MsgReply', message);
export const isUserMessage = (message) => isType('MsgUser', message);
export const isSupportMessage = (message) => isType('MsgSupport', message);
export const isNewsMessage = (message) => msgNewsMethods.isNewsMessage(message);
export const isPopupSystemNotification = (message) =>
  isType('pop-up-system-notification', message);
