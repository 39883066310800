// import { validateEmail } from './validateField';

export const syncValidation = (values) => {
  const errors = {};

  if (!values.get('email')) {
    errors.email = 'Required';
  }
  // else if (!validateEmail(values.get('email'))) {
  //   errors.email = 'Wrong email format';
  // }

  if (!values.get('password')) {
    errors.password = 'Required';
  }

  return errors;
};
