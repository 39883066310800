import io from 'socket.io-client';
import request from '../../../lib/request';

let _scktChl;
export const getScktChl = () => {
  if (!_scktChl) {
    _scktChl = io('/channel', {
      timeout: 1000
    });
  }
  return _scktChl;
};

export const initService = () => {
  const scktChl = getScktChl();

  return {
    support: {
      getData: () => request.get('/api/user/support')
    },
    chat: {
      getAllChats: () => request.get('/api/channel-all'),
      findChannel: (id) => request.get(`/api/channel/${id}`),
      getDialogIdByEmployeeId: (employeeId) =>
        request.get(`/api/dialog/by-employeeId/${employeeId}`),
      getGroups: (initial = false) =>
        request.get(`/api/dialogs/lists?initial=${initial}`),
      createGroup: (name) => request.post('/api/dialogs/lists', { name }),
      updateGroup: (id, payload) =>
        request.patch(`/api/dialogs/lists/${id}`, payload),
      deleteGroup: (id) => request.delete(`/api/dialogs/lists/${id}`),
      addToGroup: (groupId, chat) =>
        request.post('/api/dialogs/lists/member', {
          dialogListId: groupId,
          dialogType: chat.type,
          dialogId: chat.id
        }),
      removeFromGroup: (groupId, chat) =>
        request.delete(`/api/dialogs/lists/${groupId}/${chat.id}`),
      removeAllFromGroup: (groupId) =>
        request.delete(`/api/dialogs/lists/${groupId}/all`),
      createSubGroup: (parentId, name) =>
        request.post(`/api/dialogs/lists/${parentId}`, { name }),
      search: (search) => request.post('/api/chat/advanced-search', { search }),
      chatHistory: (id, password, includes, interval) =>
        request.post('/api/chat/chat-history', {
          id,
          password,
          includes,
          interval
        }),
      getBadges: () => request.get('/api/dialogs/badges')
    },
    chatMessage: {
      addBookmark: (chatId, messageId) =>
        scktChl.emit('message:bookmark:add', { channelId: chatId, messageId }),
      removeBookmark: (chatId, messageId) =>
        scktChl.emit('message:bookmark:remove', {
          channelId: chatId,
          messageId
        }),
      findChunkPrev: (chatId, date = null, limit = 40) =>
        request.post(`/api/channel/${chatId}/chunk-messages`, { date, limit }),
      findChunkNext: (chatId, date, limit = 40) =>
        request.post(`/api/channel/${chatId}/chunk-next-messages`, {
          date,
          limit
        }),
      search: (
        chatId,
        {
          queryText = '',
          fromDate = null,
          toDate = null,
          limit = 5,
          offset = 0
        }
      ) =>
        request.post(`/api/channel/${chatId}/search-messages`, {
          queryText,
          fromDate,
          toDate,
          limit,
          offset
        }),
      favorites: (chatId, { limit = 5, offset = 0 }) =>
        request.post(`/api/channel/${chatId}/favorite-messages`, {
          limit,
          offset
        })
    },
    contacts: {
      getGroups: () => request.get('/api/contacts/lists'),
      createGroup: (name) => request.post('/api/contacts/lists', { name }),
      updateGroup: (id, payload) =>
        request.patch(`/api/contacts/lists/${id}`, payload),
      deleteGroup: (id) => request.delete(`/api/contacts/lists/${id}`),
      addToGroup: (groupId, memberId) =>
        request.post('/api/contacts/lists/member', {
          memberId,
          listId: groupId
        }),
      removeFromGroup: (groupId, memberId) =>
        request.delete(`/api/contacts/lists/member/${groupId}/${memberId}`),
      createSubGroup: (parentId, name) =>
        request.post(`/api/contacts/lists/${parentId}`, { name })
    }
  };
};
