import { convertFileUrls } from '../../files/utils';
import { MODES } from '../../../../../useCases/files';

export const attachLocalFilesService = async (
  {
    reqAttachTopicUnsaveInfoFile,
    reqGetTopicUnsaveInfoFiles,
    getChatInput,
    filesLoadHandler,
    setInputFiles,
    setSuccessMessage,
    setInfoMessage,
    setErrorMessage
  },
  { id, files: loadedFiles }
) => {
  const input = getChatInput();
  await filesLoadHandler({
    mode: MODES.Exclude,
    files: loadedFiles.map((file) => ({ file })),
    attachedFiles: input.files,
    maxFiles: input.maxFiles,
    beforeLoading: () => setInfoMessage({ key: 'File upload started' }),
    loadFile: async ({ file }) => {
      await reqAttachTopicUnsaveInfoFile(id, file.name, file);
      const uploadedFiles = await reqGetTopicUnsaveInfoFiles(id);
      setInputFiles(convertFileUrls(uploadedFiles));
    },
    onError: (key, params) => setErrorMessage({ key, params }),
    onLoaded: () => setSuccessMessage({ key: '' }),
    includeToAllowExt: ['pptx', 'ppt']
  });
};
