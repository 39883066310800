import React from 'react';

const IconSimpleCross = () => (
  <svg
    style={{ cursor: 'pointer' }}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.64648 10.3536L1.64648 2.35359L2.35359 1.64648L10.3536 9.64648L9.64648 10.3536Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.35359 10.3536L10.3536 2.35359L9.64648 1.64648L1.64648 9.64648L2.35359 10.3536Z"
      fill="#767B92"
    />
  </svg>
);

export default IconSimpleCross;
