import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CWSAvatar from '../../Avatar';
import ButtonIcon from '../../ButtonIcon';
import './style.css';

const Footer = ({
  type,
  owner,
  minimized = false,
  onMinimize = () => {},
  onMaximize = () => {},
  onEditClick,
  isActive,
  canEdit,
  theme
}) => (
  <div
    className={classNames('cw-topic__footer', {
      'cw-topic__footer-minimized': minimized,
      [`cw-topic__footer-theme-${theme}`]: Boolean(theme),
      [`cw-topic__footer-type-${type}`]: Boolean(type)
    })}>
    <CWSAvatar
      size={28}
      info={{
        src: owner.avatarSrc,
        type: 'user',
        alt: '',
        employeeId: owner.employeeId,
        hideOnlineStatus: true
      }}
    />
    <span className="cw-topic__footer_user-name">{owner.userName}</span>
    <span className="cw-topic__footer_separator" />
    <span className="cw-topic__footer_company-name">{owner.companyName}</span>
    <div className="cw-topic__footer_buttons">
      {isActive && canEdit && (
        <ButtonIcon
          icon="edit-circle"
          tooltip="change-topic-message"
          onClick={onEditClick}
        />
      )}
      {!minimized && <ButtonIcon icon="arrow-up-circle" onClick={onMinimize} />}
      {minimized && (
        <ButtonIcon icon="arrow-down-circle" onClick={onMaximize} />
      )}
    </div>
  </div>
);

Footer.propTypes = {
  owner: PropTypes.object.isRequired,
  minimized: PropTypes.bool.isRequired,
  onMinimize: PropTypes.func.isRequired,
  onMaximize: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired
};

export default Footer;
