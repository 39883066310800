import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { is } from 'ramda';

export const useBindMethods = (methods) => {
  const dispatch = useDispatch();

  if (is(Array, methods)) {
    return methods.reduce(
      (boundMethods, method) => ({
        ...boundMethods,
        [method.name]: bindActionCreators(method, dispatch)
      }),
      {}
    );
  }
  return bindActionCreators(methods, dispatch);
};

export const useBindState = (getters) => {
  if (is(Function, getters)) return useSelector(getters);

  if (is(Array, getters)) {
    return getters.reduce(
      (boundedGetters, getter) => ({
        ...boundedGetters,
        [getter.name]: useSelector(getter)
      }),
      {}
    );
  }

  return Object.entries(getters).reduce(
    (boundedGetters, [getterName, getter]) => ({
      ...boundedGetters,
      [getterName]: useSelector(getter)
    }),
    {}
  );
};
