import request from '../lib/request';
import { setSuccessMessage } from './message';

export const setDialogsSearch = (search) => (dispatch) => {
  dispatch({ type: 'CHAT:SET_DIALOGS_SEARCH', payload: { search } });
};

export const clearDialogsSearch = () => (dispatch) => {
  dispatch({ type: 'CHAT:CLEAR_DIALOGS_SEARCH' });
};

export const pushOrReplaceDialog =
  (dialog, sort = true) =>
  (dispatch) => {
    dispatch({ type: 'CHAT:SET_IN_DIALOGS_LIST', payload: { dialog, sort } });
  };

export const pushOrReplaceChannel =
  (dialog, sort = true) =>
  (dispatch) => {
    dispatch({
      type: 'CHAT:SET_CHANNEL_IN_DIALOGS_LIST',
      payload: { dialog, sort }
    });
  };

export const loadDialogs =
  ({
    mode = '',
    system = [],
    user = [],
    search = '',
    orderBy = 'update-time',
    settingsOff = false
  } = {}) =>
  (dispatch) => {
    request
      .post('/api/dialogs', {
        mode,
        system,
        user,
        search,
        page: 0,
        limit: 20,
        orderBy,
        settingsOff
      })
      .then((payload) => {
        dispatch({ type: 'CHAT:SET_DIALOGS', payload });
        dispatch({
          type: 'DIALOG_LISTS:SET_MODE',
          payload: { mode: payload.mode }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const loadDialogsChunk =
  ({
    mode = '',
    system = [],
    user = [],
    search = '',
    page = 0,
    limit = 20,
    orderBy = 'update-time'
  }) =>
  (dispatch) => {
    dispatch({
      type: 'CHAT:SET_CHUNK_LOADER',
      payload: { loadingChunk: true }
    });
    request
      .post('/api/dialogs', {
        mode,
        system,
        user,
        search,
        page,
        limit,
        orderBy
      })
      .then((payload) => {
        dispatch({ type: 'CHAT:MERGE_DIALOGS', payload });
        dispatch({
          type: 'CHAT:SET_CHUNK_LOADER',
          payload: { loadingChunk: false }
        });
      })
      .catch((err) => {
        dispatch({
          type: 'CHAT:SET_CHUNK_LOADER',
          payload: { loadingChunk: false }
        });
        console.error(err);
      });
  };

export const getDialogByUniqueId = (uniqueId) => (dispatch) => {
  request
    .get(`/api/dialogs/uniqueid/${uniqueId}`)
    .then((payload) => {
      dispatch({
        type: 'CHAT:SET_IN_DIALOGS_LIST',
        payload: { dialog: payload.dialog, sort: true }
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const putDialogToArchive =
  (dialogId, withNotification) => (dispatch) => {
    request
      .post(`/api/dialogs/${dialogId}/archive`)
      .then(() => {
        if (withNotification) {
          dispatch(setSuccessMessage({ key: 'dialog_archived' }));
        }
        dispatch({
          type: 'CHAT:DELETE_DIALOG_FROM_STORE',
          payload: { dialogId }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const unarchiveDialog = (dialogId, withNotification) => (dispatch) => {
  request
    .post(`/api/dialogs/${dialogId}/unarchive`)
    .then(() => {
      if (withNotification) {
        dispatch(setSuccessMessage({ key: 'dialog_unarchived' }));
      }
      dispatch({
        type: 'CHAT:DELETE_DIALOG_FROM_STORE',
        payload: { dialogId }
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const putChannelToArchive =
  (channelId, options = { showNotify: false, key: '' }) =>
  (dispatch) => {
    request
      .post(`/api/channel/${channelId}/archive`)
      .then(() => {
        if (options.showNotify) {
          dispatch(setSuccessMessage({ key: options.key }));
        }
        dispatch({
          type: 'CHAT:DELETE_CHANNEL_FROM_STORE',
          payload: { channelId }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const unarchiveChannel =
  (channelId, options = { showNotify: false, key: '' }) =>
  (dispatch) => {
    request
      .post(`/api/channel/${channelId}/unarchive`)
      .then(() => {
        if (options.showNotify) {
          dispatch(setSuccessMessage({ key: options.key }));
        }
        dispatch({
          type: 'CHAT:DELETE_CHANNEL_FROM_STORE',
          payload: { channelId }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const getMessagesForForward = (dialogId, msgs) => () =>
  request
    .post(`/api/dialogs/${dialogId}/forward_messages`, { msgs })
    .then((messages) => messages)
    .catch((err) => {
      console.error(err);
    });

export const notificationDialogOn =
  (dialogId, withNotification) => (dispatch) => {
    request
      .post(`/api/dialogs/${dialogId}/notification_on`)
      .then(() => {
        if (withNotification) {
          dispatch(setSuccessMessage({ key: 'notification_on' }));
        }
        dispatch({
          type: 'CHAT:NOTIFICATION_ON_DIALOG',
          payload: { dialogId }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const notificationDialogOff =
  (dialogId, withNotification) => (dispatch) => {
    request
      .post(`/api/dialogs/${dialogId}/notification_off`)
      .then(() => {
        if (withNotification) {
          dispatch(setSuccessMessage({ key: 'notification_off' }));
        }
        dispatch({
          type: 'CHAT:NOTIFICATION_OFF_DIALOG',
          payload: { dialogId }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const notificationChannelOn =
  (channelId, withNotification) => (dispatch) => {
    request
      .post(`/api/channel/${channelId}/notification_on`)
      .then(() => {
        if (withNotification) {
          dispatch(setSuccessMessage({ key: 'notification_on' }));
        }
        dispatch({
          type: 'CHAT:NOTIFICATION_ON_CHANNEL',
          payload: { channelId }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const notificationChannelOff =
  (channelId, withNotification) => (dispatch) => {
    request
      .post(`/api/channel/${channelId}/notification_off`)
      .then(() => {
        if (withNotification) {
          dispatch(setSuccessMessage({ key: 'notification_off' }));
        }
        dispatch({
          type: 'CHAT:NOTIFICATION_OFF_CHANNEL',
          payload: { channelId }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
