import React from 'react';

/* eslint-disable max-len */
const IconSchedule = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.09286 8.13595L3.3006 3.64764C3.01884 3.2396 3.26191 3.11292 3.66314 3.39012L7.85349 6.25461C7.93603 6.3065 8.01409 6.36838 8.08597 6.44026C8.60096 6.95525 8.60296 7.78825 8.0904 8.30081C7.57784 8.81337 6.74484 8.81141 6.22985 8.29642C6.17923 8.2458 6.13357 8.1921 6.09286 8.13595ZM1.65694 7.76512C1.65694 11.1479 4.06611 13.6818 7.44885 13.6818C10.8316 13.6818 13.6049 11.1479 13.6049 7.76512C13.6049 4.67946 11.3979 2.2971 8.42969 1.87252V2.94921C8.42969 3.19272 8.22675 3.39012 7.99219 3.39012C7.75056 3.39012 7.44885 3.19389 7.44885 2.94921V2.25403V0.762709C11.3148 0.762709 14.6281 3.89913 14.6281 7.76512C14.6281 11.6311 11.4207 14.7651 7.55469 14.7651C3.68869 14.7651 0.554688 11.6311 0.554688 7.76512H1.65694Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconSchedule;
