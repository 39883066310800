const NEWS_MESSAGE_TYPE = 'MsgNews';

exports.NEWS_MESSAGE_TYPE = NEWS_MESSAGE_TYPE;

exports.isNewsMessage = (message = {}) => NEWS_MESSAGE_TYPE === message.type;

/**
 * @typedef MsgNewsData
 * @property {string} id
 * @property {string} title
 * @property {string} summary
 * @property {Date} publishedTime
 * @property {string} author
 * @property {string} source
 * @property {string} url
 * @property {number[]} players
 * @property {object} metadata
 */

/**
 * @typedef MsgNews
 * @property {string} type
 * @property {string} id
 * @property {string} channelId
 * @property {number} employeeId
 * @property {Date} time
 * @property {MsgNewsData} data
 * @property {number[]} accessList
 * @property {string[]} bookmarkedBy
 */

/**
 * Creates a news message data type
 *
 * @param {object} params
 * @param {string} params.id
 * @param {string} params.channelId
 * @param {number} params.employeeId
 * @param {Date} params.time
 * @param {MsgNewsData} params.data
 * @param {[number]} params.accessList
 * @param {[string]} params.bookmarkedBy
 * @returns {MsgNews}
 */
exports.makeNewsMessage = ({
  id,
  employeeId,
  channelId,
  time,
  data,
  accessList = [],
  bookmarkedBy = []
} = {}) => ({
  type: NEWS_MESSAGE_TYPE,
  id,
  employeeId,
  channelId,
  time,
  data,
  accessList,
  bookmarkedBy
});

/**
 * Creates a news data type
 *
 * @param {object} params
 * @param {string} params.id
 * @param {string} params.title
 * @param {string} params.summary
 * @param {string} params.author
 * @param {string} params.source
 * @param {string} params.url
 * @param {Date} params.publishedTime
 * @param {number[]} params.players
 * @param {object} params.metadata
 * @returns {MsgNewsData}
 */
exports.makeNewsMessageData = ({
  id,
  title,
  summary,
  publishedTime,
  author,
  source,
  url,
  players = [],
  metadata
} = {}) => ({
  type: NEWS_MESSAGE_TYPE,
  id,
  title,
  summary,
  publishedTime,
  author,
  source,
  url,
  players,
  metadata
});
