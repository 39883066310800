import React from 'react';
import cn from 'classnames';
import { useModal, useTranslate } from '@hooks';
import classes from './Button.module.css';
import { Typography } from '../../../../shared/components';
import { SUPPLIERS_ACTIVITIES_MODAL } from '../Modal/constants';
import { getTranslate } from '../../utils';

// TODO: Заменить на универсальную кнопку
const Button = ({ requestId, className, ...restProps }) => {
  const t = useTranslate();
  const { onOpen } = useModal();

  return (
    <button
      type="button"
      className={cn(classes.button, className)}
      onClick={() => onOpen(SUPPLIERS_ACTIVITIES_MODAL, { requestId })}
      {...restProps}>
      <Typography variant="body1Reg">
        {getTranslate(t, 'triggerBtn')}
      </Typography>
    </button>
  );
};

export default Button;
