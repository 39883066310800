import assert from 'assert';
import { isNil } from 'ramda';
import { isBookmarked } from 'core/data/light/message/msg-user';
import { logUseCase } from '../../../../utils/logger';
import {
  makeBLWithSelect,
  merge
} from '../../data-type/block-list-with-select';

const log = logUseCase.extend('toggleBookmark');
export const toggleBookmarkCase = async (
  { getCurrEmplId, getOpenedChatId, addBookmark, removeBookmark },
  { message }
) => {
  const employeeId = getCurrEmplId();
  const openedChatId = getOpenedChatId();

  assert(!isNil(openedChatId));

  log(
    `isBookmarked(message, employeeId) = ${isBookmarked(message, employeeId)}`
  );
  if (isBookmarked(message, employeeId)) {
    removeBookmark(openedChatId, message.id);
  } else {
    addBookmark(openedChatId, message.id);
  }
};

export const hideFavoritesCase = ({ prevChatMode, setFavotireMsgs }) => {
  prevChatMode();
  setFavotireMsgs(makeBLWithSelect());
};

export const showFavoritesCase = async (
  {
    reqFavoriteMsgsByOpenedChat,
    setFavotireMsgs,
    setChatMode,
    gaSendShowFavorites
  },
  chat
) => {
  const msgs = await reqFavoriteMsgsByOpenedChat();
  setFavotireMsgs(msgs);

  setChatMode('favorites');
  gaSendShowFavorites(chat);
};

export const loadMoreCase = async (
  { getFavoritesMsgs, reqFavoriteMsgsByOpenedChat, setFavotireMsgs },
  pageNumber
) => {
  const currentMsgs = getFavoritesMsgs();
  const msgs = await reqFavoriteMsgsByOpenedChat(pageNumber);

  setFavotireMsgs(merge(currentMsgs, msgs));
};
