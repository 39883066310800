const optionsFormatDate = {
  full: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  },
  withOutTime: { day: '2-digit', month: '2-digit', year: 'numeric' },
  dayAndMonth: { day: '2-digit', month: '2-digit' }
};

export const formatDate = (date, format = 'full') => {
  if (date === undefined || date === null || date === '-' || date === '') {
    return '-';
  }

  return new Date(date).toLocaleDateString('ru-RU', optionsFormatDate[format]);
};

export const getTime = (date) => {
  const formatter = new Intl.DateTimeFormat('ru', {
    hour: 'numeric',
    minute: 'numeric'
  });
  const time = formatter.format(new Date(date));
  return time;
};

export const getDate = (date) => {
  const formatter = new Intl.DateTimeFormat('ru');
  const fDate = formatter.format(new Date(date));
  return fDate;
};

export const validDate = (currentDate, compareDate) => {
  const day = compareDate.getDate();
  const month = compareDate.getMonth();
  const year = compareDate.getFullYear();
  const notValidYear = year < currentDate.getFullYear();
  const notValidMouth =
    year === currentDate.getFullYear() && month < currentDate.getMonth();
  const notValidDate =
    year === currentDate.getFullYear() &&
    month === currentDate.getMonth() &&
    day < currentDate.getDate();
  return !(notValidYear || notValidMouth || notValidDate);
};

export const getPrevMonthDate = () => {
  const date = new Date();
  const month = date.getMonth();
  date.setMonth(month - 1);
  return date;
};
