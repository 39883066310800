import React from 'react';
import PropTypes from 'prop-types';

const Main = ({ children }, { b }) => (
  <div className={b('main')}>{children}</div>
);

Main.contextTypes = {
  b: PropTypes.func
};

export default Main;
