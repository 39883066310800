const { assoc, append } = require('ramda');
/**
 * @typedef {import('../file')} File
 */

/**
 * @typedef MsgUser
 * @property {string} type
 * @property {string} id
 * @property {number} employeeId
 * @property {string} userName
 * @property {string} avatarSrc
 * @property {string} channelId
 * @property {Date} time
 * @property {string} msg
 * @property {string} html
 * @property {[File]} files
 * @property {[number]} mentions
 * @property {[number]} accessList
 * @property {[string]} bookmarkedBy
 * @property {boolean} edited
 * @property {boolean} hidden
 */

/**
 * Creates a msg user data type
 *
 * @param {object} params
 * @param {string} params.id
 * @param {number} params.employeeId
 * @param {string} params.userName
 * @param {string} params.avatarSrc
 * @param {string} params.channelId
 * @param {Date} params.time
 * @param {string} params.msg
 * @param {string} params.html
 * @param {[File]} params.files
 * @param {[number]} params.mentions
 * @param {[number]} params.accessList
 * @param {[string]} params.bookmarkedBy
 * @param {boolean} params.edited
 * @param {boolean} params.hidden
 * @returns {MsgUser}
 */
exports.makeMsgUser = ({
  id,
  employeeId,
  userName,
  avatarSrc = null,
  channelId,
  time,
  msg,
  html,
  files = [],
  mentions = [],
  accessList = [],
  bookmarkedBy = [],
  edited = false,
  hidden = false
} = {}) => ({
  type: 'MsgUser',
  id,
  employeeId,
  channelId,
  userName,
  avatarSrc,
  time,
  msg,
  html,
  files,
  accessList,
  mentions,
  bookmarkedBy,
  edited,
  hidden
});

/**
 * Remove file from msg user
 *
 * @param {number} fileId
 * @param {MsgUser} msgUser
 * @returns {MsgUser}
 */
exports.removeFile = (fileId, msgUser) => {
  const files = msgUser.files.filter((file) => file.id !== fileId);
  return assoc('files', files, msgUser);
};

/**
 * Checks if is bookmaked
 *
 * @param {MsgUser} msgUser
 * @param {number} employeeId
 * @returns {boolean}
 */
exports.isBookmarked = (msgUser, employeeId) =>
  msgUser.bookmarkedBy.includes(employeeId);

/**
 * Add to bookmark
 *
 * @param {MsgUser} msgUser
 * @param {number} employeeId
 * @returns {MsgUser}
 */
exports.addBookmark = (msgUser, employeeId) => {
  const bookmarkedBy = append(employeeId, msgUser.bookmarkedBy);
  return assoc('bookmarkedBy', bookmarkedBy, msgUser);
};

/**
 * Remove from bookmark
 *
 * @param {MsgUser} msgUser
 * @param {number} employeeId
 * @returns {MsgUser}
 */
exports.removeBookmark = (msgUser, employeeId) => {
  const bookmarkedBy = msgUser.bookmarkedBy.filter(
    (emId) => emId !== employeeId
  );
  return assoc('bookmarkedBy', bookmarkedBy, msgUser);
};

/**
 * Check allow to edit message
 *
 * @param {Date} msgDate
 * @param {number} timeout
 * @returns {boolean}
 */
exports.allowEdit = (msgDate, timeout) =>
  new Date() < new Date(msgDate.setMinutes(msgDate.getMinutes() + timeout));
