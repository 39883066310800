import React from 'react';
import { useSelector } from 'react-redux';
import * as getters from '../../../../getter';
import { useMessengerConfig } from '../../../../Messenger/hooks/useMessengerConfig';
import { isCurrentMode } from '../../../../data-type/mode';
import * as chatType from '../../../../helpers/chatType';
import ChatDashboard from './components/ChatDashboard';
import TopicDashboard from './components/TopicDashboard';
import SelectModeDashboard from './components/SelectModeDashboard';

const DashboardPanelContainer = ({ mode }) => {
  const { frame } = useMessengerConfig();

  const type = useSelector(getters.getOpenedChatType);

  const isDialog = chatType.isDialog(type);
  const isChannel = chatType.isChannel(type);
  const isTopic = chatType.isTopic(type);
  const isPurchaseRequestsChannel = chatType.isPurchaseRequestsChannel(type);
  const isNewsFeedChannel = chatType.isNewsFeedChannel(type);

  if (
    frame.dashboard &&
    (isDialog || isChannel || isPurchaseRequestsChannel || isNewsFeedChannel) &&
    isCurrentMode(mode, 'chat')
  ) {
    return <ChatDashboard />;
  }

  if (frame.topicPanel && isTopic && isCurrentMode(mode, 'chat')) {
    return <TopicDashboard />;
  }

  if (isCurrentMode(mode, 'select')) {
    return <SelectModeDashboard />;
  }

  return null;
};

export default React.memo(DashboardPanelContainer);
