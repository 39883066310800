import React from 'react';
import DashboardContact from './contact';
import DashboardChat from './chat';
import DashboardArchive from './archive';

const ACTIVE_TABS = {
  CONTACTS: 'contacts',
  CHATS: 'chats',
  ARCHIVES: 'archives'
};

const CWSDashboardTabs = ({ activeTab, config = {} }) => {
  switch (activeTab) {
    case ACTIVE_TABS.CONTACTS:
      return <DashboardContact config={config.contacts} />;
    case ACTIVE_TABS.CHATS:
      return <DashboardChat config={config.chats} />;
    case ACTIVE_TABS.ARCHIVES:
      return <DashboardArchive />;
    default:
      return <div />;
  }
};

export default CWSDashboardTabs;
