import React, { useState } from 'react';
import { useLayer } from 'react-laag';
import './enclosure.styl';
import Item from '../ChatWidget/Dropdown/menu/item';

const EnclosureAttachmentMenu = ({ title, list }) => {
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen((prevState) => !prevState);
  const close = () => setOpen(false);
  const baseOptions = {
    placement: 'right-center',
    possiblePlacements: ['bottom-center', 'top-center', 'left-center'],
    auto: true,
    triggerOffset: 10,
    overflowContainer: false
  };

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    onOutsideClick: close,
    ...baseOptions
  });

  return (
    <div className="enclosure-dropdown-menu">
      <div
        className="enclosure-dropdown__title"
        {...triggerProps}
        onClick={() => toggleOpen()}>
        {title}
      </div>
      {renderLayer(
        isOpen && (
          <div
            ref={layerProps.ref}
            style={layerProps.style}
            className="enclosure-dropdown-menu__list open">
            {list.map((item) => (
              <Item
                key={item.value}
                icon={item.icon}
                color={item.color}
                value={item.value}
                helper={item.helper}
                helperPosition={item.helperPosition}
                selected={item.selected}
                onClick={() => {
                  close();
                  item.onClick();
                }}
              />
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default EnclosureAttachmentMenu;
