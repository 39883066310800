import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import CWMessages from '../../../main/chat-messages';
import {
  makeFavoriteMessageMV,
  theme,
  chat,
  user,
  messages,
  ownConfig,
  otherConfig,
  config,
  initHandlerMsg,
  amountLeftNext,
  initLoadMore,
  purchaseRequestMessageConfig
} from './dataType';
import useCounter from '../../../hooks/counter';
import { getFavoritesMsgs, getOpenedChat, getCurrUser } from '../../../getter';
import * as useCase from '../../../useCases';
import { usePlacement } from '../../../main/chat-items/hooks';

// NOTE: Example of config or favorites
// const ownConfig = {
//   extraBtn: { name: 'go-to-msg', tooltip: 'go-to-msg' },
//   buttonList: ['favorite'],
//   showReadMsg: false
// };
// const otherConfig = {
//   extraBtn: { name: 'go-to-msg', tooltip: 'go-to-msg' },
//   buttonList: ['favorite']
// };

const wrapper = ({ config: configParam }) => {
  const pageNumber = useCounter(1);
  const chatParam = useSelector(getOpenedChat);
  const messagesParam = useSelector(getFavoritesMsgs);
  const userParam = useSelector(getCurrUser);
  const handler = bindActionCreators(useCase.handleFavorites, useDispatch());

  return makeFavoriteMessageMV({
    pageNumber,
    handler,
    chat: chatParam,
    user: userParam,
    messages: messagesParam,
    config: configParam
  });
};

const CWFavoritesContent = (props) => {
  const viewModel = wrapper(props);
  const messagesPlacement = usePlacement();

  return (
    <div
      className={`cw-favorites-content cw-favorites-content_theme_${theme(
        viewModel
      )}`}>
      <CWMessages
        chat={chat(viewModel)}
        user={user(viewModel)}
        list={messages(viewModel)}
        handlerMsg={initHandlerMsg(viewModel)}
        onLoadMoreNext={initLoadMore(viewModel)}
        amountLeftNext={amountLeftNext(viewModel)}
        ownConfig={ownConfig(viewModel)}
        otherConfig={otherConfig(viewModel)}
        purchaseRequestConfig={purchaseRequestMessageConfig(viewModel)}
        config={config(viewModel)}
        messagesPlacement={messagesPlacement}
      />
    </div>
  );
};

export default CWFavoritesContent;
