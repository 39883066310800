import { getInterlocutor } from 'core/data/light/dialog';
import { compare } from 'core/lib/sortNatural';
import { isEmpty, isNil } from 'ramda';
import { max } from 'date-fns';
import { logUseCase } from '../../../../../utils/logger';

const log = logUseCase.extend('sortChatsCase');

export const sortChatsMethod = ({
  currentEmployeeId,
  sortBy: sortByArg,
  direction = 'down',
  chatsList
}) => {
  const type = sortByArg || 'sort-by-last-update';
  log(`sort type = ${type}, direction = ${direction}`);

  if (isNil(chatsList) || !Array.isArray(chatsList)) {
    throw new Error(`Expected array. But got: ${typeof chatsList}`);
  }
  const pinnedChats = chatsList.filter(isPinnedChat).map(setPinned);
  const restChats = chatsList.filter(isNotPinnedChat);
  const sortedChats = sort(restChats);

  return pinnedChats.concat(sortedChats);

  function sort(list) {
    switch (type) {
      case 'sort-by-last-update':
        return sortByLastUpdate(list, direction);
      case 'sort-by-creation-date':
        return sortByCreationDate(list);
      case 'sort-by-author':
        return sortByCreatorName(list);
      case 'sort-by-chat-name':
        return sortByName(list);
      default:
        return sortByLastUpdate(list, 'down');
    }
  }

  function sortByLastUpdate(list, direct = 'down') {
    return list
      .sort((dialog1, dialog2) => {
        const dt1 = getDate(dialog1);
        const dt2 = getDate(dialog2);
        const date1 = direct === 'up' ? dt2 : dt1;
        const date2 = direct === 'up' ? dt1 : dt2;
        if (!date1) {
          return -1;
        }
        if (!date2) {
          return 1;
        }
        const diff = new Date(date1) - new Date(date2);
        if (diff === 0) {
          return dialog2.name.localeCompare(dialog1.name);
        }
        return diff;
      })
      .reverse();

    function getDate(dialog) {
      const messageDate =
        dialog.lastMessage &&
        (dialog.lastMessage.date || dialog.lastMessage.time);

      const firedScheduleMessagesDate =
        dialog.scheduleMessages &&
        !isEmpty(dialog.scheduleMessages) &&
        dialog.scheduleMessages[0].isSent &&
        dialog.scheduleMessages[0].date;

      return getMaxDate(messageDate, firedScheduleMessagesDate);

      function getMaxDate(...listOfDates) {
        const onlyExistDates = listOfDates
          .filter((d) => d)
          .map((d) => new Date(d));

        return !isEmpty(onlyExistDates) ? max(onlyExistDates) : null;
      }
    }
  }

  function sortByCreationDate(list) {
    return list
      .sort((dialogA, dialogB) => {
        const dateA =
          direction === 'up' ? dialogB.createdAt : dialogA.createdAt;
        const dateB =
          direction === 'up' ? dialogA.createdAt : dialogB.createdAt;
        if (!dateA) {
          return -1;
        }
        if (!dateB) {
          return 1;
        }
        return new Date(dateA) - new Date(dateB);
      })
      .reverse();
  }

  function sortBy(fn, list) {
    return list.sort((dialogA, dialogB) => {
      const itemA = fn(dialogA);
      const itemB = fn(dialogB);
      return compare(itemA, itemB, direction);
    });
  }

  function sortByCreatorName(list) {
    return sortBy(getCreatorName, list);

    function getCreatorName(dialog) {
      if (isDialog(dialog)) {
        const interlocutor = getInterlocutor(currentEmployeeId, dialog);
        return interlocutor.userName;
      }

      return dialog.creator ? dialog.creator.userName : ' ';
    }
  }

  function sortByName(list) {
    return sortBy(getName, list);

    function getName(dialog) {
      switch (dialog.type) {
        case 'Channel':
        case 'Topic':
          return dialog.name || ' ';
        case 'Dialog':
          return getInterlocutor(currentEmployeeId, dialog)?.userName || ' ';
        default:
          return ' ';
      }
    }
  }

  function isPinnedChat(chat) {
    return chat.pinned;
  }

  function isNotPinnedChat(chat) {
    return !isPinnedChat(chat);
  }

  function setPinned(dialog) {
    return { ...dialog, pinned: true };
  }

  function isDialog(chat) {
    return chat.type === 'Dialog';
  }
};

export const sortChatsService = (
  { getCurrentEmployeeId },
  { type, direction, chatsList }
) =>
  sortChatsMethod({
    currentEmployeeId: getCurrentEmployeeId(),
    sortBy: type,
    direction,
    chatsList
  });
