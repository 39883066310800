import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { has } from 'ramda';
import {
  haveActivatedMS,
  haveAdminMR,
  haveMemberStatus,
  fromUserDB
} from 'core/data/light/channelMember';
import { MODERATION } from 'core/data/light/memberStatus';
import * as modalAction from '../../../action-creators/modal';
import * as temporaryAction from '../../../action-creators/temporary';
import * as channelUC from '../../../useCases/channel';
import * as modalSetsAction from '../../../action-creators/modalSets';
import {
  getTempField,
  getAllChannelMembers,
  getCurrentMember,
  checkIfCurrentUserIsParticipant,
  checkIfCurrentUserIsEditor,
  getContacts
} from '../../../storeGetters';
import Member from './member';

class Members extends Component {
  constructor(props) {
    super(props);
    this.onChangeMemberRole = this.onChangeMemberRole.bind(this);
    this.showModalChangeMemberRole = this.showModalChangeMemberRole.bind(this);
  }

  onChangeMemberRole(member, role) {
    const { setMemberRole } = this.props;
    setMemberRole(member.employeeId, role);
  }

  getModerationMembers() {
    const { inviteMembers, contacts } = this.props;

    return contacts.filter((user) => inviteMembers.includes(user.employeeId));
  }

  getMembers() {
    const { members, excludeMembers } = this.props;

    const activatedMemebers = members
      .filter(haveActivatedMS)
      .filter((member) => !excludeMembers.includes(member.employeeId));

    const candidates = this.getModerationMembers().map(fromUserDB);

    return candidates.concat(activatedMemebers);
  }

  getMemberRole(member) {
    const { membersRoles } = this.props;
    if (has(member.employeeId, membersRoles)) {
      return membersRoles[member.employeeId];
    }
    return member.memberRole;
  }

  isActivetedMember(employeeId) {
    const { members } = this.props;
    const index = members
      .filter(haveActivatedMS)
      .findIndex((member) => member.employeeId === employeeId);
    return index !== -1;
  }

  removeMember(employeeId) {
    const { addToExcludeMembers, removeInviteMembers } = this.props;

    if (this.isActivetedMember(employeeId)) {
      addToExcludeMembers(employeeId);
    } else {
      removeInviteMembers(employeeId);
    }
  }

  showModalChangeMemberRole(member) {
    const {
      showModal,
      editChannelModal,
      currentMemberHaveParticipantRole,
      editTopicMembersModal,
      getMemberRoleOptionsForEdit,
      mode
    } = this.props;
    const initialRole = this.getMemberRole(member);

    const onSubmit = (role) => {
      this.onChangeMemberRole(member, role);
      if (mode === 'Channel') {
        editChannelModal();
      } else if (mode === 'Topic') {
        editTopicMembersModal(currentMemberHaveParticipantRole);
      }
    };

    const onCancel = () => {
      if (mode === 'Channel') {
        editChannelModal();
      } else if (mode === 'Topic') {
        editTopicMembersModal(currentMemberHaveParticipantRole);
      }
    };

    const rolesOptions = getMemberRoleOptionsForEdit(mode);

    showModal('EDIT_CHANNEL_USER_ROLE', {
      mode,
      initialRole,
      rolesOptions,
      onSubmit,
      onCancel
    });
  }

  render() {
    const { currentMember, currentMemberHaveEditorRole, mode, readOnly } =
      this.props;

    const isAllowDelete = (member) => {
      if (currentMember.employeeId === member.employeeId) {
        return false;
      }
      if (haveAdminMR(currentMember)) {
        return true;
      }
      if (currentMemberHaveEditorRole) {
        return !haveAdminMR(member);
      }
      return haveMemberStatus(MODERATION, member);
    };
    const onDelete = (member) => this.removeMember(member.employeeId);

    return (
      <div className="members">
        {this.getMembers().map((member) => (
          <Member
            key={member.employeeId}
            readOnly={readOnly}
            mode={mode}
            currentMember={currentMember}
            member={member}
            memberRole={this.getMemberRole(member)}
            isCurrentMember={member.employeeId === currentMember.employeeId}
            isAllowDelete={isAllowDelete(member)}
            onChangeMemberRole={this.onChangeMemberRole}
            onDelete={() => onDelete(member)}
            showModalChangeMemberRole={this.showModalChangeMemberRole}
          />
        ))}
      </div>
    );
  }
}

Members.propTypes = {
  mode: PropTypes.string,
  readOnly: PropTypes.bool,
  setMemberRole: PropTypes.func.isRequired,
  addToExcludeMembers: PropTypes.func.isRequired,
  removeInviteMembers: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  editChannelModal: PropTypes.func.isRequired,
  editTopicMembersModal: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
  contacts: PropTypes.array.isRequired,
  membersRoles: PropTypes.object.isRequired,
  excludeMembers: PropTypes.array.isRequired,
  inviteMembers: PropTypes.array.isRequired,
  currentMember: PropTypes.object.isRequired,
  currentMemberHaveParticipantRole: PropTypes.bool.isRequired,
  currentMemberHaveEditorRole: PropTypes.bool.isRequired,
  getMemberRoleOptionsForEdit: PropTypes.func.isRequired
};

Members.defaultProps = {
  mode: 'Channel',
  readOnly: false
};

export default connect(
  (state) => ({
    membersRoles: getTempField(state, 'membersRoles', {}),
    excludeMembers: getTempField(state, 'excludeMembers', []),
    inviteMembers: getTempField(state, 'inviteMembers', []),
    members: getAllChannelMembers(state),
    currentMember: getCurrentMember(state),
    currentMemberHaveParticipantRole: checkIfCurrentUserIsParticipant(state),
    currentMemberHaveEditorRole: checkIfCurrentUserIsEditor(state),
    contacts: getContacts(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalAction.showModal,
        setMemberRole: temporaryAction.setToMapTemp('membersRoles'),
        addToExcludeMembers: temporaryAction.appendToSetTemp('excludeMembers'),
        removeInviteMembers: temporaryAction.removeFromSetTemp('inviteMembers'),
        editChannelModal: modalSetsAction.editChannel,
        editTopicMembersModal: modalSetsAction.editMembers,
        getMemberRoleOptionsForEdit: channelUC.getMemberRoleOptionsForEdit
      },
      dispatch
    )
)(Members);
