import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import {
  GridContent,
  Panel,
  Content,
  Main,
  Sidebar,
  Message
} from '../elements';
import CompMessage from '../../Message';
import './grid-content_theme_default.styl';

const b = block('grid-content');

class GridContentThemeDefault extends Component {
  getChildContext() {
    return { b };
  }

  render() {
    const {
      panel,
      content,
      contentMods,
      sidebar,
      innerStyle = {}
    } = this.props;
    return (
      <GridContent mods={{ theme: 'default' }}>
        <Panel>{panel}</Panel>
        <Main>
          <Content id="content" mods={{ theme: 'default', ...contentMods }}>
            <div className={b('content-inner')} style={innerStyle}>
              {content}
              <Message>
                <CompMessage />
              </Message>
            </div>
          </Content>
          {sidebar && <Sidebar>{sidebar}</Sidebar>}
        </Main>
      </GridContent>
    );
  }
}

GridContentThemeDefault.propTypes = {
  panel: PropTypes.element.isRequired,
  sidebar: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  contentMods: PropTypes.object
};

GridContentThemeDefault.defaultProps = {
  sidebar: false,
  content: '',
  contentMods: {}
};

GridContentThemeDefault.childContextTypes = {
  b: PropTypes.func
};

export default GridContentThemeDefault;
