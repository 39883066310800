import React from 'react';

/* eslint-disable max-len */
function IconTopic() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        d="M5 4.7L12.7625 2.80248C13.3924 2.64852 14 3.12547 14 3.77388V12.2261C14 12.8745 13.3924 13.3515 12.7625 13.1975L5 11.3V4.7Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        d="M2 5.5C2 4.94771 2.44772 4.5 3 4.5H4V11.5H3C2.44772 11.5 2 11.0523 2 10.5V5.5Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        d="M6 11.5H8V14.5H6V11.5Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconTopic;
