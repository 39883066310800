import { validateEmail } from './validateField';

export const syncValidation = (values) => {
  const errors = {};

  const email = values.get('email');
  const password = values.get('password');
  const repeatPassword = values.get('repeatPassword');

  if (!email) {
    errors.email = 'Required';
  } else if (!validateEmail(email)) {
    errors.email = 'Wrong email format';
  }

  if (!password) {
    errors.password = 'Required';
  }

  if (password && password.length < 8) {
    errors.password = 'Password min length 8 symbols';
  }

  if (!repeatPassword) {
    errors.repeatPassword = 'Required';
  }

  if (repeatPassword && password && password !== repeatPassword) {
    errors.repeatPassword = 'Password fields must be the same';
  }

  return errors;
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const asyncValidation = (values) =>
  sleep(1000) // simulate server latency
    .then(() => {
      if (['test@gmail.com'].includes(values.email)) {
        return Promise.reject(new Error({ email: 'That email is taken' }));
      }
      return '';
    });
