import React from 'react';
import PropTypes from 'prop-types';

import List from './list';

const FilesList = ({
  files = [],
  onClickCheckbox = () => {},
  isMaxFiles,
  handleScroll = () => {},
  out
}) => (
  <List
    files={files}
    onClickCheckbox={onClickCheckbox}
    isMaxFiles={isMaxFiles}
    handleScroll={handleScroll}
    out={out}
  />
);

FilesList.propTypes = {
  files: PropTypes.array.isRequired,
  onClickCheckbox: PropTypes.func.isRequired,
  isMaxFiles: PropTypes.bool.isRequired
};

export default FilesList;
