import React from 'react';
import { useTranslate } from '../../../../../../TranslateProvider';
import Avatar from '../../../../Avatar';
import Select from '../../../../Select';
import RolesHelper from '../../../../RolesHelper';
import { useBindMethods } from '../../../../../../lib/bindler';
import * as useCases from './useCases';
import {
  generateCNMemberDelete,
  getAvatarInfo,
  getCompanyName,
  getMemberName,
  getMode,
  getPossibleRoles,
  getRolesHint,
  getRole,
  initOnChangeMemberRole,
  initRemoveMember,
  isEditRoleDisabled,
  makeMemberVM,
  showMode
} from './dataType';
import '../style.css';

const withoutDisabledOptions = (options) =>
  options.filter((option) => !option.disabled);

const wrapper = (props) => {
  const t = useTranslate();
  return makeMemberVM({ t, ...props, ...useBindMethods(useCases) });
};

const Member = (props) => {
  const vm = wrapper(props);

  return (
    <div className="cw-modal-edit-topic-row">
      <div className="cw-modal-add-to-group-chat-row_container">
        <div className="cws-contact-item__avatar">
          <Avatar size={46} info={getAvatarInfo(vm)} />
        </div>
        <div className="cw-modal-add-to-group-chat-row_info">
          <div className="cw-modal-add-to-group-chat-row_info-name">
            {getMemberName(vm)}
          </div>
          <div className="cw-modal-add-to-group-chat-row_info-company">
            {getCompanyName(vm)}
          </div>
        </div>
      </div>
      {showMode(vm) === 'view' && (
        <div className="member__role-text">{getRole(vm)}</div>
      )}
      {showMode(vm) === 'edit' && (
        <>
          <div className="cw-modal-add-to-group-chat-row_member-role">
            <Select
              value={getRole(vm)}
              options={getPossibleRoles(vm)}
              onChange={initOnChangeMemberRole(vm)}
              multi={false}
              searchable={false}
              clearable={false}
              disabled={isEditRoleDisabled(vm)}
              filterOptions={withoutDisabledOptions}
            />
          </div>
          <div className="cw-modal-add-to-group-chat-row_ask">
            <RolesHelper options={getRolesHint(vm)} type={getMode(vm)} />
          </div>
          <div className="modal-add-to-group-chat-row_checkbox">
            <div
              className={generateCNMemberDelete(vm)}
              onClick={initRemoveMember(vm)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Member;
