import { getLinks, removeLink } from './links';
import { isCanRespond } from './requestMethods';
import { makeBtnSmallIcon } from './btnSmall';

/*
 * Data type for render links with buttons
 */

const iconsList = {
  delete: '/img/purchase-requests/trash.svg'
};

const getIconSrc = (name) => {
  if (iconsList[name]) return iconsList[name];
  throw new Error(`Not icon for name "${name}`);
};

export const makeLinkVM = ({ key, name, url, buttons = [] }) => ({
  key,
  name,
  url,
  buttons
});

const buttonForLink = (request, hookLinks, link) => {
  if (!isCanRespond(request)) return [];
  return [
    makeBtnSmallIcon({
      name: 'delete',
      icon: 'remove-2',
      onClick: () => removeLink(link.id, hookLinks)
    })
  ];
};

export const convertLinksToVM = (request, hookLinks) =>
  getLinks(hookLinks).map((link) =>
    makeLinkVM({
      key: link.id,
      name: link.name,
      url: link.url,
      buttons: buttonForLink(request, hookLinks, link)
    })
  );
