import React from 'react';
import { Checkbox, ButtonIcon, Typography } from '@link/react-components';
import classes from './GroupItem.module.css';

export function GroupItem({
  group,
  checked,
  indeterminate,
  disabled,
  onToggle,
  onEdit,
  onDelete
}) {
  return (
    <div className={classes.groupItem}>
      <div className={classes.content}>
        <Checkbox
          key={group.id}
          label={group.name}
          checked={checked}
          indeterminate={indeterminate}
          onChange={() => onToggle(group)}
          noWrap
          disabled={disabled}
          size={16}
        />
        <Typography variant="body1Reg">{`(${group.companiesList.length})`}</Typography>
        {group.new && <div className={classes.badgeNew} />}
      </div>
      <div className={classes.actions}>
        <ButtonIcon
          onClick={() => onEdit(group)}
          iconName="edit"
          variant="small"
        />
        <ButtonIcon
          onClick={() => onDelete(group)}
          iconName="trash"
          variant="small"
        />
      </div>
    </div>
  );
}
