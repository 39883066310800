import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../components/Loader';
import ErrorPartnerToken from '../components/ErrorPartnerToken';
import { DOMAIN, getPageRegToken } from '../lib/goodwix';
import { logView } from '../utils/logger';
import { withRouter } from '../hoc/withRouter';

import * as partnersActions from '../action-creators/partners';

class PartnerInvite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValidatingToken: true
    };
  }

  async UNSAFE_componentWillMount() {
    const { validateToken, match } = this.props;
    const { params } = match;
    const { token } = params;

    await validateToken(token);
    this.setState({ isValidatingToken: false });
  }

  render() {
    const { isValidatingToken } = this.state;
    const { valid, userId, match } = this.props;
    const { params } = match;
    const { token } = params;

    if (!isValidatingToken) {
      logView('!isValidatingToken');
      // User is authenticated and token is valid
      if (valid && userId) {
        logView('isAuthenticated and isValidToken');
        // eslint-disable-next-line no-restricted-globals
        location.replace(`${DOMAIN}/api/partners/invite/${token}`);
        return <div />;
      }

      // User is not authenticated and token is valid
      if (valid && !userId) {
        logView('!isAuthenticated and isValidToken');
        location.replace(getPageRegToken(token, 'partner'));
        return <div />;
      }

      // User is authenticated and token is not valid
      if (!valid && userId) {
        logView('isAuthenticated and !isValidToken');
        return <ErrorPartnerToken />;
      }

      // User is not authenticated and token is not valid
      if (!valid && !userId) {
        logView('!isAuthenticated and !isValidToken');
        return <ErrorPartnerToken />;
      }
    }

    logView('else is process of validating token');
    return <Loader theme="page" />;
  }
}

PartnerInvite.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired
    })
  }).isRequired,
  validateToken: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired
};

const mapState = (state) => ({
  valid: state.getIn(['partners', 'valid']),
  userId: state.getIn(['partners', 'userId']) || 0
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    { validateToken: partnersActions.validateToken },
    dispatch
  );

export default withRouter(connect(mapState, mapDispatch)(PartnerInvite));
