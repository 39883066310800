import React, { useEffect, useRef, useState } from 'react';
import { useIsMounted } from '../../../../../hooks/useIsMounted';
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver';
import { getPage } from '../../../utils/managePages';
import { Spinner } from '../../../components/Spinner/Spinner';

export const CoverInner = ({ doc, getPageIndex, renderSpinner }) => {
  const [src, setSrc] = useState('');
  const renderTask = useRef();
  const isMounted = useIsMounted();

  const containerRef = useIntersectionObserver({
    onVisibilityChanged: handleVisibilityChanged
  });

  function handleVisibilityChanged({ isVisible = false }) {
    if (src || !isVisible) {
      return;
    }

    const containerElem = containerRef.current;
    if (!containerElem) {
      return;
    }

    const { numPages } = doc;
    const targetPage =
      getPageIndex && typeof getPageIndex === 'function'
        ? getPageIndex({ numPages })
        : 0;
    const normalizePage = Math.max(0, Math.min(targetPage, numPages - 1));

    getPage(doc, normalizePage).then((page) => {
      const viewport = page.getViewport({ scale: 1 });
      const w = viewport.width;
      const h = viewport.height;

      const canvas = document.createElement('canvas');
      const canvasContext = canvas.getContext('2d', { alpha: false });

      const containerWidth = containerElem.clientWidth;
      const containerHeight = containerElem.clientHeight;

      const scaled = Math.min(containerWidth / w, containerHeight / h);
      const canvasWidth = scaled * w;
      const canvasHeight = scaled * h;

      canvas.height = canvasHeight;
      canvas.width = canvasWidth;
      canvas.style.opacity = '0';

      const renderViewport = page.getViewport({ rotation: 0, scale: scaled });

      renderTask.current = page.render({
        canvasContext,
        viewport: renderViewport
      });
      renderTask.current.promise.then(
        () => {
          if (isMounted.current) {
            setSrc(canvas.toDataURL());
            canvas.width = 0;
            canvas.height = 0;
          }
        },
        () => {}
      );
    });
  }

  useEffect(
    () => () => {
      if (renderTask.current) {
        renderTask.current.cancel();
      }
    },
    []
  );

  if (!src) {
    return (
      <div className="pdf-viewer-thumbnail__cover-loader" ref={containerRef}>
        {renderSpinner ? renderSpinner() : <Spinner />}
      </div>
    );
  }

  return <img className="pdf-viewer-thumbnail__cover-image" src={src} alt="" />;
};
