import React, { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';
import { useKey } from 'react-use';
import ChatDropzone from '../../../Dropzone/Chat';
import PinnedMessagesPanel from '../chat-pinned-message-panel';
import * as getters from '../../getter';
import * as useCase from '../../useCases';
import * as storeGetters from '../../../../storeGetters';
import Search from '../../modules/search';
import { isCurrentMode, isPrevMode } from '../../data-type/mode';
import Favorites from '../../modules/favoriteMessages';
import DelayedMessages from '../../modules/delayedMessages';
import ChatConfirm from '../chat-confirm';
import { useActions } from '../../../../hooks/useActions';
import { InputContainer } from './components/InputContainer';
import MessagesContainer from './components/MessagesContainer';
import DashboardPanelContainer from './components/DashboardPanelContainer';
import './style.css';

const CWMChat = React.memo(({ mode, config }) => {
  const haveFreeSpace = useSelector(storeGetters.haveFreeStorageSpace);
  const isEditMode = useSelector(getters.isEditMessageMode);
  const isMaxFiles = useSelector(getters.isInputMaxFilesLimitReached);
  const openedChatId = useSelector(getters.maybeGetOpenedChatId);

  const { handleInputMsg, handleDashboardSelectMode } = useActions(useCase);

  const { frame } = config;

  useKey('Escape', () => {
    if (isCurrentMode(mode, 'select')) {
      handleDashboardSelectMode('close');
    }
  });

  const onAttachLocalFiles = useCallback(
    (files) => handleInputMsg('attach-local', files),
    []
  );

  if (isNil(openedChatId)) return null;

  return (
    <>
      {!isCurrentMode(mode, 'chat') && !isCurrentMode(mode, 'select') && (
        <div className="cwn-chat-window-above">
          {(isCurrentMode(mode, 'favorites') ||
            isPrevMode(mode, 'favorites')) && (
            <Favorites config={config.favorites} />
          )}
          {(isCurrentMode(mode, 'search') || isPrevMode(mode, 'search')) && (
            <Search config={config.searchMsgs} />
          )}
          {isCurrentMode(mode, 'show-delayed-messages') && (
            <DelayedMessages config={config.delayedMessages} />
          )}
        </div>
      )}
      <ChatDropzone
        disabled={
          !isCurrentMode(mode, 'chat') ||
          isMaxFiles ||
          !haveFreeSpace ||
          !isEditMode
        }
        onDrop={onAttachLocalFiles}>
        <div className="cwm-chat">
          <DashboardPanelContainer mode={mode} />
          <ChatConfirm>
            <PinnedMessagesPanel />
            {frame.messages && <MessagesContainer mode={mode} />}
          </ChatConfirm>
          {frame.inputWrapper && <InputContainer mode={mode} />}
        </div>
      </ChatDropzone>
    </>
  );
});

export default React.memo(CWMChat);
