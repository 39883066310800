import { haveActivatedMS } from 'core/data/light/channelMember';
import { ADMIN, PARTICIPANT, EDITOR, READER } from 'core/data/light/memberRole';
import { getInterlocutor } from 'core/data/light/dialog';
import customHistory from '../customHistory';
import {
  setInfo,
  setFiles,
  setMessages,
  addMessage,
  clearChannel,
  addUnreadMsg,
  clearUnreadMsg,
  removeMessage,
  setMode,
  setSearch,
  clearSearch,
  updateMessage,
  setBookmarkedIds,
  clearBookmarkedIds
} from '../action-creators/channel';
import { getStorageSize } from '../action-creators/storage';
import * as contactsActions from '../action-creators/contacts';
import { gaSend } from '../action-creators/services';
import {
  setErrorMessage,
  setInfoMessage,
  setSuccessMessage
} from '../action-creators/message';
import {
  getChannelId,
  getAttachFiles,
  getCurrentUserBookmarkedMsg,
  getAllChannelMembers,
  getContacts,
  getChannelName,
  checkIfCurrentUserIsEditor
} from '../storeGetters';
import * as tempActions from '../action-creators/temporary';
import * as channelApi from '../storage/channel.storage';
import * as topicApi from '../modules/topic/storage';
import { filesLoadHandler, MODES } from './files';

import {
  editChannel as editChannelModal,
  addParticipantsToChannel,
  addParticipantsToTopic,
  viewChannel
} from '../action-creators/modalSets';
import {
  startLoader,
  finishLoader,
  resetLoader
} from '../action-creators/loaderList';
import { CHANNEL } from '../constants';

export const loadChannel = (channelId) => async (dispatch) => {
  dispatch(startLoader(CHANNEL));
  try {
    const channel = await channelApi.findChannel(channelId);
    dispatch(setInfo(channel));
  } finally {
    dispatch(finishLoader(CHANNEL));
  }
};

export const loadMessages = (channelId) => async (dispatch, getState) => {
  dispatch(startLoader(CHANNEL));
  try {
    const [channel, messages, files, infoFiles] = await Promise.all([
      channelApi.findChannel(channelId),
      channelApi.findMessages(channelId),
      channelApi.getUnsentFiles(channelId),
      topicApi.getTopicUnsaveInfoFiles(channelId)
    ]);
    if (channel.type === 'Dialog') {
      const currentUserEmployeeId = getState().getIn([
        'user',
        'user',
        'employeeId'
      ]);
      const interlocutor = getInterlocutor(currentUserEmployeeId, channel);
      contactsActions.getContactInfoByEmployeeId(interlocutor.employeeId)(
        dispatch
      );
    }
    dispatch(setFiles(files));
    dispatch(tempActions.setKeyValueTemp('filesToAdd', infoFiles));
    dispatch(setInfo(channel));
    dispatch(setMessages(messages));
  } finally {
    dispatch(finishLoader(CHANNEL));
    getStorageSize()(dispatch);
    contactsActions.loadContactsForGroupChat()(dispatch);
  }
};

export const clear = () => (dispatch) => {
  contactsActions.clearContactsForGroupChat()(dispatch);
  dispatch(resetLoader(CHANNEL));
  dispatch(clearChannel());
};

export const subscribeToUpdate = (channelId) => (dispatch) => {
  channelApi.joinToChannel(channelId);
  channelApi.subscribeToUpdateChannel((channel) => dispatch(setInfo(channel)));
  channelApi.subscribeToNewMsg((msg) => dispatch(addMessage(msg)));
  channelApi.subToAddUnreadMsg(({ channelId: id, messageId }) =>
    dispatch(addUnreadMsg(id, messageId))
  );
  channelApi.subToClearUnreadMsg(({ channelId: id }) =>
    dispatch(clearUnreadMsg(id))
  );
  channelApi.subscribeToUpdateMsg(({ channelId: id, messageId, message }) =>
    dispatch(updateMessage(id, messageId, message))
  );
  channelApi.subscribeToRemoveMsg(({ channelId: id, messageId }) =>
    dispatch(removeMessage(id, messageId))
  );
};

export const unsubscribeToUpdate = (channelId) => () => {
  channelApi.unsubscribeFromNewMsg();
  channelApi.unsubscribeToUpdateChannel();
  channelApi.unsubToAddUnreadMsg();
  channelApi.unsubToClearUnreadMsg();
  channelApi.unsubscribeToRemoveMsg();
  channelApi.unsubscribeFromUpdateMsg();
  channelApi.leaveFromChannel(channelId);
};

export const sendMessage =
  (userMsg, files, forwardedMessages = []) =>
  async (dispatch, getState) => {
    const channelId = getChannelId(getState());

    channelApi.addMessage(channelId, userMsg, files, forwardedMessages);
    dispatch(setFiles([]));
  };

export const showFormForEditChannel = () => (dispatch) =>
  dispatch(editChannelModal());
export const showChannelInfo = () => (dispatch) => dispatch(viewChannel());

export const updateChannel = (config) => (dispatch, getState) => {
  const channelId = getChannelId(getState());
  channelApi.updateChannel(channelId, config);
};

export const showFormAddToChannel = () => async (dispatch, getState) => {
  const state = getState();

  const name = getChannelName(state);
  const channelMembers = getAllChannelMembers(state);
  const contacts = getContacts(state);
  const activatedMembersId = channelMembers
    .filter(haveActivatedMS)
    .map((member) => member.employeeId);

  const dialogs = contacts.filter(
    (user) => !activatedMembersId.includes(user.employeeId)
  );
  const members = contacts.filter((user) =>
    activatedMembersId.includes(user.employeeId)
  );

  const submit = (inviteMembers, membersRoles) => {
    dispatch(
      updateChannel({ name, excludeMembers: [], inviteMembers, membersRoles })
    );
  };

  return dispatch(
    addParticipantsToChannel({
      submit,
      dialogs,
      members,
      btnSubmitText: 'Add',
      purpose: 'Add'
    })
  );
};

export const showFormAddToTopic = () => async (dispatch, getState) => {
  const state = getState();

  const name = getChannelName(state);
  const channelMembers = getAllChannelMembers(state);
  const contacts = getContacts(state);
  const activatedMembersId = channelMembers
    .filter(haveActivatedMS)
    .map((member) => member.employeeId);

  const dialogs = contacts.filter(
    (user) => !activatedMembersId.includes(user.employeeId)
  );
  const members = contacts.filter((user) =>
    activatedMembersId.includes(user.employeeId)
  );

  const submit = (inviteMembers, membersRoles) => {
    dispatch(
      updateChannel({ name, excludeMembers: [], inviteMembers, membersRoles })
    );
  };

  return dispatch(
    addParticipantsToTopic({
      submit,
      dialogs,
      members,
      btnSubmitText: 'Add',
      purpose: 'Add'
    })
  );
};

export const getMemberRoleOptionsForCreate =
  (mode = 'Channel') =>
  (_, getState) => {
    const state = getState();
    const isEditor = checkIfCurrentUserIsEditor(state);

    switch (mode) {
      case 'Channel':
        return [
          { value: ADMIN, disabled: false },
          { value: PARTICIPANT, disabled: false }
        ];
      case 'Topic':
        return [
          { value: ADMIN, disabled: isEditor },
          { value: EDITOR, disabled: false },
          { value: PARTICIPANT, disabled: false },
          { value: READER, disabled: false }
        ];
      default:
        return [];
    }
  };

export const getMemberRoleOptionsForEdit =
  (mode = 'Channel') =>
  (_, getState) => {
    const state = getState();
    const isEditor = checkIfCurrentUserIsEditor(state);

    switch (mode) {
      case 'Channel':
        return [
          { value: ADMIN, disabled: false },
          { value: PARTICIPANT, disabled: false }
        ];
      case 'Topic':
        return [
          { value: ADMIN, disabled: isEditor },
          { value: EDITOR, disabled: false },
          { value: PARTICIPANT, disabled: false },
          { value: READER, disabled: false }
        ];
      default:
        return [];
    }
  };

export const quitFromChannel = (translatedText) => (_, getState) => {
  const confirmed = confirm(translatedText);
  if (confirmed) {
    const channelId = getChannelId(getState());
    channelApi.quitFromChannel(channelId);
    customHistory.push('/dialogs');
  }
};

export const restore = (channelId) => async (dispatch) => {
  channelApi.restoreAccesstoChannel(channelId);

  const messages = await channelApi.findMessages(channelId);
  dispatch(setMessages(messages));
};

export const removeMsg = (msgId, mainMsgId) => (dispatch, getState) => {
  const channelId = getChannelId(getState());
  channelApi.removeMsg(channelId, msgId, mainMsgId);
  dispatch(
    gaSend({
      category: 'Common',
      action: 'group_chat_delete_mes',
      label: getState().getIn(['user', 'user', 'email'])
    })
  );
};

export const updateMsg =
  (msgId, data = {}) =>
  (dispatch, getState) => {
    const channelId = getChannelId(getState());
    channelApi.updateMsg(channelId, msgId, data);
    dispatch(
      gaSend({
        category: 'Common',
        action: 'group_chat_delete_mes',
        label: getState().getIn(['user', 'user', 'email'])
      })
    );
  };

export const attachFile = (channelId, file) => async (dispatch) => {
  const fileName = file.name;
  await channelApi.attachUnsentFile(channelId, fileName, file);
  const files = await channelApi.getUnsentFiles(channelId);
  dispatch(setFiles(files));
};

export const onDeleteUnsentFile = (file) => async (dispatch, getState) => {
  const channelId = getChannelId(getState());
  await channelApi.removeUnsentFiles(channelId, file.id);
  const files = await channelApi.getUnsentFiles(channelId);
  dispatch(setFiles(files));
};

export const copyFileToStorage = (file) => async (dispatch, getState) => {
  const channelId = getChannelId(getState());

  await channelApi.copyFileToStorage(channelId, file.id);

  dispatch(setSuccessMessage({ key: 'attach_copy_success' }));
};

export const removeFile = (messageId, file) => async (_, getState) => {
  const channelId = getChannelId(getState());

  await channelApi.removeFile(channelId, messageId, file.id);
};

export const setModeView = () => (dispatch) => {
  dispatch(setMode('view'));
  dispatch(clearBookmarkedIds());
};

export const setModeSearch = () => (dispatch) => {
  dispatch(setMode('search'));
};

export const setModeBookmarks = () => (dispatch, getState) => {
  const bookmarkedMessages = getCurrentUserBookmarkedMsg(getState());
  const bookmarkedIds = bookmarkedMessages.map(({ id }) => id);
  dispatch(setBookmarkedIds(bookmarkedIds));
  dispatch(setMode('bookmarks'));
};

export const setSearchValues = (search) => (dispatch) => {
  dispatch(setSearch(search));
};

export const clearSearchValues = () => (dispatch) => {
  dispatch(clearSearch());
};

export const addBookmarkToMsg = (message) => (_, getState) => {
  const channelId = getChannelId(getState());
  channelApi.addBookmarkToMsg(channelId, message.id);
};

export const removeBookmarkFromMsg = (message) => (_, getState) => {
  const channelId = getChannelId(getState());
  channelApi.removeBookmarkFromMsg(channelId, message.id);
};

export const loadContacts = () => async (dispatch) => {
  await contactsActions.loadContactsForGroupChat()(dispatch);
};

export const clearContacts = () => async (dispatch) => {
  await contactsActions.clearContactsForGroupChat()(dispatch);
};

export const getMessagesForForward = (channelId, msgs) => () =>
  channelApi.getForwardMessages(channelId, msgs);

export const filesLoadHandlerChannel =
  (files) => async (dispatch, getState) => {
    const state = getState();
    const channelId = getChannelId(state);
    const attachedFiles = getAttachFiles(state);

    await filesLoadHandler({
      mode: MODES.Exclude,
      files,
      attachedFiles,
      beforeLoading: () =>
        dispatch(setInfoMessage({ key: 'File upload started' })),
      loadFile: async (file) => attachFile(channelId, file)(dispatch, getState),
      onError: (keyName) => dispatch(setErrorMessage({ key: keyName }))
    });
  };

export const toggleClose = () => (dispatch, getState) => {
  const channelId = getChannelId(getState());
  channelApi.closeChannel(channelId, (isClosed) => {
    if (!isClosed) {
      dispatch(setSuccessMessage({ key: 'topic.notify-restored' }));
    }
  });
};
