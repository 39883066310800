const { all, includes, __, reduce, isEmpty } = require('ramda');
const { defStruct } = require('../../lib');
const {
  makeSelectedList,
  getIds,
  add,
  contains,
  remove,
  isEmpty: isEmptySelectedList
} = require('../selectedList');
const { makeDataPart, getAllIds } = require('../dataPart');

/**
 * @typedef Select
 * @typedef {import('../dataPart')} DataPart
 * @typedef {import('../selectedList')} SelectedList
 */
const { makeSelect, getDataPart, getSelectedList, isSelect } = defStruct(
  'Select',
  ['dataPart', 'selectedList']
);

/**
 * Creates a select data type
 *
 * @param {object} params
 * @param {DataPart} params.dataPart
 * @param {SelectedList} params.selectedList
 * @returns {Select}
 */
exports.makeSelect = ({
  dataPart = makeDataPart(),
  selectedList = makeSelectedList()
} = {}) => makeSelect(dataPart, selectedList);

/**
 * Checks if is select
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isSelect = (a) => isSelect(a);

/**
 * Checks if all ids data part is selected
 *
 * @param {Select} select
 * @returns {boolean}
 */
const isSelectedAllDataPartIds = (select) => {
  const selectedListIds = getIds(getSelectedList(select));
  const dataPartAllIds = getAllIds(getDataPart(select));

  if (isEmpty(selectedListIds) && isEmpty(dataPartAllIds)) return false;

  return all(includes(__, selectedListIds), dataPartAllIds);
};
exports.isSelectedAllDataPartIds = isSelectedAllDataPartIds;

/**
 * Adds or removes an item from the list, depending on whether it exists in the list or not
 *
 * @param {number} id
 * @param {Select} select
 * @returns {SelectedList}
 */
exports.toggleItem = (id, select) => {
  const selectedList = getSelectedList(select);

  return contains(id, selectedList)
    ? remove(id, selectedList)
    : add(id, selectedList);
};

/**
 * Checks if item is selected
 *
 * @param {number} id
 * @param {Select} select
 * @returns {boolean}
 */
exports.isSelectedItem = (id, select) => contains(id, getSelectedList(select));

/**
 * Adds or removes all elements from the list, depending on whether they exist in the list
 *
 * @param {Select} select
 * @returns {SelectedList}
 */
exports.toggleAllItems = (select) => {
  const selectedList = getSelectedList(select);
  const dataPartAllIds = getAllIds(getDataPart(select));

  if (isEmptySelectedList(selectedList))
    return makeSelectedList({ ids: dataPartAllIds });

  if (isSelectedAllDataPartIds(select)) {
    return reduce((acc, id) => remove(id, acc), selectedList, dataPartAllIds);
  }

  return reduce((acc, id) => add(id, acc), selectedList, dataPartAllIds);
};
