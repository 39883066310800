import React from 'react';

/* eslint-disable max-len */
function IconArrowUpCircle({ width = 28, height = 28 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="13.5" stroke="#EBEEF4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 10.293L19.3536 15.6466L18.6465 16.3537L14 11.7072L9.35352 16.3537L8.64641 15.6466L14 10.293Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconArrowUpCircle;
