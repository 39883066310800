import request from '../lib/request';
import {
  ResponseNotificationSettingsDto,
  UpdateNotificationSettingsDto
} from '../DTOs';

class NotificationSettingsService {
  #baseUrl = '/api/notification-settings';

  async get() {
    const response = await request.get(this.#baseUrl);
    return new ResponseNotificationSettingsDto(response);
  }

  async update(payload) {
    const updateDto = new UpdateNotificationSettingsDto(payload);

    const response = await request.patch(this.#baseUrl, updateDto);
    return new ResponseNotificationSettingsDto(response);
  }

  async turnOffEmailNotificationsByToken(token) {
    return await request.get(`${this.#baseUrl}/${token}/off`);
  }
}

export const notificationSettingsService = new NotificationSettingsService();
