import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isCurrentMode } from '../../../../data-type/mode';
import Messages from '../../../chat-items';
import * as getters from '../../../../getter';
import {
  getList as getListUnsent,
  hasList as hasListUnsent
} from '../../../../data-type/block-list-unsent';
import { useMessengerConfig } from '../../../../Messenger/hooks/useMessengerConfig';

const getUnsentMsgs = (unsentMsg, openedChat) =>
  hasListUnsent(unsentMsg, openedChat.id)
    ? getListUnsent(unsentMsg, openedChat.id).list
    : [];

const MessagesContainer = ({ mode }) => {
  const { messages } = useMessengerConfig();

  const user = useSelector(getters.getCurrUser);
  const openedChat = useSelector(getters.getOpenedChat);
  const blockMessages = useSelector(getters.getChatMsgs);
  const unsentMsg = useSelector(getters.getChatUnsent);

  const chatMessages = useMemo(
    () => [...blockMessages.list, ...getUnsentMsgs(unsentMsg, openedChat)],
    [blockMessages.list, unsentMsg, openedChat.id]
  );

  return (
    <Messages
      chat={openedChat}
      user={user}
      list={chatMessages}
      amountLeftPrev={blockMessages.amountLeftPrev}
      amountLeftNext={blockMessages.amountLeftNext}
      selectedList={blockMessages.selectIds}
      isSelectMode={isCurrentMode(mode, 'select')}
      config={messages}
      isPossibleShowDivider={isCurrentMode(mode, 'chat')}
    />
  );
};

export default React.memo(MessagesContainer);
