import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import * as useCase from '../../useCases';
import { useTranslate } from '../../../../TranslateProvider';
import { useActions } from '../../../../hooks/useActions';
import './style.css';

const CWChatEditPanel = ({ message }) => {
  const t = useTranslate();
  const { handleEditMsgPanel } = useActions(useCase);

  if (isEmpty(message)) return null;

  return (
    <div className="edit-msg">
      <div className="text-wrap">{t('msg_editing')}</div>
      <div
        className="edit-msg_close"
        onClick={() => handleEditMsgPanel('cancel')}>
        <img src="/img/cross.svg" width="10" role="presentation" alt="" />
      </div>
    </div>
  );
};

CWChatEditPanel.propTypes = {
  message: PropTypes.object.isRequired
};

export default CWChatEditPanel;
