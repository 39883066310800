import React from 'react';
import CommonLoader from '../../../../../Loader';

const Loader = ({ isShow }) => {
  if (!isShow) return null;

  return (
    <div style={{ padding: '10px 0' }}>
      <CommonLoader />
    </div>
  );
};

export default React.memo(Loader);
