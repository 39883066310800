import React from 'react';
import block from 'bem-cn-lite';

import './style.styl';

const b = block('checkbox-new');

const Checkbox = ({
  set,
  onClick = () => {},
  readOnly = false,
  withoutRightMargin = false,
  style = {}
}) => (
  <span
    className={b({
      set,
      'margin-right-0': withoutRightMargin,
      read_only: readOnly
    })} // eslint-disable-line camelcase
    onClick={(e) => !readOnly && onClick(e)}
    style={{ ...style }}>
    <img className={b('img')} src="/img/ok-1.svg" alt="ok" />
  </span>
);

export default Checkbox;
