export const submit =
  (
    { showModal, setInviteMembers, setMemberRole, gaSend },
    { chat, mode, name }
  ) =>
  (inviteMembersIds, inviteMembersRoles, isShowPrevious) => {
    setInviteMembers(inviteMembersIds);
    inviteMembersRoles.forEach(([member, role]) => setMemberRole(member, role));
    showModal('CW_GROUP_CHAT_EDIT', {
      chat: { ...chat, name },
      mode,
      isShowPrevious
    });
    gaSend('group_chat_add_members');
  };

export const cancel =
  ({ showModal }, { chat, mode, name }) =>
  () => {
    showModal('CW_GROUP_CHAT_EDIT', {
      chat: { ...chat, name },
      mode
    });
  };

export const showModalPickNewMembersCase = (
  {
    getInviteMembers,
    getMembersRoles,
    showModal,
    setInviteMembers,
    setMemberRole,
    gaSend
  },
  { chat, mode, name }
) => {
  const params = { chat, mode, name };

  const inviteMembers = getInviteMembers();
  const membersRoles = getMembersRoles();

  showModal('CW_GROUP_CHAT_ADD_MEMBERS', {
    submit: submit(
      { showModal, setInviteMembers, setMemberRole, gaSend },
      params
    ),
    resetMembersIds: true,
    resetMembersRoles: true,
    initialMemberIds: inviteMembers,
    initialMemberRoles: Object.entries(membersRoles),
    btnSubmitText: 'Add',
    cancel: cancel({ showModal }, params),
    members: chat.members,
    actionType: 'Edit',
    mode
  });
};
