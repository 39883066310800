import React from 'react';
import classNames from 'classnames';
import { useSpinner } from '../../hooks/useSpinner';

export const PrintProgress = () => {
  const isRtl = false;
  const { renderSpinner } = useSpinner();

  return (
    <div className="pdf-viewer-print__progress">
      <div className="pdf-viewer-print__progress-blurred-background" />
      <div
        className={classNames({
          'pdf-viewer-print__progress-body': true,
          'pdf-viewer-print__progress-body_rtl': isRtl
        })}>
        {renderSpinner()}
        <div className="pdf-viewer-print__progress-bar">
          <div />
        </div>
      </div>
    </div>
  );
};
