import React from 'react';
import { useSelector } from 'react-redux';
import DashboardSelectMode from '../../../../../select-mode/header';
import { useMessengerConfig } from '../../../../../../Messenger/hooks/useMessengerConfig';
import * as getters from '../../../../../../getter';

const SelectModeDashboard = () => {
  const { dashboard } = useMessengerConfig();

  const openedChat = useSelector(getters.getOpenedChat);

  return <DashboardSelectMode chat={openedChat} theme={dashboard.theme} />;
};

export default React.memo(SelectModeDashboard);
