import React from 'react';
import { useActions, useTranslate } from '@hooks';
import ModalSubmitWithComment from '../ModalSubmitWithComment';
import * as storage from '../../PurchaseRequests/Responses/storage';
import { formatterText } from '../../../utils/utils';

const ModalSendToReworkRespond = ({
  requestId,
  responseId,
  companyName,
  onSuccessedSubmit = () => {}
}) => {
  const t = useTranslate();
  const { sendToRework } = useActions(storage);

  const onSubmit = (text) => {
    sendToRework({ requestId, responseId, text });
    onSuccessedSubmit();
  };

  return (
    <ModalSubmitWithComment
      title={formatterText(t('confirm_sending_to_rework', { companyName }))}
      defaultInputText={t('default_rework_text')}
      onSubmit={onSubmit}
      maxInputSymbols={250}
    />
  );
};

export default ModalSendToReworkRespond;
