import { fromUserDB, haveActivatedMS } from 'core/data/light/channelMember';
import {
  getContacts,
  getTempExcludedMembers,
  getTempInvitedMembers
} from '../../../../../storeGetters';

export const getMembers =
  ({ members }) =>
  (state) => {
    const allPossibleMembers = getContacts(state);
    const inviteMembers = getTempInvitedMembers(state);
    const excludeMembers = getTempExcludedMembers(state);

    const activatedMembers = members
      .filter(haveActivatedMS)
      .filter(withoutTempExcluded);

    const candidates = allPossibleMembers
      .filter(alreadyTempInvite)
      .map(fromUserDB);

    return candidates.concat(activatedMembers);

    function withoutTempExcluded(member) {
      return !excludeMembers.includes(member.employeeId);
    }

    function alreadyTempInvite(member) {
      return inviteMembers.includes(member.employeeId);
    }
  };
