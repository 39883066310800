import React from 'react';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';
import { startOfToday } from 'date-fns';
import Icon from 'react-components/src/icons';
import iconsView from 'react-components/src/icons/constants';
import DateTimePicker from '../../../Datepicker/dateTimePicker';
import { useTranslate } from '../../../../../TranslateProvider';
import * as getters from '../getters';
import * as cases from '../useCases';
import './style.css';
import { ChatButton } from '../../../Components/Editor/new-components/chat-button/ChatButton';
import { useActions } from '../../../../../hooks/useActions';

const MIN_DATE = startOfToday();

const PickerIcon = ({ isActive = false }) => (
  <div className="cw-planned-date-picker-wrapper">
    <ChatButton icon={<Icon icon={iconsView.IconData} size={18} />} />
    {isActive && <span className="cw-planned-date-picker-dot" />}
  </div>
);

export const PlannedDatePicker = () => {
  const t = useTranslate();
  const plannedDate = useSelector(getters.getDelayMessagesPlannedDate);
  const { setPlannedDate, clearPlannedDate } = useActions(cases);
  return (
    <div className="cw-planned-date-picker">
      <DateTimePicker
        t={t}
        value={plannedDate}
        onSubmit={setPlannedDate}
        onClear={clearPlannedDate}
        icon={<PickerIcon isActive={!isNil(plannedDate)} />}
        minDate={MIN_DATE}
        minTime={new Date()}
        placement="right-start"
        isCloseAfterClear
      />
    </div>
  );
};
