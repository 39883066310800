import React from 'react';
import { useSelector } from 'react-redux';
import Panel from '../../../../../Topic';
import { useMessengerConfig } from '../../../../../../Messenger/hooks/useMessengerConfig';
import * as getters from '../../../../../../getter';

const TopicDashboard = () => {
  const { topic } = useMessengerConfig();
  const openedChat = useSelector(getters.getOpenedChat);
  const isActive = useSelector(getters.checkCurrentMemberHaveActivedMS);

  return <Panel topic={openedChat} isActive={isActive} config={topic} />;
};

export default React.memo(TopicDashboard);
