import React from 'react';
import LastMessage from './last-message';
import {
  generateChatInfoVM,
  generateCNChat,
  generateLastMessageVM,
  getAvatarInfo,
  initOnClick,
  isShowLastMessage
} from './dataType';
import Avatar from '../../../../Components/Cards/Chat/components/Avatar';
import ChatInfo from './info';
import './style.css';

const ChatItem = ({ vm }) => (
  <div className={generateCNChat(vm)} onClick={initOnClick(vm)}>
    <div className="cws-chat-item__avatar">
      <Avatar {...getAvatarInfo(vm)} />
    </div>
    <div className="cws-chat-item__desc">
      <div className="cws-chat-item__info">
        <ChatInfo vm={generateChatInfoVM(vm)} />
      </div>
      <div className="cws-chat-item__last-message-info">
        {isShowLastMessage(vm) && (
          <LastMessage vm={generateLastMessageVM(vm)} />
        )}
      </div>
    </div>
  </div>
);

export default ChatItem;
