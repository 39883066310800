import { isType } from 'core/lib';
import { PURCHASE_REQUESTS_CHANNEL } from 'core/data/light/purchaseRequestChannel';
import {
  filterMenu,
  ADMIN,
  EDITOR,
  READER,
  NOTACTIVE,
  PARTICIPANT
} from '../../../../Dropdown/lib';
import {
  haveFullPermission,
  haveEditPermission,
  haveParticipantPermission,
  haveActivedMS
} from '../../../../roles';

const dialogMenu = [
  { icon: 'archive', value: 'archive' },
  {
    conditionName: 'pinned?',
    items: [
      { icon: 'pin', value: 'pin' },
      { icon: 'unpin', value: 'unpin' }
    ]
  },
  { icon: 'folder', value: 'add-to-list' },
  {
    conditionName: 'notice-on?',
    items: [
      { icon: 'notice-off', value: 'notice-off' },
      { icon: 'notice-on', value: 'notice-on' }
    ]
  }
];

const channelPermissions = {
  edit: [ADMIN],
  archive: [ADMIN, READER, NOTACTIVE],
  pin: [ADMIN, READER, NOTACTIVE],
  unpin: [ADMIN, READER, NOTACTIVE],
  'add-to-list': [ADMIN, READER, NOTACTIVE],
  'notice-off': [ADMIN, READER],
  'notice-on': [ADMIN, READER],
  'leave-channel': [ADMIN, READER]
};
const channelMenu = [
  { icon: 'edit', value: 'edit' },
  { icon: 'archive', value: 'archive' },
  {
    conditionName: 'pinned?',
    items: [
      { icon: 'pin', value: 'pin' },
      { icon: 'unpin', value: 'unpin' }
    ]
  },
  { icon: 'folder', value: 'add-to-list' },
  {
    conditionName: 'notice-on?',
    items: [
      { icon: 'notice-off', value: 'notice-off' },
      { icon: 'notice-on', value: 'notice-on' }
    ]
  },
  { icon: 'leave', value: 'leave-channel' }
];

const topicPermissions = {
  edit: [ADMIN, EDITOR],
  view: [PARTICIPANT],
  archive: [ADMIN, EDITOR, READER, PARTICIPANT, NOTACTIVE],
  unarchive: [ADMIN, EDITOR, READER, PARTICIPANT, NOTACTIVE],
  pin: [ADMIN, EDITOR, READER, PARTICIPANT, NOTACTIVE],
  unpin: [ADMIN, EDITOR, READER, PARTICIPANT, NOTACTIVE],
  'add-to-list': [ADMIN, EDITOR, READER, PARTICIPANT, NOTACTIVE],
  'notice-off': [ADMIN, EDITOR, READER],
  'notice-on': [ADMIN, EDITOR, READER],
  'leave-topic': [ADMIN, EDITOR, READER, PARTICIPANT],
  'close-topic': [ADMIN]
};

const topicMenu = [
  { icon: 'view', value: 'view' },
  { icon: 'edit', value: 'edit' },
  { icon: 'archive', value: 'archive' },
  {
    conditionName: 'pinned?',
    items: [
      { icon: 'pin', value: 'pin' },
      { icon: 'unpin', value: 'unpin' }
    ]
  },
  { icon: 'folder', value: 'add-to-list' },
  {
    conditionName: 'notice-on?',
    items: [
      { icon: 'notice-off', value: 'notice-off' },
      { icon: 'notice-on', value: 'notice-on' }
    ]
  },
  { icon: 'leave', value: 'leave-topic' },
  { icon: 'close', value: 'close-topic' }
];

const purchaseRequestChannelMenu = [
  {
    conditionName: 'pinned?',
    items: [
      { icon: 'pin', value: 'pin' },
      { icon: 'unpin', value: 'unpin' }
    ]
  },
  { icon: 'folder', value: 'add-to-list' },
  {
    conditionName: 'notice-on?',
    items: [
      { icon: 'notice-off', value: 'notice-off' },
      { icon: 'notice-on', value: 'notice-on' }
    ]
  },
  { icon: 'minus-round', value: 'unsubscribe-purchase-requests' }
  // {
  //   conditionName: 'active?',
  //   items: [{ icon: 'minus-circle', value: 'unsubscribe-purchase-requests' }]
  // }
];

const getRoleOfTopic = (chat, user) => {
  const isActive = haveActivedMS(chat, user);
  if (!isActive || chat.isClosed) return NOTACTIVE;
  if (haveFullPermission(chat, user)) return ADMIN;
  if (haveEditPermission(chat, user)) return EDITOR;
  if (haveParticipantPermission(chat, user)) return PARTICIPANT;
  return READER;
};

const getRoleOfChannel = (chat, user) => {
  const isActive = haveActivedMS(chat, user);
  if (!isActive) return NOTACTIVE;
  return haveEditPermission(chat, user) ? ADMIN : READER;
};

const excludeFromUnsubscribe = ['unsubscribe-purchase-requests'];
function getPurchaseRequestOptions(chat) {
  let result = purchaseRequestChannelMenu;

  if (!chat.active) {
    result = purchaseRequestChannelMenu.filter(
      (option) => !excludeFromUnsubscribe.includes(option.value)
    );
  }

  return result;
}

export default (chat, user) => {
  if (chat.archived) return [{ icon: 'archive', value: 'unarchive' }];

  if (isType('Dialog', chat)) {
    return dialogMenu;
  }

  if (isType('Channel', chat)) {
    const role = getRoleOfChannel(chat, user);
    return filterMenu(channelPermissions, role, channelMenu);
  }

  if (isType('Topic', chat)) {
    const role = getRoleOfTopic(chat, user);
    return filterMenu(topicPermissions, role, topicMenu);
  }

  if (isType(PURCHASE_REQUESTS_CHANNEL, chat)) {
    return getPurchaseRequestOptions(chat);
  }

  return [];
};
