import React, { useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const DEFAULT_THUMB_COLOR = '#767b92';

const Scrollbar = ({
  onScroll = () => {},
  horizontalBottomThumbPosition = 59,
  ...mainProps
}) => {
  const scrollbarsRef = useRef(null);

  return (
    <Scrollbars
      renderThumbHorizontal={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            bottom: `${horizontalBottomThumbPosition}px`,
            borderRadius: '2px',
            backgroundColor: DEFAULT_THUMB_COLOR
          }}
        />
      )}
      renderThumbVertical={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            borderRadius: '2px',
            backgroundColor: DEFAULT_THUMB_COLOR
          }}
        />
      )}
      renderView={({ style, ...props }) => (
        <div {...props} style={{ ...style, marginBottom: '-10px' }} />
      )}
      onScroll={(event) => onScroll({ event, scrollbarsRef })}
      {...mainProps}
      ref={scrollbarsRef}
    />
  );
};

export default Scrollbar;
