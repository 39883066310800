import { jumpToMsg } from '../../../useCases';
import * as messages from '../message';
import { pinMsgCase } from './pin';
import { unpinMsgCase } from './unpin';

export const jumpToScheduleMessage = (message) => (dispatch) =>
  dispatch(jumpToMsg(message));

export const pinMsg = (channelId, messageId) => () =>
  pinMsgCase(
    { sendPinMsg: messages.sendPinMessageCMD },
    { channelId, messageId }
  );

export const unpinMsg = (channelId, messageId) => () =>
  unpinMsgCase(
    { sendUnpinMsg: messages.sendUnpinMessageCMD },
    { channelId, messageId }
  );
