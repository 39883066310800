import { Map, List, Set, fromJS } from 'immutable';

const newSelectedGroups = () => new Set();

export const initState = () =>
  new Map({
    firstUploaded: false,
    groups: List(),
    selected: Set(),
    selectedGroup: newSelectedGroups(),
    currentGroup: Map(),
    requests: List(),
    invites: List(),
    link: Map(),
    additionRequests: List(),
    selectedRequests: Set(),
    suppliers: List(),
    size: 0,
    totalSize: 0,
    limit: 20,
    offset: 0,
    groupId: 0
  });

export const setSuppliers = (
  data,
  { suppliers, size, limit, totalSize, offset, groupId = 0 }
) =>
  data
    .remove('suppliers')
    .set('suppliers', fromJS(suppliers))
    .set('size', size)
    .set('totalSize', totalSize)
    .set('offset', offset)
    .set('limit', limit)
    .set('groupId', groupId)
    .remove('selected')
    .set('selected', Set())
    .set('firstUploaded', true);

export const setGroups = (data, { groups }) =>
  data.remove('groups').set('groups', fromJS(groups));

export const setSeletedGroup = (data, { group }) =>
  data
    .update('selectedGroup', newSelectedGroups(), (selectedCategories) =>
      selectedCategories.includes(group.get('id'))
        ? selectedCategories.remove(group.get('id'))
        : selectedCategories.add(group.get('id'))
    )
    .remove('selected')
    .set('selected', Set());

export const clearSelectedGroup = (data) =>
  data.set('selectedGroup', newSelectedGroups());

export const selectSupplier = (data, { item }) => {
  if (data.get('selected').size === 0) {
    return data
      .remove('selected')
      .set('selected', Set().add(item.get('id')))
      .remove('selectedGroup')
      .set('selectedGroup', newSelectedGroups());
  }
  return data
    .update('selected', Set(), (selected) =>
      selected.includes(item.get('id'))
        ? selected.delete(item.get('id'))
        : selected.add(item.get('id'))
    )
    .remove('selectedGroup')
    .set('selectedGroup', newSelectedGroups());
};

export const setSelectedItem = (data, { id }) =>
  data.update('selected', Set(), (selected) =>
    selected.includes(id) ? selected.delete(id) : selected.add(id)
  );

export const setSelectedRequests = (data, { id }) =>
  data.update('selectedRequests', Set(), (selected) =>
    selected.includes(id) ? selected.delete(id) : selected.add(id)
  );

export const deselectSuppliers = (data) =>
  data.remove('selected').set('selected', Set());

export const setAdditionRequests = (data, { additionRequests }) =>
  data
    .remove('additionRequests')
    .set('additionRequests', fromJS(additionRequests));

export const setRequests = (data, { requests }) =>
  data.remove('requests').set('requests', fromJS(requests));

export const setInvites = (data, { invites, link }) =>
  data
    .remove('invites')
    .set('invites', fromJS(invites))
    .set('link', fromJS(link));

export const toggleLink = (data, { isTokenOn }) =>
  data.setIn(['link', 'isTokenOn'], isTokenOn);

export const setToken = (data, { token }) =>
  data.setIn(['link', 'token'], token);

export const addInviteOrRequest = (data, { request, invite, isExists }) => {
  if (isExists) {
    return data.update('requests', List(), (requests) =>
      requests.push(fromJS(request))
    );
  }
  return data.update('invites', List(), (invites) =>
    invites.push(fromJS(invite))
  );
};

export const deleteRequests = (data, { requests: removedRequests }) =>
  data
    .update('requests', List(), (requests) =>
      requests.filter((request) => !removedRequests.includes(request.get('id')))
    )
    .remove('selectedRequests')
    .set('selectedRequests', Set());

export const deleteInvites = (data, { invites }) =>
  data
    .update('invites', List(), (items) =>
      items.filter((invite) => !invites.includes(invite.get('id')))
    )
    .remove('selected')
    .set('selected', Set());

export const deleteGroup = (data, { groupId }) =>
  data
    .update('groups', List(), (items) =>
      items.filter((group) => group.get('id') !== groupId)
    )
    .update('suppliers', (items) =>
      items.map((item) =>
        item.update('groups', (groups) => groups.filter((id) => id !== groupId))
      )
    );

export const updateGroup = (data, { id, name }) => {
  const index = data
    .get('groups')
    .findIndex((group) => +group.get('id') === +id);
  return data.setIn(['groups', index, 'name'], name);
};

export const addGroup = (data, newGroup) =>
  data.update('groups', (groups) => groups.unshift(fromJS(newGroup)));

export const removeAdditionRequest = (data, { requestId }) =>
  data.update('additionRequests', List(), (items) =>
    items.filter((item) => item.get('id') !== requestId)
  );
