import React from 'react';
import getMsgSystemText from '../../../../../SystemMsgTranslates';

const SystemMessage = ({ t, message, chat, currentEmployeeId }) => (
  <div id={`msgId-${message.id}`} className="cw-item-system-message">
    {getMsgSystemText(t, chat, message, currentEmployeeId)}
  </div>
);

export default React.memo(SystemMessage);
