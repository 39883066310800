import cn from 'classnames';
import styles from './Loader.module.scss';
import { LoaderProps } from './Loader.types';

export function Loader({ className }: LoaderProps) {
  return (
    <div data-testid="loader" className={cn(styles.loader, className)}>
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
    </div>
  );
}
