import React from 'react';
import styled from 'styled-components';
import { Flex } from '../../../styledComponents/atoms';
import Tabs from '../../ChatWidget/modules/tabs';

const TABS = ['contacts', 'chats'];

const MessengerFooter = () => (
  <Container>
    <Tabs config={TABS} />
  </Container>
);

const Container = styled(Flex)`
  width: 100%;
  background: ${(p) => p.theme.colors.gray.lighter};
  border-radius: 0 0 8px 8px;
  flex-shrink: 0;
`;

export default MessengerFooter;
