import React from 'react';
import PropTypes from 'prop-types';

const Content = ({ children, mods, id }, { b }) => (
  <div id={id} className={b('content', mods)}>
    {children}
  </div>
);

Content.contextTypes = {
  b: PropTypes.func
};

export default Content;
