import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTextMessage } from '../messages';
import * as getters from '../../modules/pinnedMessages/getters';
import * as pinnedMessagesCases from '../../modules/pinnedMessages/useCases';
import Avatar from '../../Avatar';
import { getOpenedChat } from '../../getter';
import { useTranslate } from '../../../../TranslateProvider';
import './style.css';

const ChatPinnedMessagePanel = ({
  chat,
  message,
  jumpToMessage,
  unpinMessage
}) => {
  const t = useTranslate();

  if (!message) return null;
  const { userName, avatarSrc } = message;
  const haveFiles = message.files.length > 0;

  const renderMsg = () => {
    const msg = getTextMessage({ t, message, chat });
    return msg.msg;
  };

  function unpin(event) {
    event.stopPropagation();
    unpinMessage(chat.id, message.id);
  }

  function jump() {
    jumpToMessage(message);
  }

  return (
    <div className="pinned-msg" onClick={jump}>
      <div className="pinned-msg__img">
        <img
          src="/img/chat-widget/pinned.svg"
          height={18}
          width={18}
          alt="icon-pinned"
        />
      </div>
      <div className="pinned-msg__wrap">
        <div className="pinned-msg__info text-wrap">
          <div className="pinned-msg__avatar">
            <Avatar
              size={34}
              info={{
                type: 'user',
                src: avatarSrc,
                alt: userName,
                employeeId: message.employeeId
              }}
            />
          </div>
          <div className="text-wrap">
            <div className="pinned-msg__username">{userName}</div>
            <div className="text-wrap">
              {haveFiles && (
                <img
                  src="/img/chat-widget/attach.svg"
                  className="schedule-msgs_attach"
                  role="presentation"
                  alt=""
                />
              )}
              {renderMsg()}
            </div>
          </div>
          <div className="pinned-msg__close" onClick={unpin}>
            <img src="/img/cross.svg" width="10" role="presentation" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    chat: getOpenedChat(state),
    message: getters.getPinnedMessage(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        jumpToMessage: pinnedMessagesCases.jumpToScheduleMessage,
        unpinMessage: pinnedMessagesCases.unpinMsg
      },
      dispatch
    )
)(ChatPinnedMessagePanel);
