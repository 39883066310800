import React, { useEffect, useState } from 'react';
import usePrevious from '../../components/ChatWidget/hooks/previous';
import { isSameUrl } from './utils/isSameUrl';
import { useIntersectionObserver } from './hooks/useIntersectionObserver';
import './style.css';
import { DocumentLoader } from './loader/DocumentLoader';
import { PageSizeCalculator } from './layouts/PageSizeCalculator';
import { ScrollMods } from './constants';
import { Inner } from './layouts/Inner';
import {
  defaultSpinner,
  SpinnerContext
} from './components/Spinner/SpinnerContext';

export const Viewer = ({
  defaultScale,
  fileUrl,
  initialPage = 0,
  plugins = [],
  renderLoader,
  renderError,
  renderPage,
  scrollMode = ScrollMods.Vertical,
  onDocumentLoad,
  onPageChange,
  onZoom,
  withCredentials = false
}) => {
  const [file, setFile] = useState({
    data: fileUrl,
    name: typeof fileUrl === 'string' ? fileUrl : '',
    shouldLoad: false
  });

  const openFile = (fileName, data) => {
    setFile({
      data,
      name: fileName,
      shouldLoad: true
    });
  };

  const [isVisible, setIsVisible] = useState(false);

  const prevFile = usePrevious(file);

  useEffect(() => {
    if (!isSameUrl(prevFile.data, fileUrl)) {
      setFile({
        data: fileUrl,
        name: typeof fileUrl === 'string' ? fileUrl : '',
        shouldLoad: isVisible
      });
    }
  }, [fileUrl, isVisible]);

  const visibilityChanged = (params) => {
    setIsVisible(params.isVisible);

    if (params.isVisible) {
      setFile((f) => ({ ...f, shouldLoad: true }));
    }
  };

  const containerRef = useIntersectionObserver({
    onVisibilityChanged: visibilityChanged
  });

  return (
    <SpinnerContext.Provider
      value={{ renderSpinner: renderLoader || defaultSpinner }}>
      <div ref={containerRef} className="pdf-viewer">
        {file.shouldLoad && (
          <DocumentLoader
            file={file.data}
            withCredentials={withCredentials}
            renderError={renderError}
            render={(doc) => (
              <PageSizeCalculator
                doc={doc}
                defaultScale={defaultScale}
                scrollMode={scrollMode}
                render={(pageSize) => (
                  <Inner
                    currentFile={{ data: file.data, name: file.name }}
                    defaultScale={defaultScale}
                    doc={doc}
                    initialPage={initialPage}
                    pageSize={pageSize}
                    plugins={plugins}
                    renderPage={renderPage}
                    scrollMode={scrollMode}
                    viewerState={{
                      file,
                      pageIndex: -1,
                      pageHeight: pageSize.pageHeight,
                      pageWidth: pageSize.pageWidth,
                      scale: pageSize.scale,
                      scrollMode
                    }}
                    onDocumentLoad={onDocumentLoad}
                    onPageChange={onPageChange}
                    onZoom={onZoom}
                    onOpenFile={openFile}
                  />
                )}
              />
            )}
          />
        )}
      </div>
    </SpinnerContext.Provider>
  );
};
