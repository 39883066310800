import React from 'react';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';

import 'react-datepicker/dist/react-datepicker.css';
import '../datepicker.css';

registerLocale('ru', ru);
registerLocale('en', en);

const PickDateSide = ({
  isShow,
  isStartPicker = true,
  user,
  onChange,
  startDate,
  endDate,
  minDate,
  maxDate
}) => {
  const language = user.get('language');

  return (
    <div
      className={`datepicker-goodwix__picker-${
        isStartPicker ? 'start' : 'end'
      } ${isShow ? 'shown' : 'hidden'}`}>
      <DatePicker
        locale={language}
        selected={isStartPicker ? startDate : endDate}
        onChange={onChange}
        selectsStart={isStartPicker}
        selectsEnd={!isStartPicker}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        inline
        disabledKeyboardNavigation
      />
    </div>
  );
};

export default connect((state) => ({
  user: state.getIn(['user', 'user'])
}))(PickDateSide);
