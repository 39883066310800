import { useState } from 'react';

export default function useSelect(init = []) {
  const [selected, setSelected] = useState(init);

  const onSelect = (id) => {
    if (selected.includes(id)) {
      return setSelected(selected.filter((a) => a !== id));
    }
    return setSelected([...selected, id]);
  };

  return {
    selected,
    onSelect: (id) => onSelect(id)
  };
}
