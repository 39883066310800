const { defStruct } = require('../../lib');

/**
 * @typedef PaginationQuery
 */
const { makePaginationQuery, getLimit, getOffset, isPaginationQuery } =
  defStruct('PaginationQuery', ['offset', 'limit']);

/**
 * Create a pagination query data type
 *
 * @param {object} params
 * @param {number} params.offset
 * @param {number} params.limit
 * @returns {PaginationQuery}
 */
exports.makePaginationQuery = ({ offset = 0, limit = 20 } = {}) =>
  makePaginationQuery(offset, limit);

/**
 * Gives limit of pagination query
 *
 * @param {PaginationQuery} paginationQuery
 * @returns {number}
 */
exports.getLimit = (paginationQuery) => getLimit(paginationQuery);

/**
 * Gives offset of pagination query
 *
 * @param {PaginationQuery} paginationQuery
 * @returns {number}
 */
exports.getOffset = (paginationQuery) => getOffset(paginationQuery);

/**
 * Checks if is pagination query
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isPaginationQuery = (a) => isPaginationQuery(a);
