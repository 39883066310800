import React from 'react';

export function useDebounce(callback, delay = 200) {
  const timer = React.useRef(null);

  return React.useCallback(
    (...args) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );
}
