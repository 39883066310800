import React, { useState } from 'react';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';

export const LazyRender = ({ attrs = {}, children }) => {
  const [visible, setVisible] = useState(false);

  const handleVisibilityChanged = ({ isVisible = false }) => {
    if (isVisible) {
      setVisible(true);
    }
  };

  const containerRef = useIntersectionObserver({
    onVisibilityChanged: handleVisibilityChanged
  });

  return (
    <div {...attrs} ref={containerRef}>
      {visible && children}
    </div>
  );
};
