import React from 'react';
import { Link } from 'react-router-dom';
import block from 'bem-cn-lite';

const b = block('logo');

const Logo = (props) => {
  const { withLink = false, to = '/', width = 148, height = 35 } = props;

  if (withLink) {
    return (
      <Link className={b()} to={to}>
        <img style={{ width, height }} src="/img/logo.svg" alt="Goodwix" />
      </Link>
    );
  }

  return (
    <span className={b()}>
      <img style={{ width, height }} src="/img/logo.svg" alt="Goodwix" />
    </span>
  );
};

export default Logo;
