import React from 'react';
import block from 'bem-cn-lite';

const b = block('datepicker-goodwix');

const DTFooter = ({ t, onApply, onClearDate, btnApplyTkey, btnClearTkey }) => (
  <div className={b('footer')}>
    <button type="button" onClick={onClearDate} className={b('btn-clear')}>
      {t(btnClearTkey)}
    </button>
    <button type="button" onClick={onApply} className={b('btn-apply')}>
      {t(btnApplyTkey)}
    </button>
  </div>
);

export default DTFooter;
