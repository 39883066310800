import React from 'react';
import './ImageBlurredPreview.css';

const ImageBlurredPreview = ({ src = '' }) => (
  <div
    style={{ backgroundImage: `url(${src})` }}
    className="image-blurred-preview"
  />
);

export default ImageBlurredPreview;
