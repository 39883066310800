import React from 'react';
import { ButtonIcon, Typography } from '@link/react-components';
import classes from './GroupItem.module.css';

export const GroupItem = React.memo(({ group, onEdit, onDelete }) => (
  <div className={classes.groupItem}>
    <div className={classes.content}>
      <Typography variant="body1Reg" noWrap>
        {group.name}
      </Typography>
      <Typography variant="body1Reg">{`(${group.companiesList.length})`}</Typography>
      {group.new && <div className={classes.badgeNew} />}
    </div>
    <div className={classes.actions}>
      <ButtonIcon
        onClick={() => onEdit(group)}
        iconName="edit"
        variant="small"
      />
      <ButtonIcon
        onClick={() => onDelete(group)}
        iconName="trash"
        variant="small"
      />
    </div>
  </div>
));
