import * as storage from './storage';
import * as actions from './actions';
import * as contactsActions from '../../action-creators/contacts';
import * as companyActions from '../../action-creators/company';
import * as chatActions from '../../action-creators/chat';
// eslint-disable-next-line import/no-cycle
import { updateDateAfterToggleBlock } from '../../components/ChatWidget/useCases';
import { removeByField } from '../../components/ChatWidget/data-type/block-list';
import { getChatContact } from '../../components/ChatWidget/modules/contacts/getters';
import { hideProfile } from '../../components/ChatWidget/modules/profile';
import { applyState } from '../../components/ChatWidget/modules/utils';
import { setContacts } from '../../components/ChatWidget/modules/contacts/actions';
// eslint-disable-next-line import/no-cycle
import {
  getContactQueryData,
  uploadContacts
} from '../../components/ChatWidget/modules/contacts';

export const toggleBlock = ({ employeeId }) => {
  storage.toggleBlock(employeeId);
};

export const subscribeToUpdate = () => (dispatch, getState) => {
  storage.subToBlockedContact(({ employeeId, isBlocked }) => {
    dispatch(actions.toggleBlockContact({ employeeId, isBlocked }));
    dispatch(updateDateAfterToggleBlock(employeeId, isBlocked));
  });

  storage.subToBlockedMe(({ employeeId, isBlocked }) => {
    const state = getState();

    if (state.getIn(['contacts', 'contactInfo', 'employeeId']) === employeeId) {
      contactsActions.getContactInfoByEmployeeId(employeeId)(
        dispatch,
        getState
      );
    }
    if (!state.get('companiesInfo').isEmpty()) {
      dispatch(
        companyActions.toggleBlockMeOfCompInfo({ employeeId, isBlocked })
      );
    }
    if (!state.getIn(['company', 'listOfUsers']).isEmpty()) {
      dispatch(
        companyActions.toggleBlockMeOfEmployees({ employeeId, isBlocked })
      );
    }
    if (!state.getIn(['chat', 'dialogs']).isEmpty()) {
      dispatch(chatActions.toggleBlockMe({ employeeId, isBlocked }));
    }
  });
};

export const subscribeToDeleteContact = () => (dispatch, getState) => {
  storage.subToHideContact(({ employeeId }) => {
    const blockContact = applyState(getState, getChatContact);
    const contacts = removeByField(blockContact(), employeeId, 'employeeId');
    dispatch(setContacts({ contacts: contacts.list, size: contacts.size }));
    dispatch(hideProfile());
  });
};

export const subscribeToUpdateContactByInvite = () => (dispatch) => {
  storage.subToUpdateContactByInvite(async () => {
    await dispatch(getContactQueryData());
    dispatch(uploadContacts());
  });
};

export const unsubscribeToUpdate = () => () => {
  storage.unsubToBlockedContact();
  storage.unsubToBlockedMe();
};
