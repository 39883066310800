import { useWizard } from '@link/react-components';
import { useCallback, useState } from 'react';
import { companiesGroupService } from '../../../../api';
import { sortByName } from '../utils';

export function useGroupActions() {
  const { getState, setState } = useWizard();
  const sharedState = getState();

  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState(sharedState.groups || []);

  const create = useCallback(async (data) => {
    try {
      setLoading(true);
      const createdGroup = {
        name: data.name,
        companiesList: data.companiesList
      };

      const { id } = await companiesGroupService.create(createdGroup);
      createdGroup.id = id;

      const updatedGroups = [...groups, { ...createdGroup, new: true }].sort(
        sortByName
      );

      setGroups(updatedGroups);
      setState({ groups: updatedGroups });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const edit = useCallback(
    async (data) => {
      try {
        setLoading(true);
        const updatedGroup = {
          id: data.id,
          name: data.name,
          companiesList: data.companiesList
        };
        await companiesGroupService.update(updatedGroup);
        const updatedGroups = groups
          .map((group) => (group.id === updatedGroup.id ? updatedGroup : group))
          .sort(sortByName);
        setState({ groups: updatedGroups });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [groups]
  );

  const remove = useCallback(async (groupId) => {
    try {
      setLoading(true);
      await companiesGroupService.delete(groupId);
      const updatedGroups = groups.filter((group) => group.id !== groupId);
      setState({ groups: updatedGroups });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    groups,
    create,
    edit,
    remove
  };
}
