import { TOPIC_STORE, CURRENT_TOPIC, CURRENT_MESSAGES } from './constants';
import { getTempField, getChannel } from '../../storeGetters';

export const getTopic = (state) => state.get(TOPIC_STORE)[CURRENT_TOPIC];
export const getTopicMessages = (state) =>
  state.get(TOPIC_STORE)[CURRENT_MESSAGES];

export const getEditFiles = (state) => {
  const channelFiles = getChannel(state).files;
  const filesToAdd = getTempField(state, 'filesToAdd', []);
  const listOfRemoveFilesId = getTempField(state, 'filesToRemove', []);

  return channelFiles
    .filter((file) => !listOfRemoveFilesId.includes(file.id))
    .concat(filesToAdd);
};
