import React, { useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { floatToRatio } from '../utils/floatToRatio';
import { roundToDivide } from '../utils/roundToDivide';

const MAX_CANVAS_SIZE = 4096 * 4096;
const RATIO_LIMIT = 8;

export const CanvasLayer = ({
  page,
  height,
  width,
  scale,
  onRenderCanvasCompleted
}) => {
  const canvasRef = useRef();
  const renderTask = useRef();

  useIsomorphicLayoutEffect(() => {
    const task = renderTask.current;

    if (task) {
      task.cancel();
    }

    const canvasElem = canvasRef.current;
    const viewport = page.getViewport({ scale });

    const outputScale = window.devicePixelRatio || 1;
    const maxScale = Math.sqrt(
      MAX_CANVAS_SIZE / (viewport.width * viewport.height)
    );
    const shouldScaleByCSS = outputScale > maxScale;

    if (shouldScaleByCSS) {
      canvasElem.style.transformSize = `scale(1, 1)`;
    } else {
      canvasElem.style.removeProperty('transform');
    }

    const possibleScale = Math.min(maxScale, outputScale);
    const [x, y] = floatToRatio(possibleScale, RATIO_LIMIT);

    canvasElem.width = roundToDivide(viewport.width * possibleScale, x);
    canvasElem.height = roundToDivide(viewport.height * possibleScale, x);
    canvasElem.style.width = `${roundToDivide(viewport.width, y)}px`; // Maybe x but not y
    canvasElem.style.height = `${roundToDivide(viewport.height, y)}px`;
    canvasElem.style.opacity = '0';

    const canvasContext = canvasElem.getContext('2d', { alpha: false });

    const transform =
      shouldScaleByCSS || outputScale !== 1
        ? [possibleScale, 0, 0, possibleScale, 0, 0]
        : null;
    renderTask.current = page.render({ canvasContext, transform, viewport });
    renderTask.current.promise.then(
      () => {
        canvasElem.style.removeProperty('opacity');

        if (typeof onRenderCanvasCompleted === 'function') {
          onRenderCanvasCompleted();
        }
      },
      () => {
        if (typeof onRenderCanvasCompleted === 'function') {
          onRenderCanvasCompleted();
        }
      }
    );

    return () => {
      if (renderTask.current) {
        renderTask.current.cancel();
      }
      if (canvasElem) {
        canvasElem.width = 0;
        canvasElem.height = 0;
      }
    };
  }, []);

  return (
    <div
      className="pdf-viewer__canvas-layer"
      style={{ height: `${height}px`, width: `${width}px` }}>
      <canvas ref={canvasRef} style={{ opacity: 0 }} />
    </div>
  );
};
