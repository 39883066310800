import React from 'react';

const SecurityBoxInputsWrap = ({ marginTop, children }) => {
  const style = {
    minHeight: '36px'
  };

  if (marginTop) {
    style.marginTop = '24px';
  }

  return <div style={style}>{children}</div>;
};

export default SecurityBoxInputsWrap;
