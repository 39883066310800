import React from 'react';
import Badge, { BADGES_TYPES } from 'react-components/src/components/Badge';
import Icon, { Icons } from 'react-components/src/icons';
import './style.css';

const MAX_COUNT_UNREAD_MESSAGES = 99;

const Badges = ({
  amountUnreadMsg,
  hasMention,
  isChatNotificationOff,
  isShowScheduleBadge,
  isActiveScheduleBadge
}) => (
  <div className="cw-chat-card-badges">
    {/* {hasDelayedMessages && <Icon size={14} icon={Icons.DelayedMessage} />} */}
    {isShowScheduleBadge && (
      <Icon
        size={14}
        icon={isActiveScheduleBadge ? Icons.AlarmActive : Icons.Alarm}
      />
    )}
    {hasMention && <Icon size={14} icon={Icons.At} />}
    {amountUnreadMsg > 0 && (
      <Badge type={BADGES_TYPES.Notification} disabled={isChatNotificationOff}>
        {amountUnreadMsg > MAX_COUNT_UNREAD_MESSAGES ? '99+' : amountUnreadMsg}
      </Badge>
    )}
  </div>
);

export default Badges;
