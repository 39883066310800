import React from 'react';

/* eslint-disable max-len */
function IconPerson() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <rect x="14.5" y="9.5" width="5" height="8" rx="2.5" stroke="#767B92" />
      <path
        d="M17.3333 25C13.2832 25 10 24.2674 10 23.3636C10.1111 22.4545 10.3383 21.1126 12.6667 20.6364C14 20.3636 14.7778 19.5455 14.6667 19M16.6667 25C20.7168 25 24 24.2674 24 23.3636C23.8889 22.4545 23.6617 21.1126 21.3333 20.6364C20 20.3636 19.2222 19.5455 19.3333 19"
        stroke="#767B92"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconPerson;
