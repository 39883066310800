import React, { Component } from 'react';
import block from 'bem-cn-lite';
import { InputGroup } from 'react-bootstrap';

import Arrow from '../../icons/arrow';

import './style.styl';

const b = block('input-number');

class InputNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:
        (props.defaultValue !== 0 && props.defaultValue !== ''
          ? props.value
          : props.defaultValue) || 0,
      step: +props.step || 1,
      min: props.min,
      max: props.max
    };
    this.onChange = this.onChange.bind(this);
    this.increaseValue = this.increaseValue.bind(this);
    this.decreaseValue = this.decreaseValue.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { value, max } = props;

    if (
      (value || value === 0 || value === '') &&
      +value !== +this.state.value
    ) {
      return this.setState({ value });
    }
    if (value === 0 && this.state.value === '') {
      return this.setState({ value: 0 });
    }
    if (!this.state.value && this.state.value !== 0) {
      this.setState({ value: 0 });
    }
    if (this.state.value === '') {
      this.setState({ value: '' });
    }
    if (value === '') {
      this.setState({ value: '' });
    }
    return this.setState({ max });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.value !== this.state.value ||
      nextState.viewRead !== this.props.viewRead ||
      nextState.disabled !== this.props.disabled
    );
  }

  onChange(e) {
    const { min, max } = this.state;
    const inputValue = e.target.value;

    if (this.props.maxLength && `${inputValue}`.length > this.props.maxLength) {
      return;
    }

    if (/^\d*((\d\.)|(\.\d))?\d{0,3}$/.test(inputValue)) {
      if (min === undefined && max === undefined) {
        this.setValue(inputValue);
      } else if (+inputValue >= min && max === undefined) {
        this.setValue(inputValue);
      } else if (min === undefined && +inputValue <= max) {
        this.setValue(inputValue);
      } else if (+inputValue >= min && +inputValue <= max) {
        this.setValue(inputValue);
      } else if (inputValue === '') {
        this.setValue(inputValue);
      }
    }
  }

  setValue(value) {
    const { onChange, autoSet = true } = this.props;

    const valueString = (value || '').toString();
    const isFloatNumber = valueString.indexOf('.') > -1;
    // Case prevents entry 023, it converts to 23
    if (valueString.length > 1 && !isFloatNumber) {
      const normalizedValue = valueString.replace(/^0+/, '');
      return this.setState({ value: normalizedValue }, () =>
        onChange(this.state.value)
      );
    }

    if (isNaN(value)) {
      return this.setState({ value: 0 }, () => onChange(this.state.value));
    }
    if (value === '' && autoSet) {
      return this.setState({ value: 0 }, () => onChange(0));
    }
    return this.setState({ value }, () => onChange(this.state.value));
  }

  fixedNumber(number) {
    return Number(number.toFixed(4));
  }

  increaseValue() {
    const { value, max, step } = this.state;
    const result = this.fixedNumber(+value + step);

    if (max === undefined) {
      this.setValue(result);
    } else if (result <= max) {
      this.setValue(result);
    }
  }

  decreaseValue() {
    const { value, min, step } = this.state;
    const result = this.fixedNumber(+value - step);

    if (min === undefined) {
      this.setValue(result);
    } else if (result >= min) {
      this.setValue(result);
    }
  }

  renderSimpleInputNumber() {
    const {
      placeholder,
      noArrows = false,
      style,
      disablePaste = false,
      size = 'm',
      disabled,
      className = ''
    } = this.props;

    return (
      <div
        className={`${b({ size, disabled })} ${this.props.className || ''}`}
        style={{ width: this.props.width, ...style }}>
        <input
          name={this.props.name}
          className={`${b('input')} ${className}`}
          type="text"
          inputMode="numeric"
          onChange={this.onChange}
          value={this.state.value}
          onKeyDown={this.props.onKeyDown}
          onKeyPress={this.props.onKeyPress}
          onPaste={(e) => disablePaste && e.preventDefault()}
          onBlur={this.props.onBlur}
          placeholder={placeholder}
          disabled={disabled}
          style={{ width: this.props.inputWidth }}
        />
        {!noArrows && (
          <div>
            <span
              className={b('btn', { increase: true })}
              onClick={disabled ? () => {} : this.increaseValue}>
              <Arrow direction="top" />
            </span>
            <span
              className={b('btn', { decrease: true })}
              onClick={disabled ? () => {} : this.decreaseValue}>
              <Arrow direction="down" />
            </span>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { viewRead, addon, addonStyle = {} } = this.props;

    if (viewRead) {
      return (
        <div style={{ padding: 7 }}>{`${this.state.value || '-'} ${
          this.state.value && addon ? addon : ''
        }`}</div>
      );
    }

    if (addon) {
      return (
        <InputGroup>
          {this.renderSimpleInputNumber()}
          <InputGroup.Addon style={addonStyle}>{addon}</InputGroup.Addon>
        </InputGroup>
      );
    }

    return this.renderSimpleInputNumber();
  }
}

export default InputNumber;
