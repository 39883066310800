import { increateLoadCounter } from '../../../data-type/query';

export const chunkUploadArchivesService = async (
  {
    reqGetArchives,
    getArchivesQuery,
    updateArchivesQuery,
    addArchives,
    transformToChatModels
  },
  { newParams = {} }
) => {
  const archives = await reqGetArchives(newParams);
  addArchives(transformToChatModels(archives));

  const query = newParams.query || getArchivesQuery();
  updateArchivesQuery(increateLoadCounter(query));
};
