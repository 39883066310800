import React from 'react';
import block from 'bem-cn-lite';
import { connect } from 'react-redux';
import { getAdvancedSearch } from '../../../getter';
import './style.css';
import Hashtags from './hashtags';
import Date from './date';
import {
  setDateRange,
  toggleSortDir,
  toggleTag
} from '../../AdvancedSearch/messages';

const b = block('cws-header-filter');

const CWSFilter = ({ isShow, search }) => {
  const {
    viewTags,
    params: { selectedTags, sortDir }
  } = search;
  const onClickTag = (tag) => toggleTag.emit(tag);
  const onClickChangeSortDir = (currentDir) => toggleSortDir.emit(currentDir);
  const onSelectedDate = (range) => setDateRange.emit(range);

  if (!isShow) return null;

  return (
    <div className={b()}>
      <div className={b('place-hashtags')}>
        <Hashtags
          list={viewTags}
          activeList={selectedTags}
          onClickTag={onClickTag}
        />
      </div>
      <div className={b('place-date')}>
        <Date
          onSelectedDate={onSelectedDate}
          onClickChangeSortDir={onClickChangeSortDir}
          sortDir={sortDir}
        />
      </div>
    </div>
  );
};

export default connect((state) => ({
  search: getAdvancedSearch(state)
}))(CWSFilter);
