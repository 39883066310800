import React from 'react';
import ButtonIcon from 'react-components/src/components/ButtonIcon';
import Icons from 'react-components/src/icons/constants';
import { useTranslate } from '../../../../../../../../TranslateProvider';

const TogglePinBtn = ({ size = 22, onClick, isPinned = false }) => {
  const t = useTranslate();

  if (isPinned) {
    return (
      <ButtonIcon
        t={t}
        icon={Icons.Pin}
        size={size}
        tooltip="unpin-chat"
        onClick={onClick}
      />
    );
  }

  return (
    <ButtonIcon
      t={t}
      icon={Icons.Unpin}
      size={size}
      tooltip="pin-chat"
      onClick={onClick}
    />
  );
};

export default TogglePinBtn;
