import React from 'react';
import Typography from 'react-components/src/components/Typography';
import Icon from 'react-components/src/icons';
import { useTranslate } from '../../../TranslateProvider';
import DropdownMenu from './menu';
import './Select.css';

/**
 * @typedef {import('../../../lib/data-type.js')} ReactDom
 */

/**
 * @typedef {object} SelectItem
 * @property {string} icon
 * @property {string} value
 * @property {string} text
 */

/**
 * Render the select with title
 *
 * @param {object} params
 * @param {SelectItem} params.title
 * @param {Function} params.onSelect
 * @param {[SelectItem]} params.list
 * @param params.position
 * @param params.possiblePositions
 * @param params.fixedMenuPos
 * @returns {ReactDom}
 */
const CWSSelect = ({
  title,
  onSelect,
  list,
  position = 'bottom-start',
  possiblePositions = [
    'left-start',
    'right-start',
    'bottom-start',
    'top-start'
  ],
  fixedMenuPos
}) => {
  const t = useTranslate();

  return (
    <div className="cws-select">
      <DropdownMenu
        title={() => (
          <div className="cws-select__title">
            <Typography variant="body1Med">{`${t(
              `chat-widget.menu-items.${title.value}`
            )} `}</Typography>
            <Icon icon="arrow-down" size={16} />
          </div>
        )}
        onSelect={onSelect}
        position={position}
        possiblePositions={possiblePositions}
        list={list}
        fixedMenuPos={fixedMenuPos}
      />
    </div>
  );
};

export default CWSSelect;
