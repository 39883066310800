import React from 'react';
import PropTypes from 'prop-types';

const Panel = ({ children }, { b }) => (
  <div className={b('panel')}>{children}</div>
);

Panel.contextTypes = {
  b: PropTypes.func
};

export default Panel;
