export const filterChats = ({ filterBy, chatsList }) => {
  if (filterBy === 'all-chats') return chatsList;
  if (filterBy === 'dialogs')
    return chatsList.filter(({ type }) => type === 'Dialog');
  if (filterBy === 'channels')
    return chatsList.filter(({ type }) => type === 'Channel');
  if (filterBy === 'topics')
    return chatsList.filter(({ type }) => type === 'Topic');
  return chatsList;
};
