import React from 'react';
import { Button } from 'react-bootstrap';

export default ({ text, name, submitAction, isDisabled, className }) => (
  <Button
    name={name}
    type="button"
    bsStyle="primary"
    disabled={isDisabled}
    onClick={() => submitAction()}
    style={{ fontSize: 14 }}
    className={className}>
    {text}
  </Button>
);
