import React from 'react';

/* eslint-disable max-len */
const IconArchive = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-stroke"
      d="M0.5 4V15.5C0.5 16.6046 1.39543 17.5 2.5 17.5H15.5C16.6046 17.5 17.5 16.6046 17.5 15.5V4"
      stroke="#767B92"
    />
    <path
      className="cws-icon-main-stroke"
      d="M2.24614 1.22826C2.51723 0.776448 3.00549 0.5 3.53238 0.5H14.4676C14.9945 0.5 15.4828 0.776448 15.7539 1.22826L17.1169 3.5H0.883095L2.24614 1.22826Z"
      stroke="#767B92"
    />
    <path
      className="cws-icon-main-stroke"
      d="M11.5 11.5L8.99998 14L6.49997 11.5"
      stroke="#767B92"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path className="cws-icon-main-stroke" d="M9 7L9 14" stroke="#767B92" />
  </svg>
);
/* eslint-enable max-len */

export default IconArchive;
