import React from 'react';
import { FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import { Text } from '../../styledComponents/atoms';

const TextArea = (props) => {
  const { viewRead, value } = props;

  if (viewRead) {
    return (
      <CompanyDescriptionReadonly>{value || '-'}</CompanyDescriptionReadonly>
    );
  }

  const propsCopy = { ...props };
  delete propsCopy.viewRead;

  return <FormControl componentClass="textarea" {...propsCopy} />;
};

const CompanyDescriptionReadonly = styled(Text)`
  margin: 0;
  padding: 0;
  word-break: break-word;
`;

export default TextArea;
