export class ResponseInvitePartnersCompanyDto {
  constructor(props) {
    this.id = props.id;
    this.fromId = props.fromId;
    this.toId = props.toId;
    this.email = props.email;
    this.status = props.status;
    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }
}
