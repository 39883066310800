import React, { useEffect, useState } from 'react';
import { isEmpty, isNil } from 'ramda';
import { useAsync, useModal, useTranslate } from '@hooks';
import { Modal, Typography } from '../../../../shared/components';
import { emailStatusMapper } from '../../domain/mappers/emailStatusMapper';
import { getTranslate } from '../../utils';
import { requestEmailsApi } from '../../../../api/requests';
import Content from './Content';
import StatusInfoBlock from '../StatusInfoBlock/StatusInfoBlock';
import classes from './Modal.module.css';

const SuppliersActivitiesModal = ({ requestId }) => {
  const t = useTranslate();
  const { onClose } = useModal();

  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const {
    loading,
    value: allData,
    error
  } = useAsync(async () => {
    const data = await requestEmailsApi.getEmailsByRequestId(requestId);

    return data.map(emailStatusMapper);
  }, [requestId]);

  useEffect(() => {
    if (isNil(allData)) return;

    if (searchValue.trim()) {
      setFilteredData(
        allData.filter(
          (current) =>
            current.company.toLowerCase().split(searchValue.toLowerCase())
              .length > 1
        )
      );
    } else {
      setFilteredData(allData);
    }
  }, [allData, searchValue]);

  return (
    <Modal id="modal-suppliers-activities" onClose={onClose} open>
      <div className={classes.modal}>
        <img
          className={classes.cross}
          src="/img/cross-modal.svg"
          alt="close"
          onClick={onClose}
        />
        <div className={classes.content}>
          <div className={classes.title}>
            <Typography variant="h2">
              {getTranslate(t, 'modal.title')}
            </Typography>
            <div className={classes.statusesHint}>
              <StatusInfoBlock />
            </div>
          </div>
          <Content
            loading={loading}
            error={error}
            emailStatusList={filteredData}
            onSearch={setSearchValue}
            isEmptyResult={isEmpty(allData)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SuppliersActivitiesModal;
