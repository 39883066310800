import React from 'react';

/* eslint-disable max-len */
function IconExtra() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        d="M21 17C21 17.8284 21.6716 18.5 22.5 18.5C23.3284 18.5 24 17.8284 24 17C24 16.1716 23.3284 15.5 22.5 15.5C21.6716 15.5 21 16.1716 21 17Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        d="M15.5 17C15.5 17.8284 16.1716 18.5 17 18.5C17.8284 18.5 18.5 17.8284 18.5 17C18.5 16.1716 17.8284 15.5 17 15.5C16.1716 15.5 15.5 16.1716 15.5 17Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        d="M10 17C10 17.8284 10.6716 18.5 11.5 18.5C12.3284 18.5 13 17.8284 13 17C13 16.1716 12.3284 15.5 11.5 15.5C10.6716 15.5 10 16.1716 10 17Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconExtra;
