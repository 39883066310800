import React, { useState, useEffect } from 'react';
import { isEmpty } from 'ramda';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useTranslate, useActions } from '@hooks';
import { Modal, Button } from '@link/react-components';
import { HelpBlock } from 'react-bootstrap';

import * as modalAction from '../../../../action-creators/modal';
import { isLoading } from '../../../../storeGetters';
import { getErrors } from '../../getter';
import { noop } from '../../../../utils/utils';

import './style.css';

const CWModalConfirmActionWithPassword = ({
  title,
  text,
  submit,
  back,
  clearErrors = noop()
}) => {
  const t = useTranslate();

  const errors = useSelector(getErrors);
  const loading = useSelector((state) => isLoading('modalConfirm', state));

  const { hideModalDialog } = useActions(modalAction);

  const [password, setPassword] = useState('');
  const [isSuccessRequested, setSuccessRequested] = useState(false);

  useEffect(() => {
    if (!errors.length) setSuccessRequested(false);
  }, [errors]);

  useEffect(() => {
    if (!errors.length && !loading && isSuccessRequested) {
      hideModalDialog();
    }
  }, [loading]);

  const isPasswordValid = () =>
    password.length >= process.env.PASSWORD_MIN_LENGTH;

  const isSubmitBtnDisable = () => !isPasswordValid() || loading;

  const isPasswordIncorrect = () => {
    if (errors.length) {
      return Boolean(errors.find((error) => error.type === 'passwordError'));
    }
    return false;
  };

  const onPrint = ({ target }) => {
    setPassword(target.value);

    if (errors.length) {
      clearErrors();
    }
  };

  const onSubmit = async () => {
    await submit(password);
    setSuccessRequested(true);
  };

  const onClose = () => {
    hideModalDialog();
    clearErrors();
  };

  return (
    <Modal onClose={onClose} open>
      <Modal.Header>{t(title)}</Modal.Header>
      <Modal.Content>
        <div>{t(text)}</div>
        {/* NOTE: input hidden need for fix autocomplete, otherwise autocomplete another input in page */}
        <input type="text" hidden />
        <input
          type="password"
          value={password}
          onChange={onPrint}
          className={cn(
            'cw-confirm-action-with-password__field',
            !isEmpty(errors) && 'error'
          )}
          placeholder={t('chat-widget.placeholders.password')}
          autoComplete="new-password"
        />
        {isPasswordIncorrect() && (
          <HelpBlock style={{ marginBottom: 0 }}>
            {t('The password is not correct')}
          </HelpBlock>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Modal.Actions>
          <Button mode="text" onClick={back}>
            {t('Cancel')}
          </Button>
          <Button
            mode="primary"
            onClick={onSubmit}
            disabled={isSubmitBtnDisable()}>
            {t('Confirm')}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default CWModalConfirmActionWithPassword;
