const { indexOf, append } = require('ramda');
const { defStruct } = require('../../lib');

/**
 * @typedef LoaderList
 */
const {
  makeLoaderList: makeLoader,
  getNames,
  getHistory,
  isLoaderList
} = defStruct('LoaderList', ['names', 'history']);

const haveLoader = (name, loaderList) => {
  const index = indexOf(name, loaderList);
  return index > -1;
};

const addToList = (name, loaderList) =>
  haveLoader(name, loaderList) ? loaderList : append(name, loaderList);
const delFromList = (name, loaderList) => loaderList.filter((n) => n !== name);

/**
 * Create a LoaderList
 *
 * @param {object} params
 * @param {?[string]} params.names
 * @param {?[string]} params.history
 * @returns {LoaderList}
 */
const makeLoaderList = ({ names = [], history = [] } = {}) =>
  makeLoader(names, history);
exports.makeLoaderList = makeLoaderList;

/**
 * Gives a list of loader names
 *
 * @param {LoaderList} loaderList
 * @returns {[string]}
 */
exports.getNames = (loaderList) => getNames(loaderList);

/**
 * Checks if is LoaderList
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isLoaderList = (a) => isLoaderList(a);

/**
 * Check if is loading a loader
 *
 * @param {string} name
 * @param {LoaderList} loaderList
 * @returns {boolean}
 */
const isLoading = (name, loaderList) => haveLoader(name, getNames(loaderList));
exports.isLoading = isLoading;

/**
 * Check if is loading a loader at least once
 *
 * @param {string} name
 * @param {LoaderList} loaderList
 * @returns {boolean}
 */
const isLoadedOnce = (name, loaderList) =>
  haveLoader(name, getHistory(loaderList));
exports.isLoadedOnce = isLoadedOnce;

/**
 * Launch the loader by name
 *
 * @param {string} name
 * @param {LoaderList} loaderList
 * @returns {LoaderList}
 */
exports.start = (name, loaderList) =>
  isLoading(name, loaderList)
    ? loaderList
    : makeLoaderList({
        names: addToList(name, getNames(loaderList)),
        history: getHistory(loaderList)
      });

/**
 * Disables the loader by name
 *
 * @param {string} name
 * @param {LoaderList} loaderList
 * @returns {LoaderList}
 */
const finish = (name, loaderList) => {
  if (!haveLoader(name, getNames(loaderList))) return loaderList;

  return makeLoaderList({
    names: delFromList(name, getNames(loaderList)),
    history: addToList(name, getHistory(loaderList))
  });
};
exports.finish = finish;

/**
 * Reset loader startup history by name
 *
 * @param {string} name
 * @param {LoaderList} loaderList
 * @returns {LoaderList}
 */
exports.resetLoader = (name, loaderList) =>
  makeLoaderList({
    names: getNames(loaderList),
    history: delFromList(name, getHistory(loaderList))
  });
