import { STATUS_SECTIONS } from './pages/TasksList/constants';

const getTaskManager = (state) => state.get('task-manager');

export const getTaskBadges = (state) => getTaskManager(state).badges;
export const getTaskBadgesCount = (state) =>
  getTaskManager(state).badges.length;
export const getTagsList = (state) => getTaskManager(state).tagList;
export const getTasksList = (state) => getTaskManager(state).taskList;
export const getTasksSection = (section) => (state) =>
  getTasksList(state)[section];
export const getSortedTasks = (state) => {
  const taskList = getTasksList(state);

  return STATUS_SECTIONS.flatMap(
    (status) => (taskList[status] || {}).list || []
  );
};
export const getAmountLeftTasksCount = (state) => {
  const taskList = getTasksList(state);

  return STATUS_SECTIONS.reduce(
    (acc, status) => ({
      ...acc,
      [status]: (taskList[status] || {}).amountLeft || 0
    }),
    {}
  );
};
export const getExecutorsList = (state) => getTaskManager(state).executors;

export const getCurrentTask = (state) => getTaskManager(state).currentTask;
