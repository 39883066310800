const { isNil } = require('ramda');
const { getType } = require('./struct');

/**
 * Generates a method for creating a multimethod by type
 *
 * @param {string} methodName
 * @param {{ string: Function }} handlers
 * @returns {Function}
 * @throws {Error}
 */
module.exports =
  (methodName, handlers) =>
  (...args) => {
    const firstArg = args[0];

    if (isNil(firstArg)) {
      throw new Error('There must be at least one argument');
    }

    const type = getType(firstArg);
    const handler = handlers[getType(firstArg)];
    if (isNil(handler)) {
      const listOfTypes = Object.keys(handlers)
        .map((typeName) => `"${typeName}"`)
        .join(', ');

      throw new Error(
        `The "${methodName}" method does not support "${type}" data type. Supports only: ${listOfTypes}`
      );
    }
    return handler(...args);
  };
