export const floatToRatio = (x, limit) => {
  if (Math.floor(x) === x) {
    return [x, 1];
  }

  const y = 1 / x;
  if (y > limit) {
    return [1, limit];
  }
  if (Math.floor(y) === y) {
    return [1, y];
  }

  const value = x > 1 ? y : x;

  let a = 0;
  let b = 1;
  let c = 1;
  let d = 1;

  while (true) {
    const numerator = a + c;
    const denominator = b + d;
    if (denominator > limit) break;

    if (value <= numerator / denominator) {
      [c, d] = [numerator, denominator];
    } else {
      [a, b] = [numerator, denominator];
    }
  }

  const middle = (a / b + c / d) / 2;

  if (value < middle) {
    return value === x ? [a, b] : [b, a];
  }

  if (value === x) {
    return [c, d];
  }

  return [d, c];
};
