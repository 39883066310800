import React from 'react';

/* eslint-disable max-len */
const ScheduleSVG = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="2.37421"
      y="1.5"
      width="15"
      height="15"
      rx="1.5"
      stroke="#767B92"
    />
    <line x1="2.87421" y1="5.5" x2="16.8742" y2="5.5" stroke="#767B92" />
    <line x1="7.37421" y1="-2.18557e-08" x2="7.37421" y2="3" stroke="#767B92" />
    <line x1="12.3742" y1="-2.18557e-08" x2="12.3742" y2="3" stroke="#767B92" />
  </svg>
);
/* eslint-enable max-len */

export default ScheduleSVG;
