import React from 'react';
import cn from 'classnames';
import { Icon } from '@components';
import styles from './ButtonIcon.module.css';

// TODO заменить на нормальную ButtonIcon
const ButtonIcon = ({ className, name, size, onClick, ...props }) => (
  <button
    type="button"
    className={cn(styles.btn, className)}
    onClick={onClick}
    {...props}>
    <Icon name={name} size={size} />
  </button>
);

export default ButtonIcon;
