import { useEffect, useRef } from 'react';

/**
 * Hook that save previous value
 *
 * @param {*} value
 * @param {*} defValue
 * @returns {*}
 */
export default function usePrevious(value, defValue) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef(value || defValue);

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
