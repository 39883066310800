import React, { useState } from 'react';
import { useTranslate, useActions } from '@hooks';
import { Modal, Button } from '@link/react-components';
import { endOfDay, startOfDay } from 'date-fns';

import * as modalAction from '../../../../action-creators/modal';
import * as useCase from '../../useCases';
import Checkbox from '../../Checkbox';
import PickDate from '../../Datepicker/datepickerWithOverlay';

import './style.css';

const translatePrefix = 'chat-widget.modal-history-download.';
const defaultCheckboxes = {
  history: true,
  files: true,
  images: true
};
const confirmModal = {
  title: `${translatePrefix}confirm_download`,
  text: `${translatePrefix}text_confirm_modal`
};

const Row = ({ t, value, label, onChange }) => (
  <div className="cw-choose-history-download__checkbox-container">
    <Checkbox id={`checkbox_${value}`} onClick={onChange} checked={value} />
    <label
      htmlFor={`checkbox_${value}`}
      className="cw-choose-history-download__checkbox-label">
      {t(`${translatePrefix}${label}`)}
    </label>
  </div>
);

const prepareDateForSend = (dateRange) => {
  let [fromDate, toDate] = dateRange;
  if (fromDate) {
    fromDate = startOfDay(fromDate);
  }
  if (toDate) {
    toDate = endOfDay(toDate);
  }

  return [fromDate, toDate];
};

const CWModalChooseHistoryDownload = ({ chatId, minDate }) => {
  const t = useTranslate();

  const { showModal, hideModalDialog } = useActions(modalAction);
  const { handleChatHistoryDownload } = useActions(useCase);

  const [checkboxes, setCheckboxes] = useState(defaultCheckboxes);
  const [rangeDate, setRangeDate] = useState([]);

  const onChangeCheckbox = (key, value) => () => {
    setCheckboxes((prevCheckboxes) => ({ ...prevCheckboxes, [key]: value }));
  };

  const isSubmitDisable = () => {
    const allCheckboxIsFalse = Object.keys(checkboxes).every(
      (key) => !checkboxes[key]
    );
    // filter [null, null]
    const dateIsExist = !rangeDate.filter((date) => date).length;
    return allCheckboxIsFalse || dateIsExist;
  };

  const onSubmit = () => {
    showModal('CW_CONFIRM_ACTION_WITH_PASSWORD', {
      ...confirmModal,
      submit: (password) => {
        const value = {
          password,
          chatId,
          checkboxes,
          interval: prepareDateForSend(rangeDate)
        };
        handleChatHistoryDownload('submit', value);
      },
      back: () => {
        showModal('CW_CHOOSE_HISTORY_DOWNLOAD', {
          chatId
        });
        handleChatHistoryDownload('clearErrors');
      },
      clearErrors: () => {
        handleChatHistoryDownload('clearErrors');
      },
      loadingName: 'chatHistory'
    });
  };

  return (
    <Modal onClose={hideModalDialog} open>
      <Modal.Header>{t(`${translatePrefix}title`)}</Modal.Header>
      <Modal.Content>
        <div>{t(`${translatePrefix}choose_data`)}</div>
        {Object.keys(checkboxes).map((key) => (
          <Row
            t={t}
            key={key}
            value={checkboxes[key]}
            label={key}
            onChange={onChangeCheckbox(key, !checkboxes[key])}
          />
        ))}
        <div className="cw-choose-history-download__date-label">
          {t(`${translatePrefix}choose_interval`)}
        </div>
        <div className="cw-choose-history-download__datepicker">
          <PickDate
            value={rangeDate}
            setDateRange={(date) => setRangeDate(date)}
            minDate={minDate}
          />
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Modal.Actions>
          <Button mode="text" onClick={hideModalDialog}>
            {t(`${translatePrefix}cancel`)}
          </Button>
          <Button
            mode="primary"
            onClick={onSubmit}
            disabled={isSubmitDisable()}>
            {t(`${translatePrefix}submit`)}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default CWModalChooseHistoryDownload;
