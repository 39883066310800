import React from 'react';

function SmallCrossSVG() {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.64648 9.53718L0.646484 1.53718L1.35359 0.830078L9.35359 8.83008L8.64648 9.53718Z"
        fill="#767B92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.35359 9.53718L9.35359 1.53718L8.64648 0.830078L0.646484 8.83008L1.35359 9.53718Z"
        fill="#767B92"
      />
    </svg>
  );
}

export default SmallCrossSVG;
