import { getFiles as getInputFiles } from '../../data-type/block-input';
import { getChatInput, getOpenedChat } from '../../getter';
import { getTempFilesForRemove } from '../../../../storeGetters';

const filterFilesByIds = (files, ids) =>
  files.filter((file) => !ids.includes(file.id));

export const getNotRemovedFiles =
  (editMode = false) =>
  (state) => {
    const chat = getOpenedChat(state);
    const input = getChatInput(state);
    const filesToRemove = getTempFilesForRemove(state);
    const files = chat?.files || [];
    if (editMode)
      return filterFilesByIds(
        files.concat(getInputFiles(input)),
        filesToRemove
      );
    return getInputFiles(input);
  };
