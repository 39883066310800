import React from 'react';

/* eslint-disable max-len */
function IconSimpleSearch() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 9C9.67157 9 9 9.67157 9 10.5V23.5C9 24.3284 9.67157 25 10.5 25H23.5C24.3284 25 25 24.3284 25 23.5V14H26V23.5C26 24.8807 24.8807 26 23.5 26H10.5C9.11929 26 8 24.8807 8 23.5V10.5C8 9.11929 9.11929 8 10.5 8H20V9H10.5Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1465 19.8541C13.9512 19.6588 13.9512 19.3423 14.1465 19.147L24.6469 8.64606C24.8422 8.45079 25.1588 8.45079 25.354 8.64604C25.5493 8.8413 25.5493 9.15788 25.354 9.35315L14.8536 19.8541C14.6584 20.0494 14.3418 20.0494 14.1465 19.8541Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 13C14.7761 13 15 13.2239 15 13.5V19H20.5C20.7761 19 21 19.2239 21 19.5C21 19.7761 20.7761 20 20.5 20H14.5C14.2239 20 14 19.7761 14 19.5V13.5C14 13.2239 14.2239 13 14.5 13Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconSimpleSearch;
