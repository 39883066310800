import React from 'react';

/* eslint-disable max-len */
function IconRemove2() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5V12H13V22.5C13 23.3284 13.6716 24 14.5 24H19.5C20.3284 24 21 23.3284 21 22.5V12H22V22.5C22 23.8807 20.8807 25 19.5 25H14.5C13.1193 25 12 23.8807 12 22.5Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 13L11 13L11 12L23 12V13Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 10H15V9H19V10Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconRemove2;
