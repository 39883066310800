import { Iterable } from 'immutable';

export const getContacts = (state) => {
  const contacts = state.getIn(['contacts', 'contacts']);

  if (Array.isArray(contacts)) return contacts;
  if (Iterable.isIterable(contacts)) return contacts.toJS();

  return [];
};

export const getLimit = (state) => state.getIn(['contacts', 'limit']);
export const getPage = (state) => state.getIn(['contacts', 'page']);
export const getSize = (state) => state.getIn(['contacts', 'size']);
