import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const getContainerClassName = (checked, disabled) => {
  let className = 'cwsa-checkbox__container';
  if (checked) {
    className += ' cwsa-checkbox__container-checked';
  }
  if (disabled) {
    className += ' cwsa-checkbox__container-disabled';
  }
  return className;
};

const getMarkClassName = (checked, disabled) => {
  let className = 'cwsa-checkbox__mark';
  if (checked) {
    className += ' cwsa-checkbox__mark-checked';
  }
  if (disabled) {
    className += ' cwsa-checkbox__mark-disabled';
  }
  return className;
};

const Checkbox = ({ checked, onClick, disabled }) => (
  <span
    className={getContainerClassName(checked, disabled)}
    onClick={disabled ? null : onClick}>
    <img
      className={getMarkClassName(checked, disabled)}
      src="/img/ok-1.svg"
      alt="ok"
    />
  </span>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

Checkbox.defaultProps = {
  checked: true,
  onClick: () => {},
  disabled: false
};

export default Checkbox;
