import { isEmpty } from 'ramda';

export const makeCreateGroupChatMV = ({
  name,
  isPristine,
  onChangeName,
  onCreateGroupChat,
  hideModal,
  maxLength
}) => ({
  name,
  isPristine,
  onChangeName,
  onCreateGroupChat,
  hideModal,
  maxLength
});

export const getName = (vm) => vm.name;
export const getMaxLength = (vm) => vm.maxLength;
export const isNameValid = (vm) =>
  !isEmpty(getName(vm).trim()) && getName(vm).trim().length <= getMaxLength(vm);
export const getCounter = (vm) => `${vm.name.length}/${getMaxLength(vm)}`;
export const initHideModal = (vm) => vm.hideModal;
export const initOnChangeName = (vm) => vm.onChangeName;
export const initOnCreateGroupChat = (vm) => vm.onCreateGroupChat;
export const isSubmitDisable = (vm) => !isNameValid(vm);
export const checkErrors = (vm) =>
  !isNameValid(vm) && !vm.isPristine ? 'error' : null;
export const isShowError = (vm) => isEmpty(vm.name.value) || !!checkErrors(vm);
