import React, { useRef } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  init,
  makeListVM,
  getTitle,
  getPlaceholder,
  getRows,
  getSearchBlockWidth,
  initOnCancel,
  initOnSearch,
  isShowItems
} from './dataType';
import { makeMountEffect } from '../../../../../../lib/sideEffects';
import * as modalActions from '../../../../../../action-creators/modal';
import { useTranslate } from '../../../../../../TranslateProvider';
import TemplateModal from '../components/Modal';

const wrapper = (props, ref) => {
  const onMountEffect = makeMountEffect();
  const t = useTranslate();

  const vm = makeListVM({
    t,
    title: props.title,
    placeholder: props.placeholder,
    mode: props.mode,
    search: props.search,
    items: props.items,
    limit: props.limit,
    page: props.page,
    size: props.size,
    onMountEffect,
    onCancel: props.onCancel,
    showModal: props.showModal,
    hideModal: props.hideModal,
    searchItems: props.loadItems,
    loadItems: props.loadItems,
    loadItemsChunk: props.loadItemsChunk,
    searchBlockWidth: props.searchBlockWidth,
    children: props.children
  });

  init(vm, ref);

  return vm;
};

const ItemsFromServer = (props) => {
  const ref = useRef();

  const vm = wrapper(props, ref);

  return (
    <TemplateModal
      ref={ref}
      title={getTitle(vm)}
      rows={getRows(vm)}
      placeholder={getPlaceholder(vm)}
      width={getSearchBlockWidth(vm)}
      onSearch={initOnSearch(vm)}
      onHide={initOnCancel(vm)}
      isShowItems={isShowItems(vm)}
    />
  );
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      showModal: modalActions.showModal,
      hideModal: modalActions.hideModalDialog
    },
    dispatch
  );

export default connect(null, mapDispatch)(ItemsFromServer);
