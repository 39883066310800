export const FULL = 'full';
export const SHORT = 'short';

export const FULL_WIDTH = 226;
export const SHORT_WIDTH = 62;

export const MAX_BADGE_COUNT_TO_SHOW = 99;

export const ICON_TYPE_LINK = 'link';
export const ICON_TYPE_DEFAULT = 'default';
