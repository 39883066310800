import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '../../../../../TranslateProvider';
import Checkbox from '../../../Checkbox';
import ButtonIcon from '../../../ButtonIcon';
import './style.css';

const CWSAHeader = ({
  title,
  isCheckedAll,
  setCheckedAll,
  onAddList,
  disabled,
  onClose
}) => {
  const t = useTranslate();

  return (
    <div className="cwsa-header">
      <div className="cwsa-dashboard">
        <div className="cwsa-dashboard__left">
          <Checkbox
            checked={isCheckedAll}
            onClick={() => setCheckedAll(!isCheckedAll)}
            disabled={disabled}
          />
          <span className="cwsa-dashboard__text">{t(title)}</span>
        </div>
        <div className="cwsa-dashboard__right">
          <ButtonIcon
            icon="add"
            tooltip={t('create-list')}
            onClick={onAddList}
          />
          <div className="cwsa-dashboard__separator" />
          <div style={{ transform: 'rotate(45deg)' }}>
            <ButtonIcon icon="add" onClick={onClose} />
          </div>

          <div className="cwsa-dashboard__separator" />
        </div>
      </div>
    </div>
  );
};

CWSAHeader.propTypes = {
  title: PropTypes.string.isRequired,
  isCheckedAll: PropTypes.bool.isRequired,
  setCheckedAll: PropTypes.func.isRequired,
  onAddList: PropTypes.func.isRequired
};

export default CWSAHeader;
