import { checkFieldValid } from '../../../../lib/validations';
import { trans } from '../../../../modules/translate';

export const makeEditGroupChatVM = ({
  t,
  name = '',
  maxNameLength = 60,
  setName,
  modalTitle,
  setModalTitle,
  chat,
  mode = 'Topic',
  isShowPrevious = false,
  hasMemberChanges = false,
  setHasMemberChanges,
  readOnly = false,
  onSubmit,
  onCancel,
  showModalPickNewMembers,
  btnTextConfirm = 'Save',
  btnTextCancel = 'Cancel'
}) => ({
  t,
  name,
  maxNameLength,
  setName,
  modalTitle,
  setModalTitle,
  chat,
  mode,
  isShowPrevious,
  hasMemberChanges,
  setHasMemberChanges,
  readOnly,
  onSubmit,
  onCancel,
  showModalPickNewMembers,
  btnTextConfirm,
  btnTextCancel
});

export const getModalTitle = (vm) => trans(vm.modalTitle, vm);
export const getMode = (vm) => vm.mode;
export const getTitle = (vm) => trans('Name', vm);
export const getName = (vm) => vm.name;
export const getMaxNameLength = (vm) => vm.maxNameLength;
export const checkValidStatus = (vm) => checkFieldValid(vm.name);
const isValid = (vm) => checkValidStatus(vm) !== 'error';
export const getChat = (vm) => vm.chat;
export const getChatMembers = (vm) => getChat(vm).members;
export const isReadOnly = (vm) => vm.readOnly;
export const getBtnTextCancel = (vm) => trans(vm.btnTextCancel, vm);
export const getBtnTextConfirm = (vm) => trans(vm.btnTextConfirm, vm);
export const getMembersBlockLabel = (vm) =>
  trans(`${vm.mode.toLowerCase()}.members`, vm);
export const isShowAddMembersBtn = (vm) => !isReadOnly(vm);
export const getAddMemberBtnText = (vm) =>
  `+${trans('channel.add-members', vm)}`;
const hasChanges = (vm) =>
  vm.hasMemberChanges || getChat(vm).name !== getName(vm);
export const isSubmitDisable = (vm) => !(isValid(vm) && hasChanges(vm));

export const initOnSubmit = (vm) => () =>
  vm.onSubmit({
    chat: vm.chat,
    name: vm.name,
    readOnly: vm.readOnly,
    isShowPrevious: vm.isShowPrevious
  });

export const initOnCancel = (vm) => () => vm.onCancel();

export const initOnChange =
  (vm) =>
  ({ target }) => {
    vm.setName(target.value);
  };

export const initOnKeyDown = (vm) => (event) => {
  // if press "Enter"
  if (event.keyCode === 13) {
    event.preventDefault();

    if (isValid(vm)) initOnSubmit(vm)();
  }
};

export const initShowModalPickNewMembers = (vm) => () => {
  vm.showModalPickNewMembers({ chat: vm.chat, mode: vm.mode, name: vm.name });
};
