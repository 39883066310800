import React, { Fragment, useMemo } from 'react';
import { isNil, pipe } from 'ramda';
import { groupSortByDate } from 'core/data/light/message';
import { useSelector } from 'react-redux';
import { isType } from 'core/lib';
import { StickyDateBar } from '../StickyDateBar';
import { StaticDateBar } from '../StaticDateBar';
import ItemMessageContainer from '../../itemMessageContainer';
import DividerNewMessage from '../../../message-divider/DividerNewMessage';
import { getDividerIdByChatId } from '../../../chat-items/helpers';
import { useClickByVideoChatLink } from '../../../../../../modules/VideoChat/hooks/useClickByVideoChatLink';
import { addPopUpSN, isDeliveredMessage, isUnsentMessage } from '../../methods';
import { popUpSNData } from '../../../chat-system-notification/lib';
import * as storeGetters from '../../../../../../storeGetters';
import { useTranslate } from '../../../../../../TranslateProvider';
import * as dictionariesGetters from '../../../../../../modules/dictionaries/getters';
import { getLastUnreadMessageByChatId } from '../../../../getter';
import { addUnsentMessages } from './addUnsentMessages';
import styles from './MessagesGroupContainer.module.css';

const MessagesGroupContainer = ({
  chat,
  messages,
  handlerMsg,
  config,
  selectedList,
  isCanLoadNext,
  isSelectMode,
  isShowUnreadMessagesDivider,
  isShowPopUpSystemNotification,
  datePanelFormat,
  messagesPlacement
}) => {
  const t = useTranslate();
  const onClickByVideoChatLink = useClickByVideoChatLink();

  const currentEmployeeId = useSelector(storeGetters.getCurrentEmployeeId);
  const lastUnreadMessageId = useSelector((state) =>
    getLastUnreadMessageByChatId(state, chat)
  );
  const internetConnection = useSelector((state) =>
    state.getIn(['connections', 'internet'])
  );
  const language = useSelector(storeGetters.getCurrentUserLanguage);
  const popUpSystemNotificationData = useSelector(popUpSNData);
  const PRCategoriesList = useSelector(
    dictionariesGetters.getPurchaseRequestsCategories
  );

  const sortedMessages = useMemo(() => {
    const deliveredMessages = messages.filter(isDeliveredMessage);
    const unsentMessages = messages.filter(isUnsentMessage);

    return pipe(
      addPopUpSN,
      groupSortByDate,
      addUnsentMessages(unsentMessages)
    )({
      chat,
      messages: deliveredMessages,
      isCanLoadNext,
      isShowPopUpSystemNotification,
      popUpSystemNotificationData
    });
  }, [chat, messages, isCanLoadNext, popUpSystemNotificationData]);

  const checkPinned = (message) =>
    !isNil(chat.pinnedMessages) &&
    chat.pinnedMessages.some((m) => m.id === message.id);

  function isForwardedCortege(message, prevMessage) {
    return (
      !isNil(prevMessage) &&
      isType('MsgForwarded', prevMessage) &&
      isType('MsgForwarded', message) &&
      message.employeeId === prevMessage.employeeId
    );
  }

  return sortedMessages.map(([date, messagesList]) => (
    <div key={`${chat.id}-${date}`} className={styles.container}>
      <StickyDateBar
        language={language}
        date={date}
        datePanelFormat={datePanelFormat}
      />
      <StaticDateBar
        language={language}
        date={date}
        datePanelFormat={datePanelFormat}
      />
      {messagesList.map((message, index) => (
        <Fragment key={`${message.id}-wrapper`}>
          {isShowUnreadMessagesDivider && (
            <DividerNewMessage
              key={`${message.id}-divider`}
              id={getDividerIdByChatId(chat.id)}
              chat={chat}
              messageId={message.id}
            />
          )}
          <ItemMessageContainer
            t={t}
            key={message.id}
            chat={chat}
            currentEmployeeId={currentEmployeeId}
            internetConnection={internetConnection}
            PRCategoriesList={PRCategoriesList}
            message={message}
            handler={handlerMsg}
            isSelected={selectedList.includes(message.id)}
            isSelectMode={isSelectMode}
            isPinned={checkPinned(message)}
            isRead={!chat.unreadMessagesId.includes(message.id)}
            isForwardedCortege={isForwardedCortege(
              message,
              messagesList[index - 1]
            )}
            config={config}
            isShowUnreadMessagesDivider={
              isShowUnreadMessagesDivider && lastUnreadMessageId === message.id
            }
            messagesPlacement={messagesPlacement}
            onClickByVideoChatLink={onClickByVideoChatLink}
          />
        </Fragment>
      ))}
    </div>
  ));
};

export default React.memo(MessagesGroupContainer);
