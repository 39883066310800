import { useEffect, useRef, useState } from 'react';

export const useIsClickOutside = (initialValue) => {
  const [isShow, setIsShow] = useState(initialValue);
  const ref = useRef(null);

  useEffect(() => {
    const f = (e) => {
      if (ref.current && !ref.current?.contains(e.target)) {
        setIsShow(false);
      }
    };
    document.addEventListener('click', f);
    return () => document.removeEventListener('click', f);
  }, []);

  return { ref, isShow, setIsShow };
};
