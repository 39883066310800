import React from 'react';
import ItemsOnPage from '../../views/ItemsOnPage';

const list = [20, 50, 100];
const defaultValue = list[0];

export default ({ onChange, value, style }) => {
  const isExitsValue = value && list.includes(value);

  if (!isExitsValue) {
    console.error('Not exists such value:', value, 'in list', list);
  }

  const displayValue = isExitsValue ? value : defaultValue;
  return (
    <ItemsOnPage
      value={displayValue}
      list={list}
      onChange={onChange}
      style={style}
    />
  );
};
