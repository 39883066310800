import {
  USER_ACCESS_ROLES_SET,
  USER_ACCESS_ROLES_CLEAR
} from '../actionTypes/userAccessRoles';

export const setUserAccessRoles = (userAccessRoles) => ({
  type: USER_ACCESS_ROLES_SET,
  payload: userAccessRoles
});
export const clearUserAccessRoles = () => ({
  type: USER_ACCESS_ROLES_CLEAR,
  payload: {}
});
