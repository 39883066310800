import { useDispatch } from 'react-redux';
import { useNavigate } from '@hooks';
import { useCallback } from 'react';
import { noop } from '../../utils/utils';
import * as chatWidgetUC from '../ChatWidget/useCases';

export function useOpenChat({ getChat, onOpened = noop, isWidget = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useCallback(async () => {
    const chat = await getChat();

    if (isWidget) {
      dispatch(chatWidgetUC.toggleChatWidget());
      dispatch(chatWidgetUC.handleChatList('open-chat', chat));
      onOpened(chat.id);
    } else {
      navigate(`/chat/${chat.id}`, { replace: true });
      onOpened(chat.id);
    }
  }, [getChat, onOpened, isWidget]);
}
