import { useCallback, useEffect } from 'react';

function getStep(propsStep, defaultStep) {
  return propsStep && !Number.isNaN(+propsStep) ? propsStep : defaultStep;
}

function useZoom(props) {
  const { wrapperNode } = props.state;
  const { setScale } = props.methods;

  const zoomIn = useCallback((propsStep) => {
    const { scale, maxScale, step } = props.state;
    let newScale = scale + getStep(propsStep, step);

    if (newScale > maxScale) newScale = maxScale;

    setScale(newScale);
  }, []);

  const zoomOut = useCallback((propsStep) => {
    const { scale, minScale, step } = props.state;
    let newScale = scale - getStep(propsStep, step);

    if (newScale < minScale) newScale = minScale;

    setScale(newScale);
  }, []);

  const zoomReset = useCallback(() => {
    const { defaultScale } = props.state;

    setScale(defaultScale);
  }, []);

  const zoomChange = useCallback((value) => {
    const { minScale, maxScale } = props.state;
    let newScale = +value;

    if (!Number.isNaN(newScale)) {
      if (newScale > maxScale) newScale = maxScale;
      if (newScale < minScale) newScale = minScale;
      setScale(newScale);
    } else {
      console.error(`Invalid zoomChange param value`);
    }
  }, []);

  const onClickByImage = () => {
    const { scale: currentScale, minScale } = props.state;
    if (minScale === currentScale) {
      zoomIn(0.75);
    } else {
      zoomReset();
    }
  };

  useEffect(() => {
    if (wrapperNode) {
      wrapperNode.addEventListener('dblclick', onClickByImage);

      return () => wrapperNode.removeEventListener('dblclick', onClickByImage);
    }

    return undefined;
  }, [wrapperNode]);

  return { zoomIn, zoomOut, zoomChange, zoomReset };
}

export default useZoom;
