import { useModal } from '@hooks';
import { useCallback } from 'react';
import { CHOOSE_SUPPLIER_WIZARD_KEY } from './constants';

export function useChooseSupplierWizard() {
  const modalActions = useModal();

  const open = useCallback(
    ({ chosenCompanies, onChoose }) => {
      modalActions.onOpen(CHOOSE_SUPPLIER_WIZARD_KEY, {
        chosenCompanies,
        onChoose
      });
    },
    [modalActions.onOpen]
  );

  const close = useCallback(() => {
    modalActions.onClose(CHOOSE_SUPPLIER_WIZARD_KEY);
  }, [modalActions.onClose]);

  return { open, close };
}
