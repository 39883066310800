import React, { Component, Fragment } from 'react';
import { FormControl } from 'react-bootstrap';

// eslint-disable-next-line react/prefer-stateless-function
class InputText extends Component {
  render() {
    const {
      viewRead,
      value = '',
      clearButton = false,
      onClear = () => {},
      className = '',
      ...restProps
    } = this.props;

    if (viewRead) {
      return <div style={{ padding: 7 }}>{value || '-'}</div>;
    }

    const props = { ...this.props };
    delete props.viewRead;

    return (
      <>
        <FormControl
          type="text"
          value={value}
          {...restProps}
          className={className}
        />
        {clearButton && (
          <img
            style={{
              position: 'absolute',
              top: 39,
              bottom: 0,
              right: 20,
              cursor: 'pointer'
            }}
            onClick={onClear}
            role="presentation"
            src="/img/cross.svg"
            alt=""
          />
        )}
      </>
    );
  }
}

export default InputText;
