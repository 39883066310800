const ALLOWED_TYPE_CHATS = ['dialog', 'channel', 'topic'];
exports.ALLOWED_TYPE_CHATS = ALLOWED_TYPE_CHATS;

const makeScheduleMessage = ({ message, date, sent = false }) => ({
  message,
  date,
  isSent: sent
});

exports.makeScheduleMessage = makeScheduleMessage;

exports.getScheduleMsgsOfCurrentEmployee = (channel, employeeId) => {
  const { scheduleMessages = [] } = channel;

  return scheduleMessages
    .filter(belongsCurrentEmployee)
    .map(makeScheduleMessage);

  function belongsCurrentEmployee(message) {
    return message.memberId === employeeId;
  }
};

exports.getScheduleMessageByMsgId = (channel, messageId) => {
  const { type, scheduleMessages = [] } = channel;

  return ALLOWED_TYPE_CHATS.includes(type.toLowerCase())
    ? scheduleMessages.find((item) => item.message.id === messageId)
    : null;
};
