import { isEmpty, isNil } from 'ramda';
import { getOpenedChat } from '../../getter';

export const getPinnedMessage = (state) => {
  const openedChat = getOpenedChat(state);

  if (isNil(openedChat) || isNil(openedChat.pinnedMessages)) return null;

  return !isEmpty(openedChat.pinnedMessages)
    ? openedChat.pinnedMessages[0]
    : null;
};
