import React from 'react';
import Select from 'react-select';

const ReactSelect = (props) => {
  const { viewRead = false, value, options } = props;

  if (viewRead) {
    const selectedOptions = options.find((opt) => opt.value === value);
    return (
      <div style={{ padding: 7 }}>
        {(selectedOptions && selectedOptions.label) || '-'}
      </div>
    );
  }

  return <Select {...props} />;
};

export default ReactSelect;
