import React from 'react';
import styled, { keyframes } from 'styled-components';

const Info = () => (
  <SvgWrapper
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 100 100">
    <GWrapper>
      <G>
        <FirstCircleWrapper>
          <Circle cx="20" cy="50" r="10" fill="#ffffff" />
        </FirstCircleWrapper>
        <SecondCircleWrapper>
          <Circle cx="50" cy="50" r="10" fill="#ffffff" />
        </SecondCircleWrapper>
        <ThirdCircleWrapper>
          <Circle cx="80" cy="50" r="10" fill="#ffffff" />
        </ThirdCircleWrapper>
      </G>
    </GWrapper>
  </SvgWrapper>
);

const ldFade = keyframes`
  0 % {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const SvgWrapper = styled.svg`
  transform-origin: 50px 50px 0;
  height: 18px;
  width: 18px;
`;

const G = styled.g`
  transform-origin: 50px 50px 0;
`;

const GWrapper = styled(G)`
  transform: scale(0.8);
`;

const CommonCircleWrapperStyled = styled.g`
  animation: ${ldFade} 1s infinite linear;
  -webkit-animation: ${ldFade} 1s infinite linear;
`;

const FirstCircleWrapper = styled(CommonCircleWrapperStyled)`
  transform-origin: 50px 50px 0;
  animation-duration: 1s;
  animation-delay: -0.923077s;
`;

const SecondCircleWrapper = styled(CommonCircleWrapperStyled)`
  transform-origin: 50px 50px 0;
  animation-duration: 1s;
  animation-delay: -0.846154s;
`;

const ThirdCircleWrapper = styled(CommonCircleWrapperStyled)`
  transform-origin: 50px 50px 0;
  animation-duration: 1s;
  animation-delay: -0.769231s;
`;

const Circle = styled.circle`
  fill: rgb(255, 255, 255);
`;

export default Info;
