export class CompanyPurchaseRequestDTO {
  constructor(props) {
    this.id = props.id;
    this.categories = props.categories;
    this.country = props.country;
    this.region = props.region;
    this.city = props.city;
    this.companyId = props.companyId;
    this.sum = props.sum;
    this.responseEndDate = props.responseEndDate;
    this.lastPublishedDate = props.lastPublishedDate;
    this.title = props.title;
    this.number = props.number;
    this.status = props.status;
    this.viewed = props.viewed;
    this.responded = props.responded;
    this.updatedAt = props.updatedAt;
  }
}
