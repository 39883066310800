import React, { useState } from 'react';
import classNames from 'classnames';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';

export const Spinner = ({ size = '4rem' }) => {
  const [visible, setVisible] = useState(false);

  const handleVisibilityChanged = ({ isVisible = false }) => {
    setVisible(isVisible);
  };

  const containerRef = useIntersectionObserver({
    onVisibilityChanged: handleVisibilityChanged
  });

  return (
    <div
      className={classNames({
        'pdf-viewer__spinner': true,
        'pdf-viewer__spinner_animating': visible
      })}
      ref={containerRef}
      style={{ height: size, width: size }}
    />
  );
};
