import { SpecialZoomLevel } from '../constants';

const PAGE_PADDING = 8;
const SCROLL_BAR_WIDTH = 17;

export function calculateScale(container, pageHeight, pageWidth, scale) {
  switch (scale) {
    case SpecialZoomLevel.DefaultSize:
      return 1;
    case SpecialZoomLevel.PageFit:
      return Math.min(
        (container.clientWidth - SCROLL_BAR_WIDTH) / pageWidth,
        (container.clientHeight - 2 * PAGE_PADDING) / pageHeight
      );
    case SpecialZoomLevel.PageWidth:
      return (container.clientWidth - SCROLL_BAR_WIDTH) / pageWidth;
    default:
      throw new Error(`Incorrect scale param passed`);
  }
}
