import { useState, useEffect } from 'react';
import { getClientPosition, checkPositionBounds } from './utils';

function usePan(props) {
  const { state, methods } = props;
  const { wrapperNode, contentNode } = state;
  const { disable } = state.panning;
  const [startCoords, setStartCoords] = useState(null);
  const [isDown, setIsDown] = useState(false);

  const checkDistBetweenTouches = ({ touches }) =>
    touches &&
    (touches.length !== 1 ||
      Math.abs(startCoords.x - touches[0].clientX) < 1 ||
      Math.abs(startCoords.y - touches[0].clientY) < 1);

  const checkIsPanningActive = (event) =>
    !isDown ||
    disable ||
    !wrapperNode ||
    !contentNode ||
    checkDistBetweenTouches(event);

  const savePosition = (positionX, positionY) => {
    methods.setPosition({ positionX, positionY });
  };

  const handleSetUpPanning = (x, y) => {
    const { positionX, positionY } = state;

    setIsDown(true);
    setStartCoords({ x: x - positionX, y: y - positionY });
  };

  const handleStartPanning = (event) => {
    const { scale, minScale, maxScale } = state;
    const { target, touches } = event;

    if (checkPanningDeny()) return null;

    // For mobile
    if (touches?.length === 1) {
      handleSetUpPanning(touches[0].clientX, touches[0].clientY);
    }

    // For desktop
    if (!touches) {
      handleSetUpPanning(event.clientX, event.clientY);
    }

    return undefined;

    function checkPanningDeny() {
      return (
        disable ||
        !wrapperNode?.contains(target?.parentNode) ||
        scale < minScale ||
        scale > maxScale
      );
    }
  };

  const handlePanningFunc = (event) => {
    const { positionX, positionY, bounds } = state;

    if (!startCoords) return null;
    const { x, y } = startCoords;

    const position = getClientPosition(event);
    if (!position) return console.error('Cannot find mouse client position');
    const { clientX, clientY } = position;

    const newPositionX = clientX - x;
    const newPositionY = clientY - y;

    if (newPositionX === positionX && newPositionY === positionY) return null;

    const calculatedPosition = checkPositionBounds(
      newPositionX,
      newPositionY,
      bounds
    );

    return savePosition(calculatedPosition.x, calculatedPosition.y);
  };

  const handlePanning = (event) => {
    if (isDown) event.preventDefault();
    if (checkIsPanningActive(event)) return null;
    event.stopPropagation();
    return handlePanningFunc(event);
  };

  const handleStopPanning = () => {
    if (isDown) {
      setIsDown(false);
    }
  };

  useEffect(() => {
    if (!disable) {
      const passiveOption = false;

      window.addEventListener('mousedown', handleStartPanning, passiveOption);
      window.addEventListener('mousemove', handlePanning, passiveOption);
      window.addEventListener('mouseup', handleStopPanning, passiveOption);

      return () => {
        window.removeEventListener(
          'mousedown',
          handleStartPanning,
          passiveOption
        );
        window.removeEventListener('mousemove', handlePanning, passiveOption);
        window.removeEventListener('mouseup', handleStopPanning, passiveOption);
      };
    }

    return undefined;
  }, [props, disable]);
}

export default usePan;
