import React, { useContext, useEffect, useState } from 'react';
import { getPage } from '../../utils/managePages';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { SpinnerContext } from './SpinnerContext';
import { ThumbnailItem } from './ThumbnailItem';

const THUMBNAIL_WIDTH = 100;

export const ThumbnailContainer = ({
  doc,
  pageIndex,
  pageHeight,
  pageWidth,
  shouldRender,
  onRenderCompleted,
  onVisibilityChanged
}) => {
  const { renderSpinner } = useContext(SpinnerContext);
  const [pageSize, setPageSize] = useState({
    page: null,
    height: pageHeight,
    width: pageWidth
  });

  const { page, width, height } = pageSize;

  const scale = width / height;
  const isVertical = true;
  const w = isVertical ? THUMBNAIL_WIDTH : THUMBNAIL_WIDTH / scale;
  const h = isVertical ? THUMBNAIL_WIDTH / scale : THUMBNAIL_WIDTH;

  useEffect(() => {
    if (shouldRender) {
      getPage(doc, pageIndex).then((pdfPage) => {
        const viewport = pdfPage.getViewport({ scale: 1 });
        setPageSize({
          page: pdfPage,
          height: viewport.height,
          width: viewport.width
        });
      });
    }
  }, [shouldRender]);

  const containerRef = useIntersectionObserver({
    onVisibilityChanged: (visibility) =>
      onVisibilityChanged(pageIndex, visibility)
  });

  return (
    <div className="pdf-viewer-thumbnail__container" ref={containerRef}>
      {!page ? (
        renderSpinner()
      ) : (
        <ThumbnailItem
          page={page}
          pageIndex={pageIndex}
          pageHeight={isVertical ? height : width}
          pageWidth={isVertical ? width : height}
          thumbnailHeight={h}
          thumbnailWidth={w}
          onRenderCompleted={onRenderCompleted}
        />
      )}
    </div>
  );
};
