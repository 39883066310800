import { isType } from 'core/lib';

const getTranslateKey = (type, message) => {
  if (isType('MsgCreatedChannel', message)) return `${type}.created`;
  if (isType('MsgAddedToChannel', message)) return `${type}.added`;
  if (isType('MsgExcludedFromChannel', message)) return `${type}.excluded`;
  if (isType('MsgLeftFromChannel', message)) return `${type}.left`;
  if (isType('MsgRenamedChannel', message)) return `${type}.renamed`;
  if (isType('MsgRegainedStatus', message)) return `${type}.regained-status`;
  return '';
};

const getMsgSystemText = (t, type, message) =>
  t(getTranslateKey(type, message), {
    ...message,
    interpolation: { escapeValue: false }
  });

export default getMsgSystemText;
