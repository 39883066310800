import React, { useContext, useEffect, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { SpinnerContext } from './SpinnerContext';
import { LazyRender } from '../../components/LazyRender';
import { ThumbnailList } from './ThumbnailList';

export const ThumbnailListWithStore = ({
  renderCurrentPageLabel,
  renderThumbnailItem,
  store
}) => {
  const [currentDoc, setCurrentDoc] = useState(store.get('doc'));
  const [currentPage, setCurrentPage] = useState(store.get('currentPage') || 0);
  const [pageHeight, setPageHeight] = useState(store.get('pageHeight') || 0);
  const [pageWidth, setPageWidth] = useState(store.get('pageWidth') || 0);
  const { renderSpinner } = useContext(SpinnerContext);

  const handleCurrentPageChanged = (currentPageIndex) => {
    setCurrentPage(currentPageIndex);
  };

  const handleDocumentChanged = (doc) => {
    setCurrentDoc(doc);
  };

  const handlePageHeightChanged = (height) => {
    setPageHeight(height);
  };

  const handlePageWidthChanged = (width) => {
    setPageWidth(width);
  };

  const jump = (pageIndex) => {
    const jumpToPage = store.get('jumpToPage');

    if (!jumpToPage) {
      console.error('Method not found');
      return;
    }

    jumpToPage(pageIndex);
  };

  useEffect(() => {
    store.subscribe('doc', handleDocumentChanged);
    store.subscribe('pageHeight', handlePageHeightChanged);
    store.subscribe('pageWidth', handlePageWidthChanged);

    return () => {
      store.unsubscribe('doc', handleDocumentChanged);
      store.unsubscribe('pageHeight', handlePageHeightChanged);
      store.unsubscribe('pageWidth', handlePageWidthChanged);
    };
  }, []);

  useIsomorphicLayoutEffect(() => {
    store.subscribe('currentPage', handleCurrentPageChanged);

    return () => {
      store.unsubscribe('currentPage', handleCurrentPageChanged);
    };
  }, []);

  if (!currentDoc) {
    return (
      <div className="pdf-viewer-thumbnail__loader">{renderSpinner()}</div>
    );
  }

  return (
    <LazyRender attrs={{ className: 'pdf-viewer-thumbnail__list-container' }}>
      <ThumbnailList
        doc={currentDoc}
        currentPage={currentPage}
        pageWidth={pageWidth}
        pageHeight={pageHeight}
        renderThumbnailItem={renderThumbnailItem}
        renderCurrentPageLabel={renderCurrentPageLabel}
        onJumpToPage={jump}
      />
    </LazyRender>
  );
};
