import request from '../../../../lib/request';

export const connectEmail = (type) =>
  request.post(`/api/oauth2-email/connect/${type}`);
export const verifyEmail = (data) =>
  request.post(`/api/oauth2-email/verify`, data);
export const revokeEmail = (email) =>
  request.delete(`/api/oauth2-email/revoke/${email}`);
export const fetchAllMessages = () =>
  request.get(`/api/oauth2-email/messages/all`);
export const fetchAllConnectedEmails = () =>
  request.get(`/api/oauth2-email/connected-emails`);
export const sendEmailMessage = (data) =>
  request.post(`/api/oauth2-email/message/send`, data);
