import React, { useCallback, useReducer, useRef } from 'react';
import { Button, Icon, Modal } from '@link/react-components';
import { useActions, useTranslate } from '@hooks';

import * as modalActions from '../../../action-creators/modal';
import { purchaseRequestService } from '../../../api';
import { FileSection } from './components/FileSection';
import { Content } from './components/Content';

import {
  allowedFileTypes,
  MAX_FILE_SIZE,
  MODE,
  ERROR_TYPES,
  INPUT_TYPES
} from './components/constants';
import styles from './components/FileSection/FileSection.module.css';
import * as messageAction from '../../../action-creators/message';
import { initialState, reducer } from './reducer';

const ModalImportResponse = ({
  onChangeProductImport,
  requestId,
  onExportToExcel
}) => {
  const t = useTranslate();
  const { hideModalDialog, setSuccessMessage } = useActions({
    ...modalActions,
    ...messageAction
  });

  const ref = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  const onClose = () => {
    hideModalDialog();
  };

  const onFileLoad = useCallback((e) => {
    const targetFile = e.target.files[0];
    if (targetFile.size > MAX_FILE_SIZE) {
      dispatch({
        type: INPUT_TYPES.LOAD_FILE_ERROR,
        payload: {
          file: targetFile,
          error: t('modalImportResponse.errors.size')
        }
      });
      return;
    }
    if (!allowedFileTypes.includes(targetFile.type)) {
      dispatch({
        type: INPUT_TYPES.LOAD_FILE_ERROR,
        payload: {
          file: targetFile,
          error: t('modalImportResponse.errors.type')
        }
      });
      return;
    }
    dispatch({
      type: INPUT_TYPES.LOAD_FILE_SUCCESS,
      payload: targetFile
    });
  }, []);

  const onExchangeFileClick = () => {
    ref.current.click();
  };

  const onLoad = async () => {
    dispatch({ type: INPUT_TYPES.LOADING });
    try {
      const data = await purchaseRequestService.convertProductsToJson(
        requestId,
        state.file
      );
      dispatch({ type: INPUT_TYPES.SUCCESS });
      onChangeProductImport(data);
      setSuccessMessage({
        key: 'notifications.successImportResponse'
      });
      hideModalDialog();
    } catch (e) {
      switch (e.name) {
        case ERROR_TYPES.FILE_PARSE: {
          dispatch({
            type: INPUT_TYPES.ERROR,
            payload: t('modalImportResponse.errors.template')
          });
          return;
        }
        case ERROR_TYPES.FIELDS_PARSE: {
          dispatch({
            type: INPUT_TYPES.ERROR,
            payload: t('modalImportResponse.errors.cellFormat')
          });
          return;
        }
        default: {
          dispatch({
            type: INPUT_TYPES.ERROR,
            payload: t('modalImportResponse.errors.unknown')
          });
        }
      }
    }
  };

  return (
    <Modal open onClose={hideModalDialog}>
      <Modal.Header>{t('modalImportResponse.title')}</Modal.Header>
      <Modal.Content>
        <Content onExportToExcel={onExportToExcel}>
          <FileSection
            onFileLoad={onFileLoad}
            mode={state.mode}
            fileName={state.file.name}
            error={state.error}
          />
        </Content>
      </Modal.Content>
      <Modal.Footer align="space-between">
        <Modal.Actions>
          {state.file && (
            <>
              <Button
                onClick={onExchangeFileClick}
                className={styles.choseFileGray}
                mode="text"
                before={<Icon iconName="restore" width={18} height={18} />}>
                {t('modalImportResponse.exchange')}
              </Button>
              <input
                type="file"
                onChange={onFileLoad}
                id="input-file"
                hidden
                ref={ref}
              />
            </>
          )}
        </Modal.Actions>
        <Modal.Actions>
          <Button onClick={onClose} mode="text">
            {t('modalImportResponse.cancel')}
          </Button>
          <Button
            onClick={onLoad}
            loading={state.mode === MODE.LOADING}
            disabled={!state.file || state.error}>
            {t('modalImportResponse.confirm')}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalImportResponse;
