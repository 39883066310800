import { useEffect, useState } from 'react';
import { isNil } from 'ramda';

export default function useDivider({
  isPossibleShowDivider,
  isActiveTab,
  isScrollBottom,
  isUploadedMessages,
  lastUnreadMessageId
}) {
  const [isShowDivider, setShowDivider] = useState(true);

  const showDivider = () => setShowDivider(true);
  const hideDivider = () => setShowDivider(false);

  useEffect(() => {
    if (!isPossibleShowDivider || !isUploadedMessages) return;

    if (isNil(lastUnreadMessageId)) {
      hideDivider();
      return;
    }

    if (isScrollBottom && isActiveTab) {
      hideDivider();
    } else {
      showDivider();
    }
  }, [isActiveTab, isScrollBottom, isPossibleShowDivider, isUploadedMessages]);

  return isShowDivider;
}
