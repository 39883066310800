import { differenceInDays, format } from 'date-fns';
import { isBookmarked } from 'core/data/light/message/msg-user';
import { isType } from 'core/lib';
import { getTime } from '../../../../../../utils/date';

export const getChatIdByMessage = (message) => message.channelId;
export const getMessageId = (message) => message.id;
export const isEdited = (message) => message.edited;
export const showTime = (isUnsent) => !isUnsent;
export const renderTime = (message) => {
  if (isType('MsgForwarded', message)) {
    if (
      differenceInDays(new Date(message.time), new Date(message.originTime)) !==
      0
    ) {
      return format(new Date(message.originTime), 'dd.MM.yyyy');
    }
    return getTime(message.originTime);
  }
  return getTime(message.time);
};

export const makeBtnData = ({
  type,
  value,
  tooltip = '',
  isSelect,
  isOwnMessage,
  scheduleMessages,
  onClick,
  setActive
}) => ({
  value,
  tooltip,
  isSelect: isSelect || false,
  isOwnMessage,
  onClick: (data) => onClick(type || value, data),
  scheduleMessages,
  setActive
});

const btn =
  ({ message, onClick, scheduleMessages, setActive, currentEmployeeId }) =>
  (item) => {
    const isSelect =
      item.value === 'favorite'
        ? isBookmarked(message, currentEmployeeId)
        : false;
    const isOwnMessage = message.employeeId === currentEmployeeId;

    return makeBtnData({
      value: item.value,
      tooltip: item.tooltip,
      scheduleMessages,
      setActive,
      onClick,
      isSelect,
      isOwnMessage
    });
  };

export const buttons = ({
  buttonList = [
    { value: 'reply', tooltip: 'reply-on-message' },
    { value: 'favorite', tooltip: 'add-to-favorite' }
  ],
  message,
  scheduleMessages,
  setActive,
  onClick,
  currentEmployeeId
}) =>
  buttonList.map(
    btn({ message, onClick, scheduleMessages, setActive, currentEmployeeId })
  );
