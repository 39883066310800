import React from 'react';
import ReactUse from 'react-use';
import { equals } from 'ramda';

export const useDeepCompare = (fn, deps, effect) => {
  const ref = React.useRef();

  if (!ref.current || !equals(deps, ref.current)) {
    ref.current = deps;
  }

  return effect(fn, ref.current);
};

export const useDeepCompareEffect = (fn, deps = []) =>
  ReactUse.useDeepCompareEffect(fn, deps);

export const useDeepMemo = (factory, deps = []) =>
  useDeepCompare(factory, deps, React.useMemo);
