import React, { useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { PdfJsApi } from '../vendors/PdfJsApi';

export const TextLayer = ({ page, scale, onRenderTextCompleted }) => {
  const containerRef = useRef();
  const renderTask = useRef();

  const empty = () => {
    const containerElem = containerRef.current;

    if (!containerElem) {
      return;
    }

    const spans = [].slice.call(
      containerElem.querySelectorAll('span.pdf-viewer__text-layer-text')
    );
    spans.forEach((span) => containerElem.removeChild(span));

    const breaks = [].slice.call(containerElem.querySelectorAll('br'));
    breaks.forEach((br) => containerElem.removeChild(br));
  };

  useIsomorphicLayoutEffect(() => {
    const task = renderTask.current;

    if (task) {
      task.cancel();
    }

    const containerElem = containerRef.current;
    if (!containerElem) {
      return undefined;
    }

    const viewport = page.getViewport({ scale });

    page.getTextContent().then((textContent) => {
      empty();
      renderTask.current = PdfJsApi.renderTextLayer({
        container: containerElem,
        textContent,
        viewport,
        enhanceTextSelection: true
      });

      renderTask.current.promise.then(
        () => {
          const spans = [].slice.call(containerElem.children);
          spans.forEach((span) =>
            span.classList.add('pdf-viewer__text-layer-text')
          );

          if (typeof onRenderTextCompleted === 'function') {
            onRenderTextCompleted();
          }
        },
        () => {
          if (typeof onRenderTextCompleted === 'function') {
            onRenderTextCompleted();
          }
        }
      );
    });

    return () => {
      if (renderTask.current) {
        renderTask.current.cancel();
      }
    };
  }, [containerRef]);

  return <div className="pdf-viewer__text-layer" ref={containerRef} />;
};
