import React from 'react';
import cn from 'classnames';
import { useTranslate } from '@hooks';
import DateBar from '../DateBar';
import styles from './StickyDateBar.module.css';

export function StickyDateBar({ className, date, datePanelFormat, language }) {
  const t = useTranslate();

  return (
    <DateBar
      className={cn(styles.bar, className)}
      date={date}
      datePanelFormat={datePanelFormat}
      t={t}
      language={language}
    />
  );
}
