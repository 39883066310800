const { equals, find, append, remove, findIndex, curry } = require('ramda');
const { defStruct } = require('../../lib');
const { getLabel, getKey } = require('../configItem');

/**
 * @typedef Configs - configuration list
 * @typedef {import('../configItem')} ConfigItem
 */
const {
  makeConfigs: makeConf,
  getList: getListOfItems,
  isConfigs
} = defStruct('Configs', ['list']);

/**
 * Create a configuration list data type
 *
 * @param {[ConfigItem]} list
 * @returns {Configs}
 */
const makeConfigs = (list = []) => makeConf(list);
exports.makeConfigs = makeConfigs;

/**
 * Gives a list of configuration items
 *
 * @param {Configs} configs
 * @returns {[ConfigItem]}
 */
const getList = (configs) => getListOfItems(configs);
exports.getList = getList;

/**
 * Checks if is Configs
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isConfigs = (a) => isConfigs(a);

/**
 *
 * @param {string} label
 * @param {string} key
 * @param {ConfigItem} configItem
 * @returns {boolean}
 */
const isEqualItem = curry(
  (label, key, configItem) =>
    equals(getLabel(configItem), label) && equals(getKey(configItem), key)
);
exports.isEqualItem = isEqualItem;

/**
 * Find a configuration item
 *
 * @param {string} label
 * @param {string} key
 * @param {Configs} configs
 * @returns {ConfigItem}
 */
exports.findItem = (label, key, configs) =>
  find(isEqualItem(label, key), getList(configs));

/**
 * Set item in configs list
 *
 * @param {ConfigItem} configItem
 * @param {Configs} configs
 * @returns {Configs}
 */
const setItem = (configItem, configs) => {
  let list = getList(configs);

  const label = getLabel(configItem);
  const key = getKey(configItem);
  const index = findIndex(isEqualItem(label, key), list);
  if (index > -1) {
    list = remove(index, 1, list);
  }

  return makeConfigs(append(configItem, list));
};
exports.setItem = setItem;

/**
 * Merge two configs list
 *
 * @param {Configs} configOne
 * @param {Configs} configTwo
 * @returns {Configs}
 */
exports.mergeConfigs = (configOne, configTwo) =>
  makeConfigs(
    getList(
      getList(configTwo).reduce(
        (acc, configItem) => setItem(configItem, acc),
        configOne
      )
    )
  );
