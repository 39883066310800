import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import i18n from 'i18next';
import { curry, slice } from 'ramda';
import cn from 'classnames';
import config from '../../config';
import styles from './LanguageSwitcher.module.css';
import getBrowserLanguage from '../../utils/languageDetector';

import * as actionUser from '../../action-creators/user';
import * as servicesAction from '../../action-creators/services';
import { useActions } from '../../hooks/useActions';
import { useIsClickOutside } from '../../hooks/useIsClickOutside';

const availableLangs = config.languagesOfApp;
const LANGUAGE_LEN = 3;
const getFirstAnyLettersOfWord = curry((numberOfLetters, word) =>
  slice(0, numberOfLetters, word)
);

const LanguageSwitcher = memo(() => {
  const { ref, isShow, setIsShow } = useIsClickOutside(false);
  const user = useSelector((state) => state.getIn(['user', 'user']));
  const language = useSelector((state) =>
    state.getIn(['user', 'user', 'language'])
  );
  const isUploaded = useSelector((state) =>
    state.getIn(['user', 'user', 'isUploaded'])
  );

  const { gaSend, getCurrentUser, changeUserLanguage } = useActions({
    ...actionUser,
    ...servicesAction
  });

  const languageCode = language || getBrowserLanguage();

  useEffect(() => {
    if (!user || (user && !isUploaded)) {
      getCurrentUser();
    }
  }, []);

  useEffect(() => {
    if (user && isUploaded && language) {
      i18n.changeLanguage(language);
    }
  }, [user]);

  const getCurrentLang = () =>
    availableLangs.find((lang) => lang.code === languageCode) ||
    config.defaultLang;

  const getDisplayLangs = () =>
    availableLangs.sort((lang) => (lang.code === languageCode ? -1 : 1));

  const selectLanguage = (code) => {
    if (code !== languageCode) {
      gaSend({
        category: 'User',
        action: 'choose_language',
        label: user.get('email')
      });
      i18n.changeLanguage(code);
      changeUserLanguage(code);
    }
    setIsShow(false);
  };

  return (
    <div className={styles.items} ref={ref}>
      <div className={styles.select}>
        <div
          className={styles.selected}
          onClick={() => setIsShow((prevState) => !prevState)}
          data-testid="language-switcher-close-wrapper">
          <div
            className={styles.selectedText}
            data-testid="language-switcher-current-lang">
            {getFirstAnyLettersOfWord(LANGUAGE_LEN)(getCurrentLang().view)}
          </div>
        </div>
        <div
          className={cn(styles.list, {
            [styles.listShow]: isShow
          })}>
          {getDisplayLangs().map((lang) => (
            <div
              key={lang.code}
              data-testid={`language-switcher-droplist-${lang.code}`}
              onClick={() => selectLanguage(lang.code)}
              className={styles.item}>
              {getFirstAnyLettersOfWord(LANGUAGE_LEN)(lang.view)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default LanguageSwitcher;
