import React from 'react';
import PropTypes from 'prop-types';

const GridPageNavbar = ({ children }, { b }) => (
  <section className={b('navbar')}>{children}</section>
);

GridPageNavbar.contextTypes = {
  b: PropTypes.func
};

export default GridPageNavbar;
