import React from 'react';
import { useDispatch } from 'react-redux';
import { assoc, isNil } from 'ramda';
import { format } from 'date-fns';
import block from 'bem-cn-lite';
import { formatRequestNumber } from '../../../../../../utils/utils';
import { renderMessage } from '../utils';
import './style.css';
import { openResponse } from '../../../../modules/purchaseRequestWithResponse/useCases';
import { openCompany } from '../../../../modules/purchaseRequestWithResponseCompany/useCases';

const b = block('pr-msg');

function isShowResponseEndDate(message) {
  return !isNil(message.responseEndDate);
}

function isShowSum(message) {
  return !isNil(message.sum);
}

function isMainCategory(category) {
  return isNil(category.parentId);
}

function isSubCategory(category) {
  return !isMainCategory(category);
}

function transCategory(t, categoryName = '') {
  return t(`purchaseRequestsCategories.${categoryName}`);
}

function getRowsOfCategories(t, message, categoriesList) {
  const categories = categoriesList.filter((category) =>
    message.categoriesNames.includes(category.name)
  );
  const mapCategories = categories
    .filter(isMainCategory)
    .reduce(
      (acc, category) =>
        assoc(category.id, [transCategory(t, category.name)], acc),
      {}
    );

  categories.filter(isSubCategory).forEach((category) => {
    if (!mapCategories[category.parentId])
      mapCategories[category.parentId] = [];
    mapCategories[category.parentId].push(transCategory(t, category.name));
  });

  return Object.values(mapCategories).map((row) =>
    row.join(', ').toLowerCase()
  );
}

function getLocation(message) {
  let result = `${message.country}, ${message.region}`;

  if (message.city) result += `, ${message.city}`;

  return result;
}

const PurchaseRequestMsg = ({ t, message, categoriesList = [] }) => {
  const dispatch = useDispatch();

  const onClickWrapper = () => dispatch(openResponse(message));
  const onClickName = () => dispatch(openCompany(message));

  return (
    <div className={b()}>
      <div>
        <div className={b('request-number')}>{`${t(
          'Request'
        )} №${formatRequestNumber(message.requestId)}`}</div>
        <div className={b('customer-name')} onClick={onClickName}>
          {message.customerName}
        </div>
      </div>
      <div className={b('request-wrapper')} onClick={onClickWrapper}>
        <div className={b('title-info')}>
          <div className={b('title')}>{message.title}</div>
          {isShowResponseEndDate(message) && (
            <div className={b('end-date')}>
              {`${t('Purchase response end date')}: ${format(
                new Date(message.responseEndDate),
                'dd.MM.yyyy'
              )}`}
            </div>
          )}
        </div>
        <div className={b('description')}>
          {renderMessage(message, 'description')}
        </div>
        {isShowSum(message) && (
          <div className={b('sum')}>{`${message.sum} р.`}</div>
        )}
        <div className={b('additional-info')}>
          <ul className={b('categories-info')}>
            {getRowsOfCategories(t, message, categoriesList).map((row, key) => (
              <li className={b('categories')} key={key}>
                {row}
              </li>
            ))}
          </ul>
          <div className={b('location')}>{getLocation(message)}</div>
        </div>
      </div>
    </div>
  );
};

// export default PurchaseRequestMsg;

export default PurchaseRequestMsg;
