import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  Modal
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import { useActions } from '../../../hooks/useActions';
import * as modalActions from '../../../action-creators/modal';
import { ModalContainer } from '../elements';
import { useTranslate } from '../../../TranslateProvider';
import * as emailMessagesHelpers from '../../../pages/messenger/emailMessages/helpers';
import * as emailMessagesCases from '../../ChatWidget/modules/emailMessages/useCases';
import * as emailMessagesGetters from '../../ChatWidget/modules/emailMessages/getters';
import Select from '../../Select/Universal';

const ModalSendEmail = () => {
  const t = useTranslate();

  const connectedEmails = useSelector(emailMessagesGetters.getConnectedEmails);

  const { hideModalDialog } = useActions(modalActions);
  const { fetchConnectedEmails } = emailMessagesCases.useFetchConnectedEmails();

  const [fromEmail, setFromEmail] = useState();
  const [toEmail, setToEmail] = useState();
  const [messageSubject, setMessageSubject] = useState('');
  const [messageText, setMessageText] = useState('');

  useEffect(() => {
    fetchConnectedEmails();
  }, []);

  useEffect(() => {
    if (!isEmpty(connectedEmails) && !fromEmail) {
      setFromEmail(connectedEmails[0].email);
    }
  }, [connectedEmails]);

  const possibleFromEmails = useMemo(
    () => connectedEmails.map((connectedEmail) => connectedEmail.email),
    [connectedEmails]
  );

  const onSubmit = useCallback(async () => {
    if (!fromEmail || !toEmail || !messageText) {
      return;
    }

    try {
      await emailMessagesCases.sendEmailMessage({
        fromEmail,
        toEmail,
        message: { subject: messageSubject, text: messageText }
      });

      hideModalDialog();
    } catch (error) {
      console.error(error);
    }
  }, [fromEmail, toEmail, messageText, messageText]);

  const onChange = useCallback(({ target: { name, value } }) => {
    const setters = {
      fromEmail: setFromEmail,
      toEmail: setToEmail,
      messageSubject: setMessageSubject,
      messageText: setMessageText
    };

    const setter = setters[name];
    setter(value);
  }, []);

  function getTranslate(tkey) {
    return emailMessagesHelpers.getTranslate(t, `modalSendMessage.${tkey}`);
  }

  return (
    <Modal onHide={hideModalDialog} show>
      <ModalContainer mods={{ type: 'default', theme: 'default' }}>
        <Modal.Header>
          <Modal.Title>
            {getTranslate('title')}
            <span
              className="modal-header-cross pull-right"
              onClick={hideModalDialog}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '100%' }}>
              <FormGroup controlId="email">
                <ControlLabel className="control-label-row">
                  {getTranslate('fromEmail')}
                </ControlLabel>
                <Select
                  value={fromEmail}
                  onChange={setFromEmail}
                  options={possibleFromEmails}
                  isUseTranslate={false}
                />
              </FormGroup>

              <FormGroup controlId="email" style={{ overflow: 'hidden' }}>
                <ControlLabel className="control-label-row">
                  {getTranslate('toEmail')}
                </ControlLabel>
                <FormControl
                  name="toEmail"
                  type="text"
                  placeholder="example@email.com"
                  value={toEmail}
                  onChange={onChange}
                />
              </FormGroup>

              <FormGroup controlId="email" style={{ overflow: 'hidden' }}>
                <ControlLabel className="control-label-row">
                  {getTranslate('message.subject')}
                </ControlLabel>
                <FormControl
                  name="messageSubject"
                  type="text"
                  placeholder="Тема сообщения"
                  value={messageSubject}
                  onChange={onChange}
                />
              </FormGroup>

              <FormGroup controlId="email" style={{ overflow: 'hidden' }}>
                <ControlLabel className="control-label-row">
                  {getTranslate('message.text')}
                </ControlLabel>
                <FormControl
                  name="messageText"
                  type="text"
                  placeholder="Введите текст сообщения"
                  value={messageText}
                  onChange={onChange}
                />
              </FormGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button id="cancel" className="pull-left" onClick={hideModalDialog}>
            {t('Cancel')}
          </Button>
          <Button id="saveButton" bsStyle="primary" onClick={onSubmit}>
            {t('Send')}
          </Button>
        </Modal.Footer>
      </ModalContainer>
    </Modal>
  );
};

export default ModalSendEmail;
