import { isBookmarked } from 'core/data/light/message/msg-user';
import { trans } from '../../../../../modules/translate';

export const makeViewModel = ({
  t,
  currentEmployeeId,
  messages,
  handler,
  config
}) => ({
  t,
  currentEmployeeId,
  messages,
  handler,
  config
});

export const dashboardTheme = (vm) => vm.config.commonDashboardTheme;
export const theme = (vm) => vm.config.theme;

const favoriteMessages = (vm) =>
  vm.messages.list.filter((msg) => isBookmarked(msg, vm.currentEmployeeId));
const amountLeft = (vm) => vm.messages.amountLeft;

export const total = (vm) => favoriteMessages(vm).length + amountLeft(vm);

export const title = (vm) =>
  trans('chat-widget.section.dashboard.total-favorite-msgs', vm);

export const initOnBack = (vm) => () => vm.handler('back-to-chat');
