import React from 'react';
import Footer from './view';
import {
  isEdited,
  showTime,
  renderTime,
  buttons,
  getMessageId,
  getChatIdByMessage
} from './data-type';

const CWMUMFooterWrapper = ({
  t,
  scheduleMessages,
  message,
  currentEmployeeId,
  isUnsent,
  onClick,
  setActive,
  buttonList
}) => (
  <Footer
    t={t}
    chatId={getChatIdByMessage(message)}
    messageId={getMessageId(message)}
    isEdited={isEdited(message)}
    showTime={showTime(isUnsent)}
    time={renderTime(message)}
    buttons={buttons({
      buttonList,
      message,
      scheduleMessages,
      onClick,
      setActive,
      currentEmployeeId
    })}
  />
);

export default CWMUMFooterWrapper;
