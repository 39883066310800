import { useState } from 'react';
import { useWindowChangeState } from '../../../hooks/useWindowChangeState';

export default function useIsFocusedTab() {
  const [isActiveTab, setActiveTab] = useState(true);

  useWindowChangeState({
    onFocus: () => {
      setActiveTab(true);
    },
    onBlur: () => {
      setActiveTab(false);
    }
  });

  return isActiveTab;
}
