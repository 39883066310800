/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { isNil, isEmpty } from 'ramda';
import {
  DEFAULT_SCALE,
  DEFAULT_MIN_SCALE,
  DEFAULT_MAX_SCALE,
  DEFAULT_STEP,
  DEFAULT_POSITION_X,
  DEFAULT_POSITION_Y,
  DEFAULT_BOUNDS,
  DEFAULT_PANNING
} from './constants';
import { calcBounds, getComponentsSize } from './utils';

class StateProvider {
  state = {
    mounted: false,
    wrapperNode: null,
    contentNode: null,
    step: DEFAULT_STEP,
    previousScale: DEFAULT_SCALE,
    scale: DEFAULT_SCALE,
    defaultScale: DEFAULT_SCALE,
    maxScale: DEFAULT_MAX_SCALE,
    minScale: DEFAULT_MIN_SCALE,
    positionX: DEFAULT_POSITION_X,
    positionY: DEFAULT_POSITION_Y,
    bounds: DEFAULT_BOUNDS,
    panning: DEFAULT_PANNING
  };

  get mounted() {
    return this.state.mounted;
  }

  set mounted(value) {
    this.state.mounted = value;
  }

  get wrapperNode() {
    return this.state.wrapperNode;
  }

  set wrapperNode(value) {
    this.state.wrapperNode = value;
  }

  get contentNode() {
    return this.state.contentNode;
  }

  set contentNode(value) {
    this.state.contentNode = value;
  }

  get step() {
    return this.state.step;
  }

  set step(value) {
    this.state.step = value;
  }

  get defaultScale() {
    return this.state.defaultScale;
  }

  set defaultScale(value) {
    this.state.defaultScale = value;
  }

  get scale() {
    return this.state.scale;
  }

  set scale(value) {
    this.state.previousScale = this.state.scale;
    this.state.scale = value;
  }

  get previousScale() {
    return this.state.previousScale;
  }

  get minScale() {
    return this.state.minScale;
  }

  set minScale(value) {
    this.state.minScale = value;
  }

  get maxScale() {
    return this.state.maxScale;
  }

  set maxScale(value) {
    this.state.maxScale = value;
  }

  get positionX() {
    return this.state.positionX;
  }

  set positionX(value) {
    this.state.positionX = value;
  }

  get positionY() {
    return this.state.positionY;
  }

  set positionY(value) {
    this.state.positionY = value;
  }

  set position({ positionX, positionY }) {
    this.state.positionX = positionX;
    this.state.positionY = positionY;
  }

  get bounds() {
    return this.state.bounds;
  }

  set bounds(value) {
    this.state.bounds = value;
  }

  get panning() {
    return this.state.panning;
  }

  set panning(value) {
    this.state.panning = value;
  }
}

const state = new StateProvider();

const useForceUpdate = () => {
  const [value, setValue] = useState(0);

  return () => setValue(value + 1);
};

const useProxyState = (options) => {
  const forceUpdate = useForceUpdate();

  const [_0, innerSetScale] = useState(null);
  const [_1, innerSetPosition] = useState(null);
  const [_3, innerSetStep] = useState(null);
  const [_4, innerSetWrapperNode] = useState(null);
  const [_5, innerSetContentNode] = useState(null);
  const [_6, innerSetBounds] = useState(null);

  useEffect(() => {
    init();
  }, []);

  const applyTransformation = (newScale, posX, posY) => {
    if (!state.mounted) return null;
    const { scale, positionX, positionY, contentNode } = state;
    if (!contentNode) return console.error("Content node isn't found");
    const transform = `translate3d(${posX || positionX}px, ${
      posY || positionY
    }px, 0px) scale(${newScale || scale})`;
    state.contentNode.style.transform = transform;
    state.contentNode.style.WebkitTransform = transform;

    forceUpdate();
    return undefined;
  };

  const setWrapperNode = (value) => {
    state.wrapperNode = value;
    innerSetWrapperNode(value);
  };

  const setContentNode = (value) => {
    state.contentNode = value;
    innerSetContentNode(value);
  };

  const setBounds = (value) => {
    state.bounds = value;
    innerSetBounds(value);
  };

  const calculateBounds = (newScale) => {
    const { wrapperNode, contentNode } = state;

    const { diffHeight, diffWidth } = getComponentsSize({
      newScale: newScale || state.scale,
      wrapperNode,
      contentNode
    });
    const newBounds = calcBounds({ diffHeight, diffWidth });
    state.bounds = newBounds;

    return newBounds;
  };

  const controlBounds = (newScale) => {
    calculateBounds(newScale);
    const { bounds } = state;

    if (bounds.minPositionX > state.positionX) {
      state.positionX = bounds.minPositionX;
    }
    if (bounds.minPositionY > state.positionY) {
      state.positionY = bounds.minPositionY;
    }
    if (bounds.maxPositionX < state.positionX) {
      state.positionX = bounds.maxPositionX;
    }
    if (bounds.maxPositionY < state.positionY) {
      state.positionY = bounds.maxPositionY;
    }
  };

  const setPosition = (value) => {
    state.position = value;
    controlBounds();
    innerSetPosition(value);
    applyTransformation();
  };

  const setScale = (value) => {
    controlBounds(value);
    state.scale = value;
    innerSetScale(value);
    applyTransformation();
  };

  const setStep = (value) => {
    state.step = value;
    innerSetStep(value);
  };

  return {
    state,
    methods: {
      setWrapperNode,
      setContentNode,
      setScale,
      setStep,
      setPosition,
      setBounds
    }
  };

  function init() {
    const { defaultScale, minScale, maxScale, positionX, positionY, panning } =
      options;

    state.mounted = true;
    state.defaultScale = defaultScale || DEFAULT_SCALE;
    state.scale = defaultScale || DEFAULT_SCALE;
    state.minScale = minScale || DEFAULT_MIN_SCALE;
    state.maxScale = maxScale || DEFAULT_MAX_SCALE;
    state.positionX = positionX || DEFAULT_POSITION_X;
    state.positionY = positionY || DEFAULT_POSITION_Y;
    state.bounds = DEFAULT_BOUNDS;
    state.panning =
      !isNil(panning) && !isEmpty(panning) ? panning : DEFAULT_PANNING;
  }
};

export default useProxyState;
