export const getChildren = (parentId, list) =>
  list.filter((item) => item.parentId === parentId);

export const filterOptionsByValue = ({ list, value }) =>
  list.filter(({ label }) => label.toLowerCase().includes(value.toLowerCase()));

export const toggleItem = ({ selectedList, itemValue }) => {
  if (selectedList.includes(itemValue)) {
    return selectedList.filter((item) => item !== itemValue);
  }
  return [...selectedList, itemValue];
};
