import request from '../lib/request';

export const changeMode = (mode) => (dispatch) => {
  dispatch({
    type: 'DIALOG_LISTS:SET_MODE',
    payload: { mode }
  });
};

export const clearSystemChecked = () => (dispatch) => {
  dispatch({
    type: 'DIALOG_LISTS:CLEAR_SYSTEM_CHECKED'
  });
};

export const clearUserChecked = () => (dispatch) => {
  dispatch({
    type: 'DIALOG_LISTS:CLEAR_USER_CHECKED'
  });
};

export const loadDialogLists =
  (isInitial = false) =>
  (dispatch) => {
    request
      .get(`/api/dialogs/lists?initial=${isInitial}`)
      .then((payload) => {
        dispatch({ type: 'DIALOG_LISTS:SET_LISTS', payload });

        if (isInitial) {
          dispatch({ type: 'DIALOG_LISTS:SET_INITIAL_CHECKED_LISTS', payload });
        }
      })
      .catch((err) => {
        dispatch({ type: 'DIALOG_LISTS:CLEAR_LISTS' });
        console.error(err);
      });
  };

export const createDialogList = (name) => (dispatch) =>
  request
    .post('/api/dialogs/lists', { name })
    .then((payload) => {
      dispatch({ type: 'DIALOG_LISTS:ADD_USER_LIST', payload });
    })
    .catch((err) => {
      console.error(err);
    });

export const deleteDialogList = (id) => (dispatch) =>
  request
    .delete(`/api/dialogs/lists/${id}`)
    .then(() => {
      dispatch({ type: 'DIALOG_LISTS:REMOVE_USER_LIST', payload: { id } });
    })
    .catch((err) => {
      console.error(err);
    });

export const editDialogList = (id, name) => (dispatch) =>
  request.patch(`/api/dialogs/lists/${id}`, { name }).then((payload) => {
    dispatch({ type: 'DIALOG_LISTS:EDIT_USER_LIST', payload: { id, name } });
    return payload;
  });

export const toggleSystemChecked = (id) => (dispatch) => {
  dispatch({
    type: 'DIALOG_LISTS:TOGGLE_SYSTEM_CHECKED',
    payload: { id }
  });
};

export const toggleUserChecked = (id) => (dispatch) => {
  dispatch({
    type: 'DIALOG_LISTS:TOGGLE_USER_CHECKED',
    payload: { id }
  });
};

export const addMemberToDialogList =
  ({ dialogListId, dialogType, dialogId }) =>
  (dispatch) =>
    request
      .post('/api/dialogs/lists/member', { dialogListId, dialogType, dialogId })
      .then(() => {
        dispatch({
          type: 'CHAT:ADD_MEMBER_TO_DIALOG_LISTS',
          payload: { dialogListId, dialogType, dialogId }
        });
      })
      .catch((err) => {
        console.error(err);
      });

export const deleteMemberFromDialogList =
  ({ dialogListId, dialogType, dialogId }) =>
  (dispatch) =>
    request
      .delete(`/api/dialogs/lists/${dialogListId}/${dialogId}`)
      .then(() => {
        dispatch({
          type: 'CHAT:REMOVE_MEMBER_FROM_DIALOG_LISTS',
          payload: { dialogListId, dialogType, dialogId }
        });
      })
      .catch((err) => {
        console.error(err);
      });
