import { isNil } from 'ramda';

export const makeMembersVM = ({
  mode = 'Channel',
  readOnly = false,
  hardcodeType,
  currentEmployeeId,
  setHasChanges,
  members
}) => ({
  mode,
  readOnly,
  currentEmployeeId,
  hardcodeType,
  setHasChanges,
  members
});

export const getMode = (vm) => vm.mode;
export const isReadOnly = (vm) => vm.readOnly;
export const getMembers = (vm) => vm.members;

export const getCurrentMember = (vm) => {
  const currentMember = vm.members.find(
    (member) => member.employeeId === vm.currentEmployeeId
  );

  if (isNil(currentMember)) throw new Error('Current member is not found');

  return currentMember;
};
