import user from '../storage/user.storage';
import {
  setUserAccessRoles,
  clearUserAccessRoles
} from '../action-creators/userAccessRoles';

export const loadAccessRoles = () => async (dispatch) => {
  const userAccessRoles = await user.myAccessRoles();
  dispatch(setUserAccessRoles(userAccessRoles));
};

export const clearAccessRoles = () => clearUserAccessRoles();
