import React, { useRef } from 'react';

const style = { display: 'none' };

function FileSelectWrapper({
  className,
  accept,
  component,
  onChange: changeCb,
  multiple = true,
  disabled = false
}) {
  const ref = useRef(null);

  const onClick = (event) => {
    event.preventDefault();

    ref.current.click();
  };

  const onChange = (event) => {
    if (typeof changeCb === 'function') {
      changeCb(event.target.files);
    }
    ref.current.value = null;
  };

  return (
    <div className={className}>
      {component({ onClick })}
      <input
        ref={ref}
        type="file"
        style={style}
        accept={accept}
        multiple={multiple}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
}

export default FileSelectWrapper;
