import { useEffect } from 'react';

export const useCallOnceWhenMetaKeyIsPressed = (ref, key = 'keyV') => {
  const handleKeyDown = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.code === key && e.repeat) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (ref) {
      ref.current.addEventListener('keydown', handleKeyDown, true);
    }
  }, [ref]);
};
