import React from 'react';

/* eslint-disable max-len */
function IconHideOff() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17C9 17 11 23 17 23C23 23 25 17 25 17C25 17 23 11 17 11C11 11 9 17 9 17ZM10.0734 17C10.1293 16.8685 10.2046 16.7019 10.3007 16.5097C10.5568 15.9975 10.9544 15.3168 11.5182 14.6402C12.6398 13.2943 14.3811 12 17 12C19.6189 12 21.3602 13.2943 22.4818 14.6402C23.0456 15.3168 23.4432 15.9975 23.6993 16.5097C23.7954 16.7019 23.8707 16.8685 23.9266 17C23.8707 17.1315 23.7954 17.2981 23.6993 17.4903C23.4432 18.0025 23.0456 18.6832 22.4818 19.3598C21.3602 20.7057 19.6189 22 17 22C14.3811 22 12.6398 20.7057 11.5182 19.3598C10.9544 18.6832 10.5568 18.0025 10.3007 17.4903C10.2046 17.2981 10.1293 17.1315 10.0734 17Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 19C18.1046 19 19 18.1046 19 17C19 15.8954 18.1046 15 17 15C15.8954 15 15 15.8954 15 17C15 18.1046 15.8954 19 17 19ZM17 20C18.6569 20 20 18.6569 20 17C20 15.3431 18.6569 14 17 14C15.3431 14 14 15.3431 14 17C14 18.6569 15.3431 20 17 20Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconHideOff;
