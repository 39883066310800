const changeCountInBlockList = (blockList, itemId, value) => ({
  ...blockList,
  list: blockList.list.map((item) =>
    item.id === itemId ? { ...item, count: item.count + value } : item
  )
});

export const updateCountInGroupContactsCase = (
  { getGroupContacts, setGroupContacts },
  { checked, groupId }
) => {
  const blockGroup = getGroupContacts();
  const changedCount = checked ? 1 : -1;
  console.log('before updatedBlockGroup', blockGroup);
  const updatedBlockGroup = changeCountInBlockList(
    blockGroup,
    groupId,
    changedCount
  );
  console.log('after updatedBlockGroup', updatedBlockGroup);
  setGroupContacts(updatedBlockGroup);
};
