import { showModal, hideModal } from './modal';
import { setSuccessMessage } from './message';

export const confirmSendOrderToSupplier =
  ({ deliveryDate, splitOrder, consignee, sum }) =>
  (dispatch) =>
    dispatch(
      showModal('SEND_ORDER_TO_SUPPLIER', {
        captionKey: 'Send order to supplier',
        onSubmited: () =>
          setSuccessMessage({ key: 'The request has been successfully sent' }),
        sum,
        deliveryDate,
        splitOrder,
        consignee
      })
    );

export const editChannel = () => showModal('EDIT_CHANNEL');
export const editMembers = (readOnly = false) =>
  showModal('EDIT_MEMBERS', { readOnly });

export const addParticipantsToTopic = ({
  submit,
  dialogs,
  members,
  btnSubmitText,
  purpose = 'Create',
  cancel = () => {}
}) =>
  showModal('ADD_TO_GROUP_CHAT', {
    mode: 'Topic',
    purpose,
    resetMembersIds: true,
    resetMembersRoles: true,
    submit,
    btnSubmitText,
    cancel: () => {
      hideModal();
      cancel();
    },
    dialogs,
    members
  });

export const addParticipantsToChannel = ({
  submit,
  dialogs,
  members,
  btnSubmitText,
  purpose = 'Create'
}) =>
  showModal('ADD_TO_GROUP_CHAT', {
    mode: 'Channel',
    purpose,
    resetMembersIds: true,
    resetMembersRoles: true,
    submit,
    btnSubmitText,
    cancel: hideModal,
    dialogs,
    members
  });

export const viewChannel = () => showModal('VIEW_CHANNEL_INFORMATION');
