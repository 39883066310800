import React from 'react';

const Success = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18">
    <use fill="#FFF" xlinkHref="#a" />
    <use fill="#00CEE0" transform="translate(6 6)" xlinkHref="#b" />
    <defs>
      <path id="a" d="M18 9A9 9 0 1 1 0 9a9 9 0 0 1 18 0z" />
      <path
        id="b"
        d="M7.36.348a.5.5 0 0 0-.72-.696l.72.696zM2.157 5l-.332.374.358.318.333-.344L2.157 5zM.332 2.707a.5.5 0 0 0-.664.747l.664-.747zM6.641-.348l-4.842 5 .718.696 4.842-5-.718-.696zM2.49 4.626L.331 2.706l-.664.748 2.158 1.92.664-.748z" // eslint-disable-line
      />
    </defs>
  </svg>
);

export default Success;
