import request from '../../../../lib/request';
import { getScktChl } from '../../useCases/service';
import { escapeText } from '../../../../utils/utils';

const scktChl = getScktChl();

export const updateChannel = (channelId, config) =>
  scktChl.emit('update', { channelId, config });

export const createTopic = async ({
  name,
  title,
  desc,
  files,
  employeesId,
  membersRoles
}) =>
  request.post(`/api/topic`, {
    name,
    title,
    desc,
    files,
    employeesId,
    membersRoles
  });

export const clearTopicUnsaveInfoFile = async (topicId) =>
  request.delete(`/api/topic/${topicId}/unsave-info-files/all`);

export const getTopicUnsaveInfoFiles = async (topicId) =>
  request.get(`/api/topic/${topicId}/unsave-info-files`);

export const attachTopicUnsaveInfoFile = async (topicId, fileName, file) =>
  request.sendFile(
    `/api/topic/${topicId}/unsave-info-files/${escapeText(fileName)}`,
    file
  );

export const attachTopicUnsaveInfoFileFromStorage = async (topicId, filesId) =>
  request.post(`/api/topic/${topicId}/unsave-info-files`, { filesId });

export const removeUnsaveInfoFile = async (topicId, fileId) => {
  const id = topicId === '' ? '_' : topicId;
  return request.delete(`/api/topic/${id}/unsave-info-files/${fileId}`);
};

export const updateChannelListByCreateTopic = (topic) =>
  scktChl.emit('updateChannelList:topic', { topic });
export const subToupdateChannelListByCreateTopic = (cb) =>
  scktChl.on('updateChannelList:topic', cb);
