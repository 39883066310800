import React from 'react';
import PropTypes from 'prop-types';

const Sidebar = ({ children }, { b }) => (
  <div className={b('sidebar')}>{children}</div>
);

Sidebar.contextTypes = {
  b: PropTypes.func
};

export default Sidebar;
