import React, { Component } from 'react';

class Arrow extends Component {
  // eslint-disable-next-line react/sort-comp
  static top = '-45 5.328 .793';

  static down = '135 2.879 2.95';

  getDir() {
    const { direction } = this.props;
    const dir = Arrow[direction];
    if (!dir) {
      return Arrow.top;
    }

    return dir;
  }

  render() {
    const { color = '#c6cae3', width = '6', height = '6' } = this.props;

    return (
      <svg
        viewBox="0 0 8 7"
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg">
        <path
          transform={`rotate(${this.getDir()})`}
          fill={color}
          d="M4.243 0h.5v-.5h-.5V0zM0 .5h4.243v-1H0v1zM3.743 0v4.243h1V0h-1z"
        />
      </svg>
    );
  }
}

export default Arrow;
