export const attachFilesFromStorageCase = async (
  {
    getChatInput,
    errorHandler,
    attachUnsentFileFromStorage,
    getUnsentFiles,
    setInputFiles,
    setFocusOnInputMessage,
    awaiter = setTimeout
  },
  { chatId, fileIds }
) => {
  const input = getChatInput();

  const { files: attachedFiles, maxFiles } = input;
  const totalFiles = fileIds.length + attachedFiles.length;

  if (totalFiles > maxFiles) {
    errorHandler('error_file_upload_limit', { amount: maxFiles });
    return;
  }

  await attachUnsentFileFromStorage(chatId, fileIds);

  const uploadedFiles = await getUnsentFiles(chatId);
  setInputFiles(uploadedFiles);
  awaiter(setFocusOnInputMessage, 0);
};
