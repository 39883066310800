import { useState } from 'react';

export default function useCounter(init = 0) {
  const [count, setCount] = useState(init);

  return {
    count: () => count,
    increase: () => setCount(count + 1)
  };
}
