import { makeCommand } from 'core/data/messageBus/message';
import { emitToServer } from '../../../../storage/message';

const NS = 'MESSAGES';

const pinMessageCMD = makeCommand(NS, 'PIN_MESSAGE'); // { channelId, messageId }
const unpinMessageCMD = makeCommand(NS, 'UNPIN_MESSAGE'); // { channelId, messageId }

export const sendPinMessageCMD = (channelId, messageId) =>
  emitToServer(pinMessageCMD, { channelId, messageId });
export const sendUnpinMessageCMD = (channelId, messageId) =>
  emitToServer(unpinMessageCMD, { channelId, messageId });
