const { groupBy } = require('ramda');
const { format, compareAsc } = require('../../../lib/date');
const { typeOfClass } = require('../../../lib');
const { isMsgSystem } = require('./msg-system');

/**
 * @typedef {import('./msg-system')} MsgSystem
 * @typedef {import('./msg-user')} MsgUser
 * @typedef {(MsgUser|MsgSystem)} Message
 */

/**
 * Checks if is message
 *
 * @param {*} any
 * @returns {boolean}
 */
exports.isMessage = typeOfClass(['MsgUser', isMsgSystem]);

/**
 * Groups and sort messages by date
 *
 * @param {[Message]} messages
 * @returns {[string, [Message]]}
 */
exports.groupSortByDate = (messages) => {
  // NOTE: format MM.dd.yyyy - not work in firefox in new Date()
  // NOTE: format dd.MM.yyyy not right with new Date() - right is MM/dd/yyyy
  const messageByDate = groupBy(
    (message) => format('MM/dd/yyyy', message.time),
    messages
  );
  const sortedDate = Object.keys(messageByDate).sort(compareAsc);

  return sortedDate.map((date) => [
    format('dd.MM.yyyy', date),
    messageByDate[date].sort((msgA, msgB) => compareAsc(msgA.time, msgB.time)),
    {
      date
    }
  ]);
};

/**
 * Check belongs message to user
 *
 * @param {number} currentEmployeeId
 * @param {number} senderEmployeeId
 * @returns {boolean}
 */
exports.isOwnMessage = (currentEmployeeId, senderEmployeeId) =>
  currentEmployeeId === senderEmployeeId;
