const { typeOfClass } = require('core/lib');

/**
 * Checks if is msg system
 *
 * @param {*} any
 * @returns {boolean}
 */
exports.isMsgSystem = typeOfClass([
  'MsgCreatedChannel',
  'MsgRenamedChannel',
  'MsgAddedToChannel',
  'MsgExcludedFromChannel',
  'MsgLeftFromChannel',
  'MsgRegainedStatus',
  'MsgSubscribeToChannel',
  'MsgUpdateSubscribeToChannel',
  'MsgUnsubscribeFromChannel',
  'MsgAcceptResponse',
  'MsgRejectResponse',
  'MsgSendToReworkResponse'
]);
