import React from 'react';
import cn from 'classnames';
import styles from './Checkbox.module.css';

const Checkbox = ({ className, isChecked, ...props }) => (
  <input
    type="checkbox"
    checked={isChecked}
    className={cn(styles.checkbox, className)}
    {...props}
  />
);

export default Checkbox;
