import { RichUtils, Modifier, EditorState } from 'draft-js';

export const getSearchText = (editorState) => {
  const getWordAt = (string, position) => {
    // Perform type conversions.
    const str = String(string);
    // eslint-disable-next-line no-bitwise
    const pos = Number(position) >>> 0;

    // Search for the word's beginning and end.
    const left = str.slice(0, pos + 1).search(/\S+$/);
    const right = str.slice(pos).search(/\s/);

    // The last word in the string is a special case.
    if (right < 0) {
      return {
        word: str.slice(left),
        begin: left,
        end: str.length
      };
    }

    // Return the word, using the located bounds to extract it from the string.
    return {
      word: str.slice(left, right + pos),
      begin: left,
      end: right + pos
    };
  };

  const selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  const anchorOffset = selection.getAnchorOffset() - 1;
  const currentContent = editorState.getCurrentContent();
  const currentBlock = currentContent.getBlockForKey(anchorKey);
  const blockText = currentBlock.getText();

  return getWordAt(blockText, anchorOffset);
};

const getRelativeParent = (element) => {
  if (!element) {
    return null;
  }

  const position = window
    .getComputedStyle(element)
    .getPropertyValue('position');
  if (position !== 'static') {
    return element;
  }

  return getRelativeParent(element.parentElement);
};

export function positionSuggestions({
  decoratorRect,
  popover,
  props: { open, suggestions }
}) {
  const relativeParent = getRelativeParent(popover.parentElement);

  let relativeRect = {
    scrollLeft: 0,
    scrollTop: 0,
    left: 0,
    top: 0
  };

  if (relativeParent) {
    const relativeParentRect = relativeParent.getBoundingClientRect();
    relativeRect = {
      scrollLeft: relativeParent.scrollLeft,
      scrollTop: relativeParent.scrollTop,
      left: decoratorRect.left - relativeParentRect.left,
      top: decoratorRect.top - relativeParentRect.top
    };
  } else {
    relativeRect = {
      scrollLeft: window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop: window.pageYOffset || document.documentElement.scrollTop,
      left: decoratorRect.left,
      top: decoratorRect.top
    };
  }

  const left = relativeRect.left + relativeRect.scrollLeft;
  const top = relativeRect.top + relativeRect.scrollTop;

  let transform;
  if (open) {
    if (suggestions.length > 0) {
      transform = 'translate(0px, -100%)';
    } else {
      transform = 'scale(0)';
    }
  }

  return {
    left: `${left}px`,
    top: `${top}px`,
    transform,
    transformOrigin: '1em 0%'
  };
}

export const findWithRegex = (regex, contentBlock, callback) => {
  const text = contentBlock.getText();
  let matchArr;
  let start;
  // eslint-disable-next-line
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

export const getSelectedBlock = (editorState) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const blockStartKey = selection.getStartKey();

  return contentState.getBlockMap().get(blockStartKey);
};

export const replaceContentByBlockType = (editorState, block, blockType) => {
  const newEditorState = RichUtils.toggleBlockType(editorState, blockType);
  const contentState = newEditorState.getCurrentContent();
  const selection = newEditorState.getSelection();
  const blockSelection = selection.merge({
    anchorOffset: 0,
    focusOffset: block.getLength()
  });

  const newContentState = Modifier.replaceText(
    contentState,
    blockSelection,
    ''
  );

  return EditorState.push(newEditorState, newContentState);
};

export const insertSpace = (editorState) => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const newContent = Modifier.insertText(contentState, selection, ' ');

  return EditorState.push(editorState, newContent);
};

export const insertMentionSymbol = (editorState) => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const newContent = Modifier.insertText(contentState, selection, '@');

  return EditorState.push(editorState, newContent);
};
