import React, { useEffect, useRef, useState } from 'react';

const PRINT_UNIT = 150 / 72;
export const PageThumbnail = ({
  canvas,
  page,
  pageHeight,
  pageWidth,
  onLoad
}) => {
  const renderTask = useRef();
  const [src, setSrc] = useState('');

  useEffect(() => {
    const task = renderTask.current;
    if (task) {
      task.cancel();
    }

    canvas.height = Math.floor(pageHeight * PRINT_UNIT);
    canvas.width = Math.floor(pageWidth * PRINT_UNIT);

    const canvasContext = canvas.getContext('2d');
    canvasContext.save();
    canvasContext.fillStyle = 'rgb(255, 255, 255)';
    canvasContext.fillRect(0, 0, canvas.width, canvas.height);
    canvasContext.restore();

    const viewport = page.getViewport({ scale: 1 });
    renderTask.current = page.render({
      canvasContext,
      intent: 'print',
      transform: [PRINT_UNIT, 0, 0, PRINT_UNIT, 0, 0],
      viewport
    });
    renderTask.current.promise.then(
      () => {
        'toBlob' in canvas
          ? canvas.toBlob((blob) => {
              setSrc(URL.createObjectURL(blob));
            })
          : setSrc(canvas.toDataURL());
      },
      () => {}
    );
  }, []);

  return (
    src && (
      <div className="pdf-viewer-print__page">
        <img src={src} onLoad={onLoad} alt="" />
      </div>
    )
  );
};
