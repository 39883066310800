export const closeTopicDashboardService = async (
  {
    reqClearTopicUnsaveInfoFile,
    prevChatMode,
    clearInputFiles,
    clearTempFiles
  },
  { id = '_' }
) => {
  await reqClearTopicUnsaveInfoFile(id);
  prevChatMode();
  clearInputFiles();
  clearTempFiles();
};
