import { useEffect } from 'react';
import { useMount, useUnmount } from 'react-use';

export const makeEffect =
  (deps = []) =>
  (cb) =>
    useEffect(() => {
      cb();
    }, deps);

export const makeMountEffect = () => (cb) =>
  useMount(() => {
    cb();
  });

export const makeUnmountEffect = () => (cb) =>
  useUnmount(() => {
    cb();
  });
