import React from 'react';
import ButtonIcon from '../../ButtonIcon';
import './style.css';

// Row
const Row = ({ chat, list, handler }) => {
  /* const count = (dialogsIds && dialogsIds.length) || 0; */
  /* const isAddedToList = dialogsIds.find(listDialogId => listDialogId === dialogId); */
  const isAddedToList = chat.checkedLists.user.includes(list.id);

  const icon = isAddedToList ? 'remove-m' : 'add-m';
  const tooltip = isAddedToList ? 'remove' : 'add';

  return (
    <div
      className={`cw-modal-eudl-component__dialog-lists_row ${
        isAddedToList ? 'added' : ''
      }`}>
      <div className="cw-modal-eudl-component__dialog-lists_row-title">
        {list.name}
      </div>
      <div className="cw-modal-eudl-component__dialog-lists_row-counter">
        {/* <img src="/img/dialog-count-gray.svg" alt="person" />
            {count} */}
      </div>
      <div className="cw-modal-eudl-component__dialog-lists_row-button">
        <ButtonIcon
          icon={icon}
          tooltip={tooltip}
          onClick={() => handler('toggle-add', { group: list, chat })}
        />
      </div>
    </div>
  );
};

// --------------------------------------------------------------------------------------------------------------------

// List
const DialogLists = ({ chat, handler, lists = [] }) => (
  <div className="cw-modal-eudl-component__dialog-lists">
    {lists &&
      lists.map((list) => (
        <Row key={list.id} chat={chat} handler={handler} list={list} />
      ))}
  </div>
);

export default DialogLists;
