import {
  SHOW,
  HIDE,
  SET_FILTER_DATA,
  CLEAN_FILTER_DATA,
  IS_SUBSCRIBED
} from './constants';

export const showPurchaseRequest = (payload) => ({
  type: SHOW,
  payload
});

export const hidePurchaseRequest = () => ({
  type: HIDE
});

export const setPurchaseRequestFilterData = (payload) => ({
  type: SET_FILTER_DATA,
  payload
});

export const cleanPurchaseRequestFilterData = () => ({
  type: CLEAN_FILTER_DATA
});

export const setIsSubscribed = (payload) => ({
  type: IS_SUBSCRIBED,
  payload
});
