import { Iterable } from 'immutable';
import { getCurrentUser } from '../../../../../storeGetters';
import { getOnline } from '../../../../../modules/online/getter';

const getChats = (state) => {
  const chats = state.get('chat-widget').blockChat.list;

  if (Array.isArray(chats)) return chats;
  if (Iterable.isIterable(chats)) return chats.toJS();

  return [];
};

export const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  chats: getChats(state),
  online: getOnline(state)
});
