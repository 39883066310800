import React from 'react';

import './style.css';

const Checkbox = React.forwardRef(
  ({ value, onClick, disabled, dataTestid }, ref) => (
    <div
      className={`custom-checkbox-container ${disabled ? 'disabled' : ''}`}
      onClick={!disabled ? onClick : undefined}
      ref={ref}>
      <input
        type="checkbox"
        checked={value}
        readOnly
        data-testid={dataTestid}
      />
      <span className="checkmark" />
    </div>
  )
);

export default Checkbox;
