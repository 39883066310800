import { FULL, FULL_WIDTH, SHORT, SHORT_WIDTH } from './constants';

export const makeNavigationSidebar = ({
  mode = FULL,
  width = FULL_WIDTH
} = {}) => ({
  mode,
  width
});

export const setFullMode = () =>
  makeNavigationSidebar({ mode: FULL, width: FULL_WIDTH });
export const setShortMode = () =>
  makeNavigationSidebar({ mode: SHORT, width: SHORT_WIDTH });

export const toggleNavigationSidebarMode = (currentMode = FULL) => {
  if (currentMode === FULL) {
    return setShortMode();
  }

  return setFullMode();
};
