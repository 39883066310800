import React from 'react';
import { useSelector } from 'react-redux';

import './style.css';

import Avatar from '../../Avatar';
import Message from './message';
import {
  generateMessageVM,
  getAvatarInfo,
  getMsgPreviewLinkText,
  getPanelTitle,
  initOnCancel,
  initOnShowPreview,
  isShowAttachIcon,
  isShowLinkToMsgPreview,
  isShowMessage,
  isShowMsgAvatar,
  isShowNothing,
  makeForwardPanelVM
} from './dataType';
import { getOpenedChat } from '../../getter';
import { getOpenedChatForwardList } from '../../modules/forwardMessages/getters';
import * as forwardMessagesUC from '../../modules/forwardMessages/useCases';
import * as profileUC from '../../modules/profile';
import { getCurrentEmployeeId } from '../../../../storeGetters';
import { useTranslate } from '../../../../TranslateProvider';
import { useActions } from '../../../../hooks/useActions';

const CWChatForwardPanel = () => {
  const t = useTranslate();
  const currentEmployeeId = useSelector(getCurrentEmployeeId);
  const chat = useSelector(getOpenedChat);
  const list = useSelector(getOpenedChatForwardList);

  const { showPreviewModal, cancelForward, showProfile } = useActions({
    ...forwardMessagesUC,
    ...profileUC
  });

  const vm = makeForwardPanelVM({
    t,
    chat,
    currentEmployeeId,
    list,
    showPreviewModal,
    cancelForward,
    showProfile
  });

  if (isShowNothing(vm)) return null;

  return (
    <div className="forward-msgs">
      <div className="forward-msgs_img">
        <img src="/img/forward.svg" height={22} width={22} alt="icon-attach" />
      </div>
      <div className="forward-msgs_wrap">
        <div className="forward-msgs_info text-wrap">
          {isShowMsgAvatar(vm) && (
            <div className="forward-msgs_avatar">
              <Avatar
                size={34}
                info={{ ...getAvatarInfo(vm), hideOnlineStatus: true }}
              />
            </div>
          )}
          <div className="forward-msgs-text-wrap">
            <div className="forward-msgs_username">{getPanelTitle(vm)}</div>
            <div className="forward-msgs-wrap-text-content">
              {isShowAttachIcon(vm) && (
                <img
                  src="/img/chat-widget/attach.svg"
                  className="forward-msgs_attach"
                  role="presentation"
                  alt=""
                  onClick={initOnShowPreview(vm)}
                />
              )}
              {isShowLinkToMsgPreview(vm) && (
                <a onClick={initOnShowPreview(vm)}>
                  {getMsgPreviewLinkText(vm)}
                </a>
              )}
              {isShowMessage(vm) && <Message vm={generateMessageVM(vm)} />}
            </div>
          </div>
          <div className="forward-msgs_close" onClick={initOnCancel(vm)}>
            <img src="/img/cross.svg" width="10" role="presentation" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CWChatForwardPanel;
