import React from 'react';
import Typography from 'react-components/src/components/Typography';
import { useNavigate } from '@hooks';
import { Button } from '@link/react-components';
import { useTranslate } from '../../../TranslateProvider';
import './style.css';

const authorizationLink = `${process.env.UNICAT_ACCOUNTS_SERVICE}/chat/login`;

const SuccessRegPage = ({ email }) => {
  const t = useTranslate();
  const navigate = useNavigate();

  return (
    <div className="success-wrapper">
      <div className="success-container">
        <div className="success-left-side">
          <div className="success-logo-container">
            <a className="success-logo" href="https://istock.link/">
              {' '}
            </a>
          </div>
          <div className="success-messenger-info">
            <div className="success-messenger-info-item">
              <div className="success-messenger-info-circle">
                <div className="success-messenger-info-circle-inner" />
              </div>
              {t('marketplace')}
            </div>
            <div className="success-messenger-info-item">
              <div className="success-messenger-info-square" />
              {t('corporate_messenger')}
            </div>
          </div>
          <img
            className="success-image"
            src="/img/registration_image.svg"
            alt=""
          />
        </div>
        <div className="success-right-side">
          <img src="/img/emailSent.svg" alt="" />
          <Typography variant="h2">{t('checkMailBox')}</Typography>
          <div className="success-instruction">
            <div className="success-row">
              <Typography className="info-sent" variant="body1Reg">
                {t('dataSent')}
              </Typography>
              <Typography className="info-email" variant="body1Med">
                {email}
              </Typography>
            </div>
            <Typography variant="body1Reg">{t('followLink')}</Typography>
          </div>
          <Button
            mode="text"
            size="large"
            onClick={() => {
              navigate(authorizationLink);
            }}>
            {t('goToAuthorizationPage')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessRegPage;
