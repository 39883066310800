export function getComponentsSize({ wrapperNode, contentNode, newScale }) {
  const wrapperHeight = wrapperNode.offsetHeight;
  const wrapperWidth = wrapperNode.offsetWidth;

  const contentHeight = contentNode.offsetHeight;
  const contentWidth = contentNode.offsetWidth;

  const newContentWidth = contentWidth * newScale;
  const newContentHeight = contentHeight * newScale;

  const diffWidth = Math.abs(wrapperWidth - newContentWidth);
  const diffHeight = Math.abs(wrapperHeight - newContentHeight);

  return {
    wrapperHeight,
    wrapperWidth,
    contentHeight,
    contentWidth,
    newContentHeight,
    newContentWidth,
    diffHeight,
    diffWidth
  };
}

export function calcBounds({ diffHeight, diffWidth }) {
  const scaleWidthFactor = diffWidth > 0 ? diffWidth / 2 : 0;
  const scaleHeightFactor = diffHeight > 0 ? diffHeight / 2 : 0;

  return {
    minPositionX: scaleWidthFactor > 0 ? -scaleWidthFactor : 0,
    maxPositionX: scaleWidthFactor,
    minPositionY: scaleHeightFactor > 0 ? -scaleHeightFactor : 0,
    maxPositionY: scaleHeightFactor
  };
}

export function getClientPosition(event) {
  const { touches } = event;

  // For mobile
  if (touches?.length === 1) {
    return { clientX: touches[0].clientX, clientY: touches[0].clientY };
  }

  // For desktop
  if (!touches) {
    return { clientX: event.clientX, clientY: event.clientY };
  }

  return null;
}

const roundNumber = (num, decimal = 2) => Number(num.toFixed(decimal));

export function boundLimiter(value, minBound, maxBound) {
  if (value < minBound) return roundNumber(minBound, 2);
  if (value > maxBound) return roundNumber(maxBound, 2);
  return roundNumber(value, 2);
}

export function checkPositionBounds(positionX, positionY, bounds) {
  const { minPositionX, minPositionY, maxPositionX, maxPositionY } = bounds;

  const x = boundLimiter(positionX, minPositionX, maxPositionX);
  const y = boundLimiter(positionY, minPositionY, maxPositionY);

  return { x, y };
}
