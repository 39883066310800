import { useCallback, useEffect, useRef } from 'react';

export const useBindData = (vm) => {
  const state = useRef();

  const update = (data) => {
    state.current = data;
  };
  const getData = useCallback(() => state.current, []);

  useEffect(() => {
    update(vm);
  }, [vm]);

  return getData;
};
