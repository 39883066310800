import { getAdvancedSearch } from '../../getter';
import { getChatContact } from '../../modules/contacts/getters';

const isAdvancedSearchOff = (advancedSearch) => advancedSearch.hidden;

const getContacts = ({ blockContact, advancedSearch }) => {
  if (isAdvancedSearchOff(advancedSearch)) return blockContact.list;
  return advancedSearch.result.contacts;
};

export const getContact = (state, { contactId }) => {
  const advancedSearch = getAdvancedSearch(state);
  const blockContact = getChatContact(state);
  const contacts = getContacts({ blockContact, advancedSearch });
  return contacts.find((c) => c.id === contactId);
};
