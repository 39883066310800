const { isNil, curry } = require('ramda');
const { format, compareAsc } = require('date-fns');

/**
 * Formates the date by placeholder
 *
 * @param {string} placeholder - format placeholder see in date-fns/format
 * @param {Date} date
 * @returns {string}
 */
exports.format = curry((placeholder, date) =>
  isNil(date) ? '-' : format(new Date(date), placeholder)
);

/**
 * Compares date in ascending order
 *
 * @param {Date} dateA
 * @param {Date} dateB
 * @returns {number}
 */
exports.compareAsc = (dateA, dateB) =>
  compareAsc(new Date(dateA), new Date(dateB));
