import { Map, List, Set, fromJS } from 'immutable';

const newItems = () => new Map();
const newInvitations = () => new Map();
const newSelect = () => new Set();
const newCustomer = () =>
  new Map({ companyName: '', email: '', category: null });
const newSelectedItem = () => new Map();
const newSelectedCategory = () => new Map();
const newCategory = () => new Map({ name: '' });
const newSelectedCategories = () => new Set();

export const initState = () =>
  new Map({
    items: newItems(),
    invitations: newInvitations(),
    firstUploaded: false,
    selected: newSelect(),
    selectedItem: newSelectedItem(),
    selectedCategory: newSelectedCategory(),
    selectedCategories: newSelectedCategories(),
    groups: List(),
    newCategory: newCategory(),
    newCustomer: newCustomer(),
    currentCategory: new Map(),
    selectedItems: List(),
    requests: List(),
    additionRequests: List(),
    selectedRequests: Set(),
    customersCount: 0,
    size: 0,
    totalSize: 0,
    limit: 20,
    offset: 0,
    groupId: 0,
    groupsMembersIds: List(),
    link: Map()
  });

export const setCustomers = (
  data,
  { customers, size, limit, totalSize, offset, groupId = 0 }
) =>
  data
    .remove('items')
    .set('items', fromJS(customers))
    .set('size', size)
    .set('totalSize', totalSize)
    .set('offset', offset)
    .set('limit', limit)
    .set('groupId', groupId)
    .set('firstUploaded', true);

export const deselectAll = (data) =>
  data.remove('selected').set('selected', newSelect());

export const deselectAllCategories = (data) =>
  data
    .remove('selectedCategories')
    .set('selectedCategories', newSelectedCategories());

export const clearNewCustomer = (data) =>
  data.set('newCustomer', newCustomer());

export const updateCustomerField = (data, { field, value }) =>
  data.updateIn(['newCustomer', field], () => value);

export const updateCategories = (data, { groupId }) =>
  data
    .update('groups', List(), (items) =>
      items.filter((group) => group.get('id') !== groupId)
    )
    .update('items', (items) =>
      items.map((item) =>
        item.update('groups', (groups) => groups.filter((id) => id !== groupId))
      )
    );

export const setCategories = (data, { groups }) =>
  data.remove('groups').set('groups', fromJS(groups));

export const setSelectedCategory = (data, { item }) =>
  data.remove('selectedCategory').set('selectedCategory', item);

export const deselectCategory = (data) =>
  data
    .remove('selectedCategory')
    .set('selectedCategory', newSelectedCategory());

export const setSelectedItem = (data, { item }) =>
  data.remove('selectedItem').set('selectedItem', item);

export const setSelectedItems = (data, { id }) =>
  data.update('selected', Set(), (selected) =>
    selected.includes(id) ? selected.delete(id) : selected.add(id)
  );

export const setSelectedRequests = (data, { id }) =>
  data.update('selectedRequests', Set(), (selected) =>
    selected.includes(id) ? selected.delete(id) : selected.add(id)
  );

export const setSelectedInvites = (data, { id }) =>
  data.update('selectedInvites', Set(), (selected) =>
    selected.includes(id) ? selected.delete(id) : selected.add(id)
  );

export const clearAllCustomers = () => initState();

export const selectCategory = (data, { id }) =>
  data
    .update(
      'selectedCategories',
      newSelectedCategories(),
      (selectedCategories) =>
        selectedCategories.includes(id)
          ? selectedCategories.remove(id)
          : selectedCategories.add(id)
    )
    .remove('selected')
    .set('selected', newSelect());

export const selectCustomer = (data, { id }) =>
  data.update('selected', newSelect(), (selected) =>
    selected.includes(id) ? selected.delete(id) : selected.add(id)
  );

export const deselectCustomer = (data) =>
  data.remove('selectedItem').set('selectedItem', newSelect());

export const clearNewCategory = (data) =>
  data.remove('newCategory').set('newCategory', newCategory());

export const updateNewCategoryName = (data, { field, value }) =>
  data.updateIn(['newCategory', field], () => value);

export const updateCategoryName = (data, { field, value }) =>
  data.updateIn(['selectedCategory', field], () => value);

export const updateEditField = (data, { field, value }) =>
  data.updateIn(['selectedItem', field], () => value);

export const setInvitations = (data, { invites, link }) =>
  data.set('invitations', fromJS(invites)).set('link', fromJS(link));

export const clearInvitations = (data) =>
  data.set('invitations', newInvitations());

export const addNewCustomer = (data, { request, invite, isExists }) => {
  if (isExists) {
    return data.update('requests', List(), (requests) =>
      requests.push(fromJS(request))
    );
  }
  return data.update('invitations', List(), (invites) =>
    invites.push(fromJS(invite))
  );
};

export const setRequests = (data, { requests }) =>
  data.remove('requests').set('requests', fromJS(requests));

export const setAdditionRequests = (data, { additionRequests }) =>
  data
    .remove('additionRequests')
    .set('additionRequests', fromJS(additionRequests));

export const removeRequests = (data, { requests: removedRequests }) =>
  data
    .update('requests', List(), (items) =>
      items.filter((item) => !removedRequests.includes(item.get('id')))
    )
    .remove('selectedRequests')
    .set('selectedRequests', Set());

export const removeAdditionRequest = (data, { companyId }) =>
  data.update('additionRequests', List(), (items) =>
    items.filter((item) => item.get('from') !== companyId)
  );

export const toggleLink = (data, { isTokenOn }) =>
  data.setIn(['link', 'isTokenOn'], isTokenOn);

export const setToken = (data, { token }) =>
  data.setIn(['link', 'token'], token);
