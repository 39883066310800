import {
  TEMPORARY_SET,
  TEMPORARY_CLEAR,
  TEMPORARY_VALUE_SET,
  TEMPORARY_VALUE_CLEAR,
  TEMPORARY_SET_TO_SET,
  TEMPORARY_APPEND_TO_SET,
  TEMPORARY_REMOVE_FROM_SET,
  TEMPORARY_TOGGLE_IN_SET,
  TEMPORARY_CLEAR_SET,
  TEMPORARY_SET_TO_MAP,
  TEMPORARY_DELETE_FROM_MAP,
  TEMPORARY_CLEAR_MAP
} from '../actionTypes/temporary';

export const setTemporary = (state) => ({
  type: TEMPORARY_SET,
  payload: state
});
export const clearTemporary = () => ({ type: TEMPORARY_CLEAR, payload: {} });

export const setValueTemp = (name) => (value) => ({
  type: TEMPORARY_VALUE_SET,
  payload: { name, value }
});
export const setKeyValueTemp = (name, value) => ({
  type: TEMPORARY_VALUE_SET,
  payload: { name, value }
});
export const clearValueTemp = (name) => ({
  type: TEMPORARY_VALUE_CLEAR,
  payload: { name }
});

export const setToSetTemp = (name) => (values) => ({
  type: TEMPORARY_SET_TO_SET,
  payload: { name, values }
});
export const appendToSetTemp = (name) => (value) => ({
  type: TEMPORARY_APPEND_TO_SET,
  payload: { name, value }
});
export const removeFromSetTemp = (name) => (value) => ({
  type: TEMPORARY_REMOVE_FROM_SET,
  payload: { name, value }
});
export const toggleValueSetTemp = (name) => (value) => ({
  type: TEMPORARY_TOGGLE_IN_SET,
  payload: { name, value }
});
export const clearSetTemp = (name) => () => ({
  type: TEMPORARY_CLEAR_SET,
  payload: { name }
});

export const setToMapTemp = (name) => (key, value) => ({
  type: TEMPORARY_SET_TO_MAP,
  payload: { name, key, value }
});
export const deleteFromMapTemp = (name) => (key) => ({
  type: TEMPORARY_DELETE_FROM_MAP,
  payload: { name, key }
});
export const clearMapTemp = (name) => () => ({
  type: TEMPORARY_CLEAR_MAP,
  payload: { name }
});
