import {
  isFirstLoad,
  searchQuery,
  setSearchQuery,
  isMinMaxDate
} from '../../../../lib/query';

export const makeViewModel = ({
  getQuery,
  saveQuery,
  openedChat,
  user,
  blockSearchMsgs,
  handleSearchMode,
  config,
  searchMsgs,
  loadMoreMsgs,
  searchMsgsWithDateRange,
  messagesPlacement
}) => ({
  getQuery,
  saveQuery,
  openedChat,
  user,
  blockSearchMsgs,
  handleSearchMode,
  config,
  searchMsgs,
  loadMoreMsgs,
  searchMsgsWithDateRange,
  messagesPlacement
});

export const EVENT_BACK_TO_CHAT = 'back-to-chat';

export const isFirstLoadMsgs = (vm) => isFirstLoad(vm.getQuery());

const haveToLoadMore = (vm) => vm.blockSearchMsgs.amountLeft > 0;
const totalMessages = (vm) =>
  vm.blockSearchMsgs.list.length + vm.blockSearchMsgs.amountLeft;
export const isNoMessages = (vm) => totalMessages(vm) === 0;

// ==== Header ====

export const searchQueryValue = (vm) => searchQuery(vm.getQuery());
export const initSetSearchQuery = (vm) => (text) => {
  vm.saveQuery(setSearchQuery(text, vm.getQuery()));
};
export const initOnChangeDates = (vm) => async (dataRange) =>
  vm.searchMsgsWithDateRange(
    { getQuery: vm.getQuery, saveQuery: vm.saveQuery },
    dataRange
  );
export const initOnSearch = (vm) => async () =>
  vm.searchMsgs({ getQuery: vm.getQuery, saveQuery: vm.saveQuery });
export const initOnClose = (vm) => () =>
  vm.handleSearchMode(EVENT_BACK_TO_CHAT);
export const isActiveCalendar = (vm) => !isMinMaxDate(vm.getQuery());

// ==== Content ====

export const chat = (vm) => vm.openedChat;
export const user = (vm) => vm.user;
export const messages = (vm) => vm.blockSearchMsgs.list;
export const initHandlerMsg = (vm) => (item, chatH, msg) =>
  vm.handleSearchMode(item, msg);
export const ownConfig = (vm) => vm.config.config.ownMessage;
export const otherConfig = (vm) => vm.config.config.otherMessage;
export const config = (vm) => vm.config;
export const amountLeftNext = (vm) => vm.blockSearchMsgs.amountLeft;
export const messagesPlacement = (vm) => vm.messagesPlacement;

export const isShowBtnLoadMore = (vm) => haveToLoadMore(vm);
export const initLoadMore = (vm) => async () =>
  vm.loadMoreMsgs({ getQuery: vm.getQuery, saveQuery: vm.saveQuery });
