import { getDefaultKeyBinding, KeyBindingUtil } from 'draft-js';
import { isMobileDevice } from '../../../../../../lib';

const { hasCommandModifier, isCtrlKeyCommand, isOptionKeyCommand } =
  KeyBindingUtil;

let history = [];

function isEnterWithoutModifiers(e) {
  return (
    e.key === 'Enter' &&
    !(
      hasCommandModifier(e) ||
      isCtrlKeyCommand(e) ||
      isOptionKeyCommand(e) ||
      e.shiftKey
    )
  );
}

function trackKeyHistory(e) {
  const { key } = e;

  if (history.length > 2) {
    history = history.slice(1);
  }

  if (!KeyBindingUtil.hasCommandModifier(e)) {
    history.push(key);
  }

  return history;
}

const getPreviousChar = (charHistory) => charHistory[charHistory.length - 2];
const getPreviousInput = (charHistory) => charHistory.slice(0, 2).join('');
const isEqualPrevChar = (char, charHistory) =>
  getPreviousChar(charHistory) === char;
const isEqualPrevInput = (text, charHistory) =>
  getPreviousInput(charHistory) === text;

export const initKeyBindingFn =
  ({ isTextEmpty, isSendDisable }) =>
  (e) => {
    const charHistory = trackKeyHistory(e);

    if (e.key === ' ' && isEqualPrevChar('*', charHistory))
      return 'start-unordered-list';
    if (e.key === ' ' && isEqualPrevInput('1.', charHistory))
      return 'start-ordered-list';
    if (isEnterWithoutModifiers(e) && !isSendDisable)
      return isMobileDevice() ? getDefaultKeyBinding(e) : 'send';
    if (e.key === 'ArrowUp' && isTextEmpty) return 'edit';

    // NOTE: It's need for experiments with functionality
    // if (e.key === 's' /* `S` key */ && isCtrlKeyCommand(e)) return 'test';

    return getDefaultKeyBinding(e);
  };
