import React from 'react';
import { defRecord, isType } from 'core/lib';
import { isEmpty, isNil } from 'ramda';
import { findMember } from 'core/data/light/channel';
import { PURCHASE_REQUESTS_MESSAGE_TYPE } from 'core/data/light/purchaseRequestChannel';
import { linkToProfileHandler, interpreterMessageKey } from '../../../utils';

const { makeMessageVM, getMessage, getComponent } = defRecord('MessageVM', [
  't',
  'message',
  'chat',
  'currentEmployeeId',
  'showProfile',
  'component'
]);

const initShowProfile = (vm) => (event) => {
  linkToProfileHandler(event, vm.showProfile);
};

const isNeedTranslate = (vm) =>
  isType('MsgSupport', getMessage(vm)) ||
  Boolean(getMessage(vm).msg && getMessage(vm).msg.tkey);

const isRenderComponent = (vm) =>
  !isNil(getComponent(vm)) && React.isValidElement(getComponent(vm));

const getUserName = (vm) => {
  const { userName } = findMember(vm.chat, vm.currentEmployeeId) || {};
  return userName;
};

const getI18nKey = (vm) => interpreterMessageKey(getMessage(vm));

const generateLinkProps = (vm) => ({
  to: getTo('/help'),
  text:
    getI18nKey(vm) === 'support_messenger_greeting_message'
      ? vm.t('help')
      : vm.t('Documentation')
});

function getTo(to) {
  if (window.location.origin === process.env.UNICAT_DEFAULT_SERVICE) {
    return to;
  }

  return `${process.env.UNICAT_DEFAULT_SERVICE}${to}`;
}

function isOriginalPRMessage(vm) {
  return vm.message.type === PURCHASE_REQUESTS_MESSAGE_TYPE;
}

function isForwardedPRMessage(vm) {
  return vm.message.originMsgType === PURCHASE_REQUESTS_MESSAGE_TYPE;
}

function isPRMessage(vm) {
  return isOriginalPRMessage(vm) || isForwardedPRMessage(vm);
}

function getPRMessageText(vm) {
  if (isOriginalPRMessage(vm)) return vm.message.title;

  return vm.message.originMsgData.title;
}

const getForwardedMessageText = (vm) => {
  if (isNil(vm.message)) return '';
  if (isPRMessage(vm)) return getPRMessageText(vm);
  if (!isEmpty(vm.message.msg)) return vm.message.msg;
  if (isEmpty(vm.message.msg)) return getFilesDescription();

  return '';

  function getFilesDescription() {
    return vm.message.files.length === 1
      ? vm.message.files[0].name
      : `${vm.message.files.length} ${vm.t('files')}`;
  }
};

export {
  makeMessageVM,
  getMessage,
  isNeedTranslate,
  getI18nKey,
  generateLinkProps,
  getUserName,
  getForwardedMessageText,
  initShowProfile,
  isRenderComponent,
  getComponent
};
