import { assoc } from 'ramda';
import { getTranslate } from '../../utils';

const statusesList = {
  failed: {
    label: 'failed',
    textColor: 'White',
    bgColor: 'Cardinal'
  },
  processed: {
    label: 'processed',
    textColor: 'White',
    bgColor: 'Lavender'
  },
  delivered: {
    label: 'delivered',
    textColor: 'White',
    bgColor: 'Grass'
  },
  opened: {
    label: 'opened',
    textColor: 'White',
    bgColor: 'Orange'
  },
  clicked: {
    label: 'opened',
    textColor: 'White',
    bgColor: 'Orange'
  }
};

export const getStatus = (t, status) => {
  const currentStatus = statusesList[status];

  return assoc(
    'label',
    getTranslate(t, `statuses.${currentStatus.label}.displayName`),
    currentStatus
  );
};
