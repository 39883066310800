const { defStruct } = require('../../lib');
const { makeDataPart, getById } = require('../dataPart');
const { makeSelectedList, getFirst } = require('../selectedList');

/**
 * @typedef PartList
 * @typedef {import('../dataPart')} DataPart
 * @typedef {import('../selectedList')} SelectedList
 */
const { makePartList, getDataPart, getSelectedList, isPartList } = defStruct(
  'PartList',
  ['dataPart', 'selectedList']
);

/**
 * Create a part list data type
 *
 * @param {object} params
 * @param {DataPart} params.dataPart
 * @param {SelectedList} params.selectedList
 * @returns {PartList}
 */
exports.makePartList = ({
  dataPart = makeDataPart(),
  selectedList = makeSelectedList()
} = {}) => makePartList(dataPart, selectedList);

/**
 * Gives dataPart from part list
 *
 * @param {PartList} partList
 * @returns {DataPart}
 */
exports.getDataPart = (partList) => getDataPart(partList);

/**
 * Gives dataPart from part list
 *
 * @param {PartList} partList
 * @returns {SelectedList}
 */
exports.getSelectedList = (partList) => getSelectedList(partList);

/**
 * Checks if is part list
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isPartList = (a) => isPartList(a);

/**
 * Gives first selected item
 *
 * @param {PartList} partList
 * @returns {object}
 */
exports.getFirstSelectedItem = (partList) =>
  getById(getFirst(getSelectedList(partList)), getDataPart(partList));
