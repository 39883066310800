import { defRecord } from 'core/lib';
import { isEmpty } from 'ramda';

const { makeListVMFromStorage, getItems } = defRecord(
  'ListVMFromStorage',
  [
    't',
    'mode',
    'title',
    'placeholder',
    'search',
    'items',
    'onCancel',
    'showModal',
    'hideModal',
    'searchItems',
    'searchBlockWidth',
    'children'
  ],
  {
    items: [],
    search: '',
    placeholder: 'find_contacts',
    onCancel: () => {},
    searchBlockWidth: 510,
    isLoadingChunk: false
  }
);

function isShowItems(vm) {
  return !isEmpty(getItems(vm));
}

function getTitle(vm) {
  return vm.t(vm.title);
}

function getPlaceholder(vm) {
  return vm.t(vm.placeholder);
}

function getRows(vm) {
  return vm.children;
}

function getSearchBlockWidth(vm) {
  return vm.searchBlockWidth;
}

const initOnSearch = (vm) => (search) => {
  vm.searchItems({ mode: vm.mode, search });
};

const initOnCancel = (vm) => () => {
  vm.hideModal();
  vm.onCancel();
};

export {
  makeListVMFromStorage,
  isShowItems,
  getTitle,
  getPlaceholder,
  getRows,
  getSearchBlockWidth,
  initOnCancel,
  initOnSearch
};
