import React from 'react';
import PropTypes from 'prop-types';

const GridPageContent = ({ children, style }, { b }) => (
  <div id="grid-content" className={b('content')} style={style}>
    {children}
  </div>
);

GridPageContent.contextTypes = {
  b: PropTypes.func
};

export default GridPageContent;
