import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { noop } from '@/lib/utils';
import cn from 'classnames';
import { useFocusVisible } from '@/hooks';
import { ButtonIcon } from '@/components/ButtonIcon';
import styles from './SearchInput.module.scss';

export interface SearchInputProps {
  valid?: boolean;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string;
  onSearch?: (value: string) => void;
  onCleanUp?: () => void;
  onChange?: (value: string) => void;
}

export function SearchInput({
  valid = true,
  className = '',
  placeholder = '',
  defaultValue = '',
  onSearch = noop,
  onCleanUp = noop,
  onChange = noop,
  disabled = false
}: SearchInputProps) {
  const [isHovered, setIsHovered] = useState(false);

  const { isFocused, onFocus, onBlur } = useFocusVisible();

  const [searchQuery, setSearchQuery] = useState(defaultValue);
  const handleCleanUp = () => {
    if (searchQuery.length) {
      setSearchQuery('');
      onCleanUp();
    }
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchQuery(newValue);
    onChange(newValue);
  };
  const handleSearch = () => {
    onSearch(searchQuery.trim());
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'Enter':
        handleSearch();
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={className}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <div
        className={cn(styles.searchInput, {
          [styles.disabled]: disabled,
          [styles.isError]: !valid,
          [styles.isFocused]: isFocused,
          [styles.isHovered]: !isFocused && isHovered
        })}>
        <input
          onFocus={onFocus}
          onBlur={onBlur}
          data-testid="search-input"
          className={styles.inputField}
          placeholder={placeholder}
          value={searchQuery}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
        {searchQuery.length ? (
          <ButtonIcon
            className={styles.iconBtn}
            onClick={handleCleanUp}
            disabled={disabled}
            iconName="close"
            size={18}
          />
        ) : (
          <ButtonIcon
            className={styles.iconBtn}
            onClick={handleSearch}
            disabled={disabled}
            iconName="search"
            size={18}
          />
        )}
      </div>
    </div>
  );
}
