import { isEmpty } from 'ramda';

export const makeFilesVM = ({
  editMode,
  chatId,
  files,
  haveFreeSpace,
  onDelete
}) => ({
  editMode,
  chatId,
  files,
  haveFreeSpace,
  onDelete
});

export const getFiles = (vm) => vm.files;

export const initDeleteFile = (vm) => (messageId, file) => {
  vm.onDelete({ editMode: vm.editMode, id: vm.chatId, file });
};

export const isShowFilesPreview = (vm) => !isEmpty(getFiles(vm));

export const isBlockedFunctions = (vm) => !vm.haveFreeSpace;
