import React from 'react';
import classNames from 'classnames';
import Tooltip from '../../../tooltip';
import Icon from '../../icons';
import { noop } from '../../../utils';
import './style.css';

const useThrottledCb = (cb = noop, wait = 250) => {
  const isNeedCallCb = React.useRef(true);

  return (event) => {
    if (isNeedCallCb.current) {
      isNeedCallCb.current = false;

      setTimeout(() => {
        isNeedCallCb.current = true;
      }, wait);

      cb(event);
    }
  };
};

function ButtonIcon({
  t,
  className,
  icon,
  disabled,
  tooltipId,
  active = false,
  color = 'gray',
  activeColor = 'blue',
  tooltip,
  onClick: clickCb = () => {},
  wait = 250,
  size = 14
}) {
  const onClick = useThrottledCb(clickCb, wait);
  return (
    <button
      className={classNames(
        className,
        'button-icon',
        `button-icon_color_${color}`,
        `button-icon_active-color_${activeColor}`,
        { disabled, active }
      )}
      type="button"
      onClick={(event) => !disabled && onClick(event)}
      style={{ width: size, height: size }}>
      <Tooltip t={t} text={tooltip} tooltipId={tooltipId}>
        <div className="button-icon__img">
          <Icon icon={icon} size={size} />
        </div>
      </Tooltip>
    </button>
  );
}

ButtonIcon.defaultProps = {
  active: false,
  disabled: false,
  color: 'gray',
  activeColor: 'blue',
  tooltip: '',
  onClick: () => {}
};

export default ButtonIcon;
