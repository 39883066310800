import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import {
  Container,
  HeaderDialog,
  HeaderChannel,
  HeaderTopic,
  Body
} from '../Components';
import DialogLists from './DialogLists';
import * as modalActions from '../../../../action-creators/modal';
import * as useCase from '../../useCases';
import {
  getChatBlock,
  getOpenedChat,
  getCurrEmplId,
  getChatArchive,
  getActiveTab
} from '../../getter';

const findChat = (blockChat, openedChat, chatId) => {
  const bchat = blockChat.list.find((c) => c.id === chatId);
  if (bchat) return bchat;
  if (openedChat.id === chatId) return openedChat;
  return null;
};

const CWModalEditDialogDialogLists = ({
  activeTab,
  blockChat,
  blockArchive,
  openedChat,
  chatId,
  lists = [],
  employeeId,
  hideModal,
  handleEditChatInGroup
}) => {
  const activeBlock = activeTab === 'archives' ? blockArchive : blockChat;
  const chat = findChat(activeBlock, openedChat, chatId);
  if (!chat) {
    console.error('Not found contact in blockContact with id', chatId);
    return null;
  }

  return (
    <Modal show onHide={hideModal}>
      <Container>
        {/* TODO: Calculate employee id! */}
        {chat.type === 'Dialog' && (
          <HeaderDialog
            employeeId={employeeId}
            dialog={chat}
            onClose={hideModal}
          />
        )}
        {chat.type === 'Channel' && (
          <HeaderChannel channel={chat} onClose={hideModal} />
        )}
        {chat.type === 'Topic' && (
          <HeaderTopic topic={chat} onClose={hideModal} />
        )}
        <Body>
          <DialogLists
            chat={chat}
            handler={handleEditChatInGroup}
            lists={lists}
          />
        </Body>
      </Container>
    </Modal>
  );
};

export default connect(
  (state) => ({
    activeTab: getActiveTab(state),
    blockChat: getChatBlock(state),
    blockArchive: getChatArchive(state),
    openedChat: getOpenedChat(state),
    employeeId: getCurrEmplId(state)
  }),
  (dispatch) =>
    bindActionCreators(
      { hideModal: modalActions.hideModalDialog, ...useCase },
      dispatch
    )
)(CWModalEditDialogDialogLists);
