import React from 'react';
import { useTranslate, useActions } from '@hooks';
import { Modal, Button } from '@link/react-components';

import * as modalActions from '../../../../action-creators/modal';

const CWModalDeleteContact = ({ onDelete, name, employeeId }) => {
  const t = useTranslate();

  const { hideModalDialog } = useActions(modalActions);

  async function onSubmit() {
    await onDelete(employeeId);
    hideModalDialog();
  }

  return (
    <Modal onClose={hideModalDialog} open>
      <Modal.Header>{t('delete_contact_modal_title')}</Modal.Header>
      <Modal.Content>
        {t('delete_contact_ask', { userName: name })}
      </Modal.Content>
      <Modal.Footer>
        <Modal.Actions>
          <Button mode="text" onClick={hideModalDialog}>
            {t('Cancel')}
          </Button>
          <Button mode="primary" onClick={onSubmit}>
            {t('Delete')}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default CWModalDeleteContact;
