import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PURCHASE_REQUESTS_CHANNEL } from 'core/data/light/purchaseRequestChannel';
import { Flex } from '../../../styledComponents/atoms';
import Header from '../../ChatWidget/sidebar/header';
import {
  getSidebarHeaderView,
  checkCurrentMemberHaveActivedMS,
  getOpenedChat,
  getActiveTab,
  getAdvancedSearch
} from '../../ChatWidget/getter';
import { subHeaderConfig } from './config';
import Dashboard from '../../ChatWidget/modules/dashboard';
import ChatWidgetSidebarAuxiliary from '../../ChatWidget/modules/categories';
import * as useCases from '../../ChatWidget/sidebar/AdvancedSearch/useCases';

const MessengerSubHeader = ({
  currentMode,
  subscribeAdvancedSearch,
  unsubscribeAdvencedSearch,
  activeTab,
  view
}) => {
  const isActive = useSelector(checkCurrentMemberHaveActivedMS);
  const user = useSelector((state) => state.getIn(['user', 'user']));
  const openedChat = useSelector(getOpenedChat);
  const { chatWidgetMain } = subHeaderConfig;

  const isDialog = openedChat?.type === 'Dialog';
  const isChannel = openedChat?.type === 'Channel';
  const isPurchaseRequestChannel =
    openedChat?.type === PURCHASE_REQUESTS_CHANNEL;

  useEffect(() => {
    subscribeAdvancedSearch();
    return unsubscribeAdvencedSearch;
  }, []);

  const renderContent = () => {
    if (currentMode === 'none') {
      return (
        <Header view={view} activeTab={activeTab} config={subHeaderConfig} />
      );
    }

    return (
      <>
        {(isDialog || isChannel || isPurchaseRequestChannel) &&
          currentMode === 'chat' && (
            <Dashboard
              chat={openedChat}
              user={user}
              isActive={isActive}
              config={chatWidgetMain.dashboard}
            />
          )}
      </>
    );
  };

  return (
    <Container>
      {renderContent()}
      <ChatWidgetSidebarAuxiliary />
    </Container>
  );
};

const Container = styled(Flex)`
  width: 100%;
  overflow: hidden;
  background: ${(p) => p.theme.colors.white};
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.colors.gray.lightest};
  flex-shrink: 0;

  .cws-dashboard {
    padding: 0 16px 0 10px;
  }
`;

export default connect(
  (state) => ({
    activeTab: getActiveTab(state),
    view: getSidebarHeaderView(state),
    search: getAdvancedSearch(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        subscribeAdvancedSearch: useCases.subscribeAdvancedSearch,
        unsubscribeAdvencedSearch: useCases.unsubscribeAdvencedSearch
      },
      dispatch
    )
)(MessengerSubHeader);
