import React from 'react';
import Textarea from 'react-textarea-autosize';
import '../style.css';

function DynamicBorderTextarea({
  value,
  onChange,
  placeholder,
  maxLength,
  minRows,
  maxRows
}) {
  return (
    <Textarea
      className="dynamic-border-textarea"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      maxLength={maxLength}
      minRows={minRows}
      maxRows={maxRows}
    />
  );
}

export default DynamicBorderTextarea;
