const { makeEvent, makeCommand } = require('../data/messageBus/message');
const { makeQuery } = require('./utils');

const NS = 'TASKS';

exports.taskQRY = makeQuery(NS, 'task');

exports.createdTaskEVT = makeEvent(NS, 'TASK_WAS_CREATED');

exports.expiredTaskEVT = makeEvent(NS, 'TASK_WAS_EXPIRED');

exports.changedExecutorOfTaskEVT = makeEvent(
  NS,
  'EXECUTOR_OF_TASK_WAS_CHANGED'
);

exports.destroyedTaskEVT = makeEvent(NS, 'TASK_WAS_DESTROYED');

exports.setTaskAsViewedCMD = makeCommand(NS, 'SET_TASK_AS_VIEWED');

exports.updateTaskCMD = makeCommand(NS, 'UPDATE_TASK');

exports.checkExpiredTasksCMD = makeCommand(NS, 'CHECK_EXPIRED_TASKS');

exports.viewCMD = makeCommand(NS, 'view');
