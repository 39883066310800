const PageRenderStatus = {
  NotRenderedYet: 'NotRenderedYet',
  Rendering: 'Rendering',
  Rendered: 'Rendered'
};

const OUT_OF_RANGE_VISIBILITY = -9999;

const pageVisibilities = {};

export const renderQueueService = ({ doc, queueName }) => {
  const { numPages } = doc;

  const queue = `${queueName}__${doc.loadingTask.docId}`;

  const getInitialVisibilities = () =>
    Array(numPages)
      .fill(1)
      .map((_, pageIndex) => ({
        pageIndex,
        renderStatus: PageRenderStatus.NotRenderedYet,
        visibility: OUT_OF_RANGE_VISIBILITY
      }));

  const cleanup = () => {
    pageVisibilities[queue] = [];
  };

  const resetQueue = () => {
    pageVisibilities[queue] = getInitialVisibilities();
  };

  const markRendered = (pageIndex) => {
    pageVisibilities[queue][pageIndex].renderStatus = PageRenderStatus.Rendered;
  };

  const markRendering = (pageIndex) => {
    pageVisibilities[queue][pageIndex].renderStatus =
      PageRenderStatus.Rendering;
  };

  const setRange = (startIndex, endIndex) => {
    for (let i = 0; i < numPages; i++) {
      if (i < startIndex || i > endIndex) {
        pageVisibilities[queue][i].visibility = OUT_OF_RANGE_VISIBILITY;
        pageVisibilities[queue][i].renderStatus =
          PageRenderStatus.NotRenderedYet;
      }
    }
  };

  const setVisibility = (pageIndex, newVisibility) => {
    pageVisibilities[queue][pageIndex].visibility = newVisibility;
  };

  const getHighestPriorityPage = () => {
    const visiblePages = pageVisibilities[queue].filter(
      (p) => p.visibility > OUT_OF_RANGE_VISIBILITY
    );

    if (!visiblePages.length) {
      return -1;
    }

    const firstVisiblePage = visiblePages[0].pageIndex;
    const lastVisiblePage = visiblePages[visiblePages.length - 1].pageIndex;

    const numVisiblePages = visiblePages.length;
    for (let i = 0; i < numVisiblePages; i++) {
      if (visiblePages[i].renderStatus === PageRenderStatus.Rendering) {
        return -1;
      }
      if (visiblePages[i].renderStatus === PageRenderStatus.NotRenderedYet) {
        return visiblePages[i].pageIndex;
      }
    }

    // All visible pages are rendered
    if (
      lastVisiblePage + 1 < numPages &&
      pageVisibilities[queue][lastVisiblePage + 1].renderStatus !==
        PageRenderStatus.Rendered
    ) {
      return lastVisiblePage + 1;
    }

    if (
      firstVisiblePage - 1 >= 0 &&
      pageVisibilities[queue][firstVisiblePage - 1].renderStatus !==
        PageRenderStatus.Rendered
    ) {
      return firstVisiblePage - 1;
    }

    return -1;
  };

  resetQueue();

  return {
    OUT_OF_RANGE_VISIBILITY,
    getHighestPriorityPage,
    markRendering,
    markRendered,
    cleanup,
    resetQueue,
    setRange,
    setVisibility
  };
};
