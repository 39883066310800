/* eslint-disable no-param-reassign */
import { useEffect } from 'react';
import usePan from './pan';
import useProxyState from './proxy';
import { DEFAULT_SCALE } from './constants';
import useZoom from './zoom';

function createNode(elementNode) {
  const wrapperNode = document.createElement('div');
  elementNode.parentNode.insertBefore(wrapperNode, elementNode);
  return wrapperNode;
}

function createWrapper(elementNode, options) {
  const wrapperNode = createNode(elementNode);
  wrapperNode.id = 'zoom-wrapper';
  setDefaultWrapperOption(wrapperNode, options);
  return wrapperNode;
}

function setDefaultWrapperOption(node, options) {
  if (!options) return;
  const {
    overflowX,
    overflowY,
    width = '100%',
    height = '100%',
    maxWidth,
    maxHeight,
    flex = true
  } = options;

  if (flex) {
    node.style.display = 'flex';
    node.style.justifyContent = 'center';
    node.style.alignItems = 'center';
  }
  node.style.width = width;
  node.style.height = height;
  node.style.maxWidth = setSize(maxWidth);
  node.style.maxHeight = setSize(maxHeight);
  node.style.overflowX = `${overflowX || 'auto'}`;
  node.style.overflowY = `${overflowY || 'auto'}`;
}

function setDefaultContentOptions(node, options) {
  if (!options) return;
  const { defaultScale } = options;

  node.style.transformOrigin = `0 0`;
  node.style.transform = `scale(${defaultScale || DEFAULT_SCALE})`;
  node.style.transition = `transform 200ms ease 0s`;
}

function setSize(size, dimension = 'px', defaultValue) {
  const preparedDefaultValue = prepareDefaultValue();

  return size ? `${size}${dimension}` : preparedDefaultValue;

  function prepareDefaultValue() {
    if (!defaultValue) return 'auto';
    if (Number.isInteger(defaultValue)) return `${defaultValue}${dimension}`;
    return defaultValue;
  }
}

function setChildrenNodes(nodes = []) {
  let parentNode = null;

  nodes.forEach((childNode) => {
    if (parentNode) {
      parentNode.appendChild(childNode);
    }

    parentNode = childNode;
  });
}

const useZoomEffect = ({ domTarget }, options = {}) => {
  const proxy = useProxyState(options);
  const { scale } = proxy.state;
  const { setWrapperNode, setContentNode } = proxy.methods;

  useEffect(() => {
    if (domTarget?.current) {
      const $content = domTarget.current;
      setDefaultContentOptions($content);
      const $wrapper = createWrapper($content, options);
      setWrapperNode($wrapper);
      setContentNode($content);
      setChildrenNodes([$wrapper, $content]);
    }
  }, []);

  usePan(proxy);
  const { zoomIn, zoomOut, zoomChange, zoomReset } = useZoom(proxy);

  return { scale, zoomIn, zoomOut, zoomChange, zoomReset };
};

export default useZoomEffect;
