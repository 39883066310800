import React from 'react';

/* eslint-disable max-len */
const AttachSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_20334_13862)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.13604 7.58578C0.988152 9.73367 0.988152 13.2161 3.13604 15.364C5.28392 17.5118 8.76633 17.5118 10.9142 15.364L14.4819 11.7963L13.7748 11.0892L10.2071 14.6568C8.44974 16.4142 5.6005 16.4142 3.84314 14.6568C2.08578 12.8995 2.08578 10.0502 3.84314 8.29289L9.80304 2.33299C11.0025 1.13352 12.9472 1.13352 14.1467 2.33299C15.3462 3.53246 15.3462 5.47718 14.1467 6.67664L8.3521 12.4712C7.71052 13.1128 6.67032 13.1128 6.02874 12.4712C5.38717 11.8297 5.38717 10.7895 6.02874 10.1479L10.5239 5.65272L9.81682 4.94561L5.32164 9.44079C4.28954 10.4729 4.28954 12.1463 5.32164 13.1784C6.35374 14.2105 8.0271 14.2105 9.0592 13.1784L14.8538 7.38375C16.4438 5.79376 16.4438 3.21587 14.8538 1.62588C13.2638 0.0358903 10.6859 0.0358906 9.09594 1.62588L3.13604 7.58578Z"
        fill="#767B92"
      />
    </g>
    <defs>
      <clipPath id="clip0_20334_13862">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
/* eslint-enable max-len */

export default AttachSVG;
