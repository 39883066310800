import React from 'react';
import Checkbox from '../../../../Checkbox';
import CWSIconDropdown from '../../../../Dropdown/Icon';
import './style.css';
import {
  haveChildren,
  getGroupName,
  isClickable,
  getMembersCount,
  getCountTypeItem,
  isGroupSystem,
  getDepth,
  isDisclosed,
  isChecked,
  mapGroup,
  getChildren,
  isMenuShown,
  extraMenuPosition,
  extraMenuPossiblePositions,
  MAX_COUNT,
  getMenu,
  initOnDisclose,
  initOnClickCheckbox,
  isArrowShown,
  getArrowDirection,
  initOnRowSelect,
  getKey
} from '../../viewModel';
import { getCount } from '../../../utils';

const CATEGORY_ROOT_LEVEL = 1;
const CATEGORY_ROW_MARGIN = {
  // смотри результат в браузере
  BASE: 16, // когда нет детей и родителей и нет стрелки
  ROOT_LEVEL: 13, //  когда нет стрелки и мы на 0 уровне глубины
  CHILD_LEVEL: 19 // когда нет детей и у элемента есть родитель и нет стрелки
};

const getRowIndent = (group) => {
  const depth = getDepth(group);
  if (!isGroupSystem(group) && haveChildren(group))
    return CATEGORY_ROW_MARGIN.ROOT_LEVEL * depth;
  if (depth === CATEGORY_ROOT_LEVEL)
    return CATEGORY_ROW_MARGIN.CHILD_LEVEL * depth;
  return CATEGORY_ROW_MARGIN.BASE * depth;
};

const getQuantity = (group) => getCount(MAX_COUNT, getMembersCount(group));

const RenderLock = ({ group }) => {
  if (!isGroupSystem(group)) return null;
  return <img src="/img/lock-gray.svg" alt="lock" />;
};

const RenderArrow = ({ group }) => {
  if (!isArrowShown(group)) return null;
  return (
    <img
      src={`/img/chat-widget/arrow_${getArrowDirection(group)}.svg`}
      alt="arrow_toggler"
    />
  );
};

// eslint-disable-next-line no-shadow
const RowCheckbox = ({ group }) => (
  <div className="cwsa-category-lists__list-checkbox">
    <Checkbox checked={isChecked(group)} onClick={initOnClickCheckbox(group)} />
  </div>
);

const getCNName = (group) => {
  let className = `cwsa-category-lists__list-name`;
  if (isClickable(group))
    className += ' cwsa-category-lists__list-name_is-clickable';
  return className;
};

// eslint-disable-next-line no-shadow
const Name = ({ group }) => (
  <div
    className={getCNName(group)}
    style={{ marginLeft: getRowIndent(group) }}
    onClick={initOnDisclose(group)}>
    <RenderArrow group={group} />
    <span>{getGroupName(group)}</span>
  </div>
);

const Count = ({ group }) => (
  <div className="cwsa-category-lists__list-count">
    <img src={`/img/${getCountTypeItem(group)}-count-gray.svg`} alt="person" />
    <span>{getQuantity(group)}</span>
  </div>
);

const Menu = ({ group }) => {
  if (!isMenuShown(group)) return null;
  return (
    <div className="cwsa-category-lists__list-menu">
      <CWSIconDropdown
        fixedMenuPos={false}
        icon="extra"
        list={getMenu(group)}
        onSelect={initOnRowSelect(group)}
        position={extraMenuPosition}
        possiblePositions={extraMenuPossiblePositions}
      />
    </div>
  );
};

const getCNRowWrapper = (group) => {
  let className = `cwsa-category-lists__list-container`;
  if (isDisclosed(group))
    className += ' cwsa-category-lists__list-name_selected';
  return className;
};

const Row = ({ group }) => (
  <div className={getCNRowWrapper(group)}>
    <RowCheckbox group={group} />
    <Name group={group} />
    <RenderLock group={group} />
    <Count group={group} />
    <Menu group={group} />
  </div>
);

const ExpandableRow = ({ group }) => (
  <div>
    <Row group={group} />
    {isDisclosed(group) &&
      mapGroup(
        (child) => (
          <ExpandableRow group={child} key={getKey(child, 'row-child')} />
        ),
        getChildren(group)
      )}
  </div>
);

export default ExpandableRow;
