import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.css';
import Empty from './empty';
import EmptySearch from './empty-search';
import ContactList from './list';
import * as useCase from '../../../useCases';
import { generateContactsList, getShowMode } from './generator';
import Loader from '../../../../Loader';
import { getChatWidget } from '../../../getterCommon';
import CWSGroupList from './CWSGroupList';

const CWSContacts = ({ showMode, viewModel, handlerContactList, groupBy }) => {
  if (showMode === 'loading') {
    return <Loader theme="center" />;
  }

  if (showMode === 'empty') {
    return <Empty />;
  }

  if (showMode === 'no-search-result') {
    return <EmptySearch />;
  }

  if (showMode === 'list') {
    if (groupBy.length > 0) {
      return (
        <CWSGroupList
          viewModel={viewModel}
          handler={handlerContactList}
          groupBy={groupBy}
        />
      );
    }

    return <ContactList viewModel={viewModel} handler={handlerContactList} />;
  }

  return <div />;
};

export default connect(
  (state, ownProps) => ({
    groupBy: getChatWidget(state).groupBy,
    showMode: getShowMode(state),
    viewModel: generateContactsList(state, ownProps)
  }),
  (dispatch) => bindActionCreators(useCase, dispatch)
)(CWSContacts);
