import React from 'react';
import styled from 'styled-components';
import { Text } from '../../../../styledComponents/atoms';
import WarningIcon from './icon/warning';
import { useTranslate } from '../../../../TranslateProvider';

const CWNoInternet = () => {
  const t = useTranslate();

  return (
    <Wrapper>
      <Container>
        <IconWrapper>
          <WarningIcon />
        </IconWrapper>
        <TextWrapper>{t('No Internet connection')}</TextWrapper>
      </Container>
      <Input />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-flow: column;
`;

const Container = styled.div`
  height: calc(100% - 56px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

const Input = styled.div`
  height: var(--cw-header-height);
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.colors.gray.lightest};
`;

const TextWrapper = styled(Text)`
  color: ${(p) => p.theme.colors.gray.disabled};
  font-size: ${(p) => p.theme.font.size.normal};
`;

const IconWrapper = styled.div`
  margin-bottom: 8px;
`;

export default CWNoInternet;
