import React from 'react';
import Text from 'react-components/src/components/Typography';
import { getLastMessageDate } from '../../../../../helpers/date';
import './style.css';

const LastMessageDate = ({ language, time }) => {
  if (!time) return null;

  return (
    <Text
      level={2}
      lineHeight={3}
      weight={3}
      className="cw-chat-card-last-message-date">
      {getLastMessageDate(language, time)}
    </Text>
  );
};

export default LastMessageDate;
