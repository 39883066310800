import { useState, useEffect } from 'react';
import { startOfDay, endOfDay } from 'date-fns';
import useOutsideClick from '../hooks/outsideClick';

const useDatepickerProps = ({
  isShow = false,
  setShow = () => {},
  setDateRange,
  minDate,
  maxDate,
  datepickerRef
}) => {
  const [isOpen, setOpen] = useState(isShow);
  const [isShowDPStart, setShowDPStart] = useState(true);
  const [rangeBeforeApply, setRangeBeforeApply] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const defaultMinDate = minDate
    ? startOfDay(new Date(minDate))
    : new Date('1900');
  const defaultMaxDate = maxDate ? new Date(maxDate) : new Date();

  const clearDates = () => {
    setStartDate(null);
    setEndDate(null);
    setShowDPStart(true);
  };

  useEffect(() => {
    setOpen(isShow);
  }, [isShow]);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      if (rangeBeforeApply) {
        setStartDate(rangeBeforeApply[0]);
        setEndDate(rangeBeforeApply[1]);
      } else {
        clearDates();
      }
    }
  }, [isOpen]);

  const show = () => setOpen(true);
  const hide = () => setOpen(false);

  const onApply = () => {
    const formatedStartDate = startDate === null ? null : startOfDay(startDate);
    const formatedEndDate = endDate === null ? null : endOfDay(endDate);
    setDateRange([formatedStartDate, formatedEndDate]);
    setRangeBeforeApply([startDate, endDate]);
    hide();
  };

  useOutsideClick(datepickerRef, hide);

  const headerProps = {
    isShowDPStart,
    setShowDPStart,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    minDate: defaultMinDate,
    maxDate: defaultMaxDate,
    show
  };

  const bodyProps = {
    ...headerProps,
    onApply,
    clearDates
  };

  return { isOpen, headerProps, bodyProps };
};

export default useDatepickerProps;
