import React from 'react';

/* eslint-disable max-len */
function IconShare() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        d="M14.3496 18.7776L10.4338 14.8482C10.19 14.6045 10.19 14.208 10.4328 13.9653L14.3479 10.0679C14.5925 9.8244 14.5934 9.42869 14.3499 9.18406C14.1064 8.9394 13.7106 8.93856 13.466 9.18206L9.54983 13.0804C8.8188 13.8115 8.8188 15.001 9.54911 15.7313L13.4642 19.6599C13.5863 19.7825 13.7466 19.8437 13.9069 19.8437C14.0665 19.8437 14.2261 19.783 14.3481 19.6614C14.5926 19.4178 14.5933 19.0221 14.3496 18.7776Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        d="M19.3903 13.7812H12.7185C12.3733 13.7812 12.0935 14.061 12.0935 14.4062C12.0935 14.7514 12.3733 15.0312 12.7185 15.0312H19.3903C21.7941 15.0312 23.7497 16.9868 23.7497 19.3905C23.7497 21.7943 21.7941 23.7499 19.3903 23.7499C19.0451 23.7499 18.7653 24.0297 18.7653 24.3749C18.7653 24.7201 19.0451 24.9999 19.3903 24.9999C22.4834 24.9999 24.9997 22.4836 24.9997 19.3905C24.9997 16.2975 22.4834 13.7812 19.3903 13.7812Z"
        fill="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconShare;
