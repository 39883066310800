/* eslint-disable max-len */

import React from 'react';

const CopyIcon = ({ style = {} }) => (
  <svg
    style={style}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.88053 1.9565C9.76003 0.0769995 12.8073 0.0769995 14.6868 1.9565C16.5663 3.836 16.5663 6.88328 14.6868 8.76278L12.5794 10.8702L11.8723 10.1631L13.9797 8.05567C15.4687 6.5667 15.4687 4.15259 13.9797 2.66361C12.4907 1.17463 10.0766 1.17463 8.58764 2.66361L6.4802 4.77105L5.77309 4.06394L7.88053 1.9565ZM4.89342 6.35783L2.78598 8.46526C1.29701 9.95424 1.29701 12.3684 2.78598 13.8573C4.27496 15.3463 6.68907 15.3463 8.17805 13.8573L10.2855 11.7499L10.9926 12.457L8.88515 14.5644C7.00565 16.4439 3.95838 16.4439 2.07888 14.5644C0.199375 12.6849 0.199375 9.63766 2.07888 7.75816L4.18631 5.65072L4.89342 6.35783Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.669 4.97429C11.8643 5.16955 11.8643 5.48613 11.669 5.6814L5.7364 11.614C5.54114 11.8093 5.22456 11.8093 5.02929 11.614C4.83403 11.4188 4.83403 11.1022 5.02929 10.9069L10.9619 4.97429C11.1572 4.77903 11.4738 4.77903 11.669 4.97429Z"
      fill="#767B92"
    />
  </svg>
);

export default CopyIcon;
