import React from 'react';
import cn from 'classnames';
import { Chip, Typography } from '../../../../shared/components';
import classes from './EmailStatusList.module.css';
import { getStatus } from './status';

const Cell = (props) => {
  const { t, dataKey, cellData, cellClassName, rowHeight, rowData } = props;

  const status = getStatus(t, rowData.status);

  if (dataKey === 'company') {
    return (
      <div
        className={cn(classes.cellWrapper, cellClassName)}
        style={{ height: rowHeight }}>
        <div className={cn(classes.cell, classes.company)}>
          <Typography variant="body1Reg" className={classes.companyName}>
            {rowData.company}
          </Typography>
          <Chip
            className={classes.chip}
            label={status.label}
            bgColor={status.bgColor}
            textColor={status.textColor}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn(classes.cellWrapper, cellClassName)}
      style={{ height: rowHeight }}>
      <Typography variant="body1Reg" className={classes.cell}>
        {cellData}
      </Typography>
    </div>
  );
};

export default Cell;
