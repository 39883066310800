import React from 'react';
import Header from './header';
import Content from './content';
import './style.css';

const CWDelayedMessages = ({ config }) => (
  <div className="cw-delayed-messages">
    <Header config={config} />
    <Content config={config} />
  </div>
);

export default React.memo(CWDelayedMessages);
