import { has } from 'ramda';

export const getMemberRoleCase = ({ getMembersRoles }, { member }) => {
  const membersRoles = getMembersRoles();

  if (has(member.employeeId, membersRoles)) {
    return membersRoles[member.employeeId];
  }
  return member.memberRole;
};
