export const PlacementSides = {
  Left: 'left',
  Right: 'right'
};

export function getPlacement(side = PlacementSides.Left) {
  const placements = {
    right: {
      position: 'left-start',
      possiblePositions: [
        'left-center',
        'top-center',
        'right-center',
        'bottom-center'
      ]
    },
    left: {
      position: 'right-start',
      possiblePositions: ['right-center', 'top-center', 'left-center']
    }
  };

  return placements[side];
}
