import React from 'react';
import { useTranslate } from '../../../../../../TranslateProvider';

const NotFound = () => {
  const t = useTranslate();

  return (
    <div style={{ textAlign: 'center', marginTop: 50 }}>
      <img src="/img/ico_contacts.svg" alt="" />
      <div className="contacts-empty-text">{t('not_found')}</div>
    </div>
  );
};

export default NotFound;
