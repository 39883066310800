import { useEvent } from 'react-use';

export const useOnReachBottom = (ref, handler) => {
  const loadDataOnScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = ref.current;

    if (isAtBottom()) {
      handler();
    }

    function isAtBottom() {
      return Math.abs(scrollHeight - scrollTop - clientHeight) <= 3.0;
    }
  };

  useEvent('scroll', loadDataOnScroll, ref.current);
};
