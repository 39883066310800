const { append, assoc, uniq, includes } = require('ramda');
const { setMemberStatusOfList } = require('../channelMember');
const { EXCLUDED } = require('../memberStatus');

/**
 * @typedef {import('../channelMember')} ChannelMember
 * @typedef {import('../channel')} MainChannel
 * @typedef {import('../topic')} Topic
 * @typedef {(MainChannel|Topic)} MainChannel
 * @typedef {import('../message')} Message
 */

/**
 * Checks if exist member with employeeId
 *
 * @param {MainChannel} mainChannel
 * @param {number} employeeId
 * @returns {boolean}
 */
exports.isMember = (mainChannel, employeeId) => {
  const index = mainChannel.members.findIndex(
    (member) => member.employeeId === employeeId
  );
  return index !== -1;
};

/**
 * Find member by employeeId if exist
 *
 * @param {MainChannel} mainChannel
 * @param {number} employeeId
 * @returns {(null|ChannelMember)}
 */
exports.findMember = (mainChannel, employeeId) => {
  const index = mainChannel.members.findIndex(
    (member) => member.employeeId === employeeId
  );

  if (index === -1) return null;
  return mainChannel.members[index];
};

/**
 * Check if member is owner
 *
 * @param {MainChannel} mainChannel
 * @param {number} employeeId
 * @returns {boolean}
 */
const isOwner = (mainChannel, employeeId) =>
  mainChannel.ownerEmployeeId === employeeId;
exports.isOwner = isOwner;

/**
 * Gets owner member
 *
 * @param {MainChannel} mainChannel
 * @returns {ChannelMember}
 */
exports.getOwnerMember = (mainChannel) =>
  mainChannel.members.find((member) => isOwner(mainChannel, member.employeeId));

/**
 * Gets members without owner
 *
 * @param {MainChannel} mainChannel
 * @returns {[ChannelMember]}
 */
exports.getMembersWithoutOwner = (mainChannel) =>
  mainChannel.members.filter(
    (member) => !isOwner(mainChannel, member.employeeId)
  );

/**
 * Gets members without current employee
 *
 * @param {MainChannel} mainChannel
 * @param {number} employeeId
 * @returns {[ChannelMember]}
 */
exports.getMembersWithoutUser = (mainChannel, employeeId) =>
  mainChannel.members.filter((member) => member.employeeId !== employeeId);

/**
 * Add unread message to mainChannel
 *
 * @param {MainChannel} mainChannel
 * @param {string} messageId
 * @returns {MainChannel}
 */
exports.addUnreadMsg = (mainChannel, messageId) => {
  const updatedUnreadMsgId = append(messageId, mainChannel.unreadMessagesId);
  return assoc('unreadMessagesId', updatedUnreadMsgId, mainChannel);
};

/**
 * Check if is a unread message
 *
 * @param {MainChannel} mainChannel
 * @param {string} messageId
 * @returns {boolean}
 */
exports.containsUnreadMsg = (mainChannel, messageId) =>
  includes(messageId, mainChannel.unreadMessagesId);

/**
 * Clear unread message in mainChannel
 *
 * @param {MainChannel} mainChannel
 * @returns {MainChannel}
 */
exports.clearUnreadMsg = (mainChannel) =>
  assoc('unreadMessagesId', [], mainChannel);

/**
 * Checks if mainChannel archived
 *
 * @param {MainChannel} mainChannel
 * @returns {boolean}
 */
exports.isArchived = (mainChannel) => mainChannel.archived;

/**
 * Checks mainChannel notifications is off for member
 *
 * @param {MainChannel} mainChannel
 * @param {number} employeeId
 * @returns {boolean}
 */
const isMemberNotificationOff = (mainChannel, employeeId) =>
  mainChannel.notificationOffMembers.includes(employeeId);
exports.isMemberNotificationOff = isMemberNotificationOff;

/**
 * Checks mainChannel notifications is on for member
 *
 * @param {MainChannel} mainChannel
 * @param {number} employeeId
 * @returns {boolean}
 */
exports.isMemberNotificationOn = (mainChannel, employeeId) =>
  !isMemberNotificationOff(mainChannel, employeeId);

/**
 * Disable notification for member
 *
 * @param {MainChannel} mainChannel
 * @param {number} employeeId
 * @returns {boolean}
 */
exports.notifyOff = (mainChannel, employeeId) =>
  assoc(
    'notificationOffMembers',
    uniq(append(employeeId, mainChannel.notificationOffMembers)),
    mainChannel
  );

/**
 * Enable notification for member
 *
 * @param {MainChannel} mainChannel
 * @param {number} employeeId
 * @returns {boolean}
 */
exports.notifyOn = (mainChannel, employeeId) =>
  assoc(
    'notificationOffMembers',
    mainChannel.notificationOffMembers.filter((emId) => emId !== employeeId),
    mainChannel
  );

/**
 * Count how many unread my msg
 *
 * @param {MainChannel} chat
 * @param {number} employeeId
 * @returns {boolean}
 */
exports.countMyUnreadMsg = (chat, employeeId) => {
  if (chat.lastMessage !== null && chat.lastMessage.employeeId === employeeId) {
    return chat.unreadMessagesId.length;
  }
  return 0;
};

/**
 * Count how many unread not my msg
 *
 * @param {MainChannel} chat
 * @param {number} employeeId
 * @returns {boolean}
 */
exports.countNotMyUnreadMsg = (chat, employeeId) => {
  if (chat.lastMessage !== null) {
    const { lastMessage } = chat;

    if (
      lastMessage.type === 'MsgSupport' &&
      lastMessage.msg === 'answerphone'
    ) {
      return 0;
    }

    if (lastMessage.employeeId !== employeeId) {
      return chat.unreadMessagesId.length;
    }
  }
  return 0;
};

/**
 * Set members to chat
 *
 * @param {MainChannel} chat
 * @param {[ChannelMember]} members
 * @returns {MainChannel}
 */
exports.setMembers = (chat, members) => assoc('members', members, chat);

/**
 * Add members to chat
 *
 * @param {MainChannel} chat
 * @param {[ChannelMember]} members
 * @returns {MainChannel}
 */
exports.addMembers = (chat, members) =>
  assoc('members', chat.members.concat(members), chat);

/**
 * Exclude members from chat by employee ids
 *
 * @param {MainChannel} chat
 * @param {[number]} excludeMembersIds
 * @returns {MainChannel}
 */
exports.excludeMembersById = (chat, excludeMembersIds) => {
  const members = setMemberStatusOfList(
    chat.members,
    excludeMembersIds,
    EXCLUDED
  );

  return assoc('members', members, chat);
};

/**
 * Set last message to chat
 *
 * @param {MainChannel} chat
 * @param {(null|Message)} lastMessage
 * @returns {MainChannel}
 */
exports.setLastMessage = (chat, lastMessage) =>
  assoc('lastMessage', lastMessage, chat);

/**
 * Set channel name
 *
 * @param {MainChannel} chat
 * @param {string} name
 * @returns {Topic}
 */
exports.setName = (chat, name) => assoc('name', name, chat);

/**
 * Set checkedLists
 *
 * @param {MainChannel} chat
 * @param {Array} checkedLists
 * @returns {Topic}
 */
exports.setCheckedLists = (chat, checkedLists) =>
  assoc('checkedLists', checkedLists, chat);

/**
 * Set creator
 *
 * @param {MainChannel} chat
 * @param {ChannelMember} creator
 * @returns {Topic}
 */
exports.setCreator = (chat, creator) => assoc('creator', creator, chat);
