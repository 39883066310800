import { trans } from '../../../../modules/translate';
import { formatListsToHierarchicalView } from '../../utils';
import {
  includesItem,
  isHidden,
  isSelectedAll,
  isShown
} from '../../data-type/block-group';

export const extraMenuPosition = 'left-start';
export const extraMenuPossiblePositions = ['left-center'];
export const MAX_COUNT = 99;
export const MAX_DEPTH = 5;

export const getMenuList = (depth, maxDepth = MAX_DEPTH) => {
  const list = [{ icon: 'edit', value: 'edit-group' }];
  if (depth < maxDepth) {
    list.push({ icon: 'add-folder', value: 'create-subgroup' });
  }
  list.push({ icon: 'delete', value: 'delete-group', color: 'red' });
  return list;
};

export const isGroupSystem = (group) => group.system;
export const isSelected = (group) => group.isSelected;
export const getDepth = (group) => group.depth;
export const getID = (group) => group.id;
export const getKey = (group, prefix) => `${prefix}-${group.id}`;

export const getGroupName = (group) =>
  isGroupSystem(group) ? trans(group.name, group) : group.name;

export const haveChildren = (group) => group.childsIds.length > 0;
export const canDisclose = (group) =>
  !isGroupSystem(group) && haveChildren(group);

export const isDisclosed = (group) => isSelected(group);

export const createArrow = (group) => ({
  isShown: !isGroupSystem(group) && haveChildren(group),
  direction: isDisclosed(group) ? 'down' : 'right'
});

export const isArrowShown = (group) => createArrow(group).isShown;
export const getArrowDirection = (group) => createArrow(group).direction;

export const isChecked = (group) => group.isChecked;

export const getCountTypeItem = (group) => group.iconCount;
export const getMembersCount = (group) => group.count;

export const mapGroup = (fn, group) => group.map((g) => fn(g));

export const isMenuShown = (group) => !isGroupSystem(group);

export const getMenu = (group) => getMenuList(getDepth(group), MAX_DEPTH);

export const getChildren = (group) => group.childsList;

export const isClickable = (group) => haveChildren(group);

export const initOnClickCheckbox = (group) => group.onClickCheckbox;

export const initOnRowSelect = (group) => group.onRowSelect;

export const initOnDisclose = (group) =>
  canDisclose(group) ? group.onDisclose : undefined;

export const prepareGroup = ({
  t,
  group: currentGroup,
  selected = [],
  handler,
  onSelect,
  iconCount
}) => {
  const enrichedList = currentGroup.list.map((group) => ({
    t,
    ...group,
    iconCount,
    selected,
    isSelected: selected.includes(group.id),
    isChecked: includesItem(currentGroup, group),
    onDisclose: () => onSelect(getID(group)),
    onClickCheckbox: () => handler('toggle-select', group),
    onRowSelect: (item) => handler(item, group),
    onSelect
  }));
  return formatListsToHierarchicalView(enrichedList);
};

// eslint-disable-next-line consistent-return
export const getCurrentGroup = (groupChats, groupContacts) => {
  if (isShown(groupChats)) return groupChats;
  if (isShown(groupContacts)) return groupContacts;
};

const isHiddenGroups = (groupChats, groupContacts) =>
  isHidden(groupContacts) && isHidden(groupChats);

const makeHeaderTitle = (header) => {
  if (header.kind === 'chat') return 'lists';
  if (header.kind === 'chat-archive') return 'lists';
  if (header.kind === 'contact') return 'contactLists';
  throw new Error('There are no visible groups');
};

export const getHeader = (vm) => vm.header;
export const getCloseSidebarHandler = (vm) => vm.closeSidebarHandler;
export const isCheckedAll = (vm) => getHeader(vm).getCheckedAll;
export const initSetCheckedAll = (vm) => getHeader(vm).setCheckedAll;
export const initOnAddList = (vm) => getHeader(vm).onAddList;
export const isHeaderDisabled = (vm) => getHeader(vm).disabled;
export const getHeaderTitle = (vm) => getHeader(vm).title;

const generateModelHeader = (group, handler) => {
  if (isShown(group)) {
    return {
      disabled: false,
      title: makeHeaderTitle(group),
      getCheckedAll: isSelectedAll(group),
      setCheckedAll: () => handler('toggle-select-all'),
      onAddList: () => handler('create-group')
    };
  }
  throw new Error('There are no visible groups');
};

export const getList = (vm) => vm.list;
export const isCategoriesHidden = (vm) => vm.isHiddenGroups;

export const generateViewModel = ({
  t,
  groupChats,
  groupContacts,
  selected,
  group,
  handler,
  closeSidebarHandler,
  onSelect,
  iconCount
}) => ({
  header: generateModelHeader(group, handler),
  list: prepareGroup({ t, group, selected, handler, onSelect, iconCount }),
  closeSidebarHandler,
  isHiddenGroups: isHiddenGroups(groupChats, groupContacts)
});

export const makeCategoriesVM = ({
  t,
  groupContacts,
  groupChats,
  selected,
  onSelect,
  actions = {}
}) => {
  const { handleGroupContacts, handleGroupChats } = actions;

  const currentGroup = getCurrentGroup(groupChats, groupContacts);
  if (!currentGroup)
    return { isHiddenGroups: isHiddenGroups(groupChats, groupContacts) };

  const getCloseHandler = () => {
    if (isShown(groupChats)) return actions.handleChatBtnClick;
    if (isShown(groupContacts)) return actions.handleContactBtnClick;
    throw new Error('There are no visible groups');
  };
  const closeSidebarHandler = getCloseHandler();

  const getHandlerGroup = () => {
    if (isShown(groupChats)) return handleGroupChats;
    if (isShown(groupContacts)) return handleGroupContacts;
    throw new Error('There are no visible groups');
  };
  const handler = getHandlerGroup();

  const getIconCount = () => {
    if (isShown(groupChats)) return 'dialog';
    if (isShown(groupContacts)) return 'person';
    throw new Error('There are no visible groups');
  };

  const iconCount = getIconCount();

  return generateViewModel({
    t,
    groupChats,
    groupContacts,
    selected,
    group: currentGroup,
    handler,
    closeSidebarHandler,
    onSelect,
    iconCount
  });
};
