import React from 'react';
import Row from './Row';
import { getUsers, initOnClick, makeRowsVM } from './dataType';

const Rows = ({ contacts, onClick }) => {
  const vm = makeRowsVM({ users: contacts, onClick });

  return getUsers(vm).map(({ name, company, avatar = null, employeeId }) => (
    <Row
      key={employeeId}
      employeeId={employeeId}
      avatar={avatar}
      name={name}
      company={company}
      onClick={initOnClick(vm, employeeId)}
    />
  ));
};

export default Rows;
