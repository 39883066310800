import React, { useRef } from 'react';
import { useLayer } from 'react-laag';

import useDatepickerProps from './datepickerProps';
import DatePickHeader from './components/datepickerHeader';
import DatePickRange from './components/datepickerRange';

const PickDateWithOverlay = (props) => {
  const datepickerRef = useRef(null);
  const { isOpen, headerProps, bodyProps } = useDatepickerProps({
    ...props,
    datepickerRef
  });
  const { layerProps, triggerProps, renderLayer } = useLayer({
    isOpen,
    placement: 'bottom-center',
    possiblePlacements: [
      'top-center',
      'left-center',
      'bottom-center',
      'right-center'
    ],
    auto: true,
    fixed: true,
    triggerOffset: 0
  });

  return (
    <div className="datepicker-goodwix">
      <div
        style={{ position: 'relative' }}
        onClick={headerProps.show}
        {...triggerProps}>
        <DatePickHeader {...headerProps} />
      </div>
      {isOpen &&
        renderLayer(
          <div
            ref={layerProps.ref}
            style={{ ...layerProps.style, zIndex: 1301 }}>
            <DatePickRange {...bodyProps} reference={datepickerRef} />
          </div>
        )}
    </div>
  );
};

export default PickDateWithOverlay;
