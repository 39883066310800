import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslate } from '../../../../TranslateProvider';
import * as getters from './getters';
import DasboardCommon from '../../main/dashboard-common';
import Button from '../../ButtonIcon';
import ButtonBack from '../../ButtonBack';
import * as useCase from '../../useCases';

const BtnBack = ({ theme, onClick }) => {
  if (theme === 'widget') {
    return <ButtonBack onClick={onClick} />;
  }
  return <Button icon="arrow-left" tooltip="all-messages" onClick={onClick} />;
};

const CWDelayedMessagesHeader = ({ config }) => {
  const t = useTranslate();
  const { theme } = config;

  const messages = useSelector(getters.getDelayMessagesList);
  const amountLeft = useSelector(getters.getDelayMessagesAmountLeft);
  const handler = bindActionCreators(
    useCase.handleDelayedMessages,
    useDispatch()
  );

  const backToChat = () => {
    handler('back-to-chat');
  };

  const total = messages.length + amountLeft;

  return (
    <DasboardCommon theme={config.commonDashboardTheme}>
      <div
        className={`cw-delayed-messages-header cw-delayed-messages-header_theme_${theme}`}>
        <BtnBack theme={theme} onClick={backToChat} />
        <div className="cw-delayed-messages-header__text">{`${t(
          'delayedMessages.title',
          { total }
        )}`}</div>
      </div>
    </DasboardCommon>
  );
};

export default CWDelayedMessagesHeader;
