import { compareDesc } from 'date-fns';
import { isNil } from 'ramda';
import { DEFAULT_TIME, TIME_SEPARATOR } from './config';

export function padStart(value, sign, length) {
  if (typeof value === 'string' || typeof value === 'number') {
    return value.toString().padStart(length, sign);
  }

  return '';
}

export function getInitTime(minTime) {
  return !isNil(minTime) ? getTime(minTime) : DEFAULT_TIME;
}

export function getTime(date) {
  const hh = padStart(new Date(date).getHours(), '0', 2);
  const mm = padStart(new Date(date).getMinutes(), '0', 2);

  return `${hh}${TIME_SEPARATOR}${mm}`;
}

export function isFirstDateGTE(firstDate, nextDate) {
  return compareDesc(new Date(firstDate), new Date(nextDate)) < 1;
}
