import assert from 'assert';
import { isNil } from 'ramda';
import { defStruct, getType } from 'core/lib';
import { implies } from '../../utils/asserts';

export const trans = (key, ...args) => {
  // Kind of args
  // - (key, viewModel)
  // - (key, options, viewModel)

  assert(args.length > 0, 'Arguments should be greater 0');
  assert(args.length <= 2, 'Arguments should be less or equal 2');

  return translateExec(key, getOptions());

  function translateExec(keyName, options) {
    return getViewModel().t(keyName, options);
  }

  function getViewModel() {
    if (args.length === 1) {
      return args[0];
    }
    if (args.length === 2) {
      return args[1];
    }
    return undefined;
  }

  function getOptions() {
    if (args.length === 2) {
      return args[0];
    }
    return undefined;
  }
};

const { makeMockTrans, getKey, getOptions, isMockTrans } = defStruct(
  'MockTrans',
  ['key', 'options']
);

export const mockTrans = (key, options) => makeMockTrans(key, options);

const assertType = (mTrans) =>
  assert(
    isMockTrans(mTrans),
    `Expect pass type MockTrans but pass ${getType(mTrans)}`
  );
const assertOptions = (mTrans, expectedOptions) => {
  const actual = getOptions(mTrans);
  assert(
    implies(isNil(expectedOptions), isNil(actual)),
    'expectedOptions should be undefined'
  );
  assert(
    implies(!isNil(expectedOptions), !isNil(actual)),
    "MockTrans options shouldn't be undefined"
  );
  assert.deepEqual(actual, expectedOptions);
};

export const assertTrans = (mTrans, expectedOptions) => {
  assertType(mTrans);
  assert(!isNil(getKey(mTrans)));
  assertOptions(mTrans, expectedOptions);
};

export const assertTransKey = (mTrans, expectedKey, expectedOptions) => {
  assertType(mTrans);
  assert.equal(getKey(mTrans), expectedKey);
  assertOptions(mTrans, expectedOptions);
};
