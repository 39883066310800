import React from 'react';

export default class FileSelectWrap extends React.Component {
  onClick(event) {
    event.preventDefault();

    this._fileInput.click();
  }

  onChange(files) {
    const { onChange } = this.props;

    onChange(files);
    this._fileInput.value = null;
  }

  setInputRef = (inputRef) => {
    this._fileInput = inputRef;
  };

  render() {
    const {
      accept,
      multiple = true,
      component,
      style,
      disabled = false,
      className
    } = this.props;

    return (
      <div className={className} style={style}>
        {component({
          onClick: this.onClick.bind(this)
        })}
        <input
          type="file"
          style={{ display: 'none' }}
          accept={accept}
          multiple={multiple}
          disabled={disabled}
          ref={this.setInputRef}
          onChange={(e) => this.onChange(e.target.files)}
        />
      </div>
    );
  }
}
