import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { bindActionCreators } from 'redux';
import AttachFileMenu from './AttachFiles';
import Attachments from '../../../../Channel/attachments/Attachments';
import { getNotRemovedFiles } from '../getters';
import {
  makeFilesVM,
  getFiles,
  isBlockedFunctions,
  isShowFilesPreview,
  initDeleteFile
} from './dataType';
import { haveFreeStorageSpace } from '../../../../../storeGetters';

import * as useCases from '../../../modules/topics/useCases';

const wrapper = (editMode, chatId) =>
  makeFilesVM({
    editMode,
    chatId,
    files: useSelector(getNotRemovedFiles(editMode)),
    haveFreeSpace: useSelector(haveFreeStorageSpace),
    onDelete: bindActionCreators(useCases.deleteFile, useDispatch())
  });

const Files = ({ editMode, chatId }) => {
  const vm = wrapper(editMode, chatId);

  return (
    <div className="cw-create-topic__files">
      {!isBlockedFunctions(vm) && (
        <div className="cw-create-topic__files_attach">
          <AttachFileMenu chatId={chatId} />
        </div>
      )}
      <div className="cw-create-topic__files_preview">
        {isShowFilesPreview(vm) && (
          <div className="cw-input-msg__input-files">
            <Attachments files={getFiles(vm)} onDelete={initDeleteFile(vm)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Files;
