import { PrintStatus } from './PrintStatus';

export const Print = ({ enableShortcuts, store, children }) => {
  const print = () => {
    store.update('printStatus', PrintStatus.Preparing);
  };

  return children({
    enableShortcuts,
    onClick: print
  });
};
