import React from 'react';
import './style.css';

function RoundCheckbox({ id, value, onClick, disabled = false }) {
  return (
    <input
      className="round-checkbox"
      id={id}
      type="checkbox"
      value={value}
      onClick={onClick}
      disabled={disabled}
    />
  );
}

export default RoundCheckbox;
