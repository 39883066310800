import React, { useMemo, useRef } from 'react';
import ChatItem from '../../../Components/Cards/Chat';
import useChunkLoaderByScroll from '../../../hooks/chunkLoadBlockListByScroll';
import Scrollbox from '../../../../Scrollbox';
import '../chats/style.css';

const CWSChatList = ({ viewModel, handler }) => {
  const listRef = useRef(null);

  useChunkLoaderByScroll({
    ref: listRef,
    blockList: viewModel,
    onLoad: () => handler('load-more')
  });

  const memoizedHandler = useMemo(() => handler, []);

  return (
    <Scrollbox className="cws-chat-list" ref={listRef}>
      {viewModel.list.map((item) => (
        <ChatItem key={item.key} {...item} handler={memoizedHandler} />
      ))}
    </Scrollbox>
  );
};

export default CWSChatList;
