import React, { forwardRef, useEffect, useState } from 'react';
import cn from 'classnames';
import { useIsScrolling } from '@hooks/useIsScrolling';
import GeneralScrollbox from '../../../../../Scrollbox';

const TIME_FOR_INITIALIZE = 1000;
const DELAY_SHOW_DATE_BAR = 3000;

const ScrollboxComponent = (
  { id, chatId, className, style, children },
  ref
) => {
  const [initialized, setInitialized] = useState(false);
  const isScrolling = useIsScrolling(ref, DELAY_SHOW_DATE_BAR);

  useEffect(() => {
    setInitialized(false);

    const timer = setTimeout(() => {
      setInitialized(true);
    }, TIME_FOR_INITIALIZE);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [chatId]);

  useEffect(() => {
    if (!initialized) {
      ref.current.style.overflow = 'hidden';
    } else {
      ref.current.style.overflow = 'auto';
    }
  }, [initialized]);

  const showDateBar = initialized && isScrolling;

  return (
    <GeneralScrollbox
      ref={ref}
      id={id}
      className={cn(className, showDateBar && `${className}__date-bar_visible`)}
      style={style}>
      {children}
    </GeneralScrollbox>
  );
};

export const Scrollbox = forwardRef(ScrollboxComponent);
