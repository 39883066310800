import { useCallback, useRef } from 'react';

export default function useScrollMode(initialValue) {
  const scrollMode = useRef(initialValue);

  const setScrollMode = useCallback((mode) => {
    scrollMode.current = mode;
  }, []);

  return [scrollMode, setScrollMode];
}
