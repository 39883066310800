.container {
  box-sizing: border-box;
  width: 100%;

  :global(.MuiButtonBase-root) {
    text-transform: none;
  }

  :global(.MuiButtonBase-root.Mui-disabled) > span {
    color: var(--color-gray-600);
  }

  .tabs {
    margin-bottom: 20px;
  }

  .title {
    color: var(--color-gray-800);
  }

  .delimiter {
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 0;
    background-color: var(--color-gray-500);
  }

  :global(.MuiTabs-indicator) {
    height: 2px;
    background-color: var(--color-gray-900);
  }
}
