import * as modalActions from '../../../action-creators/modal';
import { attachUnsentFileFromStorage } from '../modules/messageInput/storage';
import { ac, setMsgToChannel } from './index';
import { noop } from '../../../utils/utils';

const handleResend = (file) => (dispatch) => {
  dispatch(
    modalActions.showModal('CHATS_LIST', {
      onClick,
      onCancel: noop,
      mode: 'all',
      title: 'modalSendPRToChat.title',
      placeholder: 'modalSendPRToChat.placeholder'
    })
  );

  async function onClick({ id }) {
    await attachUnsentFileFromStorage(id, file.id);
    await dispatch(setMsgToChannel(id, ''));
    await dispatch(modalActions.hideModalDialog());
    dispatch(ac.setInputFiles([file]));
  }
};

export default handleResend;
