import React from 'react';

const Warning = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18">
    <use fill="#FFF" xlinkHref="#a" />
    <use fill="#00CEE0" transform="translate(4.67 4.5)" xlinkHref="#b" />
    <use fill="#00CEE0" transform="rotate(90 .9 7.6)" xlinkHref="#c" />
    <use fill="#00CEE0" transform="translate(8.5 10)" xlinkHref="#d" />
    <defs>
      <path id="a" d="M18 9A9 9 0 1 1 0 9a9 9 0 0 1 18 0z" />
      <path
        id="b"
        d="M4.33 0l.433-.25a.5.5 0 0 0-.866 0L4.33 0zm4.33 7.5V8a.5.5 0 0 0 .433-.75l-.433.25zM0 7.5l-.433-.25A.5.5 0 0 0 0 8v-.5zM3.897.25l4.33 7.5.866-.5-4.33-7.5-.866.5zM8.66 7H0v1h8.66V7zm-8.227.75l4.33-7.5-.866-.5-4.33 7.5.866.5z" // eslint-disable-line
      />
      <path
        id="c"
        d="M.5-1a.5.5 0 0 0 0 1v-1zm2 1a.5.5 0 0 0 0-1v1zm-2 0h2v-1h-2v1z"
      />
      <path id="d" d="M1 .5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
    </defs>
  </svg>
);

export default Warning;
