import { useCallback, useMemo, useState } from 'react';
import { setData } from '../../../../../storage/lib/LS';

const DEFAULT_SIZE = { width: '100%', height: 'auto' };

const styleRnd = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'transparent',
  position: 'relative',
  padding: '17px 0 8px'
};

const generateConfig = (size) => ({
  size,
  style: styleRnd,
  enableResizing: { top: false },
  resizeHandleStyles: { top: true },
  disableDragging: true,
  cursor: 'default',
  dragAxis: 'none',
  minWidth: '100%',
  maxWidth: '100%',
  minHeight: 'var(--cw--messenger-height)',
  maxHeight: '40vh'
});

export const useResizeLogic = ({ ls = {} } = {}) => {
  const [itemSize, setItemSize] = useState(DEFAULT_SIZE);

  const getWidgetSize = (resizeRef) => ({
    width: resizeRef.offsetWidth,
    height: resizeRef.offsetHeight
  });

  const saveSize = (size) => {
    if (ls.key) {
      setData(ls.key, size);
    }
  };

  const rndConfig = useMemo(() => generateConfig(itemSize), [itemSize]);

  const setDefaultSize = useCallback(() => {
    setItemSize(DEFAULT_SIZE);
  }, []);

  const onResize = useCallback((_, __, resizeRef) => {
    setItemSize(getWidgetSize(resizeRef));
  }, []);

  const onResizeStop = useCallback((_, __, inputRef) => {
    saveSize(getWidgetSize(inputRef));
  }, []);

  return {
    onResize,
    onResizeStop,
    setDefaultSize,
    rndConfig
  };
};
