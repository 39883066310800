import React from 'react';

function IconFitIn() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        className="cws-icon-main-stroke"
        x="0.5"
        y="0.5"
        width="33"
        height="33"
        rx="16.5"
        stroke="white"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 15L9 15L9 14L14 14L14 9L15 9L15 15Z"
        fill="white"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 19L9 19L9 20L14 20L14 25L15 25L15 19Z"
        fill="white"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 15L25 15L25 14L20 14L20 9L19 9L19 15Z"
        fill="white"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 19L25 19L25 20L20 20L20 25L19 25L19 19Z"
        fill="white"
      />
    </svg>
  );
}

export default IconFitIn;
