import React, { useEffect, useState } from 'react';
import { PdfJsApi } from '../vendors/PdfJsApi';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { LoadingState } from './LoadingState';
import { FailureState } from './FailureState';
import { CompletedState } from './CompletedState';
import { useSpinner } from '../hooks/useSpinner';

const DefaultErrorComponent = ({ error }) => (
  <div className="pdf-viewer__doc-error">{error.text}</div>
);

export const DocumentLoader = ({
  file,
  render,
  renderError,
  withCredentials = false
}) => {
  const [status, setStatus] = useState(new LoadingState(0));
  const [loadedDocument, setLoadedDocument] = useState(null);
  const [percentages, setPercentages] = useState(0);
  const isMounted = useIsMounted();
  const { renderSpinner } = useSpinner();

  useEffect(() => {
    const worker = new PdfJsApi.PDFWorker({ name: `PDFWorker_${Date.now()}` });

    const params = {
      worker,
      withCredentials,
      ...(typeof file === 'string' ? { url: file } : { data: file })
    };

    const loadingTask = PdfJsApi.getDocument(params);

    loadingTask.onProgress = (progress) => {
      if (!isMounted.current) return;

      if (progress.total > 0) {
        setPercentages(Math.min(100, (100 * progress.loaded) / progress.total));
      } else {
        setPercentages(100);
      }
    };

    loadingTask.promise.then(
      (doc) => isMounted.current && setLoadedDocument(doc),
      (err) =>
        isMounted.current &&
        !worker.destroyed &&
        new FailureState({
          message: err.message || 'Cannot load document',
          name: err.name
        })
    );

    return () => {
      loadingTask.destroy();
      worker.destroy();
    };
  }, [file]);

  useEffect(() => {
    if (isMounted.current) {
      if (percentages === 100 && loadedDocument) {
        setStatus(new CompletedState(loadedDocument));
      } else {
        setStatus(new LoadingState(percentages));
      }
    }
  }, [percentages, loadedDocument]);

  if (status instanceof CompletedState) {
    return render(status.doc);
  }

  if (status instanceof FailureState) {
    return renderError ? (
      renderError(status.error)
    ) : (
      <DefaultErrorComponent error={status.error} />
    );
  }

  return <div className="pdf-viewer__doc-loading">{renderSpinner()}</div>;
};
