import React from 'react';

/* eslint-disable max-len */
const IconDownload = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.56119 0.757489C5.69518 0.998948 5.60805 1.30331 5.36659 1.43729C3.0596 2.71744 1.5 5.17698 1.5 8.00009C1.5 12.1422 4.85786 15.5001 9 15.5001C13.1421 15.5001 16.5 12.1422 16.5 8.00009C16.5 5.17698 14.9404 2.71744 12.6334 1.43729C12.3919 1.30331 12.3048 0.998948 12.4388 0.757489C12.5728 0.51603 12.8772 0.428905 13.1186 0.562891C15.7304 2.0122 17.5 4.79915 17.5 8.00009C17.5 12.6945 13.6944 16.5001 9 16.5001C4.30558 16.5001 0.5 12.6945 0.5 8.00009C0.5 4.79915 2.26955 2.0122 4.88139 0.562891C5.12285 0.428905 5.42721 0.51603 5.56119 0.757489Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0.500091C9.27614 0.500091 9.5 0.723949 9.5 1.00009V11.0001C9.5 11.2762 9.27614 11.5001 9 11.5001C8.72386 11.5001 8.5 11.2762 8.5 11.0001V1.00009C8.5 0.723949 8.72386 0.500091 9 0.500091Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4751 6.76809C13.6704 6.96335 13.6704 7.27993 13.4751 7.47519L9.35355 11.5967C9.15829 11.792 8.84171 11.792 8.64645 11.5967L4.5249 7.47519C4.32964 7.27993 4.32964 6.96335 4.5249 6.76809C4.72016 6.57282 5.03674 6.57282 5.23201 6.76809L9 10.5361L12.768 6.76809C12.9633 6.57282 13.2798 6.57282 13.4751 6.76809Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconDownload;
