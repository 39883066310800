import { isNil } from 'ramda';
import { PUSH_ENDPOINT_KEY } from '../constants';
import { getCookie } from '../../../../../lib/cookie';

export const changeMutedCase = async (
  { getCurrentUserId, changeMuted },
  { isMuted }
) => {
  const currentUserId = getCurrentUserId();
  const endpoint = getCookie(`${PUSH_ENDPOINT_KEY}-${currentUserId}`);
  if (!isNil(endpoint)) {
    await changeMuted(endpoint, isMuted);
  }
};
