import React, { useEffect, useState } from 'react';
import { Spinner } from '../../../components/Spinner/Spinner';
import { CoverInner } from './CoverInner';

export const Cover = ({ getPageIndex, renderSpinner, store }) => {
  const [currentDoc, setCurrentDoc] = useState(store.get('doc'));

  const handleDocumentChanged = (doc) => {
    setCurrentDoc(doc);
  };

  useEffect(() => {
    store.subscribe('doc', handleDocumentChanged);

    return () => {
      store.unsubscribe('doc', handleDocumentChanged);
    };
  });

  return (
    <div className="pdf-viewer-thumbnail__cover">
      {currentDoc ? (
        <CoverInner
          doc={currentDoc}
          renderSpinner={renderSpinner}
          getPageIndex={getPageIndex}
        />
      ) : (
        <div className="pdf-viewer-thumbnail__cover-loader">
          {renderSpinner ? renderSpinner() : <Spinner />}
        </div>
      )}
    </div>
  );
};
