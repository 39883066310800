import React, { useMemo, useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { Zoom } from './Zoom';
import { createStore } from '../../store/createStore';
import { useZoom } from './useZoom';
import * as zoomingLevel from './zoomingLevels';
import { SpecialZoomLevel } from '../../constants';

export const zoomPlugin = ({ enableShortcuts = true } = {}) => {
  const store = useMemo(() => createStore({}), []);
  const { scale } = useZoom(store);
  const scaleRef = useRef(scale);

  useIsomorphicLayoutEffect(() => {
    scaleRef.current = scale;
  }, [scale]);

  const ZoomDecorator = (props) => <Zoom {...props} store={store} />;

  const zoomTo = (newScale) => {
    const zoom = store.get('zoom');
    if (zoom) {
      zoom(newScale);
    }
  };

  const zoomIn = () => {
    const zoom = store.get('zoom');
    if (zoom) {
      const newLvl = zoomingLevel.increase(scaleRef.current);
      zoom(newLvl);
    }
  };

  const zoomOut = () => {
    const zoom = store.get('zoom');
    if (zoom) {
      const newLvl = zoomingLevel.decrease(scaleRef.current);
      zoom(newLvl);
    }
  };

  const zoomDefaultPage = () => zoomTo(SpecialZoomLevel.DefaultSize);
  const zoomPageFit = () => zoomTo(SpecialZoomLevel.PageFit);
  const zoomPageWidth = () => zoomTo(SpecialZoomLevel.PageWidth);

  return {
    install: (methods) => {
      store.update('zoom', methods.zoom);
    },
    onViewerStateChange: (viewerState) => {
      store.update('scale', viewerState.scale);
      return viewerState;
    },
    scale: scaleRef.current,
    zoomTo,
    zoomIn,
    zoomOut,
    zoomDefaultPage,
    zoomPageFit,
    zoomPageWidth,
    Zoom: ZoomDecorator
  };
};
