import { useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

export const useIntersectionObserver = ({
  once,
  threshold = 0,
  onVisibilityChanged
}) => {
  const containerRef = useRef(null);

  useIsomorphicLayoutEffect(() => {
    const { current: container } = containerRef;

    if (!container) {
      return;
    }

    const intersectionTracker = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isVisible = entry.isIntersecting;
          const ratio = entry.intersectionRatio;
          onVisibilityChanged({ isVisible, ratio });

          if (isVisible && once) {
            intersectionTracker.unobserve(container);
            intersectionTracker.disconnect();
          }
        });
      },
      { threshold }
    );

    intersectionTracker.observe(container);

    return () => {
      intersectionTracker.unobserve(container);
      intersectionTracker.disconnect();
    };
  }, []);

  return containerRef;
};
