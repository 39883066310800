import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hasPath } from 'ramda';
import * as useCase from '../../../../useCases';
import Attachments from '../../../../../Channel/attachments/Attachments';
import { convertFileUrls } from '../../../../modules/files/utils';

const CWMMFiles = ({
  chat,
  message,
  isReadMsg,
  isOwnMsg = false,
  handleMessageClick,
  theme,
  onLoadedSingleImage,
  filesData
}) => {
  const getMenu = useCallback(
    (file, allowDelete) => {
      if (isOwnMsg) {
        const fileMenu = [
          {
            btnText: 'Download',
            onClick: () =>
              handleMessageClick('download-file', chat, message, file)
          }
        ];
        if (allowDelete) {
          fileMenu.push({
            btnText: 'Delete',
            onClick: () =>
              handleMessageClick('delete-file', chat, message, file)
          });
        }
        return fileMenu;
      }
      return [
        {
          btnText: 'Download',
          onClick: () =>
            handleMessageClick('download-file', chat, message, file)
        },
        {
          btnText: 'Add_to_storage',
          onClick: () =>
            handleMessageClick('attach-storage', chat, message, file)
        }
      ];
    },
    [chat]
  );

  const getAvatar = useCallback(
    () => (!message.avatarSrc ? '/img/user.svg' : message.avatarSrc),
    [message]
  );
  const onDelete = useCallback(
    (msgId, file) => handleMessageClick('delete-file', chat, message, file),
    [chat, message]
  );
  const onCopyToStorage = useCallback(
    (id) => handleMessageClick('attach-storage', chat, message, { id }),
    [chat, message]
  );

  function isShowSingleImage() {
    return hasPath(['files'], message) ? message.files.length === 1 : false;
  }

  return (
    <Attachments
      getAvatar={getAvatar}
      messageId={message.id}
      files={convertFileUrls(message.files)}
      allowDelete={isOwnMsg && !isReadMsg}
      onDelete={onDelete}
      onCopyToStorage={onCopyToStorage}
      mode="menu"
      getMenu={getMenu}
      onLoadSingleImage={onLoadedSingleImage}
      showSingleImage={isShowSingleImage()}
      members={chat.members}
      theme={theme}
      filesData={filesData}
    />
  );
};

export default connect(null, (dispatch) =>
  bindActionCreators(useCase, dispatch)
)(CWMMFiles);
