import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { useTranslate } from '../../../../../TranslateProvider';

import DashboardCommon from '../../dashboard-common';

import * as useCase from '../../../useCases';

import { getSelectedMessagesCount } from '../../../getter';
import ButtonIcon from '../../../ButtonIcon';

import './style.css';

const CWMDashboardSelectMode = ({
  selectedCount,
  handleDashboardSelectMode,
  theme
}) => {
  const t = useTranslate();

  return (
    <DashboardCommon theme={theme}>
      <div className="cwm-dashboard-select-mode">
        <div className="cwm-dashboard-select-mode__side-left">
          <div>{`${t(
            'chat-widget.menu-items.messages-selected'
          )}: ${selectedCount}`}</div>
          <div className="cwm-dashboard-select-mode__actions">
            <ButtonIcon
              icon="forward"
              tooltip="forward"
              onClick={() => handleDashboardSelectMode('forward-message')}
              disabled={!selectedCount}
            />
          </div>
          <div className="cwm-dashboard-select-mode__separator" />
        </div>
        <div className="cwm-dashboard-select-mode__side-right">
          <div
            className="cwm-dashboard-select-mode__close-wrapper"
            onClick={() => handleDashboardSelectMode('close')}>
            <ButtonIcon icon="cross" tooltip="close" width={14} height={14} />
          </div>
        </div>
      </div>
    </DashboardCommon>
  );
};

CWMDashboardSelectMode.propTypes = {
  selectedCount: PropTypes.number.isRequired
};

export default connect(
  (state) => ({
    selectedCount: getSelectedMessagesCount(state)
  }),
  (dispatch) => bindActionCreators(useCase, dispatch)
)(CWMDashboardSelectMode);
