import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo
} from 'react';
import {
  SearchInput,
  Accordion,
  Button,
  Icon,
  useWizard
} from '@link/react-components';
import { useTranslate, useDebounce } from '@hooks';
import { useChooseSupplierWizard } from '../../../useChooseSupplierWizard';
import { getTranslate } from '../../../utils';
import { GroupItem } from './GroupItem';
import { STEPS } from '../../../constants';
import { Container, Content, Footer, Header } from '../../layout';
import { Row } from '../../row';
import { useInitializeCompaniesGroup } from '../../../hooks';
import { config } from '../../../config';
import classes from './ChooseGroups.module.css';
import { NoSearchResult } from '../../noSearchResult';

const BUTTON_ICON_SIZE = 18;

export function ChooseGroups() {
  const t = useTranslate();
  const { setState, goTo, getState } = useWizard();
  const { close } = useChooseSupplierWizard();

  const { initialCompanies, onChoose } = getState();

  const {
    companies: sharedCompanies,
    groups: sharedGroups,
    selectedCompanies,
    selectedCompaniesActions
  } = useInitializeCompaniesGroup({ initialCompanies });

  const [companies, setCompanies] = useState(sharedCompanies || []);
  const [visibleGroups, setVisibleGroups] = useState(sharedGroups || []);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [search, setSearch] = useState('');
  const selectedCompaniesRef = useRef(null);
  selectedCompaniesRef.current = selectedCompanies;

  useEffect(() => {
    setState({ maxChosenCount: config.maxSelectedCompanies });
  }, []);

  useEffect(() => {
    setCompanies(sharedCompanies);
  }, [sharedCompanies]);

  useEffect(() => {
    setVisibleGroups(sharedGroups);
  }, [sharedGroups]);

  const isGroupSelected = useCallback(
    (group) => selectedGroups.find(({ id }) => id === group.id) != null,
    [selectedGroups]
  );

  const toggleGroup = useCallback(
    (group) => {
      if (isGroupSelected(group)) {
        selectedCompaniesActions.set((prevState) =>
          prevState.filter(
            (companyId) => !group.companiesList.includes(companyId)
          )
        );

        setSelectedGroups((prev) =>
          prev.filter(
            ({ id, companiesList }) =>
              id !== group.id &&
              companiesList.filter(
                (companyId) => !group.companiesList.includes(companyId)
              ).length > 0
          )
        );
      } else {
        selectedCompaniesActions.set((prevState) => {
          const selectedCompanies = Array.from(
            new Set([...prevState, ...group.companiesList])
          );

          return selectedCompanies;
        });
        setSelectedGroups((prev) => [...prev, group]);
      }
    },
    [selectedCompaniesActions, isGroupSelected]
  );

  const onSearch = useDebounce((searchValue) => {
    const searchRegExp = new RegExp(searchValue, 'i');
    const selectedGroups = sharedGroups.filter((group) =>
      searchRegExp.test(`${group.name}`)
    );
    setVisibleGroups(selectedGroups);
    const selectedCompanies = sharedCompanies.filter((company) =>
      searchRegExp.test(`${company.label}`)
    );
    setCompanies(selectedCompanies);
  }, config.searchDebounce);

  useEffect(() => {
    onSearch(search);
  }, [search, sharedGroups, sharedCompanies]);

  const onCreate = useCallback(() => {
    setState({ chosenCompanies: selectedCompaniesRef.current });
    goTo(STEPS.createGroup);
  }, [setState, goTo]);

  const onEdit = useCallback(
    (group) => {
      setState({
        currentGroup: group,
        chosenCompanies: selectedCompaniesRef.current
      });
      goTo(STEPS.editGroup);
    },
    [setState, goTo]
  );

  const onDelete = useCallback(
    (group) => {
      setState({
        currentGroup: group,
        chosenCompanies: selectedCompaniesRef.current
      });
      goTo(STEPS.deleteGroup);
    },
    [setState, goTo]
  );

  function onSubmit() {
    onChoose(selectedCompanies);
    close();
  }

  function isGroupDisabled(group) {
    if (isGroupSelected(group)) {
      return false;
    }

    return (
      new Set([...selectedCompanies, ...group.companiesList]).size >
      config.maxSelectedCompanies
    );
  }

  function isGroupIndeterminate(group) {
    if (selectedCompanies.length === 0) {
      return false;
    }

    const filteredCompaniesCount = group.companiesList.filter((companyId) =>
      selectedCompanies.includes(companyId)
    ).length;

    return (
      filteredCompaniesCount > 0 &&
      filteredCompaniesCount < group.companiesList.length
    );
  }

  function isCompanyDisabled(companyId) {
    return (
      !selectedCompanies.includes(companyId) &&
      selectedCompanies.length >= config.maxCompaniesInGroup
    );
  }

  function translate(tKey, params) {
    return getTranslate(t, `chooseGroups.${tKey}`, params);
  }

  useEffect(() => {
    setState({ selectedCompanies });
  }, [selectedCompanies, setState]);

  const onCompanyToggleCallback = useCallback(
    (value) => {
      if (selectedCompanies.includes(value)) {
        const filteredCompanies = selectedCompanies.filter(
          (item) => item !== value
        );
        setSelectedGroups((prev) => {
          const filteredGroups = prev.filter(({ companiesList }) =>
            filteredCompanies.some((companyId) =>
              companiesList.includes(companyId)
            )
          );
          if (filteredGroups.length !== prev.length) {
            return filteredGroups;
          }

          return prev;
        });
      }

      selectedCompaniesActions.toggle(value);
    },
    [selectedCompaniesActions, selectedCompanies]
  );

  return (
    <Container>
      <Header>
        <SearchInput
          defaultValue={search}
          onSearch={setSearch}
          onChange={setSearch}
          onCleanUp={() => setSearch('')}
          placeholder={translate('inputs.search.placeholder')}
        />
      </Header>
      <Content>
        <Accordion className={classes.accordion} alwaysOpen>
          <Accordion.Item
            detailsClassName={classes.accordionDetails}
            titleClassName={classes.accordionTitle}
            title={translate('accordions.groups.title')}
            paddings={false}
            expanded
            fontVariant="body1Reg">
            <Button
              className={classes.createGroup}
              mode="text"
              size="small"
              onClick={onCreate}
              before={
                <Icon
                  iconName="plus"
                  width={BUTTON_ICON_SIZE}
                  height={BUTTON_ICON_SIZE}
                />
              }
              stretched={false}>
              {translate('accordions.groups.createGroupButton')}
            </Button>
            <div>
              {Boolean(!visibleGroups.length && search) && (
                <NoSearchResult tKey="chooseGroups" />
              )}
              {useMemo(
                () =>
                  visibleGroups.map((group) => (
                    <GroupItem
                      key={group.id}
                      group={group}
                      checked={
                        selectedCompanies.length > 0 &&
                        isGroupSelected(group) &&
                        !isGroupIndeterminate(group)
                      }
                      indeterminate={
                        isGroupSelected(group) && isGroupIndeterminate(group)
                      }
                      disabled={isGroupDisabled(group)}
                      onToggle={toggleGroup}
                      onEdit={onEdit}
                      onDelete={onDelete}
                    />
                  )),
                [visibleGroups, selectedCompanies]
              )}
            </div>
          </Accordion.Item>
          <Accordion.Item
            detailsClassName={classes.accordionDetails}
            titleClassName={classes.accordionTitle}
            title={translate('accordions.companies.title')}
            paddings={false}
            expanded
            fontVariant="body1Reg">
            {Boolean(!companies.length && search) && (
              <NoSearchResult tKey="chooseGroups" />
            )}
            {useMemo(
              () =>
                companies.map((company) => (
                  <Row
                    key={company.value}
                    checked={selectedCompanies.includes(company.value)}
                    value={company.value}
                    onToggle={onCompanyToggleCallback}
                    label={company.label}
                    checkDisabled={isCompanyDisabled(company.value)}
                  />
                )),
              [companies, selectedCompanies]
            )}
          </Accordion.Item>
        </Accordion>
      </Content>
      <Footer>
        <Button mode="text" size="large" onClick={close}>
          {translate('actions.cancel')}
        </Button>
        <Button mode="primary" size="large" onClick={onSubmit}>
          {translate('actions.submit')}
        </Button>
      </Footer>
    </Container>
  );
}
