import React from 'react';

const Arrow = () => (
  <svg
    className="image-viewer-carousel__arrow"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12.3004 0.600032C12.5213 0.765719 12.5661 1.07912 12.4004 1.30003L6.625 9.00041L12.4004 16.7008C12.5661 16.9217 12.5213 17.2351 12.3004 17.4008C12.0795 17.5665 11.7661 17.5217 11.6004 17.3008L5.6 9.30042C5.46667 9.12264 5.46667 8.87819 5.6 8.70041L11.6004 0.700026C11.7661 0.479114 12.0795 0.434345 12.3004 0.600032Z"
      fill="white"
    />
  </svg>
);

export default Arrow;
