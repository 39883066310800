import React, { useCallback, useMemo, useState } from 'react';
import { createStore } from '../../store/createStore';
import { Cover } from './Cover';
import { defaultSpinner, SpinnerContext } from './SpinnerContext';
import { ThumbnailListWithStore } from './ThumbnailListWithStore';
import './style.css';

export const thumbnailPlugin = ({
  renderSpinner,
  renderCurrentPageLabel
} = {}) => {
  const store = useMemo(() => createStore({}), []);
  const [docId, setDocId] = useState('');

  const CoverDecorator = (props) => (
    <Cover {...props} renderSpinner={renderSpinner} store={store} />
  );

  const ThumbnailsDecorator = useCallback(
    (props) => (
      <SpinnerContext.Provider
        value={{ renderSpinner: renderSpinner || defaultSpinner }}>
        <ThumbnailListWithStore
          store={store}
          renderCurrentPageLabel={renderCurrentPageLabel}
          renderThumbnailItem={props?.renderThumbnailItem}
        />
      </SpinnerContext.Provider>
    ),
    [docId]
  );

  return {
    install: (methods) => {
      store.update('jumpToPage', methods.jumpToPage);
    },
    onDocumentLoad: ({ doc }) => {
      setDocId(doc.loadingTask.docId);
      store.update('doc', doc);
    },
    onViewerStateChange: (viewerState) => {
      store.update('currentPage', viewerState.pageIndex);
      store.update('pageHeight', viewerState.pageHeight);
      store.update('pageWidth', viewerState.pageWidth);
      return viewerState;
    },
    Cover: CoverDecorator,
    Thumbnails: ThumbnailsDecorator
  };
};
