import React from 'react';
import { useTranslate } from '../../../../../TranslateProvider';
import './style-for-empty.css';

const ContactsEmpty = ({ text }) => {
  const t = useTranslate();

  return (
    <div className="cws-contacts-empty">
      <img
        className="cws-contacts-empty__icon"
        src="/img/contact-icon-36-36.svg"
        alt="contact-icon"
      />
      <span className="cws-contacts-empty__title">
        {text || t('no_contact')}
      </span>
    </div>
  );
};

export default ContactsEmpty;
