const { prepend, isEmpty, dropLast } = require('ramda');

const EMPTY_MODAL = null;
exports.EMPTY_MODAL = EMPTY_MODAL;
/**
 * @typedef {(object|EMPTY_MODAL)} Modal
 * @property {string} type
 * @property {string} name
 * @property {object} props
 */

/**
 * @typedef {object} ModalManager
 * @property {string} type
 * @property {string} current
 * @property {Date} history
 */

/**
 * Creates a modal data type
 *
 * @param {string} name
 * @param {object} props
 * @returns {Modal}
 */
const makeModal = (name, props = {}) => ({ type: 'Modal', name, props });
exports.makeModal = makeModal;

/**
 * Creates a modal history data type
 *
 * @param {[Modal]} history
 * @returns {[Modal]}
 */
const makeHistory = (history = []) => history;
exports.makeHistory = makeHistory;

/**
 * Creates modal manager
 *
 * @param {Modal} current
 * @param {[Modal]} history
 * @returns {ModalManager}
 */
const makeModalManager = (current = EMPTY_MODAL, history = makeHistory()) => ({
  type: 'ModalManager',
  current,
  history
});
exports.makeModalManager = makeModalManager;

/**
 * Shows modal with name and props
 *
 * @param {ModalManager} modalManager
 * @param {string} name
 * @param {object} props
 * @returns {ModalManager}
 */
exports.show = (modalManager, name, props) =>
  makeModalManager(makeModal(name, props));

/**
 * Hides modal with name and props
 *
 * @param {ModalManager} modalManager
 * @returns {ModalManager}
 */
exports.hide = (modalManager) => {
  /**
   * Adds to history new modal
   *
   * @param {Modal} modal
   * @param {[Modal]} history
   * @returns {[Modal]}
   */
  const addToHistory = (modal, history) => {
    if (history.length === 3) {
      const updatedHistory = prepend(modal, history);
      return dropLast(1, updatedHistory);
    }

    return prepend(modal, history);
  };

  return makeModalManager(
    EMPTY_MODAL,
    addToHistory(modalManager.current, modalManager.history)
  );
};

/**
 * Shows last modal
 *
 * @param {ModalManager} modalManager
 * @returns {ModalManager}
 */
exports.showLast = (modalManager) => {
  if (isEmpty(modalManager.history)) return modalManager;

  const [head, ...tail] = modalManager.history;
  return makeModalManager(head, tail);
};

/**
 * Determiners if show modal
 *
 * @param {ModalManager} modalManager
 * @returns {boolean}
 */
exports.isShow = (modalManager) => EMPTY_MODAL !== modalManager.current;

/**
 * Determiners if previous modal have such name
 *
 * @param {string} name
 * @param {ModalManager} modalManager
 * @returns {boolean}
 */
exports.equalPrevName = (name, modalManager) => {
  if (isEmpty(modalManager.history)) return false;

  return modalManager.history[0].name === name;
};
