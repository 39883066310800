export const SpecialZoomLevel = {
  DefaultSize: 'zoom-default',
  PageFit: 'zoom-page-fit',
  PageWidth: 'zoom-page-width'
};

export const ScrollMods = {
  Horizontal: 'horizontal',
  Vertical: 'vertical',
  Wrapped: 'wrapped'
};
