const { pipe, assoc } = require('ramda');
const { ACTIVATED, MODERATION, DELETED } = require('../memberStatus');
const { ADMIN, PARTICIPANT, EDITOR, READER } = require('../memberRole');

/**
 * @typedef {import('../imgSrc')} ImgSrc
 * @typedef {import('../memberStatus')} MemberStatus
 * @typedef {import('../memberRole')} MemberRole
 */

/**
 * @typedef {object} ChannelMember
 * @property {string} type
 * @property {string} employeeId
 * @property {string} userName
 * @property {string} companyName
 * @property {ImgSrc} avatarSrc
 * @property {MemberStatus} memberStatus
 * @property {MemberRole} memberRole
 * @property {boolean} isDeletedCompany
 * @property {Date} changedMSAt - changed member status at
 * @property {Date} changedMRAt - changed member role at
 */

/**
 * Creates a channel member data type
 *
 * @param {object} params
 * @param {string} params.employeeId
 * @param {number|string} params.companyId
 * @param {string} params.userName
 * @param {string} params.companyName
 * @param {string} params.department
 * @param {ImgSrc} params.avatarSrc
 * @param {MemberStatus} params.memberStatus
 * @param {MemberRole} params.memberRole
 * @param {boolean} params.isDeletedCompany
 * @param {Date} params.changedMSAt
 * @param {Date} params.changedMRAt
 * @returns {ChannelMember}
 */
const makeChannelMember = ({
  employeeId,
  companyId,
  userName,
  companyName,
  department,
  avatarSrc,
  memberStatus,
  memberRole,
  changedMSAt,
  changedMRAt,
  isDeletedCompany = false
} = {}) => ({
  type: 'ChannelMember',
  employeeId,
  companyId,
  userName,
  companyName,
  department,
  avatarSrc,
  memberStatus,
  memberRole,
  changedMSAt,
  changedMRAt,
  isDeletedCompany
});
exports.makeChannelMember = makeChannelMember;

/**
 * Check if channelMember have activated member status
 *
 * @param {ChannelMember} channelMember
 * @returns {boolean}
 */
exports.haveActivatedMS = (channelMember) =>
  channelMember.memberStatus === ACTIVATED;

/**
 * Check if channelMember have deleted member status
 *
 * @param {ChannelMember} channelMember
 * @returns {boolean}
 */
exports.haveDeletedMS = (channelMember) =>
  channelMember.memberStatus === DELETED;

/**
 * Check if channelMember have member status
 *
 * @param {MemberStatus} memberStatus
 * @param {ChannelMember} channelMember
 * @returns {boolean}
 */
exports.haveMemberStatus = (memberStatus, channelMember) =>
  channelMember && memberStatus === channelMember.memberStatus;

/**
 * Sets a member status for channelMember
 *
 * @param {ChannelMember} channelMember
 * @param {MemberStatus} memberStatus
 * @param {Date} [changedMSAt=new Date()]
 * @returns {ChannelMember}
 */
const setMemberStatus = (
  channelMember,
  memberStatus,
  changedMSAt = new Date()
) =>
  pipe(
    assoc('memberStatus', memberStatus),
    assoc('changedMSAt', changedMSAt)
  )(channelMember);
exports.setMemberStatus = setMemberStatus;

/**
 * Sets a member status for array of channelMember
 *
 * @param {[ChannelMember]} channelMembers
 * @param {[number]} employeeIds
 * @param {MemberStatus} memberStatus
 * @param {Date} [changedMSAt=new Date()]
 * @returns {ChannelMember}
 */
exports.setMemberStatusOfList = (
  channelMembers,
  employeeIds,
  memberStatus,
  changedMSAt = new Date()
) =>
  channelMembers.map((member) => {
    if (!employeeIds.includes(member.employeeId)) return member;
    return setMemberStatus(member, memberStatus, changedMSAt);
  });

/**
 * Check if channelMember have admin role
 *
 * @param {ChannelMember} channelMember
 * @returns {boolean}
 */
exports.haveAdminMR = (channelMember) => channelMember.memberRole === ADMIN;

/**
 * Check if channelMember have editor role
 *
 * @param {ChannelMember} channelMember
 * @returns {boolean}
 */
exports.haveEditorMR = (channelMember) => channelMember.memberRole === EDITOR;

/**
 * Check if channelMember have participant role
 *
 * @param {ChannelMember} channelMember
 * @returns {boolean}
 */
exports.haveParticipantMR = (channelMember) =>
  channelMember.memberRole === PARTICIPANT;

/**
 * Check if channelMember have reader role
 *
 * @param {ChannelMember} channelMember
 * @returns {boolean}
 */
exports.haveReaderMR = (channelMember) => channelMember.memberRole === READER;

/**
 * Check if channelMember have member role
 *
 * @param {MemberRole} memberRole
 * @param {ChannelMember} channelMember
 * @returns {boolean}
 */
exports.haveMemberRole = (memberRole, channelMember) =>
  memberRole === channelMember.memberRole;

/**
 * Sets a member role for channelMember
 *
 * @param {ChannelMember} channelMember
 * @param {MemberRole} memberRole
 * @param {Date} [changedMRAt=new Date()]
 * @returns {ChannelMember}
 */
const setMemberRole = (channelMember, memberRole, changedMRAt = new Date()) =>
  pipe(
    assoc('memberRole', memberRole),
    assoc('changedMRAt', changedMRAt)
  )(channelMember);
exports.setMemberRole = setMemberRole;

/**
 * Sets a member role for array of channelMember
 *
 * @param {[ChannelMember]} channelMembers
 * @param {[number]} employeeIds
 * @param {MemberRole} memberRole
 * @param {Date} [changedMRAt=new Date()]
 * @returns {ChannelMember}
 */
exports.setMemberRoleOfList = (
  channelMembers,
  employeeIds,
  memberRole,
  changedMRAt = new Date()
) =>
  channelMembers.map((member) => {
    if (!employeeIds.includes(member.employeeId)) return member;
    return setMemberRole(member, memberRole, changedMRAt);
  });

/**
 * Create a channelMember from user db
 *
 * @param {object} userDB
 * @param {number} userDB.employeeId
 * @param {string} userDB.firstName
 * @param {string} userDB.lastName
 * @param {string} userDB.companyName
 * @param {(string|null)} userDB.avatar
 * @param {object} options
 * @param {MemberStatus} [options.memberStatus=MODERATION]
 * @param {MemberRole} [options.memberRole=PARTICIPANT]
 * @param {Date} [options.changedMSAt=new Date()]
 * @param {Date} [options.changedMRAt=new Date()]
 * @returns {ChannelMember}
 */
exports.fromUserDB = (
  { employeeId, firstName, lastName, companyName, avatar },
  {
    memberStatus = MODERATION,
    memberRole = PARTICIPANT,
    changedMSAt = new Date(),
    changedMRAt = new Date()
  }
) =>
  makeChannelMember({
    employeeId,
    userName: `${lastName} ${firstName}`,
    companyName,
    avatarSrc: avatar,
    memberStatus,
    memberRole,
    changedMSAt,
    changedMRAt
  });
