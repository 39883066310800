export const updateTopicService = (
  {
    getChatInput,
    getFilesForRemove,
    updateChannel,
    prevChatMode,
    clearInputFiles,
    clearTempFiles,
    gaTopicUpdated
  },
  { id = '_', name, title, desc }
) => {
  const { files: filesToAdd } = getChatInput();
  const filesToRemove = getFilesForRemove();
  updateChannel(id, { name, title, desc, filesToAdd, filesToRemove });
  prevChatMode();
  clearInputFiles();
  clearTempFiles();
  gaTopicUpdated();
};
