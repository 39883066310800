import React from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Close from '../icons/close';

const formatDate = (language, date) => {
  if (!date) return '';
  const formattedDate = format(new Date(date), 'dd.M.yyyy');
  const formattedTime = format(new Date(date), 'HH:mm');
  const unionWord = language === 'ru' ? 'в' : 'at';
  return `${formattedDate} ${unionWord} ${formattedTime}`;
};

const PDFViewerHeader = ({
  name,
  senderName = '',
  language,
  date,
  hideModalDialog
}) => {
  const description = `${senderName} ${formatDate(language, date)}`.trim();

  return (
    <div className="pdf-viewer-header__container">
      <div className="pdf-viewer-header__container-left">
        <div className="pdf-viewer-header__name">{name}</div>
        {!isEmpty(description) && (
          <div className="pdf-viewer-header__description">{description}</div>
        )}
      </div>
      <div
        className="pdf-viewer-header__button-close"
        onClick={hideModalDialog}>
        <Close />
      </div>
    </div>
  );
};

PDFViewerHeader.propTypes = {
  hideModalDialog: PropTypes.func.isRequired
};

export default PDFViewerHeader;
