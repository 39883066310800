import React from 'react';
import { createSelector } from 'reselect';
import { defMulti, addHandler, call } from '../../../../lib/multi';
import NotContactCard from './NotContactCard';
import Archived from './Archived';
import NotMyContactCard from './NotMyContactCard';
import BlockedMe from './BlockedMe';
import BlockedContact from './BlockedContact';
import ChannelClosed from './ChannelClosed';
import ChannelNoAccess from './ChannelNoAccess';
import {
  checkCurrentMemberHaveActivedMS,
  checkIfCurrentUserOwnerChannel,
  checkCurrentMemberLeaveChat,
  checkCurrentMemberIsAdmin,
  checkCurrentMemberIsSupport,
  checkDialogWithIstock,
  getDialogInfo,
  getOpenedChat
} from '../../getter';

const MODE_NONE = 'none';
const MODE_ARCHIVED = 'archived';
const MODE_CLOSED = 'closed';
const MODE_NO_ACCESS = 'no-access';
const MODE_NO_MY_CONTACT = 'no-my-contact';
const MODE_BLOCKED_ME = 'blocked-me';
const MODE_BLOCKED_CONTACT = 'blocked-contact';
const MODE_HIDDEN_CONTACT = 'hidden';

const isClosableChat = (chat) =>
  chat.type === 'Channel' || chat.type === 'Topic';
const isDialog = (chat) => chat.type === 'Dialog';

export const popUpSNDispatcher = ({
  chat,
  currentMemberHaveActivedMS,
  isDialogWithIstock,
  currentMemberIsAdmin,
  currentMemberIsSupport,
  info
}) => {
  if (chat.archived) return MODE_ARCHIVED;
  if (isClosableChat(chat) && chat.isClosed) return MODE_CLOSED;
  if (!currentMemberHaveActivedMS) return MODE_NO_ACCESS;
  if (isDialog(chat)) {
    if (info.isInactive || info.isDeleted || info.companyDeleted)
      return MODE_NO_MY_CONTACT;
    if (info.isBlockedMe) return MODE_BLOCKED_ME;
    if (info.isBlockedContact) return MODE_BLOCKED_CONTACT;
    if (info.isHidden) return MODE_HIDDEN_CONTACT;
  }
  if (
    isDialog(chat) &&
    (isDialogWithIstock || currentMemberIsAdmin || currentMemberIsSupport)
  )
    return MODE_NONE;
  return MODE_NONE;
};

export const hasPopUpSN = (props) => popUpSNDispatcher(props) !== MODE_NONE;

const popUpSystemNotification = defMulti(
  'popUpSystemNotification',
  popUpSNDispatcher
);

addHandler(popUpSystemNotification, MODE_NONE, () => null);
addHandler(popUpSystemNotification, MODE_ARCHIVED, ({ chat }) => (
  <Archived chat={chat} tkey={`${chat.type.toLowerCase()}_text_archived`} />
));
addHandler(
  popUpSystemNotification,
  MODE_CLOSED,
  ({ chat, currentMemberIsChannelOwner, handleChatNotifications }) => (
    <ChannelClosed
      chat={chat}
      currentMemberIsChannelOwner={currentMemberIsChannelOwner}
      handleChatNotifications={handleChatNotifications}
    />
  )
);
addHandler(
  popUpSystemNotification,
  MODE_NO_ACCESS,
  ({
    chat,
    currentMemberIsChannelOwner,
    currentMemberLeavedChat,
    handleChatNotifications
  }) => (
    <ChannelNoAccess
      chat={chat}
      currentMemberIsChannelOwner={currentMemberIsChannelOwner}
      currentMemberLeavedChat={currentMemberLeavedChat}
      handleChatNotifications={handleChatNotifications}
    />
  )
);
addHandler(popUpSystemNotification, MODE_NO_MY_CONTACT, () => (
  <NotMyContactCard />
));
addHandler(popUpSystemNotification, MODE_BLOCKED_ME, () => <BlockedMe />);
addHandler(
  popUpSystemNotification,
  MODE_BLOCKED_CONTACT,
  ({ chat, handlerContactList, info }) => (
    <BlockedContact
      dialogId={chat.dialogId}
      unblock={() => handlerContactList('unblock', info)}
    />
  )
);
addHandler(popUpSystemNotification, MODE_HIDDEN_CONTACT, ({ chat }) => (
  <NotContactCard chat={chat} />
));

export const PopUpSystemNotification = (props) =>
  call(props, popUpSystemNotification);

export const popUpSNData = createSelector(
  [
    checkCurrentMemberIsAdmin,
    checkCurrentMemberIsSupport,
    checkCurrentMemberHaveActivedMS,
    checkIfCurrentUserOwnerChannel,
    checkCurrentMemberLeaveChat,
    checkDialogWithIstock,
    getOpenedChat,
    getDialogInfo
  ],
  (
    currentMemberIsAdmin,
    currentMemberIsSupport,
    currentMemberHaveActivedMS,
    currentMemberIsChannelOwner,
    currentMemberLeavedChat,
    isDialogWithIstock,
    chat,
    info
  ) => ({
    currentMemberIsAdmin,
    currentMemberIsSupport,
    currentMemberHaveActivedMS,
    currentMemberIsChannelOwner,
    currentMemberLeavedChat,
    isDialogWithIstock,
    chat,
    info
  })
);
