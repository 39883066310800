import { validateEmail } from './validateField';

export default (values) => {
  const errors = {};
  const email = values.get('email');

  if (!email) {
    errors.email = 'Required';
  } else if (!validateEmail(email)) {
    errors.email = 'Wrong email format';
  }

  return errors;
};
