import React from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { createAudioStreamByStream, stopStream } from '../../../utils';

const Audio = ({ id, stream, muted = false }) => {
  const audioRef = React.useRef();
  const audioStreamRef = React.useRef();

  console.log(`audio component for peer = ${id} is muted = ${muted}`);

  useIsomorphicLayoutEffect(() => {
    if (audioRef.current) {
      if (audioStreamRef.current) {
        stopStream(audioStreamRef.current);
      }
      audioStreamRef.current = createAudioStreamByStream(stream);

      audioRef.current.srcObject = audioStreamRef.current;
    }

    return () => {
      if (audioStreamRef.current) {
        stopStream(audioStreamRef.current);
      }
    };
  }, [stream]);

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <audio ref={audioRef} muted={muted} autoPlay />;
};

export default Audio;
