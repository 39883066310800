import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import classNames from 'classnames';
import { useTranslate } from '../../../TranslateProvider';
import ImageViewerCarousel from './ImageViewerCarousel';
import * as modalActions from '../../../action-creators/modal';
import * as messageActions from '../../../action-creators/message';
import ImageViewerHeader from './ImageViewerHeader';
import ImageViewerFooter from './ImageViewerFooter';
import ImageBlurredPreview from './ImageBlurredPreview';
import ZoomRange from '../common/ZoomRange';
import useZoomEffect from '../../../utils/zoomer';
import useWindowSize from '../../../utils/windowSize';
import { resendFile } from '../../ChatWidget/useCases';
import { useActions } from '../../../hooks/useActions';
import { noop } from '../../../utils/utils';
import './ModalImageViewer.css';

function calculateSize({ width, height }) {
  return {
    maxWidth: width - 128 - 16,
    maxHeight: height - 51
  };
}

const ModalImageViewer = ({
  images = [],
  imageIndex: defaultImageIndex,
  clearMessages,
  onCopyToStorageImage,
  onDeleteImage,
  isDeleteEnabled,
  isDownloadEnabled,
  hideModalDialog,
  file
}) => {
  const t = useTranslate();
  const [isLoading, setIsLoading] = useState(true);
  const [imageIndex, setImageIndex] = useState(defaultImageIndex);
  const { height, width } = useWindowSize();
  const currentImage = images[imageIndex];
  const actions = useActions({ resendFile });

  useEffect(() => {
    clearMessages();
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  const imageRef = useRef();

  useEffect(() => {
    function setLoaded() {
      setIsLoading(false);
    }

    imageRef.current.addEventListener('load', setLoaded);

    return () => imageRef.current.removeEventListener('load', setLoaded);
  }, []);

  const step = 0.5;
  const minScale = 1;
  const maxScale = 2.5;
  const rangeStep = 0.01;
  const { maxHeight, maxWidth } = calculateSize({ height, width });

  const { scale, zoomIn, zoomOut, zoomChange, zoomReset } = useZoomEffect(
    { domTarget: imageRef },
    {
      step,
      minScale,
      maxScale,
      maxHeight,
      maxWidth,
      panning: {
        disable: false
      },
      overflowX: 'hidden',
      overflowY: 'hidden'
    }
  );

  useEffect(() => {
    zoomReset();
  }, [imageIndex]);

  const cursor = scale === minScale ? 'zoom-in' : 'zoom-out';

  const onNextImage = () => {
    setIsLoading(true);
    setImageIndex((prevState) => prevState + 1);
  };

  const onPrevImage = () => {
    setIsLoading(true);
    setImageIndex((prevState) => prevState - 1);
  };

  const onDownload = () => {
    const { src, name } = currentImage;
    window.open(`${src}?name=${name}`, '_blank');
  };

  const onDelete = async () => {
    if (window.confirm(t('confirm_image_delete'))) {
      await onDeleteImage(imageIndex);
      hideModalDialog();
    }
  };

  const onCopyToStorage = async () => {
    await onCopyToStorageImage(imageIndex);
  };

  const isFirstImage = () => imageIndex === 0;

  const isLastImage = () => imageIndex === images.length - 1;

  const isSingleImage = () => images.length === 1;

  function onKeyDown(e) {
    if ((e.keyCode === 37 || e.keyCode === 40) && !isFirstImage()) {
      onPrevImage();
    }

    if ((e.keyCode === 39 || e.keyCode === 38) && !isLastImage()) {
      onNextImage();
    }
  }

  const {
    src = '',
    name = '',
    addedAt = '',
    senderName = ''
  } = currentImage || {};
  const { addToStorageEnable: isShowAddToStorage } = currentImage || {};

  const handleResend = useCallback(() => {
    actions.resendFile(file);
  }, []);

  return (
    <div
      className={classNames('modal-image-viewer__container', {
        loading: isLoading
      })}>
      <ImageBlurredPreview src={src} />
      <ImageViewerHeader hideModalDialog={hideModalDialog} />
      <ImageViewerCarousel
        src={src}
        name={name}
        isFirstImage={isFirstImage()}
        isLastImage={isLastImage()}
        isSingleImage={isSingleImage()}
        onNextImage={onNextImage}
        onPrevImage={onPrevImage}>
        <>
          <Image
            className="modal-image-viewer__image"
            ref={imageRef}
            src={src}
            alt=""
            draggable={false}
            cursor={cursor}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
          />
          <ZoomRange
            value={scale}
            step={rangeStep}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            zoomChange={zoomChange}
            onReset={zoomReset}
            minValue={minScale}
            maxValue={maxScale}
          />
        </>
      </ImageViewerCarousel>
      <ImageViewerFooter
        t={t}
        name={name}
        date={addedAt}
        senderName={senderName}
        isShowAddToStorage={isShowAddToStorage}
        isDeleteEnabled={isDeleteEnabled}
        isDownloadEnabled={isDownloadEnabled}
        onDownload={onDownload}
        onDelete={onDelete}
        onCopyToStorage={onCopyToStorage}
        handleResend={handleResend}
      />
    </div>
  );
};

const Image = styled.img((props) => ({
  'max-height': props.maxHeight || '100%',
  'max-width': props.maxWidth || '100%',
  margin: 'auto',
  cursor: props.cursor || 'default'
}));

ModalImageViewer.propTypes = {
  imageIndex: PropTypes.number,
  isDeleteEnabled: PropTypes.bool.isRequired,
  isDownloadEnabled: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      src: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string
    })
  ),
  onDeleteImage: PropTypes.func,
  hideModalDialog: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired
};

ModalImageViewer.defaultProps = {
  isDownloadEnabled: true,
  imageIndex: 0,
  images: [],
  onDeleteImage: noop
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalActions.hideModalDialog,
      clearMessages: messageActions.clearMessages
    },
    dispatch
  );

export default connect(null, mapDispatch)(ModalImageViewer);
