import { has } from 'ramda';

export const ADMIN = 'ADMIN';
export const EDITOR = 'EDITOR';
export const READER = 'READER';
export const PARTICIPANT = 'PARTICIPANT';
export const NOTACTIVE = 'NOTACTIVE';
export const USER = 'USER';

export const havePermission = (name, role, permissions) => {
  if (!has(name, permissions))
    throw Error(`Not config permission with name "${name}"`);
  const roles = permissions[name];
  return roles.includes(role);
};

// NOTE: Examples
// const permissions = {
//   archive: [ADMIN, USER],
//   'add-to-list': [ADMIN, USER],
//   'notice-off': [ADMIN],
//   'notice-on': [ADMIN]
// };

// const menu = [
//   { icon: 'archive', value: 'archive' },
//   { icon: 'folder', value: 'add-to-list' },
//   {
//     conditionName: 'notice-on?',
//     items: [
//       { icon: 'notice-off', value: 'notice-off' },
//       { icon: 'notice-on', value: 'notice-on' }
//     ]
//   }
// ];

export const filterMenu = (permissions, role, menu) =>
  menu.reduce((acc, item) => {
    if (has('items', item)) {
      const filteredItems = filterMenu(permissions, role, item.items);

      if (filteredItems.length === 0) return acc;

      const condition = {
        conditionName: item.conditionName,
        items: filteredItems
      };
      return [...acc, condition];
    }

    if (havePermission(item.value, role, permissions)) {
      return [...acc, item];
    }

    return acc;
  }, []);
