import React from 'react';
import PropTypes from 'prop-types';
import './ModalImageViewer.css';
import Arrow from '../icons/arrow';

const ImageViewerCarousel = ({
  onNextImage,
  onPrevImage,
  isFirstImage,
  isLastImage,
  isSingleImage,
  children
}) => (
  <div className="image-viewer-carousel__container">
    <div className="image-viewer-carousel__left-side-column">
      {!isSingleImage && !isFirstImage && (
        <div
          className="image-viewer-carousel__side-button"
          onClick={onPrevImage}
          onKeyDown={(e) => (e.keyCode === 37 ? onNextImage : null)}>
          <Arrow />
        </div>
      )}
    </div>
    {children}
    <div className="image-viewer-carousel__right-side-column">
      {!isSingleImage && !isLastImage && (
        <div
          className="image-viewer-carousel__side-button"
          onClick={onNextImage}
          onKeyDown={(e) => (e.keyCode === 39 ? onNextImage : null)}>
          <Arrow />
        </div>
      )}
    </div>
  </div>
);

ImageViewerCarousel.propTypes = {
  onNextImage: PropTypes.func.isRequired,
  onPrevImage: PropTypes.func.isRequired,
  isFirstImage: PropTypes.bool.isRequired,
  isSingleImage: PropTypes.bool.isRequired,
  isLastImage: PropTypes.bool.isRequired
};

export default ImageViewerCarousel;
