import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { checkPinBtn } from '../helpers/pin';
import CreatedRoomMsg from '../CreatedRoomMessage';
import UserMessageLayout from '../../../layouts/UserMessageLayout';
import Msg from '../../../msg/msg';
import Footer from '../../MessageFooter';
import Reply from '../../../msg/reply';
import {
  isAdmin as checkIsAdmin,
  isSupport as checkIsSupport
} from '../../../../../../../lib/goodwix';
import { isDialog } from '../../../../../helpers/chatType';
import ForwardedTitle from '../../ForwardedMessageTitle';
import PurchaseRequestMsg from '../../../msg/viewPurchaseRequestMsg/msg';
import * as messageTypes from '../../../entity/message';
import * as dictionariesGetters from '../../../../../../../modules/dictionaries/getters';

const PersonMessage = ({
  t,
  id,
  currentEmployeeId,
  chat,
  message,
  isSelected,
  isSelectMode,
  isRead,
  isPinned,
  isCortege,
  onClick,
  internetConnection,
  config,
  messagesPlacement,
  onLoadedSingleImage
}) => {
  const { avatarSize } = config.config;
  const { showReadMsg, buttonList, placement } = config.config.otherMessage;

  const [isOpen, setOpen] = useState(null);

  const isForwardedMessage = messageTypes.isForwardedMessage(message);
  const isReplyMessage = messageTypes.isReplyMessage(message);
  const isUnsentMessage = messageTypes.isUnsentMessage(message);
  const isVideoRoomCreatedMessage = messageTypes.isMsgRoomCreated(message);
  const isForwardVideoRoomCreatedMessage =
    messageTypes.isForwardedVideoRoomCreatedMsg(message);
  const isForwardPurchaseRequestMessage =
    messageTypes.isForwardedPurchaseRequestMessage(message);

  const PRCategoriesList = useSelector(
    dictionariesGetters.getPurchaseRequestsCategories
  );

  const isAdmin =
    checkIsAdmin(message.employeeId) || checkIsSupport(message.employeeId);

  const menuOptions = useMemo(() => {
    const { extraBtn, positions } = config.config.otherMessage;
    const { position, possiblePositions } = positions[messagesPlacement];

    if (extraBtn.name === 'go-to-msg') {
      return {
        type: 'regular',
        icon: 'arrow-right',
        tooltip: extraBtn.tooltip,
        onClick: () => onClick('go-to-msg')
      };
    }
    if (extraBtn.name === 'extra') {
      return {
        type: 'dropdown',
        icon: 'extra',
        onSelect: onClick,
        list: checkPinBtn(chat, currentEmployeeId, extraBtn.list).filter(
          (extBtn) => !buttonList.some((btnL) => extBtn.value === btnL.value)
        ),
        conditions: {
          'pinned?': isPinned ? 'unpin-message' : 'pin-message'
        },
        position,
        possiblePositions,
        onToggle: setOpen
      };
    }
    throw new Error('Not such condition');
  }, [
    onClick,
    chat,
    isRead,
    isPinned,
    message.time,
    internetConnection,
    isUnsentMessage,
    messagesPlacement
  ]);

  const userName = {
    show: !isCortege && !isDialog(chat),
    name: message.userName,
    color: isAdmin ? 'blue' : 'no-color'
  };

  const avatar = useMemo(
    () => ({
      show: config.showAvatar && !isCortege,
      size: avatarSize,
      info: {
        type: 'user',
        subType: 'avatar-in-chat',
        src: message.avatarSrc,
        alt: message.userName,
        employeeId: message.employeeId
      },
      isCursorPointer: true,
      onClick: () => onClick('view-profile', chat, message)
    }),
    [message.avatarSrc, isCortege, onClick, chat.id, message.id]
  );

  const preparedMessage = useMemo(() => {
    if (isVideoRoomCreatedMessage) {
      return { msg: <CreatedRoomMsg message={message} chat={chat} /> };
    }
    if (isForwardVideoRoomCreatedMessage) {
      return {
        msg: <CreatedRoomMsg message={message.originMsgData} chat={chat} />
      };
    }

    return message;
  }, [message]);

  const messageStatus = useMemo(
    () => ({
      show: showReadMsg && !isUnsentMessage,
      status: isRead ? 'read' : 'unread'
    }),
    [isRead, isUnsentMessage]
  );

  const showProfile = useCallback(
    (targetEmployeeId) => {
      onClick('view-specified-profile', targetEmployeeId);
    },
    [onClick]
  );

  const onMessageLineClick = useCallback(() => {
    if (isSelectMode) {
      onClick('highlight-message', chat, message);
    }
  }, [isSelectMode, chat, message]);

  return (
    <UserMessageLayout
      id={id}
      className={cn(`cwm-msg_theme-${config.theme}`, {
        selected: isSelected,
        'select-mode': isSelectMode,
        active: isOpen,
        cortege: isCortege,
        'cwm-msg_forward-theme_1':
          isForwardedMessage || isForwardVideoRoomCreatedMessage
      })}
      bgColor="gray-1"
      defaultPlacement={placement}
      avatar={avatar}
      onClick={onMessageLineClick}
      content={
        <>
          {isForwardPurchaseRequestMessage ? (
            <PurchaseRequestMsg
              t={t}
              message={message.originMsgData}
              theme={config.theme}
              categoriesList={PRCategoriesList}
            />
          ) : (
            <Msg
              chat={chat}
              message={preparedMessage}
              isOwnMsg={false}
              isReadMsg={isRead}
              showProfile={showProfile}
              theme={config.theme}
              onLoadedSingleImage={onLoadedSingleImage}
              filesData={message.files}
            />
          )}
          <Footer
            t={t}
            currentEmployeeId={currentEmployeeId}
            message={message}
            onClick={onClick}
            buttonList={buttonList}
            isUnsent={isUnsentMessage}
            scheduleMessages={chat.scheduleMessages}
            setActive={setOpen}
          />
        </>
      }
      header={
        <>
          <div className="cwm-msg__msg-header">
            {userName.show && (
              <div
                className={`cwm-msg__msg-user-name cwm-user-message__msg-user-name_color_${userName.color}`}>
                {userName.name}
              </div>
            )}
          </div>
          <Reply
            t={t}
            type="user"
            show={isReplyMessage}
            chat={chat}
            message={message.targetMsg}
            theme={config.theme}
            onClick={onClick}
          />
          <ForwardedTitle
            t={t}
            show={isForwardedMessage}
            name={message.authorUserName}
          />
        </>
      }
      menuOptions={menuOptions}
      status={messageStatus}
      isSelected={isSelected}
    />
  );
};

export default React.memo(PersonMessage);
