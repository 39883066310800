import { useCallback, useEffect, useState } from 'react';
import { isNil, hasPath } from 'ramda';

function calcScrollBottom(node) {
  return node.scrollHeight - (node.scrollTop + node.offsetHeight);
}

function isRef(candidate) {
  return !isNil(candidate) && hasPath(['current'], candidate);
}

function getTarget(data) {
  return isRef(data) ? data.current : data;
}

export function useIsScrollBottom(
  target,
  deps = [],
  options = {
    delta: 0
  }
) {
  const [isScrollBottom, setIsScrollBottom] = useState(false);

  const handler = useCallback(() => {
    const isBottom = calcScrollBottom(getTarget(target)) < options.delta;

    setIsScrollBottom(isBottom);
  }, [options.delta]);

  useEffect(() => {
    const _target = getTarget(target);

    if (!_target) return undefined;

    handler();

    _target.addEventListener('scroll', handler);

    return () => {
      _target.removeEventListener('scroll', handler);
    };
  }, [handler, ...deps]);

  return isScrollBottom;
}
