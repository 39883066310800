const { defStruct } = require('../../lib');

/**
 * @typedef ConfigItem - configuration item
 */
const { makeConfigItem, getLabel, getKey, getValue, isConfigItem } = defStruct(
  'ConfigItem',
  ['label', 'key', 'value']
);

/**
 * Create a configuration item data type
 *
 * @param {string} label
 * @param {string} key
 * @param {string} value
 * @returns {ConfigItem}
 */
exports.makeConfigItem = (label = '', key = '', value = null) =>
  makeConfigItem(label, key, value);

/**
 * Gives a label of configuration item
 *
 * @param {ConfigItem} configItem
 * @returns {string}
 */
exports.getLabel = (configItem) => getLabel(configItem);

/**
 * Gives a key of configuration item
 *
 * @param {ConfigItem} configItem
 * @returns {string}
 */
exports.getKey = (configItem) => getKey(configItem);

/**
 * Gives a value of configuration item
 *
 * @param {ConfigItem} configItem
 * @returns {*}
 */
exports.getValue = (configItem) => getValue(configItem);

/**
 * Checks if is ConfigItem
 *
 * @param {*} configItem
 * @returns {boolean}
 */
exports.isConfigItem = (configItem) => isConfigItem(configItem);
