import { Palette } from './palette';

export function getColor(color, palette = Palette) {
  return (
    palette[color] ||
    Object.values(palette).find((currentColor) => currentColor === color)
  );
}

export function isColor(color, palette = Palette) {
  return typeof color === 'string' && Boolean(getColor(color, palette));
}
