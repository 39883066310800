import { haveActivatedMS } from 'core/data/light/channelMember';

export const removeMemberCase = (
  { addToExcludeMembers, removeInviteMembers },
  { member }
) => {
  if (haveActivatedMS(member)) {
    addToExcludeMembers(member.employeeId);
  } else {
    removeInviteMembers(member.employeeId);
  }
};
