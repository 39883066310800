import React, { useCallback } from 'react';
import throttle from 'lodash.throttle';
import { useTranslate } from '../../../../../../../TranslateProvider';
import getMsgSystemText from '../../../../../SystemMsgTranslates';
import { useClickByVideoChatLink } from '../../../../../../../modules/VideoChat/hooks/useClickByVideoChatLink';

const CreatedRoomMsg = ({ chat, message }) => {
  const t = useTranslate();

  const onClickByVideoChatLink = useClickByVideoChatLink();

  const throttledClickByVideoChatLink = useCallback(
    throttle(
      () => {
        onClickByVideoChatLink(message.videoRoomId, chat.id);
      },
      1000,
      {
        trailing: false
      }
    ),
    [onClickByVideoChatLink]
  );

  if (message.type === 'MsgVideoRoomWasCreated') {
    return (
      <>
        {getMsgSystemText(t, chat, message)}
        <a onClick={throttledClickByVideoChatLink}>
          {t('chat-widget.system-msg.join')}
        </a>
      </>
    );
  }

  return null;
};

export default CreatedRoomMsg;
