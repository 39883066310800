import React from 'react';
import PropTypes from 'prop-types';
import Close from '../icons/close';

const ViewerHeader = ({ hideModalDialog }) => (
  <div className="other-viewer-header__container">
    <div
      className="other-viewer-header__button-close"
      onClick={hideModalDialog}>
      <Close />
    </div>
  </div>
);

ViewerHeader.propTypes = {
  hideModalDialog: PropTypes.func.isRequired
};

export default ViewerHeader;
