import React from 'react';
import { isFunction } from '../../../../../utils/utils';
import ItemsFromServer from './ItemsFromServer';
import ItemsFromStorage from './ItemsFromStorage';

const ListOfItemsModal = (props) => {
  if (isFunction(props.loadItemsChunk)) {
    return <ItemsFromServer {...props} />;
  }

  return <ItemsFromStorage {...props} />;
};

export default ListOfItemsModal;
