import { useEffect } from 'react';

const getInputNode = (inputId) => document.getElementById(inputId);

export const useInnerPlaceholder = (inputId, placeholder = '') => {
  useEffect(() => {
    const inputNode = getInputNode(inputId);

    if (inputNode) {
      setInputPlaceholder(inputNode);
    } else {
      console.error(`Input with id ${inputId} not found`);
    }
  }, []);

  function setInputPlaceholder(inputNode) {
    inputNode.placeholder = placeholder; // eslint-disable-line
  }
};
