import React from 'react';
import { Button as RBButton } from 'react-bootstrap';
import './style.css';

function generateClassName(cn = '', variant) {
  return `${cn} custom-btn custom-btn_${variant}`.trim();
}

const Button = ({
  variant = 'primary',
  className,
  disabled = false,
  onClick,
  children
}) => (
  <RBButton
    className={generateClassName(className, variant)}
    onClick={onClick}
    disabled={disabled}>
    {children}
  </RBButton>
);

export default Button;
