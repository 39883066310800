import { clearOrderByAndDirection } from '../../../data-type/query';

export const cancelSortChatsService = async ({
  getChatsQuery,
  updateChatsQuery,
  uploadChats
}) => {
  const updatedQuery = clearOrderByAndDirection(getChatsQuery());
  updateChatsQuery(updatedQuery);
  await uploadChats({ query: updatedQuery });
};
