import React from 'react';

/* eslint-disable max-len */
function IconAddMan() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <rect
        className="cws-icon-main-stroke"
        x="14.5"
        y="8.5"
        width="5"
        height="8"
        rx="2.5"
        stroke="#767B92"
      />
      <path
        className="cws-icon-main-stroke"
        d="M17.3333 24C13.2832 24 10 23.2674 10 22.3636C10.1111 21.4545 10.3383 20.1126 12.6667 19.6364C14 19.3636 14.7778 18.5455 14.6667 18"
        stroke="#767B92"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        className="cws-icon-main-stroke"
        x1="19.5"
        y1="20.5"
        x2="25.5"
        y2="20.5"
        stroke="#767B92"
        strokeLinecap="round"
      />
      <line
        className="cws-icon-main-stroke"
        x1="22.5"
        y1="17.5"
        x2="22.5"
        y2="23.5"
        stroke="#767B92"
        strokeLinecap="round"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconAddMan;
