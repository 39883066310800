import React, { useEffect, useState } from 'react';
import { getPage } from '../../utils/managePages';
import { PageThumbnail } from './PageThumbnail';

export const PageThumbnailContainer = ({
  canvas,
  doc,
  pageIndex,
  pageHeight,
  pageWidth,
  onLoad
}) => {
  const [pageSize, setPageSize] = useState({
    page: null,
    width: pageWidth,
    height: pageHeight
  });

  const { page, height, width } = pageSize;
  const isVertical = true;

  useEffect(() => {
    getPage(doc, pageIndex).then((pdfPage) => {
      const viewport = pdfPage.getViewport({ scale: 1 });

      setPageSize({
        page: pdfPage,
        height: viewport.height,
        width: viewport.width
      });
    });
  }, []);

  return (
    page && (
      <PageThumbnail
        canvas={canvas}
        page={page}
        pageHeight={isVertical ? height : width}
        pageWidth={isVertical ? width : height}
        onLoad={onLoad}
      />
    )
  );
};
