import { updateAndUnshift } from '../../../data-type/block-list';

export const createGroupChatCase = async (
  {
    reqCreateGroupChat,
    getChatList,
    prevChatMode,
    setChats,
    showChat,
    scktUpdateChatList
  },
  { name, membersIds, roles }
) => {
  const chat = await reqCreateGroupChat(name, membersIds, roles);
  const chatList = getChatList();
  const updateChatList = updateAndUnshift(chatList, chat);
  scktUpdateChatList(chat);
  prevChatMode();
  setChats(updateChatList);
  showChat(chat);

  return chat;
};
