import { useState, useEffect } from 'react';
import { useScrolling } from 'react-use';
import throttle from 'lodash.throttle';
import { isNil } from 'ramda';

export function useScrollBottom(ref, deps = []) {
  const isScrolling = useScrolling(ref);

  const [scrollBottom, setScrollBottom] = useState(null);

  useEffect(() => {
    if (!isScrolling) {
      setScrollBottom(calcScrollBottom(ref.current));
    }
  }, [isScrolling, ...deps]);

  return scrollBottom;
}

// NOTE: Notice this method return boolean value and don't call useless re-renders!
export function useIsScrollBottom(ref, deps = []) {
  const [isScrollBottom, setIsScrollBottom] = useState(false);

  useEffect(() => {
    const { current } = ref;

    const handler = () => {
      const isBottom = calcScrollBottom(current) < 30;

      setIsScrollBottom(isBottom);
    };

    handler();

    current.addEventListener('scroll', handler);

    return () => current.removeEventListener('scroll', handler);
  }, deps);

  return isScrollBottom;
}

function calcScrollBottom(node) {
  return node.scrollHeight - (node.scrollTop + node.offsetHeight);
}

export function useIsScrollTop(ref, deps = [], { delta = 0 }) {
  const [isScrollTop, setIsScrollTop] = useState(false);

  useEffect(() => {
    const { current } = ref;

    if (isNil(current)) return undefined;

    const handler = () => {
      const isTop = current.scrollTop <= delta;

      setIsScrollTop(isTop);
    };

    current.addEventListener('scroll', handler);

    return () => current.removeEventListener('scroll', handler);
  }, deps);

  return isScrollTop;
}

const PIXEL_BEFORE = 400;
export function useScrollToEndButton(ref, deps = []) {
  useEffect(() => {
    const { current } = ref;
    const $btn = document.querySelector('.cw-btn-bottom');

    const handler = () => {
      if (!current || !$btn) return;

      const totalHeight = current.scrollHeight;
      const scrollPosition = current.scrollTop + current.clientHeight;

      if (scrollPosition < totalHeight - PIXEL_BEFORE) {
        $btn.classList.add('show');
      } else {
        $btn.classList.remove('show');
      }
    };

    const throttledHandler = throttle(handler, 500);

    current.addEventListener('scroll', throttledHandler);

    return () => current.removeEventListener('scroll', throttledHandler);
  }, deps);
}
