import React from 'react';

const ContactsNotFound = ({ t }) => (
  <div style={{ textAlign: 'center', marginTop: 50 }}>
    <img src="/img/ico_contacts.svg" alt="contacts" />
    <div className="contacts-empty-text">{t('not_found')}</div>
  </div>
);

export default ContactsNotFound;
