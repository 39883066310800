export const createOrUpdateTopicService = (
  { updateTopic, createTopic, showAddMembersModal, onCancel },
  { editMode, id = '_', name, title, desc, files }
) => {
  if (editMode) {
    updateTopic({ id, name, title, desc });
  } else {
    showAddMembersModal({
      cancel: onCancel,
      submit: async (employeesId, membersRoles) =>
        createTopic({ name, title, desc, files, employeesId, membersRoles }),
      mode: 'Topic',
      resetMembersIds: true,
      resetMembersRoles: true
    });
  }
};
