export const getCategoryById = (list, id) =>
  list.find((item) => item.id === id);

export const getNameString = (name, t) =>
  t(`purchaseRequestsCategories.${name}`);

export const filterCategories = ({ list, value, t }) =>
  list.filter(({ name }) =>
    getNameString(name, t).toLowerCase().includes(value.toLowerCase())
  );

export const toggleCategory = ({ selectedList, categoryId }) => {
  if (selectedList.includes(categoryId)) {
    return selectedList.filter((item) => item !== categoryId);
  }
  return [...selectedList, categoryId];
};
