import React from 'react';

const Attached = () => (
  <svg
    width="33"
    height="36"
    viewBox="0 0 33 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M10.7672 36C7.89632 36 5.19121 34.8972 3.15498 32.8901C1.11874 30.9122 0 28.2457 0 25.3867C0 22.5569 1.11874 19.8904 3.15498 17.8833L18.8648 2.39805C20.4334 0.851864 22.517 0 24.7248 0C26.9327 0 29.0163 0.851864 30.5849 2.39805C32.1417 3.93258 33 5.98055 33 8.15689C33 10.3332 32.1417 12.3812 30.5849 13.9157L14.8396 29.436C13.7505 30.5212 12.2943 31.1222 10.7435 31.1222C9.18673 31.1222 7.72466 30.5212 6.62368 29.436C5.50493 28.3332 4.913 26.9329 4.913 25.3867C4.913 23.8405 5.50493 22.4402 6.62368 21.3374L13.5966 14.4642C13.7683 14.295 13.9932 14.2016 14.2359 14.2016C14.4786 14.2016 14.7035 14.295 14.8752 14.4642C15.2244 14.8084 15.2244 15.3744 14.8752 15.7245L7.87265 22.5919C7.10906 23.3446 6.68879 24.3365 6.68879 25.3809C6.68879 26.4311 7.10906 27.423 7.87265 28.1699C8.63623 28.905 9.64843 29.3135 10.7139 29.3135C11.7853 29.3135 12.7857 28.9109 13.5315 28.1699L29.2768 12.6613C30.4784 11.4768 31.165 9.83728 31.165 8.16272C31.165 6.45899 30.4961 4.86029 29.2768 3.66418C28.0752 2.47974 26.4118 1.80292 24.713 1.80292C22.9846 1.80292 21.3627 2.46224 20.1492 3.66418L4.43354 19.1436C2.74063 20.8123 1.80538 23.0295 1.80538 25.3867C1.80538 27.7439 2.74063 29.9611 4.43354 31.6298C6.12646 33.2985 8.37578 34.2204 10.7672 34.2204C13.1586 34.2204 15.4079 33.2985 17.1008 31.6298L24.0737 24.7566C24.2454 24.5874 24.4703 24.494 24.713 24.494C24.9557 24.494 25.1806 24.5874 25.3523 24.7566C25.5239 24.9258 25.6187 25.1475 25.6187 25.3867C25.6187 25.6259 25.5239 25.8476 25.3523 26.0169L18.3735 32.8843C16.3727 34.8914 13.6676 36 10.7672 36Z"
      fill="#C1C5DE"
    />
  </svg>
);

export default Attached;
