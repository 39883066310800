import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonIcon, Checkbox, Typography } from '@components';
import cn from 'classnames';
import { useLayer } from 'react-laag';
import styles from './Filter.module.css';
import iconsView from '../../../assets/images/constants';

/* eslint-disable*/
/**
 * Filter
 *
 * options : [{
 *    checked:boolean,
 *    label:t() - string,
 *    value: unique identifier
 * }];
 * onCheck: (value)=>options
 * clearOptionsChecked: ()=>options
 *
 *
 * @param options
 * @param onCheck
 * @param clearOptionsChecked-- -
 * @returns {JSX.Element}
 */
/* eslint-enable */
const Filter = ({ options, onCheck, clearOptionsChecked, headerTitle }) => {
  const [isShow, setIsShow] = useState(false);

  const onToggle = useCallback(() => setIsShow((prevState) => !prevState), []);

  const isShowCloseButton = !options.every((option) => !option.checked);

  const filterRef = useRef(null);

  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (filterRef.current) {
      setWidth(filterRef.current.getBoundingClientRect().width);
    }
  }, [filterRef.current]);

  const onHide = () => {
    setIsShow(false);
  };

  const { layerProps, triggerProps, renderLayer } = useLayer({
    isOpen: isShow,
    placement: 'bottom-center',
    fixed: true,
    overflowContainer: true,
    container: filterRef.current,
    arrowOffset: 10,
    triggerOffset: 5,
    containerOffset: 5,
    onOutsideClick: onHide,
    onDisappear: onHide
  });

  return (
    <div ref={filterRef}>
      <div
        className={cn(styles.headerContainer, {
          [styles.selectedHeader]: isShow
        })}
        style={{ width }}
        {...triggerProps}>
        <ButtonIcon name={iconsView.Filter} size="m" onClick={onToggle} />
        <Typography variant="body1Reg" className={styles.headerTextColor}>
          {headerTitle}
        </Typography>
        {isShowCloseButton && (
          <ButtonIcon
            classNames={styles.closeBtn}
            onClick={clearOptionsChecked}
            name={iconsView.CloseLarge}
            size="m"
          />
        )}
      </div>

      {isShow &&
        renderLayer(
          <div
            ref={layerProps.ref}
            style={{ ...layerProps.style, zIndex: 1051, width }}>
            <ul className={styles.filterOptionList}>
              {options.map((option, index) => (
                <FilterOption
                  key={`${option.label} - ${index}`}
                  checked={option.checked}
                  label={option.label}
                  onChange={() => onCheck(option.value)}
                />
              ))}
            </ul>
          </div>
        )}
    </div>
  );
};

function FilterOption({ label, onChange, checked }) {
  return (
    <li className={styles.filterOptionItem}>
      <label htmlFor={label} className={styles.labelContainer}>
        <Checkbox id={label} isChecked={checked} onChange={onChange} />
        <Typography variant="body1Reg" className={styles.label}>
          {label}
        </Typography>
      </label>
    </li>
  );
}

export default Filter;
