import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';

import { clearPagesCache, getPage } from '../utils/managePages';
import { ScrollMods, SpecialZoomLevel } from '../constants';
import { calculateScale } from './CalculateScale';
import { useVirtual } from '../hooks/useVirtual';
import { getFileExt } from '../utils/getFileExt';
import { renderQueueService } from '../services/renderQueueService';
import { PageLayer } from '../layers/PageLayer';

const NUM_OVERSCAN_PAGES = 3;
const PAGE_PADDING = 16;

export const Inner = ({
  currentFile,
  defaultScale,
  doc,
  initialPage,
  pageSize,
  plugins,
  renderPage,
  scrollMode,
  viewerState,
  onDocumentLoad,
  onOpenFile,
  onPageChange,
  onZoom
}) => {
  const { numPages } = doc;
  const { docId } = doc.loadingTask;
  const containerRef = useRef();
  const pagesRef = useRef();
  const [currentPage, setCurrentPage] = useState(0);
  const [currentScrollMode, setCurrentScrollMode] = useState(scrollMode);
  const stateRef = useRef(viewerState);
  const [scale, setScale] = useState(pageSize.scale);
  const keepSpecialZoomLevelRef = useRef(
    typeof defaultScale === 'string' ? defaultScale : null
  );
  const isRtl = false;

  const [renderPageIndex, setRenderPageIndex] = useState(-1);
  const renderQueueInstance = useMemo(
    () => renderQueueService({ doc, queueName: 'core-pages' }),
    [docId]
  );

  const setViewerState = (newViewerState) => {
    let newState = newViewerState;

    plugins.forEach((plugin) => {
      if (
        plugin.onViewerStateChange &&
        typeof plugin.onViewerStateChange === 'function'
      ) {
        newState = plugin.onViewerStateChange(newState);
      }
    });

    stateRef.current = newState;
  };

  const estimateSize = useCallback(() => {
    const sizes = [pageSize.pageHeight, pageSize.pageWidth];
    const rect = {
      height: sizes[0],
      width: sizes[1]
    };

    return {
      height: rect.height * scale,
      width: rect.width * scale
    };
  }, [scale]);

  const setStartRange = useCallback(
    (startIndex) => Math.max(startIndex - NUM_OVERSCAN_PAGES, 0),
    []
  );
  const setEndRange = useCallback(
    (endIndex) => Math.min(endIndex + NUM_OVERSCAN_PAGES, numPages - 1),
    [numPages]
  );

  const transformSize = useCallback(
    (size) => ({
      height: size.height + PAGE_PADDING,
      width: size.width + PAGE_PADDING
    }),
    []
  );

  const virtualizer = useVirtual({
    estimateSize,
    isRtl,
    numberOfItems: numPages,
    parentRef: pagesRef,
    scrollMode: currentScrollMode,
    setStartRange,
    setEndRange,
    transformSize
  });

  const { pageWidth, pageHeight } = pageSize;

  const getPagesContainer = () => pagesRef.current;
  const getViewerState = () => stateRef.current;

  const zoom = useCallback((newScale) => {
    renderQueueInstance.resetQueue();

    const pageElem = pagesRef.current;
    // eslint-disable-next-line no-nested-ternary
    const updateScale = pageElem
      ? typeof newScale === 'string'
        ? calculateScale(pageElem, pageHeight, pageWidth, newScale)
        : newScale
      : 1;

    keepSpecialZoomLevelRef.current =
      typeof newScale === 'string' ? newScale : null;

    pageElem.scrollTop =
      (pageElem.scrollTop * updateScale) / stateRef.current.scale;
    pageElem.scrollLeft =
      (pageElem.scrollLeft * updateScale) / stateRef.current.scale;

    setScale(updateScale);

    if (typeof onZoom === 'function') {
      onZoom({ doc, scale: updateScale });
    }

    setViewerState({
      file: viewerState.file,
      pageIndex: stateRef.current.pageIndex,
      pageHeight,
      pageWidth,
      scale: updateScale,
      scrollMode: currentScrollMode
    });
  }, []);

  const jumpToDestination = useCallback(
    (pageIndex, bottomOffset, leftOffset, scaleTo) => {
      const pagesContainer = pagesRef.current;
      const currentState = stateRef.current;

      if (!pagesContainer || !currentState) {
        console.error(`Something broke`);
        return;
      }

      getPage(doc, pageIndex).then((page) => {
        const viewport = page.getViewport({ scale: 1 });
        let boundingRect = [];
        let top = 0;
        const bottom =
          (typeof bottomOffset === 'function'
            ? bottomOffset(viewport.width, viewport.height)
            : bottomOffset) || 0;
        let left =
          (typeof leftOffset === 'function'
            ? leftOffset(viewport.width, viewport.height)
            : leftOffset) || 0;
        let updateScale = currentState.scale;

        switch (scaleTo) {
          case SpecialZoomLevel.PageFit:
            top = 0;
            left = 0;
            zoom(SpecialZoomLevel.PageFit);
            break;
          case SpecialZoomLevel.PageWidth:
            updateScale = calculateScale(
              pagesContainer,
              pageHeight,
              pageWidth,
              SpecialZoomLevel.PageWidth
            );
            top = (viewport.height - bottom) * updateScale;
            left *= updateScale;
            zoom(updateScale);
            break;
          default:
            boundingRect = viewport.convertToViewportPoint(left, bottom);
            left = Math.max(boundingRect[0] * currentState.scale, 0);
            top = Math.max(boundingRect[1] * currentState.scale, 0);
            break;
        }

        switch (currentState.scrollMode) {
          case ScrollMods.Horizontal:
            virtualizer.scrollToItem(pageIndex, { left, top: 0 });
            break;
          case ScrollMods.Vertical:
          default:
            virtualizer.scrollToItem(pageIndex, { left: 0, top });
            break;
        }
      });
    },
    []
  );

  const jumpToPage = useCallback((pageIndex) => {
    if (pageIndex >= 0 && pageIndex < numPages) {
      virtualizer.scrollToItem(pageIndex, { left: 0, top: 0 });
    }
  }, []);

  const openFile = useCallback(
    (file) => {
      if (getFileExt(file.name) !== 'pdf') {
        return;
      }

      new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
          const bytes = new Uint8Array(reader.result);
          resolve(bytes);
        };
      }).then((data) => {
        if (typeof onOpenFile === 'function') {
          onOpenFile(file.name, data);
        }
      });
    },
    [onOpenFile]
  );

  const switchScrollMode = useCallback((newScrollMode) => {
    setViewerState({
      file: viewerState.file,
      pageIndex: stateRef.current.pageIndex,
      pageHeight,
      pageWidth,
      scale,
      scrollMode: newScrollMode
    });
    setCurrentScrollMode(newScrollMode);
  }, []);

  const renderNextPage = () => {
    const nextPageIndex = renderQueueInstance.getHighestPriorityPage();

    if (nextPageIndex > -1) {
      renderQueueInstance.markRendering(nextPageIndex);
      setRenderPageIndex(nextPageIndex);
    }
  };

  const handlePageRenderCompleted = useCallback((pageIndex) => {
    renderQueueInstance.markRendered(pageIndex);
    renderNextPage();
  }, []);

  const executeNamedAction = (action) => {
    const previousPage = currentPage - 1;
    const nextPage = currentPage + 1;

    switch (action) {
      case 'FirstPage':
        jumpToPage(0);
        break;
      case 'LastPage':
        jumpToPage(numPages - 1);
        break;
      case 'NextPage':
        if (nextPage < numPages) {
          jumpToPage(nextPage);
        }
        break;
      case 'PrevPage':
        if (previousPage >= 0) {
          jumpToPage(previousPage);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const pluginMethods = {
      getPagesContainer,
      getViewerState,
      jumpToDestination,
      jumpToPage,
      openFile,
      setViewerState,
      switchScrollMode,
      zoom
    };

    plugins.forEach((plugin) => {
      if (plugin.install && typeof plugin.install === 'function') {
        plugin.install(pluginMethods);
      }
    });

    return () => {
      plugins.forEach((plugin) => {
        if (plugin.uninstall && typeof plugin.uninstall === 'function') {
          plugin.uninstall(pluginMethods);
        }
      });
    };
  }, [docId]);

  useEffect(() => {
    if (typeof onDocumentLoad === 'function') {
      onDocumentLoad({ doc, file: currentFile });
    }

    plugins.forEach((plugin) => {
      if (
        plugin.onDocumentLoad &&
        typeof plugin.onDocumentLoad === 'function'
      ) {
        plugin.onDocumentLoad({ doc, file: currentFile });
      }
    });

    if (initialPage) {
      jumpToPage(initialPage);
    }
  }, [docId]);

  // Scroll to current page after switching the scroll mode
  useEffect(() => {
    const latestPage = stateRef.current.pageIndex;

    if (latestPage > -1) {
      virtualizer.scrollToItem(latestPage, { left: 0, top: 0 });
    }
  }, [currentScrollMode]);

  useEffect(() => {
    const { startRange, endRange, maxVisibilityIndex, virtualItems } =
      virtualizer;

    // eslint-disable-next-line no-shadow
    const currentPage = maxVisibilityIndex;
    setCurrentPage(currentPage);

    if (
      stateRef.current.pageIndex !== currentPage &&
      typeof onPageChange === 'function'
    ) {
      onPageChange({ doc, currentPage });
    }

    setViewerState({
      file: viewerState.file,
      pageIndex: currentPage,
      pageHeight,
      pageWidth,
      scale,
      scrollMode: currentScrollMode
    });

    renderQueueInstance.setRange(startRange, endRange);

    for (let i = startRange; i <= endRange; i++) {
      const item = virtualItems.find((vi) => vi.index === i);
      if (item) {
        renderQueueInstance.setVisibility(i, item.visibility);
      }
    }

    renderNextPage();
  }, [
    virtualizer.startRange,
    virtualizer.endRange,
    virtualizer.maxVisibilityIndex,
    scale
  ]);

  const renderViewer = useCallback(() => {
    let slot = {
      attrs: {
        ref: containerRef,
        style: {
          height: '100%'
        }
      },
      children: <></>,
      subSlot: {
        attrs: {
          className: classNames({
            'pdf-viewer__inner-pages': true,
            'pdf-viewer__inner-pages_horizontal':
              currentScrollMode === ScrollMods.Horizontal,
            'pdf-viewer__inner-pages_rtl': isRtl,
            'pdf-viewer__inner-pages_vertical':
              currentScrollMode === ScrollMods.Vertical,
            'pdf-viewer__inner-pages_wrapped':
              currentScrollMode === ScrollMods.Wrapped
          }),
          ref: pagesRef,
          style: {
            height: '100%',
            overflow: 'auto',
            position: 'relative'
          }
        },
        children: (
          <div style={virtualizer.getContainerStyles()}>
            {virtualizer.virtualItems.map((item) => (
              <div
                className="pdf-viewer__inner-page"
                key={item.index}
                style={virtualizer.getItemStyles(item)}>
                <PageLayer
                  doc={doc}
                  height={pageHeight}
                  width={pageWidth}
                  measureRef={item.measureRef}
                  pageIndex={item.index}
                  renderPage={renderPage}
                  scale={scale}
                  plugins={plugins}
                  shouldRender={renderPageIndex === item.index}
                  onExecuteNamedAction={executeNamedAction}
                  onJumpToDest={jumpToDestination}
                  onRenderCompleted={handlePageRenderCompleted}
                />
              </div>
            ))}
          </div>
        )
      }
    };

    plugins.forEach((plugin) => {
      if (plugin.renderViewer && typeof plugin.renderViewer === 'function') {
        slot = plugin.renderViewer({
          containerRef,
          doc,
          pageHeight,
          pageWidth,
          slot,
          jumpToPage,
          openFile,
          switchScrollMode,
          zoom
        });
      }
    });

    return slot;
  }, [virtualizer, plugins]);

  const renderSlot = useCallback(
    (slot) => (
      <div
        {...slot.attrs}
        style={slot.attrs && slot.attrs.style ? slot.attrs.style : {}}>
        {slot.children}
        {slot.subSlot && renderSlot(slot.subSlot)}
      </div>
    ),
    []
  );

  useEffect(
    () => () => {
      renderQueueInstance.cleanup();
      clearPagesCache();
    },
    []
  );

  return renderSlot(renderViewer());
};
