import { assoc, assocPath, pipe, and } from 'ramda';
import { fork } from '../combinators';

export const minDate = null;
export const maxDate = null;
export const isMinDate = (date) => date === minDate;
export const isMaxDate = (date) => date === maxDate;
export const makeDateRange = ({ from = minDate, to = maxDate } = {}) => ({
  from,
  to
});

const makePagination = ({ offset = 0, limit = 30 } = {}) => ({ offset, limit });

export const makeQuery = ({
  searchQuery = '',
  dateRange = makeDateRange(),
  doOnce = false,
  pagination = makePagination()
} = {}) => ({
  searchQuery,
  dateRange,
  pagination,
  doOnce
});

export const limit = (query) => query.pagination.limit;
export const offset = (query) => query.pagination.offset;
export const setOffset = (offsetValue, query) =>
  assocPath(['pagination', 'offset'], offsetValue, query);
export const zeroOffset = (query) => setOffset(0, query);
export const nextOffset = (query) =>
  setOffset(limit(query) + offset(query), query);

export const isFirstLoad = (query) => !query.doOnce;
export const writeLoad = (query) => ({ ...query, doOnce: true });

export const setDateRange = assoc('dateRange');
export const from = (query) => query.dateRange.from;
export const to = (query) => query.dateRange.to;
export const isMinDateFrom = pipe(from, isMinDate);
export const isMaxDateTo = pipe(to, isMaxDate);
export const isMinMaxDate = fork(and, isMinDateFrom, isMaxDateTo);

export const searchQuery = (query) => query.searchQuery;
export const setSearchQuery = assoc('searchQuery');
