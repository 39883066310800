import React from 'react';

/* eslint-disable max-len */
const IconLeave = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3.5C1 2.11929 2.11929 1 3.5 1H9V2H3.5C2.67157 2 2 2.67157 2 3.5V14.5C2 15.3284 2.67157 16 3.5 16H9V17H3.5C2.11929 17 1 15.8807 1 14.5V3.5Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1464 6.64643C13.3417 6.45117 13.6583 6.45117 13.8535 6.64643L16.3536 9.14648C16.5488 9.34174 16.5488 9.65832 16.3536 9.85358L13.8536 12.3536C13.6583 12.5489 13.3417 12.5489 13.1464 12.3536C12.9512 12.1583 12.9512 11.8418 13.1464 11.6465L15.2929 9.50003L13.1464 7.35354C12.9512 7.15828 12.9512 6.84169 13.1464 6.64643Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 10L6 10V9L16 9V10Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconLeave;
