import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as useCases from '../../useCases';
import { PopUpSystemNotification, popUpSNData } from './lib';
import './style.css';

const CWMPopUpSystemNotification = (props) => (
  <div className="cwm-system-message">
    <PopUpSystemNotification {...props} />
  </div>
);

export default connect(popUpSNData, (dispatch) =>
  bindActionCreators(useCases, dispatch)
)(CWMPopUpSystemNotification);
