export const makeFavoriteMessageMV = ({
  pageNumber,
  handler,
  chat,
  user,
  messages,
  config
}) => ({
  pageNumber,
  handler,
  chat,
  user,
  messages,
  config
});

const isPossibleLoadMore = (messages) => messages.amountLeft > 0;

export const theme = (vm) => vm.config.theme;
export const chat = (vm) => vm.chat;
export const user = (vm) => vm.user;
export const messages = (vm) => vm.messages.list;
export const ownConfig = (vm) => vm.config.config.ownMessage;
export const otherConfig = (vm) => vm.config.config.otherMessage;
export const purchaseRequestMessageConfig = (vm) =>
  vm.config.config.purchaseRequestMessage;
export const config = (vm) => vm.config;
export const initHandlerMsg = (vm) => (item, chatH, msg) =>
  vm.handler(item, msg);
export const amountLeftNext = (vm) => vm.messages.amountLeft;
export const isShowBtnLoadMore = (vm) => isPossibleLoadMore(vm.messages);

export const EVENT_LOAD_MORE = 'load-more';
export const initLoadMore = (vm) => () => {
  vm.handler(EVENT_LOAD_MORE, vm.pageNumber.count());
  vm.pageNumber.increase();
};
