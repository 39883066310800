import { updateQuery } from '../query';
import { makeQuery } from '../../data-type/query';
import { SET_HEADER_VIEW, SET_TAB } from '../../constants';
// eslint-disable-next-line import/no-cycle
import { uploadContacts } from '../contacts';
// eslint-disable-next-line import/no-cycle
import { uploadChats, uploadArchives } from '../chats/services';
import { changeTabCase } from './useCase';
// eslint-disable-next-line import/no-cycle
import { hideGroup } from '../categories/useCase';

const setTab = (tab) => ({ type: SET_TAB, payload: { tab } });
const setSidebarHeaderView = (view) => ({
  type: SET_HEADER_VIEW,
  payload: view
});

const uploadCategory = (tab) => (dispatch) => {
  if (tab === 'contacts') return dispatch(uploadContacts());
  if (tab === 'chats') return dispatch(uploadChats());
  if (tab === 'archives') return dispatch(uploadArchives());
  throw new Error('no such tab');
};

const tabs = {
  contacts: false,
  chats: false,
  archives: false
};

const isLoadedOnce = (tab) => tabs[tab] === true;
const setLoaded = (tab) => {
  tabs[tab] = true;
};

const uploadDataMethod = (tab) => async (dispatch) => {
  if (!isLoadedOnce(tab)) {
    dispatch(updateQuery(tab, makeQuery()));
    dispatch(uploadCategory(tab));
    dispatch(setSidebarHeaderView('dashboard'));
    setLoaded(tab);
  }
};

export const changeTab = (tab) => async (dispatch) =>
  changeTabCase({
    // eslint-disable-next-line no-shadow
    uploadData: () => dispatch(uploadDataMethod(tab)),
    hideCategory: () => dispatch(hideGroup()),
    // eslint-disable-next-line no-shadow
    actionChangeTab: () => dispatch(setTab(tab))
  });
