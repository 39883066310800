import { nanoid } from 'nanoid';
import { isEmpty, isNil } from 'ramda';
import { getList } from '../../forwardMessages/block-list-forward';
import { makeMsgUnsent } from '../entities/unsentForwardMsg';

export const createUnsentForwardMessagesCase = ({
  getOpenedChatId,
  getChatForward,
  getCurrentUser,
  addUnsentMessage
}) => {
  if (isListEmpty()) return [];

  const { employeeId, lastName, firstName, avatarSrc } =
    getCurrentUser().toJS();

  return getForwardBlock().list.map((msg) => {
    const id = nanoid();

    const unsentMessage = makeMsgUnsent({
      id,
      deliveryId: id,
      originId: msg.id,
      originTime: msg.time,
      employeeId,
      userName: `${lastName} ${firstName}`,
      avatarSrc,
      authorEmployeeId: msg.employeeId,
      authorUserName: msg.userName,
      authorAvatarSrc: msg.avatarSrc,
      edited: false,
      time: new Date(),
      msg: msg.msg,
      html: msg.html,
      channelId: msg.channelId,
      files: msg.files,
      accessList: msg.accessList,
      mentions: msg.mentions,
      bookmarkedBy: msg.bookmarkedBy,
      hidden: msg.hidden
    });

    addUnsentMessage(unsentMessage);

    return unsentMessage;
  });

  function getForwardBlock() {
    const chatId = getOpenedChatId();

    const forwardList = getChatForward();
    return getList(forwardList, chatId);
  }

  function isListEmpty() {
    const forwardBlock = getForwardBlock();

    return isNil(forwardBlock) || isEmpty(forwardBlock.list);
  }
};
