import { useEffect, useRef } from 'react';
import { useSessionStorage, useUnmount } from 'react-use';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';

const EDITOR_STORAGE_KEY = 'input-messages';

function usePrevious(value, defValue) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current || defValue;
}

export const useCacheState = ({
  editorId,
  editorState,
  setEditorState,
  clearContent
}) => {
  const prevEditorId = usePrevious(editorId, '');
  const [cache, setCache] = useSessionStorage(
    EDITOR_STORAGE_KEY,
    getNewCache()
  );

  useEffect(() => {
    if (isExistPrevEditorId()) {
      setEditorState(getNewCache());
      setCache((prevCache) => ({
        ...prevCache,
        [prevEditorId]: convertToRaw(editorState.getCurrentContent())
      }));
    }
  }, [editorId]);

  useUnmount(() => {
    const serializedState = JSON.stringify({
      ...cache,
      [editorId]: convertToRaw(editorState.getCurrentContent())
    });
    sessionStorage.setItem(EDITOR_STORAGE_KEY, serializedState);
  });

  return undefined;

  function isExistPrevEditorId() {
    return prevEditorId !== '';
  }

  function isExistCache() {
    return Boolean(cache && cache[editorId]);
  }

  function getNewCache() {
    if (isExistCache()) {
      const rawCache = convertFromRaw(cache[editorId]);
      return EditorState.createWithContent(rawCache);
    }

    return clearContent(editorState);
  }
};
