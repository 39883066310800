import { findIconByName } from './Icon.helpers';
import type { IconProps } from './Icon.types';

export function Icon({
  iconName: IconName,
  width,
  height,
  className
}: IconProps) {
  const Component = findIconByName(IconName);

  return (
    Component && (
      <Component width={width} height={height} className={className} />
    )
  );
}
