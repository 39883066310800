import { getInterlocutor } from 'core/data/light/dialog';
import {
  updateAndUnshift,
  updateAndUnshiftBeforeItems
} from '../../../components/ChatWidget/data-type/block-list';
import {
  includesAny,
  isSelectedSome
} from '../../../components/ChatWidget/data-type/block-group';
import * as getter from '../../../components/ChatWidget/getter';
import * as actions from '../../../components/ChatWidget/modules/chats/actions';
import { logUseCase } from '../../../utils/logger';

const notify = new Audio('/img/notifySound.mp3');

const makeParams = ({
  filterName,
  searchText,
  getChats,
  appendChat,
  saveChats,
  log
}) => ({
  filterNameParam: filterName,
  searchTextParam: searchText,
  getChats,
  appendChat,
  saveChats,
  log
});

const logUpdateChatInList = logUseCase.extend('updateChatInList');
const logRegular = logUpdateChatInList.extend('regular');
const logArchived = logUpdateChatInList.extend('archived');
const selectMethods = (
  { getChatArchive, getChatList, setArchives, setChats },
  chat
) => {
  const list = {
    archived: makeParams({
      filterName: 'archive',
      searchText: 'archives',
      getChats: getChatArchive,
      appendChat: updateAndUnshift,
      saveChats: setArchives,
      log: logArchived
    }),
    reqular: makeParams({
      filterName: 'chat',
      searchText: 'chats',
      getChats: getChatList,
      appendChat: (chatList, chatData) =>
        updateAndUnshiftBeforeItems(chatList, chatData, (c) => c.pinned),
      saveChats: setChats,
      log: logRegular
    })
  };

  if (chat.archived) return list.archived;
  return list.reqular;
};

export const useCase = (
  {
    soundNotifications,
    getCurrentEmployeeId,
    getGroupChats,
    getFilterByTabName,
    getSearchText,
    getChatArchive,
    getChatList,
    setArchives,
    setChats
  },
  chat
) => {
  const methods = selectMethods(
    {
      getChatArchive,
      getChatList,
      setArchives,
      setChats
    },
    chat
  );

  if (!chat.notificationOff && soundNotifications && chat.haveNewMessages) {
    notify.volume = 0.5;
    notify.play();
  }

  execute(methods);

  function execute({
    filterNameParam,
    searchTextParam,
    log,
    getChats,
    appendChat,
    saveChats
  }) {
    const currentEmployeeId = getCurrentEmployeeId();
    const groupChat = getGroupChats();

    log('run');
    const filterName = getFilterByTabName(filterNameParam);
    const searchText = getSearchText(searchTextParam);
    logArchived(`isAllowedByTypeChat ${isAllowedByTypeChat(filterName, chat)}`);
    logArchived(
      `isAllowBySearch ${isAllowBySearch(searchText, currentEmployeeId, chat)}`
    );
    logArchived(`isAllowedByFilterGroup ${isAllowedByFilterGroup(groupChat)}`);
    if (
      isAllowedByTypeChat(filterName, chat) &&
      isAllowBySearch(searchText, currentEmployeeId, chat) &&
      isAllowedByFilterGroup(groupChat)
    ) {
      logArchived('allow update');
      const archives = getChats();
      const updateArchives = appendChat(archives, chat);
      saveChats(updateArchives);
    }
  }

  function isAllowedByFilterGroup(group) {
    if (!isSelectedSome(group)) return true;
    return includesAny(group, chat.checkedLists.user);
  }

  function isAllowedByTypeChat(filterName, chatData) {
    if (filterName === 'dialogs') return chatData.type === 'Dialog';
    if (filterName === 'channels') return chatData.type === 'Channel';
    if (filterName === 'topics') return chatData.type === 'Topic';
    if (filterName === 'all-chats') return true;
    throw new Error(`No such type ${filterName}`);
  }

  function isAllowBySearch(search, employeeId, chatData) {
    if (search === '') return true;

    const reg = new RegExp(escapeSearch(), 'i');
    return reg.test(getText());

    function getText() {
      if (chatData.type === 'Channel' || chatData.type === 'Topic') {
        return chat.name;
      }
      if (chatData.type === 'Dialog') {
        const interlocutor = getInterlocutor(employeeId, chatData);
        return `${interlocutor.userName} ${interlocutor.companyName}`;
      }
      throw new Error(`No such type of chat ${chatData.type}`);
    }

    function escapeSearch() {
      return search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
  }
};

export default (chat) => (dispatch, getState) => {
  useCase(
    {
      soundNotifications: getState().getIn([
        'user',
        'user',
        'soundNotifications'
      ]),
      getCurrentEmployeeId: () =>
        getState().getIn(['user', 'user', 'employeeId']),
      getGroupChats: () => getter.getGroupChats(getState()),
      getChatArchive: () => getter.getChatArchive(getState()),
      getChatList: () => getter.getChatBlock(getState()),
      getFilterByTabName: (name) =>
        getter.getFilterByName(name, getState()).value,
      getSearchText: (name) => getter.getQueryByName(name, getState()).search,
      setArchives: (archivedChats) =>
        dispatch(actions.setArchives(archivedChats)),
      setChats: (chats) => dispatch(actions.setChats(chats))
    },
    chat
  );
};
