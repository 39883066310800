import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';
import ButtonIcon from 'react-components/src/components/ButtonIcon';
import Icons from 'react-components/src/icons/constants';
import * as modalActions from '../../../action-creators/modal';
import OldButtonIcon from '../../ChatWidget/ButtonIcon';
import { getUserLang } from '../../ChatWidget/getter';
import './style.css';

const formatDate = (language, date) => {
  if (!date) return '';
  const formattedDate = format(new Date(date), 'dd.M.yyyy');
  const formattedTime = format(new Date(date), 'HH:mm');
  const unionWord = language === 'ru' ? 'в' : 'at';
  return `${formattedDate} ${unionWord} ${formattedTime}`;
};

const ViewerFooter = ({
  t,
  isShowAddToStorage,
  isDeleteEnabled,
  isDownloadEnabled,
  name,
  senderName,
  date,
  language,
  onCopyToStorage,
  onDownload,
  onDelete,
  handleResend
}) => (
  <div className="other-viewer-footer__container">
    <div className="other-viewer-footer__container-left">
      <div className="other-viewer-footer__name">{name}</div>
      <div className="other-viewer-footer__description">{`${senderName} ${formatDate(
        language,
        date
      )}`}</div>
    </div>
    <div className="other-viewer-footer__container-right">
      {isShowAddToStorage && (
        <OldButtonIcon
          disabled={!isDownloadEnabled}
          onClick={onCopyToStorage}
          icon="save-file"
          tooltip="add_to_storage"
          color="white"
        />
      )}
      <ButtonIcon
        t={t}
        onClick={handleResend}
        icon={Icons.Forward}
        size={34}
        tooltip="reply"
        color="white"
      />
      {isDownloadEnabled && (
        <OldButtonIcon
          disabled={!isDownloadEnabled}
          onClick={onDownload}
          icon="download"
          tooltip="download"
          color="white"
        />
      )}
      {isDeleteEnabled && (
        <ButtonIcon
          disabled={!isDeleteEnabled}
          onClick={onDelete}
          icon="remove-1"
          tooltip="delete"
          color="white"
        />
      )}
    </div>
  </div>
);

ViewerFooter.propTypes = {
  language: PropTypes.string,
  isShowAddToStorage: PropTypes.bool,
  isDeleteEnabled: PropTypes.bool.isRequired,
  isDownloadEnabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  senderName: PropTypes.string,
  date: PropTypes.string,
  onDownload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCopyToStorage: PropTypes.func.isRequired
};

ViewerFooter.defaultProps = {
  isShowAddToStorage: false,
  isDownloadEnabled: true,
  senderName: '',
  date: '',
  language: 'en'
};

const mapState = (state) => ({
  language: getUserLang(state)
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalActions.hideModalDialog
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ViewerFooter);
