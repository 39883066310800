import React, { useLayoutEffect, useRef } from 'react';
import { Table as LibTable } from 'antd';
import { useScroll } from './useScroll';

const SCROLLBAR_WIDTH = 5;

const Table = ({
  className,
  rowClassName,
  columns,
  rows,
  pagination = false,
  loading,
  components
}) => {
  const tableRef = useRef();

  const { containerWidth, containerHeight, tableWidth } = useScroll(
    tableRef,
    columns
  );

  useLayoutEffect(() => {
    if (!loading) {
      const tableHeader = document.querySelector('.ant-table-header');
      const tableBody = document.querySelector('.ant-table-body');

      if (containerWidth > tableWidth) {
        const width = Math.min(tableWidth + SCROLLBAR_WIDTH, containerWidth);

        tableHeader.style.width = `${width}px`;
        tableBody.style.width = `${width}px`;
        tableBody.style.overflow = 'auto';
        tableBody.style.height = `${
          tableBody.getBoundingClientRect().height + 1
        }px`;
      }
    }
  }, [loading, tableWidth, containerWidth]);

  return (
    <LibTable
      loading={loading}
      ref={tableRef}
      className={className}
      rowClassName={rowClassName}
      columns={columns}
      dataSource={rows}
      pagination={pagination}
      scroll={{ x: tableWidth, y: containerHeight }}
      components={components}
    />
  );
};

export default Table;
