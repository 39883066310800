import React from 'react';
import cn from 'classnames';
import { getFormattedDate } from '../../methods';

const DateBar = ({
  t,
  className,
  date,
  language,
  datePanelFormat = getFormattedDate
}) => (
  <div className={cn('cw-messages__date-block', className)}>
    <div className="cw-messages__date">
      {datePanelFormat(t, date, language)}
    </div>
  </div>
);

export default React.memo(DateBar);
