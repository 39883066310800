import React from 'react';
import ButtonIcon from '../../ButtonIcon';
import './style.css';

// Row
const Row = ({ contact, group, handler }) => {
  /* const count = (contactsIds && contactsIds.length) || 0; */
  /* const isAddedToList = contactsIds.find(listContactId => +listContactId === +contactId); */

  const icon = group.checked ? 'remove-m' : 'add-m';
  const tooltip = group.checked ? 'remove' : 'add';

  return (
    <div
      className={`cw-modal-eucl-component__contact-lists_row ${
        group.checked ? 'added' : ''
      }`}>
      <div className="cw-modal-eucl-component__contact-lists_row-title">
        {group.name}
      </div>
      <div className="cw-modal-eucl-component__contact-lists_row-counter">
        {/* <img src="/img/person-count-gray.svg" alt="person" />
            {count} */}
      </div>
      <div className="cw-modal-eucl-component__contact-lists_row-button">
        <ButtonIcon
          icon={icon}
          tooltip={tooltip}
          onClick={() => handler('toggle-add', { group, contact })}
        />
      </div>
    </div>
  );
};

// --------------------------------------------------------------------------------------------------------------------

// List
const ContactLists = ({ contact, handler }) => (
  <div className="cw-modal-eucl-component__contact-lists">
    {contact.lists
      .filter((group) => group.system === false)
      .map((group) => (
        <Row key={group.id} contact={contact} handler={handler} group={group} />
      ))}
  </div>
);

export default ContactLists;
