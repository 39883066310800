import React from 'react';
import { isNil } from 'ramda';
import Text from 'react-components/src/components/Typography';
import Icon from 'react-components/src/icons';
import { getCountActiveMembers } from './helpers';
import { useTranslate } from '../../../../../../../TranslateProvider';
import './style.css';

const ChatInfo = ({ icon, members, online, chatName }) => {
  const t = useTranslate();

  return (
    <div className="cw-chat-card-info">
      <div className="cw-chat-card-info__chat-title">
        {!isNil(icon) && (
          <div className="cw-chat-card-info__title-icon">
            <Icon icon={icon} size={16} />
          </div>
        )}
        <Text
          variant="body1Med"
          level={1}
          weight={2}
          lineHeight={2}
          className="cw-chat-card-info__name">
          {chatName}
        </Text>
      </div>
      <Text
        className="cw-chat-card-info__subtitle"
        level={2}
        lineHeight={3}
        weight={3}>
        {`${t('chat-widget.chat-item.members')} ${getCountActiveMembers(
          members
        )}, online ${online}`}
      </Text>
    </div>
  );
};

export default ChatInfo;
