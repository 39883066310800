import React from 'react';
import { Typography } from '../../../../shared/components';
import classes from './EmailStatusList.module.css';

const Header = ({ headerHeight, label }) => (
  <div className={classes.header} style={{ height: headerHeight }}>
    <Typography variant="body1Med">{label}</Typography>
  </div>
);

export default Header;
