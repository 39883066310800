import React from 'react';
import Text from 'react-components/src/components/Typography';
import Attaches from './attaches';
import {
  generateAttachesVM,
  getAvatar,
  getAvatarAlt,
  getCNMessage,
  getCNMessageText,
  getCNMessageUserName,
  getMessageText,
  getSenderName,
  isShowAttaches,
  isShowAvatar,
  isShowSenderName
} from './dataType';

const LastMessage = ({ vm }) => (
  <div className={getCNMessage(vm)}>
    {isShowAvatar(vm) && (
      <img
        className="cws-chat-last-message__avatar"
        src={getAvatar(vm)}
        alt={getAvatarAlt(vm)}
      />
    )}
    {isShowSenderName(vm) && (
      <Text className={getCNMessageUserName(vm)} variant="body2Med">
        {getSenderName(vm)}
      </Text>
    )}
    {isShowAttaches(vm) && <Attaches vm={generateAttachesVM(vm)} />}
    <Text className={getCNMessageText(vm)} variant="body2Reg">
      {getMessageText(vm)}
    </Text>
  </div>
);

export default LastMessage;
