import React, { useEffect, useState, useCallback } from 'react';
import { defaultSuggestionsFilter } from '@draft-js-plugins/mention';

const Entry = (props) => {
  const { mention, theme, searchValue, isFocused, ...parentProps } = props;

  return (
    <div {...parentProps}>
      <div className={theme.mentionSuggestionsEntryContainer}>
        <div className={theme.mentionSuggestionsEntryContainerLeft}>
          <img
            src={mention.avatar}
            className={theme.mentionSuggestionsEntryAvatar}
            role="presentation"
            alt=""
          />
        </div>

        <div className={theme.mentionSuggestionsEntryContainerRight}>
          <div className={theme.mentionSuggestionsEntryText}>
            {mention.name}
          </div>

          <div className={theme.mentionSuggestionsEntryTitle}>
            {mention.title}
          </div>
        </div>
      </div>
    </div>
  );
};

export default function MentionSuggestions({ editorId, mentions, Component }) {
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(mentions);

  useEffect(() => {
    // NOTE: when change channel reset mentions
    setSuggestions(defaultSuggestionsFilter('', mentions));
  }, [editorId]);

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);
  const onSearchChange = useCallback(
    ({ value }) => {
      setSuggestions(defaultSuggestionsFilter(value, mentions));
    },
    [editorId]
  );

  return (
    <Component
      open={open}
      onOpenChange={onOpenChange}
      suggestions={suggestions}
      onSearchChange={onSearchChange}
      onAddMention={() => {
        // get the mention object selected
      }}
      entryComponent={Entry}
    />
  );
}
