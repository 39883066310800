const { assoc } = require('ramda');

/*
 * Сообщение (Message) это абстрактный тип данных
 * который выражается в Команде и Событии
 */

const makeCommand = (namespace, name) => ({
  type: 'Command',
  name: `COMMAND:${namespace}:${name}`
});

const makeEvent = (namespace, name) => ({
  type: 'Event',
  name: `EVENT:${namespace}:${name}`
});

const addParams = assoc('params');

module.exports = {
  makeCommand,
  makeEvent,
  addParams
};
