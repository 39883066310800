import React from 'react';

/* eslint-disable max-len */
function IconFolder() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="17"
        cy="17"
        r="17"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-stroke"
        d="M18.584 11.2774L18.7324 11.5H19H24C24.8284 11.5 25.5 12.1716 25.5 13V24C25.5 24.8284 24.8284 25.5 24 25.5H10C9.17157 25.5 8.5 24.8284 8.5 24V10C8.5 9.17157 9.17157 8.5 10 8.5H15.9296C16.4312 8.5 16.8995 8.75065 17.1777 9.16795L18.584 11.2774Z"
        stroke="#767B92"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconFolder;
