import { useEffect } from 'react';
import { setMessage } from '../utils';

export const useMessageFromOutside = ({
  editorRef,
  editorState,
  setEditorState
}) => {
  useEffect(() => {
    const fn = (e) => {
      const { msg } = e.detail;
      setEditorState(setMessage(editorState, msg));

      // NOTE: fixed bug of delete char after pasted text for edit
      setTimeout(() => {
        editorRef.current.focus();
      }, 0);
    };

    document.addEventListener('set-msg-to-editor', fn);
    return () => document.removeEventListener('set-msg-to-editor', fn);
  }, []);
};
