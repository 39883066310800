const { makeCommand, makeEvent } = require('../data/messageBus/message');
const { makeQuery } = require('./utils');

const NS = 'TASK_BADGES';

exports.badgesQRY = makeQuery(NS, 'TASK_BADGES');

exports.removeBadgeCMD = makeCommand(NS, 'TASK_BADGE_REMOVE');

exports.badgesCreatedEVT = makeEvent(NS, 'TASK_BADGES_WAS_CREATED');
exports.badgeRemovedEVT = makeEvent(NS, 'TASK_BADGE_WAS_REMOVED');

exports.viewCMD = makeCommand(NS, 'view');
